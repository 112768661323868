<div class="" *ngIf="milage">
  <div class="row" *ngIf="mileageForm">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="mileageForm" id="mileageForm" (ngSubmit)="saveMileage()">
          <div class="col-md-4">
            <mat-form-field>
              <input type="text" id = "description" placeholder="Description" [(ngModel)]="milage.vehicleUsageDescription" matInput formControlName="vehicleUsageDescription"
              />
              <mat-error *ngIf="mileageForm.controls['vehicleUsageDescription'].hasError('required')">A description is required</mat-error>

            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <input type="text" placeholder="Mileage Start" readonly="readonly" [(ngModel)]="milage.vehicleUsageMileageStart" matInput
                formControlName="vehicleUsageMileageStart" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-select formControlName="vehicleUsageType" placeholder="Usage Type" [(ngModel)]="milage.vehicleUsageType" disableOptionCentering>
                <mat-option [value]="0">to/from Client</mat-option>
                <mat-option [value]="1">to/from Meridian office</mat-option>
              </mat-select>
              <mat-error *ngIf="mileageForm.controls['vehicleUsageType'].hasError('required')">A usage type is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <input type="number" placeholder="Mileage End" [(ngModel)]="milage.vehicleUsageMileageEnd" matInput formControlName="vehicleUsageMileageEnd"
              />
              <mat-error *ngIf="mileageForm.controls['vehicleUsageMileageEnd'].hasError('required')">The end mileage is required</mat-error>

              <mat-error *ngIf="mileageForm.controls['vehicleUsageMileageEnd'].hasError('min')">Mileage cannot be less than 0</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <input matInput [matDatepicker]="picker" [(ngModel)]="milage.vehicleUsageDate" placeholder="Vehicle Usage Date" formControlName="vehicleUsageDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="mileageForm.controls['vehicleUsageDate'].hasError('required')">A usage date is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4" *ngIf="isAdmin">
            <ng-template #loading>
              <p>Loading the list of drivers</p>
            </ng-template>
            <mat-form-field *ngIf="drivers; else loading">
              <mat-select [(ngModel)]="milage.createdByEmployeeId" formControlName="createdByEmployeeId" placeholder="Select a driver" disableOptionCentering>
                <mat-option *ngFor="let driver of drivers" [value]="driver.employeeId">{{driver.firstName + ' ' + driver.lastName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
          <mat-form-field>
            <input type="text" id = "note" placeholder="Notes" [(ngModel)]="milage.vehicleUsageNote" matInput formControlName="vehicleUsageNote"
            />
          </mat-form-field>
          </div>


          <div class="col-md-4">
            <button [disabled]="!mileageForm.valid" mat-raised-button color="primary" type="submit">Save</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
