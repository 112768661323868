import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import { Discovery } from '../../models/discovery-datasource';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { Workload, WorkloadDataSource } from '../../models/workload-datasource';
import { MsalService } from '@azure/msal-angular';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { CloudMigrationDiscoveryWorkloadComponent } from '../cloud-migration-discovery-workload/cloud-migration-discovery-workload.component';
import { CloudMigrationDiscoveryNetworkprofileComponent } from '../cloud-migration-discovery-networkprofile/cloud-migration-discovery-networkprofile.component';
import { AssessmentDataSource } from '../../models/assessment-datasource';
import { CloudMigrationDiscoveryNetworkComponent } from '../cloud-migration-discovery-network/cloud-migration-discovery-network.component';

@Component({
  selector: 'app-cloud-migration-discovery-detail',
  templateUrl: './cloud-migration-discovery-detail.component.html',
  styleUrls: ['./cloud-migration-discovery-detail.component.scss'],
})
export class CloudMigrationDiscoveryDetailComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  projectId: string;
  displayedColumns = ['vmId', 'actions'];
  displayedInterfaceColumns = ['broadcastIp'];
  wrkloadDataSource: WorkloadDataSource;
  assessmentList: AssessmentDataSource;
  loading: boolean;
  workloads: Array<Workload>;
  discovery: Discovery;
  discoveryId: string;
  clientId: string;
  paymentOptions: any[];
  cloudProviderOptions: any[];
  newAssessment: any;
  newWorkload: any;
  networkData: any;
  networkProfileList: any;
  workloadOptions: any;
  discoverySourceEnvironment: any;
  isLPAR: boolean = false;
  workloadList: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private cloudMigrationService: CloudMigrationToolService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe((param) => {
      this.discoveryId = param['discoveryId'];
      if (this.discoveryId) {
        this.loadData();
      }
    });
  }

  loadData() {
    this.loading = true;
    this.cloudMigrationService.getDiscovery(this.discoveryId).subscribe(
      (res) => {
        this.loading = false;
        this.discovery = res;
        this.discoverySourceEnvironment = res.sourceEnvironment;
        this.workloads = res.workloadList.rows;
        this.workloadOptions = res.workloadOptions;
        this.workloadList = res.workloadList.rows;
        this.wrkloadDataSource = new WorkloadDataSource(this.workloads);

        this.assessmentList = new AssessmentDataSource(res.assessments.rows);
        this.assessmentList.projectId = this.discovery.projectId;
        this.networkData = this.discovery.networkData;
        this.networkProfileList = this.discovery.networkProfiles;

        this.wrkloadDataSource.sort = this.sort;
        this.wrkloadDataSource.paginator = this.paginator;
        this.isLPAR = this.discoverySourceEnvironment == 'PowerVM';
      },
      (error) => {
        this.loading = false;
      }
    );

    this.cloudMigrationService
      .getNewWorkload(this.discoveryId)
      .subscribe((data) => {
        this.newWorkload = data;
      });
  }

  deleteRecord(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cloudMigrationService
          .deleteWorkload(id, this.discoveryId)
          .subscribe((res) => {
            //this.getWorkloads();
          });
      }
    });
  }

  workloadAdded($event: any) {
    this.loadData();
  }

  generateNetworkProfile() {
    this.openGenerateNetworkProfileDialog();
  }

  addWorkload() {
    this.cloudMigrationService
      .getNewWorkload(this.discoveryId)
      .subscribe((data) => {
        this.newWorkload = data;
        this.openDiscoveryWorkloadDialog(this.newWorkload);
      });
  }

  manageNetworkProfile() {
    const popup = this.dialog.open(
      CloudMigrationDiscoveryNetworkprofileComponent,
      {
        width: '80%',
        minWidth: '250px',
        data: {
          discoveryId: this.discoveryId,
        },
        disableClose: true,
        autoFocus: true,
      }
    );

    popup.afterClosed().subscribe((res) => {
      console.log('popup after close res:', res);
      if (res) {
        this.loadData();
      }
    });
  }

  addNetworkData() {
    const popup = this.dialog.open(CloudMigrationDiscoveryNetworkComponent, {
      width: '80%',
      data: {
        discoveryId: this.discoveryId,
      },
      disableClose: true,
    });

    popup.afterClosed().subscribe((res) => {
      console.log('popup after close res:', res);
      if (res) {
        this.loadData();
      }
    });
  }

  openDiscoveryWorkloadDialog(rowData: any) {
    rowData.operatingSystemTypeOptions =
      this.workloadOptions.operatingSystemTypeOptions;
    rowData.optimizationTypeOptions =
      this.workloadOptions.optimizationTypeOptions;
    rowData.environmentTypeOptions =
      this.workloadOptions.environmentTypeOptions;
    rowData.virtualizationTypeOptions =
      this.workloadOptions.virtualizationTypeOptions;

    const popup = this.dialog.open(CloudMigrationDiscoveryWorkloadComponent, {
      height: '30%',
      width: '60%',
      data: {
        rowData: rowData,
        discoveryId: this.discoveryId,
        isLPAR: this.isLPAR,
      },
      disableClose: true,
    });

    popup.afterClosed().subscribe((res) => {
      this.loadData();
    });
  }

  openGenerateNetworkProfileDialog() {
    const popup = this.dialog.open(
      CloudMigrationDiscoveryNetworkprofileComponent,
      {
        width: '80%',
        minWidth: '250px',
        data: {
          networkDataId: this.networkData.id,
          discoveryId: this.discoveryId,
          delay: this.networkData.delay,
          loss: this.networkData.loss,
        },
        disableClose: true,
      }
    );

    popup.afterClosed().subscribe((res) => {
      if (res) {
        this.loadData();
      }
    });
  }
}
