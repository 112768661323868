import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { SalesforceTestingData } from './salesforce-testing.models';
export class SalesforceTestingDatasource extends DataSource<any> {
    data: Array<SalesforceTestingData>;
    connect(): Observable<SalesforceTestingData[]> {

        return of(this.data);
    }
    disconnect(collectionViewer: CollectionViewer): void {

    }
    setData(data: Array<any> | null | undefined) {
      if (data) {
        this.data = data;
      } else {
        this.data = [];
      }
    }
}
