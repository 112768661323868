  <fieldset>
    <legend>
      Assessments
    </legend>
    <mat-table mat-table matSort class="full-width-table" aria-label="Assessments Table" [dataSource]="assessmentsDataSource" >
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.title}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{rowTitle[1]}}</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.date| date:'shortDate'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="source">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{rowTitle[2]}}</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.source}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="target">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{rowTitle[3]}}</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.target}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="cost">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{rowTitle[4]}}</mat-header-cell>
        <mat-cell *matCellDef="let row">${{row.cost}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="navigateMenu(row.id)">
                <mat-icon>search</mat-icon>
                <span>View Assessment</span>
              </button>
              <button mat-menu-item (click)="deleteRecord(row.id)">
                <mat-icon>delete_outline</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>

          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [class.loading]="loading" #paginator [length]="assessments?.rows?.length" [pageIndex]="0" [pageSize]="50"
                   [pageSizeOptions]="[5, 25, 50, 100]">
    </mat-paginator>
  </fieldset>
