import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-business-partner-task',
  templateUrl: './business-partner-task.component.html',
  styleUrls: ['./business-partner-task.component.scss']
})
export class BusinessPartnerTaskComponent implements OnInit {
  @Input() businessPartnerTask: any;

  constructor() { }

  ngOnInit() {
  }

}
