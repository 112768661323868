<mat-card>
  <mat-card-content>
    <div
      [class.loading]="!loading"
      style="position: relative; margin: auto; width: calc(50% + 225px)"
    >
      <mat-spinner
        mode="indeterminate"
        width="450"
        height="450"
        style="margin: 0 auto"
      ></mat-spinner>
    </div>

    <div *ngIf="discovery" [class.loading]="loading">
      <div>
        <fieldset>
          <legend>Discovery</legend>
          <div class="row adjust-linehgt">
            <p class="col-md-6">
              Client Name: {{ discovery.projectInfo?.clientLegalName }}
            </p>
            <p class="col-md-6">Environment: {{ discovery.environment }}</p>
          </div>
          <div class="row adjust-linehgt"></div>
          <div class="row adjust-linehgt">
            <p class="col-md-6">Performed by: {{ discovery.performedBy }}</p>
            <p class="col-md-6">
              Date Performed: {{ discovery.datePerformed | date : "shortDate" }}
            </p>
          </div>
        </fieldset>
      </div>
      <br />
      <!--Workloads-->
      <div [class.loading]="loading">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ discoverySourceEnvironment }} Workloads({{
                wrkloadDataSource.workloads.length
              }})
            </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <h4 class="col-md-2 card-label-text">Add Workload</h4>
            <cloud-migration-workload-add
              [workload]="newWorkload"
              [isLPAR]="isLPAR"
              (OnAdd)="workloadAdded($event)"
            ></cloud-migration-workload-add>
          </div>
          <br />

          <div>
            <fieldset>
              <legend>Workloads</legend>
              <!-- list -->
              <div>
                <cloud-migration-discovery-workload-list
                  [workloads]="workloadList"
                  [LPARLabeling]="isLPAR"
                  (OnViewAction)="openDiscoveryWorkloadDialog($event)"
                  (OnDeleteAction)="deleteRecord($event)"
                ></cloud-migration-discovery-workload-list>
              </div>
            </fieldset>
          </div>
        </mat-expansion-panel>
      </div>
      <br />
      <!--Network Data-->
      <div>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> Network Data... </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>

          <div [class.loading]="loading">
            <fieldset>
              <!-- <legend>
                Network Data
              </legend> -->
              <div class="align-button">
                <a
                  mat-raised-button
                  color="primary"
                  (click)="generateNetworkProfile()"
                  style="float: right"
                >
                  <mat-icon>assignment</mat-icon>Generate Network Profile
                </a>
                <a
                  mat-raised-button
                  color="primary"
                  (click)="addNetworkData()"
                  style="float: right"
                >
                  <mat-icon>add</mat-icon>Add Network Data
                </a>
              </div>
              <div *ngIf="networkData">
                <div class="row">
                  <div class="col-6">
                    <app-cloud-migration-discovery-traceroutedata
                      *ngIf="networkData.traceRoutDataLoaded"
                      [traceroutedata]="networkData.tracerouteDataRows"
                    >
                    </app-cloud-migration-discovery-traceroutedata>
                  </div>
                  <div class="col-6">
                    <app-cloud-migration-discovery-pingdata
                      *ngIf="discovery.networkData.pingDataLoaded"
                      [pingdata]="networkData.pingDataRows"
                    >
                    </app-cloud-migration-discovery-pingdata>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <app-cloud-migration-discovery-perfdata
                      *ngIf="discovery.networkData.perfDataLoaded"
                      [perfdata]="networkData.perfDataRows"
                    >
                    </app-cloud-migration-discovery-perfdata>
                  </div>
                  <div class="col-6">
                    <app-cloud-migration-discovery-interfacedata
                      *ngIf="networkData.interfaceDataLoaded"
                      [interfacedata]="networkData.interfaceDataRows"
                    >
                    </app-cloud-migration-discovery-interfacedata>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </mat-expansion-panel>
      </div>
      <br />
      <!-- Network Profiles -->
      <div *ngIf="networkProfileList">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Network Profiles({{ networkProfileList.rows.length }})
            </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>
          <app-cloud-migration-discovery-networkprofile-list
            [networkProfiles]="networkProfileList"
          ></app-cloud-migration-discovery-networkprofile-list>
        </mat-expansion-panel>
      </div>
      <br />

      <!--Assessments-->
      <div *ngIf="assessmentList">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Assessements({{ assessmentList.assessments.length }})
            </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>
          <app-cloud-migration-assessments-table
            [assessments]="assessmentList"
          ></app-cloud-migration-assessments-table>
        </mat-expansion-panel>
      </div>
      <br />
    </div>
  </mat-card-content>
</mat-card>
