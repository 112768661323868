<a #downloadCsvLink style="display: none;"></a>
<div class="col-md-12">
  <mat-card>
    <h3 class="title-underline">Select Service Estimates/Change Orders to be quoted</h3>
    <mat-card-content>

      <!-- Error message if no valid service estimates or change orders -->
      <p class="error-message">
        Only valid Service Estimate and/or Change Orders will appear here. They must be both Approved and not expired.
      </p>

      <!-- List for Service Estimates -->
      <h4 *ngIf="filteredServiceEstimates.length > 0">Service Estimates</h4>
      <mat-selection-list [(ngModel)]="selectedServiceEstimates" *ngIf="filteredServiceEstimates.length > 0">
        <mat-list-option *ngFor="let estimate of filteredServiceEstimates" [value]="estimate" checkboxPosition="before">
          {{ estimate.name }}
        </mat-list-option>
      </mat-selection-list>

      <!-- List for Change Orders -->
      <h4 *ngIf="filteredChangeOrders.length > 0">Change Orders</h4>
      <mat-selection-list [(ngModel)]="selectedChangeOrders" *ngIf="filteredChangeOrders.length > 0">
        <mat-list-option *ngFor="let order of filteredChangeOrders" [value]="order" checkboxPosition="before">
          {{ order.name }}
        </mat-list-option>
      </mat-selection-list>
      
    </mat-card-content>

    <!-- Button at the bottom -->
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="exportToCSV()"
              [disabled]="(filteredServiceEstimates.length === 0 && filteredChangeOrders.length === 0) || 
                         (selectedServiceEstimates.length === 0 && selectedChangeOrders.length === 0)">
        Export to CSV
      </button>
    </mat-card-actions>
  </mat-card>
</div>
