<div class="row">
  <div class="col-md-12">
    <mat-card *ngIf="card">
      <mat-card-content>
        <p class="statusMessage" *ngIf="statusMessage" [class.error]="hasUpdateError">

        </p>
        <mat-toolbar>
          <button [disabled]="isEditing" mat-button mat-icon-button (click)="startEditMode()" [color]="color">
            <mat-icon>edit</mat-icon>
          </button>
          <button [disabled]="!isEditing" mat-button mat-icon-button (click)="endEditMode()" [color]="color">
            <mat-icon>cancel</mat-icon>
          </button>
          <button [disabled]="!isEditing" mat-button mat-icon-button (click)="updateCard()" [color]="color">
            <mat-icon>save</mat-icon>
          </button>
        </mat-toolbar>
        <br>
        <ng-template #gasCard>
          <mat-form-field>
            <input [disabled]="!isEditing" matInput placeholder="Card Number" [(ngModel)]="card.vehicleGasCardNumber" type="text" />
            <mat-hint align="start">
              <strong>The trailing six to eight numbers. Ex. 0016-1</strong>
            </mat-hint>
          </mat-form-field>
          <mat-form-field>
            <input [disabled]="!isEditing" matInput [matDatepicker]="picker" placeholder="Expiration Date" [(ngModel)]="card.vehicleGasCardExpirationDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </ng-template>
        <span *ngIf="cardType === 'oil';else gasCard">
          
            <mat-form-field>
                <input [disabled]="!isEditing" matInput placeholder="Card Number" [(ngModel)]="card.vehicleOilCardNumber" type="text" />
                <mat-hint align="start">
                    <strong>The trailing four numbers. Ex. 16-1</strong>
                </mat-hint>
              </mat-form-field>
              <mat-form-field>
                <input [disabled]="!isEditing" matInput [matDatepicker]="picker" placeholder="Expiration Date" [(ngModel)]="card.vehicleOilCardExpirationDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </span>
        <mat-form-field>
          <mat-select [disabled]="!isEditing" placeholder="Employee" [(ngModel)]="card.assignedToEmployeeId" disableOptionCentering>
            <mat-option *ngFor="let employee of employees" [value]="employee.employeeId">{{employee.firstName}} {{employee.lastName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>

    </mat-card>
  </div>
</div>
