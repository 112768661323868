import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceEstimateService {

  constructor(private http: HttpClient) { }
  getServiceEstimates(oppQ: any) {
    return this.http.get<any>(`/api/ServiceProjects/ServiceEstimates/${oppQ.toString()}`);
  }
}
