import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProjectDocumentService } from './project-document.service';

@Component({
  templateUrl: './project-document-popup.component.html',
  styleUrls: ['./project-document-popup.component.scss']
})
export class ProjectDocumentPopupComponent implements OnInit {

  documentForm: UntypedFormGroup;
  fileName: string;
  isDuplicateName: boolean;
  docFileError: boolean;
  constructor(private fb: UntypedFormBuilder, public dialogRef: MatDialogRef<ProjectDocumentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private projectDocumentService: ProjectDocumentService) { }

  ngOnInit(): void {
    this.fileName = "";
    this.documentForm = this.fb.group({
      'name': ['', Validators.required],
      'projectDocumentType': [null, Validators.required],
      'documentFile': [null, Validators.required],
      'serviceProjectId': [this.data?.id]
    });
  }
  onFileChange(event: any) {
    console.log(event);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = file.name;
      this.documentForm.patchValue({ documentFile: file });
    }
  }
  saveFile() {
    this.projectDocumentService.createProjectDocument(this.documentForm.value)
      .subscribe(res => {
        this.dialogRef.close(this.data);
        this.isDuplicateName = false;
        console.log(res);
      }, (error) => {
        if (error.status == 500)
        this.isDuplicateName = true;
      });
  }
  updateDocumentFile(event: any) {
    let file = event.target.files[0];
    if (file) {
      this.documentForm.patchValue({ documentFile: file });
      this.documentForm.updateValueAndValidity();
      this.fileName = file?.name;
      this.setErrorStatus();
    }
  }
  setErrorStatus() {
    if (this.fileName)
      this.docFileError = false;
    else
      this.docFileError = true;
  }
}
