<div [ngClass]="getFontColor()" class="release-notes">
  <div *ngIf="isLoading">
    <p>
      ...Loading the Release Information
      Please wait
    </p>
  </div>
  <div *ngIf="!isLoading">
      <div>
        <div class="header">
          <h3 align="center" class="dialog-title">
            Release Notes
          </h3>
          <button color="accent" align="end" class="md-18 cursor" (click)="closePopup()" mat-icon-button id="closeNotes">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <div id="popUpReleaseVersion" *ngIf="currentRelease && !noRecords && isAckBtnDisplayed()" align="end">
          Release Version : {{currentRelease.version}}
          <br />
          Release Date : {{currentRelease.releaseDate| date:'shortDate'}}
        </div>
      </div>
      <div class="notes-content">
        <div *ngIf="userstories?.length">
          <h4>Enhancements :</h4>
          <div *ngFor="let st of userstories">
            <div>
              <!-- <mat-icon *ngIf="st.imgDetails.length" (click)="viewMockup(st.imgDetails)" class="display-pointer" [attr.id]="'image_'+st.id">image</mat-icon> -->
              <h4 class="apply-color inline" (click)="navigateToWorkItemDetails(st.id)">{{st.title}}</h4>
            </div>
            <span [innerHtml]="st.releaseNoteDetails"></span>
            <br />
          </div>
        </div>

        <div *ngIf="bugs?.length">
          <h4>Bugs :</h4>
          <div *ngFor="let bg of bugs">
            <div>
              <!-- <mat-icon *ngIf="bg.imgDetails.length" (click)="viewMockup(bg.imgDetails)" class="display-pointer" [attr.id]="'image_'+bg.id">image</mat-icon> -->
              <h4 class="apply-color inline" (click)="navigateToWorkItemDetails(bg.id)"> {{bg.title}}</h4>
            </div>
            <span [innerHtml]="bg.releaseNoteDetails"></span>
            <br />
          </div>
        </div>
        <div *ngIf="noRecords || !isAckBtnDisplayed()" id="noRecords"><br />Release Notes Yet to be Posted</div>
      </div>

    <div *ngIf="isAckBtnDisplayed()">
      <button id="ackBtn" mat-raised-button color="primary" (click)="acknowledge()" [disabled]="disableAckBtn">Acknowledge</button>
    </div>
  </div>
</div>

