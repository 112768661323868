import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';

@Component({
  selector: 'app-cloud-migration-storage-popup',
  templateUrl: './cloud-migration-storage-popup.component.html',
  styleUrls: ['./cloud-migration-storage-popup.component.scss']
})
export class CloudMigrationStoragePopupComponent implements OnInit {

  storageForm: UntypedFormGroup;
  storageId: string;
  @ViewChild('form') form: NgForm;

  constructor(public dialogRef: MatDialogRef<CloudMigrationStoragePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private location: Location,
    private cloudMigrationService: CloudMigrationToolService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    //this.storageId = this.data['storageId'];
    //if (this.storageId) {
    //  this.cloudMigrationService.getStorage(this.storageId)
    //    .subscribe(res => {
    //      this.storageForm = this.fb.group({
    //        'environment': [res.environment, Validators.required],
    //        'serverId': [res.serverId, Validators.required],
    //        'name': [res.name, Validators.required],
    //        'ram': [res.ram, [Validators.required, Validators.min(0)]],
    //        'operatingSystemType': [res.operatingSystemType, Validators.required],
    //        'coresPerProcessor': [res.coresPerProcessor, [Validators.required, Validators.min(0)]],
    //        'id': [res.id],
    //      });
    //    });
    //}
    //else {
    //  this.wrkloadForm = this.fb.group({
    //    'environment': [null, Validators.required],
    //    'serverId': [null, Validators.required],
    //    'name': [null, Validators.required],
    //    'ram': [null, [Validators.required, Validators.min(0)]],
    //    'operatingSystemType': [null, Validators.required],
    //    'coresPerProcessor': [null, [Validators.required, Validators.min(0)]]
    //  });
    //}
  }
  //public canDeactivate(): boolean {
  //  let state: boolean;
  //  state = true;
  //  if (this.wrkloadForm) {
  //    state = this.form.submitted || !this.form.dirty;
  //  }

  //  const url = this.location.path();

  //  if (state) {

  //  } else {
  //    history.pushState(null, 'Meridian Unified Portal', url);
  //  }
  //  return state;
  //}
  //saveWrkload() {
  //  if (this.wrkloadForm.valid) {
  //    if (this.wrkloadForm.controls['id']) {
  //      this.cloudMigrationService.updateWorkload(this.wrkloadForm.value, this.discoveryId)
  //        .subscribe(res => {
  //          this.dialogRef.close(this.wrkloadForm.value);
  //        });
  //    } else {

  //      this.cloudMigrationService.createWorkload(this.wrkloadForm.value, this.discoveryId)
  //        .subscribe(res => {
  //          this.dialogRef.close(this.wrkloadForm.value);
  //        });
  //    }
  //  }
  //}

}
