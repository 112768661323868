<div class="container" *ngIf="currentUser">
  <fieldset class="my-profile spacing">
    <legend><span><mat-icon class="profile-icon" color="primary">account_circle</mat-icon></span></legend>
    <h2>My Profile</h2>
    <div [ngClass]="getFontColor()">
      <h2 id="displayName">{{currentUser.displayName}}</h2>
      <h3 id="jobTitle">{{currentUser.jobTitle}}</h3>
      <mat-grid-list class="top" cols="4" rowHeight="fit" id="gridList">
        <mat-grid-tile colspan="1"><mat-icon>mail</mat-icon>&ensp;: </mat-grid-tile>
        <mat-grid-tile colspan="3" class="align-margin" id="email"><div class="grid-tile text-overflow">{{currentUser.mail}}</div></mat-grid-tile><!--[matTooltip]="getToolTipData()" [matTooltipPosition]="'below'"-->
        <mat-grid-tile colspan="1"><mat-icon>local_phone</mat-icon>&ensp;: </mat-grid-tile>
        <mat-grid-tile colspan="3" class="align-margin" id="phone"><div class="grid-tile">{{currentUser.mobilePhone}}</div></mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="4" rowHeight="2:1">
        <mat-grid-tile class="grid-tile-left" colspan="3"><div class="grid-tile">Dark Theme</div></mat-grid-tile>
        <mat-grid-tile class="grid-tile-right" colspan="1"><mat-slide-toggle id="darkThemeToggle" [(ngModel)]="isDarkThemeEnabled" (change)="flipTheme()"></mat-slide-toggle></mat-grid-tile>
        <mat-grid-tile class="grid-tile-left" colspan="3" *ngIf="this.isDashbboardViewVisible"><div class="grid-tile">Dashboard</div></mat-grid-tile>
        <mat-grid-tile class="grid-tile-right" colspan="1" *ngIf="this.isDashbboardViewVisible"><mat-slide-toggle id="dashboardToggle" [(ngModel)]="isDashboardViewEnabled" (change)="toggleDashboardView()"></mat-slide-toggle></mat-grid-tile>
      </mat-grid-list>     
    </div>
  </fieldset>
</div>

<div [class.loading]="!isLoading" style="position: relative; margin: 1% auto; width: calc(50% + 225px)">
  <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
</div>
