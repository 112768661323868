<fieldset>
  <legend>Cloud Solution Details</legend>
  <div>
    <mat-expansion-panel id="btn-items-hide-show" hideToggle [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>{{itemCount}} Mapped Workloads. Click to View/Hide..</h3>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <mat-table
          *ngIf="cloudSolDetailsDataSource"
          mat-table
          matSort
          class="full-width-table"
          aria-label="Cloud Solution Table"
          [dataSource]="cloudSolDetailsDataSource"
        >
          <!-- Discovery Workload Items -->
          <div *ngIf="lparLabeling">
            <ng-container matColumnDef="processors">
              <mat-header-cell
                class="mat-workload-header-cell"
                mat-sort-header
                *matHeaderCellDef
                >Processors</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.vmcpu }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="vmRam">
              <mat-header-cell
                class="mat-workload-header-cell"
                mat-sort-header
                *matHeaderCellDef
                >Main Storage (GB)</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.vmRam }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="osType">
              <mat-header-cell
                class="mat-workload-header-cell"
                mat-sort-header
                *matHeaderCellDef
                >OS Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.vmosType }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="osLevel">
              <mat-header-cell
                class="mat-workload-header-cell"
                mat-sort-header
                *matHeaderCellDef
                >OS Level</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.vmosLevel }}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="diskspace">
              <mat-header-cell
                class="mat-workload-header-cell"
                mat-sort-header
                *matHeaderCellDef
                >Disk Space</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{
                row.vmDiskSpace | number
              }}</mat-cell>
            </ng-container>
          </div>
          <div *ngIf="!lparLabeling">
            <ng-container matColumnDef="environment">
              <mat-header-cell
                class="mat-workload-header-cell"
                mat-sort-header
                *matHeaderCellDef
                >Environment</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.environment }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="vmId">
              <mat-header-cell
                class="mat-workload-header-cell"
                mat-sort-header
                *matHeaderCellDef
                >VM ID</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.vmId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="vmCores">
              <mat-header-cell
                class="mat-workload-header-cell"
                mat-sort-header
                *matHeaderCellDef
                >VM Cores</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.vmCores }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="vmRam">
              <mat-header-cell
                class="mat-workload-header-cell"
                mat-sort-header
                *matHeaderCellDef
                >VM Ram(GB)</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.vmRam }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="vmCpu">
              <mat-header-cell
                class="mat-workload-header-cell"
                mat-sort-header
                *matHeaderCellDef
                >VM CPU</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.vmcpu }}</mat-cell>
            </ng-container>
          </div>

          <!-- Mathced Cloud Products -->
          <ng-container matColumnDef="instanceType">
            <mat-header-cell
              class="mat-cloudproduct-header-cell"
              mat-sort-header
              *matHeaderCellDef
              >Instance Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{ row.instanceType }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="osSoftware">
            <mat-header-cell
              class="mat-cloudproduct-header-cell"
              mat-sort-header
              *matHeaderCellDef
              >OS/Software</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{ row.osSoftware }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="category">
            <mat-header-cell
              class="mat-cloudproduct-header-cell"
              mat-sort-header
              *matHeaderCellDef
              >Category</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{ row.category }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="vCPU">
            <mat-header-cell
              class="mat-cloudproduct-header-cell"
              mat-sort-header
              *matHeaderCellDef
              >vCPU</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{ row.vCPU }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="ram">
            <mat-header-cell
              class="mat-cloudproduct-header-cell"
              mat-sort-header
              *matHeaderCellDef
              >Ram(GB)</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{ row.ram }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="pricePerMonth">
            <mat-header-cell
              class="mat-cloudproduct-header-cell"
              mat-sort-header
              *matHeaderCellDef
              >Price/month($)</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{
              row.pricePerMonth | number: "1.3-3"
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="annualPrice">
            <mat-header-cell
              class="mat-cloudproduct-header-cell"
              mat-sort-header
              *matHeaderCellDef
              >Annual Price($)</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{
              row.annualPrice | number: "1.3-3"
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell
              class="mat-cloudproduct-header-cell"
              *matHeaderCellDef
              >Actions</mat-header-cell
            >
            <mat-cell *matCellDef="let row">
              <ng-container>
                <button id="btn-actions-{{row.id}}" mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button id="btn-update-match-{{row.id}}" mat-menu-item (click)="changeMatchedProduct(row)">
                    <mat-icon>search</mat-icon>
                    <span>Update Matched Product</span>
                  </button>
                </mat-menu>
              </ng-container>
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="cloudSolcolumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: cloudSolcolumns"></mat-row>
        </mat-table>
      </div>
      <mat-paginator
        #paginator
        [length]="cloudSolDetailsDataSource?.data?.length"
        [pageIndex]="0"
        [pageSize]="5"
        [pageSizeOptions]="[5, 25, 50, 100]"
      >
      </mat-paginator>
    </mat-expansion-panel>
  </div>
</fieldset>
