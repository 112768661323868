import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BusinessPartner } from './business-partner.model';
import { Observable, of } from 'rxjs';
export class BusinessPartnerDataSource extends DataSource<BusinessPartner> {
    data: Array<BusinessPartner>;
    connect(): Observable<BusinessPartner[]> {

        return of(this.data);
    }
    disconnect(collectionViewer: CollectionViewer): void {

    }
    setData(data: Array<any>) {
        this.data = data;
    }

}
