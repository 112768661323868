import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CloudMigrationToolService } from 'src/app/cloud-migration-tool/cloud-migration-tool.service';
import { ServiceEstimateSelectModel } from 'src/app/professional-services/service-estimates/_models/service-estimate-select-list.model';

@Component({
  selector: 'cloud-migration-workload-add',
  templateUrl: './cloud-migration-workload-add.component.html',
  styleUrls: ['./cloud-migration-workload-add.component.scss'],
})
export class CloudMigrationWorkloadAddComponent implements OnInit {
  @Input() workload: any;
  @Input() isLPAR: boolean = false;
  @Input() showAddAction: boolean = true;
  @Output() OnAdd: EventEmitter<any> = new EventEmitter();
  @Output() OnUpdated: EventEmitter<any> = new EventEmitter();

  wrkloadFormVM: UntypedFormGroup;
  wrkloadFormLPAR: UntypedFormGroup;
  operatingSystemTypes: any[];
  optimizationTypes: any[];
  environmentTypes: any[];
  virtualizationTypes: any[];
  showAdd: boolean = true;
  discoveryId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private cloudMigrationService: CloudMigrationToolService
  ) {}

  ngOnInit(): void {
    console.log("addeditcomponent workload",this.workload);
    console.log("addeditcomponent workload is LPAR",this.isLPAR);
    this.operatingSystemTypes = this.workload?.operatingSystemTypeOptions;
    this.optimizationTypes = this.workload?.optimizationTypeOptions;
    this.environmentTypes = this.workload?.environmentTypeOptions;
    this.virtualizationTypes = this.workload?.virtualizationTypeOptions;
    if (this.isLPAR) {
      this.wrkloadFormLPAR = this.createLPARFormGroup(this.workload);
      this.wrkloadFormLPAR.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((res) => {
          this.formUpdatedEvent(res);
        });
    } else {
      this.wrkloadFormVM = this.createVMFormGroup(this.workload);
      this.wrkloadFormVM.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe((res) => {
          this.formUpdatedEvent(res);
        });
    }
  }

  formUpdatedEvent(formValue: any) {
    const formData = formValue;
    this.OnUpdated.emit(formData);
  }

  createVMFormGroup(data: any): UntypedFormGroup {
    return this.fb.group({
      id: new UntypedFormControl(data?.id),
      environment: new UntypedFormControl(data?.environment),
      serverId: new UntypedFormControl(data?.serverId),
      processors: new UntypedFormControl(
        data?.processors ? data?.processors | 0 : 1,
        Validators.compose([Validators.required, Validators.min(1)])
      ),
      coresPerProcessor: new UntypedFormControl(
        data?.coresPerProcessor ? data?.coresPerProcessor | 0 : '',
        Validators.compose([Validators.required, Validators.min(1)])
      ),
      ram: new UntypedFormControl(data?.ram ? data?.ram | 0 : '', [
        Validators.required,
        Validators.min(1),
      ]),
      graphicsCardType: new UntypedFormControl(data?.graphicsCardType),
      optimizationType: new UntypedFormControl(data?.optimizationType),
      operatingSystemType: new UntypedFormControl(
        data?.operatingSystem,
        Validators.required
      ),
      environmentType: new UntypedFormControl(data?.environmentType),
      workloadType: new UntypedFormControl(data?.workloadType),
      // operatingSystemLicenseType: new UntypedFormControl(
      //   data?.operatingSystemType
      // ),
      virtualizationType: new UntypedFormControl(data?.virtualizationType),
      databaseType: new UntypedFormControl(data?.databaseType),
      isWindows2008: new UntypedFormControl(data?.isWindows2008),
      storageUtilizationInBytes: new UntypedFormControl(
        data?.storageUtilizationInBytes
      ),
      name: new UntypedFormControl(data?.name, Validators.required),
      operatingSystem: new UntypedFormControl(data?.operatingSystem),
      discoveryId: new UntypedFormControl(data?.discoveryId),
    });
  }

  createLPARFormGroup(data: any): UntypedFormGroup {
    return this.fb.group({
      id: new UntypedFormControl(data?.id),

      serverId: new UntypedFormControl(data?.name),
      processors: new UntypedFormControl(
        data?.processors ? data?.processors | 0 : '',
        Validators.compose([Validators.required, Validators.min(1)])
      ),
      // coresPerProcessor: new UntypedFormControl(
      //  data?.coresPerProcessor ? (data?.coresPerProcessor | 0) : ''
      // ),
      ram: new UntypedFormControl(data?.ram ? data?.ram | 0 : '', [
        Validators.required,
        Validators.min(2),
      ]),
      operatingSystemRelease: new UntypedFormControl(
        data?.operatingSystemRelease
      ),
      workloadType: new UntypedFormControl(data?.workloadType),
      // operatingSystemLicenseType: new UntypedFormControl(
      //   data?.operatingSystemType
      // ),
      name: new UntypedFormControl(data?.name, Validators.required),
      operatingSystemType: new UntypedFormControl(
        data?.operatingSystem,
        Validators.required
      ),
      // operatingSystem: new UntypedFormControl(data?.operatingSystem),
      totalDiskSpace: new UntypedFormControl(''),
      discoveryId: new UntypedFormControl(data?.discoveryId),
    });
  }

  addVmWorkload() {
    var formVal = this.wrkloadFormVM.value;
    console.log('formVal', formVal);

    let workloadData = {
      id: this.workload.id,
      environment: formVal.environment,
      serverId: formVal.serverId,
      processors: formVal.processors,
      coresPerProcessor: formVal.coresPerProcessor,
      ram: formVal.ram,
      graphicsCardType: formVal.graphicsCardType,
      optimizationType: formVal.optimizationType,
      operatingSystemType: formVal.operatingSystemType,
      environmentType: formVal.environmentType,
      workloadType: formVal.workloadType,
      operatingSystemLicenseType: formVal.operatingSystemLicenseType,
      virtualizationType: formVal.virtualizationType,
      databaseType: formVal.databaseType,
      isWindows2008: formVal.isWindows2008,
      storageUtilizationInBytes: formVal.storageUtilizationInBytes ?? 0,
      name: formVal.name,
      // operatingSystem: formVal.operatingSystem,
      discoveryId: formVal.discoveryId,
    };
    this.cloudMigrationService
      .saveDiscoveryWorkload(workloadData)
      .then((response) => {
        this.OnAdd.emit(response);
        this.wrkloadFormVM.reset();
      });
  }

  addLparWorkload() {
    var formVal = this.wrkloadFormLPAR.value;
    console.log('OS:', formVal.operatingSystemType);
    this.cloudMigrationService
      .saveDiscoveryWorkload(formVal)
      .then((response) => {
        console.log('response received: ', response);
        this.OnAdd.emit(response);
        this.wrkloadFormLPAR.reset();
      });
  }
}
