import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ProductSearchCriteria } from './cloud-provider-compute-product-list/cloud-provider-compute-product-list.component';

@Injectable({
  providedIn: 'root',
})
export class CloudMigrationToolAdminService {
  

  getMatchingProducts(searchCriteria: ProductSearchCriteria): Observable<any[]> {
    let url = `/api/cmtadmin/cloudproviders/products`;
    console.log('Product Search Criteria: ', searchCriteria)
    return this.http.post<any>(url, searchCriteria);
  }

  constructor(private http: HttpClient) {}

  saveCloudProvider(request: { cloudProviderId: any; payload: any }) {
    let url = `/api/cmtadmin/cloudproviders`;

    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, request).subscribe(
        (res) => {
          resolve({ data: res });
        },
        (error) => {
          resolve({ data: {} });
        }
      );
    });

    return promise;
  }

  
  getPricing(request: any): any {
    let url = `/api/cmtadmin/pricelpar`;
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, request).subscribe(res => {
        resolve({data: res});
      },(error) => {
          resolve({ data: {} });
      });
    });
    
    return promise;
  }

  saveCloudProviderPricingSchedule(request: any): any {

    console.log("request in service: ", request);
    let url = `/api/cmtadmin/cloudproviders/${request.cloudProviderId}/pricingSchedule`;

    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, request.payload).subscribe(
        (res) => {
          resolve({ data: res });
        },
        (error) => {
          resolve({ data: {} });
        }
      );
    });

    return promise;
  }

  getCloudProviders() {
    return this.http.get<any[]>(`/api/cmtadmin/cloudproviders`);
  }

  getCloudProvider(cloudProviderId: string): Observable<any> {
    let url = `/api/cmtadmin/cloudproviders/${cloudProviderId}`;
    return this.http.get<any>(url);
  }
}
