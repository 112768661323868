import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeListDataSource, EmployeeListItem } from './employee-list-datasource';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { GraphApiService } from '../../shared/services/graph-api.service';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { AccountInfo } from '@azure/msal-browser';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { forkJoin } from 'rxjs';
import { AdminService } from '../../admin/admin.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<EmployeeListItem>;
  userListDataSource: EmployeeListDataSource;
  loading: boolean;
  fullData: any[];
  currentUser: AccountInfo | null;
  appRoles: any;
  filterForm: UntypedFormGroup;

  displayedColumns = ['firstName', 'lastName', 'email', 'appRoleId', 'status', 'actions'];

  constructor(private graphApiService: GraphApiService, private fb: UntypedFormBuilder, private authService: MsalService, private adminService: AdminService) { this.loading = true; }

  ngOnInit(): void {
    this.currentUser = this.authService.instance.getActiveAccount();
    this.filterForm = this.fb.group({
      firstNameFilter: [],
      lastNameFilter: [],
      emailFilter: [],
      employeeStatusFilter: [],
      appRoleFilter: []
    });
    this.filterForm.valueChanges
      .subscribe(res => {
        this.userListDataSource = new EmployeeListDataSource(this.userListDataSource.filter(res, this.fullData));
        this.userListDataSource.sort = this.sort;
        this.userListDataSource.paginator = this.paginator;
        this.table.dataSource = this.userListDataSource;
      });
    this.getEmployeeData();
  }
  getEmployeeData() {
    let fetchAzAdEmployees = this.graphApiService.getUsers();
    let fetchUsers = this.graphApiService.getApplicationUsers();
    let fetchAppRoles = this.graphApiService.getApplicationRoles();
    let fetchEmployees = this.adminService.getAllEmployees();
    forkJoin([fetchAzAdEmployees, fetchUsers, fetchAppRoles, fetchEmployees]).subscribe(results => {
      const data = results[0];
      this.appRoles = results[2];
      let userData: Array<EmployeeListItem> = [];
      results[1].forEach(user => {
        let userIndex: number = userData.findIndex(x => x.principalId == user.principalId);
        let appRole = this.appRoles.find((x: any) => x.id == user.appRoleId);
        let emp = results[3].find(x => x.azureAdId == user.principalId);
        if (emp) {
          if (userIndex != -1) {
            userData[userIndex].appRoles.push(appRole ? appRole : { id: 0, displayName: 'Not Assigned' });
          } else if (appRole) {
            data.forEach(employee => {
              if (user.principalId == employee.id) {
                userData.push({ firstName: employee.givenName, lastName: employee.surname, email: employee.mail, appRoles: [appRole ? appRole : { id: 0, displayName: 'Not Assigned' }], id: user.id, principalId: user.principalId, softDelete: employee.accountEnabled })
              }
            })
          }
        }
      })
      this.fullData = userData;
      this.userListDataSource = new EmployeeListDataSource(userData);
      this.userListDataSource.sort = this.sort;
      this.userListDataSource.paginator = this.paginator;
      this.table.dataSource = this.userListDataSource;
    }, (error) => {

    }, () => {
      this.loading = false;
    });

  }
  getAppRoles(appRoles: any) {
    return appRoles.map((x: any)=>x.displayName).join(",");
  }
  resetFilters() {
    // Object.assign(this.filteredPartners, this.allPartners);
    this.filterForm.controls['firstNameFilter'].setValue('');
    this.filterForm.controls['lastNameFilter'].setValue('');
    this.filterForm.controls['emailFilter'].setValue('');
    this.filterForm.controls['employeeStatusFilter'].setValue('');
    this.filterForm.controls['appRoleFilter'].setValue('');

  }

}
