import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { GasCardDataSource } from '../../models/gas-card-datasource';
import { OilCardDataSource } from '../../models/oil-card-datasource';
import { Employee } from '../../../shared/models/employee';

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss']
})
export class ListCardComponent implements OnInit {
  gasCardsDataSource: GasCardDataSource;
  oilCardsDataSource: OilCardDataSource | null;
  employees: Array<Employee>;
  hasOilCard: boolean;
  displayedColumns = [
    'assignedEmployee',
    'cardNumber',
    'expirationDate',
    'cardId'
  ];
  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.adminService.getEmployees()
      .subscribe(employeeResponse => {
        this.employees = employeeResponse;
      });
    this.adminService.getCards()
      .subscribe(httpResult => {
        const data: any[] = httpResult;
        this.gasCardsDataSource = new GasCardDataSource();
        this.gasCardsDataSource.setData(data);

      });
    this.adminService.getOilCards()
      .subscribe(httpResult => {
        this.hasOilCard = true;
        const data: any[] = httpResult;
        this.oilCardsDataSource = new OilCardDataSource();
        this.oilCardsDataSource.setData(data);
      },
        (error) => {
          this.hasOilCard = false;
          this.oilCardsDataSource = null;
        });

  }
  deleteGasCard(id: string) {
    this.adminService.deleteCard(id)
      .subscribe(httpResult => {
        this.adminService.getCards()
          .subscribe(res => {
            const data: any[] = res;
            this.gasCardsDataSource = new GasCardDataSource();
            this.gasCardsDataSource.setData(data);
          });
      });
  }
  deleteOilCard(id: string) {
    this.adminService.deleteOilCard(id)
      .subscribe(httpResult => {
        this.adminService.getOilCards()
          .subscribe(res => {
            const data: any[] = res;
            this.oilCardsDataSource = new OilCardDataSource();
            this.oilCardsDataSource.setData(data);
          });
      });
  }
  filterEmployees(employeeId: string) {
    const _employee = this.employees.filter(employee => employee.employeeId === employeeId)[0];
    return `${_employee.firstName} ${_employee.lastName}`;
  }

}
