import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CloudMigrationToolService } from 'src/app/cloud-migration-tool/cloud-migration-tool.service';

@Component({
  selector: 'cloud-migration-environment-add',
  templateUrl: './cloud-migration-environment-add.component.html',
  styleUrls: ['./cloud-migration-environment-add.component.scss'],
})
export class CloudMigrationEnvironmentAddComponent implements OnInit {
  @Input() environment: any;
  @Input() showAdd: boolean = false;
  @Output() addEnvironmentEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editEnvironmentEvent: EventEmitter<any> = new EventEmitter<any>();

  environmentForm: UntypedFormGroup;
  environmentId: string;
  environmentTypeOptions: any = [];
  virtualizationTypeOptions: any = [];
  projectId: string;
  formTitle: string = 'Add Environment';
  discoveryEnvironment: any;
  constructor(
    private fb: UntypedFormBuilder,
    private cloudMigrationService: CloudMigrationToolService
  ) {}

  ngOnInit(): void {
    this.setState(this.environment);
    this.environmentForm.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe((res) => {
        this.formUpdatedEvent(res);
      });
  }
  setState(environment: any) {
    this.projectId = environment?.projectId;
    this.discoveryEnvironment = environment;
    this.environmentTypeOptions = environment?.environmentTypeOptions;
    this.virtualizationTypeOptions = environment?.virtualizationTypeOptions;
    this.environmentForm = this.createFormGroup(environment);
  }

  createFormGroup(data: any): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(data?.id),
      name: new UntypedFormControl(data?.name, Validators.required),
      projectId: new UntypedFormControl(this.projectId, Validators.required),
      environmentType: new UntypedFormControl(
        data?.environmentType,
        Validators.required
      ),

    });
  }

  addDiscoveryEnvironment() {
    this.cloudMigrationService
      .saveDiscoveryEnvironment(this.environmentForm.value)
      .then((response) => {
        this.addEnvironmentEvent.emit(response);
      });
  }

  formUpdatedEvent(formValue: any) {
    this.editEnvironmentEvent.emit(formValue);
  }
}
