import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApproverThresholdService {

  constructor(private http: HttpClient) { }
  createThreshold(threshold: any) {
    const body = JSON.stringify(threshold);
    return this.http.post(`/api/ApproverThresholds`, body);
  }
  updateThreshold(threshold: any) {
    const body = JSON.stringify(threshold);
    return this.http.put(`/api/ApproverThresholds/${threshold.id}`, body);
  }
  getThresholds() {

    return this.http.get(`/api/ApproverThresholds`);
  }
  getThreshold(id: number) {

    return this.http.get<any>(`/api/ApproverThresholds/${id}`);
  }
  deleteThreshold(id: string) {
    return this.http.delete(`/api/ApproverThresholds/${id}`);
  }
  addThreshServiceRole(obj: any) {
    console.log(obj);
    const body = JSON.stringify(obj);
    return this.http.post(`/api/ApproverThresholds/ApproverThreshHoldsServiceRoles`, body);
  }
  removeThreshServiceRole(id: any, serviceRoleId: any) {
    return this.http.delete(`/api/ApproverThresholds/${id}/ApproverThreshHoldsServiceRoles/${serviceRoleId}`);
  }

}
