import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { HttpCacheService } from '../services/http-cache.service';
import { UrlCacheWhiteList } from '../url-cache-whitelist';
import { MsalService } from '@azure/msal-angular';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ApplicationInsightsService } from '../services/telemetry.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(
    private httpCacheService: HttpCacheService,
    private authService: MsalService,
    private loaderService: LoadingService,
    private appInsights: ApplicationInsightsService
  ) {}

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = req.headers;
    const start = new Date().getTime();

    if (req.url.includes('https://dc.services.visualstudio.com/v2/track')) {
    } else {
      this.requests.push(req);

      this.loaderService.isLoading.next(true);
    }

    let url: string;
    let contentType = req.detectContentTypeHeader();
    if (contentType) {
      if (!contentType.includes('form')) {
        headers = req.headers.set('Content-Type', 'application/json');
      } else {
      }
    } else {
    }
    if (req.url.includes('api')) {
      url = environment.apiEndPoint + req.url;
    } else {
      url = req.url;
    }
    if (req.url.includes('graph.micro')) {
      headers = headers.set('ConsistencyLevel', 'eventual');
    }
    const authReq = req.clone({ headers, url }); // pass along non-cacheable requests and invalidate cache
    if (authReq.method !== 'GET') {
      console.log(`Invalidating cache: ${authReq.method} ${authReq.url}`);
      this.httpCacheService.invalidateCache();

      return next.handle(authReq).pipe(
        tap((event) => {
          const end = new Date().getTime();
          const duration = end - start;
          // this.appInsights.trackEvent({
          //   name: 'HttpRequestSuccess',
          //   properties: {
          //     url: authReq.url,
          //     duration: duration.toString(),
          //     method: authReq.method,
          //   },
          // });
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
          }
        })
      );
    }

    // attempt to retrieve a cached response
    const cachedResponse: HttpResponse<any> | undefined | null =
      this.httpCacheService.get(authReq.url);

    // return cached response
    if (cachedResponse) {
      console.log(`Returning a cached response: ${cachedResponse.url}`);
      return of(cachedResponse);
    }
    return next.handle(authReq).pipe(
      tap((event) => {
        const end = new Date().getTime();
        const duration = end - start;
        // this.appInsights.trackEvent({
        //   name: 'HttpRequestSuccess',
        //   properties: {
        //     url: authReq.url,
        //     duration: duration.toString(),
        //     method: authReq.method,
        //   },
        // });
        if (event instanceof HttpResponse) {
          this.removeRequest(req);
          if (UrlCacheWhiteList.includes(authReq.url)) {
            this.httpCacheService.put(authReq.url, event);
          }
          if (event.url?.includes(environment.authSettings.authority)) {
            if (event.status === 401) {
              this.authService.logout();
            }
          }
          if (event.status === 401) {
            console.log(event.status);
            this.authService.logout();
          }
        }
      }),
      catchError((errorResponse: HttpErrorResponse): Observable<any> => {
        let errorMessage = 'There is a problem with the service.';
        if (errorResponse.error instanceof ErrorEvent) {
          console.error('Client side error: ', errorResponse.error.message);
        } else {
          console.error('Server side error: ', errorResponse);
        }
        const end = new Date().getTime();
        const duration = end - start;
        // this.appInsights.trackEvent({
        //   name: 'HttpRequestError',
        //   properties: {
        //     url: authReq.url,
        //     duration: duration.toString(),
        //     method: authReq.method,
        //     error: errorResponse.error.message
        //   }
        // });
        return throwError(errorMessage);
      })
    );
  }

}
