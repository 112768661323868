import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable()
export class ServiceEstimatorService {

  constructor(private http: HttpClient) { }

  getServiceEstimates() {
    return this.http.get(`/api/solutions/serviceEstimates`);
  }
  getServiceEstimate(serviceId: string) {
    return this.http.get(`/api/solutions/serviceEstimates/${serviceId}`);
  }
  createServiceEstimate(model: any) {
    const body = JSON.stringify(model);
    return this.http.post('/api/solutions/serviceEstimates', body);
  }
  deleteServiceEstimate(serviceId: string) {
    return this.http.delete(`/api/Solutions/ServiceEstimates/${serviceId}`);
  }
  getServiceEstimateDetails(serviceEstimateId: string) {
    return this.http.get(`/api/soultions/serviceestimates/${serviceEstimateId}/detailItems`);
  }
  lockServiceEstimate(id: string) {
    return this.http.put(`/api/solutions/serviceestimates/${id}/lock`, null);
  }
  unlockServiceEstimate(id: string) {
    return this.http.put(`/api/solutions/serviceestimates/${id}/unlock`, null);
  }
  updateServiceEstimate(serviceEstimate: any, id: string) {
    const body = JSON.stringify(serviceEstimate);
    return this.http.put(`/api/solutions/serviceestimates/${id}`, body);
  }
  deleteServiceEstimateDetailItem(id: string) {
    return this.http.delete(`/api/PhaseItems/${id}`);
  }
  deleteServiceEstimateDetailItemTemplate(id: string) {
    return this.http.delete(`/api/TemplatePhaseItem/${id}`);
  }
  deleteServiceEstimateDetailItemChangeOrder(id: string) {
    return this.http.delete(`/api/ChangeOrderPhaseItem/${id}`);
  }
  deleteBusinessPartnerItemById(id: string) {
    return this.http.delete(`/api/BusinessPartnerItems/${id}`);
  }
  deleteTravelExpenseItem(id: string) {
    return this.http.delete(`/api/TravelExpenses/${id}`);
  }
  deleteSkuItem(id: string) {
    return this.http.delete(`/api/SkuItems/${id}`);
  }
  deleteTemplateSkuItem(id: string) {
    return this.http.delete(`/api/TemplateSkuItems/${id}`);
  }
  downloanComponentActivities(componentId:string){
    return this.http.get(`/api/ServiceEstimateComponents/${componentId}/activities`);
  }
}
