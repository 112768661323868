import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SupportService {

  constructor(private http: HttpClient) { }
  sendSupportEmail(msg: any) {
    const body = JSON.stringify(msg);
    return this.http.post(`/api/supportrequests`, body);
  }
  saveIncidentReports(data: any) {
    let timeOfIncident: any;
    timeOfIncident = new Date(0);
    timeOfIncident.setHours(data.timeOfIncident.split(":")[0]);
    timeOfIncident.setMinutes(data.timeOfIncident.split(":")[1]);
    data.timeOfIncident = timeOfIncident.toLocaleTimeString('en-US', { hour12: false });
    const body = JSON.stringify(data);
    return this.http.post(`/api/IncidentReports`, body);
  }
}
