import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attach-maintenance-schedule',
  templateUrl: './attach-maintenance-schedule.component.html',
  styleUrls: ['./attach-maintenance-schedule.component.scss']
})
export class AttachMaintenanceScheduleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
