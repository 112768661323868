<button
  id="btn-close-icon"
  mat-icon-button
  class="close-button"
  (click)="close()"
>
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h3
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  {{ formTitle }}
</h3>
<mat-card>
  <mat-dialog-content
    id="dlg-pricing-schedule" *ngIf="pricingScheduleForm && hostedSystems"
    [formGroup]="pricingScheduleForm"
  >
    <div>
      <fieldset>
        <legend>Pricing</legend>
        <div class="container">
          <div class="row"><h4>Service Description</h4></div>
          <div class="row"> 
            <mat-form-field>
              <input
                matInput
                formControlName="serviceName"
                placeholder="Service Name"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['serviceName'].hasError(
                    'required'
                  )
                "
              >
                Service Name is required
              </mat-error>
            </mat-form-field>              
            <mat-form-field>
              <mat-label>Hosted System</mat-label>
              <mat-select formControlName="hostedSystem">
                <mat-option
                  *ngFor="let hostedSystem of hostedSystems"
                  [value]="hostedSystem.value"
                >
                  {{ hostedSystem.text }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['hostedSystem'].hasError(
                    'required'
                  )
                "
              >
                Hosted System is required
              </mat-error>
            </mat-form-field>

          
            <mat-form-field>
              <input
                matInput
                formControlName="productCode"
                placeholder="Product/Part#/SKU"
                style="width: 230px;"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['productCode'].hasError(
                    'required'
                  )
                "
              >
                Product Code is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row"><h4>Service Costs</h4></div>
          <div class="row">
            <mat-form-field>
              <input
                matInput
                formControlName="partitionCosts"
                placeholder="Partition Costs"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['partitionCosts'].hasError(
                    'required'
                  )
                "
              >
                Partition Costs is required
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                formControlName="hostingLaborCosts"
                placeholder="Hosting Labor Costs"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['hostingLaborCosts'].hasError(
                    'required'
                  )
                "
              >
                Hosting Labor Costs is required
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                formControlName="dRlaborCosts"
                placeholder="DR Costs"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['dRlaborCosts'].hasError(
                    'required'
                  )
                "
              >
                DR Costs is required
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                formControlName="haLaborCosts"
                placeholder="HA Labor Costs"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['haLaborCosts'].hasError(
                    'required'
                  )
                "
              >
                HA Labor Costs is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <input
                matInput
                formControlName="setUpCosts"
                placeholder="Setup Costs"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['setUpCosts'].hasError(
                    'required'
                  )
                "
              >
                Setup Costs is required
              </mat-error>
            </mat-form-field>
          </div>
 <div class="row"><h4>Configuration Item Costs</h4></div>
          <div class="row">
            <mat-form-field>
              <input
                matInput
                formControlName="diskUnitPrice"
                placeholder="Disk Unit Price"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['diskUnitPrice'].hasError(
                    'required'
                  )
                "
              >
                Disk Unit Price is required
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                formControlName="processingUnitPrice"
                placeholder="Processor Unit Price"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['processingUnitPrice'].hasError(
                    'required'
                  )
                "
              >
                ProcessingUnitPrice is required
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                formControlName="memoryUnitPrice"
                placeholder="Memory Unit Price"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['memoryUnitPrice'].hasError(
                    'required'
                  )
                "
              >
                memoryUnitPrice is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            

            <mat-form-field>
              <input
                matInput
                formControlName="v5R4GpRate"
                placeholder="v5R4 Gp Rate"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['v5R4GpRate'].hasError(
                    'required'
                  )
                "
              >
              v5R4 Gp Rate is required
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                formControlName="v6R1GpRate"
                placeholder="V6R1 Gp Rate"
              />
              <mat-error
                *ngIf="
                  pricingScheduleForm.controls['v6R1GpRate'].hasError(
                    'required'
                  )
                "
              >
              V5R1 Gp Rate is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row"><h4>Target Percent by Term</h4></div>
          <div class="row" >
            <mat-form-field *ngFor="let term of terms">
              <input matInput
              formControlName="year{{term.value}}GpPercentPercent"
              placeholder="{{term.value}} Year Target GP %"/>
            </mat-form-field>
          </div>
        </div>
      </fieldset>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      id="btn-save" type="submit"
      mat-raised-button
      mat-primary
      [disabled]="!pricingScheduleForm?.valid"
      (click)="save()"
    >
      Save
    </button>
    <button id="btn-close" class="mat-raised-button" (click)="close()">Close</button>
  </mat-dialog-actions>
</mat-card>
