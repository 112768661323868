import { Component, OnInit } from '@angular/core';
import { ManagedServicesProviderService } from "./managed-services-provider.service";

@Component({
  selector: 'app-managed-services-provider',
  templateUrl: './managed-services-provider.component.html',
  styleUrls: ['./managed-services-provider.component.scss']
})
export class ManagedServicesProviderComponent implements OnInit {
  phases: Array<string>;
  
  constructor(private service: ManagedServicesProviderService) { }

  ngOnInit() {
    this.phases = this.service.phases;
  }

}
