import { Component, OnInit } from '@angular/core';

import { FleetMangementService } from '../../../fleet-mangement.service';

import { VehicleRegistration } from '../../../models/fleet-management-models';

@Component({
  selector: 'app-vehicle-registration-create',
  templateUrl: './vehicle-registration-create.component.html',
  styleUrls: ['./vehicle-registration-create.component.scss']
})
export class VehicleRegistrationCreateComponent implements OnInit {
  model: VehicleRegistration;
  constructor(private service: FleetMangementService) { }

  ngOnInit() {
    if (this.model) {

    } else {
      this.model = new VehicleRegistration();
    }
  }
  onSubmit() {
    this.service.createVehicleRegistration(this.model);
  }

}
