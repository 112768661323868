<mat-divider></mat-divider>
<form [formGroup]="formGroup" *ngIf="serviceRoles" (ngSubmit)="submitForm()">
  <mat-form-field>
    <mat-label>Choose a Start Date</mat-label>
    <input matInput [matDatepicker]="startDate" formControlName="startDate">
    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Choose a End Date</mat-label>
    <input matInput [matDatepicker]="endDate" formControlName="endDate">
    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
    <mat-datepicker #endDate></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-select placeholder="Select a Service Role" formControlName="serviceRoleId" disableOptionCentering>
      <mat-option *ngFor="let serviceRole of serviceRoles" [value]="serviceRole.serviceRoleId">
        {{serviceRole.serviceRoleName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox formControlName="signedContractOnly">Signed Contract Only?</mat-checkbox>
  <button mat-raised-button [disabled]="!formGroup.valid" type="submit" color="primary">Run Report</button>
  <button mat-raised-button [disabled]="!formGroup.valid" type="button" color="primary" (click)="onReportComplete()">Update Table</button>
</form>
<app-pivot #pivot1 [toolbar]="true" [width]="'100%'" [height]="500" [customizeCell]="onCustomizeCell"
           (ready)="onPivotReady($event)" (reportcomplete)="onReportComplete()"></app-pivot>
<a #donwloadCsvLink></a>
