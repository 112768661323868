import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
export declare const EMPTY_GUID: string;


@Component({
  selector: 'app-cloud-migration-discovery-networkprofile',
  templateUrl: './cloud-migration-discovery-networkprofile.component.html',
  styleUrls: ['../../../dialog.scss','./cloud-migration-discovery-networkprofile.component.scss'],
  
})
export class CloudMigrationDiscoveryNetworkprofileComponent implements OnInit {
  formTitle: string = 'Generate Network Profile';
  networkprofileRequestForm: UntypedFormGroup;
  networkprofileId: string;
  networkprofile: any;
  discoveryId: string;
  networkDataId: string;
  networkDataRTT: string;
  networkDataPacketLoss: string;
  projectId: any;
  defaultTCPWindowSize: number =  65535;
  defaultWindowScalingExponent: number =  2;
  defaultEthernetHeaderBytes: number =  0;
  defaultIpHeaderBytes: number =  20;
  defaultTCPHeaderBytes: number =  20;
  defaultVPNHeaderBytes: number =  0;
  message: string;
  value: string = 'value';
  title: string = 'title';
  profileGenerated: any;

  constructor(private cloudMigrationService: CloudMigrationToolService, private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CloudMigrationDiscoveryNetworkprofileComponent>,
    private router: Router, private activeRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit(): void {

    let formData = this.data;
    this.networkprofileRequestForm = this.createFormGroup(formData);
    this.discoveryId = this.data?.discoveryId;
    this.networkDataRTT = this.data?.delay;
    this.networkDataPacketLoss = this.data?.loss;
    this.activeRoute.parent?.params.subscribe(rp => {
      this.projectId = rp['id'] ?? '';
    });
  }

  createFormGroup(data: any): UntypedFormGroup {
    if (data) {
      return this.fb.group({
        discoveryId: new UntypedFormControl(data?.discoveryId),
        networkDataId: new UntypedFormControl(data?.networkDataId),
        bandwidth: new UntypedFormControl("", Validators.required),
        tCPWindowSize: new UntypedFormControl(this.defaultTCPWindowSize, Validators.required),
        windowScalingExponent: new UntypedFormControl(this.defaultWindowScalingExponent, Validators.required),
        ethernetHeaderBytes: new UntypedFormControl(this.defaultEthernetHeaderBytes, Validators.required),
        ipHeaderBytes: new UntypedFormControl(this.defaultIpHeaderBytes, Validators.required),
        tCPHeaderBytes: new UntypedFormControl(this.defaultTCPHeaderBytes, Validators.required),
        vPNHeaderBytes: new UntypedFormControl(this.defaultVPNHeaderBytes, Validators.required),
        migrationDataSizeInTerabytes:  new UntypedFormControl("", Validators.required),
        estimatedLossPercentage:  new UntypedFormControl(this.data?.loss, Validators.required),
        // measuredDelay:  new FormControl(this.data?.delay, Validators.required),
        measuredDelay:  new UntypedFormControl({ value: this.data?.delay, disabled: true }),
      })
    }
    return this.fb.group({
        discoveryId:   [""],
        networkDataId: [""],
        bandwidth: [""],
        tCPWindowSize:                [""],
        windowScalingExponent:        [""],
        ethernetHeaderBytes:          [""],
        ipHeaderBytes:                [""],
        tCPHeaderBytes:               [""],
        vPNHeaderBytes:               [""],
        migrationDataSizeInTerabytes: [""],
        estimatedLossPercentage: [""],
    })
  }

  save() {
      let request = {"discoveryId": this.discoveryId, "payload": this.networkprofile};
        this.cloudMigrationService.saveNetworkprofile(request)
      .then((res:any)=>{
        this.networkprofile = res.data.result;
        this.message = res.data.message;
      });
  }

  run(){
    var formVal = this.networkprofileRequestForm.value;
    console.log("Assessment Form Value: ", formVal);

    var data = {
		'discoveryId':   						formVal.discoveryId,   				
        'networkDataId': 				        formVal.networkDataId, 				
        'bandwidth': 					        formVal.bandwidth, 					
        'tCPWindowSize':                        formVal.tCPWindowSize,                
        'windowScalingExponent':                formVal.windowScalingExponent,        
        'ethernetHeaderBytes':                  formVal.ethernetHeaderBytes,          
        'ipHeaderBytes':                        formVal.ipHeaderBytes,                
        'tCPHeaderBytes':                       formVal.tCPHeaderBytes,               
        'vPNHeaderBytes':                       formVal.vPNHeaderBytes,               
        'migrationDataSizeInTerabytes':         formVal.migrationDataSizeInTerabytes,     
        'estimatedLossPercentage':         formVal.estimatedLossPercentage,    
      };


    this.cloudMigrationService.generateNetworkprofile(data)
      .then((res:any)=>{
        this.networkprofile = res.data.result;
        this.message = res.data.message;
        this.profileGenerated = res.data.success;
        
      });
  }

  close() {
    this.dialogRef.close(this.networkprofileRequestForm.value);
  }
}
