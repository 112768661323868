import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FleetMangementService } from '../../fleet-mangement.service';

@Component({
  selector: 'app-registration-expiration',
  templateUrl: './registration-expiration.component.html',
  styleUrls: ['./registration-expiration.component.scss']
})
export class RegistrationExpirationComponent implements OnInit {
  startDate: Date;
  endDate: Date;
  reportResult: any;
  noRecordsError: boolean;
  @ViewChild('donwloadCsvLink', { static: true }) donwloadCsvLink: ElementRef;
  constructor(private fleetService: FleetMangementService) { }

  ngOnInit() {
    this.noRecordsError = false;
  }
  getReport() {
    this.fleetService.getVehicleRegistrationExpirationReport(this.startDate, this.endDate)
      .subscribe(res => {
          this.reportResult = res;
          if (this.reportResult.length > 0) {
            this.noRecordsError = false;
            const fileText = this.fleetService.convertToCsv(JSON.stringify(this.reportResult));
            const blob = new Blob([fileText], {
              type: 'text/csv'
            });
            const url = window.URL.createObjectURL(blob);
            const link = this.donwloadCsvLink.nativeElement;
            link.href = url;
            link.download = 'registrationExpirationReport.csv';
            link.click();
            window.URL.revokeObjectURL(url);
          } else {
            this.noRecordsError = true;
          }
        
      });
  }
  getMinDate() {
    return this.startDate;
  }
  getMaxDate() {
    return this.endDate;
  }

}
