<form #form="ngForm" (ngSubmit)="submitIssue()">

  <mat-form-field>
    <mat-select [(ngModel)]="supportIssue.levelOfSupport" placeholder="Level of Support" name="supportLevel" disableOptionCentering>
      <mat-option [value]="0">Critical</mat-option>
      <mat-option [value]="1">Enhancement</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field>
    <textarea matInput [(ngModel)]="supportIssue.description" placeholder="Issue Detail" name="description"></textarea>
  </mat-form-field>
  <br />
  <button mat-raised-button [disabled]="form.submitted" color="primary" type="submit">Submit</button>
</form>
