import { Component, OnInit } from '@angular/core';
import { MileageEmployeeDataSource } from '../../models/mileage-employee-datasource';
import { FleetMangementService } from '../../fleet-mangement.service';

@Component({
  selector: 'app-milage-table',
  templateUrl: './milage-table.component.html',
  styleUrls: ['./milage-table.component.scss']
})
export class MilageTableComponent implements OnInit {
  displayedColumns: Array<string>;
  mileageDataSource: MileageEmployeeDataSource;
  constructor(private fleetService: FleetMangementService) { }

  ngOnInit() {
    this.fleetService.getAzureId().subscribe(aId => {
      this.fleetService.getMilageByEmployeeId(aId as string)
        .subscribe(res => {
          this.setupDataSource(res);

        });
    });
    this.displayedColumns = [
      'vehicleNumber',
      'milesStart',
      'milesEnd',
      'mileageDate'
    ];
  }
  setupDataSource(data: any) {
    this.mileageDataSource = new MileageEmployeeDataSource();
    this.mileageDataSource.setData(data);
  }

}
