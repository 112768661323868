<mat-card>
  <mat-card-content>
    <div class="row">
      <div class="col-md-12">
        <mat-checkbox formControlName="businessPartnerTask.isAgreementSent">Is Agreement Sent?</mat-checkbox>
      </div>
      <div class="col-md-12">
        <mat-checkbox formControlName="businessPartnerTask.receivedSignedAgreement">Received Signed Agreement?</mat-checkbox>
      </div>
      <div class="col-md-12">
        <mat-checkbox formControlName="businessPartnerTask.receivedW9">Received W9?</mat-checkbox>
      </div>
      <div class="col-md-12">
        <mat-checkbox formControlName="businessPartnerTask.receivedCOI">Received COI?</mat-checkbox>
      </div>
    </div>
  </mat-card-content>
</mat-card>