import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appStepCount]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: StepCountDirective,
    multi: true
  }]

})
export class StepCountDirective implements Validator {

  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } | null {

    let rem = control.value && Number.parseFloat(control.value) % 0.25;
    if (rem) {
      return {
        'stepCount': "Please enter the Valid value, only an increment of .25 allowed"
      };
    }
    else
    return null;
  }

}
