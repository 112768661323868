<mat-card>
  <mat-card-content>
    <div>
      <mat-form-field>
        <mat-select placeholder="Select a Report" [formControl]="reportOption" disableOptionCentering>
          <mat-option *ngFor="let report of reports" [value]="report.id"> {{report.name}} </mat-option>
        </mat-select>
      </mat-form-field>
      
    </div>
    <div [ngSwitch]="getReportOption()">
      <!-- <ng-template ngSwitchCase="0">
        <app-expense-per-mile></app-expense-per-mile>
      </ng-template> -->
      <ng-template ngSwitchCase="1">
        <app-registration-expiration></app-registration-expiration>
      </ng-template>
      <ng-template ngSwitchCase="2">
        <app-mileage></app-mileage>
      </ng-template>
      <!-- <ng-template ngSwitchCase="3">
        <app-vehicle-expense-report></app-vehicle-expense-report>
      </ng-template> -->
      <ng-template ngSwitchCase="4">
        <app-vehicle-aging-report></app-vehicle-aging-report>
      </ng-template>
      <ng-template ngSwitchCase="5">
        <app-audit-report></app-audit-report>
      </ng-template>
    </div>
  </mat-card-content>
</mat-card>
