import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ServiceEstimateComponentService } from './service-estimate-component.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
//import { ServiceEstimateDataSource } from '../../../professional-services/models/service-estimate.datasource';
import { MatSort } from '@angular/material/sort';
import { AdminService } from '../../../admin/admin.service';
import { ProjectsDataSource } from '../../../professional-services/models/professional-services-projects.datasource';
import { animate, style, trigger, transition, state } from '@angular/animations';
import { ServiceRoleService } from '../../../admin/service-role/service-role.service';
import { BusinessPartnerService } from '../../../business-partners/business-partner.service';
import { ProjectPhase } from '../../../professional-services/models/service-estimate';

@Component({
  templateUrl: './service-estimate-component-popup.component.html',
  styleUrls: ['./service-estimate-component-popup.component.scss']
})
export class ServiceEstimateComponentPopupComponent implements OnInit {

  serviceEstimateComponents: Array<any>;
  displayedColumns: Array<string>;
  dataSource: ProjectsDataSource;
  secId: string;
  isDateAscending: boolean;
  isNameAscending: boolean;
  pageSize: number;
  pagination: any;
  employees: any[];
  componentName: string;
  expandedElement: any;
  historyItem: any;
  createdBy: any;
  createdDate: any;
  oldValue: any;
  updatedValue: any;
  changes: any;
  addedPhaseItems: any;
  solutionPilars: any;
  deletedPhaseItems: any;
  changedPhaseItems: any;
  changeItems: any;
  serviceRoles: any;
  engagementType: any;

  constructor(public dialogRef: MatDialogRef<ServiceEstimateComponentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serviceEstimateComponentService: ServiceEstimateComponentService,
    private serviceRolesService: ServiceRoleService,
    private businessPartnerService: BusinessPartnerService,
    private adminService: AdminService) {
    this.secId = data.secId;
    this.componentName = data.componentName;
    this.createdBy = data.createdBy;
    this.createdDate = data.createdDate;
  }

  ngOnInit(): void {
    this.pageSize = 5;
    this.isDateAscending = false;
    this.isNameAscending = true;
    this.changes = [];
    this.engagementType = ['Professional Services - Implementations, Installations, Upgrades', 'Managed Services', 'Consulting Services - Technical Information, Instruction without touching environments'];
    this.displayedColumns = [
      'componentName',
      'dateModified',
      'createdBy',
      'lastEditedBy',
      'actions'
    ];
    this.serviceRolesService.getServiceRoles()
      .subscribe(res => {
        this.serviceRoles = res;
      });
    this.businessPartnerService.getSolutionPilars()
      .subscribe(pilarResult => {
        this.solutionPilars = pilarResult;
      });
    this.getEmployees();
    this.getTableData();
  }

  getTableData() {
    this.serviceEstimateComponentService.getComponentHistory(this.secId)
      .subscribe(res => {
        this.serviceEstimateComponents = res;
        this.sortByDate();
        this.setTableData();
      });

  }

  getEmployees() {
    this.adminService.getEmployees()
      .subscribe(httpResult => {
        this.employees = httpResult;
      });
  }
  setTableData(data?: Array<any>) {
    this.dataSource = new ProjectsDataSource();
    let pageAmount = 5;
    if (!data) {
      if (this.pagination) {
        pageAmount = this.pagination.pageSize;
      }
      const initData = this.serviceEstimateComponents.slice(0, pageAmount);
      this.dataSource.setData(initData);
    } else {
      this.dataSource.setData(data);
    }
  }
  pageChangedEventUsages(event: any) {
    this.pagination = event;
    this.pageSize = event.pageSize;
    const pagedData = this.serviceEstimateComponents.slice(event.pageIndex * event.pageSize, (event.pageIndex * event.pageSize) + event.pageSize);
    this.setTableData(pagedData);

  }
  sortByProjectName() {
    this.isNameAscending = !this.isNameAscending;
    if (this.isNameAscending) {
      this.setTableData(this.serviceEstimateComponents.sort((a, b) => {
        const nameOne = a.componentName;
        const nameTwo = b.componentName;
        if (nameOne < nameTwo) { return -1; }
        if (nameOne > nameTwo) { return 1; }
        return 1;
      }).slice(0, this.pageSize));
    } else {
      this.setTableData(this.serviceEstimateComponents?.sort((a, b) => {
        const nameOne = a.componentName;
        const nameTwo = b.componentName;
        if (nameOne < nameTwo) { return 1; }
        if (nameOne > nameTwo) { return -1; }
        return 1;
      })?.slice(0, this.pageSize));
    }
  }
  sortByDate() {
    this.isDateAscending = !this.isDateAscending;
    if (this.isDateAscending) {
      this.setTableData(this.serviceEstimateComponents.sort((a, b) => {
        const dateOne: any = new Date(a.createdDate);
        const dateTwo: any = new Date(b.createdDate);
        if (dateOne < dateTwo) { return -1; }
        if (dateOne > dateTwo) { return 1; }
        return 1;
      }).slice(0, this.pageSize));
    } else {
      this.setTableData(this.serviceEstimateComponents.sort((a, b) => {
        const dateOne: any = new Date(a.createdDate);
        const dateTwo: any = new Date(b.createdDate);

        if (dateOne < dateTwo) { return 1; }
        if (dateOne > dateTwo) { return -1; }
        return 1;
      }).slice(0, this.pageSize));
    }
  }
  getHistoryItem(id: any) {
    this.addedPhaseItems = [];
    this.deletedPhaseItems = [];
    this.changedPhaseItems = [];
    this.changeItems = [];
    this.serviceEstimateComponentService.getComponentHistoryItem(id).subscribe(res => {
      this.historyItem = this.serviceEstimateComponents.find(x => x.id == id);
      this.oldValue = JSON.parse(this.historyItem.oldValue);
      this.updatedValue = JSON.parse(this.historyItem.updatedValue);
      this.changes = res;
      this.getChanges();
    });
  }
  viewDetails(element: any) {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.expandedElement ? this.getHistoryItem(element.id) : '';
  }
  getChanges() {
    this.changes.filter((x: any) => x.id).forEach((item: any) => {

      switch (item.status.toLowerCase()) {
        case "added":
          let addValue = this.updatedValue[item.key].find((x: any) => x.Id == item.id);
          if (item.key.toLowerCase() == "businesspartneritems") {
            addValue.BusinessPartner = addValue.BusinessPartner.BusinessPartnerLegalName;
            addValue.SolutionPilar = this.getPillarValue(addValue.SolutionPilarId);
          } else if (item.key.toLowerCase() == "phaseitems") {
            addValue.ServiceRole = this.getServiceRole(addValue.ServiceRoleId);
            addValue.Phase = this.getPhase(addValue.Phase);
          } else if (item.key.toLowerCase() == "skuitems") {
              addValue.SolutionPilar = this.getPillarValue(addValue.SolutionPilarId);
          } else if (item.key.toLowerCase() == "serviceestimatecomponenttags") {
            addValue = { 'Tag': addValue.Tag.Name };
          }
          this.addedPhaseItems.push(addValue);
          break;
        case "deleted":
          let delValue = this.oldValue[item.key].find((x: any) => x.Id == item.id);
          if (item.key.toLowerCase() == "businesspartneritems") {
            delValue.BusinessPartner = delValue.BusinessPartner.BusinessPartnerLegalName;
            delValue.SolutionPilar = this.getPillarValue(delValue.SolutionPilarId);
          } else if (item.key.toLowerCase() == "phaseitems") {
            delValue.ServiceRole = this.getServiceRole(delValue.ServiceRoleId);
            delValue.Phase = this.getPhase(delValue.Phase);
          } else if (item.key.toLowerCase() == "skuitems") {
            delValue.SolutionPilar = this.getPillarValue(delValue.SolutionPilarId);
          } else if (item.key.toLowerCase() == "serviceestimatecomponenttags") {
            delValue = {'Tag': delValue.Tag.Name};
          }
          this.deletedPhaseItems.push(delValue);
          break;
      }
    }, this);
    let items = this.changes.filter((x: any) => x.id && x.status.toLowerCase() == "changed").reduce(function (r: any, a: any) {
      if (a.key.toLowerCase() != "serviceestimatecomponenttags") {
        r[a.id] = r[a.id] || [];
        r[a.id].push(a);
      }
      return r;
    }, Object.create(null));
    Object.keys(items).forEach((key) => {
      let changeProps: any[] = [];
      items[key].forEach((item: any) => {
        changeProps.push(item.property);
      }, this)
      let oldItem = this.oldValue[items[key][0].key].find((x: any) => x.Id == key);
      let newItem = this.updatedValue[items[key][0].key].find((x: any) => x.Id == key);
      let oldObj: any = {};
      let newObj: any = {};
      if (!changeProps.map(p => p.toLowerCase()).includes("activity") && (items[key].findIndex((p: any) => p.key.toLowerCase() == "materialitems") == -1 && items[key].findIndex((p: any) => p.key.toLowerCase() == "travelexpenses") == -1)) {
        changeProps.push('Activity');
      }
      changeProps.forEach(prop => {
        if (prop.toLowerCase() == "businesspartner") {
          oldObj[prop] = oldItem[prop].BusinessPartnerLegalName;
          newObj[prop] = newItem[prop].BusinessPartnerLegalName;
        } else if (prop.toLowerCase() == "solutionpilarid") {
          oldObj['SolutionPilar'] = this.getPillarValue(oldItem[prop]);
          newObj['SolutionPilar'] = this.getPillarValue(newItem[prop]);
        } else if (prop.toLowerCase() == "phase") {
          oldObj[prop] = this.getPhase(oldItem[prop]);
          newObj[prop] = this.getPhase(newItem[prop]);
        } else {
          oldObj[prop] = oldItem[prop];
          newObj[prop] = newItem[prop];
        }
      })
      this.changedPhaseItems.push({ key: key, oldValue: [oldObj], updatedValue: [newObj] });

    }, this);
    this.changes.filter((x: any) => !x.id).forEach((item: any) => {
      let key = item.key;
      if (typeof (this.oldValue[key]) == "object") {
        if (item.key.toLowerCase() == "travelexpense")
          this.changeItems.push({ key: item.property, oldValue: this.oldValue[key][item.property], updatedValue: this.updatedValue[key][item.property] });
        else
          this.changeItems.push({ key: key, oldValue: this.oldValue[key], updatedValue: this.updatedValue[key] });
      } else {
        if (item.key.toLowerCase() == "engagementtype") {
          this.oldValue[key] = this.getEngagementType(this.oldValue[key]);
          this.updatedValue[key] = this.getEngagementType(this.updatedValue[key]);
        }
        this.changeItems.push({ key: key, oldValue: this.oldValue[key], updatedValue: this.updatedValue[key] });
      }
    });
  }
  checkChanges() {
    return this.changedPhaseItems?.length || this.changeItems?.length || this.addedPhaseItems?.length || this.deletedPhaseItems?.length;
  }
  isDisplay(item: any) {
    return (((!item.key.includes("Id") && item.key.toLowerCase() != "templatecomponent") && (typeof (item.value) != "boolean")) || item.key == 'IsPrevailingWage' || item.key == 'IsLocalClient');
  }
  getPillarValue(id: any) {
    return this.solutionPilars.find((x: any) => x.solutionPilarId == id).solutionPilarName;
  }
  getServiceRole(id: any) {
    return this.serviceRoles.find((x: any) => x.serviceRoleId == id).serviceRoleName;
  }
  getEngagementType(id: any) {
    return this.engagementType[id];
  }
  getPhase(id: any) {
    return ProjectPhase[id];
  }
  closePopup(data: string) {
    this.dialogRef.close({ data });
  }
}
