<div *ngIf="discoveryList">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
      
        <!-- Discovery Description (distinct Environment) Column -->
        <ng-container matColumnDef="environment">
          <th mat-header-cell *matHeaderCellDef> Environment</th>
          <td mat-cell *matCellDef="let discovery"> {{discovery.environment}} </td>
        </ng-container>
        <!-- Discovery Environment Type Column -->
        <ng-container matColumnDef="environmentType">
          <th mat-header-cell *matHeaderCellDef>Env. Type</th>
          <td mat-cell *matCellDef="let discovery"> {{discovery.environmentType}} </td>
        </ng-container>
        <!-- Discovery Workload Count Column -->
        <ng-container matColumnDef="workloadCount">
          <th mat-header-cell *matHeaderCellDef> Workloads
          <td mat-cell *matCellDef="let discovery"> {{discovery.workloadCount}} </td>
        </ng-container>
        <!-- Discovery Date Performed Column -->
        <ng-container matColumnDef="datePerformed">
          <th mat-header-cell *matHeaderCellDef>Performed On</th>
          <td mat-cell *matCellDef="let discovery"> {{discovery.datePerformed}} </td>
        </ng-container>
      
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)">
        </tr>
      </table>
</div>