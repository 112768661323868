import { Injectable } from '@angular/core';
import { VehicleInsurance, VehicleExpense, VehicleCreateInsurance, VehicleValvolineCard, VehicleGasCard } from './models/fleet-management-models';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { of } from 'rxjs';
import { IdTokenClaims } from '../shared/models/id-token-claims';
@Injectable()
export class FleetMangementService {
  private azureId: string | null | undefined = null;
  constructor(private http: HttpClient, private authService: MsalService) {
  }
  getMilageByEmployeeId(azureId: string) {
    return this.http.get(`/api/vehicles/milages/employee/${azureId}`);
  }
  runExpenseReport(queryObject: any) {
    const body = JSON.stringify(queryObject);
    return this.http.post<any[]>(`/api/reports/vehicles/expense`, body);
  }
  runAgingReport() {
    return this.http.get<any[]>(`/api/reports/vehicles/vehicleAging`);
  }
  convertToCsv(jsonToConvert: string) {
    const jsonItems = JSON.parse(jsonToConvert);
    const items = jsonItems;
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    let csv = items.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    const normalizeHeaders: Array<string> = [];
    header.forEach(h => {
      normalizeHeaders.push(h.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }));
    });
    csv.unshift(normalizeHeaders.join(','));
    csv = csv.join('\r\n');
    return csv;
  }
  checkIfDriver(azureId: string) {
    return this.http.get(`/api/vehicles/${azureId}/isdriver`);
  }
  getVehicleRegistrationExpirationReport(startDate: Date, endDate: Date) {
    const obj = { 'startDate': startDate.toLocaleDateString('en-US'), 'endDate': endDate.toLocaleDateString('en-US') };
    const queryString = this.convertToQueryString(obj);
    return this.http.get(`/api/vehicles/report/registrationExpiration?${queryString}`);
  }
  getVehicleMileageReport(queryObject: any) {
    const body = JSON.stringify(queryObject);
    return this.http.post(`/api/vehicles/report/mileage`, body);
  }
  getVehicleAuditReport() {
    return this.http.get(`/api/Reports/FleetManagment/report/acct`);
  }
  getVehicleReportExpensePerMile(startDate: Date, endDate: Date) {
    const obj = { 'startDate': startDate.toLocaleDateString('en-US'), 'endDate': endDate.toLocaleDateString('en-US') };
    const queryString = this.convertToQueryString(obj);
    return this.http.get(`/api/vehicles/report/expensepermile?${queryString}`);
  }
  getVehicles() {
    try {
      return this.http.get<any[]>(`/api/Vehicles`);
    } catch (error) {
      return this.http.get<any[]>(`/api/Vehicles`);
    }
  }
  getVehicle(id: string) {
    return this.http.get<any>(`/api/Vehicles/${id}`);
  }
  getRetiredVehicles() {
    return this.http.get<any[]>(`/api/Vehicles?SoftDelete=true`);
  }
  updateVehicle(vehicle: any) {
    const body = JSON.stringify(vehicle);
    return this.http.put(`/api/Vehicles`, body);
  }
  unassignVehicle(vehicle: any) {
    const body = JSON.stringify(vehicle);
    return this.http.put(`/api/Vehicles/Unassign`, body)
  }
  unassignVehicleById(vehicleId: any) {
    const body = { vehicleId: vehicleId };
    return this.http.put(`/api/Vehicles/Unassign/${vehicleId}`, body)
  }
  reassignVehicle(vehicle: any) {
    const body = JSON.stringify(vehicle);
    return this.http.put(`/api/Vehicles/Reassign`, body)
  }
  reassignVehicleById(vehicleId: any, employeeId: string) {
    const body = { vehicleId, employeeId };
    return this.http.put(`/api/Vehicles/Reassign/${vehicleId}`, body)
  }
  restoreVehicle(vehicleId: string) {
    const body = { vehicleId };
    return this.http.put(`/api/Vehicles/${vehicleId}/restore`, body);
  }
  deleteVehicle(vehicleId: string) {
    return this.http.delete(`/api/Vehicles/${vehicleId}`);
  }
  createVehicle(vehicle: any) {
    const body = JSON.stringify(vehicle);
    return this.http.post(`/api/Vehicles`, body);
  }
  CreateValvolineCard(vehicleValvolineCard: any) {
    const body = JSON.stringify(vehicleValvolineCard);
    return this.http.post(`/api/Vehicles/Valvolinecard`, body);
  }
  GetVavolineCard(vehicleid: string) {
    return this.http.get<VehicleValvolineCard>(`/api/Vehicles/Valvolinecard/${vehicleid}`);
  }
  CreateGasCard(vehicleGasCard: any) {
    const body = JSON.stringify(vehicleGasCard);
    return this.http.post(`/api/Vehicles/Gascard`, body);
  }
  GetGasCard(vehicleId: string) {
    return this.http.get<VehicleGasCard>(`/api/Vehicles/Gascard/${vehicleId}`);
  }
  createVehicleMaitenanceSchedule(vehicleMaitenanceScheduleItem: any) {
    const body = JSON.stringify(vehicleMaitenanceScheduleItem);
    return this.http.post(`/api/Vehicles/MaitenanceSchedules`, body);
  }
  createVehicleMaintenanceScheduleRecord(vehicleMaintenanceScheduleRecord: any) {
    const body = JSON.stringify(vehicleMaintenanceScheduleRecord);
    return this.http.post(`/api/Vehicles/MaitenanceRecords`, body);
  }
  getVehicleMaitenanceSchedule(vehicleMaitenanceChesuleItemId: string) {
    return this.http.get<any>(`/api/Vehicles/MaitenanceSchedules/${vehicleMaitenanceChesuleItemId}`);
  }
  getVehicleMaintenanceScheduleRecord(vehicleMaintenanceScheduleRecordId: string) {
    return this.http.get(`/api/Vehicles/MaitenanceScheduleRecords/${vehicleMaintenanceScheduleRecordId}`);
  }
  getVehicleMaitenanceSchedules() {
    return this.http.get<any[]>(`/api/Vehicles/MaitenanceSchedules`);
  }
  getVehicleMaintenanceScheduleRecords() {
    return this.http.get(`/api/Vehicles/MaitenanceScheduleRecords`);
  }
  deleteVehicleMaintenanceScheduleRecord(id: string) {
    return this.http.delete(`/api/Vehicles/MaitenanceScheduleRecords/${id}`);
  }
  deleteVehicleMaintenanceSchedule(id: string) {
    return this.http.delete(`/api/Vehicles/MaitenanceSchedules/${id}`);
  }
  updateVehicleMaitenanceSchedule(vehicleMaitenanceChesuleItem: any) {
    const body = JSON.stringify(vehicleMaitenanceChesuleItem);
    return this.http.put(`/api/Vehicles/MaitenanceSchedules`, body);
  }
  updateVehicleMaintenanceScheduleRecord(vehicleMaintenanceScheduleRecord: any) {
    const body = JSON.stringify(vehicleMaintenanceScheduleRecord);
    return this.http.put(`/api/Vehicles/MaitenanceScheduleRecords`, body);
  }
  createVehicleRegistration(vehicleRegirationModel: any) {
    const body = JSON.stringify(vehicleRegirationModel);
    return this.http.post(`/api/Vehicles/Registrations`, body);
  }
  getVehicleRegistration(id: string) {
    return this.http.get<any>(`/api/Vehicles/Registrations/${id}`);
  }
  getVehicleRegistrations() {
    return this.http.get(`/api/Vehicles/Registrations`);
  }
  updateVehicleRegistration(vehicleResitrationModel: any) {
    const body = JSON.stringify(vehicleResitrationModel);
    return this.http.put(`/api/Vehicles/Registrations`, body);
  }
  deleteVehicleResitration(id: string) {
    return this.http.delete(`/api/Vehicles/Registrations/${id}`);
  }
  createVehicleModels(vehicleRegirationModel: any) {
    const body = JSON.stringify(vehicleRegirationModel);
    return this.http.post(`/api/Vehicles/Models`, body);
  }
  getVehicleModelByVehicleId(id: string) {
    return this.http.get(`/api/Vehicles/${id}/Model`);
  }
  getVehicleModelByModelId(id: string) {
    return this.http.get<any>(`/api/Vehicles/Models/${id}`);
  }
  getVehicleModels() {
    return this.http.get<any[]>(`/api/Vehicles/Models`);
  }
  updateVehicleModel(vehicleResitrationModel: any) {
    const body = JSON.stringify(vehicleResitrationModel);
    return this.http.put(`/api/Vehicles/Models`, body);
  }
  deleteVehicleModel(id: string) {
    return this.http.delete(`/api/Vehicles/Models/${id}`);
  }
  getCities() {
    return this.http.get<any[]>(`/api/locations/cities`);
  }
  getCityById(cityId: string) {
    return this.http.get<any>(`/api/locations/cities/${cityId}`);
  }
  getCitiesByStateId(stateId: string) {
    return this.http.get<any[]>(`/api/locations/states/${stateId}/cities`);
  }
  getStates() {
    return this.http.get<any[]>(`/api/locations/states`);
  }
  getState(id: string) {
    return this.http.get<any>(`/api/locations/states/${id}`);
  }
  createVehicleUsages(vehicleRegirationModel: any, azureId: string) {
    const body = JSON.stringify(vehicleRegirationModel);

    return this.http.post(`/api/Vehicles/Usages/${azureId}`, body);
  }
  getVehicleUsage(id: string) {
    return this.http.get<any>(`/api/Vehicles/Usages/${id}`);
  }
  getVehicleUsages() {
    return this.http.get(`/api/Vehicles/Usages`);
  }
  getVehicleUsagesByCarId(vehicleId: string) {
    return this.http.get<any[]>(`/api/Vehicles/Usages/Car/${vehicleId}`);
  }
  updateVehicleUsages(vehicleResitrationModel: any) {
    const body = JSON.stringify(vehicleResitrationModel);
    return this.http.put(`/api/Vehicles/Usages`, body);
  }
  deleteVehicleUsages(id: string) {
    return this.http.delete(`/api/Vehicles/Usages/${id}`);
  }
  getVehicleMilagesByVehicleId(vehicleId: string) {
    return this.http.get(`/api/vehicles/vehcilemilage/${vehicleId}`);
  }
  getVehicleMileageById(mileageId: string) {
    return this.http.get<any>(`/api/vehicles/mileages/${mileageId}`);
  }
  updateVehicleMileage(mileage: any) {
    const body = JSON.stringify(mileage);
    return this.http.put(`/api/vehicles/mileages`, body);
  }
  getVehicleUsagesNotFinished() {
    return this.http.get(`/api/Vehicles/Usages/incomplete`);
  }
  getVehicleInsuranceRecord(vehicleId: string, insuranceId: string) {
    return this.http.get(`/api/Vehicles/${vehicleId}/insurancerecords/${insuranceId}`);
  }
  getVehicleInsuranceRecordById(insuranceId: string) {
    return this.http.get<any>(`/api/Vehicles/insurancerecords/${insuranceId}`);
  }
  getVehicleInsuranceRecords(vehicleId?: string) {
    if (vehicleId) {
      return this.http.get<any[]>(`/api/Vehicles/${vehicleId}/insurancerecords`);
    } else {
      return this.http.get<any[]>(`/api/Vehicles/insurancerecords`);
    }
  }
  updateVehicleInsuranceRecord(vehicleInsuranceRecord: VehicleInsurance | any) {
    const body = JSON.stringify(vehicleInsuranceRecord);
    return this.http.put(`/api/Vehicles/insurancerecords`, body);
  }
  deleteVehicleInsuranceRecord(insuranceRecordId: string) {

    return this.http.delete(`/api/Vehicles/insurancerecords/${insuranceRecordId}`);
  }
  createVehicleInsuranceRecord(vehicleInsuranceRecord: VehicleInsurance) {
    const body = JSON.stringify(vehicleInsuranceRecord);
    return this.http.post(`/api/Vehicles/insurancerecords`, body);
  }
  uploadVehicleInsuranceRecordPhoto(vehicleInsuranceRecord: VehicleCreateInsurance) {
    const formData: FormData = new FormData();
    formData.append('companyName', vehicleInsuranceRecord.vehicleInsuranceCompanyName);
    formData.append('startDate', new Date(vehicleInsuranceRecord.vehicleInsuranceStartDate).toDateString());
    formData.append('endDate', new Date(vehicleInsuranceRecord.vehicleInsuranceEndDate).toDateString());
    formData.append('policyNumber', vehicleInsuranceRecord.vehicleInsurancePolicyNumber);
    formData.append('file', vehicleInsuranceRecord.insurancePhoto, vehicleInsuranceRecord.insurancePhoto.name);
    // var header = new Headers();

    //const options: RequestOptions = new RequestOptions();
    // return this.test.post(`http://localhost:9191/api/vehicles/insurancecard/upload`, formData, options);
    return this.http.post(`/api/vehicles/insurancecard/upload`, formData);
  }
  getVehicleExpense(insuranceId: string, vehicleId?: string) {
    if (vehicleId) {

      return this.http.get<any>(`/api/Vehicles/${vehicleId}/expenses/${insuranceId}`);
    } else {

      return this.http.get<any>(`/api/Vehicles/expenses/${insuranceId}`);
    }
  }
  getVehicleExpenses(vehicleId?: string) {
    if (vehicleId) {
      return this.http.get<any[]>(`/api/Vehicles/${vehicleId}/expenses`);
    } else {
      return this.http.get<any[]>(`/api/Vehicles/expenses`);
    }
  }
  updateVehicleExpense(vehicleExpense: VehicleExpense) {
    const body = JSON.stringify(vehicleExpense);
    return this.http.put(`/api/Vehicles/expenses`, body);
  }
  deleteVehicleExpense(expenseId: string, vehicleId?: string) {

    return this.http.delete(`/api/Vehicles/${vehicleId}/expenses/${expenseId}`);
  }
  createVehicleExpenceNoRecipet(vehicleExpense: any) {
    const body = JSON.stringify(vehicleExpense);
    return this.http.post(`/api/Vehicles/${vehicleExpense.vehicleId}/expenses/norecipt`, body);
  }
  createVehicleExpense(vehicleExpense: VehicleExpense) {
    const body: FormData = new FormData();
    body.append('file', vehicleExpense.vehicleExpenseReceipt ?? new Blob(), vehicleExpense.vehicleExpenseReceipt?.name);
    body.append('vehicleExpenseDescription', vehicleExpense.vehicleExpenseDescription);
    body.append('vehicleExpenseCost', vehicleExpense.vehicleExpenseCost.toString());
    body.append('vehicleExpenseType', vehicleExpense.vehicleExpenseType.toString());
    body.append('vehicleExpenseDate', new Date(vehicleExpense.vehicleExpenseDate).toLocaleString("en-us"));


    return this.http.post(`/api/Vehicles/${vehicleExpense.vehicleId}/expenses`, body);
  }
  addRefuelItem(fuelData: any) {
    const body = JSON.stringify(fuelData);
    return this.http.post(`/api/vehicles/${fuelData.vehicleId}/fuel`, body);
  }
  checkoutVehicle(vehicleId: string, azureId: string, vehicle?: any) {
    let body: string;
    if (vehicle) {

      body = JSON.stringify(vehicle);
    } else {
      body = '';
    }

    return this.http.put(`/api/vehicles/${vehicleId}/Checkout/${azureId}`, body);
  }
  checkInVehicle(vehicleId: string, vehicle?: any) {
    let body: string;
    if (vehicle) {

      body = JSON.stringify(vehicle);
    } else {
      body = '';
    }

    return this.http.put(`/api/vehicles/${vehicleId}/CheckIn`, body);
  }
  checkForCheckedOutCar(azureId: any) {

    return this.http.get<any>(`/api/vehicles/${azureId}/checkedoutvehicles`);
  }
  getCheckedOutCarsByUser(azureId: string) {
    return this.http.get<any>(`/api/vehicles/${azureId}/checkedoutbyuserid`);
  }
  getDrivers() {
    return this.http.get<any[]>('/api/vehicles/drivers');
  }
  getAllDrivers() {
    return this.http.get<any[]>('/api/vehicles/allDrivers');
  }
  getDriver(id: string) {
    return this.http.get<any>(`/api/vehicles/drivers/${id}`);
  }
  createDriver(driver: any) {
    const body = JSON.stringify(driver);
    return this.http.post(`/api/vehicles/drivers`, body);
  }
  deleteDriver(id: string) {
    return this.http.delete(`/api/vehicles/drivers/${id}`);
  }
  updateDriver(driver: any) {
    const body = JSON.stringify(driver);
    return this.http.put(`/api/vehicles/drivers`, body);
  }

  removeMaitenanceScheduleItem(itemId: string) {
    return this.http.delete(`/api/vehicles/`);
  }
  convertToQueryString(parameters: any) {
    const params = new URLSearchParams();
    for (const key in parameters) {
      if (parameters[key]) {
        params.set(key, parameters[key]);
      }
    }
    return params.toString();
  }
  getAzureId() {
    if (!this.azureId || (this.azureId as string)?.length < 1) {
      let idTokenClaims = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
      if (idTokenClaims) {
        this.azureId = idTokenClaims['oid'];
      }
      else {
        this.azureId = '';
      }
    }
    return of(this.azureId)
  }


}


