<app-breadcrumb></app-breadcrumb>
<mat-card>
  <mat-card-content>
    <nav mat-tab-nav-bar>
      <a mat-tab-link [routerLink]="['businessPartners']" routerLinkActive="active-link"
        routerLinkActiveOptions="{ exact: false }">Business Partners</a>
      <a mat-tab-link [routerLink]="['solutionPilars']" *ngIf="!hasSolPilarAccess" id="solPillars"
        routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Solution Pillars</a>
    </nav>
  </mat-card-content>
</mat-card>
<router-outlet></router-outlet>