<div class="col-md-12">
  <mat-card>
    <h3 class="title-underline">Component Details</h3>
    <mat-card-content>

      <!-- Loading Spinner -->
      <mat-spinner *ngIf="loading" id="loading-spinner"></mat-spinner>

      <!-- Material Table -->
      <mat-table #table [dataSource]="componentDetails" *ngIf="!loading && componentDetails?.length > 0" id="component-details-table">

        <!-- Component Name Column -->
        <ng-container matColumnDef="componentName">
          <mat-header-cell *matHeaderCellDef id="header-component-name"> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" id="component-name-cell-{{ element.componentName }}"> 
            {{ element.componentName }} 
          </mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef id="header-status"> Status </mat-header-cell>
          <mat-cell *matCellDef="let element" id="status-cell-{{ element.componentName }}"> 
            {{ element.status }} 
          </mat-cell>
        </ng-container>

        <!-- Approvers Column -->
        <ng-container matColumnDef="approvers">
          <mat-header-cell *matHeaderCellDef id="header-approvers"> Approver </mat-header-cell>
          <mat-cell *matCellDef="let element" id="approvers-cell-{{ element.componentName }}">
            <span *ngIf="element.approvers.length > 0; else noApprovers">
              {{ element.approvers.join(', ') }}
            </span>
            <ng-template #noApprovers id="no-approvers-template">No Approvers</ng-template>
          </mat-cell>
        </ng-container>

        <!-- Header and Row definitions -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      </mat-table>

      <!-- No Data Message -->
      <div *ngIf="!loading && componentDetails.length === 0" id="no-data-message">
        No component details available.
      </div>

    </mat-card-content>
  </mat-card>
</div>
