import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-estimate-components-list',
  templateUrl: './service-estimate-components-list.component.html',
  styleUrls: ['./service-estimate-components-list.component.scss']
})
export class ServiceEstimateComponentsListComponent implements OnInit {
  @Input() seComponentData: any;
  @Input() serviceRoles: any;
  displayedColumns = ['title', 'approvedByMe', 'totalPendingApproval'];

  constructor() { }

  ngOnInit(): void {
  }

  getServiceRoleCount(id: string, isApproved: boolean) {
    if (isApproved) {
      const serviceRole = this.seComponentData.selfApprovedServiceRoles.find((x: any) => x.serviceRoleId == id);
      return serviceRole ? serviceRole.numberOfComponents : 0;
    }
    else {
      const serviceRole = this.seComponentData.pendingApprovalServiceRoles.find((x: any) => x.serviceRoleId == id);
      return serviceRole ? serviceRole.numberOfComponents : 0;
    }
  }

}
