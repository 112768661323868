////import { component, elementref, input, oninit, viewchild } from '@angular/core';
//import { chart, chartevent, point, registerables } from 'chart.js';
import { Chart, Point, registerables } from 'chart.js';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DashboardRequestModel } from '../models/dashboard-request.model';
import { DashboardService } from '../dashboard.service';
import { debounceTime } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';


@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  projectsData: any;
  @ViewChild('pieChart', { static: true }) pieChartReference: ElementRef;
  pieChart: Chart;
  pieRequestFromGroup: UntypedFormGroup;
  isDarkViewEnabled: boolean;

  constructor(private dashboardService: DashboardService, private fb: UntypedFormBuilder, private overlayContainer: OverlayContainer) {
    Chart.register(...registerables);
  }

  ngOnInit() {
    this.isDarkViewEnabled = this.overlayContainer.getContainerElement().classList.contains("dark-theme");
    this.pieRequestFromGroup = this.fb.group({
      'noOfDays': ['90'],
      'startDate': [''],
      'endDate': ['']
    });
    this.getProjectData(this.pieRequestFromGroup.value);
    this.formValueChanges();   
  }

  filterProjectData(event: any) {
    this.pieRequestFromGroup.patchValue({ startDate: null, endDate: null });
    this.getProjectData(this.pieRequestFromGroup.value);
  }

  formValueChanges() {
    this.pieRequestFromGroup.controls['startDate'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.checkForValidDateRange();
      });
    this.pieRequestFromGroup.controls['endDate'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.checkForValidDateRange();
      });
  }

  checkForValidDateRange() {
    let startDate = this.pieRequestFromGroup.controls['startDate'];
    let endDate = this.pieRequestFromGroup.controls['endDate'];
    if (startDate.value && endDate.value && startDate.valid && endDate.valid) {
      this.dateRangeChange(startDate.value, endDate.value);
    }
  }

  dateRangeChange(startDate: Date, endDate: Date) {
    this.pieRequestFromGroup.patchValue({ noOfDays: null });
      let dashboardRequestModel: DashboardRequestModel = Object.assign({}, this.pieRequestFromGroup.value);
      dashboardRequestModel.startDate = new Date(startDate).toLocaleDateString('en-US');
      dashboardRequestModel.endDate = new Date(endDate).toLocaleDateString('en-US');
      this.getProjectData(dashboardRequestModel);
  }

  getProjectData(dashboardRequestModel: DashboardRequestModel) {
    this.dashboardService.getProjectInfo(dashboardRequestModel).subscribe(res => {
      this.projectsData = res;
      this.getPrjectStatusChartData();
    });
  }

  getPrjectStatusChartData() {
    if (!this.pieChart) {
      this.pieChart = new Chart(this.pieChartReference.nativeElement, {
        type: 'pie',
        data: {
          labels: ['Active', 'In Active', 'Lost', 'Completed'],
          datasets: [{
            label: '# of Projects',
            data: [this.getProjectStatusCount(0, null), this.getProjectStatusCount(1, null), this.getProjectStatusCount(2, null), this.getProjectStatusCount(3, null)],
            backgroundColor: [
              'rgb(33, 150, 243)',
              'rgb(255 150 48)',
              'rgb(255,0,0)',
              'rgb(75 175 80)',

            ],
            borderColor: [
              'rgb(255 255 255)'
            ],
            borderWidth: 3,
            borderRadius: 5
          }]
        },
        options: {
          color: this.getFontColor(),
          responsive: false,
        }
      });
    }
    else {
      this.pieChart.data.datasets[0].data = [this.getProjectStatusCount(0, null), this.getProjectStatusCount(1, null), this.getProjectStatusCount(2, null), this.getProjectStatusCount(3, null)]
      this.pieChart.update();
    }
  }

  getFontColor() {
    return this.isDarkViewEnabled ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
  }

  getProjectStatusCount(status?: number | null, isAssigned?: number | null) {
    if (status != null && isAssigned != null)
      return this.projectsData.filter((x: any) => x.status == status && x.isAssigned == isAssigned).length;
    else if (isAssigned != null)
      return this.projectsData.filter((x: any) => x.isAssigned == isAssigned).length;
    else
      return this.projectsData?.filter((x: any) => x.status == status).length;
  }

  resetFilters() {
    this.pieRequestFromGroup.reset({ noOfDays: '90' });
    this.getProjectData(this.pieRequestFromGroup.value);
  }

}
