import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { VehicleOilCard } from './gas-card';
import { Observable, of } from 'rxjs';
export class OilCardDataSource extends DataSource<any> {
    data: Array<VehicleOilCard>;
    connect(): Observable<VehicleOilCard[]> {

        return of(this.data);
    }
    disconnect(collectionViewer: CollectionViewer): void {

    }
    setData(data: Array<VehicleOilCard>) {
        this.data = data;
    }

}
