<div *ngIf="vms">
  <div *ngIf="employees" class="container-fluid">
    <mat-card>
      <mat-card-content>
        <mat-table #table [dataSource]="vms">
          <ng-container matColumnDef="vehicleDriverName">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span> {{getEmployee(element.empId).firstName}} {{getEmployee(element.empId).lastName}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="vehicleDriverActions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a mat-raised-button color="primary" [routerLink]="['edit',element.vehicleMangerId]">Edit</a>
              <a mat-raised-button color="accent" [routerLink]="['detail',element.vehicleMangerId]">Details</a>
              <button mat-raised-button color="warn" type="button" (click)="deleteEmployee(element.vehicleMangerId)">Delete</button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>