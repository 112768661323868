import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { SolutionPilar } from '../../../business-partners/models/solution-pilar';
import { ServiceEstimatorService } from '../../_services/service-estimator.service';

@Component({
  selector: 'app-professional-service-estimate-component-sku-item',
  templateUrl: './professional-service-estimate-component-sku-item.component.html',
  styleUrls: ['./professional-service-estimate-component-sku-item.component.scss']
})
export class ProfessionalServiceEstimateComponentSkuItemComponent implements OnInit {
  @Input() solutionPilars: Array<SolutionPilar>;
  @Input() formArrayName: string;
  @Input() skuItems: UntypedFormArray;
  @Input() isLocked: boolean;
  @Input() formGroup: UntypedFormGroup;
  @Input() phaseItemType: string;
  @Output() addSkuItem: EventEmitter<null> = new EventEmitter<null>();

  constructor(private serviceEstimatiorService: ServiceEstimatorService) { }

  ngOnInit() {
  }

  removeSkuControl(index: any) {
    const id = this.skuItems.at(index).value['id'];
    if (id) {
      if (this.phaseItemType == 'standard') {
        this.serviceEstimatiorService.deleteSkuItem(id)
          .subscribe(res => {
            this.skuItems.removeAt(index);

          });
      }
      else if (this.phaseItemType == 'template') {
        this.serviceEstimatiorService.deleteTemplateSkuItem(id)
          .subscribe(res => {
            this.skuItems.removeAt(index);

          });
      }      
    } else {
      this.skuItems.removeAt(index);
    }
  }

  getSolutionPilar(item: UntypedFormGroup) {
    let pilarId = item.controls['solutionPilarId'].value;
    let solPilar = this.solutionPilars.find(x => x.solutionPilarId == pilarId);
    return solPilar ? solPilar.solutionPilarName : null;
  }
}
