import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ProjectControlRateService } from '../project-control-rate.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ProjectControlRate } from '../../models/service-role';

@Component({
  selector: 'app-project-control-rate-update',
  templateUrl: './project-control-rate-update.component.html',
  styleUrls: ['./project-control-rate-update.component.scss']
})
export class ProjectControlRateUpdateComponent implements OnInit {

  projectControlRateFormGroup: UntypedFormGroup;
  initalProjectControlRate: ProjectControlRate;
  projectControlRate: ProjectControlRate;
  minHourValue: number;
  isSubmitDisabled: boolean;

  constructor(private fb: UntypedFormBuilder, private projectControlRateService: ProjectControlRateService, private location: Location, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        const id: string = params['id'];
        this.projectControlRateService.getProjectControlRate(id)
          .subscribe(projectControlRateResult => {
            this.isSubmitDisabled = true;
            const projectControlRate: ProjectControlRate = projectControlRateResult;
            this.projectControlRate = projectControlRate;
            this.initalProjectControlRate = new ProjectControlRate();
            Object.assign(this.initalProjectControlRate, projectControlRate);
            this.projectControlRateFormGroup = this.fb.group({
              'projectControlRateName': [projectControlRate.projectControlRateName, Validators.required],
              'projectControlRateDescription': [projectControlRate.projectControlRateDescription, Validators.required],
              'projectControlRateMinHours': [projectControlRate.projectControlRateMinHours, Validators.compose([Validators.required, Validators.min(1), Validators.pattern("^[0-9]+$")])],
              'projectControlRateMaxHours': [projectControlRate.projectControlRateMaxHours, Validators.compose([Validators.required, Validators.min(this.convertFloat2int(projectControlRate.projectControlRateMinHours) + 1), Validators.pattern("^[0-9]+$")])],
              'projectControlRatePercentage': [projectControlRate.projectControlRatePercentage, Validators.required],
              'projectControlRateId': [projectControlRate.projectControlRateId],
              'createdDate': [projectControlRate.createdDate],
              'softDelete': [projectControlRate.softDelete],
              'projectControl': [projectControlRate.projectControl],
              'createdByEmployeeId': [projectControlRate.createdByEmployeeId],
              'projectControlId': [projectControlRate.projectControlId]
            });

            this.projectControlRateFormGroup.controls['projectControlRateMinHours'].valueChanges
              .subscribe(formValue => {
                let maxHourControl = this.projectControlRateFormGroup.controls['projectControlRateMaxHours'];
                maxHourControl.setValidators(Validators.compose([Validators.required, Validators.min(this.convertFloat2int(formValue) + 1), Validators.pattern("^[0-9]+$")]));
                maxHourControl.updateValueAndValidity();
              });
            this.projectControlRateFormGroup.valueChanges.subscribe(formValue => {
              const newFormValue: ProjectControlRate = formValue;
              if (this.isEquivalent(newFormValue, this.initalProjectControlRate)) {
                this.isSubmitDisabled = true;
              } else {
                this.isSubmitDisabled = false;
              }
            });

          });
      });
  }
  submitForm() {
    if (this.projectControlRateFormGroup.valid) {
      this.projectControlRateService.updateProjectControlRate(this.projectControlRateFormGroup.value)
        .subscribe(createResult => {
          this.location.back();

        });
    }
  }
  isEquivalent(a: any, b: any) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }
  convertFloat2int(value: any) {
    return value | 0;
  }
}
