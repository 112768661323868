import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MarginOption } from './models/marginOption';

@Injectable({
  providedIn: 'root'
})
export class ApplicationConfigurationService {

  constructor(private http: HttpClient) { }

  updateMarginOptions(marginOption: MarginOption) {
    const body = JSON.stringify(marginOption);
    return this.http.post(`/api/MarginOptions`, body);
  }

  getMarginOptions() {
    return this.http.get<MarginOption | null>(`/api/MarginOptions`);
  }
}
