<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{formTitle}} - {{cloudProvider}} ({{region}}) </h3>
<mat-card>
  <mat-dialog-content [formGroup]="cloudProductSelectionForm">
  <mat-grid-list cols="2" rowHeight="6:1">
      <mat-form-field>
        <mat-label>VM Id</mat-label>
        <input type="text" matInput formControlName="vmId" readonly>
      </mat-form-field>
      <mat-form-field>
        <mat-label>CPU</mat-label>
        <input matInput formControlName="cpu" placeholder="cpu" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ram</mat-label>
        <input matInput formControlName="ram" placeholder="ram" />
      </mat-form-field>
    </mat-grid-list>
    <app-cloud-migration-tool-product-table [events]="eventsSubject.asObservable()" [selectedProduct]="productSelected" [matchingCriteria]="matchingCriteria" ></app-cloud-migration-tool-product-table>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button class="mat-raised-button mat-primary" (click)="refresh()">Refresh</button>
  </mat-dialog-actions>
</mat-card>