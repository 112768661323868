<mat-card>
  <mat-card-content *ngIf="employees">
    <div class="row">
      <mat-form-field class="normalSize">
        <mat-select [(ngModel)]="selectedEmployeeId" (selectionChange)="filterByEmployee()" disableOptionCentering #employee
          placeholder="Employee Filter">
          <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">{{emp.firstName + " " + emp.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button (click)="clearSelectedEmployee()" mat-icon-button type="button"><mat-icon>clear</mat-icon></button>
    </div>
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="projectName">
        <mat-header-cell *matHeaderCellDef (click)="sortByProjectName()" class="clickable">
          Name <mat-icon class="twelve">
            sort
          </mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.componentName}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef (click)="sortByDate()" class="clickable">
          Created Date <mat-icon class="twelve">
            sort
          </mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createdDate | date:'shortDate'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef> Created by</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: element.createdByEmployeeId}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="approvedBy">
        <mat-header-cell *matHeaderCellDef> Approved by</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: getApprover(element.approvals)}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="tags">
        <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngFor="let secTag of element.serviceEstimateComponentTags; first as isFirst">
            <span *ngIf="!isFirst">, </span><span> {{secTag.tag.name}}</span>
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button id = "kebabMenu" [attr.data-id] = "element" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item color="default" type="button" [routerLink]="['editComponent',element.id]">
              <mat-icon>create</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item color="warn" type="button" (click)="deleteRecord(element.id)">
              <mat-icon>remove</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item color="accent" type="button" (click)="cloneComponent(element.id)">
              <mat-icon>file_copy</mat-icon>
              <span>Clone</span>
            </button>
            <button mat-menu-item color="default" type="button" (click)="viewHistory(element.id)">
              <mat-icon>history</mat-icon>
              <span>History</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="serviceEstimates">
      <mat-paginator (page)="pageChangedEventUsages($event)" *ngIf="dataSource" [length]="serviceEstimates.length"
        [pageSize]="5" [pageIndex]="0" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
