import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyProfileLandingPageComponent } from './my-profile-landing-page/my-profile-landing-page.component';
import { MyProfileComponent } from './my-profile.component';

const routes: Routes = [{
  path: 'myProfile', component: MyProfileComponent, data: {
    breadcrumb: 'My Profile', isRoutable: true
  }, children: [
    {
      path: '', component: MyProfileLandingPageComponent, data: {
        breadcrumb: null, isRoutable: false
      }
    }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyProfileRoutingModule { }
