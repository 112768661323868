<form #form="ngForm" *ngIf="serviceEstimateForm" [formGroup]="serviceEstimateForm" (ngSubmit)="calculateTotals()">
    <mat-toolbar color="accent">
        <span>
            <button
                [disabled]="serviceEstimateDetailsClosing.length == 0 && serviceEstimateDetailsExecute.length == 0 && serviceEstimateDetailsPlanning.length == 0 && businessPartnerItems.length == 0 && !serviceEstimateForm.valid"
                title="Save Component" mat-icon-button type="submit">
                <mat-icon>save</mat-icon>
            </button>
        </span>
    </mat-toolbar>
    <mat-card class="top-level">
        <h3><span *ngIf="!secId">Create</span> <span *ngIf="secId">Update</span> Change Order Component</h3>
        <mat-card-content *ngIf="!serviceRoles">
            <mat-spinner></mat-spinner>
        </mat-card-content>
        <mat-card-content *ngIf="serviceRoles">
            <p class="text-right totalP">Totals<br>
                {{(serviceEstimateForm.controls['totalAmount'].value) | currency :'USD' :'symbol'}}<br>
                {{(serviceEstimateForm.controls['totalHours'].value)}} hours</p>
            <div class="row">

                <div *ngIf="!isValid" style="width: 100%;">
                    <h3 class="red-text">Check required fields.</h3>
                    <hr>
                </div>
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-select type="text" formControlName="serviceRoleId" placeholder="Service Role" disableOptionCentering
                            [disabled]="secId">
                            <mat-option *ngFor="let sr of serviceRoles" [value]="sr.serviceRoleId">
                                {{sr.serviceRoleName}}
                            </mat-option>
                        </mat-select>

                        <mat-error *ngIf="serviceEstimateForm.controls['serviceRoleId'].hasError('required')">Service
                            Role
                            is
                            required</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <input matInput required type="text" formControlName="componentName" placeholder="Name" />

                        <mat-error *ngIf="serviceEstimateForm.controls['componentName'].hasError('required')">Name
                            is
                            required</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <h4>Pricing Notes</h4>
                    <ul>
                        <li *ngFor="let note of pricingNotes">{{note.note}}</li>

                    </ul>
                </div>
            </div>
            <div class="expansionGap" *ngIf="serviceEstimateForm.controls['serviceRoleId'].value">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>Planning Phase</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-professional-services-estimate-detail-item #planItems [phaseItemType]="'changeOrder'"
                        (addPlanItem)="addPlanningItem()" [serviceRoles]="serviceRoles"
                        [formArrayName]="'serviceEstimateDetailsPlanning'" [items]="serviceEstimateDetailsPlanning"
                        [formGroup]="serviceEstimateForm" [phase]="'Plan'">
                    </app-professional-services-estimate-detail-item>
                </mat-expansion-panel>
            </div>
            <div class="expansionGap" *ngIf="serviceEstimateForm.controls['serviceRoleId'].value">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>Executing Phase</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-professional-services-estimate-detail-item [phaseItemType]="'changeOrder'"
                        (addExecuteItem)="addExecuteItem()" [serviceRoles]="serviceRoles"
                        [formArrayName]="'serviceEstimateDetailsExecute'" [items]="serviceEstimateDetailsExecute"
                        [formGroup]="serviceEstimateForm" [phase]="'Execute'">
                    </app-professional-services-estimate-detail-item>
                </mat-expansion-panel>
            </div>
            <div class="expansionGap" *ngIf="serviceEstimateForm.controls['serviceRoleId'].value">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>Closing Phase</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-professional-services-estimate-detail-item [phaseItemType]="'changeOrder'"
                        (addCloseItem)="addClosingItem()" [serviceRoles]="serviceRoles"
                        [formArrayName]="'serviceEstimateDetailsClosing'" [items]="serviceEstimateDetailsClosing"
                        [formGroup]="serviceEstimateForm" [phase]="'Close'">
                    </app-professional-services-estimate-detail-item>
                </mat-expansion-panel>
            </div>
            <div class="col-md-12" style="padding: 0 !important;">
                <mat-accordion *ngIf="serviceRoles">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h3>Business Partner Items</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div style="width:90%;">
                            <button mat-icon-button type="button" (click)="addBusinessItem()" color="primary"
                                title="Add Business Item">
                                <mat-icon>add</mat-icon>
                            </button>
                            <div *ngIf="businessPartnerItems.length > 0" [formArrayName]="'businessPartnerItems'"
                                class="col-md-12">
                                <div *ngFor="let item of businessPartnerItems.controls;let i = index"
                                    [formGroupName]="i">
                                    <p class="header5">Business Item {{ i + 1}}
                                        <mat-icon class="md-18 cursor" (click)="removeControl(businessPartnerItems,i)">
                                            clear</mat-icon>
                                    </p>
                                    <div class="col-xl-12">
                                        <div class="row">
                                            <div class="col-xl-4">
                                                <input type="hidden" formControlName="businessPartnerId" />

                                                <mat-form-field>
                                                    <input disabled readonly required type="text" matInput
                                                        placeholder="Business Partner" formControlName="bpName" />

                                                    <mat-hint>Click the magnifying glass to select a business partner
                                                    </mat-hint>
                                                    <span matSuffix>
                                                        <button mat-icon-button type="button"
                                                            (click)="openBusinessSearch(businessPartnerItems,i)">
                                                            <mat-icon>search</mat-icon>
                                                        </button>
                                                    </span>

                                                    <mat-error
                                                        *ngIf="businessPartnerItems.at(i).hasError('required', ['bpName'])">
                                                        Business Partner is required</mat-error>
                                                </mat-form-field>

                                                <!-- <mat-checkbox formControlName="isFixedCost">Has Fixed Cost?</mat-checkbox> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 checkboxSpacing">
                                        <mat-checkbox formControlName="isFixedCost">Has Fixed Cost?</mat-checkbox>
                                    </div>
                                    <br>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-xl-2">
                                                <mat-form-field>
                                                    <input type="number" required matInput placeholder="Estimated Hours"
                                                        formControlName="estimatedHours" />
                                                    <mat-error
                                                        *ngIf="businessPartnerItems.at(i).hasError('required', ['estimatedHours'])">
                                                        Estimated
                                                        Hours are required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-xl-3">
                                                <mat-form-field>
                                                    <input matInput formControlName="activity" type="text"
                                                        placeholder="Activity" required />
                                                    <mat-error
                                                        *ngIf="businessPartnerItems.at(i).hasError('required', ['activity'])">
                                                        Activity is required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-xl-2">
                                                <mat-form-field *ngIf="solutionPilars">
                                                    <mat-select placeholder="Solution Pillar" required disableOptionCentering
                                                        formControlName="solutionPilarId">
                                                        <mat-option *ngFor="let serviceRole of solutionPilars"
                                                            [value]="serviceRole.solutionPilarId">
                                                            {{serviceRole.solutionPilarName}} </mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                            </div>
                                            <div class="col-xl-2">
                                                <mat-form-field>
                                                    <span matPrefix>$ </span>
                                                    <input type="number" matInput placeholder="Cost"
                                                        formControlName="cost" required />
                                                </mat-form-field>
                                            </div>
                                            <div class="col-xl-2">
                                                <mat-form-field>
                                                    <span matPrefix>$ </span>
                                                    <input type="number" matInput placeholder="Sell"
                                                        formControlName="sell" required />

                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <br>
            <div class="col-md-12" style="padding: 0 !important;">
                <div>
                    <mat-card class="customSizingCard_MI">
                        <mat-card-header>
                            <h3>Material Items</h3>
                            <button mat-icon-button (click)="addMeterialItem()" color="primary" type="button">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="col-md-4" [formArrayName]="'materialItems'">
                                <div *ngFor="let item of materialItems.controls;let i = index;" [formGroupName]="i">
                                    <p class="header5">
                                        Item {{i + 1}}

                                        <mat-icon class="md-18 cursor" (click)="removeMaterialItem(i)">clear</mat-icon>
                                    </p>
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="Name" formControlName="name">
                                    </mat-form-field>
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="Cost" formControlName="cost">
                                    </mat-form-field>
                                    <mat-form-field>
                                        <input matInput type="number" placeholder="Sell" formControlName="sell">
                                    </mat-form-field>
                                    <hr>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <br>
            <div class="col-md-12">
                <mat-card class="customSizingCard_MI">
                    <mat-card-header>
                        <h3>Travel Expenses</h3>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="col-md-4" formGroupName="travelExpense">
                            <mat-form-field>
                                <span matPrefix>$&nbsp;</span>
                                <input matInput type="number" placeholder="Airfare" formControlName="airfare" />
                            </mat-form-field>
                            <mat-form-field>
                                <span matPrefix>$&nbsp;</span>
                                <input matInput type="number" placeholder="Auto" formControlName="auto" />
                            </mat-form-field>
                            <mat-form-field>
                                <span matPrefix>$&nbsp;</span>
                                <input matInput type="number" placeholder="Hotel" formControlName="hotel" />
                            </mat-form-field>
                            <mat-form-field>
                                <span matPrefix>$&nbsp;</span>
                                <input matInput type="number" placeholder="Gas" formControlName="gas" />
                            </mat-form-field>
                            <mat-form-field>
                                <span matPrefix>$&nbsp;</span>
                                <input matInput type="number" placeholder="Meals" formControlName="meals" />
                            </mat-form-field>
                            <mat-form-field>
                                <span matPrefix>$&nbsp;</span>
                                <input matInput type="number" placeholder="Other/Misc" formControlName="other" />
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput type="number" placeholder="Travel Hours"
                                    formControlName="travelHours" />
                            </mat-form-field>
                            <mat-form-field>
                                <span matPrefix>$&nbsp;</span>
                                <input matInput type="number" placeholder="Per Hour Charge"
                                    formControlName="perHourCharge" />
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>
                <div *ngIf="isValid">
                    <h3>Check required fields.</h3>
                </div>
            </div>
            <br>
            <div class="col-md-12">

                <mat-card class="customSizingCard_MI">
                    <mat-card-header>
                        <h3>Additional Items</h3>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="col-md-4">
                            <mat-form-field color="warn" appearance="outline">
                                <span matPrefix>$&nbsp;</span>
                                <input matInput type="number" placeholder="Price Adjustment"
                                    formControlName="priceAdjustment" />
                            </mat-form-field>
                            <mat-form-field>
                                <textarea matInput formControlName="notes" placeholder="Notes" rows="10"></textarea>
                                <mat-error *ngIf="serviceEstimateForm.controls['notes'].hasError('required')">Notes
                                    are
                                    required for Price Adjustment</mat-error>
                            </mat-form-field>
                            <!-- <mat-form-field>
                                <span matPrefix>$&nbsp;</span>
                                <input matInput readonly placeholder="Total" formControlName="totalAmount" />
                            </mat-form-field> -->
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div *ngIf="!isValid">
                <h3 class="red-text">Check required fields.</h3>
            </div>
        </mat-card-content>
    </mat-card>
</form>
