<span>
    <input type="text" #select="matAutocompleteTrigger" [ngStyle]="{display: isSelectOpened? '' : 'none'}" class="adjust-width"
           placeholder="Tag"
           aria-label="Tag"
           matInput
           [formControl]="tag"
           [matAutocomplete]="auto" />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [panelWidth]="200" [ngStyle]="{display: isSelectOpened? '' : 'none'}" (optionSelected)="selectedOtopn($event)">
      <mat-option *ngFor="let tag of filteredOptionTags| async " [value]="tag.name" [id]="tag.id" [matTooltip]="tag.name">
        {{tag.name}}
      </mat-option>
    </mat-autocomplete>
  </span>
