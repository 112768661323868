import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { StorageType } from './discovery-datasource';
import { OptimizationType, Workload } from './workload-datasource';

//export class Assessment {
//  id: string;
//  date: Date;
//  title?: string;
//  source: string;
//  target: string;
//  cost: string;
//}

export class Assessment {
  id: string;
  date: Date;
  title: string;
  region: string;
  paymentOption: string;
  discountPercentage: number;
  //cloudProvider: CloudProvider;
  cloudProviderName: string;
  cloudProviderId: string;

  computeSummary: any;
  networkSummary: any;
  storageSummary: any;
  pricingSummary: any;


  serviceEstimateId?: string;
  networkProfileId?: string;
  projectId?: string;
  discoveryId?: string

  existingEnvironmentDetails: any;
  cloudSolutionDetails: any;
  paymentOptions: any;
  regionOptions: any;
  networkProfileOptions: any;
  cloudProviderOptions: any;
  managedServicePercent: number;
  storageType: string;
  storageAmount:number;
  vpnRate: any;
  bandwidthRate: any;
  bandwidth: any;
  dataTransferTimeMinutes: any;
  vpnProvider: string;
  vpnProviderOptions: any;
  licenseCost: any;

}

export class ComputeSummary {
  label: string;
  vmServers: number;
  vmCores: number;
  vmRam: number;
  vmCoresAtUtil: number;
  vmRamAtUtil: number;
}

export class NetworkSummary {
  label: string;
  bandwidth: number;
  cost: number;
}

export class StorageSummary {
  label: string;
  capacity: number;
  cost: number;
  storageType: StorageType;
}

export class CloudSolutionDetail {
  id: string;
  instanceType: string;
  osSoftware: string;
  category: string;
  ram: string;
  vCPU: string;
  pricePerHour: string;
  pricePerMonth: string;
  annualPrice: string;
}

export class SourceEnvironmentDetail {
  id: string;
  environment: string;
  vmId: string;
  vmRam: string;
  vmCores: string;
  vmCpu: string;
}

export class CloudProvider {
  id: string;
  virtualMachineCloudProducts: Array<VirtualMachineCloudProducts>;
}

export class VirtualMachineCloudProducts {
  id: string;
  cpu: number;
  memoryInGigabytes: string;
  operatingSystem: string;
  optimizationType: OptimizationType;
}

export enum RegionOptions {
  "Americas - Brazil South",
  "Americas - Canada Central",
  "Americas - Central US",
  "Americas - East US",
  "Americas - East US 2",
  "Americas - South Central US",
  "Americas - US Gov Virginia",
  "Americas - West US 2",
  "Americas - West US 3"
}

export enum PaymentOptions {
  "On Demand",
  "Reserved Instances",
}

export class AssessmentDataSource extends DataSource<any> {
  data: Array<any>;
  paginator: MatPaginator;
  sort: MatSort;
  nonFilteredData;
  projectId: string;

  constructor(private assessments: Array<any>) {
    super();
    if (assessments) {
      this.data = assessments;
    } else {
      this.data = [];
    }
    this.nonFilteredData = assessments;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

  filter(field: any, fullData: any) {

    let filterApplied = false;
    let filterData: any[] = fullData;
    //if (field['namefilter']) {
    //  filterdata = filterdata.filter(x => {
    //    return x.name.tolocalelowercase().indexof(field['namefilter'].tolocalelowercase()) > -1;
    //  });
    //}
    return filterData;
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: any[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: any[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'source': return compare(a.source, b.source, isAsc);
        case 'target': return compare(a.target, b.target, isAsc);
        case 'title': return compare(a.title, b.title, isAsc);
        case 'date': return compare(a.date, b.date, isAsc);
        case 'cost': return compare(a.cost, b.cost, isAsc);
        case 'environment': return compare(a.environment, b.environment, isAsc);
        case 'vmCores': return compare(a.vmCores, b.vmCores, isAsc);
        case 'vmCpu': return compare(a.vmCpu, b.vmCpu, isAsc);
        case 'vmRam': return compare(a.vmRam, b.vmRam, isAsc);
        case 'vmId': return compare(a.vmId, b.vmId, isAsc);
        case 'category': return compare(a.category, b.category, isAsc);
        case 'instanceType': return compare(a.instanceType, b.instanceType, isAsc);
        case 'osSoftware': return compare(a.osSoftware, b.osSoftware, isAsc);
        case 'pricePerHour': return compare(a.pricePerHour, b.pricePerHour, isAsc);
        case 'ram': return compare(a.ram, b.ram, isAsc);
        case 'vCPU': return compare(a.vCPU, b.vCPU, isAsc);
        case 'annualPrice': return compare(a.annualPrice, b.annualPrice, isAsc);
        default: return 0;
      }
    });
  }

}
/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
