import { Component, OnInit } from '@angular/core';
import { SolutionPilarDataSource } from '../../models/solution-pilar.datasource';
import { SolutionPilar } from '../../models/solution-pilar';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BusinessPartnerService } from '../../business-partner.service';
import { debounceTime } from 'rxjs/operators';
import { SessionStorageService } from '../../../shared/services/session-storage.service';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { RoleService } from '../../../shared/services/role.service';

@Component({
  selector: 'app-solution-pilar-list',
  templateUrl: './solution-pilar-list.component.html',
  styleUrls: ['./solution-pilar-list.component.scss']
})
export class SolutionPilarListComponent implements OnInit {
  solutionPilarDataSource: SolutionPilarDataSource;
  solutionPilars: Array<SolutionPilar>;
  displayedColumns: Array<string>;
  displayedInActiveColumns: Array<string>;
  filterForm: UntypedFormGroup;
  sessionInfo: any;
  sessionStorageKey: string;
  roles: Array<string> | string;
  user: AccountInfo | null;
  canModify: boolean;

  constructor(private businessPartnerService: BusinessPartnerService,
    private fb: UntypedFormBuilder,
    private sessionStorageService: SessionStorageService,
    private authService: MsalService,
    private roleService: RoleService  ) { }

  ngOnInit() {
    this.roles = [];
    this.canModify = false;
    this.displayedColumns = [
      'name',
      'tag',
      'actions'
    ];
    this.displayedInActiveColumns = [
      'name',
      'tag'
    ];
    this.filterForm = this.fb.group({
      solPilarName: [''],
      solPilarTag: [''],
      solPilarStatus: [0]
    });
    this.sessionStorageKey = 'solPilarsData';
    let storageData = this.sessionStorageService.fetchStorageData(this.sessionStorageKey);
    if (storageData) {
      this.sessionInfo = this.sessionStorageService.parseStorageData(storageData);
    } else {
      this.sessionInfo = this.filterForm.value;
      this.sessionStorageService.setStorageData(this.sessionStorageKey, this.filterForm.value);
    }
    this.setupPermissions();
    this.onFormValueChange();
    this.getSolutionPilars();
  }

  setupPermissions() {
    this.user = this.authService.instance.getActiveAccount();
    if (this.hasRole('PortalAdmin') || this.hasRole('PortalSEDirector')) {
      this.canModify = true;
    }
  }

  onFormValueChange() {
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.solutionPilarDataSource = new SolutionPilarDataSource(this.solutionPilarDataSource.filter(res, this.solutionPilars));
        this.sessionInfo = this.filterForm.value;
        this.sessionStorageService.setStorageData(this.sessionStorageKey, this.sessionInfo);
        this.setSoltutionPilarDataSource(this.solutionPilarDataSource.data);
      });
  }

  getSolutionPilars() {
    this.businessPartnerService.getAllSolutionPilars()
      .subscribe(res => {
        this.solutionPilars = res;
        this.setSoltutionPilarDataSource(res);
        this.updateForm();
      },
        (error) => {

        });
  }

  setSoltutionPilarDataSource(data: Array<SolutionPilar>) {
    this.solutionPilarDataSource = new SolutionPilarDataSource(data);
  }

  deleteSolutionPilar(id: string) {
    this.businessPartnerService.deleteSolutionPilar(id)
      .subscribe(res => {
        this.getSolutionPilars();

      });
  }

  resetFilters() {
    this.filterForm.controls['solPilarName'].setValue('');
    this.filterForm.controls['solPilarTag'].setValue('');
    this.filterForm.controls['solPilarStatus'].setValue(0);
  }

  updateForm() {
    this.filterForm.patchValue({
      solPilarName: this.sessionInfo['solPilarName'] ? this.sessionInfo['solPilarName'] : '',
      solPilarTag: this.sessionInfo['solPilarTag'] ? this.sessionInfo['solPilarTag'] : '',
      solPilarStatus: this.sessionInfo['solPilarStatus'] == null || this.sessionInfo['solPilarStatus'].toString() == "" ? '' : this.sessionInfo['solPilarStatus']
    });
  }

  getDisplayedColumns() {
    return this.filterForm.controls['solPilarStatus'].value == 0 && this.canModify ? this.displayedColumns : this.displayedInActiveColumns;
  }

  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, false);
  }
}
