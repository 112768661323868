import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HomeService } from './home/home.service';
import { ManagedServicesProviderModule } from './managed-services-provider/managed-services-provider.module';
// import { ServiceEstimatorComponent } from './service-estimator/service-estimator.component';
// import { ServiceEstimatorService } from './service-estimator/service-estimator.service';
// import { ServiceEstimateDetailComponent } from './service-estimator/service-estimate-detail/service-estimate-detail.component';
// import { ServiceEstimateCreateComponent } from './service-estimator/service-estimate-create/service-estimate-create.component';
// import { ServiceEstimateListComponent } from './service-estimator/service-estimate-list/service-estimate-list.component';
import { LogoutComponent } from './logout/logout.component';
import { DocumentCreatorModule } from './document-creator/document-creator.module';
import { FleetManagementModule } from './fleet-management/fleet-management.module';
import { LandingPageComponent } from './landing-page/landing-page.component';

import { AdminModule } from './admin/admin.module';
import { BusinessPartnersModule } from './business-partners/business-partners.module';
import { UserIdleModule } from './core/services/user-idle/user-idle.module';
import { ProfessionalServicesModule } from './professional-services/professional-services.module';
import { SupportModule } from './support/support.module';
import { CanDeactivateGuard } from './core/models/UnsavedChanges/can-deactivate-form-unsaved.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HelpModule } from './help/help.module';
import { TagsModule } from './tags/tags.module';
import { EmployeeModule } from './employee/employee.module';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { ClientsModule } from './clients/clients.module';
import { AdminHelpVideosModule } from './admin-help-videos/admin-help-videos.module';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { azureAdMsalModule, MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './core/services/azure-ad-auth.service';
import { ReportsModule } from './reports/reports.module';
import { CloudMigrationToolComponent } from './cloud-migration-tool/cloud-migration-tool.component';
import { CloudMigrationToolModule } from './cloud-migration-tool/cloud-migration-tool.module';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { CommonModule } from '@angular/common';
import { ScollTopComponent } from './shared/components/scoll-top/scoll-top.component';
import { LoadingPopupComponent } from './shared/components/loading-popup/loading-popup.component';
import { FooterComponent } from './footer/footer.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { CloudMigrationToolAdminModule } from './cloud-migration-tool-admin/cloud-migration-tool-admin.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { SalesForceTestingListComponent } from './salesforce-testing/salesforce-testing-list/salesforce-testing-list.component';
import { SalesforceTestingCreateComponent } from './salesforce-testing/salesforce-testing-create/salesforce-testing-create.component';
import { SalesforceComponent } from './salesforce-testing/salesforce.component';
import { MyProfileModule } from './my-profile/my-profile.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LogoutComponent,
    LogoutComponent,
    LandingPageComponent,
    NotFoundComponent,
    CloudMigrationToolComponent,
    ScollTopComponent,
    LoadingPopupComponent,
    FooterComponent,
    SalesForceTestingListComponent,
    SalesforceTestingCreateComponent,
    SalesforceComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    CoreModule,
    FormsModule, 
    AppRoutingModule,
    MyProfileModule,
    ManagedServicesProviderModule,
    DocumentCreatorModule,
    FleetManagementModule,
    AdminModule,
    CloudMigrationToolAdminModule,
    BusinessPartnersModule,
    ProfessionalServicesModule,
    // UserIdleModule.forRoot({ timeout: 600, idle: 14400, ping: 120 }),
    SupportModule,
    HelpModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ClientsModule,
    TagsModule,
    EmployeeModule,
    AdminHelpVideosModule,
    MsalModule,
    ReportsModule,
    CloudMigrationToolModule,
    NgxJsonViewerModule,
    DashboardModule
  ],
  providers: [
    HomeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    CanDeactivateGuard
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    var darkThemeLocalStorageValue = localStorage.getItem('useDarkTheme');
    let darkTheme;
    if (darkThemeLocalStorageValue) {
      darkTheme = JSON.parse(darkThemeLocalStorageValue);
    }
    if (darkTheme) {
      if (darkTheme['useDarkTheme']) {
        overlayContainer.getContainerElement().classList.add('dark-theme');
      }
    }
  }
}
