import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-update-expiration-date-dialog',
  templateUrl: './update-expiration-date-dialog.component.html',
  styleUrls: ['./update-expiration-date-dialog.component.scss']
})
export class UpdateExpirationDateDialogComponent implements OnInit {
  minDate: Date;
  maxDate: Date;

  constructor(
    public dialogRef: MatDialogRef<UpdateExpirationDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // Expecting data to include `approvedDate`
  ) { }

  ngOnInit() {
    const currentDate = new Date(); // Current date
    this.minDate = currentDate; // Set minDate to current date
    this.maxDate = this.getMaxDate(this.data.approvedDate);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  getMaxDate(approvedDate: Date): Date {
    if (!approvedDate) {
      return new Date(); // Fallback to the current date if no approvedDate is provided
    }
    const approvedDateObj = new Date(approvedDate);
    approvedDateObj.setDate(approvedDateObj.getDate() + 90); // Add 90 days
    return approvedDateObj;
  }
}
