import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';


@Component({
  selector: 'app-cloud-migration-discovery-network',
  templateUrl: './cloud-migration-discovery-network.component.html',
  styleUrls: ['./cloud-migration-discovery-network.component.scss']
})
export class CloudMigrationDiscoveryNetworkComponent implements OnInit {
  formTitle: string = 'Load Network Data';
  networkDataForm: UntypedFormGroup;
  discoveryId: string;
  projectId: any;
  environmentOptions: any[];
  networkDataOptions: any[];
  message: string;
  loadResult: any;
  dataLoaded: boolean = false;

  constructor(private cloudMigrationService: CloudMigrationToolService, private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CloudMigrationDiscoveryNetworkComponent>,
    private router: Router, private activeRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) private data: any) {
  }


  ngOnInit(): void {
    this.discoveryId = this.data.discoveryId;
    
    let formData = {};
    this.environmentOptions =  [
      {"text": "Windows", "value": "Windows"},
      {"text": "*nix/Mac", "value": "Linux"},
      {"text": "IBM", "value": "IBM"},
    ];
    this.networkDataOptions =  [
      {"text": "Traceroute", "value": "Traceroute"},
      {"text": "Ping", "value": "Ping"},
      {"text": "IPerf3", "value": "IPerf3"},
      // {"text": "Routing Table/netstat", "value": "RoutingTable"},
      {"text": "Interface", "value": "Interface"},
    ];
    this.networkDataForm = this.createFormGroup(formData);
  }

  createFormGroup(data: any): UntypedFormGroup {
    if (data) {
      return this.fb.group({
        discoveryId: new UntypedFormControl(this.discoveryId, Validators.required),
        dataSource: new UntypedFormControl(data?.networkDataSource, Validators.required),
        environment: new UntypedFormControl(data?.environment, Validators.required),
        networkData: new UntypedFormControl("", Validators.required),
      })
    }
    return this.fb.group({
      dataSource: [""],
      discoveryId: [this.discoveryId],
      environment: ["", Validators.required],
      networkData: ["", Validators.required],
    })
  }
  get networkData(): any { return this.networkDataForm.get('networkData'); }
  refresh()
  {
    this.loadResult  = {};
    this.dataLoaded = false;
    this.networkDataForm.setValue(this.networkData.reset());
  }
  
  save() {
    var formVal = this.networkDataForm.value;
    this.message = '';
    this.cloudMigrationService.saveDiscoveryNetworkData(this.discoveryId, this.networkDataForm.value)
      .then((res:any)=>{
        this.loadResult = res.data.networkData;
      });
  }
  
  load() {
    var formVal = this.networkDataForm.value;
    this.message = '';
    this.cloudMigrationService.loadDiscoveryNetworkData(this.discoveryId, this.networkDataForm.value)
      .then((res:any)=>{
        this.loadResult = res.data.networkData;
        this.dataLoaded = true;
      });
  }

  close() {
    this.dialogRef.close(this.networkDataForm.value);
  }
}
