import { Component, OnInit } from '@angular/core';
import { LocationService } from '../../location.service';

@Component({
  selector: 'app-county-list',
  templateUrl: './county-list.component.html',
  styleUrls: ['./county-list.component.scss']
})
export class CountyListComponent implements OnInit {

  constructor(private locationService: LocationService) { }

  ngOnInit() {
  }

}
