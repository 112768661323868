<mat-card>
  <mat-card-title>Admin Help Videos</mat-card-title>
  <mat-card-content>
    <div [class.loading]="!loading" style="position: relative; margin:auto;width:calc(50% + 225px)">
      <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
    </div>
    <mat-card [class.loading]="loading">
      <mat-card-content>
        <button mat-raised-button [routerLink]="['create']"
                color="primary">
          Create/Upload Video
        </button>
        <br />
        <div class="col-md-12">
          <fieldset>
            <legend>
              Search Selection
            </legend>
            <form [formGroup]="filterForm">
              <mat-form-field>
                <mat-label>Filter By Name</mat-label>
                <input matInput formControlName="nameFilter" type="text" id="videoName">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Filter By Section</mat-label>
                <input matInput formControlName="sectionFilter" type="text" id="videoSection">
              </mat-form-field>
              <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters" id="resetBtn">
                <mat-icon>clear</mat-icon>
              </button>
            </form>
          </fieldset>
        </div>
        <br />
        <div class="col-md-12">
          <mat-table mat-table matSort class="full-width-table apply-border" aria-label="Admin Help Videos Table" [dataSource]="videoListDataSource" [class.loading]="loading">
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="isActive">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.isActive}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="section">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Section</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.section}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <button id="kebabMenu" [attr.data-id]="row.id" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item color="accent" type="button" [routerLink]="['details',row.id]">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item color="warn" type="button" (click)="deleteVideo(row.id)">
                    <mat-icon>delete_outline</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <div *ngIf="!(videoListDataSource && videoListDataSource.data.length > 0)" class="no-records">
            No records found
          </div>
        </div>

        <mat-paginator [class.loading]="loading" #paginator [length]="videoListDataSource?.data.length" [pageIndex]="0" [pageSize]="50"
                       [pageSizeOptions]="[5, 25, 50, 100]">
        </mat-paginator>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>
