<mat-card *ngIf="projectForm">

  <mat-card-content>
    <form #form="ngForm" [formGroup]="projectForm" (ngSubmit)="createProject()" *ngIf="!isLoading">
      <mat-toolbar color="accent">
        <button mat-icon-button type="submit" color="default" [disabled]="!projectForm.valid" title="Save/Update">
          <mat-icon>save</mat-icon>
        </button>
        <span *ngIf="projectId" class="flex-wrap">
          <span *ngFor="let item of tagItems.controls;let i = index">
            <button type="button" mat-raised-button (click)="openTagPopup(tagItems.controls[i].value.tagId)" [ngClass]="isRemoveBtnDisabled? 'no-click' : ''" [disabled]="isRemoveBtnDisabled">
              {{getTagName(tagItems.controls[i].value.tagId)}}
              <mat-icon class="md-18 cursor" (click)="removeTagItem(i, $event)">
                clear
              </mat-icon>
            </button>
          </span>
          <span (clickOutside)="toggleSelectOpened()" appClickOutside (keydown.Tab)="toggleSelectOpened()">
            <button mat-icon-button matTooltip="Add Tag" class="icon-ht" (click)="toggleSelect()" type="button" [ngStyle]="{display: isSelectOpened? 'none' : ''}" [disabled]="tagItems.value.length == 5">
              <mat-icon>add</mat-icon>
            </button>
            <app-tag *ngIf="tags" (tagSelected)="tagSelected($event)" [actualTags]="actualTags" [isSelectOpened]="isSelectOpened" [tagItems]="tagItems" #tag></app-tag>
          </span>
        </span>
        <span class="fill-remaining-space"></span>
        <span *ngIf="!isCmtProj">
          <button matTooltip="Project Tools" type="button" mat-icon-button [matMenuTriggerFor]="menu"
                  aria-label="Project">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button type="button" mat-menu-item (click)="openProjectDocumentUploadPopup()">
              <mat-icon>cloud_upload</mat-icon>
              <span class="menu-text">Upload Document</span>
            </button>
            <button *ngIf="projectId && isReadyToAssignPostSalesResources && businessPartnerItems?.length > 0"
                    mat-menu-item type="button" (click)="openBusinessPartnerScorecard()">
              <mat-icon>score</mat-icon>
              <span class="menu-text">Grade BP Score Card</span>
            </button>
          </mat-menu>
        </span>
      </mat-toolbar>
      <div style="margin-left: 20px;margin-right: 20px;">
        <h3 style="margin: 10px;">
          <span *ngIf="projectId">Update</span> <span *ngIf="!projectId">Create</span> a
          Project <br /> <mat-hint *ngIf="projectId && businessPartnerItems?.length > 0 && !isCmtProj" id="hint">
            In order for a project to be marked closed a
            business partner scorecard should be completed
          </mat-hint>
        </h3>
        <mat-form-field>
          <input id = "pName" matInput placeholder="Project Name" type="text" formControlName="projectName" required>
          <mat-error *ngIf="projectForm.controls['projectName'].hasError('required')">
            An Project Name is required.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input readonly matInput placeholder="Client" type="text" [(ngModel)]="clientName" required
                 [ngModelOptions]="{standalone: true}">
          <mat-error *ngIf="projectForm.controls['clientId'].hasError('required')">
            A Client is required.
          </mat-error>
          <span matSuffix>
            <mat-icon (click)="openPopup()">search</mat-icon>
          </span>
        </mat-form-field>
        <mat-form-field>
          <input id= "oppQField" matInput placeholder="BF Number" type="text" formControlName="oppurtunityNumber" required>
          <mat-error *ngIf="projectForm.controls['oppurtunityNumber'].hasError('required')">
            A BF Number is
            required.
          </mat-error>
          <p class="error" *ngIf="!uniqueName">Unique BF Number required. Please check BF Number and try again.</p>
        </mat-form-field>
        <mat-form-field *ngIf="projectId">
          <mat-select placeholder="Project Status" formControlName="serviceProjectStatus" disableOptionCentering>
            <mat-option [value]="0">Active</mat-option>
            <mat-option [value]="1">Inactive</mat-option>
            <mat-option [value]="2">Lost</mat-option>
            <mat-option *ngIf="projectCanBeClosed" [value]="3">Complete</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox *ngIf="projectId && canAddPostSales && !isCmtProj"
                      formControlName="isAllResourcesAssigned">All resources assigned</mat-checkbox>
        <br>
        <div class="col-md-12" *ngIf="!isCmtProj">
          <h4 style="margin-bottom: 5px;">Scope Risk</h4>
          <mat-checkbox #isScopeRiskEnabled [value]="false" formControlName="isScopeRiskEnabled">
            Add Scope Risk%
          </mat-checkbox>
          <mat-form-field>
            <input matInput currencyMask placeholder="Enter Scope Risk% Value" [readonly]="!projectForm.controls['isScopeRiskEnabled'].value" formControlName="scopeRiskPercentage" />
            <span matSuffix>%</span>
          </mat-form-field>
        </div>
        <br>
        <div class="col-md-12 display-flex" *ngIf="projectId">
          <fieldset class="col-md-6" style="margin-right: 10px;">
            <legend><b>Pre Sales Resources</b></legend>
            <div class="col-md-12" *ngIf="employees">
              <h4 style="margin-bottom: 5px;"></h4>
              <mat-form-field class="col-md-8" style="padding: 0px;">
                <mat-select [(ngModel)]="assignedEmployee.employeeId" placeholder="Select an Employee to Assign" (selectionChange)="selectedEmployee($event)"
                            [ngModelOptions]="{standalone: true}" disableOptionCentering>
                  <mat-option *ngFor="let employee of employees" [value]="employee.employeeId" [disabled]="isEmployeeDisabled(employee.employeeId)">
                    {{employee.firstName + ' ' + employee.lastName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-raised-button type="button" (click)="assignEmployee()" color="primary" [disabled]="!isAssignBtnEnabled">Assign Employee</button>
            </div>
            <div class="col-md-6" *ngIf="projectId && assignedEmployees">

              <mat-list>
                <mat-list-item *ngFor="let employee of assignedEmployees">
                  {{employee.firstName + ' ' + employee.lastName}}
                  <button mat-icon-button type="button"
                          (click)="unassignEmployee(employee.serviceProjectsAssignedEmployeeId)">
                    <mat-icon>remove</mat-icon>
                  </button>
                </mat-list-item>
              </mat-list>
            </div>
          </fieldset>
          <fieldset class="col-md-6" *ngIf="isReadyToAssignPostSalesResources && canAddPostSales && !isCmtProj">
            <legend><b>Post Sales Resources</b></legend>
            <div class="row">

              <div class="col-md-6">
                <h4 style="margin-left: 10px;">Post Sales Resources</h4>
                <div *ngFor="let se of project.serviceEstimates">
                  <div *ngIf="se.serviceEstimateStatus == 4" style="margin-left: 15px;">
                    <h4>
                      <span *ngIf="se.isChangeOrder">Change Order:</span> <span *ngIf="!se.isChangeOrder">
                        Service
                        Estimate:
                      </span> {{se.name}}
                    </h4>
                    <h4 style="margin-bottom: 0;margin-left: 10px;">Service Roles</h4>
                    <app-post-sales-tree [postSaleAssignedResources]="project.serviceProjectPostSalesAssignedEmployees" (isResourceDataUpdated)="refreshPostSalesData()"
                                         [serviceEstimateComponents]="se.serviceEstimateComponents" [projectId]="projectId">
                    </app-post-sales-tree>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
