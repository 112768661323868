<div class="" *ngIf="milage">
  <div *ngIf="mileageForm">
    <mat-card>
      <mat-card-content>
        <div class="col-md-12">

          <p *ngIf="mileageUpdatedSuccesfully">{{mileageConfirmationMessage}}</p>
        </div>
        <form [formGroup]="mileageForm" (ngSubmit)="saveMileage()">
          <div class="col-md-4">
            <mat-form-field>
              <input type="text" placeholder="Description" [(ngModel)]="milage.vehicleUsageNote" matInput formControlName="vehicleUsageNote"
              />
              <mat-error *ngIf="vehicleUsageNote.hasError('required')">A description is required</mat-error>

            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <input type="number" placeholder="Milage Start" readonly="readonly" [(ngModel)]="milage.vehicleUsageMileageStart" matInput
                formControlName="vehicleUsageMileageStart" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-select formControlName="vehicleUsageType" placeholder="Usage Type" [(ngModel)]="milage.vehicleUsageType" disableOptionCentering>
                <mat-option [value]="0">to/from Client</mat-option>
                <mat-option [value]="1">to/from Meridian office</mat-option>
              </mat-select>
              <mat-error *ngIf="mileageForm.controls['vehicleUsageType'].hasError('required')">A usage type is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <input type="number" placeholder="Milage End" [(ngModel)]="milage.vehicleUsageMileageEnd" matInput formControlName="vehicleUsageMileageEnd"
              />
              <mat-error *ngIf="vehicleUsageMileageEnd.hasError('required')">The end milage is required</mat-error>

              <mat-error *ngIf="vehicleUsageMileageEnd.hasError('min')">Milage cannot be less than 0 or less than current mileage</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <input matInput [matDatepicker]="picker" [(ngModel)]="milage.vehicleUsageDate" placeholder="Vehicle Usage Date" formControlName="vehicleUsageDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="vehicleUsageDate.hasError('required')">A usage date is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4" *ngIf="isAdmin">
            <ng-template #loading>
              <p>Loading the list of drivers</p>
            </ng-template>
            <mat-form-field *ngIf="drivers; else loading">
              <mat-select [(ngModel)]="milage.createdByEmployeeId" formControlName="createdByEmployeeId" placeholder="Select a driver" disableOptionCentering>
                <mat-option *ngFor="let driver of drivers" [value]="driver.employeeId">{{driver.firstName + ' ' + driver.lastName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <button [disabled]="!mileageForm.valid" mat-raised-button color="primary" type="submit">Update Mileage</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <br>

  <app-vehicle-expenses-create [comboPage]="true"></app-vehicle-expenses-create>
  <!-- <mat-card>
    <form [formGroup]="expenseForm" (ngSubmit)="addVehicle()">
      <mat-card-content>
        <div class="col-md-12">
          <p *ngIf="expenseUpdatedSuccesfully">{{expenseConfirmationMessage}}</p>
        </div>
        <div class="col-md-12">
          <mat-form-field>
            <input placeholder="Description" type="text" matInput [(ngModel)]="model.vehicleExpenseDescription" formControlName="vehicleExpenseDescription"
              name="vehicleExpenseDescription" />
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field>
            <input placeholder="Cost" type="number" matInput [(ngModel)]="model.vehicleExpenseCost" formControlName="vehicleExpenseCost"
              name="vehicleExpenseCost" />
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field>
            <mat-select placeholder="Expense Type" [(ngModel)]="model.vehicleExpenseType" formControlName="vehicleExpenseType" name="vehicleExpenseType">
              <mat-option [value]="0">Fuel</mat-option>
              <mat-option [value]="1">Maintenance</mat-option>
              <mat-option [value]="2">Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <div>
            <mat-form-field>
              <input [readonly]="true" matInput type="text" [value]="fileName" placeholder="Select a File" (focus)="imgFileInput.click()"
              />
              <span matSuffix>
                <button mat-button mat-icon-button color="accent">
                  <mat-icon>attachment</mat-icon>
                </button>
              </span>
            </mat-form-field>
            <input hidden type="file" #imgFileInput (change)="updateReciptFile($event)" />
          </div>
        </div>
        <br>
        <button mat-raised-button type="submit" [disabled]="!expenseForm.valid">Update Expenses</button>
      </mat-card-content>
    </form>
    <mat-card-actions>
    </mat-card-actions>
  </mat-card> -->

  <button mat-raised-button type="button" (click)="completeAddingMileandExpense()">Complete</button>
</div>
