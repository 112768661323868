import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ServiceEstimatorService } from '../../_services/service-estimator.service';
import { ProjectPhase } from '../../models/service-estimate';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-professional-services-estimate-detail-item',
  templateUrl: './professional-services-estimate-detail-item.component.html',
  styleUrls: ['./professional-services-estimate-detail-item.component.scss']
})
export class ProfessionalServicesEstimateDetailItemComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input() expanded = false;
  @Input() formGroup: UntypedFormGroup;
  @Input() items: UntypedFormArray;
  @Input() formArrayName: string;
  @Input() phase: string;
  @Input() isMutilplierReadOnly: boolean = true;
  @Input() serviceRoles: Array<any>;
  @Input() phaseItemType: string;
  @Input() serviceEstimateType: string;
  @Output() addPlanItem: EventEmitter<null> = new EventEmitter<null>();
  @Output() addExecuteItem: EventEmitter<null> = new EventEmitter<null>();
  @Output() addCloseItem: EventEmitter<null> = new EventEmitter<null>();

  formItems: UntypedFormControl[];

  constructor(private soltuionService: ServiceEstimatorService) { }

  ngOnInit() {
    this.formItems = this.sortItems(<UntypedFormControl[]>this.items.controls);
  }
  removeControl(control: any, index: any) {
    const id = this.items.controls[index].value['id'];
    if (id) {
      if (this.phaseItemType == 'standard') {
        this.soltuionService.deleteServiceEstimateDetailItem(id)
          .subscribe(res => {
            this.items.removeAt(index);

          });
      }
      else if (this.phaseItemType == 'template') {
        this.soltuionService.deleteServiceEstimateDetailItemTemplate(id)
          .subscribe(res => {
            this.items.removeAt(index);

          });

      } else if (this.phaseItemType == 'changeOrder') {
        this.soltuionService.deleteServiceEstimateDetailItemChangeOrder(id)
          .subscribe(res => {
            this.items.removeAt(index);

          });
      }
    } else {
      this.items.removeAt(index);
    }
  }
  convertStringToInt(value: any) {
    let newValue = parseFloat(value);
    return parseFloat(value);
  }
  rearrangePhaseId(items: UntypedFormControl[]) {
    let rearraneItems = items.filter(x => x.value.phaseItemOrder == null);
    if (rearraneItems.length) {
      rearraneItems.forEach((item, index) => {
        let phaseItemOrderVal = Math.max.apply(Math, items.map(function (x) { return x.value.phaseItemOrder; }));
        item.value.phaseItemOrder = phaseItemOrderVal + 1;
      }, this);
      //this.items.patchValue(items);
    }
    return items;
  }
  sortItems(items: UntypedFormControl[]) {
      items = this.rearrangePhaseId(items);
      items = items.sort((a, b) => {
        const itemA = a.value.phaseItemOrder;
        const itemB = b.value.phaseItemOrder;
        if (itemA < itemB) { return -1; }
        if (itemA > itemB) { return 1; }
        return 1;
      });
      return items;
  }
  getServiceRole(item: UntypedFormGroup) {
    let roleId = item.controls['serviceRoleId'].value;
    let serviceRole = this.serviceRoles.find(x => x.serviceRoleId == roleId);
    return serviceRole ? serviceRole.serviceRoleName + '(' + serviceRole.serviceRoleDescription + ')' : null;
  }
}
