import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LocationService {

  constructor(private http: HttpClient) { }
  getCounties() {
    return this.http.get<any[]>(`/api/locations/counties`);
  }
  getCounty(id: string) {
    return this.http.get<any>(`/api/locations/counties/${id}`);
  }
  getState(id: string) {
    return this.http.get<any>(`/api/locations/states/${id}`);
  }
  getStates() {
    return this.http.get<any[]>(`/api/locations/states`);
  }
  getCity(id: string) {
    return this.http.get<any>(`/api/locations/cities/${id}`);
  }

  getCityByName(name: string) {
    return this.http.get<any>(`/api/cities/filter?name=${name}`);
  }
  getCities() {
    return this.http.get<any[]>(`/api/locations/cities`);
  }
  getCitiesByStateId(stateId: string) {
    return this.http.get<any[]>(`/api/locations/states/${stateId}/cities`);
  }
  getCitiesByStateIds(stateIds: any[]) {
    return this.http.post<any[]>(`/api/locations/states/cities`, stateIds);
  }
  getCountiesByStateId(stateId: string) {
    return this.http.get<any[]>(`/api/locations/states/${stateId}/counties`);
  }

}
