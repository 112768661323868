<div class="container-fluid">

    <button mat-raised-button color="accent" [routerLink]="['create']">Create</button>
    <mat-card *ngIf="employees">
        <h2>Gas Cards</h2>
        <mat-card-content *ngIf="gasCardsDataSource">
            <mat-table #table [dataSource]="gasCardsDataSource">
                <ng-container matColumnDef="assignedEmployee">
                    <mat-header-cell *matHeaderCellDef> Assigned Driver </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{filterEmployees(element.assignedToEmployeeId)}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cardNumber">
                    <mat-header-cell *matHeaderCellDef> Gas Card Number </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.vehicleGasCardNumber}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="expirationDate">
                    <mat-header-cell *matHeaderCellDef> Gas Card Expiration </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.vehicleGasCardExpirationDate | date:'short'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cardId">
                    <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a mat-raised-button color="accent" type="button" [routerLink]="['details',element.vehicleGasCardId]" [queryParams]="{cardType:'gas'}">Details/Edit </a>
                        <button mat-raised-button color="warn" type="button" (click)="deleteGasCard(element.vehicleGasCardId)">Delete</button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

        </mat-card-content>
    </mat-card>
</div>
<div class="container-fluid">
    <mat-card *ngIf="employees">
        <mat-card-content *ngIf="hasOilCard">
            <h2>Oil Cards</h2>
            <mat-table #oilTable [dataSource]="oilCardsDataSource">
                <ng-container matColumnDef="assignedEmployee">
                    <mat-header-cell *matHeaderCellDef> Assigned Driver </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{filterEmployees(element.assignedToEmployeeId)}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="cardNumber">
                    <mat-header-cell *matHeaderCellDef> Gas Card Number </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.vehicleOilCardNumber}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="expirationDate">
                    <mat-header-cell *matHeaderCellDef> Gas Card Expiration </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.vehicleOilCardExpirationDate | date:'short'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cardId">
                    <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-raised-button color="accent" type="button" [routerLink]="['details',element.vehicleOilCardId]" [queryParams]="{cardType:'oil'}">Details/Edit </button>
                        <button mat-raised-button color="warn" type="button" (click)="deleteOilCard(element.vehicleOilCardId)">Delete</button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

        </mat-card-content>
    </mat-card>
</div>