<mat-card>
  <mat-card-content>
    <form [formGroup]="cutomerDataFrom" (ngSubmit)="onSubmit()">
      <h3>
        Customer Information
      </h3>
      <div class="col-md-12">
        <mat-form-field>
          <input type="text" id="epicorName" matInput placeholder="Name" formControlName="name" />
          <mat-error *ngIf="cutomerDataFrom.controls['name'].hasError('required')">A Customer Name is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="epicorLegalName" matInput placeholder="Legal Name" formControlName="legalName" />
          <mat-error *ngIf="cutomerDataFrom.controls['legalName'].hasError('required')">A Customer Legal Name is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="billingCity" matInput placeholder="Billing City" formControlName="billingCity" />
          <mat-error *ngIf="cutomerDataFrom.controls['billingCity'].hasError('required')">A Billing City is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="billingState" matInput placeholder="Billing State" formControlName="billingState" />
          <mat-error *ngIf="cutomerDataFrom.controls['billingState'].hasError('required')">A Billing State is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="tel" id="billingPostalCode" matInput placeholder="Billing Postal Code"
            formControlName="billingPostalCode" />
          <mat-error *ngIf="cutomerDataFrom.controls['billingPostalCode'].hasError('required')">A Billing Postal Code is
            required</mat-error>
          <mat-error *ngIf="cutomerDataFrom.controls['billingPostalCode'].hasError('pattern')">Please enter a valid postal code</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="billingStreet" matInput placeholder="Billing Street" formControlName="billingStreet" />
          <mat-error *ngIf="cutomerDataFrom.controls['billingStreet'].hasError('required')">A Billing Street is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Billing Country</mat-label>
          <mat-select formControlName="billingCountry" disableOptionCentering id="billingCountryDropdown">
            <mat-option *ngFor="let country of countries" [value]="country" id="billingCountry">
              {{country}}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="cutomerDataFrom.controls['billingCountry'].hasError('required')">A Billing Country is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Territory</mat-label>
          <mat-select formControlName="territory" disableOptionCentering id="territoryDropdown">
            <mat-option *ngFor="let territory of territories" [value]="territory" id="territory">
              {{territory}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="cutomerDataFrom.controls['territory'].hasError('required')">A Teritory is
            required</mat-error>
        </mat-form-field>
      </div>
      <h3>
        Contact Information
      </h3>
      <div class="col-md-12">
        <mat-form-field>
          <input type="text" id="contactName" matInput placeholder="Name" formControlName="contactName" />
          <mat-error *ngIf="cutomerDataFrom.controls['contactName'].hasError('required')">A Contact Name is
            required</mat-error>
            <mat-error *ngIf="cutomerDataFrom.controls['contactName'].hasError('pattern')">Please enter a valid full name</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="contactEmail" matInput placeholder="Contact Email" formControlName="contactEmail" />
          <mat-error *ngIf="cutomerDataFrom.controls['contactEmail'].hasError('required')">A Contact Email is
            required</mat-error>
          <mat-error *ngIf="cutomerDataFrom.controls['contactEmail'].hasError('email')">Please enter a valid email
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="contactRole" matInput placeholder="Contact Role" formControlName="contactRole"
            readonly="true" />
          <mat-error *ngIf="cutomerDataFrom.controls['contactRole'].hasError('required')">A Contact Role is
            required</mat-error>
        </mat-form-field>
      </div>
      <h3>
        Shipping Information
      </h3>
      <div class="col-md-12">
        <mat-form-field>
          <input type="text" id="shippingLocationStreet" matInput placeholder="Street"
            formControlName="shippingLocationStreet" />
          <mat-error *ngIf="cutomerDataFrom.controls['shippingLocationStreet'].hasError('required')">A Street is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="shippingLocationCity" matInput placeholder="City"
            formControlName="shippingLocationCity" />
          <mat-error *ngIf="cutomerDataFrom.controls['shippingLocationCity'].hasError('required')">A City is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="shippingLocationState" matInput placeholder="State"
            formControlName="shippingLocationState" />
          <mat-error *ngIf="cutomerDataFrom.controls['shippingLocationState'].hasError('required')">A State is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="tel" id="shippingLocationPostalCode" matInput placeholder="Zipcode"
            formControlName="shippingLocationPostalCode" />
          <mat-error *ngIf="cutomerDataFrom.controls['shippingLocationPostalCode'].hasError('required')">A Zipcode is
            required</mat-error>
            <mat-error *ngIf="cutomerDataFrom.controls['shippingLocationPostalCode'].hasError('pattern')">Please enter a valid postal code</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="shippingLocationCountry" matInput placeholder="Country"
            formControlName="shippingLocationCountry" readonly="true" />
          <mat-error *ngIf="cutomerDataFrom.controls['shippingLocationCountry'].hasError('required')">A Country is
            required</mat-error>
        </mat-form-field>
      </div>
      <h3>
        Billing Information
      </h3>
      <div class="col-md-12">
        <mat-checkbox class="full-width" formControlName="isAltBilling" id="isAltBilling">
          Alternate Billing
        </mat-checkbox>
      </div>
      <div class="col-md-12" *ngIf="isAltBilling">
        <mat-form-field>
          <input type="text" id="customerID" matInput placeholder="Customer ID" formControlName="customerID" />
          <mat-error *ngIf="cutomerDataFrom.controls['customerID'].hasError('required')">A Customer ID is
            required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="!isAltBilling">
        <mat-form-field>
          <input type="text" id="billingLocationStreet" matInput placeholder="Street"
            formControlName="billingLocationStreet" />
          <mat-error *ngIf="cutomerDataFrom.controls['billingLocationStreet'].hasError('required')">A Street is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="billingLocationCity" matInput placeholder="City"
            formControlName="billingLocationCity" />
          <mat-error *ngIf="cutomerDataFrom.controls['billingLocationCity'].hasError('required')">A City is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="billingLocationState" matInput placeholder="State"
            formControlName="billingLocationState" />
          <mat-error *ngIf="cutomerDataFrom.controls['billingLocationState'].hasError('required')">A State is
            required</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="tel" id="billingLocationPostalCode" matInput placeholder="Zipcode"
            formControlName="billingLocationPostalCode" />
          <mat-error *ngIf="cutomerDataFrom.controls['billingLocationPostalCode'].hasError('required')">A Zipcode is
            required</mat-error>
            <mat-error *ngIf="cutomerDataFrom.controls['billingLocationPostalCode'].hasError('pattern')">Please enter a valid postal code</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="billingLocationCountry" matInput placeholder="Country"
            formControlName="billingLocationCountry" readonly="true" />
          <mat-error *ngIf="cutomerDataFrom.controls['billingLocationCountry'].hasError('required')">A Country is
            required</mat-error>
        </mat-form-field>
      </div>
      <br>
      <div class="col-md-4">
        <button mat-raised-button [disabled]="!cutomerDataFrom.valid">Save</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>