import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { DocumentCreatorService } from '../document-creator.service';
import { DocumentCreator } from '../models/document-creator';


@Component({
  selector: 'app-document-creator-create',
  templateUrl: './document-creator-create.component.html',
  styleUrls: ['./document-creator-create.component.scss']
})
export class DocumentCreatorCreateComponent implements OnInit {
  editorValue: any;
  editorControl: UntypedFormControl;
  trustedValue: SafeHtml;
  documentCreator: DocumentCreator;

  constructor(
    //private santizier: DomSanitizer,
    private documentService: DocumentCreatorService,
    private router: Router) { }

  ngOnInit() {
    this.documentCreator = new DocumentCreator();
    this.editorValue = '';
    this.editorControl = new UntypedFormControl();
  }
  updateDisplayedValue() {
    //this.trustedValue = this.santizier.bypassSecurityTrustHtml(this.documentCreator.content);
  }
  saveDocument() {
    this.documentService.createDocument(this.documentCreator)
      .subscribe(httpResult => {
        this.router.navigate(['documentcreator']);

      });
  }


}
