import { Component, Input, OnInit } from '@angular/core';
import { Assessment } from '../../../models/assessment-datasource';

@Component({
  selector: 'app-cloud-migration-compute-summary',
  templateUrl: './cloud-migration-compute-summary.component.html',
  styleUrls: ['./cloud-migration-compute-summary.component.scss']
})
export class CloudMigrationComputeSummaryComponent implements OnInit {

  tableDataSource: any;
  @Input() assessment: Assessment;
  displayedColumns: string[];
  constructor() { }

  ngOnInit(): void {
    this.tableDataSource = this.assessment?.computeSummary?.rows;
    this.displayedColumns = this.assessment?.computeSummary?.displayedColumns;
  }
}
