import { Component, OnInit } from '@angular/core';


import { FleetMangementService } from '../../../fleet-mangement.service';

import { VehicleModelDataSource } from '../../../models/vehicle-model-datasource';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
@Component({
  selector: 'app-vehicle-models-list',
  templateUrl: './vehicle-models-list.component.html',
  styleUrls: ['./vehicle-models-list.component.scss']
})
export class VehicleModelsListComponent implements OnInit {
  dataSource: VehicleModelDataSource;
  displayedColumns = [
    'manufacturerName',
    'vehicleModelName',
    'vehcileModelYear',
    'vehicleModelId'
  ];
  constructor(private service: FleetMangementService, public dialog: MatDialog) { }

  ngOnInit() {
    this.service.getVehicleModels()
      .subscribe(res => {
        this.dataSource = new VehicleModelDataSource();
        this.dataSource.setData(res);
      });
  }
  deleteVM(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.service.deleteVehicleModel(id)
            .subscribe(res => {
              this.service.getVehicleModels()
                .subscribe(res2 => {
                  this.dataSource = new VehicleModelDataSource();
                  this.dataSource.setData(res2);
                });
            });
        }
      });


  }

}
