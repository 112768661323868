import { Component, OnInit } from '@angular/core';
import { VehicleManagerService } from '../vehicle-manager.service';
import { ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { FormBuilder, FormGroup, Validators } from '../../../../../node_modules/@angular/forms';
import { FleetMangementService } from '../../../fleet-management/fleet-mangement.service';
import { Location } from '../../../../../node_modules/@angular/common';

@Component({
  selector: 'app-vehicle-manager-update',
  templateUrl: './vehicle-manager-update.component.html',
  styleUrls: ['./vehicle-manager-update.component.scss']
})
export class VehicleManagerUpdateComponent implements OnInit {
  vmForm: FormGroup;
  vmManager: any;
  drivers: Array<any>;

  constructor(private fmService: FleetMangementService, private vmService: VehicleManagerService, private route: ActivatedRoute, private fb: FormBuilder, private location: Location) { }
  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      if (id) {
        this.fmService.getDrivers()
          .subscribe(driverResponse => {
            this.drivers = driverResponse;
            this.drivers = this.drivers.filter(d => d.vehicleManager ? d.vehicleManager.vehicleMangerId === id : true);
            this.vmService.getVehicleManager(id)
              .subscribe(vmResponse => {
                this.vmManager = vmResponse;
                const avaliableDrivers =
                  this.vmForm = this.fb.group({
                    'vehicleMangerId': [this.vmManager.vehicleMangerId],
                    'empId': [this.vmManager.empId],
                    'driverIds': [this.vmManager.drivers.map((d: any) => d.vehicleDriverId), Validators.required]
                  });

              });

          });
      }
    });
  }
  clearDrivers() {
    this.vmForm.controls['driverIds'].setValue(null);
  }

  saveManager() {
    if (this.vmForm.valid) {
      const _drivers: any[] = [];
      this.drivers.forEach(d => {
        const driverIds: Array<string> = this.vmForm.controls['driverIds'].value;
        const cocksucker = '';
        driverIds.forEach(did => {
          if (d.vehicleDriverId === did) {
            _drivers.push(d);
          }
        });
      });
      const modelToSend = new VehicleManagerUpdateModel(this.vmForm.value, _drivers);
      this.vmService.updateVehicleManager(modelToSend)
        .subscribe((updateResult) => {
          this.location.back();

        });
    }
  }


}

export class VehicleManagerUpdateModel {

  vehicleMangerId: string;
  empId: string;
  drivers: Array<any>;
  constructor(params?: any, drivers?: any) {
    this.vehicleMangerId = params.vehicleMangerId ? params.vehicleMangerId : null;
    this.empId = params.empId ? params.empId : null;
    this.drivers = drivers ? drivers : null;
  }
}

