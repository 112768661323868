
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, share, tap, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-scoll-top',
  templateUrl: './scoll-top.component.html',
  styleUrls: ['./scoll-top.component.scss']
})
export class ScollTopComponent implements OnInit {

  windowScrolled: boolean;
  constructor(@Inject(DOCUMENT) private _document: Document) { }

  scrollToTop() {
    window.document.getElementById('topSection')?.scrollIntoView();
  }
  ngOnInit() { }
  ngAfterViewInit() {
    const content = document.querySelector('.mat-sidenav-content');
    if (content) {
      const scroll$ = fromEvent(content, 'scroll').pipe(

        throttleTime(10), // only emit every 10 ms
        map(() => content.scrollTop), // get vertical scroll position
        distinctUntilChanged(), // only emit when scrolling direction changed
        share(), // share a single subscription to the underlying sequence in case of multiple subscribers })
      );
      const goingUp$ = scroll$.pipe(
        filter(direction => direction > 15)
      );

      const goingDown$ = scroll$.pipe(
        filter(direction => direction < 10)
      );

      goingUp$.subscribe(() => this.windowScrolled = true);
      goingDown$.subscribe(() => this.windowScrolled = false);
    }
  }

}

