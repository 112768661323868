<mat-card>
  <mat-card-title>
    Create Document
  </mat-card-title>
  <br />
  <button mat-button type="button" (click)="addSection()">Add Section</button>
  <button mat-button type="button" (click)="addImage()">Add Image</button>
  <div style="width:500px;">
    <mat-form-field>
      <input type="text" matInput placeholder="Document Title" />
    </mat-form-field>
    <br />
    <div *ngFor="let section of sections; let i = index">
      <div class="section">
        <mat-form-field *ngIf="section == 'section'">
          <textarea rows="10" cols="10" matInput [placeholder]="'section ' + (i+1)"></textarea>
        </mat-form-field>
      </div>
      <div class="section" *ngIf="section == 'image'">
        <br>
        <mat-form-field>
          <input type="text" matInput placeholder="Image Title">
        </mat-form-field>
        <input type="file" />
        <br>
      </div>
    </div>
    <button mat-raised-button color="primary" type="text">Create</button>
  </div>
</mat-card>
