import { Component, OnInit, Input } from '@angular/core';
import { FleetMangementService } from '../../fleet-mangement.service';
import { VehicleExpense } from '../../models/fleet-management-models';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { tap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from 'src/app/shared/models/id-token-claims';


@Component({
  selector: 'app-vehicle-expenses-create',
  templateUrl: './vehicle-expenses-create.component.html',
  styleUrls: ['./vehicle-expenses-create.component.scss']
})
export class VehicleExpensesCreateComponent implements OnInit {

  @Input() comboPage: boolean;
  model: VehicleExpense;
  vehicleId: string;
  expenseForm: UntypedFormGroup;
  fileName: string | undefined;
  reciptError: boolean;
  successMessage: string;
  addedSuccesfully: boolean;
  isLoading: boolean;
  isDriver: boolean;
  isUser: boolean;
  isAdmin: boolean;
  roles: string[] | string;
  drivers: Array<any>;
  constructor(private service: FleetMangementService, private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, private location: Location, private authService: MsalService) {
    let idTokenClaims = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
    if (idTokenClaims) {
      this.roles = idTokenClaims.roles;
      this.checkIfAdmin();
      this.checkIfUser();
    }
  }

  ngOnInit() {

    this.isDriver = false;

    if (this.isUser) {
      this.service.getAzureId().subscribe(aId => {


        this.service.checkIfDriver(aId as string)
          .subscribe(driverResult => {
            if (driverResult) {
              this.isDriver = true;
            } else {
              this.isDriver = false;
            }

          });
      });
    }
    if (this.isAdmin) {
      this.service.getDrivers()
        .subscribe(driverResponse => {
          this.drivers = driverResponse;

        });
    }
    this.isLoading = false;
    this.addedSuccesfully = false;
    this.reciptError = false;
    this.fileName = '';
    this.model = new VehicleExpense();
    this.expenseForm = this.fb.group({
      vehicleExpenseDescription: ['', Validators.required],
      vehicleExpenseCost: ['', Validators.required],
      vehicleExpenseType: ['', Validators.required],
      vehicleExpenseDate: ['', Validators.required],
      createdByEmployeeId: [null]
    });
    this.route.params
      .subscribe(params => {
        this.vehicleId = params['id'];

        this.model.vehicleId = this.vehicleId;
      });
  }
  addVehicle() {
    if (this.expenseForm.valid) {
      if (this.model.vehicleExpenseReceipt) {
        this.service.createVehicleExpense(this.model)
          .pipe(tap(() => this.isLoading = true))
          .subscribe(res => {
            this.addedSuccesfully = true;
            if (this.comboPage) {
            } else {
              this.location.back();
            }

          });
      } else {
        let newModel = { 'vehicleId': '' };
        Object.assign(newModel, this.expenseForm.value);
        newModel['vehicleId'] = this.vehicleId;
        this.service.createVehicleExpenceNoRecipet(newModel)
          .subscribe(res => {
            this.addedSuccesfully = true;
            if (this.comboPage) {
            } else {
              this.location.back();
            }

          });
      }
      this.isLoading = false;
    }

  }
  updateReciptFile(event: any) {
    this.model.vehicleExpenseReceipt = event.target.files[0];
    this.fileName = this.model.vehicleExpenseReceipt?.name;
    //this.setErrorStatus();
  }
  setErrorStatus() {
    if (this.fileName)
      this.reciptError = false;
    else
      this.reciptError = true;
  }
  checkIfAdmin() {
    this.isAdmin = this.roles.includes('FleetMangementAdmin');
    if (!this.isAdmin) {
      this.isAdmin = this.roles.includes('PortalAdmin');
    }
  }
  checkIfUser() {
    this.isUser = this.roles.includes('FleetManagementUser');
  }

}
