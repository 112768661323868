import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Video } from './admin-help-videos-list/admin-help-video-datasource';
import { of } from 'rxjs';

@Injectable()
export class AdminHelpVideosService {

  constructor(private http: HttpClient) { }
  getVideos() {
    //return of(this.data);
    return this.http.get<any[]>(`/api/Video`);
  }

  getVideo(id: string) {
    //return of(this.data[0]);
    return this.http.get<any>(`/api/Video/${id}`);
  }

  updateVideo(video: any) {
    const body = JSON.stringify(video);
    return this.http.put(`/api/Video/${video.id}`, body);
  }

  deleteVideo(id: string) {
    return this.http.delete(`/api/Video/${id}`);
  }

  uploadVideo(video: Video) {
    const body: FormData = new FormData();
    let videoFile: Blob = new Blob();
    if (video.videoFile) {
      videoFile = video.videoFile;
    }
    body.append('file', videoFile, video?.videoFile?.name);
    body.append('name', video.name);
    body.append('title', video.title.toString());
    body.append('description', video.description.toString());
    body.append('section', video.section.toString());


    return this.http.post(`/api/Video/upload`, body);
  }
}
