import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-control',
  templateUrl: './project-control.component.html',
  styleUrls: ['./project-control.component.scss']
})
export class ProjectControlComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
