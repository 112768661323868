import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LocationService } from 'src/app/shared/services/location.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { State } from 'src/app/admin/location/models/state.model';
import { City } from 'src/app/admin/location/models/city.model';
import { Vehicle, VehicleGasCard, VehicleInsurance, VehicleModel, VehicleRegistration, VehicleValvolineCard } from '../../fleet-management/models/fleet-management-models';
import { FleetMangementService } from '../../fleet-management/fleet-mangement.service';
import { SalesforceTestingService } from '../salesforce-testing-service/salesforce-testing.service';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';


@Component({
  selector: 'app-salesforce-testing-create',
  templateUrl: './salesforce-testing-create.component.html',
  styleUrls: ['./salesforce-testing-create.component.scss']
})
export class SalesforceTestingCreateComponent implements OnInit {
  isAltBilling: boolean;
  cutomerDataFrom: UntypedFormGroup;
  employees: Array<any>;
  countries: Array<string>;
  territories: Array<string>;
  constructor(
    private _salesforceTestingService: SalesforceTestingService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.countries = ['United States of America'];
    this.territories = ['Jeff Sernick'];
    this.cutomerDataFrom = this.fb.group({
      name: ['', Validators.required],
      legalName: ['', Validators.required],
      billingCity: ['', Validators.required],
      billingState: ['', Validators.required],
      billingPostalCode: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9\-\/]+$')])],
      billingStreet: ['', Validators.required],
      billingCountry: ['', Validators.required],
      territory: ['', Validators.required],
      contactName: ['', Validators.compose([Validators.required, Validators.pattern("^\s*[0-9a-zA-Z]+[ ][0-9a-zA-z]+\s*$")])],
      contactEmail: ['', Validators.compose([Validators.required, Validators.email])],
      contactRole: ['Services Approver', Validators.required],
      billingLocationStreet: ['', Validators.required],
      billingLocationCity: ['', Validators.required],
      billingLocationState: ['', Validators.required],
      billingLocationPostalCode: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9\-\/]+$')])],
      billingLocationCountry: ['United States of America', Validators.required],
      shippingLocationStreet: ['', Validators.required],
      shippingLocationCity: ['', Validators.required],
      shippingLocationState: ['', Validators.required],
      shippingLocationPostalCode: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9\-\/]+$')])],
      shippingLocationCountry: ['United States of America', Validators.required],
      isAltBilling: [false, Validators.required],
    });

    this.cutomerDataFrom.controls['isAltBilling'].valueChanges.subscribe(isAltBilling => {
      this.isAltBilling = isAltBilling;
      if (isAltBilling) {
        this.cutomerDataFrom.removeControl('billingLocationStreet');
        this.cutomerDataFrom.removeControl('billingLocationCity');
        this.cutomerDataFrom.removeControl('billingLocationState');
        this.cutomerDataFrom.removeControl('billingLocationPostalCode');
        this.cutomerDataFrom.removeControl('billingLocationCountry');
        this.cutomerDataFrom.addControl('customerID', new UntypedFormControl('', Validators.required));
      } else {
        this.cutomerDataFrom.removeControl('customerID');
        this.cutomerDataFrom.addControl('billingLocationStreet', new UntypedFormControl('', Validators.required));
        this.cutomerDataFrom.addControl('billingLocationCity', new UntypedFormControl('', Validators.required));
        this.cutomerDataFrom.addControl('billingLocationState', new UntypedFormControl('', Validators.required));
        this.cutomerDataFrom.addControl('billingLocationPostalCode', new UntypedFormControl('', Validators.required));
        this.cutomerDataFrom.addControl('billingLocationCountry', new UntypedFormControl('United States of America', Validators.required));
      }
    })
  }

  onSubmit() {
    if (this.cutomerDataFrom.valid) {
      this._salesforceTestingService.addData(this.cutomerDataFrom.value).subscribe(res => {
        if (res) {
          console.log('res', res)
          this.router.navigate(['salesforce-testing'])
        } else {
          this._snackBar.open("Create Customer Failed", "Close", {
            duration: 5000,
          });
        }
      }, err => {
        this._snackBar.open("Create Customer Failed", "Close", {
          duration: 5000,
        });
      })
    }
  }
}
