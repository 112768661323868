import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cloud-migration-dashboard',
  templateUrl: './cloud-migration-dashboard.component.html',
  styleUrls: ['./cloud-migration-dashboard.component.scss']
})
export class CloudMigrationDashboardComponent implements OnInit {
  projectId: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(param => {
        if (param['id']) {
          this.projectId = param['id'];
        }
      });
  }
}
