import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProjectControlService {

  constructor(private http: HttpClient) { }
  getProjectControls() {
    return this.http.get<any[]>(`/api/solutions/projectControls`);
  }
  getProjectControl(id: string) {
    return this.http.get<any>(`/api/solutions/projectControls/${id}`);
  }
  deleteProjectControl(id: string) {
    return this.http.delete(`/api/solutions/projectControls/${id}`);
  }
  updateProjectControl(projectControl: any) {
    const body = JSON.stringify(projectControl);
    return this.http.put(`/api/solutions/projectControls/${projectControl.projectControlId}`, body);
  }
  createProjectControl(projectControl: any) {
    const body = JSON.stringify(projectControl);
    return this.http.post<any>(`/api/solutions/projectControls`, body);
  }


}
