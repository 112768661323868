import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit {
  @Input() projectsData: any;
  loading: boolean;
  rowData = [{ id: 0, title: "Created by me" }, { id: 1, title: "Assigned to me" }];
  displayedColumns = ['title', 'projectCount', 'active', 'lost', 'complete', 'inActive'];

  constructor() { }

  ngOnInit(): void {
  }

  getProjectStatusCount(status?: number | null, isAssigned?: number | null) {
    if (status != null && isAssigned != null)
      return this.projectsData.filter((x: any) => x.status == status && x.isAssigned == isAssigned).length;
    else if (isAssigned != null)
      return this.projectsData.filter((x: any) => x.isAssigned == isAssigned).length;
    else
      return this.projectsData?.filter((x: any) => x.status == status).length;
  }

//  if(status != null && isAssigned != null)
//return this.projectsData.find((x: any) => x.status == status && x.isAssigned == isAssigned)?.numberOfProjects;
//    else if (isAssigned != null)
//  return this.projectsData.filter((x: any) => x.isAssigned == isAssigned).map((x: any) => x.numberOfProjects).reduce((sum: any, current: any) => sum + current);
//else
//  return this.projectsData.filter((x: any) => x.status == status).map((x: any) => x.numberOfProjects).reduce((sum: any, current: any) => sum + current);
 

}
