import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminHelpVideosRoutingModule } from './admin-help-videos-routing.module';
import { AdminHelpVideosService } from './admin-help-videos.service';
import { AdminHelpVideosComponent } from './admin-help-videos.component';
import { AdminHelpVideosListComponent } from './admin-help-videos-list/admin-help-videos-list.component';
import { AdminHelpVideosCreateComponent } from './admin-help-videos-create/admin-help-videos-create.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [AdminHelpVideosComponent, AdminHelpVideosListComponent, AdminHelpVideosCreateComponent],
  imports: [
    CommonModule,
    AdminHelpVideosRoutingModule,
    SharedModule
  ],
  providers: [
    AdminHelpVideosService
  ]
})
export class AdminHelpVideosModule { }
