import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-active-projects-list',
  templateUrl: './active-projects-list.component.html',
  styleUrls: ['./active-projects-list.component.scss']
})
export class ActiveProjectsListComponent implements OnInit {
  @Input() activeProjectsData: any;
  displayedColumns = ['clientName', 'projectName', 'oppQ', 'createdDate', 'status'];

  constructor() { }

  ngOnInit(): void {
  }

}
