<mat-card>
  <mat-card-content>
    <h2>Service Estimate Milestones</h2>
    <div [class.loading]="!loading" style="position: relative; margin:auto;width:calc(50% + 225px)">
      <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
    </div>
    <mat-table matSort #table [dataSource]="dataSource" [class.loading]="loading">
      <ng-container matColumnDef="seName">
        <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header> SE Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="milestone">
        <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header> Milestone </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.milestone}} </mat-cell>
        <mat-cell mat-footer-cell *matFooterCellDef class="align-center"> Total : </mat-cell>
      </ng-container>
      <ng-container matColumnDef="totalHrs">
        <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header> Total MIT Hours </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.serviceHours}} </mat-cell>
        <mat-footer-cell *matFooterCellDef> {{getTotalHours()}} </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="estimatedTotalPrice">
        <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header> Estimated Total Price </mat-header-cell>
        <mat-cell *matCellDef="let element"> ${{element.updatedEstimatedTotalPrice}} </mat-cell>
        <mat-footer-cell *matFooterCellDef> ${{getTotalEstimatedPrice()}} </mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <mat-footer-row *matFooterRowDef="displayedColumns" [class.hide-footer]="!(dataSource && dataSource.data.length > 0)"></mat-footer-row>
    </mat-table>
    <div *ngIf="!(dataSource && dataSource.data.length > 0)" [class.loading]="loading" class="no-records">
      No records found
    </div>
    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [ngStyle]="{display: dataSource?.data.length > 0 ? 'block' : 'none'}"
                   [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>
