import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Vehicle, VehicleGasCard, VehicleModel, VehicleUsage, VehicleValvolineCard } from '../../models/fleet-management-models';
import { FleetMangementService } from '../../fleet-mangement.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Employee } from '../../../shared/models/employee';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { VehicleUsageModelDataSource } from '../../models/vehicle-usages-model-datasource';
import { distinctUntilChanged } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { LocationService } from 'src/app/shared/services/location.service';
import { State } from 'src/app/admin/location/models/state.model';
import { City } from 'src/app/admin/location/models/city.model';


@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.scss']
})
export class VehicleDetailComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatPaginator, { static: false }) set paginator(mp: MatPaginator) {
  //   if (mp !== undefined && this.vehicleUsages) {  // <-- should fix your problem
  //     this.paginator = mp;
  //     this.vehicleUsages.paginator = this.paginator;
  //   }
  // }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  vehicleForm: UntypedFormGroup;
  isEditing: boolean;
  employees: Array<Employee>;
  vehicle: Vehicle;
  usages: Array<VehicleUsage>;
  vechileModel: VehicleModel;
  isSaveable: boolean;
  gasCard: VehicleGasCard;
  valvoCard: VehicleValvolineCard;
  gasCardCheck: boolean;
  valCardCheck: boolean;
  cityId: string;
  stateId: string;
  states: Array<State>;
  cities: Array<City>;

  vehicleUsages: VehicleUsageModelDataSource;
  usageColumns = [
    'vehicleUsageTripMileage',
    'vehicleUsageDate',
    'vehicleUsageNote',
    'vehicleActions'
  ];
  models: Array<VehicleModel>;
  records: any[];
  insurances: any[];
  isFinishUpdating: boolean;
  finishUpdatingMessage: string;
  errorOccurred: boolean;
  storedVehicle: Vehicle;
  tempVehicleModel: VehicleModel;
  vehicleId: string;
  displayedUsages: Array<VehicleUsage>;
  currentDriver: Employee;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private service: FleetMangementService, private locationService: LocationService, private dialog: MatDialog) { }

  ngOnInit() {
    this.valCardCheck = false;
    this.gasCardCheck = false;
    this.isSaveable = false;
    this.vehicleForm = this.fb.group({
      'vehicleEzPass': [''],
      'vehicleAssignedToEmployeeId': [''],
      'vehicleModelId': ['', Validators.required],
      'vehicleMaintenanceScheduleId': ['', Validators.required],
      'vehicleGasCardId': [''],
      'vehicleColor': [''],
      'vehicleValvolineCardNumber': [''],
      'vehicleValvolineCardPinNumber': [''],
      'vehicleRegionStateId': [''],
      'vehicleRegionCityId': [''],
      'vehicleWrightPromptId': [''],
      'vehiclePin': [''],
      'vehicleAAAMembership': [''],
      'vehicleGasCardNumber': [''],
    });
    this.vehicleForm.controls['vehicleAssignedToEmployeeId'].valueChanges.pipe(distinctUntilChanged())
      .subscribe(res => {
        this.vehicle.assignedEmployeeId = res as string;
      })

    this.isFinishUpdating = false;
    this.isEditing = false;
    this.route.params
      .subscribe(params => {

        const id = params['id'];
        this.vehicleId = id;
        this.initalOfPage();
      });
  }

  initalOfPage() {
    this.service.GetGasCard(this.vehicleId)
    .subscribe(res => {
      if (res) {
        this.gasCardCheck = true;
        this.gasCard = res;
      } else {
        this.gasCard = new VehicleGasCard();
      }
    });
  this.service.GetVavolineCard(this.vehicleId)
    .subscribe(res => {
      if (res) {
        this.valCardCheck = true;
        this.valvoCard = res;
      } else {
        this.valvoCard = new VehicleValvolineCard();
      }
    });
    this.service.getVehicleMaitenanceSchedules()
      .subscribe(res => {
        this.records = res;
      });
    this.service.getDrivers()
      .subscribe(res => {
        this.employees = res;
        this.service.getVehicle(this.vehicleId)
          .subscribe(httpResult => {
            this.vehicle = httpResult;
            this.cityId = this.vehicle.vehicleRegionCityId;
            this.stateId = this.vehicle.vehicleRegionStateId;
            if (this.vehicle.assignedEmployeeId) {
              this.service.getDriver(this.vehicle.assignedEmployeeId)
                .subscribe(driverResult => {
                  this.currentDriver = driverResult;
                  const filterDriver = this.employees.find(e => e.firstName == driverResult.firstName
                    && e.lastName == driverResult.lastName);
                  if (!filterDriver) {
                    this.employees.push(driverResult);
                  }
                });
            }
            this.vehicleForm.controls['vehicleAssignedToEmployeeId'].setValue(this.vehicle.assignedEmployeeId);
            this.vehicleForm.updateValueAndValidity();
            this.storedVehicle = new Vehicle();
            Object.assign(this.storedVehicle, this.vehicle);

            this.service.getVehicleUsagesByCarId(this.vehicleId)
              .subscribe(usageResult => {
                this.usages = usageResult;
                this.displayedUsages = [];
                this.displayedUsages = this.usages;
                this.displayedUsages.sort((a, b) => {
                  const first: any = a.vehicleUsageMileageEnd;
                  const second: any = b.vehicleUsageMileageEnd;

                  return first - second;
                });
                this.setVehicleUsageTable(this.displayedUsages);
              });
            if (this.vehicle.vehicleModelId) {
              // this.service.getVehicleModelByVehicleId(this.vehicleId)
              //   .subscribe(modelResult => {
              //     this.vechileModel = modelResult;
              //     this.tempVehicleModel = new VehicleModel();
              //     Object.assign(this.tempVehicleModel, this.vechileModel);
              //   });
            }
            this.service.getVehicleInsuranceRecords()
              .subscribe(res => {
                this.insurances = res;
              });
            this.service.getStates()
              .subscribe(stateResult => {
                this.states = stateResult;
              });
            this.service.getCitiesByStateId(this.stateId)
              .subscribe(res => {
                this.cities = res;
              });
              this.vehicleForm.controls['vehicleRegionStateId'].valueChanges.subscribe(v => {
                this.vehicleForm.controls["vehicleRegionCityId"].patchValue('');
                this.locationService.getCitiesByStateId(v)
                  .subscribe(res => {
                    this.cities = res;
                  });
              });
          });

      });
    this.service.getVehicleModels()
      .subscribe(modelsResult => {
        this.models = modelsResult;

      });
  }

  setVehicleUsageTable(data: any) {
    this.vehicleUsages = new VehicleUsageModelDataSource();
    this.vehicleUsages.setData(data);
    this.vehicleUsages.sort = this.sort;
    this.vehicleUsages.paginator = this.paginator;
  }

  updateVehicle() {
    if (this.gasCardCheck) {
      this.vehicle.vehicleGasCards = [this.gasCard];
    } else {
      this.gasCard.AssignedToVehicleId = this.vehicleId;
      this.vehicle.vehicleGasCards = [this.gasCard];
    }

    if (this.valCardCheck) {
      this.vehicle.vehicleValvolineCards = [this.valvoCard];
    } else {
      this.valvoCard.AssignedToVehicleId = this.vehicleId;
      this.vehicle.vehicleValvolineCards = [this.valvoCard];
    }

    this.service.updateVehicle(this.vehicle)
      .subscribe(res => {
        this.isFinishUpdating = true;
        this.errorOccurred = false;
        this.finishUpdatingMessage = 'The vehicle was updated successfully.';
        this.initalOfPage();

        this.isEditing = false;
        this.isSaveable = false;
      },
        (error) => {
          this.errorOccurred = true;
          this.finishUpdatingMessage = 'There was a problem updating the vehicle. Please try again later or contact support.';
          this.isEditing = false;
          this.isSaveable = false;
        });
  }
  clearVehicleMaintenanceScheduleId() {
    this.vehicle.vehicleMaintenanceScheduleId = null;
  }
  toggleEdit(actionType: string) {
    switch (actionType) {
      case 'cancel':
        Object.assign(this.vehicle, this.storedVehicle);
        this.vechileModel = this.tempVehicleModel;
        this.isSaveable = false;
        break;
      case 'edit':
        this.isSaveable = true;
        break;
    }
    this.isEditing = !this.isEditing;
  }
}
