import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'componentServiceRoleMap'
})
export class ComponentServiceRoleMapPipe implements PipeTransform {

  transform(items: any[]) {
    if (!items) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    if (Array.isArray(items)) {
      return items.map(component => component.serviceRole);
    }
    return items;

  }

}
