<div class="col-md-12">
  <mat-card>
    <mat-card-content>
      <div class="col-md-12" *ngIf="employees">
        <fieldset>
          <legend>
            Search Selection
          </legend>
          <form [formGroup]="filterForm">
            <mat-form-field>
              <input matInput type="text" placeholder="Filter by Template Name" formControlName="templateName" />
            </mat-form-field>
            <mat-form-field>
              <mat-select formControlName="employee" disableOptionCentering placeholder="Employee Filter" #employee>
                <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">
                  {{emp.firstName + " " + emp.lastName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button (click)="resetFilters()" mat-icon-button type="button">
              <mat-icon>clear</mat-icon>
            </button>
          </form>
        </fieldset>
      </div>
      <br />
      <div class="col-md-12">
        <mat-table matSort #table [dataSource]="dataSource" class="apply-border">
          <ng-container matColumnDef="componentName">
            <mat-header-cell *matHeaderCellDef class="clickable" mat-sort-header>
              Name
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.componentName}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="createdDate">
            <mat-header-cell *matHeaderCellDef class="clickable" mat-sort-header>
              Created Date
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.createdDate | date:'shortDate'}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="!isPopup">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item color="default" type="button" [routerLink]="['edit',element.id]"
                          *ngIf="(isDirector || element.createdByEmployeeId == currentUser.employeeId)">
                    <mat-icon>create</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item color="warn" type="button" (click)="deleteRecord(element.id)" *ngIf="(isDirector || element.createdByEmployeeId == currentUser.employeeId)">
                    <mat-icon>remove</mat-icon>
                    <span>Delete</span>
                  </button>
                  <button mat-menu-item color="accent" type="button" (click)="cloneTemplate(element.id)">
                    <mat-icon>file_copy</mat-icon>
                    <span>Clone</span>
                  </button>
                </mat-menu>
              </ng-container>
              <ng-container *ngIf="isPopup">
                <button id = "selectTemp" mat-raised-button color="accent" *ngIf="isPopup" type="button" class='selectButtonForTemplate'
                        (click)="selectTemplate(element.id)">
                  Select
                </button>
              </ng-container>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef> Created by</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: element.createdByEmployeeId}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="!(dataSource && dataSource.data.length > 0)" class="no-records">
          No records found
        </div>
      </div>
      <div>
        <mat-paginator #paginator [length]="dataSource?.data.length"
                       [pageSize]="10" [pageIndex]="0" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
