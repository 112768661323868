<!-- <md-card>
    <md-card-content>
        <button color="primary"
                mat-raised-button
                [routerLink]="['vehicles' ,'addMileage']">Add Milage</button>
    </md-card-content>
</md-card> -->
<div class="col-md-12">
    <mat-card *ngIf="isAdmin">
        <mat-card-content *ngIf="employees && adminDataSource">
            <mat-table #table [dataSource]="adminDataSource">
                <ng-container matColumnDef="vehicleNumber">
                    <mat-header-cell *matHeaderCellDef> Vehicle Number </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.vehicleNumber}} </mat-cell>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="vehicleStatus">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span *ngIf="element.vehicleStatus === 0">Available</span>
                        <span *ngIf="element.vehicleStatus === 1">Assigned</span>
                        <span *ngIf="element.vehicleStatus === 2">Maintenance</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="vehicleMileageCurrent">
                    <mat-header-cell *matHeaderCellDef> Assigned Driver </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{filterEmployee(element.assignedEmployeeId) }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="vehicleId">
                    <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-icon-button   id = "kebabMenu" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['detail',element.vehicleId]">
                                <mat-icon> edit </mat-icon>
                                <span> Details/Edit </span>
                            </button>
                            <button mat-menu-item (click)="deleteVehicle(element.vehicleId)">
                                <mat-icon> delete_outline </mat-icon>
                                <span> Retire </span>
                            </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>
    <div *ngIf="isUser">
        <mat-card>
            <mat-card-content>
                <div *ngIf="isDriver">
                    <div *ngIf="!hasCheckedOutCar && dataSource">
                        <h2>Available Vehicles</h2>
                        <mat-table #table [dataSource]="dataSource">
                            <ng-container matColumnDef="vehicleNumber">
                                <mat-header-cell *matHeaderCellDef> Vehicle Number </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.vehicleNumber}} </mat-cell>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="vehicleStatus">
                                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <span *ngIf="element.vehicleStatus === 0">Available</span>
                                    <span *ngIf="element.vehicleStatus === 1">Assigned</span>
                                    <span *ngIf="element.vehicleStatus === 2">Maintenance</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="vehicleMileageCurrent">
                                <mat-header-cell *matHeaderCellDef> Mileage </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.vehicleMileageCurrent | number}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="vehicleId">
                              <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                              <mat-cell *matCellDef="let element">
                                <button id="kebabMenuAvailVehicles" [attr.data-id]="element.vehicleId" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item color="primary" type="button" (click)="checkOutVehicle(element.vehicleId)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Check Out</span>
                                  </button>
                                </mat-menu>
                              </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                    </div>
                    <div *ngIf="hasCheckedOutCar && checkedOutVehicles">
                        <h2>Check Out Vehicles</h2>
                        <mat-table #table [dataSource]="checkedOutVehicles">
                            <ng-container matColumnDef="vehicleNumber">
                                <mat-header-cell *matHeaderCellDef> Number </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.vehicleNumber}} </mat-cell>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="vehicleStatus">
                                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <span *ngIf="element.vehicleStatus === 0">Avaliable</span>
                                    <span *ngIf="element.vehicleStatus === 1">Assigned</span>
                                    <span *ngIf="element.vehicleStatus === 2">Maintenance</span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="vehicleMileageCurrent">
                                <mat-header-cell *matHeaderCellDef> Mileage </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.vehicleMileageCurrent | number}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="vehicleId">
                              <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                              <mat-cell *matCellDef="let element">
                                <button id="kebabMenuChkOutVehicles" [attr.data-id]="element.vehicleId" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item *ngIf="element.vehicleStatus === 1" color="accent" type="button" (click)="checkInVehicle(element.vehicleId)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Check In</span>
                                  </button>
                                  <button mat-menu-item color="primary" type="button" [routerLink]="['addMileage',element.vehicleId]">
                                    <mat-icon>add</mat-icon>
                                    <span>Add Mileage</span>
                                  </button>
                                </mat-menu>
                              </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                    </div>
                </div>
                <div *ngIf="!isDriver">
                    <p>You have not been converted to a driver please contact support to complete this process.</p>
                </div>
            </mat-card-content>
        </mat-card>
        <br>
        <mat-card *ngIf="isDriver">
            <mat-card-content>
                <app-milage-table></app-milage-table>
            </mat-card-content>
        </mat-card>
    </div>
</div>
