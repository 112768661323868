import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl, NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { ProfessionalServicesService } from '../../professional-services.service';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ClientsListUrlFilteringComponent } from '../../../clients/clients-list-url-filtering/clients-list-url-filtering.component';
import { ClientsService } from '../../../clients/clients.service';
import { DocumentViewerPopupComponent } from '../../../shared/components/document-viewer-popup/document-viewer-popup.component';
import { ScoreCardPopupComponent } from '../../../shared/components/score-card-popup/score-card-popup.component';
import { BusinessPartnerScoreScoreCardService } from '../../../business-partner-score-card/business-partner-score-score-card.service';
import { BusinessPartnerService } from '../../../business-partners/business-partner.service';
import { ProjectDocumentPopupComponent } from '../../../shared/components/project-document-popup/project-document-popup.component';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { ProjectDocumentService } from '../../../shared/components/project-document-popup/project-document.service';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { RoleService } from '../../../shared/services/role.service';
import { ProjectCreateFormComponent } from './project-create-form/project-create-form.component';

@Component({
  selector: 'app-professional-services-project-create',
  templateUrl: './professional-services-project-create.component.html',
  styleUrls: ['./professional-services-project-create.component.scss']
})
export class ProfessionalServicesProjectCreateComponent implements OnInit {
  projectForm: UntypedFormGroup;
  projectId: string;
  employees: Array<any>;
  canEditStatus: boolean;
  user: AccountInfo | null;
  isScoreCardGrader: boolean;
  project: any;
  postSaleResources: any[];
  @ViewChild('form')
  form: NgForm;
  @ViewChild('createForm') createForm: ProjectCreateFormComponent;
  isReadyToAssignPostSalesResources: boolean;
  fileUrl: string;
  documents: any[];
  businessPartnerItems: any[];
  scorecardDatasource: any[];
  displayedColumns: string[] = ['businessPartner', 'solutionPillar', 'createdBy', 'createdDate', 'actions'];
  projectCanBeClosed: boolean;
  isCmtProj: boolean;
  displayData: boolean;
  selectedTabIndex: number;
  discoveryId: string;
  isDirector: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private location: Location,
    private projectService: ProfessionalServicesService,
    private route: ActivatedRoute,
    private authService: MsalService,
    private dialog: MatDialog,
    private clientService: ClientsService,
    private scoreCardService: BusinessPartnerScoreScoreCardService,
    private businessPartnerService: BusinessPartnerService,
    private projectDocumentService: ProjectDocumentService,
    private roleService: RoleService,
    private router: Router) {

  }

  ngOnInit() {
    this.displayData = false;
    this.projectCanBeClosed = false;
    this.user = this.authService.instance.getActiveAccount();
    this.checkRoles();
    this.isScoreCardGrader = false;
    this.route.params
      .subscribe(param => {
        if (param['id']) {
          this.projectId = param['id'];
          this.getProjectDetails();
        } else {
          this.displayData = true;
        }
      });
  }

  checkRoles() {
    this.isDirector = this.hasRole('PortalSEDirector');
  }

  getProjectDetails() {
    this.businessPartnerService.getBusinessPartnerByServiceProjectId(this.projectId)
      .subscribe(resbp => {
        this.businessPartnerItems = resbp;
        this.getScoreCards();
      });
    this.projectService.getProject(this.projectId)
      .subscribe(seResult => {
        this.project = seResult;
        this.displayData = true;
      });
  }

  getScoreCards() {
    this.businessPartnerService.getBusinessPartnerScoreCardsByServiceProjectId(this.projectId)
      .subscribe(res => {
        this.scorecardDatasource = res;
        if (this.businessPartnerItems?.length > 0) {
          if (res.length > 0) {
            res.forEach(bpsc => {
              if (bpsc.isComplete) {
                var bp: any[] = this.businessPartnerItems.find(bpi => bpi.businessPartnerId == bpsc.businessPartnerId);
                if (bp) {
                  this.projectCanBeClosed = true;
                }
              }
            })
          }
        } else {
          this.projectCanBeClosed = true;
        }
      });
  }

  public canDeactivate(): boolean {
    this.form = this.createForm.getProjectForm();
    const state = this.form.submitted || !this.form.dirty;
    const url = this.location.path();

    if (state) {

    } else {
      history.pushState(null, 'Meridian Unified Portal', url);
    }
    return state;
  }
  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, false);
  }

  loadFile(url: string) {
    const ref = this.dialog.open(DocumentViewerPopupComponent, {
      width: '100%',
      data: { fileUrl: url }
    })
  }
  openBusinessPartnerScorecard() {
    const popup = this.dialog.open(ScoreCardPopupComponent, {
      height: '60%',
      minWidth: '400px',
      panelClass: 'no-padding',
      data: { 'id': this.projectId }
    });
    popup.afterClosed()
      .subscribe(selectedPopupValue => {
        if (selectedPopupValue) {
          this.scoreCardService.createBusinessPartnerScoreCard(selectedPopupValue);
          this.getScoreCards();
        }
      });

  }
  openBusinessPartnerScorecardWithData(data: any) {
    const popup = this.dialog.open(ScoreCardPopupComponent, {
      height: '60%',
      minWidth: '400px',
      panelClass: 'no-padding',
      data: { 'id': this.projectId, 'scoreCard': data, 'disabled': true }
    });
    popup.afterClosed()
      .subscribe(selectedPopupValue => {
        if (selectedPopupValue) {
          this.scoreCardService.createBusinessPartnerScoreCard(selectedPopupValue);
          this.getScoreCards();
        }
      });

  }
  openBusinessPartnerScorecardEdit(data: any) {
    const popup = this.dialog.open(ScoreCardPopupComponent, {
      height: '60%',
      minWidth: '400px',
      panelClass: 'no-padding',
      data: { 'id': this.projectId, 'scoreCard': data, 'disabled': false }
    });
    popup.afterClosed()
      .subscribe(selectedPopupValue => {
        if (selectedPopupValue) {
          this.scoreCardService.createBusinessPartnerScoreCard(selectedPopupValue);
          this.getScoreCards();
        }
      });

  }
  deleteDocument(id: string) {
    const popup = this.dialog.open(ConfirmDeleteDialogComponent, {
      height: 'auto',
      minWidth: '400px',
      panelClass: 'no-padding',

    });
    popup.afterClosed()
      .subscribe(res => {
        if (res) {
          this.projectDocumentService.deleteDocument(id)
            .subscribe(resDocument => {
              this.getProjectDetails();
            });
        }
      });
  }
  deleteScoreCard(id: string) {
    this.scoreCardService.deleteBusinessPartnerScoreCard(id)
      .subscribe(res => {
        this.getScoreCards();
      });
  }
}

