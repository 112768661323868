import { Injectable } from '@angular/core';
import { Discovery } from './models/discovery-datasource';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { Workload } from './models/workload-datasource';
import { DiscoveryConfiguration } from './models/configuration-datasource';
import { Assessment, RegionOptions } from './models/assessment-datasource';
import { CloudProductMatchingCriteria } from './assessments/cloud-migration-match-cloudproduct/cloud-migration-match-cloudproduct.component';
import { ServiceEstimateSelectModel } from '../professional-services/service-estimates/_models/service-estimate-select-list.model';
import { AssessmentPricingSummaryModel } from './models/assessment-pricing.model';

@Injectable({
  providedIn: 'root',
})
export class CloudMigrationToolService {
  constructor(private http: HttpClient) {}

  getDiscoveries(clientId: string) {
    let url = `/api/cloudmigrationprojectdiscoveries/${clientId}/discoveries`;
    return this.http.get<any[]>(url);
  }

  getDiscovery(discoveryId: string): Observable<any> {
    let url = `/api/cloudmigrationprojectdiscoveries/discoveries/${discoveryId}`;
    return this.http.get<Discovery>(url);
  }

  getProjectDiscovery(projectId: any, discoveryId: any): Observable<any> {
    let url = `/api/cloudmigrationprojectdiscoveries/${projectId}/discoveries/${discoveryId}`;
    return this.http.get<any>(url);
  }

  addProjectDiscovery(discovery: any) {
    let url = `/api/cloudmigrationprojectdiscoveries/discoveries`;
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, discovery).subscribe(
        (res) => {
          resolve({ data: res });
        },
        (error) => {
          resolve({ data: error });
        }
      );
    });
    return promise;
  }

  getWorkload(wrkloadId: string, discoveryId: string): Observable<Workload> {
    return this.http.get<Workload>(
      `/api/cloudmigrationprojectdiscoveries/Discoveries/${discoveryId}/workloads/${wrkloadId}`
    );
  }

  getNewWorkload(discoveryId: string): Observable<any> {
    let url = `/api/cloudmigrationprojectdiscoveries/${discoveryId}/workloads/workload`;
    return this.http.get<any>(url);
  }


  getServiceEstimateSelectListByProjectId(
    projectId: string
  ): Observable<Array<ServiceEstimateSelectModel>> {
    return this.http.get<Array<ServiceEstimateSelectModel>>(
      `/api/CloudMigrationServiceProjects/${projectId}/ServiceEstimates/selectlist`
    );
  }

  saveDiscoveryWorkload(workload: any) {
    // let url = `/api/cloudmigrationprojectdiscoveries/${discoveryId}/workloads`;
    let url = `/api/cloudmigrationprojectdiscoveries/workloads`;

    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, workload).subscribe(
        (res) => {
          resolve({ data: res });
        },
        (error) => {
          resolve({ data: {} });
        }
      );
    });

    return promise;
  }

  loadDiscoveryNetworkData(discoveryId: string, networkData: any) {
    let url = `/api/cloudmigrationclients/${discoveryId}/loadnetworkdata`;
    let retVal: any = {};

    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, networkData).subscribe(
        (res) => {
          resolve({ data: res });
        },
        (error) => {
          resolve({ data: {} });
        }
      );
    });

    return promise;
  }

  saveDiscoveryNetworkData(discoveryId: string, networkData: any) {
    let url = `/api/cloudmigrationclients/${discoveryId}/networkdata`;
    let retVal: any = {};

    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, networkData).subscribe(
        (res) => {
          resolve({ data: res });
        },
        (error) => {
          resolve({ data: {} });
        }
      );
    });

    return promise;
  }

  generateNetworkprofile(request: any): any {
    let url = `/api/cloudmigrationnetworkprofile/${request.discoveryId}/createnetworkprofile`;

    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, request).subscribe(
        (res) => {
          resolve({ data: res });
        },
        (error) => {
          resolve({ data: {} });
        }
      );
    });

    return promise;
  }

  saveNetworkprofile(request: any) {
    let url = `/api/cloudmigrationnetworkprofile/${request.discoveryId}/networkprofile`;

    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, request).subscribe(
        (res) => {
          resolve({ data: res });
        },
        (error) => {
          resolve({ data: {} });
        }
      );
    });

    return promise;
  }

  getNetworkProfiles(discoveryId: any): Observable<any> {
    let url = `/api/cloudmigrationclients/${discoveryId}/networkprofiles`;
    return this.http.get<any[]>(url);
  }
  
  getDataTransferNetworkProfiling(projectId: string) {
    let url = `/api/cloudmigrationanalysis/${projectId}/networkprofiling`;
    return this.http.get<any[]>(url);
  }


  uploadDiscovery(file: any, discovery: Discovery) {
    let url = `/api/CloudMigration/uploaddiscoverydatafile`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append(
      'discovery',
      new Blob([JSON.stringify(discovery)], {
        type: 'application/json',
      })
    );

    return new Promise((resolve, reject) => {
      this.http.post<any>(url, formData).subscribe(
        (res) => {
          resolve({ status: res });
        },
        (error) => {
          resolve({ status: false });
        },
        () => {}
      );
    });
  }

  uploadIBMiDiscovery(file: any, projectId: any, configId: any) {
    let url = `/api/CloudMigration/uploadibmidiscoverydatafile`;
    console.log('configId', configId);
    console.log('projectId', projectId);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('configurationId', configId);
    formData.append('projectId', projectId);

    return new Promise((resolve, reject) => {
      this.http.post<any>(url, formData).subscribe(
        (res) => {
          resolve({ status: res });
        },
        (error) => {
          resolve({ status: false });
        },
        () => {}
      );
    });
  }

  /** Workload Discovery Environment */
  getDiscoveryEnvironments(projectId: any) {
    let url = `/api/CloudMigrationWorkloadEnvironment/${projectId}/workloadenvironments`;
    return this.http.get<any[]>(url);
  }

  getDiscoveryEnvironment(projectId: any, envId: any): Observable<any> {
    console.log('getDiscoveryEnvironment', projectId, envId);
    let url = `/api/CloudMigrationWorkloadEnvironment/${projectId}/workloadenvironments/${envId}`;
    return this.http.get<any>(url);
  }

  getenviromentconfiguration(envId: string): Observable<any> {
    let url = `/api/CloudMigrationWorkloadEnvironment/workloadenvironments/${envId}/configuration`;

    return this.http.get<any>(url);
  }
  updateenvironment(env: any): Observable<any> {
    let url = `/api/CloudMigrationWorkloadEnvironment/workloadenvironments/${env.id}`;
    this.http.put<any>(url, env).subscribe({
      next: (data) => {
        this.postId = data;
      },
      error: (error) => {
        this.errorMessage = error.message;
      },
    });

    return of(this.postId);
  }

  addenvironemnt(env: any): Observable<any> {
    let url = `/api/CloudMigrationWorkloadEnvironment/workloadenvironments`;
    this.http.post<any>(url, env).subscribe({
      next: (data) => {
        this.postId = data;
      },
      error: (error) => {
        this.errorMessage = error.message;
      },
    });
    return of(this.postId);
  }

  saveDiscoveryEnvironment(env: any) {
    let url = `/api/CloudMigrationWorkloadEnvironment/workloadenvironments`;
    
    
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, env).subscribe(
        (res) => {
          resolve({ data: res });
        },
        (error) => {
          resolve({ data: {} });
        }
      );
    });

    return promise;
  }

  updateEnvironment(env: any) {
    let url = `/api/CloudMigrationWorkloadEnvironment/workloadenvironments/${env.id}`;
    
    let promise = new Promise((resolve, reject) => {
      this.http.put<any>(url, env).subscribe(
        (res) => {
          resolve({ data: res });
        },
        (error) => {
          resolve({ data: {} });
        }
      );
    });

    return promise;
  }


  updateEnvironmentConfig(env: any): Observable<any> {
    let url = `/api/CloudMigrationWorkloadEnvironment/workloadenvironments/configuration`;
    this.http.put<any>(url, env).subscribe({
      next: (data) => {
        this.postId = data;
      },
      error: (error) => {
        this.errorMessage = error.message;
      },
    });

    return of(this.postId);
  }
  /** End Workload Environments **/

  /** Configrations **/
  getConfigurations(projectId: string) {
    let url = `/api/cloudmigrationclients/${projectId}/discoveryconfigurations`;
    return this.http.get<any[]>(url);
  }

  getconfiguration(
    projectId: any,
    configId: any
  ): Observable<DiscoveryConfiguration> {
    let url = `/api/cloudmigrationclients/${projectId}/discoveryconfigurations/${configId}`;
    return this.http.get<DiscoveryConfiguration>(url);
  }

  updateconfiguration(config: DiscoveryConfiguration): Observable<any> {
    let url = `/api/cloudmigrationclients/discoveryconfigurations/${config.id}`;
    this.http.put<any>(url, config).subscribe({
      next: (data) => {
        this.postId = data;
      },
      error: (error) => {
        this.errorMessage = error.message;
      },
    });

    return of(this.postId);
  }

  addconfiguration(config: DiscoveryConfiguration): Observable<any> {
    let url = `/api/cloudmigrationclients/discoveryconfigurations`;
    this.http.post<any>(url, config).subscribe({
      next: (data) => {
        this.postId = data;
      },
      error: (error) => {
        this.errorMessage = error.message;
      },
    });
    return of(this.postId);
  }

  /** End Configrations **/

  /** Assessements **/
  getAssessments(projectId: any): Observable<any> {
    let url = `/api/cloudmigrationassessment/${projectId}/assessments`;
    return this.http.get<any[]>(url);
  }

  getAssessment(assessmentId: any): Observable<Assessment> {
    let url = `/api/cloudmigrationassessment/assessments/${assessmentId}`;
    return this.http.get<Assessment>(url);
  }
  calculateAssessmentPricing(
    assessment: Assessment
  ): Observable<AssessmentPricingSummaryModel> {
    console.log('calculateAssessmentPricing: ', assessment);
    const body = {
      cloudProviderId: assessment.cloudProviderId,
      regionName: assessment.region,
      managedServiceePercentage: assessment.managedServicePercent,
      storageCapacity: assessment.storageAmount,
      serviceEstimateId: assessment.serviceEstimateId,
      networkProfileId: assessment.networkProfileId,
      cloudSolutionDetailItems: assessment.cloudSolutionDetails,
      dataTransferTimeMinutes: assessment.dataTransferTimeMinutes,
      bandwidthRequired: assessment.bandwidth,
      vpnRate: assessment.vpnRate,
    };
    return this.http.post<AssessmentPricingSummaryModel>(
      `/api/cloudmigrationassessment/assessments/${assessment.id}/pricingSummary`,
      body
    );
  }

  getNewAssessment(discoveryId: any): Observable<Assessment> {
    let url = `/api/cloudmigrationassessment/${discoveryId}/assessments/assessment`;
    return this.http.get<Assessment>(url);
  }

  generateAssessment(assessment: any): Observable<any> {
    let url = `/api/cloudmigrationassessment/${assessment.discoveryId}/assessments`;

    return this.http.post<any>(url, assessment);
  }
  reMatchWorkloadsForRegionChange(assessment: any): Observable<any> {
    let url = `/api/cloudmigrationassessment/assessments/updateproductmatching`;

    return this.http.post<any>(url, assessment);
  }

  getMatchingCloudProducts(
    matchingCriteria: CloudProductMatchingCriteria
  ): Observable<any[]> {
    let url = `/api/cloudmigrationassessment/assessments/matchingcloudproducts`;
    return this.http.post<any>(url, matchingCriteria);
  }
  
  getContractPreviewRequest(assessmentId: any) :Observable<any> {
    let url = `/api/cloudmigrationcontract/${assessmentId}/contractpreview`;
     return this.http.get<any>(url);
  }
  
  generateContractPreview(request: any): any {
    let url = `/api/cloudmigrationcontract/${request.assessmentId}/createcontractpreview`;
    
    let promise = new Promise((resolve, reject) => {
      this.http.post<any>(url, request).subscribe(res => {
        resolve({data: res});
      },(error) => {
          resolve({ data: {} });
      });
    });
    
    return promise;
  }

  generateContractPreviewExcelFile(request: any): Observable<any> {
    let url = `/api/cloudmigrationcontract/${request.assessmentId}/createcontractpreviewexcelfile`;
    return this.http
        .post(url,request, { responseType: 'blob' });
  
  }

  updatePaymentSummary(
    formData: any,
    assessmentId: string
  ): Observable<Assessment> {
    let url = `/api/cloudmigrationassessment/assessments/${assessmentId}`;
    return this.http.put<any>(url, formData);
  }

  updatePaymentInfo(formData: any): Observable<Assessment> {
    let url = `/api/cloudmigrationassessment/assessments/updatepricinginfo`;
    return this.http.post<any>(url, formData);
  }

  /** End Assessements **/

  getStorage(storageId: string) {}

  postId: any;
  errorMessage: any;

  deleteDiscovery(discoveryId: string) {
    // let index = this.discoveries.findIndex(d => d.id == discoveryId);
    // this.discoveries.splice(index, 1);
    return of([]);
  }

  deleteWorkload(wrkloadId: string, discoveryId: string) {
    // let discovery: Discovery | undefined = this.discoveries.find(d => d.id == discoveryId);
    // let index = discovery ? discovery.workloads.findIndex(wl => wl.id == wrkloadId) : 0;
    // discovery?.workloads.splice(index, 1);
    return of([]);
  }

  deleteDiscoveryConfig(configId: string) {
    // let index = this.configurations.findIndex(wl => wl.id == configId);
    // this.configurations.splice(index, 1);
    return of([]);
  }
  getProject(id: string): Observable<any> {
    return this.http.get(`/api/CloudMigrationServiceProjects/${id}`);
  }
  getProjects(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`/api/CloudMigrationServiceProjects`);
  }
  addProject(projectData: any) {
    return this.http.post(`/api/CloudMigrationServiceProjects`, projectData);
  }
  updateProject(projectData: any) {
    return this.http.put(
      `/api/CloudMigrationServiceProjects/${projectData.id}`,
      projectData
    );
  }

  deleteAssessment(assessmentId: string) {
    //let index = this.assessments.findIndex(ass => ass.id == assessmentId);
    //this.assessments.splice(index, 1);
    return of([]);
  }
}
