<mat-card>
    <mat-card-content>
      <!-- <div
        [class.loading]="!loading"
        style="position: relative; margin: auto; width: calc(50% + 225px)"
      >
        <mat-spinner
          mode="indeterminate"
          width="450"
          height="450"
          style="margin: 0 auto"
        ></mat-spinner>
      </div> -->
  
      <div *ngIf="networkProfiling" [class.loading]="loading">
        <div>
          <fieldset>
            <legend>Data Transfer Network Profiling</legend>
            <div class="row adjust-linehgt">
              <p class="col-md-6">
                Client Name: {{ networkProfiling.clientLegalName }}
              </p>
            </div>
            <div class="align-button">
<!--   
              <a
                mat-raised-button
                color="primary"
                (click)="addWorkload()"
                style="float: right"
              >
                <mat-icon>add</mat-icon>Add Workload
              </a> -->
            </div>
          </fieldset>
        </div>
        <br />

        <br />
        <!--Network Data-->
        <div>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Network Data... </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
  
            <div [class.loading]="loading">
              <fieldset>
                <!-- <legend>
                  Network Data
                </legend> -->
                <div class="align-button">
                  <a
                    mat-raised-button
                    color="primary"
                    (click)="generateNetworkProfile()"
                    style="float: right"
                  >
                    <mat-icon>assignment</mat-icon>Generate Network Profile
                  </a>
                  <a
                    mat-raised-button
                    color="primary"
                    (click)="addNetworkData()"
                    style="float: right"
                  >
                    <mat-icon>add</mat-icon>Add Network Data
                  </a>
                </div>
                <div *ngIf="networkData">
                  <div class="row">
                    <div class="col-6">
                      <app-cloud-migration-discovery-traceroutedata
                        *ngIf="networkData.traceRoutDataLoaded"
                        [traceroutedata]="networkData.tracerouteDataRows"
                      >
                      </app-cloud-migration-discovery-traceroutedata>
                    </div>
                    <div class="col-6">
                      <app-cloud-migration-discovery-pingdata
                        *ngIf="networkProfiling.networkData.pingDataLoaded"
                        [pingdata]="networkData.pingDataRows"
                      >
                      </app-cloud-migration-discovery-pingdata>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <app-cloud-migration-discovery-perfdata
                        *ngIf="networkProfiling.networkData.perfDataLoaded"
                        [perfdata]="networkData.perfDataRows"
                      >
                      </app-cloud-migration-discovery-perfdata>
                    </div>
                    <div class="col-6">
                      <app-cloud-migration-discovery-interfacedata
                        *ngIf="networkData.interfaceDataLoaded"
                        [interfacedata]="networkData.interfaceDataRows"
                      >
                      </app-cloud-migration-discovery-interfacedata>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </mat-expansion-panel>
        </div>
        <br />
        <!-- Network Profiles -->
        <div *ngIf="networkProfileList">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Network Profiles({{ networkProfileList.rows.length }})
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <app-cloud-migration-discovery-networkprofile-list
              [networkProfiles]="networkProfileList"
            ></app-cloud-migration-discovery-networkprofile-list>
          </mat-expansion-panel>
        </div>
        <br />

      </div>
    </mat-card-content>
  </mat-card>
  