<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{formTitle}}</h3>
<div>
 
  <mat-dialog-content [formGroup]="discoveryUploadForm">

 
 <mat-card> 

    <div class="row">
    <div class="col md-4">
        <mat-form-field>
        <mat-label>Configuration Environment</mat-label>
        <mat-select (selectionChange)="selectEnvironment($event)" formControlName="configurationEnvironment" disableOptionCentering >
          <mat-option *ngFor="let config of configOptions" 
          [value]="config.id">
            {{config.title}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="discoveryUploadForm.controls['configurationEnvironment'].hasError('required')">
            Configuration Environment is required
        </mat-error>
      </mat-form-field>
    </div>
    <div   class="col md-2">
        <app-discovery-upload
          [configurationId]="configurationId"
          [projectId]="projectId"
          style="float: right;" disabled
          fileExtension=".csv"
          title="IBMi Discovery File!"
          (onUploadFinished)="onUploadFinished($event)"
        ></app-discovery-upload>
 
      </div> 
    </div>
    <div class="row">
        <div class="col-12">
          <mat-dialog-actions align="end">
            <button class="mat-raised-button" (click)="close()">Close</button>
          </mat-dialog-actions>
        </div>
      </div>
  </mat-card>
</mat-dialog-content><!--  -->
</div>
