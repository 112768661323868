<div *ngIf="employees">
    <h1 mat-dialog-title>Select a driver to assign.</h1>
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>Select a Driver</mat-label>
            <mat-select id="driversDropdown" required [(ngModel)]="data.assignedEmployeeId" disableOptionCentering #selectedEmployee="ngModel">
                <mat-option id="driverOption" *ngFor="let employee of employees" [value]="employee.vehicleDriverId">{{employee.firstName}}
                    {{employee.lastName}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button [mat-dialog-close]="data" type="button" color="accent" id="saveButton"
            [disabled]="!selectedEmployee?.valid">Save</button>
        <button mat-raised-button mat-dialog-close color="warn" type="button">Cancel</button>
    </div>
</div>