import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ReportsService } from '../reports.service';
import { ProfessionalServicesService } from '../../professional-services/professional-services.service';
import { PivotComponent } from '../../shared/components/pivot/pivot.component';
import * as WebDataRocks from 'webdatarocks';

@Component({
  selector: 'app-business-partner-reports',
  templateUrl: './business-partner-reports.component.html',
  styleUrls: ['./business-partner-reports.component.scss']
})
export class BusinessPartnerReportsComponent implements OnInit {

  @ViewChild('donwloadCsvLink', { static: true }) private donwloadCsvLink: ElementRef;

  @ViewChild('pivot1', { static: true }) child: PivotComponent;
  formGroup: UntypedFormGroup;
  reportRan: boolean;

  constructor(private fb: UntypedFormBuilder, private reportsService: ReportsService, private profServices: ProfessionalServicesService) { }

  ngOnInit(): void {
    this.reportRan = false;
    this.formGroup = this.fb.group({
      businessPartnerStatus: [null],
      agreementTypes: [null],
      generalLiabilityExpirationDate: [null],
      workersCompExpiration: [null],
      cyberSecurityExpirationDate: [null]
    });
    this.child?.beforetoolbarcreated?.subscribe(toolbar => {
      // var tabs = toolbar.getTabs();  get all tabs from the toolbar
      //toolbar.pivotContainer.uielement.addClass("abc")
      // toolbar.getTabs = function () {
      //delete tabs.filter(item => item.title != "Export"); // delete the first tab
      //    return tabs.filter(item => item.title == "Export");
      // }
    })
  }
  submitForm() {
    this.reportsService.runBusinessPartnerReport(this.formGroup.value)
      .subscribe(res => {
        this.onReportComplete();
        const fileText = this.profServices.convertToCsvReports(JSON.stringify(res));
        const blob = new Blob([fileText], {
          type: 'text/csv'
        });
        const url = window.URL.createObjectURL(blob);
        const link = this.donwloadCsvLink.nativeElement;
        link.href = url;
        link.download = 'businessPartnersReports.csv';
        link.click();
        window.URL.revokeObjectURL(url);
      });
  }
  onCustomizeCell(cell: WebDataRocks.CellBuilder, data: WebDataRocks.CellData): void {
    if (data.isClassicTotalRow) cell.addClass("fm-total-classic-r");
    if (data.isGrandTotalRow) cell.addClass("fm-grand-total-r");
    if (data.isGrandTotalColumn) cell.addClass("fm-grand-total-c");
  }

  onReportComplete(): void {
    this.reportsService.runBusinessPartnerReport(this.formGroup.value)
      .subscribe(res => {
        //this.child.applyToolbarStyles();
        this.child.webDataRocks.off("reportcomplete");
        this.reportRan = true;
        this.child.webDataRocks.setReport({
          dataSource: {
            data: res.length > 0 ? res : undefined
          }, slice: {
            expands: {
              expandAll: true
            }
          },
          options: {
            grid: {
              type: "flat",
              showHierarchies: false,
              showGrandTotals: false,
              showTotals: false,
            },
            showAggregationLabels: false,
            showAggregations: false,
          }
        });
      });
  }
  onReady() {
    //this.child.applyToolbarStyles();
  }
  resetFilters() {
    this.formGroup.controls['businessPartnerStatus'].setValue('');
    this.formGroup.controls['agreementTypes'].setValue('');
    this.formGroup.controls['generalLiabilityExpirationDate'].setValue('');
    this.formGroup.controls['workersCompExpiration'].setValue('');
    this.formGroup.controls['cyberSecurityExpirationDate'].setValue('');
    this.onReportComplete();
  }
}
