<mat-card>
  <mat-card-content>
    <div
      [class.loading]="!loading"
      style="position: relative; margin: auto; width: calc(50% + 225px)"
    >
      <mat-spinner
        mode="indeterminate"
        width="450"
        height="450"
        style="margin: 0 auto"
      ></mat-spinner>
    </div>

    <div *ngIf="project" [class.loading]="loading">
      <fieldset>
        <legend>Project Details</legend>
        <div class="row adjust-linehgt">
          <p class="col-md-6">Project Name: {{ project.projectName }}</p>
          <p class="col-md-6">Opp Q#: {{ project.oppurtunityNumber }}</p>
        </div>
        <div class="row adjust-linehgt">
          <p class="col-md-6">Client Name: {{ project.client.commonName }}</p>
          <p class="col-md-6">
            Created Date: {{ project.createdDate | date: "shortDate" }}
          </p>
        </div>
        <div class="align-button">
          <a id="btn-generate-assessment"
            mat-raised-button
            color="primary"
            (click)="generateAssessement()"
            style="float: right"
          >
            <mat-icon>assignment</mat-icon>Generate Assessment
          </a>
        </div>
      </fieldset>
    </div>
    <br />
    <div [class.loading]="loading">
      <fieldset>
        <legend>Assessments</legend>
        <mat-table
          mat-table
          matSort
          class="full-width-table"
          aria-label="Assessments Table"
          [dataSource]="assessmentDataSource"
          [class.loading]="loading"
        >
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Title</mat-header-cell
            >
            <mat-cell *matCellDef="let row">
              {{ row.title }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{
              row.date | date: "shortDate"
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="source">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Source</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{ row.source }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="target">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Target</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{ row.target }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="cost">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Cost/Month</mat-header-cell
            >
            <mat-cell *matCellDef="let row">${{ row.cost }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <ng-container>
                <button id="btn-view-actions-{{row.id}}" mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button id="btn-view-assessment-{{row.id}}" mat-menu-item [routerLink]="[row.id]">
                    <mat-icon>search</mat-icon>
                    <span>View Assessment</span>
                  </button>
                  <button id="btn-delete-assessment-{{row.id}}" mat-menu-item (click)="deleteRecord(row.id)">
                    <mat-icon>delete_outline</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </ng-container>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
        </mat-table>

        <mat-paginator
          [class.loading]="loading"
          #paginator
          [length]="assessments?.length"
          [pageIndex]="0"
          [pageSize]="50"
          [pageSizeOptions]="[5, 25, 50, 100]"
        >
        </mat-paginator>
      </fieldset>
    </div>
  </mat-card-content>
</mat-card>
