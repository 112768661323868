<mat-table *ngIf="workloadsDataSource" mat-table matSort class="full-width-table" aria-label="Cloud Solution Table" [dataSource]="workloadsDataSource">
    <!-- Discovery Workload Items -->
<div *ngIf="!LPARLabeling">
    <ng-container matColumnDef="vmId">
      <mat-header-cell class="mat-workload-header-cell" mat-sort-header *matHeaderCellDef>Name/ID</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="vmCores">
      <mat-header-cell class="mat-workload-header-cell" mat-sort-header *matHeaderCellDef>Cores</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.coresPerProcessor}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="vmRam">
      <mat-header-cell class="mat-workload-header-cell" mat-sort-header *matHeaderCellDef>Ram(GB)</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.ram}}</mat-cell>
    </ng-container>
   
	<ng-container matColumnDef="vmCpu">
      <mat-header-cell class="mat-workload-header-cell" mat-sort-header *matHeaderCellDef>CPU</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.processors}}</mat-cell>
    </ng-container>
    
</div>
<div *ngIf="LPARLabeling">
	<ng-container matColumnDef="name">
	   <mat-header-cell class="mat-workload-header-cell" mat-sort-header *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
	</ng-container>
	   <ng-container matColumnDef="processors">
      <mat-header-cell class="mat-workload-header-cell" mat-sort-header *matHeaderCellDef>Processors</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.processors}}</mat-cell>
    </ng-container>
	<ng-container matColumnDef="ram">
      <mat-header-cell class="mat-workload-header-cell" mat-sort-header *matHeaderCellDef>Memory(GB)</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.ram}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="osType">
      <mat-header-cell class="mat-workload-header-cell" mat-sort-header *matHeaderCellDef>Operating System</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.operatingSystem}}</mat-cell>
	</ng-container>
</div>
	<ng-container matColumnDef="actions">
        <mat-header-cell class="mat-cloudproduct-header-cell" *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="viewRow(row)">
                <mat-icon>edit</mat-icon>
                <span>View</span>
              </button>
              <button mat-menu-item (click)="deleteRow(row.id)">
                <mat-icon>delete_outline</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
 </mat-table>
  
    <mat-paginator #paginator [length]="workloadsDataSource?.length" 
                  [pageIndex]="0" [pageSize]="5"
                   [pageSizeOptions]="[5, 25, 50, 100]">