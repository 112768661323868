import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectControlService } from '../project-control.service';
import { ProjectControlRateService } from '../../project-control-rate/project-control-rate.service';
import { ProjectControlRate, ProjectControl, ProjectControlProjectControlRates } from '../../models/service-role';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project-control-create',
  templateUrl: './project-control-create.component.html',
  styleUrls: ['./project-control-create.component.scss']
})
export class ProjectControlCreateComponent implements OnInit {
  projectControlFormGroup: UntypedFormGroup;
  projectControlProjectControlRates: Array<ProjectControlProjectControlRates>;
  isCreateError: boolean;
  constructor(private fb: UntypedFormBuilder, private router: Router, private projectControlRateService: ProjectControlRateService, private projectControlService: ProjectControlService, private location: Location) { }

  ngOnInit() {

    this.isCreateError = false;
    this.projectControlRateService.getProjectControlRates()
      .subscribe(controlRatesResult => {
          this.projectControlProjectControlRates = controlRatesResult;

      });
    this.projectControlFormGroup = this.fb.group({
      'projectControlName': ['', Validators.required],
      'projectControlDescription': [''],
      'projectControlHourlyRate': [null, Validators.required],
      'projectControlProjectControlRates': [null]
    });
  }
  submitForm() {
    if (this.projectControlFormGroup.valid) {
      const _projectControl: ProjectControl = this.projectControlFormGroup.value;
      _projectControl.projectControlProjectControlRates = [];
      const projectControlRateIds: Array<string> = this.projectControlFormGroup.controls['projectControlProjectControlRates'].value;

      projectControlRateIds?.forEach(id => {
        _projectControl.projectControlProjectControlRates.push(this.projectControlProjectControlRates.filter(pcr => pcr.projectControlRateId === id)[0]);
      });
      this.projectControlService.createProjectControl(_projectControl)
        .subscribe(createResult => {
          const projectControl = createResult;
          // this.router.navigate(['/admin', 'projectControls', 'detail', projectControl.projectControlId]);
          this.location.back();

        },
          (error) => {
            this.isCreateError = true;
          });
    } else {
      this.isCreateError = true;
    }
  }

}
