<mat-card>
  <mat-card-content>
    <div
      class="row"
      *ngIf="!isLPAR && wrkloadFormVM"
      [formGroup]="wrkloadFormVM"
    >
      <div class="col">
        <mat-form-field>
          <input matInput formControlName="name"  placeholder="Server Name/ID" />
          <mat-error
            *ngIf="wrkloadFormVM.controls['name'].hasError('required')"
          >
            Name is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field *ngIf="operatingSystemTypes">
          <mat-label>Operating System</mat-label>
          <mat-select 
            formControlName="operatingSystemType"
            disableOptionCentering
          >
            <mat-option
              *ngFor="let _operatingSystemType of operatingSystemTypes"
              [value]="_operatingSystemType?.value"
            >
              {{ _operatingSystemType?.text }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              wrkloadFormVM.controls['operatingSystemType'].hasError('required')
            "
          >
            Operating System is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field  *ngIf="optimizationTypes" >
          <mat-label>Optimization Type</mat-label>
          <mat-select  formControlName="optimizationType" disableOptionCentering>
            <mat-option 
              *ngFor="let optimizationType of optimizationTypes"
              [value]="optimizationType?.value"
            >
              {{ optimizationType?.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput formControlName="processors" type="number" min="0" placeholder="# of Processors"/>
          <mat-error
            *ngIf="wrkloadFormVM.controls['processors'].hasError('required')"
          >
            # of Processors is required
          </mat-error>
          <mat-error
            *ngIf="wrkloadFormVM.controls['processors'].hasError('min')"
          >
            Please enter value greater than 0
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput formControlName="coresPerProcessor" type="number" min="0" placeholder="Cores Per Processor"/>
          <mat-error
            *ngIf="
              wrkloadFormVM.controls['coresPerProcessor'].hasError('required')
            "
          >
            # of Cores per Processor is required
          </mat-error>
          <mat-error
            *ngIf="wrkloadFormVM.controls['coresPerProcessor'].hasError('min')"
          >
            Please enter value greater than 0
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput formControlName="ram" type="number" min="0" max="32768" list="defaultGigValues" placeholder="Ram(GB)"/>
          <datalist id="defaultGigValues">
            <option value="2"></option>
            <option value="4"></option>
            <option value="8"></option>
            <option value="16"></option>
            <option value="32"></option>
            <option value="64"></option>
            <option value="128"></option>
            <option value="256"></option>
            <option value="512"></option>
            <option value="1024"></option>
            <option value="2048"></option>
            <option value="4096"></option>
            <option value="8192"></option>
            <option value="16384"></option>
            <option value="32768"></option>
          </datalist>
          <mat-error *ngIf="wrkloadFormVM.controls['ram'].hasError('required')">
            Ram is required
          </mat-error>
          <mat-error *ngIf="wrkloadFormVM.controls['ram'].hasError('min')">
            Please enter value greater than 0
          </mat-error>
        </mat-form-field>
        <span *ngIf="showAddAction">
          <button
            mat-icon-button
            type="button"
            id="btn-add-workload"
            [disabled]="!wrkloadFormVM.valid"
            (click)="addVmWorkload()"
          >
            <mat-icon>add</mat-icon>
          </button>
        </span>
      </div>
    </div>
    <div
      class="row"
      *ngIf="isLPAR && wrkloadFormLPAR"
      [formGroup]="wrkloadFormLPAR"
    >
      <!-- Server Name -->
      <div class="col">
        <mat-form-field>
          <input matInput formControlName="name" placeholder="System Name" />
        </mat-form-field>
      </div>
      <!-- Processors -->
      <div class="col">
        <mat-form-field>
      <input matInput formControlName="processors" type="number" min="0" placeholder="Processors CPW Rating"/>
          <mat-error
            *ngIf="wrkloadFormLPAR.controls['processors'].hasError('required')"
          >
            Processors/CPW is required
          </mat-error>
          <mat-error
            *ngIf="wrkloadFormLPAR.controls['processors'].hasError('min')"
          >
            Please enter value greater than 0
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Ram/MemoryElements -->
      <div class="col">
        <mat-form-field>
          <input matInput formControlName="ram" type="number" min="0" placeholder="Main Storage (GB)"/>
          <mat-error *ngIf="wrkloadFormLPAR.controls['ram'].hasError('required')">Ram/Memory Elements is required</mat-error>
          <mat-error *ngIf="wrkloadFormLPAR.controls['ram'].hasError('min')">
            Please enter value of 2 or greater
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Disk Space -->
      <div class="col">
        <mat-form-field>
          <input matInput formControlName="totalDiskSpace"  type="number"  min="0"  placeholder="Total Disk Space (GB)"/>
        </mat-form-field>
      </div>
      <!-- OS Type -->
      <div class="col">
        <mat-form-field  *ngIf="operatingSystemTypes">
          <mat-label>Operating System</mat-label>
          <mat-select
            appearance="fill"
            formControlName="operatingSystemType"
            disableOptionCentering
          >
            <mat-option
              *ngFor="let osType of operatingSystemTypes"
              [value]="osType?.value"
            >
              {{ osType?.text }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              wrkloadFormLPAR.controls['operatingSystemType'].hasError(
                'required'
              )
            "
          >
            Operating System is required
          </mat-error>
        </mat-form-field>
      </div>
      <!-- OS Level -->
      <div class="col">
        <mat-form-field>
          <input matInput  formControlName="operatingSystemRelease" placeholder="OS Release"          />
        </mat-form-field>
      </div>
      <div class="col" *ngIf="showAddAction">
        <button
          mat-icon-button
          type="button"
          id="btn-add-lpar-workload"
          [disabled]="!wrkloadFormLPAR.valid"
          (click)="addLparWorkload()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
