import { Environment } from "./environmentProperties";

export const environment: Environment = {
    production: false,
    appInsights: {
      instrumentationKey: '1449f7fc-0628-4615-a225-434246749991',
      cloudRoleName: 'mup-ng-ui-dev',
      connectionString: 'InstrumentationKey=1449f7fc-0628-4615-a225-434246749991;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/'
    },
    apiEndPoint: 'https://devapi.portal.meridianitinc.com',
    authSettings: {
        authority: 'https://devlogin.portal.meridianitinc.com',
        post_logout_uri: 'https://dev.portal.meridianitinc.com/logout',
        redirect_uri: 'https://dev.portal.meridianitinc.com'
    },
    azureAd: {
      clientId: 'e1be725e-c599-4e34-9ca4-71ca9cf1d859',
      tenatId: 'c55fab4a-533e-46bb-a450-768de6e9d931',
      redirectUri: 'https://dev.portal.meridianitinc.com',
    },
    protectedResources: [
      {
        name: 'portalApi',
        resourceUri: 'https://devapi.portal.meridianitinc.com',
        resourceScopes: ['api://90c69f40-6e53-4bce-811c-6338347db710/ApiAccess']
      },
      {
        name: 'graphApi',
        resourceUri: 'https://graph.microsoft.com',
        resourceScopes: [
          'https://graph.microsoft.com/Application.ReadWrite.All',
          'https://graph.microsoft.com/AppRoleAssignment.ReadWrite.All',
          'https://graph.microsoft.com/User.ReadWrite.All',
          'https://graph.microsoft.com/RoleManagement.ReadWrite.Directory'
        ]
      }
    ],
    enterpriseApplicationId: '3b58bc47-8529-4e68-af13-762dcc114423',
    applicationId: '969e6851-78ba-4eda-9d11-1f6acdafe3ed'
};
