import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approver-threshold',
  templateUrl: './approver-threshold.component.html',
  styleUrls: ['./approver-threshold.component.scss']
})
export class ApproverThresholdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
