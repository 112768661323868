import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfessionalServicesComponent } from './professional-services.component';
import { ProfessionalServicesLandingPageComponent } from './professional-services-landing-page/professional-services-landing-page.component';
import { ProfessionalServicesEstimateCreateComponent } from './service-estimate-components/professional-services-estimate-create/professional-services-estimate-create.component';
import { ParentEstimateCreateComponent } from './service-estimates/parent-estimate-create/parent-estimate-create.component';
import { ProfessionalServicesProjectComponent } from './professional-services-project/professional-services-project.component';
import { ProfessionalServicesProjectLpComponent } from './professional-services-project/professional-services-project-lp/professional-services-project-lp.component';
import { ProfessionalServicesProjectCreateComponent } from './professional-services-project/professional-services-project-create/professional-services-project-create.component';
import { ProfessionalServicesProjectDetailComponent } from './professional-services-project/professional-services-project-detail/professional-services-project-detail.component';
import { ParentEstmiateDetailComponent } from './service-estimates/parent-estmiate-detail/parent-estmiate-detail.component';
import { ChangeOrderCreateComponent } from './change-order/change-order-create/change-order-create.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplatesLandingPageComponent } from './templates/templates-landing-page/templates-landing-page.component';
import { TemplatesCreateComponent } from './templates/templates-create/templates-create.component';
import { CanDeactivateGuard } from '../core/models/UnsavedChanges/can-deactivate-form-unsaved.guard';
import { ProfessionalServicesEstimateSummaryComponent } from './service-estimates/professional-services-estimate-summary/professional-services-estimate-summary.component';
import { ServiceEstimateComponentsPendingApprovalComponent } from './service-estimate-components/service-estimate-components-pending-approval/service-estimate-components-pending-approval.component';
import { ServiceEstimateComponentsApprovedComponent } from './service-estimate-components/service-estimate-components-approved/service-estimate-components-approved.component';
import { ServiceEstimateComponentsPendingPmApprovalComponent } from './service-estimate-components/service-estimate-components-pending-pm-approval/service-estimate-components-pending-pm-approval.component';
import { ServiceEstimatesPendingThresholdApprovalComponent } from './service-estimates/service-estimates-pending-threshold-approval/service-estimates-pending-threshold-approval.component';
import { ServiceEstimateComponentsRateApprovalComponent } from './service-estimate-components/service-estimate-components-rate-approval/service-estimate-components-rate-approval.component';

const componentRoute = [
  {
    path: '', component: ParentEstmiateDetailComponent, data: {
      breadcrumb: null, isRoutable: false
    }
  },
  {
    path: 'createComponent', component: ProfessionalServicesEstimateCreateComponent, data: {
      breadcrumb: 'Create Component', isRoutable: true
    }, canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'editComponent/:secId', component: ProfessionalServicesEstimateCreateComponent, data: {
      breadcrumb: 'Edit Component', isRoutable: true
    }, canDeactivate: [CanDeactivateGuard],
  }
];

const summaryRoute = {
  path: 'seDetails/:serviceEstimateId/summary', component: ProfessionalServicesEstimateSummaryComponent, data: {
    breadcrumb: 'Summary', isRoutable: true
  }
};

const seComponentsRoute = [
  {
    path: '', component: ParentEstimateCreateComponent, data: {
      breadcrumb: null, isRoutable: false
    }, canDeactivate: [CanDeactivateGuard],
  }, {
    path: 'seDetails', data: {
      breadcrumb: 'Components', isRoutable: true
    }, children: componentRoute
  },
  {
    path: 'editComponent/:secId', component: ProfessionalServicesEstimateCreateComponent, data: {
      breadcrumb: 'Edit Component', isRoutable: true
    }, canDeactivate: [CanDeactivateGuard]
  }
];

const editServiceEstimateRoute = {
  path: 'editServiceEstimate/:serviceEstimateId', data: {
    breadcrumb: 'Edit Service Estimate/Change Order', isRoutable: true
  }, children: seComponentsRoute
};

const editChangeOrderRoute = {
  path: 'editChangeOrder/:serviceEstimateId', data: {
    breadcrumb: 'Edit Service Estimate/Change Order', isRoutable: true
  }, children: seComponentsRoute
};

const componentsRoute = {
  path: 'seDetails/:serviceEstimateId', data: {
    breadcrumb: 'Components', isRoutable: true
  }, children: componentRoute
};

const serviceEstimatesRoute = {
  path: ':projectId', data: {
    breadcrumb: 'Service Estimates/Change Orders', isRoutable: true,
  }, children: [
    {
      path: '', component: ProfessionalServicesProjectDetailComponent, data: {
        breadcrumb: null, isRoutable: false
      }
    },
    {
      path: 'createServiceEstimate', component: ParentEstimateCreateComponent, data: {
        breadcrumb: 'Create Service Estimate', isRoutable: true
      }, canDeactivate: [CanDeactivateGuard]
    },
    editServiceEstimateRoute,
    {
      path: 'editChangeOrder/:serviceEstimateId', data: {
        breadcrumb: 'Edit Change Order', isRoutable: true
      }, children: seComponentsRoute
    },
    {
      path: 'createChangeOrder', component: ParentEstimateCreateComponent, data: {
        breadcrumb: 'Create Change Order', isRoutable: true
      }, canDeactivate: [CanDeactivateGuard]
    },
    componentsRoute,
    summaryRoute
  ]
};

const pendingApprovalRoute = {
  path: ':projectId', data: {
    breadcrumb: null, isRoutable: false, isApprovalPendingArea: true
  }, children: [editServiceEstimateRoute, editChangeOrderRoute]
};

const routes: Routes = [
  {
    path: 'professionalServices', component: ProfessionalServicesComponent, data: {
      breadcrumb: "Projects", isRoutable: true
    }, children: [
      { path: '', pathMatch: 'full', redirectTo: 'project' },
      //{ path: '', component: ProfessionalServicesLandingPageComponent },
      //{ path: 'create', component: ProfessionalServicesEstimateCreateComponent },
      //{ path: 'createParent', component: ParentEstimateCreateComponent },
      {
        path: 'project', component: ProfessionalServicesProjectComponent, data: {
          breadcrumb: null, isRoutable: false
        }, children: [
          {
            path: '', component: ProfessionalServicesProjectLpComponent, data: {
              breadcrumb: null, isRoutable: false
            }
          },
          {
            path: 'create', component: ProfessionalServicesProjectCreateComponent, data: {
              breadcrumb: 'Create Project', isRoutable: true
            }, canDeactivate: [CanDeactivateGuard]
          },
          {
            path: ':id/edit', component: ProfessionalServicesProjectCreateComponent, data: {
              breadcrumb: 'Edit Project', isRoutable: true
            }, canDeactivate: [CanDeactivateGuard]
          },
          { path: 'details/:projectId', component: ProfessionalServicesProjectDetailComponent },
          serviceEstimatesRoute          
        ]
      },
      {
        path: 'templates', component: TemplatesComponent, data: {
          breadcrumb: 'Templates', isRoutable: true
        }, children: [
          {
            path: '', component: TemplatesLandingPageComponent, data: {
              breadcrumb: null, isRoutable: false
            }
          },
          {
            path: 'create', component: TemplatesCreateComponent, data: {
              breadcrumb: ' Create Template', isRoutable: true
            }, canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'edit/:templateId', component: TemplatesCreateComponent, data: {
              breadcrumb: ' Edit Template', isRoutable: true
            }, canDeactivate: [CanDeactivateGuard]
          }
        ]
      }, {
        path: 'componentsPendingApproval', data: {
          breadcrumb: 'Components Pending Approval', isRoutable: true
        }, children: [
          {
            path: '', component: ServiceEstimateComponentsPendingApprovalComponent, data: {
              breadcrumb: null, isRoutable: false
            }
          },
          serviceEstimatesRoute
        ]
      },
      {
        path: 'componentsRateApproval', data: {
          breadcrumb: 'Components Rate Approval', isRoutable: true
        }, children: [
          {
            path: '', component: ServiceEstimateComponentsRateApprovalComponent, data: {
              breadcrumb: null, isRoutable: false
            }
          },
          serviceEstimatesRoute
        ]
      },
      {
        path: 'componentsApproved', data: {
          breadcrumb: 'Approved Components', isRoutable: true
        }, children: [
          {
            path: '', component: ServiceEstimateComponentsApprovedComponent, data: {
              breadcrumb: null, isRoutable: false
            }
          },
          serviceEstimatesRoute
        ]
      },
      {
        path: 'serviceestimatependingpmoApproval', data: {
          breadcrumb: 'Service Estimate Pending PM Approval', isRoutable: true
        }, children: [
          {
            path: '', component: ServiceEstimateComponentsPendingPmApprovalComponent, data: {
              breadcrumb: null, isRoutable: false
            }
          }, pendingApprovalRoute,
          componentsRoute,
          summaryRoute

        ]
      },
      {
        path: 'serviceestimatependingThresholdApproval', data: {
          breadcrumb: 'Service Estimate Pending Threshold Approval', isRoutable: true
        }, children: [
          {
            path: '', component: ServiceEstimatesPendingThresholdApprovalComponent, data: {
              breadcrumb: null, isRoutable: false
            }
          }, pendingApprovalRoute,
          componentsRoute,
          summaryRoute

        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfessionalServicesRoutingModule { }
