<div style="width:90%;">
  <button id="addSkuItemButton" mat-icon-button type="button" (click)="addSkuItem.emit()"
          color="primary" title="Add SKU Base Item">
    <mat-icon>add</mat-icon>
  </button>
  <div [formGroup]="formGroup" style="width:100%;">
    <div *ngIf="skuItems.length > 0" class="col-md-12" [formArrayName]="'skuItems'">
      <div *ngFor="let item of skuItems.controls;let i = index;" [formGroupName]="i" [id]="'skuItem'+(i+1)">
        <p class="header5">
          SKU Base Item {{i + 1}}
          <button mat-icon-button type="button" (click)="removeSkuControl(i)" [disabled]='isLocked' id="removeSkuItem">
            <mat-icon class="adjust-font cursor">clear</mat-icon>
          </button>
        </p>
        <div class="col-md-12">
          <div class="row">
            <div class="col-xl-3">
              <mat-form-field>
                <input matInput formControlName="activity" type="text" placeholder="Activity" [id]="'skuActivity'+(i+1)"
                       required />
                <mat-hint>
                  Enter a short description of SKU services being
                  performed.
                </mat-hint>
                <mat-error *ngIf="skuItems.at(i).hasError('required', ['activity'])">
                  Activity is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xl-2">
              <mat-form-field *ngIf="solutionPilars">
                <mat-select *ngIf="!isLocked" required placeholder="Solution Pillar" disableOptionCentering [id]="'skuSolutionPilar'+(i+1)"
                            formControlName="solutionPilarId">
                  <mat-option *ngFor="let pilar of solutionPilars"
                              [value]="pilar.solutionPilarId">
                    {{pilar.solutionPilarName}}
                  </mat-option>
                </mat-select>
                <input [id]="'skuSolutionPilar'+(i+1)" *ngIf="isLocked" matInput placeholder="Solution Pillar *"
                       [value]="getSolutionPilar(item)" [disabled]="isLocked" />
              </mat-form-field>
            </div>
            <div class="col-xl-2">
              <mat-form-field>
                <span matPrefix>$ </span>
                <input currencyMask matInput placeholder="Cost" [id]="'skuCostPrice'+(i+1)"
                       formControlName="cost" required />
                <mat-error *ngIf="skuItems.at(i).hasError('required', ['cost'])">
                  Cost is required
                </mat-error>
                <mat-error *ngIf="skuItems.at(i).hasError('max', ['cost'])">
                  Cost value is greater than solution pillar max charge value
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>


        <hr>
      </div>
    </div>
    </div>
  </div>
