import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';

import { BusinessPartnerService } from '../business-partner.service';
import { Employee } from '../../admin/models/employee';
import { AdminService } from '../../admin/admin.service';
import { SolutionPilar } from '../models/solution-pilar';
import { LocationService } from '../../shared/services/location.service';
import { requestors, signers } from '../models/requestors.model';
import { ServiceableState, ServiceableCity, State, City } from '../models/bp-model';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TagsService } from '../../tags/tags.service';
import { Tag } from '../../tags/models/tag.model';
import { Observable, of } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-business-partner-create',
  templateUrl: './business-partner-create.component.html',
  styleUrls: ['./business-partner-create.component.scss']
})
export class BusinessPartnerCreateComponent implements OnInit {
  businessPartnerForm: UntypedFormGroup;
  filteredRequestorEmployees: Array<any>;
  filteredSignedByEmployees: Array<any>;
  tempList: Array<Employee>;
  solutionPilars: Array<SolutionPilar>;
  tags: Array<Tag>;
  businessAgreements: UntypedFormArray;
  addresses: UntypedFormArray;
  contacts: UntypedFormArray;
  states: Array<State>;
  serviceableCities: Array<any>;
  filteredServiceableCities: Array<any>;
  cities: Array<City> | null;
  citiesGroup: UntypedFormControl;
  primaryLocation: UntypedFormGroup;
  filteredCities: Array<any>;
  disableSubmit: boolean;
  isInternationalEntity: boolean;
  filterString: string;
  citiesAutoCompleteOptions: Observable<Array<City>>;
  serviceableCitiesAutoCompleteOptions: Array<Observable<Array<City>>>;
  constructor(private adminService: AdminService,
    private businessPartnerService: BusinessPartnerService,
    private fb: UntypedFormBuilder,
    private locationService: LocationService,
    private router: Router,
    private location: Location,
    private tagsService: TagsService) { }

  ngOnInit() {
    this.isInternationalEntity = false;
    this.disableSubmit = true;
    this.citiesGroup = new UntypedFormControl();
    this.primaryLocation = this.fb.group({
      'streetOne': [null, Validators.required],
      'streetTwo': [null],
      'stateId': [null, Validators.required],
      'cityId': [null, Validators.required],
      'zipCode': [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'), Validators.maxLength(10)])],
      isPrimary: [true],
      isInternational: [false],
      country: [''],
      city: [''],
      state: ['']
    });
    this.locationService.getStates()
      .subscribe(res => {

        this.states = res;

      });
    this.businessAgreements = this.fb.array([]);
    this.addresses = this.fb.array([]);
    this.contacts = this.fb.array([]);
    this.filteredRequestorEmployees = requestors;
    this.filteredSignedByEmployees = signers;
    this.serviceableCitiesAutoCompleteOptions = [];
    this.businessPartnerService.getSolutionPilars()
      .subscribe(res => {
        this.solutionPilars = res;

      });
    this.tagsService.getTags()
      .subscribe(res => {
        this.tags = res.sort((a, b) => {
          return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
        });
      });
    this.businessPartnerForm = this.fb.group({
      businessPartnerLegalName: ['', Validators.required],
      websiteUri: [''],
      businessPartnerStatus: [0],
      requestor: [null, Validators.required],
      scoreCard: [{ value: '', disabled: true }],
      notes: [''],
      w9Date: [null],
      supplierNumber: [''],
      solutionPilarsBusinessPartners: [null],
      contacts: this.contacts,
      additionalLocations: this.addresses,
      agreements: this.businessAgreements,
      primaryLocation: this.primaryLocation,
      liabilityLimit: [null],
      workersCompLimit: [null],
      workersCompExpiration: [null],
      generalLiabilityExpirationDate: [null],
      cyberSecurityLiabilityLimit: [],
      cyberSecurityExpirationDate: [],
      serviceableStates: [],
      serviceableCities: [],
      businessPartnerTags: [null]
    });
    this.businessPartnerForm.markAllAsTouched();
    this.businessPartnerForm.valueChanges.subscribe(r => {
      this.disableSubmit = !this.businessPartnerForm.valid;
    });
    this.businessPartnerForm.controls['serviceableStates'].valueChanges.subscribe(r => {
      var cities = this.locationService.getCitiesByStateIds(this.businessPartnerForm.controls['serviceableStates'].value)
        .subscribe(res => {
          var cities = res;
          cities.forEach((item, i) =>
            cities[i].name = item.name + "(" + this.states.find(x => x.stateId == item.stateId)?.name + ")");
            this.serviceableCities = cities;
            this.filteredServiceableCities = cities;
        });

    });

    this.primaryLocation.controls['stateId'].valueChanges.subscribe(v => {
      this.primaryLocation.controls["cityId"].patchValue('');
      this.cities = null;
      if (v) {
        this.locationService.getCitiesByStateId(v)
          .subscribe(res => {
            if (res) {
              this.cities = res;
              console.log('cities', this.cities[0])
              this.citiesAutoCompleteOptions = of(this.cities);
              this.primaryLocation.controls["cityId"].patchValue('');
            }
          });
      }
    });

    this.primaryLocation.controls['isInternational'].valueChanges.subscribe(isInternational => {
      if (isInternational) {
        this.primaryLocation.controls["country"].setValidators(Validators.required);
        this.primaryLocation.controls["state"].setValidators(Validators.required);
        this.primaryLocation.controls["city"].setValidators(Validators.required);
        this.primaryLocation.controls["zipCode"].setValidators(Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9\-\ ]+$'), Validators.maxLength(10)]));
        this.primaryLocation.controls["stateId"].clearValidators();
        this.primaryLocation.controls["stateId"].patchValue(null);
        this.primaryLocation.controls["cityId"].clearValidators();
        this.primaryLocation.controls["cityId"].patchValue(null);
        this.primaryLocation.controls["country"].updateValueAndValidity();
        this.primaryLocation.controls["state"].updateValueAndValidity();
        this.primaryLocation.controls["city"].updateValueAndValidity();
        this.primaryLocation.controls["zipCode"].updateValueAndValidity();
      } else {
        this.primaryLocation.controls["stateId"].setValidators(Validators.required);
        this.primaryLocation.controls["cityId"].setValidators(Validators.required);
        this.primaryLocation.controls["zipCode"].setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'), Validators.maxLength(10)]));
        this.primaryLocation.controls["country"].clearValidators();
        this.primaryLocation.controls["country"].patchValue("");
        this.primaryLocation.controls["state"].clearValidators();
        this.primaryLocation.controls["state"].patchValue("");
        this.primaryLocation.controls["city"].clearValidators();
        this.primaryLocation.controls["city"].patchValue("");
        this.primaryLocation.controls["stateId"].updateValueAndValidity();
        this.primaryLocation.controls["cityId"].updateValueAndValidity();
        this.primaryLocation.controls["zipCode"].updateValueAndValidity();
      }
    })

    this.primaryLocation.controls["cityId"].valueChanges
      .subscribe(updatedSelectedCity => {
        if (this.cities) {
          if (updatedSelectedCity) {
            if (typeof updatedSelectedCity == 'string') {
              this.filteredCities = this.cities.filter(s => s.name.toLowerCase().includes(updatedSelectedCity.toLowerCase()));
              this.citiesAutoCompleteOptions = of(this.filteredCities);
            } else {
              this.citiesAutoCompleteOptions = of([]);
            }
          }
        }
      });
  }

displayAutoCompleteForCity(input: any){
  const city = this.cities?.find( city => city.cityId == input);
  return city && city.name ? city.name : '';
}

  addAgreementItem() {
    if (!this.businessAgreements) {
      this.businessAgreements = this.fb.array([]);
    }
    this.businessAgreements.push(this.buildAgreemtnItem());
  }
  addContactItem() {
    if (!this.contacts) {
      this.contacts = this.fb.array([]);
    }
    this.contacts.push(this.buildContactItem());
  }
  addAddressItem() {
    if (!this.addresses) {
      this.addresses = this.fb.array([]);
    }
    this.addresses.push(this.buildAdressItem());
  }
  buildContactItem(contactItem?: any) {
    let item: UntypedFormGroup;

    item = this.fb.group({
      'firstName': [null, Validators.required],
      'lastName': [null, Validators.required],
      'title': null,
      'email': null,
      'primaryPhoneNumber': [null, Validators.compose([Validators.required, Validators.pattern('[0-9]{10,}')])],
      'secondaryPhoneNumber': [null, Validators.pattern('[0-9]{10,}')],
      'faxNumber': null,
      'isPrimaryContact': null
    });
    item.markAllAsTouched();
    return item;
  }


  buildAdressItem(addressItem?: any) {
    let item: UntypedFormGroup;
    item = this.fb.group({
      'streetOne': [null, Validators.required],
      'streetTwo': [null],
      'stateId': [null, Validators.required],
      'cityId': [null, Validators.required],
      'zipCode': [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'), Validators.maxLength(10)])],
      isPrimary: false,
      isInternational: [false],
      country: [''],
      city: [''],
      state: ['']
    });
    this.businessPartnerPostalCodeValidationOnGroup(item);
    item.markAllAsTouched();
    return item;
  }
  buildAgreemtnItem(agreementItem?: any) {
    let item: UntypedFormGroup;
    item = this.fb.group({
      'agreementType': 0,
      'dateOnAgreement': null,
      'dateSignedByMit': null,
      'signer': null,
      'dateAgreementSent': null
    });

    return item;
  }
  removeContact(index: number) {
    this.contacts.removeAt(index);
  }
  removeAddressItem(index: number) {
    this.addresses.removeAt(index);
  }
  removeAgreementItem(index: number) {
    this.businessAgreements.removeAt(index);
  }
  filterEmployees(value: any) {

  }
  filter(val: string): Array<Employee> {
    return this.tempList.filter(option =>
      option.firstName.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }
  typeof(item: any): any {
    return this.typeof(item);
  }
  onSelectAll(isAllStatusSelected: boolean){
    if (isAllStatusSelected) {
      this.businessPartnerForm.controls['serviceableCities'].setValue(this.serviceableCities.map(sc => sc.cityId));
    } else {
      this.businessPartnerForm.controls['serviceableCities'].setValue([]);
    }
    this.businessPartnerForm.controls['serviceableCities'].updateValueAndValidity();
  }
  getServiceableCities(){
    return this.businessPartnerForm.controls['serviceableCities'] as UntypedFormControl;
  }
  submitPartner() {
    if (this.businessPartnerForm.valid) {
      this.disableSubmit = true;
      let formvalues: any;
      formvalues = {};
      Object.assign(formvalues, this.businessPartnerForm.value);
      if (formvalues['serviceableStates']) {
        let serviceStates: Array<ServiceableState>;
        serviceStates = [];
        let formStates: any[];
        formStates = [];
        Object.assign(formStates, formvalues['serviceableStates']);

        formStates.forEach(id => {
          const state = this.states.filter(st => st.stateId == id)[0];
          const serviceableState = new ServiceableState();
          serviceableState.stateId = state.stateId;
          serviceStates.push(serviceableState);
        });
        formvalues['serviceableStates'] = serviceStates;
      }
      if (formvalues['serviceableCities']) {
        let serviceableCities: Array<ServiceableCity>;
        serviceableCities = [];
        let formCities: any[];
        formCities = [];
        Object.assign(formCities, formvalues['serviceableCities']);

        formCities.forEach(id => {
          const city = this.serviceableCities.find(cy => cy.cityId == id);
          if (city) {
            const serviceableCity = new ServiceableCity();
            serviceableCity.cityId = city.cityId;
            serviceableCities.push(serviceableCity);
          }
        });
        formvalues['serviceableCities'] = serviceableCities;
      }
      let sps: Array<any>; let tags: Array<any>;
      sps = []; tags = [];
      const spIds = formvalues['solutionPilarsBusinessPartners'];
      if (spIds) {
        spIds.forEach((sId: string) => {
          const sp = this.solutionPilars.filter(_sp => _sp.solutionPilarId == sId)[0];
          const _sps = { 'businessPartnerId': null, 'solutionPilarId': sp.solutionPilarId };
          sps.push(_sps);
        });
      }
      formvalues['solutionPilarsBusinessPartners'] = sps;
      const tagIds = formvalues['businessPartnerTags'];
      if (tagIds) {
        tagIds.forEach((tagId: string) => {
          const tag = this.tags.filter(_tag => _tag.id == tagId)[0];
          const _tags = { 'businessPartnerId': null, 'tagId': tag.id };
          tags.push(_tags);
        });
      }
      formvalues['businessPartnerTags'] = tags;
      formvalues['locations'] = [];
      formvalues['locations'].push(formvalues['primaryLocation']);
      const locations = formvalues['additionalLocations'];
      locations.forEach((l: any) => {
        formvalues['locations'].push(l);
      });
      this.businessPartnerService.createBusinessPartner(formvalues)
        .subscribe(bpCreateRes => {
          this.location.back();
        },
          (error) => {
            this.disableSubmit = true;
          });
    }
    // else {
    //   const tick = new BusinessPartner();
    //   this.businessPartnerService.createBusinessPartner(tick)
    //     .subscribe(bpCreateRes => {
    //       console.log(bpCreateRes);
    //     });
    // }
  }
  businessPartnerPostalCodeValidationOnGroup(group: UntypedFormGroup) {
    group.controls['isInternational'].valueChanges
      .subscribe(isInternational => {
        if (isInternational) {
          group.controls["zipCode"].setValidators(Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9\-\ ]+$'), Validators.maxLength(10)]));
          group.controls["zipCode"].updateValueAndValidity();
        } else {
          group.controls["zipCode"].setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'), Validators.maxLength(10)]));
          group.controls["zipCode"].updateValueAndValidity();
        }
      });
  }

  onInputChange(inputValue: string) {
    this.filteredServiceableCities = this.serviceableCities.filter(c => c.name.toLowerCase().includes(inputValue.toLowerCase()));
  }

  optionClicked(event: MatCheckboxChange, city: any) {
    this.toggleSelection(event, city);
  }

  toggleSelection(event: MatCheckboxChange, city: any) {
    let formGrp: UntypedFormGroup = this.businessPartnerForm.controls['serviceableCities'] as UntypedFormGroup;
    if (event.checked) {
      if (this.serviceableCities?.some(serviceableCity => serviceableCity.cityId === city.cityId)) {
        city.selected = true;
      }
      if(formGrp.value){
        formGrp.patchValue([...formGrp.value, city.cityId]);
        formGrp.updateValueAndValidity();
      }
      else {
        formGrp.setValue([city.cityId]);
        formGrp.updateValueAndValidity();
      }
    } 
    else if (!event.checked) {
      if (this.serviceableCities?.some(serviceableCity => serviceableCity.cityId === city.cityId)) {
        city.selected = false;
      }
      const updatedValue = formGrp.value.filter((x:any) => x !== city.cityId);
      formGrp.setValue(updatedValue);
      formGrp.updateValueAndValidity();
    }
  };

  displayAutoCompleteForServiceableCity() {
    let stateIds = this.businessPartnerForm.value.serviceableStates;
    let cities = this.serviceableCities?.filter(x => stateIds?.includes(x.stateId));
    let cityIds: string[] = (<UntypedFormGroup>this.businessPartnerForm)?.controls['serviceableCities'].value;
    if (cities && cityIds) {
      let selectedCities = cities.filter(city => cityIds.includes(city.cityId));
      let cityNames = selectedCities.map(city => city.name);
      let commaSeparatedNames = cityNames.join(', ');
      return commaSeparatedNames;
    }
    return ''; 
  }

}
