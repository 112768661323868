<mat-form-field class="full-width">
    <span matTooltip="{{ value }}">
      <input
        matInput
        matTooltip="tooltip"
        [placeholder]="title"
        disabled
        [value]="value"
        class="text-with-dots"
      />
    </span>
    <span matSuffix>{{suffix}}</span>

  </mat-form-field>
