import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BusinessPartnerService } from '../../business-partner.service';
import { SolutionPilar } from '../../models/solution-pilar';
import { Location } from '@angular/common';

@Component({
  selector: 'app-solution-pilar-edit',
  templateUrl: './solution-pilar-edit.component.html',
  styleUrls: ['./solution-pilar-edit.component.scss']
})
export class SolutionPilarEditComponent implements OnInit {

  solutionPilarFormGroup: UntypedFormGroup;
  solutionPilarId: string;
  solutionPilarRatesArray: UntypedFormArray;
  isUniqueName: boolean;
  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private businessService: BusinessPartnerService, private location: Location) { }

  ngOnInit() {
    this.isUniqueName = true;
    this.solutionPilarRatesArray = this.fb.array([]);
    this.route.params
      .subscribe(routeParams => {
        this.solutionPilarId = routeParams['spid'];
        this.businessService.getSolutionPilar(this.solutionPilarId)
          .subscribe(solPilarResult => {
            const solutionPilar: SolutionPilar = solPilarResult;
            if (solutionPilar.solutionPilarRates) {
              solutionPilar.solutionPilarRates.forEach(rate => {
                this.addSolutionPilarRate(rate);
              });
            }
            this.solutionPilarFormGroup = this.fb.group({
              'solutionPilarId': [solutionPilar.solutionPilarId],
              'solutionPilarName': [solutionPilar.solutionPilarName, Validators.required],
              'solutionPilarTag': [solutionPilar.solutionPilarTag, Validators.required],
              'solutionPilarRates': this.solutionPilarRatesArray,
              'createdByEmployeeId': [solutionPilar.createdByEmployeeId]
            });

          });
      });

  }

  addSolutionPilarRate(solutionPilarRate?: any) {
    this.solutionPilarRatesArray.push(this.buildSolutionPilarRate(solutionPilarRate));
  }
  buildSolutionPilarRate(solutionPilarRate?: any) {
    let group: UntypedFormGroup;
    if (solutionPilarRate) {
      group = this.fb.group({
        'id': [solutionPilarRate.id ? solutionPilarRate.id : null],
        'solutionPilarId': [solutionPilarRate.solutionPilarId ? solutionPilarRate.solutionPilarId : this.solutionPilarId],
        'projectManagementPercentage': [solutionPilarRate.projectManagementPercentage ? solutionPilarRate.projectManagementPercentage : null, Validators.required],
        'maxCharge': [solutionPilarRate.maxCharge ? solutionPilarRate.maxCharge : null, Validators.required],
        'minimumCharge': [solutionPilarRate.minimumCharge ? solutionPilarRate.minimumCharge : null, Validators.required],
        'baseHourlyRate': [solutionPilarRate.baseHourlyRate ? solutionPilarRate.baseHourlyRate : null, Validators.required],
        'createdByEmployeeId': [solutionPilarRate.createdByEmployeeId]
      }, { validator: checkMaxMinValidator });
    } else {
      solutionPilarRate = {};
      group = this.fb.group({
        'solutionPilarId': [this.solutionPilarId],
        'projectManagementPercentage': [null, Validators.required],
        'maxCharge': [null, Validators.required],
        'minimumCharge': [null, Validators.required],
        'baseHourlyRate': [null, Validators.required]
      }, { validator: checkMaxMinValidator });
    }
    return group;
  }
  removeSolutionPilarRate(index: any) {
    if (this.solutionPilarRatesArray.controls[index].get('id')?.value) {
      this.businessService.deleteSolutionPilarRate(this.solutionPilarRatesArray.controls[index].get('id')?.value)
        .subscribe(res => {
          this.solutionPilarRatesArray.removeAt(index);

        });
    } else
      this.solutionPilarRatesArray.removeAt(index);
  }
  updateSolutionPilar() {
    if (this.solutionPilarFormGroup.valid) {
      this.businessService.updateSolutionPilars(this.solutionPilarFormGroup.value)
        .subscribe(updateResult => {
          this.location.back();
        }, error => {
          if (error.status == 409) {
            this.isUniqueName = false;
          }
        });
    }
  }

}

export function checkMaxMinValidator(control: AbstractControl) {
  const minimumCharge: string = control.get('minimumCharge')?.value;
  const maxCharge: string = control.get('maxCharge')?.value;
  if (minimumCharge > maxCharge) {
    control.get('minimumCharge')?.setErrors({ checkChargeMinRange: true });
  }
  else if (minimumCharge == null) {
    control.get('minimumCharge')?.setErrors({ required: true });
  }
  else if (minimumCharge <= maxCharge) {
    control.get('minimumCharge')?.setErrors(null);
  }
}
