import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TempApproverService {

  constructor(private http: HttpClient) { }

  getTemporaryApprovers() {
    return this.http.get<any[]>(`/api/TemporaryApprovers`);
  }

  getTemporaryApprover(id: number) {
    return this.http.get<any>(`/api/TemporaryApprovers/${id}`);
  }

  createTemporaryApprover(temporaryApprover: any) {
    const body = JSON.stringify(temporaryApprover);
    return this.http.post(`/api/TemporaryApprovers`, body);
  }

  updateTemporaryApprover(temporaryApprover: any) {
    const body = JSON.stringify(temporaryApprover);
    return this.http.put(`/api/TemporaryApprovers/${temporaryApprover.id}`, body);
  }

  deleteTemporaryApprover(id: string) {
    return this.http.delete(`/api/TemporaryApprovers/${id}`);
  }

}
