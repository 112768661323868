import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-professional-services-project-detail',
  templateUrl: './professional-services-project-detail.component.html',
  styleUrls: ['./professional-services-project-detail.component.scss']
})
export class ProfessionalServicesProjectDetailComponent implements OnInit {
  projectId: any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params
      .subscribe(param => {
        this.projectId = param['projectId'];
        if (!this.projectId) {
          this.route.parent?.parent?.params.subscribe(param2 => {
            this.projectId = param2['id'];
          });
        }

      });
  }

}
