import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BusinessPartnersComponent } from './business-partners.component';
import { BusinessPartnersLandingPageComponent } from './business-partners-landing-page/business-partners-landing-page.component';
import { BusinessPartnerCreateComponent } from './business-partner-create/business-partner-create.component';
import { BusinessPartnerDetailComponent } from './business-partner-detail/business-partner-detail.component';
import { BusinessPartnerEditComponent } from './business-partner-edit/business-partner-edit.component';
import { SolutionPilarsComponent } from './solution-pilars/solution-pilars.component';
import { SolutionPilarLandingPageComponent } from './solution-pilars/solution-pilar-landing-page/solution-pilar-landing-page.component';
import { SolutionPilarCreateComponent } from './solution-pilars/solution-pilar-create/solution-pilar-create.component';
import { SolutionPilarEditComponent } from './solution-pilars/solution-pilar-edit/solution-pilar-edit.component';
import { PartnersComponent } from './partners/partners.component';

const routes: Routes = [
  {
    path: 'partners', component: PartnersComponent, data: {
      breadcrumb: 'Business Partners', isRoutable: true
    }, children: [
      { path: '', pathMatch: 'full', redirectTo: 'businessPartners' },
      {
        path: 'businessPartners', component: BusinessPartnersComponent, data: {
          breadcrumb: null, isRoutable: true
        }, children: [
          {
            path: '', component: BusinessPartnersLandingPageComponent, data: {
              breadcrumb: null, isRoutable: true
            }
          },
          {
            path: 'create', component: BusinessPartnerCreateComponent, data: {
              breadcrumb: 'Create Business Partner', isRoutable: true
            }
          },
          { path: 'detail/:id', component: BusinessPartnerDetailComponent },
          {
            path: 'edit/:id', component: BusinessPartnerEditComponent, data: {
              breadcrumb: 'Edit Business Partner', isRoutable: true
            }
          },
          {
            path: 'view/:id', component: BusinessPartnerEditComponent, data: {
              isViewOnly: true, breadcrumb: 'View Business Partner', isRoutable: true
            }
          },
        ]
      },
      {
        path: 'solutionPilars', component: SolutionPilarsComponent, data: {
          breadcrumb: 'Solution Pillars', isRoutable: true
        }, children: [
          {
            path: '', component: SolutionPilarLandingPageComponent, data: {
              breadcrumb: null, isRoutable: false
            }
          },
          {
            path: 'create', component: SolutionPilarCreateComponent, data: {
              breadcrumb: 'Create Solution Pillar', isRoutable: true
            }
          },
          {
            path: 'edit/:spid', component: SolutionPilarEditComponent, data: {
              breadcrumb: 'Edit Solution Pillar', isRoutable: true
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BusinessPartnersRoutingModule { }
