<div *ngIf="assessment">
  <mat-toolbar color="primary">
    <button id="btn-save" mat-icon-button 
            matTooltip="Save Pricing Updates"
            matTooltipClass="tooltip-background-color" 
            type="button" (click)="savePricingUpdates();">
      <mat-icon>save</mat-icon>
    </button>
    <button id="btn-clear" mat-icon-button type="button" 
            matTooltip="Clear Pending Changes"
            matTooltipClass="tooltip-background-color" 
            (click)="resetValues()">
      <mat-icon>clear</mat-icon>
    </button>
    <button class="contract-preview-button" id="btn-contract-preview" mat-icon-button type="button" 
            matTooltip="Preview Contract Proposal"
            matTooltipClass="tooltip-background-color" 
            (click)="contractPreview()">
      <mat-icon>preview</mat-icon>
    </button>
  </mat-toolbar>
  <mat-card>
    <mat-card-title>{{assessment.cloudProviderName}} {{assessment.title}}</mat-card-title>
    <mat-card-content *ngIf="assessment">
      <div [class.loading]="!loading" style="position: relative; margin:auto;width:calc(50% + 225px)">
        <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
      </div>
      <div class="row">
        <div class="col-8">
          <app-cloud-migration-pricing-form #pricingComponent [assessment]="assessment" [resetFormsEvent]="resetForms"
                                            (formValueEvent)="onPricingFormUpdated($event)">
          </app-cloud-migration-pricing-form>
        </div>
        <div class="col-4">
          <app-cloud-migration-pricing-summary  [pricingSummaryData]="pricingSummaryData"
                                                [managedServiceCostUpdated]="managedServiceCostUpdated"
                                                [platformCostUpdated]="platformCostUpdated"
                                                [migrationCostUpdated]="migrationCostUpdated"
                                                [dataTransferCostUpdated]="dataTransferCostUpdated">
          </app-cloud-migration-pricing-summary>
        </div>
      </div>
      <div class="row" [class.loading]="loading">
        <div class="col-4">
          <app-cloud-migration-compute-summary [assessment]="assessment"></app-cloud-migration-compute-summary>
        </div>
        <div class="col-4">
          <app-cloud-migration-network-summary [networkSummaryData]="networkSummaryData"
                                               [networkRequiredBandwitdhUpdated]="networkBandwidthUpdated">
          </app-cloud-migration-network-summary>
        </div>
        <div class="col-4">
          <app-cloud-migration-storage-summary [storageSummaryData]="storageSummaryData"
                                               [storageCapacityUpdated]="storageCapacityUpdated"
                                               [storageTypeUpdated]="storageTypeUpdated">
          </app-cloud-migration-storage-summary>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-cloud-migration-cloud-solution-details [assessment]="assessment"
                                                      [priceUpdate]="updatePriceTotalForPlatformCostChange">
          </app-cloud-migration-cloud-solution-details>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
