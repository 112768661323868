import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DocumentCreatorComponent } from './document-creator.component';
import { DocumentCreatorListComponent } from './document-creator-list/document-creator-list.component';
import { DocumentCreatorCreateComponent } from './document-creator-create/document-creator-create.component';
import { DocumentCreatorDetailsComponent } from './document-creator-details/document-creator-details.component';

const routes: Routes = [
  {
    path: 'documentcreator', component: DocumentCreatorComponent, data: {
      breadcrumb: 'Document creator', isRoutable: true
    }, children: [
      {
        path: '', component: DocumentCreatorListComponent, data: {
          breadcrumb: null, isRoutable: false
        } },
      {
        path: 'create', component: DocumentCreatorCreateComponent, data: {
          breadcrumb: 'Create Document creator', isRoutable: true
        } },
      {
        path: 'details/:id', component: DocumentCreatorDetailsComponent, data: {
          breadcrumb: 'Edit Document creator', isRoutable: true
        } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentCreatorRoutingModule { }
