<div *ngIf="expenseReportFormGroup">
  <form [formGroup]="expenseReportFormGroup" (ngSubmit)="runReport()">
    <mat-form-field>
      <input matInput [matDatepicker]="picker" formControlName="startDate" placeholder="Start Date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" placeholder="End Date">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-select multiple formControlName="driverIds" placeholder="Select drivers" disableOptionCentering>
        <mat-option *ngFor="let driver of drivers" [value]="driver.vehicleDriverId"> {{driver.firstName + ' ' + driver.lastName}} </mat-option>
      </mat-select>
    </mat-form-field>
    <a #donwloadCsvLink></a>
    <button type="submit" mat-raised-button color="primary" [disabled]="!expenseReportFormGroup.valid">Run Report</button>
  </form>
</div>
