
<mat-card *ngIf="videoForm">
  <h3>{{videoId? 'Update': 'Create/Upload'}} Help Video</h3>
  <div [class.loading]="!isLoading" style="position: relative; margin:auto;width:calc(50% + 225px)">
    <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
  </div>
  <form [formGroup]="videoForm" *ngIf="!isLoading">
    <div class="row">
      <div class="col-md-4">
        <mat-card-content>
          <mat-form-field>
            <input matInput formControlName="title"
                   placeholder="Video Title" />
            <mat-error *ngIf="videoForm.controls['title'].hasError('required')">
              Video Title is required
            </mat-error>
            <mat-error *ngIf="videoForm.controls['title'].errors?.maxlength">
              Video Title should not exceed 32 characters
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="description"
                   placeholder="Video Description" />
            <mat-error *ngIf="videoForm.controls['description'].hasError('required')">
              Video Description is required
            </mat-error>
            <mat-error *ngIf="videoForm.controls['description'].errors?.maxlength">
              Video Description should not exceed 255 characters
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="name"
                   placeholder="Name" />
            <mat-error *ngIf="videoForm.controls['name'].hasError('required')">
              Name is required
            </mat-error>
            <mat-error *ngIf="videoForm.controls['name'].errors?.maxlength">
              Name should not exceed 128 characters
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="section"
                   placeholder="Section" />
            <mat-error *ngIf="videoForm.controls['section'].hasError('required')">
              Section is required
            </mat-error>
            <mat-error *ngIf="videoForm.controls['section'].errors?.maxlength">
              Section should not exceed 128 characters
            </mat-error>
          </mat-form-field>
          <div class="row" *ngIf="videoId">
            <div class="col-md-3">
              <mat-checkbox formControlName="isActive">Is Active?</mat-checkbox>
              <mat-error *ngIf="videoForm.controls['isActive'].hasError('required')">
                Active field is required
              </mat-error>
            </div>
            <div class="col-md-3">
              <mat-checkbox formControlName="isVisible">Is Visible?</mat-checkbox>
              <mat-error *ngIf="videoForm.controls['isVisible'].hasError('required')">
                Visible field is required
              </mat-error>
            </div>
          </div>
          <mat-form-field *ngIf="videoId">
            <input matInput formControlName="version" type="number" min="1" step="1"
                   placeholder="Version" />
            <mat-error *ngIf="videoForm.controls['version'].hasError('required')">
              Version is required
            </mat-error>
            <mat-error *ngIf="videoForm.controls['version'].hasError('pattern')">
              Only integer numbers are allowed
            </mat-error>
          </mat-form-field>
          <div>
            <div class="upload">
              <input type="file" #fileRef id="fileRef" (change)="updateVideoControl($event)" accept="video/mp4" [disabled]="videoId" placeholder="Select a Video File" value="fileName" (blur)="setErrorStatus()" />

              <label for="fileRef" [class.label-disabled]="videoId">Browse for Video(mp4) file to upload<mat-icon class="icon">attachment</mat-icon></label>
            </div>
            <div class="single-file">
              <div class="info">
                <h4 class="name">
                  {{ fileName }}
                </h4>
              </div>
            </div>
            <div class="error" *ngIf="videoUriError">A Video file is required</div>
            <div class="error" *ngIf="isVideoUriExceeds()">Video Url name should not exceed 128 characters</div>
            
          </div>
          <div class="video" [hidden]="!fileName">
            <video controls (click)="toggleVideo()" preload="none" #videoPlayer>
              <source [src]="fileName" type="video/mp4" (error)="handleError($event)" />
              Browser not supported
            </video>
          </div>
          <div class="error align-margin" *ngIf="videoId && isErrorFile">Video file no longer exists in the Azure Storage</div>
        </mat-card-content>
        <mat-card-actions>
          <button type="submit" mat-raised-button [disabled]="!videoForm.valid || (videoId && !isDirty()) || isVideoUriExceeds()" (click)="saveVideo()">Save</button>
        </mat-card-actions>
      </div>
    </div>
  </form>
</mat-card>
