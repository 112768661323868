import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mileage-confirmation-dialog',
  templateUrl: './mileage-confirmation-dialog.component.html',
  styleUrls: ['./mileage-confirmation-dialog.component.scss']
})
export class MileageConfirmationDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
