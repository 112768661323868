import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import { CloudMigrationEnvironmentPopupComponent } from '../cloud-migration-environment-popup/cloud-migration-environment-popup.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CloudMigrationEnvironmentConfigPopupComponent } from '../configurations/cloud-migration-environment-config-popup/cloud-migration-environment-config-popup.component';

@Component({
  selector: 'cloud-migration-environment',
  templateUrl: './cloud-migration-environment.component.html',
  styleUrls: ['./cloud-migration-environment.component.scss'],
})
export class CloudMigrationEnvironmentComponent implements OnInit {
  loading: boolean;
  projectId: any;
  project: any;
  sort: any;
  paginator: any;
  newEnvironment: any;
  refreshList: boolean = false;
  environmentList: any;
  message: string;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private cloudMigrationService: CloudMigrationToolService
  ) {}

  ngOnInit(): void {
    this.setData();
    
    this.loading = false;
  }
  setData() {
    this.loading = true;
    this.route.parent?.params.subscribe(
      (param) => {
        this.projectId = param['id'];
        console.log("setdata: ", this.projectId);
        let project = this.cloudMigrationService.getProject(this.projectId);
        forkJoin([project]).subscribe((results) => {
          this.loading = false;
          this.project = results[0];
          this.loading = false;
        });
        this.setNewEnvironmentData();
        this.setEnvironmentList();
        this.message = '';
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  setEnvironmentList(){
    this.cloudMigrationService.getDiscoveryEnvironments(this.projectId)
    .subscribe((data) => {
      this.environmentList = data;
    });
  }
  setNewEnvironmentData() {
    this.cloudMigrationService
      .getDiscoveryEnvironment(this.projectId, '00000000-0000-0000-0000-000000000000')
      .subscribe((data) => {
        this.newEnvironment = data;

      });
  }

  onAddEnvironment($event: any) {
    this.message = '';
    if ($event?.data?.message != "Success") {
      this.message = $event?.data?.message;
    }
    this.setEnvironmentList();
  }

  onEditEnvironmentClick($event: any) {
    let environmentId = $event;
    this.cloudMigrationService
      .getDiscoveryEnvironment(this.projectId, environmentId)
      .subscribe((data) => {
        this.openEditEnvironmentDialog(data);
      });
  }

  openEditEnvironmentDialog(newEnvironment: any) {
    const dialogRef = this.dialog.open(
      CloudMigrationEnvironmentPopupComponent,
      {
        height: '20%',
        width: '35%',
        data: {
          environment: newEnvironment,
          projectId: this.projectId,
        },
        disableClose: true,
        autoFocus: true,
      }
    );
    dialogRef.afterClosed().subscribe(res => {
      this.setEnvironmentList();
    });
  }
  onEditEnvironmentConfigClick($event: any) {
    let environmentId = $event;

    this.cloudMigrationService
      .getenviromentconfiguration(environmentId)
      .subscribe((data) => {
        data.projectId = this.projectId;
        this.openEditConfigurationDialog(data);
      });
  }
  
  openEditConfigurationDialog(environmentConfig: any) {
    const popup = this.dialog.open(CloudMigrationEnvironmentConfigPopupComponent, {
      height: '35%',
      width: '60%',
      data: { 'configuration': environmentConfig },
      disableClose: true
    });
    popup.afterClosed().subscribe(res => {
      if (res) {
        let item: any;
        //this.getDiscoveryConfigs();
      }
    });
  }
  
}
