
import { AbstractControl, ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export function nonZeroValidator(control: AbstractControl) {
  const value: number = control.value;
  if (value === 0) {
    return { 'nonZero': true };
  };
  return null;
}

/**
 * Revisit and understand why this is here
 */
// export const identityRevealedValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null | any => {
//   const name = control.get('name');
//   const alterEgo = control.get('alterEgo');

//   return name && alterEgo && name.value === alterEgo.value ? { 'identityRevealed': true } : null;
// };
