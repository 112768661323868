<mat-card>
  <mat-card-content>
    <div class="col-md-12">
      <div class="row">
        <mat-form-field>
          <input type="text"
                 placeholder="Title"
                 matInput
                 [(ngModel)]="documentCreator.title" />
        </mat-form-field>

      </div>
      <div class="row">
        <mat-form-field>
          <input type="text"
                 matInput
                 placeholder="Module"
                 [(ngModel)]="documentCreator.module" />
        </mat-form-field>
      </div>

      <div class="row">
          <mat-form-field>
            <input type="text"
                   matInput
                   placeholder="Description"
                   [(ngModel)]="documentCreator.description" />
          </mat-form-field>
        </div>
    </div>
    <br>
    <!-- <ck-editor name="editor1"
               [(ngModel)]="documentCreator.content"
               skin="moono-lisa"
               lang="en"
               [fullPage]="true"></ck-editor> -->
    <br>

  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button
            type="button"
            (click)="saveDocument()">Save</button>
  </mat-card-actions>
</mat-card>
<div>
  <button mat-raised-button (click)="updateDisplayedValue()"
          type="button">Update Value</button>

</div>

<div [innerHTML]="trustedValue">

</div>
