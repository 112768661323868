<mat-card>
  <mat-card-header>
    <h3>Release History</h3>
  </mat-card-header>
  <mat-card-content>
    <div [class.loading]="!isLoading" style="position: relative; margin:auto;width:calc(50% + 225px)">
      <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
    </div>
    <mat-accordion [displayMode]="'flat'" [class.loading]="isLoading">
    
      <mat-expansion-panel *ngFor="let releaseNote of releaseNotesData">
        <mat-expansion-panel-header (click)="expandPanel(releaseNote)" [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title>
            <div class="row">
              <span class="col-md-1" id="releaseVersion">Version : {{releaseNote.version}}</span>
              <span class="col-md-2" id="releaseDate">Release Date : {{releaseNote.releaseDate| date:'shortDate'}}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>
        <div>
          <div [class.loading]="!isPanelLoading" style="position: relative; margin:auto;width:calc(50% + 225px)">
            <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
          </div>
          <div *ngIf="!isPanelLoading && userstories?.length" [class.loading]="isPanelLoading">
            <h4>Enhancements :</h4>
            <mat-table [dataSource]="userstories">
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                <mat-cell *matCellDef="let element" [innerHtml]="element.releaseNoteDetails"> </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell class="align-center" *matCellDef="let element" id="kebabMenu">
                  <ng-container>
                    <button mat-icon-button [matMenuTriggerFor]="menu" [attr.data-id]="element.id">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item [routerLink]="['details', element.id]">
                        <mat-icon>edit</mat-icon>
                        <span>Details</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                </mat-cell>
              </ng-container>
    
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>
    
          <div *ngIf="!isPanelLoading && bugs?.length" [class.loading]="isPanelLoading">
            <h4>Bugs :</h4>
            <mat-table [dataSource]="bugs">
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                <mat-cell *matCellDef="let element" [innerHtml]="element.releaseNoteDetails"></mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell class="align-center" *matCellDef="let element" id="kebabMenu">
                  <ng-container>
                    <button mat-icon-button [matMenuTriggerFor]="menu" [attr.data-id]="element.id">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item [routerLink]="['details', element.id]">
                        <mat-icon>edit</mat-icon>
                        <span>Details</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                </mat-cell>
              </ng-container>
    
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>
          <div *ngIf="!isPanelLoading && noRecords" [class.loading]="isPanelLoading">
            No records found
          </div>
        </div>
      </mat-expansion-panel>
      <div *ngIf="!(releaseNotesData && releaseNotesData.length > 0)" [class.loading]="loading" class="no-records">
        No records found
      </div>
    </mat-accordion>
    
  </mat-card-content>
</mat-card>


