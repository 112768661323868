import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import { Discovery } from '../../models/discovery-datasource';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { CloudMigrationDiscoveryNetworkComponent } from '../../discoveries/cloud-migration-discovery-network/cloud-migration-discovery-network.component';
import { CloudMigrationDiscoveryNetworkprofileComponent } from '../../discoveries/cloud-migration-discovery-networkprofile/cloud-migration-discovery-networkprofile.component';
@Component({
  selector: 'app-cloud-migration-analysis-data-transfer',
  templateUrl: './cloud-migration-analysis-data-transfer.component.html',
  styleUrls: ['./cloud-migration-analysis-data-transfer.component.scss']
})
export class CloudMigrationAnalysisDataTransferComponent implements OnInit {
  projectId: string;
  displayedInterfaceColumns = ['broadcastIp'];
  loading: boolean;
  discovery: Discovery;
  discoveryId: string;
  clientId: string;
  networkData: any;
  networkProfileList: any;
  discoverySourceEnvironment: any;
  networkProfiling: any;

  constructor(private route: ActivatedRoute, private router: Router,
    private dialog: MatDialog,
    private cloudMigrationService: CloudMigrationToolService) { }

  ngOnInit(): void {
    this.loading = true;
    const params = this.route.parent?.parent?.params;
    params?.subscribe(param => {
      this.projectId = param['id'];

      if (this.projectId) {
        this.loadData();
      }
    });

  }

  loadData() {
    this.loading = true;
    this.cloudMigrationService.getDataTransferNetworkProfiling(this.projectId).subscribe(res => {
      this.networkProfiling = res;
      this.loading = false;
      this.networkData = this.networkProfiling.networkData;
      this.networkProfileList = this.networkProfiling.networkProfiles;
      
    }, (error) => {
      this.loading = false;
    });
  }

  // getDiscoveries() {
  //   this.cloudMigrationService.getDiscoveries(this.project.id).subscribe(
  //     (res) => {
  //       this.discoveryList = res;
  //       this.discoveryDataSource = new DiscoveryDataSource(this.discoveryList);
  //       this.discoveryDataSource.sort = this.sort;
  //       this.discoveryDataSource.paginator = this.paginator;
  //       this.loading = false;
  //     },
  //     (error) => {
  //       this.loading = false;
  //     }
  //   );
  // }

  generateNetworkProfile()
  {
    this.openGenerateNetworkProfileDialog();

  }
  
  
  manageNetworkProfile() {
    const popup = this.dialog.open(CloudMigrationDiscoveryNetworkprofileComponent, {
      width: '80%',
      minWidth: '250px',
      data: {
        discoveryId: this.discoveryId,
      },
      disableClose: true,
      autoFocus: true,
    });

    popup.afterClosed().subscribe(res => {
      console.log('popup after close res:', res);
      if (res) {
        this.loadData();
      }
    });;
  }
    
  
  addNetworkData() {
    const popup = this.dialog.open(CloudMigrationDiscoveryNetworkComponent, {
      width: '80%',
      data: {
        discoveryId: this.discoveryId,
      },
      disableClose: true
    });

    popup.afterClosed().subscribe(res => {
      console.log('popup after close res:', res);
      if (res) {
        this.loadData();
      }
    });
  }
 
 
  openGenerateNetworkProfileDialog() {
    const popup = this.dialog.open(CloudMigrationDiscoveryNetworkprofileComponent, {
      width: '80%',
      minWidth: '250px',
      data: {
        networkDataId: this.networkData.id,
        discoveryId: this.discoveryId,
        delay: this.networkData.delay,
        loss: this.networkData.loss,
      },
      disableClose: true
    });

    popup.afterClosed().subscribe(res => {
      console.log('popup after close res:', res);
      if (res) {
        this.loadData();
      }
    });
  }
}

