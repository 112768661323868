import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-rejections-popup',
  templateUrl: './rejections-popup.component.html',
  styleUrls: ['./rejections-popup.component.scss']
})
export class RejectionsPopupComponent implements OnInit {
  note: string;

  constructor(
    public dialogRef: MatDialogRef<RejectionsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.note = "";
  }
  onNoClick() {
    this.dialogRef.close(this.note);
  }

}
