import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeoFencingService {

  constructor(private httpClient: HttpClient) { }

  getCities() {
    return this.httpClient.get<any[]>(`/api/cities`);
  }
  getCitiesByStateId(stateId: string) {
    return this.httpClient.get<any[]>(`/api/locations/states/${stateId}/cities`);
  }
  getStates() {
    return this.httpClient.get<any[]>(`/api/locations/states`);
  }
  getState(id: string) {
    return this.httpClient.get<any>(`/api/locations/states/${id}`);
  }
  getBusinessPartners() {
    return this.httpClient.get<any[]>(`/api/businessPartners`);
  }
  getGeoFencedCities(lon: number | undefined, lat: number | undefined, radius: string) {
    return this.httpClient.get<any[]>(`/api/cities/geofenced?lat=${lat}&lon=${lon}&radius=${radius}`);
  }
  checkForValidOrigin(origin: string) {
    return this.httpClient.get<boolean>(`/api/cities/isValidOrigin/${origin}`);
  }
  deleteBusinessPartners(id: string) {
    return this.httpClient.delete(`/api/businessPartners/${id}`);
  }
}
