<div class="container-fluid"
     *ngIf="document">
  <mat-toolbar>
    <button mat-raised-button
            type="button"
            color="warn"
            (click)="deleteDocument()">Delete</button>
  </mat-toolbar>
  <!-- <div [innerHTML]="document">

  </div> -->
</div>
