import { Component, OnInit } from '@angular/core';
import { LocationService } from '../../location.service';

@Component({
  selector: 'app-state-list',
  templateUrl: './state-list.component.html',
  styleUrls: ['./state-list.component.scss']
})
export class StateListComponent implements OnInit {

  constructor(private locationService: LocationService) { }

  ngOnInit() {
  }

}
