<mat-card>
  <mat-card-content>
    <a id="releaseNoteCreate" mat-raised-button [routerLink]="['create']" color="primary"
       matTooltip="Create Release Note" [matTooltipPosition]="'right'">
      <mat-icon class="add-icon">add</mat-icon>
      Release Note
    </a>
    <div [class.loading]="!isLoading" style="position: relative; margin:auto;width:calc(50% + 225px)">
      <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
    </div>
    <br />
    <div class="col-md-12" [class.loading]="isLoading">
      <fieldset>
        <legend>
          Search Selection
        </legend>
        <form [formGroup]="filterForm">
          <mat-form-field>
            <mat-select formControlName="iteration" id="iteration" disableOptionCentering #employee placeholder="Filter By Iteration">
              <mat-option [value]="''">
                None
              </mat-option>
              <mat-option *ngFor="let itr of iterations" [value]="itr.id">
                {{itr.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Filter By Date Range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date" #startDate formControlName="startDate">
              <input matEndDate placeholder="End date" #endDate formControlName="endDate">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" placeholder="Filter by Version" id="version" formControlName="version" />
          </mat-form-field>
          <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters" id="resetBtn">
            <mat-icon>clear</mat-icon>
          </button>
        </form>
      </fieldset>
    </div>
    <br />
    <div class="col-md-12">
      <mat-table #table [dataSource]="releaseNotesDataSource" matSort [class.loading]="isLoading" class="apply-border">
        <ng-container matColumnDef="iteration">
          <mat-header-cell class="sortable" *matHeaderCellDef mat-sort-header> Iteration </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngFor="let itr of iterations | filterByIterationId: element.iterations; first as isFirst">
              <span *ngIf="!isFirst">, </span><span class="remove-white-space"> {{itr.name}}</span>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="releaseDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Release Date </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.releaseDate| date:'shortDate'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="version">
          <mat-header-cell class="sortable" *matHeaderCellDef mat-sort-header> Version </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.version}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="notes">
          <mat-header-cell class="sortable" *matHeaderCellDef> Notes </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.notes}} </mat-cell>
        </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" [attr.data-id]="element.id">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item color="default" type="button" [routerLink]="['edit',element.id]">
              <mat-icon>edit</mat-icon>
              <span>Edit/View</span>
            </button>
            <button mat-menu-item (click)="deleteRecord(element.id)">
              <mat-icon>delete_outline</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator #paginator [class.loading]="isLoading"
                   [length]="releaseNotesDataSource?.data.length" [pageIndex]="0" [pageSize]="50"
                   [pageSizeOptions]="[5, 25, 50, 100]">
    </mat-paginator>
  </mat-card-content>
</mat-card>
