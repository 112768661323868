<div class="mat-elevation-z8">
    <form [formGroup]="clientForm">
      <mat-form-field>
        <mat-label>ExternalCustomerId</mat-label>
        <input matInput type="text" formControlName="externalCustomerId" />
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="CustomerType" formControlName="customerType" disableOptionCentering>
          <mat-option [value]="0"> Suspect </mat-option>
          <mat-option [value]="1"> Prospect </mat-option>
          <mat-option [value]="2"> Customer </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>CommonName</mat-label>
        <input matInput type="text" placeholder="" formControlName="commonName" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>LegalName</mat-label>
        <input matInput type="text" placeholder="" formControlName="legalName" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>AddressLine1</mat-label>
        <input matInput type="text" placeholder="" formControlName="addressLine1" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>AddressLine2</mat-label>
        <input matInput type="text" placeholder="" formControlName="addressLine2" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>AddressLine3</mat-label>
        <input matInput type="text" placeholder="" formControlName="addressLine3" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput type="text" placeholder="" formControlName="city" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>State</mat-label>
        <input matInput type="text" placeholder="" formControlName="state" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Zip</mat-label>
        <input matInput type="text" placeholder="" formControlName="zip" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Country</mat-label>
        <input matInput type="text" placeholder="" formControlName="country" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>IndustryClassType</mat-label>
        <input matInput type="text" placeholder="" formControlName="industryClassType" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>IndustryClassCode</mat-label>
        <input matInput type="text" placeholder="" formControlName="industryClassCode" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>IndustryClassDescription</mat-label>
        <input matInput type="text" placeholder="" formControlName="industryClassDescription" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Phone</mat-label>
        <input matInput type="tel" placeholder="" formControlName="phone" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="email" placeholder="" formControlName="email" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Website</mat-label>
        <input matInput type="text" placeholder="" formControlName="website" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>TerritoryDescription</mat-label>
        <input matInput type="text" placeholder="" formControlName="territoryDescription" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Region</mat-label>
        <input matInput type="text" placeholder="" formControlName="region" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>DateCreatedExternally</mat-label>
        <input matInput type="text" placeholder="" formControlName="dateCreatedExternally" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Notes</mat-label>
        <input matInput type="text" placeholder="" formControlName="notes" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput type="text" placeholder="" formControlName="description" />
      </mat-form-field>
      <mat-checkbox formControlName="customSowTemplate">Custom SOW Template</mat-checkbox>
    </form>
</div>
