import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { LocationService } from '../../shared/services/location.service';

@Component({
  selector: 'app-business-partner-location-item',
  templateUrl: './business-partner-location-item.component.html',
  styleUrls: ['./business-partner-location-item.component.scss']
})
export class BusinessPartnerLocationItemComponent implements OnInit {
  formGroupName: UntypedFormGroup;
  @Input() formArray: UntypedFormArray;
  @Input() index: number;
  model: any;

  states: Array<any>;
  cities: Array<any>;
  citiesAutoCompleteOptions: Observable<any>;
  filteredCities: any[];
  constructor(private locationService: LocationService) { }

  ngOnInit() {
    this.formGroupName = <UntypedFormGroup>this.formArray?.controls[this.index];
    const obj = this.formGroupName?.controls['stateId'];
    if (obj?.value) {
      this.locationService.getCitiesByStateId(obj.value)
        .subscribe(cityRes => {
          this.cities = cityRes;
          this.citiesAutoCompleteOptions = of(this.cities);
        });
    }
    obj?.valueChanges.subscribe(value => {
      this.formGroupName?.controls["cityId"].patchValue('');
      this.locationService.getCitiesByStateId(value)
        .subscribe(cityResult => {
          this.cities = cityResult;
          this.citiesAutoCompleteOptions = of(this.cities);
        });

    });
    this.model = {};

    this.locationService.getStates()
      .subscribe(stateResult => {
        this.states = stateResult;
      });

    this.formGroupName.controls['isInternational'].valueChanges.subscribe(isInternational => {
      if (isInternational) {
        this.formGroupName.controls["country"].setValidators(Validators.required);
        this.formGroupName.controls["state"].setValidators(Validators.required);
        this.formGroupName.controls["city"].setValidators(Validators.required);
        this.formGroupName.controls["stateId"].clearValidators();
        this.formGroupName.controls["stateId"].patchValue(null);
        this.formGroupName.controls["cityId"].clearValidators();
        this.formGroupName.controls["cityId"].patchValue(null);
        this.formGroupName.controls["country"].updateValueAndValidity();
        this.formGroupName.controls["state"].updateValueAndValidity();
        this.formGroupName.controls["city"].updateValueAndValidity();
      } else {
        this.formGroupName.controls["stateId"].setValidators(Validators.required);
        this.formGroupName.controls["cityId"].setValidators(Validators.required);
        this.formGroupName.controls["country"].clearValidators();
        this.formGroupName.controls["country"].patchValue("");
        this.formGroupName.controls["state"].clearValidators();
        this.formGroupName.controls["state"].patchValue("");
        this.formGroupName.controls["city"].clearValidators();
        this.formGroupName.controls["city"].patchValue("");
        this.formGroupName.controls["stateId"].updateValueAndValidity();
        this.formGroupName.controls["cityId"].updateValueAndValidity();
      }
    })
    this.formGroupName.controls["cityId"].valueChanges
      .subscribe(updatedSelectedCity => {
        if (this.cities) {
          if (updatedSelectedCity) {
            if (typeof updatedSelectedCity == 'string') {
              this.filteredCities = this.cities.filter(s => s.name.toLowerCase().includes(updatedSelectedCity.toLowerCase()));
              this.citiesAutoCompleteOptions = of(this.filteredCities);
            } else {
              this.citiesAutoCompleteOptions = of([]);
            }
          }
        }
      });
  }

  displayAutoCompleteForCity(input: any){
    const city = this.cities?.find( city => city.cityId == input);
    return city && city.name ? city.name : '';
  }
}
