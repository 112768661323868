import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupportRoutingModule } from './support-routing.module';
import { SupportLandingPageComponent } from './support-landing-page/support-landing-page.component';
import { SupportComponent } from './support.component';
import { SharedModule } from '../shared/shared.module';
import { SupportService } from './support.service';
import { SupportRequestFormComponent } from './support-request-form/support-request-form.component';
import { IncidentRequestFormComponent } from './incident-request-form/incident-request-form.component';

@NgModule({
  imports: [
    CommonModule,
    SupportRoutingModule,
    SharedModule
  ],
  declarations: [
    SupportComponent,
    SupportLandingPageComponent,
    SupportRequestFormComponent,
    IncidentRequestFormComponent
  ],
  providers: [
    SupportService
  ]
})
export class SupportModule { }
