import { Component, OnInit, Input } from '@angular/core';
import { ProfessionalServicesService } from '../../professional-services.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectsDataSource } from '../../models/professional-services-projects.datasource';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { AdminService } from '../../../admin/admin.service';

@Component({
  selector: 'app-service-estimate-components-approved',
  templateUrl: './service-estimate-components-approved.component.html',
  styleUrls: ['./service-estimate-components-approved.component.scss']
})
export class ServiceEstimateComponentsApprovedComponent implements OnInit {

  @Input() serviceEstimates: Array<any>;
  displayedColumns: Array<string>;
  dataSource: ProjectsDataSource;
  seId: string;
  isDateAscending: boolean;
  isNameAscending: boolean;
  pageSize: number;
  pagination: any;
  employees: any[];
  selectedEmployeeId: string | null;


  constructor(private projectService: ProfessionalServicesService, private route: ActivatedRoute, private dialog: MatDialog, private adminService: AdminService) { }

  ngOnInit() {

    this.isDateAscending = true;
    this.isNameAscending = true;
    this.pageSize = 100;
    this.displayedColumns = [
      'projectName',
      'createdDate',
      'createdBy',
      'actions'
    ];
    this.getEmployees();
    this.getTableData();
  }
  clearSelectedEmployee() {
    this.selectedEmployeeId = null;
    this.filterByEmployee();
  }
  getTableData() {
    this.projectService.getSEComponentsApproved()
      .subscribe(res => {
        this.serviceEstimates = res;
        this.setTableData();

      });

  }
  filterByEmployee() {
    if (this.selectedEmployeeId) {
      const filteredData = this.serviceEstimates.filter(temp => {
        if (temp.createdByEmployeeId) {
          return temp.createdByEmployeeId == this.selectedEmployeeId
        } else {
          return false;
        }
      });
      this.setTableData(filteredData);
    } else {
      this.setTableData(this.serviceEstimates);
    }
  }

  getEmployees() {
    this.adminService.getAllEmployees()
      .subscribe(httpResult => {
        this.employees = httpResult;
      });
  }
  setTableData(data?: Array<any>) {
    this.dataSource = new ProjectsDataSource();
    let pageAmount = 100;
    if (!data) {
      if (this.pagination) {
        pageAmount = this.pagination.pageSize;
      }
      const initData = this.serviceEstimates.slice(0, pageAmount);
      this.dataSource.setData(initData);
    } else {
      this.dataSource.setData(data);
    }
  }
  pageChangedEventUsages(event: any) {
    this.pagination = event;
    this.pageSize = event.pageSize;
    console.log(event);
    const pagedData = this.serviceEstimates.slice(event.pageIndex * event.pageSize, (event.pageIndex * event.pageSize) + event.pageSize);
    this.setTableData(pagedData);

  }
  sortByProjectName() {
    this.isNameAscending = !this.isNameAscending;
    if (this.isNameAscending) {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const nameOne = a.componentName;
        const nameTwo = b.componentName;
        if (nameOne < nameTwo) { return -1; }
        if (nameOne > nameTwo) { return 1; }
        return 1;
      }).slice(0, this.pageSize));
    } else {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const nameOne = a.componentName;
        const nameTwo = b.componentName;
        if (nameOne < nameTwo) { return 1; }
        if (nameOne > nameTwo) { return -1; }
        return 1;
      }).slice(0, this.pageSize));
    }
  }
  sortByDate() {
    this.isDateAscending = !this.isDateAscending;
    if (this.isDateAscending) {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const dateOne: any = new Date(a.createdDate);
        const dateTwo: any = new Date(b.createdDate);

        return dateTwo - dateOne;
      }).slice(0, this.pageSize));
    } else {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const dateOne: any = new Date(a.createdDate);
        const dateTwo: any = new Date(b.createdDate);

        return dateOne - dateTwo;
      }).slice(0, this.pageSize));
    }
  }

}
