import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-migration-discovery-perfdata',
  templateUrl: './cloud-migration-discovery-perfdata.component.html',
  styleUrls: ['./cloud-migration-discovery-perfdata.component.scss']
})
export class CloudMigrationDiscoveryPerfdataComponent implements OnInit {
  @Input() perfdata: any;
  
  tableDataSource: any[];
  displayedColumns:string[]= ['senderIpV4','targetHostIpV4','senderPort','targetHostPort','targetHostUrl', ];
  

  constructor() { }
  ngOnInit(): void {
    this.setData();
   
  }
  setData() {
    this.tableDataSource = this.perfdata;
  }

}
