<mat-card>
  <mat-card-content>
    <form [formGroup]="marginOptionsFormGroup" #form="ngForm" (ngSubmit)="submitForm()" *ngIf="marginOptionsFormGroup">
      <mat-card-content>
        <div class="row">
          <!-- Service Estimate Expiration Field -->
          <div class="col-md-3">
            <h3 style="color: grey; font-weight: normal; font-family: 'Roboto', 'Helvetica Neue', sans-serif; font-size: small;">Service Estimate Expiration Date</h3>
            <mat-form-field>
              <mat-label>Enter a value</mat-label>
              <input matInput type="number" placeholder="Enter whole number" formControlName="serviceEstimateExpiration" id="serviceEstimateExpiration" />
              <mat-error *ngIf="marginOptionsFormGroup.controls['serviceEstimateExpiration'].hasError('required')">This field is required</mat-error>
              <mat-error *ngIf="marginOptionsFormGroup.controls['serviceEstimateExpiration'].hasError('pattern')">Please enter a valid whole number</mat-error>
            </mat-form-field>
          </div>
          <!-- Radio Button Control and Associated Fields -->
          <div class="col-md-2">
            <h3 style="color: grey; font-weight: normal; font-family: 'Roboto', 'Helvetica Neue', sans-serif; font-size: small;">Margin Adjustments</h3>
            <div class="row">
              <mat-radio-group formControlName="marginOptionType" class="col-md-9 radio-group" name="marginOptionType" id="marginOptionType" >

                <mat-radio-button class="radio-button align-bottom" value="uplift">Uplift %</mat-radio-button>
                <mat-radio-button class="radio-button" value="grossMargin">Gross Margin %</mat-radio-button>
              </mat-radio-group>
              <div class="col-md-2">
                <mat-form-field [class.isVisible]="marginOptionsFormGroup.controls['marginOptionType'].value!='uplift'">
                  <input matInput currencyMask placeholder="Enter a value" formControlName="upliftPercentage" id="upliftPercentage" (input)="onInputChange('upliftPercentage')" />
                  <span matSuffix>%</span>
                  <mat-error *ngIf="marginOptionsFormGroup.controls['upliftPercentage'].hasError('required')">Please enter a valid uplift%</mat-error>
                </mat-form-field>
                <mat-form-field [class.isVisible]="marginOptionsFormGroup.controls['marginOptionType'].value!='grossMargin'">
                  <input matInput currencyMask  placeholder="Enter a value" formControlName="grossMarginPercentage" id="grossMarginPercentage" (input)="onInputChange('grossMarginPercentage')">
                  <span matSuffix>%</span>
                  <mat-error *ngIf="marginOptionsFormGroup.controls['grossMarginPercentage'].hasError('required')">Please enter a valid gross margin%</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button type="submit" [disabled]="!marginOptionsFormGroup.valid" id="btnSubmit">Save</button>
        <!-- <button mat-raised-button type="button" (click)="goBack()" id="btnCancel">Cancel</button> -->
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>
