<mat-card *ngIf="projectControlRate">
  <mat-card-content>
    <div class="row">
      <div class="col-md-12">
        <p>Name: {{projectControlRate.projectControlRateName}}</p>
      </div>
      <div class="col-md-12">
        <p>Description: {{projectControlRate.projectControlRateDescription}}</p>
      </div>
      <div class="col-md-12">
        <p>Minimum Hours: {{projectControlRate.projectControlRateMinHours}}</p>
      </div>
      <div class="col-md-12">
        <p>Maximum Hours: {{projectControlRate.projectControlRateMaxHours}}</p>
      </div>
      <div class="col-md-12">
        <p>Percentage: {{projectControlRate.projectControlRatePercentage}}</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>