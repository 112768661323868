import { Component, OnInit } from '@angular/core';
import { FleetMangementService } from '../../../fleet-management/fleet-mangement.service';
import { VehicleManagerService } from '../vehicle-manager.service';
import { VehicleManager } from '../../models/vehicle-manager.model';
import { ActivatedRoute } from '@angular/router';
import { Employee } from '../../models/employee';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-vehicle-manager-detail',
  templateUrl: './vehicle-manager-detail.component.html',
  styleUrls: ['./vehicle-manager-detail.component.scss']
})
export class VehicleManagerDetailComponent implements OnInit {
  vm: VehicleManager;
  selectedDrivers: any[];
  employee: Employee;
  constructor(private vmService: VehicleManagerService, private route: ActivatedRoute, private adminService: AdminService) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        if (params['id']) {
          this.selectedDrivers = [];
          this.vmService.getVehicleManager(params['id'])
            .subscribe(res => {
              this.vm = res;

              this.adminService.getEmployee(this.vm.empId)
                .subscribe(employeeResult => {
                  this.employee = employeeResult;

                });

            });
        }
      });

  }
  addDriver() {
    this.vm.drivers.push();
  }

}
