import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from '../models/id-token-claims';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private _idToken: IdTokenClaims | undefined;
  idToken() {
    if (this._idToken) {

    } else {
      let idTokenClaims = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
      this._idToken = idTokenClaims;
    }
    return this._idToken;
  }
  constructor(private authService: MsalService) { }

  checkRoleExists(role: string, user: AccountInfo | null, isInclude: boolean) {
    role = role.toLocaleLowerCase();
    let roleExist = false;
    if (user?.idTokenClaims) {
      var idToken = <IdTokenClaims | undefined>user.idTokenClaims;
      if (typeof idToken?.roles === 'string') {
        let _role: string = idToken.roles;
        _role = _role.toLocaleLowerCase();
        return _role === role || isInclude && _role.includes(role);
      } else {
        idToken?.roles.forEach(name => {
          name = name.toLowerCase();
          if (name === role || isInclude && name.includes(role)) {
            roleExist = true;
            return;
          }
        });
        return roleExist;
      }
    }
    return roleExist;
  }
}
