import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VehicleInsuranceDataSource } from '../../../models/vehicle-insurance-datasource';
import { FleetMangementService } from '../../../fleet-mangement.service';
import { ConfirmDeleteDialogComponent } from '../../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-vehicle-insurance-list',
  templateUrl: './vehicle-insurance-list.component.html',
  styleUrls: ['./vehicle-insurance-list.component.scss']
})
export class VehicleInsuranceListComponent implements OnInit {
  insurances: VehicleInsuranceDataSource;
  displayedColumns = [
    'vehicleInsurancePolicyNumber',
    'vehicleInsuranceEndDate',
    'vehicleInsuranceState',
    'vehicleInsuranceDaysRemaining'
  ];

  constructor(private route: ActivatedRoute, private service: FleetMangementService, private dialog: MatDialog) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        const id = params['id'];
        if (id) {

        } else {
          this.getInsurancePolcies();
        }
      });
  }
  deleteInsurance(vehicleId: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          this.service.deleteVehicleInsuranceRecord(vehicleId)
            .subscribe(httpDeleteResult => {
              this.getInsurancePolcies();

            });
        }
      });
  }
  getInsurancePolcies() {
    this.service.getVehicleInsuranceRecords()
      .subscribe(res => {
        this.insurances = new VehicleInsuranceDataSource();
        this.insurances.setData(res);
      });
  }

}
