import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ReleaseNotesService } from '../release-notes.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { AzureDevOpsIteration } from '../../../shared/models/iteration';
import { ReleaseNote } from '../models/releaseNotes';

@Component({
  selector: 'app-release-notes-create',
  templateUrl: './release-notes-create.component.html',
  styleUrls: ['./release-notes-create.component.scss']
})
export class ReleaseNotesCreateComponent implements OnInit {

  releaseNotesFormGroup: UntypedFormGroup;
  devopsIterations: Array<AzureDevOpsIteration>;
  currentIteration: Array<AzureDevOpsIteration>;
  displayIterationError: boolean;
  releaseNotesId: string;
  releaseNote: ReleaseNote;
  isUniqueRecord: boolean;
  iterationItems: UntypedFormArray;
  errorText: string;
  isSaveDisabled: boolean;
  savedIterationItems: any;
  currentRelease: any;

  constructor(private fb: UntypedFormBuilder,
    private releaseNotesService: ReleaseNotesService,
    private location: Location,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isUniqueRecord = true;
    this.iterationItems = this.fb.array([]);
    this.savedIterationItems = [];
    this.route.params
      .subscribe(params => {
        if (params['id']) {
          this.releaseNotesId = params['id'];
          let releaseNote = this.releaseNotesService.getReleaseNote(this.releaseNotesId);
          let iterations = this.releaseNotesService.getIterations();
          forkJoin([releaseNote, iterations])
            .subscribe(results => {
              this.devopsIterations = results[1].filter(x => x.attributes.timeFrame.toLowerCase() != 'future').sort((a, b) => {
                return this.compare(a.attributes.startDate, b.attributes.startDate, false)
              });
              this.releaseNote = results[0];
              this.currentIteration = this.devopsIterations.filter(itr => this.releaseNote?.iterations?.some((x: any) => x.azureIterationId == itr.id));

              this.releaseNote?.iterations?.forEach((iteration: any, index: number) => {
                this.addIterationItem(iteration);
              });
              Object.assign(this.savedIterationItems, this.iterationItems.value);
              
              this.releaseNotesFormGroup = this.fb.group({
                'iterations': [this.iterationItems.value.map((x: any) => x.azureIterationId), Validators.required],
                'releaseDate': [this.releaseNote.releaseDate, (Validators.required, this.dateValidator.bind(this))],
                'version': [{ value: this.releaseNote.version, disabled: true }],
                'notes': [this.releaseNote.notes],
                'id': [this.releaseNote.id],
                'createdDate': [this.releaseNote.createdDate],
                'versionType': [{ value: null, disabled: true }],
                'isMaintenanceNotified': [this.releaseNote.isMaintenanceNotified],
                'isReleaseNoteNotified': [this.releaseNote.isReleaseNoteNotified]
              });
              this.monitorChanges();
            });
          
        } else {
          
          let iterations = this.releaseNotesService.getIterations();
          let currentRelease = this.releaseNotesService.getCurrentReleaseInfo();
          forkJoin([currentRelease, iterations])
            .subscribe(results => {
              this.devopsIterations = results[1].filter(x => x.attributes.timeFrame.toLowerCase() != 'future').sort((a, b) => {
                return this.compare(a.attributes.startDate, b.attributes.startDate, false)
              });
              const note: ReleaseNote = {
                  version: '0.0.0',
                  id: '',
                  iterations: [],
                  releaseDate: '',
                  notes: '',
                  createdDate: '',
                  releaseNotesAcknowledgements: null,
                  isMaintenanceNotified: false,
                  isReleaseNoteNotified: false
              };
              this.currentRelease = results[0] ? results[0] : note;
              this.releaseNotesFormGroup = this.fb.group({
                'iterations': [this.iterationItems.value, Validators.required],
                'releaseDate': ['', (Validators.required, this.dateValidator.bind(this))],
                'version': [{value: this.currentRelease.version, disabled: true }],
                'notes': [''],
                'versionType': [null, Validators.required],
                'isMaintenanceNotified': [false],
                'isReleaseNoteNotified': [false]
              });
              this.monitorChanges();
            });
        }
      });

    
  }

  versionTypeChanged() {
    let splitVersion: any[] = this.currentRelease.version.split(".");
    let versionType = this.releaseNotesFormGroup.controls['versionType'].value;
    if (+versionType == 0) {
      let majorVersion = +splitVersion[0] + 1;
      splitVersion[0] = majorVersion;
      splitVersion[1] = splitVersion[2] = 0;
    } else if (+versionType == 1) {
      let minorVersion = +splitVersion[1] + 1;
      splitVersion[1] = minorVersion;
      splitVersion[2] = 0;
    } else if (+versionType == 2) {
      let patchVersion = +splitVersion[2] + 1;
      splitVersion[2] = patchVersion;
    }
    this.releaseNotesFormGroup.controls['version'].setValue(splitVersion.join('.'));
  }

  compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

  addIterationItem(iterationItem?: any) {
    let item: UntypedFormGroup;
    let itrItem;
    if (typeof (iterationItem) == 'string') {
      itrItem = this.iterationItems.value.find((x: any) => x.azureIterationId == iterationItem);
    if (this.releaseNotesId) {
      if (itrItem)
        item = this.fb.group({
          'id': [itrItem.id],
          'releaseNoteId': [itrItem.releaseNoteId],
          'azureIterationId': [itrItem.azureIterationId]
        });
      else
        item = this.fb.group({
          'azureIterationId': [iterationItem],
          'releaseNoteId': [this.releaseNotesId]
        });
      } else {
        item = this.fb.group({
          'azureIterationId': [iterationItem]
        });
      }
    }
    else if (iterationItem) {
      item = this.fb.group({
        'id': [iterationItem.id],
        'releaseNoteId': [iterationItem.releaseNoteId],
        'azureIterationId': [iterationItem.azureIterationId]
      });
    }
    else {
      item = this.fb.group({
        'azureIterationId': ['']
      });
    }
    itrItem ? "" : this.iterationItems.push(item);
  }

  submitForm() {
    this.isSaveDisabled = true;
    if (this.releaseNotesFormGroup.valid) {
      let releaseNotes: any = Object.assign({}, this.releaseNotesFormGroup.getRawValue());
      releaseNotes.iterations.filter((x:any) => !x.azureIterationId).forEach((iteration: any, index: number) => {
        this.addIterationItem(iteration);
      });
      const removableIterations = this.savedIterationItems.filter((oldItr: any) => !this.currentIteration.some(newItr => oldItr.azureIterationId === newItr.id));
      removableIterations.forEach((itr: any) => {
        let itemIndex = this.iterationItems.value.findIndex((x: any) => x.id == itr.id);
        let item = this.iterationItems.value[itemIndex];
        if (item) {
          this.iterationItems.removeAt(itemIndex);
          this.releaseNotesService.removeReleaseNoteIteration(item.id).subscribe(res => {
            
          });
        }
      })
        
      releaseNotes.iterations = this.iterationItems.value;
      if (this.releaseNotesId) {
        this.releaseNotesService.updateReleaseNotes(releaseNotes)
          .subscribe((res: any) => {
            this.location.back();

          },
            (error) => {
              if (error.status == 409) {
                this.isUniqueRecord = false;
                this.isSaveDisabled = false;
                this.errorText = error.error;
              }
            });
      } else {
        this.releaseNotesService.createReleaseNotes(releaseNotes)
          .subscribe((res: any) => {
            this.location.back();

          },
            (error) => {
              if (error.status == 409) {
                this.isSaveDisabled = false;
                this.isUniqueRecord = false;
                this.errorText = error.error;
              }
            });
      }
      }
  }
  monitorChanges() {
    this.releaseNotesFormGroup.controls['iterations'].valueChanges.subscribe((val: any) => {
      const iterations = this.releaseNotesFormGroup.controls['iterations'].value;
      this.currentIteration = this.devopsIterations.filter(itr => iterations.some((x: any) => x == itr.id));
      this.releaseNotesFormGroup.controls['releaseDate'].updateValueAndValidity();
    })
  }

  isNotCurrentIteration(event: any) {
    this.displayIterationError =  this.currentIteration[0]?.id != event.value;
  }
  goBack() {
    this.location.back();
  }

  dateValidator(control: AbstractControl) {
    if (control.value) {
      const date = moment(control.value);
      let maxDate = this.currentIteration[0]?.attributes?.finishDate;
      this.currentIteration.forEach((iteration: AzureDevOpsIteration) => {
        if (iteration.attributes.finishDate > maxDate)
          maxDate = iteration.attributes.finishDate;
      });
      if (date.format('YYYY-MM-DD') < moment(maxDate).format('YYYY-MM-DD') || date.format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
        return { 'invalidDate': true };
      }
      return null;
    }
    return { 'required': true };
  }

}

