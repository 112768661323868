import { Component, Input, OnInit } from '@angular/core';
import { AssessmentDataSource } from '../../models/assessment-datasource';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cloud-migration-assessments-table',
  templateUrl: './cloud-migration-assessments-table.component.html',
  styleUrls: ['./cloud-migration-assessments-table.component.scss']
})
export class CloudMigrationAssessmentsTableComponent implements OnInit {
  @Input() assessments: any;
  assessmentsDataSource: AssessmentDataSource;
  loading: boolean;
   
  projectId: string = "";
  //displayedColumns = ['title', 'date', 'source', 'target', 'cost', 'actions'];
  displayedColumns = ['title', 'date', 'cost', 'actions'];
  rowTitle =  ['Title', 'Date', 'Source', 'Target','Cost/Month', 'actions'];
  
  constructor(private router: Router) { }

  ngOnInit(): void {
    console.log('this.assessments',this.assessments);
    this.assessmentsDataSource = this.assessments?.assessments??null;
    this.projectId = this.assessments?.projectId;
  }

  navigateMenu(rowId:any){
    this.router.navigateByUrl(`cmt/projects/${this.projectId}/view/assessments/${rowId}`);
  }
}
