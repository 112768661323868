import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { FleetManagementComponent } from './fleet-management.component';
import { VehicleRegistrationCreateComponent } from './vehicles/vehicle-registration/vehicle-registration-create/vehicle-registration-create.component';
import { VehicleRegistrationComponent } from './vehicles/vehicle-registration/vehicle-registration.component';
import { RegistrationLandingPageComponent } from './vehicles/vehicle-registration/registration-landing-page/registration-landing-page.component';
import { VehicleModelsComponent } from './vehicles/vehicle-models/vehicle-models.component';
import { VehicleModelsCreateComponent } from './vehicles/vehicle-models/vehicle-models-create/vehicle-models-create.component';
import { VehicleModel } from './models/fleet-management-models';
import { VehicleModelsLandingPageComponent } from './vehicles/vehicle-models/vehicle-models-landing-page/vehicle-models-landing-page.component';
import { FleetManagementLandingPageComponent } from './fleet-management-landing-page/fleet-management-landing-page.component';
import { VehicleModelsListComponent } from './vehicles/vehicle-models/vehicle-models-list/vehicle-models-list.component';
import { VehicleCreateComponent } from './vehicles/vehicle-create/vehicle-create.component';
import { VehiclesListComponent } from './vehicles/vehicles-list/vehicles-list.component';
import { AddMileageComponent } from './add-mileage/add-mileage.component';
import { UsageRecordUpdateComponent } from './usage-record-update/usage-record-update.component';
import { VehicleModelDetailComponent } from './vehicles/vehicle-models/vehicle-model-detail/vehicle-model-detail.component';
import { VehicleInsuranceCreateComponent } from './vehicles/vehicle-insurance/vehicle-insurance-create/vehicle-insurance-create.component';
import { VehicleInsuranceComponent } from './vehicles/vehicle-insurance/vehicle-insurance.component';

import { VehicleInsuranceListComponent } from './vehicles/vehicle-insurance/vehicle-insurance-list/vehicle-insurance-list.component';
import { VehicleDetailComponent } from './vehicles/vehicle-detail/vehicle-detail.component';
import { VehicleModelsUpdateComponent } from './vehicles/vehicle-models/vehicle-models-update/vehicle-models-update.component';
import { VehicleExpensesCreateComponent } from './expenses/vehicle-expenses-create/vehicle-expenses-create.component';
import { VehicleMaintenanceScheduleCreateComponent } from './vehicle-maintenance-schedule/vehicle-maintenance-schedule-create/vehicle-maintenance-schedule-create.component';
import { AddFuelComponent } from './add-fuel/add-fuel.component';
import { VehicleMaintenanceScheduleListComponent } from './vehicle-maintenance-schedule/vehicle-maintenance-schedule-list/vehicle-maintenance-schedule-list.component';
import { VehicleInsuranceDetailComponent } from './vehicles/vehicle-insurance/vehicle-insurance-detail/vehicle-insurance-detail.component';
import { VehicleExpensesDetailComponent } from './expenses/vehicle-expenses-detail/vehicle-expenses-detail.component';
import { AddMileageAndExpenseComponent } from './add-mileage-and-expense/add-mileage-and-expense.component';
import { DriversComponent } from './drivers/drivers.component';
import { DriversListComponent } from './drivers/drivers-list/drivers-list.component';
import { VehicleDriverCreateComponent } from './drivers/vehicle-driver-create/vehicle-driver-create.component';
import { VehicleMileageUpdateComponent } from './vehicles/vehicle-mileage-update/vehicle-mileage-update.component';
import { VehicleReportsComponent } from './vehicle-reports/vehicle-reports.component';
import { RetiredComponent } from './retired/retired.component';
import { RetiredListComponent } from './retired/retired-list/retired-list.component';
import { VehicleMaintenanceScheduleComponent } from './vehicle-maintenance-schedule/vehicle-maintenance-schedule.component';
import { FleetManagementHomeComponent } from './fleet-management-home/fleet-management-home.component';

const vehicleEditRoute = {
  path: 'detail/:id', component: VehicleDetailComponent, data: {
    breadcrumb: 'Edit Vehicle', isRoutable: true
  }
};

const addMileageRoute = {
  path: 'addMileage/:id', component: AddMileageComponent, data: {
    breadcrumb: 'Add Mileage', isRoutable: true
  }
};

const addExpenseAndMileageRoute = {
  path: 'addExpenseAndMileage/:id', component: AddMileageAndExpenseComponent, data: {
    breadcrumb: 'Add Expense and Mileage', isRoutable: true
  }
};

const routes: Routes = [
  {
    path: 'fleetmanagement', component: FleetManagementComponent, data: {
      breadcrumb: "Fleet Management", isRoutable: true
    }, children:
      [
        { path: '', pathMatch: 'full', redirectTo: 'home' },
        {
          path: 'home', component: FleetManagementHomeComponent, data: {
            breadcrumb: null, isRoutable: true
          }, children: [
            {
              path: '', component: FleetManagementLandingPageComponent, data: {
                breadcrumb: null, isRoutable: false
              }
            },
            vehicleEditRoute,
            addMileageRoute,
            addExpenseAndMileageRoute
          ]
        },
        {
          path: 'vehicles', component: VehiclesComponent, data: {
            breadcrumb: "Vehicle Management", isRoutable: true
          }, children: [
            {
              path: '', component: VehiclesListComponent, data: {
                breadcrumb: null, isRoutable: false
              } },
            {
              path: 'create', component: VehicleCreateComponent, data: {
                breadcrumb: 'Create Vehicle', isRoutable: true
              }
            },
            addMileageRoute,
            {
              path: 'addGas/:id', component: AddFuelComponent },
            vehicleEditRoute,
            {
              path: 'addexpense/:id', component: VehicleExpensesCreateComponent },
            {
              path: 'expenseDetail/:id', component: VehicleExpensesDetailComponent }, 
            {
              path: 'mileage/update/:id', component: VehicleMileageUpdateComponent, data: {
                breadcrumb: 'Edit Mileage', isRoutable: true
              } }
          ]
        },
        {
          path: 'schedule', component: VehicleMaintenanceScheduleComponent, data: {
            breadcrumb: 'Maintenance Schedule', isRoutable: true
          }, children: [
            {
              path: '', component: VehicleMaintenanceScheduleListComponent, data: {
                breadcrumb: null, isRoutable: false
              } },
            {
              path: 'create', component: VehicleMaintenanceScheduleCreateComponent, data: {
                breadcrumb: 'Create Maintenance Schedule', isRoutable: true
              } },
            {
              path: 'update/:id', component: VehicleMaintenanceScheduleCreateComponent, data: {
                breadcrumb: 'Edit Maintenance Schedule', isRoutable: true
              }
            }
          ]
        },
        {
          path: 'insurance', component: VehicleInsuranceComponent, data: {
            breadcrumb: 'Insurance Policy Management', isRoutable: true
          }, children: [
            {
              path: '', component: VehicleInsuranceListComponent, data: {
                breadcrumb: null, isRoutable: false
              } },
            {
              path: 'create', component: VehicleInsuranceCreateComponent, data: {
                breadcrumb: 'Create Insurance Policy Management', isRoutable: true
              } },
            {
              path: 'details/:id', component: VehicleInsuranceDetailComponent, data: {
                breadcrumb: 'Edit Insurance Policy Management', isRoutable: true
              } },
            {
              path: 'edit/:id', component: VehicleInsuranceCreateComponent, data: {
                breadcrumb: 'Edit Insurance Policy Management', isRoutable: true
              } }
          ]
        },
        {
          path: 'usage', children: [
            { path: 'update/:id', component: UsageRecordUpdateComponent }
          ]
        },
        {
          path: 'vehicleregistration', component: VehicleRegistrationComponent, data: {
            breadcrumb: 'Vehicle Registration', isRoutable: true
          }, children: [
            {
              path: '', component: RegistrationLandingPageComponent, data: {
                breadcrumb: null, isRoutable: false
              } },
            {
              path: 'create', component: VehicleRegistrationCreateComponent, data: {
                breadcrumb: 'Create Vehicle Registration', isRoutable: true
              } }
          ]
        },
        {
          path: 'models', component: VehicleModelsComponent, data: {
            breadcrumb: 'Models', isRoutable: true
          }, children: [
            {
              path: '', component: VehicleModelsListComponent, data: {
                breadcrumb: null, isRoutable: false
              } },
            {
              path: 'create', component: VehicleModelsCreateComponent, data: {
                breadcrumb: 'Create Model', isRoutable: true
              } },
            {
              path: 'detail/:id', component: VehicleModelDetailComponent, data: {
                breadcrumb: 'Edit Model', isRoutable: true
              } },
            {
              path: 'update/:id', component: VehicleModelsUpdateComponent, data: {
                breadcrumb: 'Edit Model', isRoutable: true
              } }
          ]
        },
        {
          path: 'drivers', component: DriversComponent, data: {
            breadcrumb: 'Drivers', isRoutable: true
          }, children: [
            {
              path: '', component: DriversListComponent, data: {
                breadcrumb: null, isRoutable: false
              } },
            {
              path: 'create', component: VehicleDriverCreateComponent, data: {
                breadcrumb: 'Create Driver', isRoutable: true
              } }
          ]
        },
        {
          path: 'retired', component: RetiredComponent, data: {
            breadcrumb: 'Retired', isRoutable: true
          }, children: [
            {
              path: '', component: RetiredListComponent, data: {
                breadcrumb: null, isRoutable: false
              }
            },
            vehicleEditRoute
          ]
        }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FleetManagementRoutingModule { }
