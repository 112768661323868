import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cloud-migration-project-info',
  templateUrl: './cloud-migration-project-info.component.html',
  styleUrls: ['./cloud-migration-project-info.component.scss']
})
export class CloudMigrationProjectInfoComponent implements OnInit {
@Input() project: any;

  constructor() { }

  ngOnInit(): void {
  }

}
