import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { DocumentCreatorService } from '../document-creator.service';


@Component({
  selector: 'app-document-creator-details',
  templateUrl: './document-creator-details.component.html',
  styleUrls: ['./document-creator-details.component.scss']
})
export class DocumentCreatorDetailsComponent implements OnInit {
  document: SafeHtml;
  id: string;

  constructor(
    //private santizier: DomSanitizer,
    private service: DocumentCreatorService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        this.id = params['id'];
        this.service.getDocument(this.id)
          .subscribe(res => {
            const data = res;
            const content = data.content;
            //this.document = this.santizier.bypassSecurityTrustHtml(content);
          });

      });

  }
  deleteDocument() {
    this.service.deleteDocument(this.id)
      .subscribe(res => {
          this.router.navigate(['documentcreator']);

      });
  }

}
