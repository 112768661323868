import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceRoleService {

  constructor(private http: HttpClient) { }
  getAllServiceRoles() {
    return this.http.get<any[]>(`/api/solutions/AllServiceRoles`);
  }

  getAllServiceRolesBySeId(id: string) {
    return this.http.get<any[]>(`/api/solutions/AllServiceRolesBySeId/${id}`);
  }
  getServiceRoles() {
    return this.http.get<any[]>(`/api/solutions/ServiceRoles`);
  }
  getRetiredServiceRoles() {
    return this.http.get(`/api/solutions/ServiceRoles/Retired`);
  }
  getServiceRole(id: string) {
    return this.http.get<any>(`/api/solutions/ServiceRoles/${id}`);
  }
  updateServiceRole(serviceRole: any) {
    const body = JSON.stringify(serviceRole);
    return this.http.put(`/api/solutions/ServiceRoles/${serviceRole.serviceRoleId}`, body);
  }
  createServiceRole(serviceRole: any) {
    const body = JSON.stringify(serviceRole);
    return this.http.post<any>(`/api/solutions/ServiceRoles`, body);
  }
  deleteServiceRole(serviceRoleId: any) {
    return this.http.delete(`/api/solutions/ServiceRoles/${serviceRoleId}`);
  }
  removeServiceRoleApprover(serviceRoleId: any, serviceRoleEmployeeId: number) {
    return this.http.delete(`/api/solutions/serviceroles/${serviceRoleId}/serviceroleemployee/${serviceRoleEmployeeId}`);
  }
  removeServiceRoleEstimateType(id: string) {
    return this.http.delete(`/api/solutions/serviceroles/ServiceEstimateTypes/${id}`);
  }
  getAppDirectors() {
    return this.http.get(`/api/solutions/AppDirectors`);
  }

}
