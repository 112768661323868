<button
  id="btn-close-icon"
  mat-icon-button
  class="close-button"
  (click)="close()"
>
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div>
    <h3
      mat-dialog-title
      cdkDrag
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragHandle
    >
      {{ formTitle }}
    </h3>
  </div>
  <mat-card *ngIf="discoveryForm" style="height: 85%;">
  <mat-label class="card-label-text">Create Discovery to Start Cataloguing Workloads for Selected Environment</mat-label>
    <div>
      <mat-dialog-content [formGroup]="discoveryForm">
        <div class="row">
          <div class="col-6">
            <mat-form-field *ngIf="environmentListOptions" formControlName="environmentId">
              <mat-label>Select Environment</mat-label>
              <mat-select  formControlName="environmentId" disableOptionCentering>
                <mat-option *ngFor="let item of environmentListOptions" [value]="item?.value">
                    {{item?.text}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="discoveryForm.controls['environmentId'].hasError('required')">
                Environment required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-dialog-content>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-card-actions align="right">
          <button id="btn-save-discovery" type="submit" mat-raised-button class="mat-primary"
                  [disabled]="!discoveryForm.valid" (click)="save()">
            Create
          </button>
          <button class="mat-raised-button" (click)="close()">Close</button>
        </mat-card-actions>
      </div>
    </div>
    <b></b>
    <span style="font-weight: bold;">{{message}}</span>
  </mat-card>
