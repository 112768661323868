import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Video } from './video/video.model';

@Injectable({
  providedIn: 'root'
})
export class VideosService {

  constructor(private http: HttpClient) { }
  
  getVideos() {
    return this.http.get<Video[]>(`/api/Video`);
  }
}
