import { NgModule } from '@angular/core';

import { ProfessionalServicesRoutingModule } from './professional-services-routing.module';
import { ProfessionalServicesComponent } from './professional-services.component';
import { ProfessionalServicesLandingPageComponent } from './professional-services-landing-page/professional-services-landing-page.component';
import { ProfessionalServicesService } from './professional-services.service';
import { ProfessionalServicesProjectComponent } from './professional-services-project/professional-services-project.component';
import { ProfessionalServicesProjectListComponent } from './professional-services-project/professional-services-project-list/professional-services-project-list.component';
import { ProfessionalServicesEstimateListComponent } from './service-estimates/professional-services-estimate-list/professional-services-estimate-list.component';
import { ProfessionalServicesEstimateCreateComponent } from './service-estimate-components/professional-services-estimate-create/professional-services-estimate-create.component';
import { SharedModule } from '../shared/shared.module';
import { ProfessionalServicesEstimateDetailItemComponent } from './service-estimate-components/professional-services-estimate-detail-item/professional-services-estimate-detail-item.component';
import { ServiceEstimatiorItemFilterPipe } from '../shared/pipes/service-estimator/service-estimatior-item-filter.pipe';
import { ParentEstimateCreateComponent } from './service-estimates/parent-estimate-create/parent-estimate-create.component';
import { BusinessPartnerSearchPopupComponent } from '../business-partners/business-partner-search-popup/business-partner-search-popup.component';
import { ProfessionalServicesProjectLpComponent } from './professional-services-project/professional-services-project-lp/professional-services-project-lp.component';
import { ProfessionalServicesProjectCreateComponent } from './professional-services-project/professional-services-project-create/professional-services-project-create.component';
import { ProfessionalServicesProjectDetailComponent } from './professional-services-project/professional-services-project-detail/professional-services-project-detail.component';
import { ParentEstmiateDetailComponent } from './service-estimates/parent-estmiate-detail/parent-estmiate-detail.component';
import { ServiceEstimateComponentListComponent } from './service-estimate-components/service-estimate-component-list/service-estimate-component-list.component';
import { MaterialItemService } from './material-item.service';
import { ResourceItemService } from './resource-item.service';
import { ChangeOrderComponent } from './change-order/change-order.component';
import { ChangeOrderCreateComponent } from './change-order/change-order-create/change-order-create.component';
import { ChangeOrderListComponent } from './change-order/change-order-list/change-order-list.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplatesLandingPageComponent } from './templates/templates-landing-page/templates-landing-page.component';
import { TemplatesListComponent } from './templates/templates-list/templates-list.component';
import { TemplatesCreateComponent } from './templates/templates-create/templates-create.component';
import { TemplatesDetailComponent } from './templates/templates-detail/templates-detail.component';
import { TemplatesPopupComponent } from './templates/templates-popup/templates-popup.component';
import { ConfirmDeleteDialogComponent } from '../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { RejectionsPopupComponent } from './service-estimates/rejections-popup/rejections-popup.component';
import { ApprovalComponentComponent } from './service-estimates/approval-component/approval-component.component';
import { ProfessionalServicesEstimateSummaryComponent } from './service-estimates/professional-services-estimate-summary/professional-services-estimate-summary.component';
import { ServiceEstimatorService } from './_services/service-estimator.service';
import { ServiceEstimateComponentsPendingApprovalComponent } from './service-estimate-components/service-estimate-components-pending-approval/service-estimate-components-pending-approval.component';
import { ServiceEstimateComponentsApprovedComponent } from './service-estimate-components/service-estimate-components-approved/service-estimate-components-approved.component';
import { AdminService } from '../admin/admin.service';
import { ApprovedServiceEstimatesReportComponent } from './reports/approved-service-estimates-report/approved-service-estimates-report.component';
import { ReportsComponent } from './reports/reports.component';
import { ServiceEstimateComponentsPendingPmApprovalComponent } from './service-estimate-components/service-estimate-components-pending-pm-approval/service-estimate-components-pending-pm-approval.component';
import { ServiceEstimatesPendingThresholdApprovalComponent } from './service-estimates/service-estimates-pending-threshold-approval/service-estimates-pending-threshold-approval.component';
import { ContractSignedDateDialogComponent } from '../shared/components/contract-signed-date-dialog/contract-signed-date-dialog.component';
import { ClientsListUrlFilteringComponent } from '../clients/clients-list-url-filtering/clients-list-url-filtering.component';
import { PostSalesTreeComponent } from './professional-services-project/professional-services-project-create/post-sales-tree/post-sales-tree.component';
import { AssignEmployeePopupComponent } from '../shared/components/assign-employee-popup/assign-employee-popup.component';
import { DocumentViewerPopupComponent } from '../shared/components/document-viewer-popup/document-viewer-popup.component';
import { ScoreCardPopupComponent } from '../shared/components/score-card-popup/score-card-popup.component';
import { ProjectDocumentPopupComponent } from '../shared/components/project-document-popup/project-document-popup.component';
import { ProfessionalServiceEstimateDetailedQuoteComponent } from './service-estimates/professional-service-estimate-detailed-quote/professional-service-estimate-detailed-quote.component';
import { ProjectCreateFormComponent } from './professional-services-project/professional-services-project-create/project-create-form/project-create-form.component';
import { MilestoneSummaryPopupComponent } from './service-estimates/milestone-summary-popup/milestone-summary-popup.component';
import { ProfessionalServiceEstimateComponentSkuItemComponent } from './service-estimate-components/professional-service-estimate-component-sku-item/professional-service-estimate-component-sku-item.component';
import { ServiceEstimateComponentsRateApprovalComponent } from './service-estimate-components/service-estimate-components-rate-approval/service-estimate-components-rate-approval.component';

@NgModule({
    imports: [
        SharedModule,
        ProfessionalServicesRoutingModule
    ],
    declarations: [
        ProfessionalServicesComponent,
        ProfessionalServicesLandingPageComponent,
        ProfessionalServicesProjectComponent,
        ProfessionalServicesProjectListComponent,
        ProfessionalServicesEstimateListComponent,
        ProfessionalServicesEstimateCreateComponent,
        ProfessionalServicesEstimateDetailItemComponent,
        ParentEstimateCreateComponent,
        ProfessionalServicesProjectLpComponent,
        ProfessionalServicesProjectCreateComponent,
        ProfessionalServicesProjectDetailComponent,
        ParentEstmiateDetailComponent,
        ServiceEstimateComponentListComponent,
        ChangeOrderComponent,
        ChangeOrderCreateComponent,
        ChangeOrderListComponent,
        TemplatesComponent,
        TemplatesLandingPageComponent,
        TemplatesListComponent,
        TemplatesCreateComponent,
        TemplatesDetailComponent,
        TemplatesPopupComponent,
        RejectionsPopupComponent,
        ApprovalComponentComponent,
        ProfessionalServicesEstimateSummaryComponent,
        ServiceEstimateComponentsPendingApprovalComponent,
        ServiceEstimateComponentsApprovedComponent,
        ApprovedServiceEstimatesReportComponent,
        ReportsComponent,
        ServiceEstimateComponentsPendingPmApprovalComponent,
        ServiceEstimatesPendingThresholdApprovalComponent,
        PostSalesTreeComponent,
        ProfessionalServiceEstimateDetailedQuoteComponent,
        ProjectCreateFormComponent,
        MilestoneSummaryPopupComponent,
        ProfessionalServiceEstimateComponentSkuItemComponent,
        ServiceEstimateComponentsRateApprovalComponent
    ],
    providers: [
        ProfessionalServicesService,
        MaterialItemService,
        ResourceItemService,
        ServiceEstimatorService,
        AdminService
    ]
})
export class ProfessionalServicesModule { }
