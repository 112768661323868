<mat-card *ngIf="tagFormGroup">
  <form [formGroup]="tagFormGroup" (ngSubmit)="submitForm()">
    <mat-card-content>
      <mat-form-field class="col-md-3">
        <input matInput type="text" formControlName="name" placeholder="Name" />
        <mat-error *ngIf="tagFormGroup.controls['name'].hasError('required')">A name is required</mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" [disabled]="!tagFormGroup.valid">Save</button>
      <button mat-raised-button type="button" (click)="goBack()">Cancel</button>
    </mat-card-actions>
  </form>
</mat-card>
