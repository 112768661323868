import { Component, OnInit, ViewChild } from '@angular/core';
import { TagsListDataSource } from '../models/tags-list-datasource';
import { TagsService } from '../tags.service';
import { Tag } from '../models/tag.model';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AdminService } from '../../admin/admin.service';
import { Employee } from '../../employee/employee-list/employee-list-datasource';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss']
})
export class TagsListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  tagsDataSource: TagsListDataSource;
  displayedColumns: Array<string>;
  filterForm: UntypedFormGroup;
  tags: Array<Tag>;
  employees: Employee[];

  constructor(private tagsService: TagsService, private adminService: AdminService, private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'name', 'createdBy', 'createdDate', 'actions'
    ];
    this.filterForm = this.fb.group({
      tagName: [''],
      createdBy: ['']
    });
    this.onFormValueChange();
    this.getTagsAndEmployees();
  }

  onFormValueChange() {
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.tagsDataSource = new TagsListDataSource(this.tagsDataSource.filter(res, this.tags));
        this.setTableData(this.tagsDataSource.data);
      });
  }

  getTagsAndEmployees() {
    let tags = this.tagsService.getTags();
    let employees = this.adminService.getAllEmployees();
    forkJoin([tags, employees])
      .subscribe(results => {
        this.tags = results[0];
        this.employees = results[1];
        this.setTableData(results[0]);
        this.updateForm();
      });
  }

  setTableData(data: Array<Tag>) {
    this.tagsDataSource = new TagsListDataSource(data);
    this.tagsDataSource.sort = this.sort;
    this.tagsDataSource.paginator = this.paginator;
  }

  resetFilters() {
    this.filterForm.controls['tagName'].setValue('');
    this.filterForm.controls['createdBy'].setValue('');
  }

  updateForm() {
    this.filterForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }
}
