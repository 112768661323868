import { Component, OnInit } from '@angular/core';
import { CloudMigrationToolAdminService } from '../../cloud-migration-tool-admin.service';

@Component({
  selector: 'app-cloud-provider-list',
  templateUrl: './cloud-provider-list.component.html',
  styleUrls: ['./cloud-provider-list.component.scss']
})
export class CloudProviderListComponent implements OnInit {
  cloudProviders: any;
  displayedColumns: string[];

  constructor(private service: CloudMigrationToolAdminService) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'name',
      'cloudProviderActions'
    ];
    this.getCloudProviders();
  }

  getCloudProviders() {
    this.service.getCloudProviders()
      .subscribe(res => {
        const data = res;
        this.setCloudProviderData(data);

      });
  }
  
  setCloudProviderData(data: Array<any>) {
    if (data) {
      this.cloudProviders = data;
    }
  }
}
