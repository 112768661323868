import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  setStorageData(key: string, formGroupValue: object) {
    sessionStorage.setItem(key, JSON.stringify(formGroupValue));
  }

  fetchStorageData(key: string) {
    return sessionStorage.getItem(key);
  }

  parseStorageData(storageData: string) {
    return JSON.parse(storageData);
  }
}
