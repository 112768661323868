<div class="mat-elevation-z8">
  <mat-card>
    <mat-card-title>
      Client CSV Upload
    </mat-card-title>
    <mat-card-content>
      <!-- <mat-form-field>
        <ngx-mat-file-input [(ngModel)]="model.file" placeholder="Clients File"></ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
      </mat-form-field> -->
      <br />
      <button mat-raised-button type="submit" (click)="saveFile()">Save</button>
    </mat-card-content>
  </mat-card>
</div>
