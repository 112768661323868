<div class="container-fluid">
  <mat-card>
    <mat-card-content [class.hide-content]="!(serviceRoles && employees)">
      <a id="serviceRoleCreate" mat-raised-button [routerLink]="['create']" color="primary"
         matTooltip="Create Service Role"
         [matTooltipPosition]="'right'">
        <mat-icon class="add-icon">add</mat-icon>
        Service Role
      </a>
      <br />
      <div class="col-md-12">
        <fieldset>
          <legend>
            Search Selection
          </legend>
          <form [formGroup]="filterForm">
            <mat-form-field>
              <input matInput type="text" placeholder="Filter by Name" id="roleName" formControlName="roleName" />
            </mat-form-field>
            <mat-form-field>
              <mat-select formControlName="projectControl" placeholder="Filter By Project Control" disableOptionCentering id='projectControl'>
                <mat-option [value]="''">
                  None
                </mat-option>
                <mat-option *ngFor="let projectControl of projectControls" [value]="projectControl.projectControlId">{{projectControl.projectControlName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Filter by Status" id="roleStatus" formControlName="roleStatus" disableOptionCentering>
                <mat-option [value]="0">Active</mat-option>
                <mat-option [value]="1">Inactive</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select formControlName="createdBy" id="createdBy" disableOptionCentering #employee placeholder="Filter By Created By">
                <mat-option [value]="''">
                  None
                </mat-option>
                <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">
                  {{emp.firstName + " " + emp.lastName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters" id="resetBtn">
              <mat-icon>clear</mat-icon>
            </button>
          </form>
        </fieldset>
      </div>
      <br />
      <div class="col-md-12">
        <mat-table matSort #table [dataSource]="serviceRoleDataSource" id="serviceRoles" class="apply-border">
          <ng-container matColumnDef="serviceRoleName">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="sortable"> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span> {{element.serviceRoleName}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="serviceRoleDescription">
            <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span> {{element.serviceRoleDescription}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="service">
            <mat-header-cell *matHeaderCellDef> Service </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngFor="let estType of element.serviceRoleEstimateTypes;first as isFirst">
                <span *ngIf="!isFirst">, </span><span> {{getserviceEstimateType(estType.serviceEstimateType)}}</span>
              </span>
            </mat-cell>           
          </ng-container>

          <ng-container matColumnDef="serviceRoleHourlyRate">
            <mat-header-cell *matHeaderCellDef>Hourly Rate </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span> {{element.serviceRoleHourlyRate| currency}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="projectControl">
            <mat-header-cell *matHeaderCellDef>Project Control </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="!element.projectControl.softDelete">
                <span> {{element.projectControl.projectControlName}} </span>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef> Created By</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: element.createdByEmployeeId}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="serviceRoleActions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element" id="kebabMenu">
              <ng-container>
                <button mat-icon-button [matMenuTriggerFor]="menu"
                        [attr.data-id]="element.serviceRoleId">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [routerLink]="['edit', element.serviceRoleId]">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item (click)="deleteServiceRole(element.serviceRoleId)">
                    <mat-icon>delete_outline</mat-icon>
                    <span>Retire</span>
                  </button>
                </mat-menu>
              </ng-container>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
          <mat-row *matRowDef="let row; columns: getDisplayedColumns();"></mat-row>
        </mat-table>
      </div>
      <div>
        <mat-paginator #paginator [length]="serviceRoleDataSource?.data.length" [pageIndex]="0" [pageSize]="50"
                       [pageSizeOptions]="[5, 25, 50, 100]">
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
