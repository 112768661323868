import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfessionalServicesProjectLpComponent } from '../professional-services/professional-services-project/professional-services-project-lp/professional-services-project-lp.component';
import { CloudMigrationToolLandingPageComponent } from './cloud-migration-tool-landing-page/cloud-migration-tool-landing-page.component';
import { CanDeactivateGuard } from '../core/models/UnsavedChanges/can-deactivate-form-unsaved.guard';
import { ProfessionalServicesProjectComponent } from '../professional-services/professional-services-project/professional-services-project.component';
import { CloudMigrationDiscoveryComponent } from './discoveries/cloud-migration-discovery/cloud-migration-discovery.component';
import { CloudMigrationDiscoveryDetailComponent } from './discoveries/cloud-migration-discovery-detail/cloud-migration-discovery-detail.component';
import { CloudMigrationDashboardComponent } from './cloud-migration-dashboard/cloud-migration-dashboard.component';
import { ProjectCreateFormComponent } from '../professional-services/professional-services-project/professional-services-project-create/project-create-form/project-create-form.component';
import { CloudMigrationDiscoveryDashboardComponent } from './discoveries/cloud-migration-discovery-dashboard/cloud-migration-discovery-dashboard.component';
import { CloudMigrationAssessmentsDashboardComponent } from './assessments/cloud-migration-assessments-dashboard/cloud-migration-assessments-dashboard.component';
import { CloudMigrationAssessmentsListComponent } from './assessments/cloud-migration-assessments-list/cloud-migration-assessments-list.component';
import { CloudMigrationAssessmentDetailComponent } from './assessments/cloud-migration-assessment-detail/cloud-migration-assessment-detail.component';
import { ParentEstmiateDetailComponent } from '../professional-services/service-estimates/parent-estmiate-detail/parent-estmiate-detail.component';
import { CloudMigrationServiceEstimateDashboardComponent } from './service-estimates/cloud-migration-service-estimate-dashboard/cloud-migration-service-estimate-dashboard.component';
import { ProfessionalServicesProjectDetailComponent } from '../professional-services/professional-services-project/professional-services-project-detail/professional-services-project-detail.component';
import { CloudMigrationEnvironmentComponent } from './environments/cloud-migration-environment/cloud-migration-environment.component';
import { CloudMigrationDiscoveryConfigurationPopupComponent } from './configurations/cloud-migration-discovery-configuration-popup/cloud-migration-discovery-configuration-popup.component';
import { ParentEstimateCreateComponent } from '../professional-services/service-estimates/parent-estimate-create/parent-estimate-create.component';
import { ProfessionalServicesEstimateCreateComponent } from '../professional-services/service-estimate-components/professional-services-estimate-create/professional-services-estimate-create.component';
import { ProfessionalServicesEstimateSummaryComponent } from '../professional-services/service-estimates/professional-services-estimate-summary/professional-services-estimate-summary.component';
import { CloudMigrationAnalysisDashboardComponent } from './analyses/cloud-migration-analysis-dashboard/cloud-migration-analysis-dashboard.component';
import { CloudMigrationAnalysisDataTransferComponent } from './analyses/cloud-migration-analysis-data-transfer/cloud-migration-analysis-data-transfer.component';
import { CloudMigrationAnalysisComponent } from './analyses/cloud-migration-analysis/cloud-migration-analysis.component';
import { CloudMigrationAnalysisComplexityComponent } from './analyses/cloud-migration-analysis-complexity/cloud-migration-analysis-complexity.component';


const assessmentDetailTabRoutes: Routes = [
  {
    path: '', component: CloudMigrationAssessmentsListComponent, pathMatch: 'full', data: {
      breadcrumb: null, isRoutable: false
    }
  },
  {
    path: ':assessmentId', component: CloudMigrationAssessmentDetailComponent, data: {
      breadcrumb: 'View Assessment', isRoutable: true
    }
  }
];

const discoveryDetailTabRoutes: Routes = [
  {
    path: '', component: CloudMigrationDiscoveryComponent, pathMatch: 'full', data: {
      breadcrumb: null, isRoutable: false
    }
  },
  {
    path: ':discoveryId', component: CloudMigrationDiscoveryDetailComponent, data: {
      breadcrumb: 'View Discovery', isRoutable: true
    }
  }
];

const componentRoute = [
  {
    path: '', component: ParentEstmiateDetailComponent, data: {
      breadcrumb: null, isRoutable: false
    }
  },
  {
    path: 'createComponent', component: ProfessionalServicesEstimateCreateComponent, data: {
      breadcrumb: 'Create Component', isRoutable: true
    }, canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'editComponent/:secId', component: ProfessionalServicesEstimateCreateComponent, data: {
      breadcrumb: 'Edit Component', isRoutable: true
    }, canDeactivate: [CanDeactivateGuard],
  }
];

const summaryRoute = {
  path: 'seDetails/:serviceEstimateId/summary', component: ProfessionalServicesEstimateSummaryComponent, data: {
    breadcrumb: 'Summary', isRoutable: true
  }
};

const seComponentsRoute = [
  {
    path: '', component: ParentEstimateCreateComponent, data: {
      breadcrumb: null, isRoutable: false
    }, canDeactivate: [CanDeactivateGuard],
  }, {
    path: 'seDetails', data: {
      breadcrumb: 'Components', isRoutable: true
    }, children: componentRoute
  },
  {
    path: 'editComponent/:secId', component: ProfessionalServicesEstimateCreateComponent, data: {
      breadcrumb: 'Edit Component', isRoutable: true
    }, canDeactivate: [CanDeactivateGuard]
  }
];

const componentsRoute = {
  path: 'seDetails/:serviceEstimateId', data: {
    breadcrumb: 'Components', isRoutable: true
  }, children: componentRoute
};

const editServiceEstimateRoute = {
  path: 'editServiceEstimate/:serviceEstimateId', data: {
    breadcrumb: 'Edit Service Estimate/Change Order', isRoutable: true
  }, children: seComponentsRoute
};

const migrationEnvironmentTabRoutes: Routes = [
  {
    path: '', component: CloudMigrationEnvironmentComponent, pathMatch: 'full', data: {
      breadcrumb: null, isRoutable: false
    }
  },
  {
    path: ':environmentId', component: CloudMigrationEnvironmentComponent, data: {
      breadcrumb: 'View Discovery Environment', isRoutable: true
    }
  }
];

const serviceEstimatesRoute =  [
    {
      path: '', component: ProfessionalServicesProjectDetailComponent, data: {
        breadcrumb: null, isRoutable: false
      }
    },
    {
      path: 'createServiceEstimate', component: ParentEstimateCreateComponent, data: {
        breadcrumb: 'Create Service Estimate', isRoutable: true
      }, canDeactivate: [CanDeactivateGuard]
    },
    editServiceEstimateRoute,
    {
      path: 'editChangeOrder/:serviceEstimateId', data: {
        breadcrumb: 'Edit Change Order', isRoutable: true
      }, children: seComponentsRoute
    },
    {
      path: 'createChangeOrder', component: ParentEstimateCreateComponent, data: {
        breadcrumb: 'Create Change Order', isRoutable: true
      }, canDeactivate: [CanDeactivateGuard]
    },
    componentsRoute,
    summaryRoute
  ];

const analysisRoutes: Routes = [
  { path: '', pathMatch: 'full', component: CloudMigrationAnalysisDashboardComponent },
  { path: 'datatransfer', component: CloudMigrationAnalysisDataTransferComponent },
  { path: 'complexity', component: CloudMigrationAnalysisComplexityComponent },
];

const tabRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'details' },
  {
    path: 'details', component: ProjectCreateFormComponent, canDeactivate: [CanDeactivateGuard], data: {
      breadcrumb: 'Details', isRoutable: true
    }
  },
  {
    path: 'discoveries', component: CloudMigrationDiscoveryDashboardComponent, data: {
      breadcrumb: 'Discoveries', isRoutable: true
    }, children: [...discoveryDetailTabRoutes]
  },
  {
    path: 'discoveryConfigurations', component: CloudMigrationDiscoveryConfigurationPopupComponent, data: {
      breadcrumb: 'Discovery Configurations', isRoutable: true
    }
  },
  {
    path: 'migrationEnvironments', component: CloudMigrationEnvironmentComponent, data: {
      breadcrumb: 'Discovery Environments', isRoutable: true
    }, children: [...migrationEnvironmentTabRoutes]
  },
  {
    path: 'assessments', component: CloudMigrationAssessmentsDashboardComponent, data: {
      breadcrumb: 'Discoveries', isRoutable: true
    }, children: [...assessmentDetailTabRoutes]
  },
  {
    path: 'serviceestimates',  children:
      serviceEstimatesRoute, data: {
      breadcrumb: 'Service Estimates/Change Orders', isRoutable: true,
    }
  }
  ,
  {
    path: 'analyses', component: CloudMigrationAnalysisComponent, children: [
      ...analysisRoutes
    ]
  }
];


const routes: Routes = [
  {
    path: 'cmt', children: [
      {
        path: '', component: CloudMigrationToolLandingPageComponent,
        data: { breadcrumb: 'CMT', isRoutable: false },
        children: [
          {
            path: 'projects', component: ProfessionalServicesProjectComponent, data: {
              breadcrumb: 'Projects', isRoutable: true, isCmtProj: true
            },
            children: [
              {
                path: '', component: ProfessionalServicesProjectLpComponent, data: {
                  breadcrumb: null, isRoutable: true
                }
              },
              {
                path: 'create', component: CloudMigrationDashboardComponent,
                children: [{
                  path: '', component: ProjectCreateFormComponent, canDeactivate: [CanDeactivateGuard], data: {
                    breadcrumb: 'Create', isRoutable: true
                  }
                }],
                data: {
                  breadcrumb: null, isRoutable: false
                }
              },
              {
                path: ':id/view', component: CloudMigrationDashboardComponent, data: {
                  breadcrumb: 'View', isRoutable: false
                },
                children: [...tabRoutes]
              }
            ]
          }]
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CloudMigrationToolRoutingModule { }
