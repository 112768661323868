import { Component, OnInit } from '@angular/core';
import { Employee } from '../../models/employee';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { Location } from '@angular/common';
import { VehicleManagerService } from '../vehicle-manager.service';

@Component({
  selector: 'app-vehicle-manager-create',
  templateUrl: './vehicle-manager-create.component.html',
  styleUrls: ['./vehicle-manager-create.component.scss']
})
export class VehicleManagerCreateComponent implements OnInit {
  employees: Array<Employee>;
  managerForm: UntypedFormGroup;


  constructor(private fb: UntypedFormBuilder, private adminService: AdminService, private managerService: VehicleManagerService, private location: Location) { }

  ngOnInit() {
    this.adminService.getEmployees()
      .subscribe(res => {
        this.employees = res;

      });
    this.managerForm = this.fb.group({
      'vehicleMangerId': [null],
      'empId': [null, Validators.required]
    });
  }
  saveManager() {
    if (this.managerForm.valid) {
      this.managerService.saveVehicleManager(this.managerForm.value)
        .subscribe(res => {
          this.location.back();

        });
    }
  }

}
