<mat-card>
  <mat-card-content>
    <h2>Service Estimates Pending Threshold Approval</h2>
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="projectName">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createdDate | date:'shortDate'}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="expirationDate">
        <mat-header-cell *matHeaderCellDef> Expiration Date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.expirationDate | date:'shortDate'}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="isChangeOrder">
        <mat-header-cell *matHeaderCellDef> Approval Pending - Type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.isChangeOrder? 'Change Order' : 'Service Estimate'}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="element.serviceEstimateStatus == 0">Components still in progress</span>
          <span *ngIf="element.serviceEstimateStatus == 1">Pending Director Approval/Override</span>
          <span *ngIf="element.serviceEstimateStatus == 2">Approved</span>
          <span *ngIf="element.serviceEstimateStatus == 3">Expired</span>
          <span *ngIf="element.serviceEstimateStatus == 4">Contract Signed</span>
          <span *ngIf="element.serviceEstimateStatus == 5">Pending PM Approval/Override</span>
          <span *ngIf="element.serviceEstimateStatus == 6">Pending VP Threshold Approval</span>
          <span *ngIf="element.serviceEstimateStatus == 8">Component Pending Rate Approval</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                      <button mat-menu-item color="default" type="button" [routerLink]="element.isChangeOrder ? [element.serviceProjectId, 'editChangeOrder', element.serviceEstimateId] : [element.serviceProjectId, 'editServiceEstimate', element.serviceEstimateId]"
                      *ngIf="!isSales">
                <mat-icon>create</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item color="accent" type="button" [routerLink]="['seDetails',element.serviceEstimateId]"
                      *ngIf="!isSales">
                <mat-icon>list</mat-icon>
                <span>Components</span>
              </button>
              <button mat-menu-item color="accent" type="button" [routerLink]="['seDetails',element.serviceEstimateId,'summary']"
                      *ngIf="element.approvedDate">
                <mat-icon>info</mat-icon>
                <span>Summary</span>
              </button>
              <button mat-menu-item type="button" color="primary" *ngIf="!isSales" (click)="markContractSigned(element.serviceEstimateId)"
                      [disabled]="element.serviceEstimateStatus != 2 || !isAbleToSignContract">
                <mat-icon>date_range</mat-icon>
                <span>Mark Contract Signed</span>
              </button>
            </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
