
<h3
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  {{ formTitle }}
</h3>

<mat-dialog-content id="frm-edit-cloudprovider" *ngIf="cloudProvider" [formGroup]="cloudProviderForm">
  <br/>
  <fieldset>
  <mat-card>
    <div class="row">
      <mat-form-field>
        <input
          matInput
          formControlName="name"
          placeholder="Provider Name"
        />
        <mat-error
          *ngIf="
          cloudProviderForm.controls['name'].hasError('required')
          "
        >
          Service Name is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <input matInput formControlName="vpnRate" placeholder="VPN Rate" />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          formControlName="bandwidthRate"
          placeholder="Bandwidth Rate"
        />
      </mat-form-field>
    </div>
  </mat-card></fieldset>
</mat-dialog-content>
<div>
  <mat-dialog-actions align="bottom" align="end">
    <button id="btn-save"
      type="submit"
      mat-raised-button
      mat-primary
      [disabled]="!cloudProviderForm?.valid"
      (click)="save()"
    >
      Save
    </button>
    <button id="btn-close" class="mat-raised-button" (click)="close()">Close</button>
  </mat-dialog-actions>
</div>
