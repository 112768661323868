import { Pipe, PipeTransform } from '@angular/core';
import { ReleaseNotesIteration } from '../../admin/release-notes/models/releaseNotes';
import { AzureDevOpsIteration } from '../models/iteration';

@Pipe({
  name: 'filterByIterationId'
})
export class FilterByIterationIdPipe implements PipeTransform {

  transform(devopsIterations: Array<AzureDevOpsIteration>, iterations?: Array<ReleaseNotesIteration>): any {
    let _iterations: Array<any>;
    _iterations = [];
    devopsIterations.forEach((t: AzureDevOpsIteration) => {
      iterations?.forEach((tid: ReleaseNotesIteration) => {
        if (t.id == tid.azureIterationId) {
          _iterations.push(t);
        }
      });
    });
    return _iterations;
  }

}
