import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-employee-create',
  templateUrl: './employee-create.component.html',
  styleUrls: ['./employee-create.component.scss']
})
export class EmployeeCreateComponent implements OnInit {
  employee: any;

  constructor(private adminService: AdminService) { }

  ngOnInit() {
  }
  createEmployee() {
    this.adminService.createEmployee(this.employee);
  }

}
