import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ReleaseNotesService as sharedReleaseNotes} from '../../../shared/services/release-notes.service';
import { ReleaseNotesService } from '../../../admin/release-notes/release-notes.service';
import { AzureDevOpsIteration, AzureDevOpsWorkItem, AzureDevOpsWorkItemData, AzureDevOpsWorkItemFields } from '../../../shared/models/iteration';
import { ReleaseNote, ReleaseNotesIteration } from '../../../admin/release-notes/models/releaseNotes';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-release-notes-history',
  templateUrl: './release-notes-history.component.html',
  styleUrls: ['./release-notes-history.component.scss']
})
export class ReleaseNotesHistoryComponent implements OnInit {

  panelOpenState = false;
  releaseNotesData: Array<ReleaseNote>;
  iterations: Array<AzureDevOpsIteration>;
  expandedElement: ReleaseNote | null;
  workItems: Array<AzureDevOpsWorkItemFields>;
  bugs: Array<AzureDevOpsWorkItemFields>;
  userstories: Array<AzureDevOpsWorkItemFields>;
  isLoading: boolean;
  isPanelLoading: boolean;
  noRecords: boolean;
  iterationWorkItems: Array<AzureDevOpsWorkItem> | null;
  iterationCount: number;
  batchCount: number;
  ids: number[];
  workItemsData: Array<AzureDevOpsWorkItemFields>;
  @ViewChild("userstory", { static: true }) userstoryTable: MatTable<AzureDevOpsWorkItemFields>;
  @ViewChild("bug", { static: true }) bugTable: MatTable<AzureDevOpsWorkItemFields>;
  displayedColumns: any = ['id', 'title', 'description', 'actions'];

  //@ViewChild('accordion', { static: true }) Accordion: MatAccordion

  constructor(private releaseNotesService: ReleaseNotesService, private sharedReleaseNotesService: sharedReleaseNotes, private sanitizer: DomSanitizer) {
   
  }

  ngOnInit() {
    this.isLoading = true;
    this.noRecords = false;
    this.isPanelLoading = false;
    let releaseNotes = this.releaseNotesService.getReleaseNotes();
    let iterations = this.releaseNotesService.getIterations();
    forkJoin([releaseNotes, iterations])
      .subscribe(results => {
        this.releaseNotesData = results[0];
        this.iterations = results[1];
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
  }


  expandPanel(releaseNote: ReleaseNote) {
    this.isPanelLoading = true;
    this.expandedElement = this.expandedElement === releaseNote ? null : releaseNote;
    this.expandedElement ? this.getReleaseInfo(releaseNote.iterations) : '';
  }

  getIterationName(iterations: Array<ReleaseNotesIteration>) {

    //let iteration: AzureDevOpsIteration | undefined = this.iterations.find((it: AzureDevOpsIteration) => it.id == iterationId);
    return "";
  }

  getReleaseInfo(iterations: Array<ReleaseNotesIteration>) {
    this.workItems = [];
    this.userstories = [];
    this.bugs = [];
    this.ids = [];
    this.iterationWorkItems = [];
    this.iterationCount = 0;
    iterations.forEach((iteration: ReleaseNotesIteration, index: number) => {
      this.getIterationWorkItems(iteration.azureIterationId, iterations.length);
      
    });
    
  }

  getIterationWorkItems(iterationId: string, iterationCount: number) {
    this.sharedReleaseNotesService.getIterationWorkItems(iterationId).subscribe((res: Array<AzureDevOpsWorkItem> | null) => {
      this.iterationWorkItems = res;
      this.iterationWorkItems?.map((x: AzureDevOpsWorkItem) => {
        if (this.ids.indexOf(x.target.id) == -1)
          this.ids.push(x.target.id);
      });
      if ((++this.iterationCount == iterationCount && this.ids.length > 0)) {
        let wiBatches: any = [];
        if (this.ids.length > 200) {
          wiBatches = this.getWIBatches(this.ids, 200);
        } else {
          wiBatches = [this.ids];
        }
        this.batchCount = 0;
        this.workItemsData = [];
        for (let i = 0; i < wiBatches.length; i++) {
          this.getWorkItemsData(wiBatches[i], wiBatches.length);
        }
      } else if (this.ids.length == 0) {
        this.noRecords = true;
        this.isPanelLoading = false;
      }
    }, (error) => {
      this.isPanelLoading = false;
    });
  }

  getWorkItemsData(wiBatches: any, wiLength: number) {
    this.sharedReleaseNotesService.getWorkItemsData(wiBatches).subscribe((res: Array<AzureDevOpsWorkItemData>) => {
      this.workItemsData = res.map((x: AzureDevOpsWorkItemData) => x.fields).filter(x => x.includeinReleaseNotes);
      this.workItemsData.forEach(item => {
        let releaseNoteDetails: any = item.releaseNoteDetails;
        item.releaseNoteDetails = this.getReleaseNoteDetails(releaseNoteDetails);
        this.workItems.push(item);
      })
      if (++this.batchCount == wiLength) {
        //this.workItems = workItemsData;
        this.bugs = this.workItems.filter((wi: AzureDevOpsWorkItemFields) => wi.workItemType == "Bug");
        this.userstories = this.workItems.filter((wi: AzureDevOpsWorkItemFields) => wi.workItemType == "User Story");
        this.isPanelLoading = false;
        this.noRecords = this.workItems.length > 0 ? false : true;
      }
    }, (error) => {
      this.isPanelLoading = false;
    });
  }

  getWIBatches(wiIds: any[], size: number): any[] {
    const batches: any[] = [],
      wiCount = wiIds.length;
    let wiIndex = 0,
      batchIndex = 0;
    while (wiIndex < wiCount) {
      batches[batchIndex++] = wiIds.slice(
        wiIndex,
        (wiIndex += size)
      );
    }
    return batches;
  }

  getReleaseNoteDetails(details: string | null) {
    return details ? this.sanitizer.bypassSecurityTrustHtml(details) : null;
  }

  //getBugInfo(bug: AzureDevOpsWorkItemFields) {
  //  let workItem: AzureDevOpsWorkItemFields | undefined = this.workItems.find((wi: AzureDevOpsWorkItemFields) => wi.id == bug.parent);
  //  return workItem ? workItem.includeinReleaseNotes ? this.getDescription(workItem.releaseNoteDetails) : this.getDescription(workItem.description): '';
  //}
}
