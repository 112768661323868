<app-breadcrumb></app-breadcrumb>
<mat-card>
  <mat-card-content>
    <h2>Resources</h2>
    <nav mat-tab-nav-bar>
      <a mat-tab-link routerLink="enterpriseUnifiedProcess" routerLinkActive="active-link">Enterprise Unified Process (EUP)</a>
      <a mat-tab-link routerLink="videos" routerLinkActive="active-link">Help Videos</a>
      <a mat-tab-link routerLink="releaseHistory" routerLinkActive="active-link">Release History</a>
    </nav>
      <router-outlet></router-outlet>
  </mat-card-content>
</mat-card>
