import { Component, OnInit, ViewChild } from '@angular/core';
import { VehicleDataSource } from '../../models/vehicle-datasource';
import { FleetMangementService } from '../../fleet-mangement.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Employee } from '../../../admin/models/employee';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Vehicle } from '../../models/fleet-management-models';
import { ConfirmRestoreDialogComponent } from 'src/app/shared/components/confirm-restore-dialog/confirm-restore-dialog.component';

@Component({
  selector: 'app-retired-list',
  templateUrl: './retired-list.component.html',
  styleUrls: ['./retired-list.component.scss']
})
export class RetiredListComponent implements OnInit {
  dataSource: VehicleDataSource;
  employees: Array<Employee>;
  isNameAscending: boolean;
  vehiclesData: Array<any>;
  pagination: any;
  pageSize: number;
  displayedColumns = [
    'vehicleNumber',
    'vehicleVin',
    'licensePlate',
    'vehicleIsActive',
    'assignedDriver',
    'vehicleId'
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private service: FleetMangementService, public dialog: MatDialog) { }

  ngOnInit() {
    this.pageSize = 50;
    this.isNameAscending = true;
    this.service.getRetiredVehicles()
      .subscribe(res => {
        this.service.getAllDrivers()
          .subscribe(employeesResponse => {
            this.employees = employeesResponse;
            this.vehiclesData = res.sort((a, b) => (a.vehicleNumber < b.vehicleNumber ? -1 : 1) * 1);
            this.setTableData();
          });
      });
  }
  updateVehicle(vehicle: Vehicle) {
    const dialogRef = this.dialog.open(ConfirmRestoreDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.service.restoreVehicle(vehicle.vehicleId)
            .subscribe(() => {
              this.service.getRetiredVehicles()
                .subscribe(res2 => {
                  this.vehiclesData = res2.sort((a, b) => (a.vehicleNumber < b.vehicleNumber ? -1 : 1) * 1);;
                  this.setTableData(this.vehiclesData);
                });
            });
        }
      });
  }
  filterEmployee(employeeId: string): Employee | undefined {
    const employee = this.employees.filter(_employee => _employee.vehicleDriverId === employeeId)[0];
    if (employee) {
      return employee;
    } else {
      return;
    }
  }
  setTableData(data?: Array<any>) {
    this.dataSource = new VehicleDataSource();
    let pageAmount = 1000;
    if (!data) {
      if (this.pagination) {
        pageAmount = this.pagination.pageSize;
      }
      const initData = this.vehiclesData?.slice(0, pageAmount);
      this.dataSource.setData(initData);
    } else {
      this.dataSource.setData(data);
    }
  }
}
