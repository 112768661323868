import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employeeFilter'
})
export class EmployeFilterPipe implements PipeTransform {

  transform(employeeList: Array<any>, employeeId: any): any {
    let employee: any;
    if (employeeList) {
      employeeList.forEach((emp: any) => {
        if (emp.employeeId == employeeId) {
          employee = emp;
        }
      });
      if (!employee) {
        return '';
      }
      return `${employee.firstName} ${employee.lastName}`;
    }
    return '';
  }

}
