<mat-sidenav-container #sideNavContainer class="example-container" *ngIf="loginDisplay">
  <mat-toolbar id="topSection" color="primary" class="hidden-print">
    <mat-icon class="navToggleBtn" (click)="toggleSideNav()">reorder</mat-icon>
    <a class="navBarLinkHome" routerLink="">
      <span>
        <img src="/assets/MIT-US-Logo-Color.png" width="150" />
      </span>
    </a>
    <span class="fill-remaining-space"></span>

    <div (clickOutside)="closeNotes()" appClickOutside class="notes-container">
      <mat-icon class="navToggleBtn" [matMenuTriggerFor]="releaseNoteMenu" id="info" (click)="displayNotes()" #notesMenuTrigger="matMenuTrigger">info_outline</mat-icon>
      <mat-menu #releaseNoteMenu="matMenu" id="notesMenu" xPosition="before" class="note-menu">
        <span *ngIf="displayReleaseNotes">
          <app-release-notes-display (click)="ignoreMenuEvent($event)" (clearNotes)="closeNotes()"></app-release-notes-display>
        </span>
      </mat-menu>
    </div>

    <mat-icon class="navToggleBtn" id="notification">notifications_none</mat-icon>

    <mat-icon class="navToggleBtn" [matMenuTriggerFor]="menu" id="profile">person_outline</mat-icon>
    <mat-menu #menu="matMenu" id="profileMenu" xPosition="before">
      <button mat-menu-item id="myProfile" [routerLink]="['myProfile']">My Profile</button> 
      <button mat-menu-item id="logout" (click)="logout()">Logout</button>
    </mat-menu>

    <!--<mat-icon class="navToggleBtn" (click)="rightSidenav.toggle()">more_vert</mat-icon>-->
  </mat-toolbar>
  <mat-sidenav #sidenav [opened]="sideNavOpened" mode="over" class="example-sidenav">
    <mat-nav-list *ngIf="currentUser">
      <mat-divider></mat-divider>
      <a id="homeTab" mat-list-item [routerLink]="['/']"> Home </a>
      <mat-divider></mat-divider>
      <a mat-list-item [routerLink]="['documentcreator']" *ngIf="isSuperAdmin || isOfferLetterAccess">
        Document Creator
      </a>
      <mat-divider *ngIf="isSuperAdmin || isOfferLetterAccess"></mat-divider>
      <a id="projectsTab" routerLinkActive="active" mat-list-item [routerLink]="['professionalServices']"
        *ngIf="isServiceEstimateUser"> Projects </a>
      <mat-divider *ngIf="isServiceEstimateUser"></mat-divider>
      <a id="cmtTab" routerLinkActive="active" mat-list-item [routerLink]="['cmt','projects']" *ngIf="isCloudMigrationUser">
        Cloud
        Migration Tool
      </a>
      <a id="cmtAdminTab" routerLinkActive="active" mat-list-item [routerLink]="['cmtadmin']" *ngIf="isCloudMigrationUser">
        CMT Admin
      </a>
      <mat-divider *ngIf="isCloudMigrationUser"></mat-divider>
      <a id="fleetManagementTab" routerLinkActive="active" mat-list-item [routerLink]="['fleetmanagement']" *ngIf="isFleetManagementUser">
        Fleet Management
      </a>
      <mat-divider *ngIf="isFleetManagementUser"></mat-divider>
      <a id="adminTab" routerLinkActive="active" mat-list-item [routerLink]="['admin']" *ngIf="isSuperAdmin || isDirector">
        Admin
      </a>
      <mat-divider *ngIf="isSuperAdmin || isDirector"></mat-divider>
      <a id="businessPartnersTab" routerLinkActive="active" mat-list-item [routerLink]="['partners']" *ngIf="hasBusinessPartnerAccess">
        Business Partners
      </a>
      <mat-divider *ngIf="hasBusinessPartnerAccess"></mat-divider>
      <a id="clientsTab" routerLinkActive="active" *ngIf="hasClientAccess" mat-list-item [routerLink]="['clients']">
        Clients
      </a>
      <mat-divider *ngIf="isAdmin || isOfferLetterAccess || hasClientAccess"></mat-divider>
      <a id="tagsTab" routerLinkActive="active" mat-list-item [routerLink]="['tags']" *ngIf="isSuperAdmin || isDirector">
        Tags
      </a>
      <mat-divider *ngIf="isSuperAdmin || isDirector"></mat-divider>
      <a id="supportRequestTab" routerLinkActive="active" mat-list-item [routerLink]="['support']">
        Support Request
      </a>
      <mat-divider *ngIf="isAdmin"></mat-divider>
      <a id="adminHelpVideosTab" routerLinkActive="active" mat-list-item [routerLink]="['adminHelpVideos']" *ngIf="hasVideosAccess">
        Admin Help Videos
      </a>
      <mat-divider></mat-divider>
      <a id="resourcesTab" routerLinkActive="active" mat-list-item [routerLink]="['help']">
        Resources
      </a>
      <mat-divider></mat-divider>
      <a id="emplyeesTab" routerLinkActive="active" mat-list-item [routerLink]="['employee']" *ngIf="isSuperAdmin || isDirector">
        Employees
      </a>
      <mat-divider *ngIf="isSuperAdmin"></mat-divider>
      <a id="reportsTab" routerLinkActive="active" mat-list-item [routerLink]="['reports']" *ngIf="isAdmin || isDirector">
        Reports
      </a>
      <mat-divider *ngIf="isAdmin || isDirector"></mat-divider>
      <a id="announcementsTab" *ngIf="isSuperAdmin" routerLinkActive="active" mat-list-item [routerLink]="['simpplr-integration']">
        Announcements
      </a>
      <mat-divider *ngIf="isSuperAdmin"></mat-divider>
      <mat-divider *ngIf="isAdmin || isDirector"></mat-divider>
      <a id="salesforceTestingTab" *ngIf="isSuperAdmin" routerLinkActive="active" mat-list-item [routerLink]="['salesforce-testing']">
        Salesforce Testing
      </a>
      <mat-divider *ngIf="isSuperAdmin"></mat-divider>
    </mat-nav-list>
  </mat-sidenav>
  <!--<mat-sidenav #rightSidenav [opened]="isRightSideNavOpen" mode="over" class="example-sidenav" [position]="'end'">
    <mat-list dense>
      <mat-divider></mat-divider>
      <mat-list-item>
        <button mat-raised-button color="warn" (click)="logout()" type="button" style="margin: 0 auto !important;">
          Logout
        </button>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <mat-slide-toggle (change)="flipTheme()" [(ngModel)]="isDarkThemeEnabled">Dark Theme</mat-slide-toggle>
      </mat-list-item>
      <mat-list-item *ngIf="isDashbboardViewVisible">
        <mat-slide-toggle (change)="toggleDashboardView()" [(ngModel)]="isDashboardViewEnabled">Dashboard</mat-slide-toggle>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </mat-sidenav>-->


  <div class="example-sidenav-content">
    <div class="col-md-12 backWrapper" *ngIf="isBackButtonVisible">
      <button class="hidden-print" mat-raised-button (click)="goBack()">Back</button>
    </div>
    <router-outlet *ngIf="loginDisplay"></router-outlet>
  </div>
  <app-footer></app-footer>
  <!-- <button type="button" mat-icon-button class="bottom-button hidden-print" (click)="gotoTop()">
    <mat-icon>arrow_upward</mat-icon>
  </button> -->
  <!-- <app-scoll-top></app-scoll-top> -->
</mat-sidenav-container>
<app-loading-popup #loadingPopup [class.active]="loadingPopup.loading"></app-loading-popup>
