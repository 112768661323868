import { Component, OnInit, Inject } from '@angular/core';
import { AdminService } from '../../../admin/admin.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GraphApiService } from '../../services/graph-api.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-assign-employee-popup',
  templateUrl: './assign-employee-popup.component.html',
  styleUrls: ['./assign-employee-popup.component.scss']
})
export class AssignEmployeePopupComponent implements OnInit {
  employees: Array<any>;
  empData: any;

  constructor(private employeeService: AdminService, public dialogRef: MatDialogRef<AssignEmployeePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private graphApiService: GraphApiService) { }

  ngOnInit(): void {
    this.empData = {};
    this.getActiveEmployees();
  }
  getActiveEmployees() {
    let fetchEmployees = this.employeeService.getEmployees();
    let fetchAdUsers = this.graphApiService.getUsers();
    forkJoin([fetchEmployees, fetchAdUsers]).subscribe(results => {
      let azAdEmployees = results[1].filter(user => user.givenName != null && user.surname != null && user.accountEnabled != false);
      this.employees = results[0].filter(emp => azAdEmployees.find(azUser => azUser.id == emp.azureAdId));
    });
  }
  createAsShadowPhase() {
    this.empData.isShadowPhase = true;
    this.dialogRef.close(this.empData);
  }
  isEmployeeDisabled(employeeId: string) {
    return this.data?.component?.children.map((sp: any) => sp.employeeId).indexOf(employeeId) >= 0;
  }
}
