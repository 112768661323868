<div>
  <mat-form-field>
    <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" placeholder="Start Date">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate" placeholder="End Date">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <button mat-raised-button type="button" (click)="getReport()">Run Report</button>
</div>
<br>
<div *ngIf="reportResult">
  <label>Average Miles Over Selected Date Range</label>
  <p>
    <b>{{reportResult.milesOverSelectedTime | number : '1.0-2'}}</b>
  </p>
  <label>Expense Cost Per Mile</label>
  <p>
    <b>{{reportResult.expenseCostPerMile | currency:'USD':'symbol'}}</b>
  </p>
</div>