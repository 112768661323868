import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  currentStates: Array<ReportState> = new Array<ReportState>();
  constructor(private http: HttpClient) { }

  runBusinessPartnerReport(query: any) {
    var body = JSON.stringify(query);
    return this.http.post<any[]>(`/api/Reports/BusinessPartners`, body);
  }
  getBusinessPartners() {
    return this.http.get<any[]>(`/api/businessPartners`);
  }
  getReportState(reportOption: string): number | undefined {
    return this.currentStates.find(s => s.reportOption == reportOption)?.value;
  }
  setReportState(reportOption: string, reportValue: number) {
    let currentState = this.currentStates.find(s => s.reportOption == reportOption);
    if (currentState) {
      currentState.value = reportValue;
    }
    else {
      this.currentStates.push({
        reportOption: reportOption,
        value: reportValue
      });
    }
  }
}

export class ReportState {
  reportOption: string;
  value: number;
}
