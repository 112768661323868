<fieldset>
  <legend>
    Traceroute Data
  </legend>

  <mat-table class="full-width-table mat-elevation-z8" [dataSource]="tableDataSource">
    <ng-container matColumnDef="senderIpV4">
      <mat-header-cell *matHeaderCellDef> Sender IP</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.senderIpV4}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="targetHostIpV4">
      <mat-header-cell *matHeaderCellDef> Target Host IP</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.targetHostIpV4}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastHopIpV4">
      <mat-header-cell *matHeaderCellDef> Last Hop IP</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.lastHopIpV4}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="maxHops">
      <mat-header-cell *matHeaderCellDef> Max Hops</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.maxHops}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="packetSize">
      <mat-header-cell *matHeaderCellDef> Packet Size</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.packetSize}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

</fieldset>
