import { NgModule } from '@angular/core';

import { CloudMigrationToolRoutingModule } from './cloud-migration-tool-routing.module';
import { CloudMigrationToolLandingPageComponent } from './cloud-migration-tool-landing-page/cloud-migration-tool-landing-page.component';
import { SharedModule } from '../shared/shared.module';
import { CloudMigrationDiscoveryComponent } from './discoveries/cloud-migration-discovery/cloud-migration-discovery.component';
import { CloudMigrationDiscoveryDetailComponent } from './discoveries/cloud-migration-discovery-detail/cloud-migration-discovery-detail.component';
import { CloudMigrationDiscoveryListComponent } from './discoveries/cloud-migration-discovery-list/cloud-migration-discovery-list.component';
import { CloudMigrationDiscoveryConfigurationPopupComponent } from './configurations/cloud-migration-discovery-configuration-popup/cloud-migration-discovery-configuration-popup.component';
import { CloudMigrationDashboardComponent } from './cloud-migration-dashboard/cloud-migration-dashboard.component';
import { CloudMigrationDiscoveryDashboardComponent } from './discoveries/cloud-migration-discovery-dashboard/cloud-migration-discovery-dashboard.component';
import { CloudMigrationAssessmentsDashboardComponent } from './assessments/cloud-migration-assessments-dashboard/cloud-migration-assessments-dashboard.component';
import { CloudMigrationAssessmentsListComponent } from './assessments/cloud-migration-assessments-list/cloud-migration-assessments-list.component';
import { CloudMigrationAssessmentDetailComponent } from './assessments/cloud-migration-assessment-detail/cloud-migration-assessment-detail.component';
import { CloudMigrationStorageSummaryComponent } from './assessments/summary-tables/cloud-migration-storage-summary/cloud-migration-storage-summary.component';
import { CloudMigrationNetworkSummaryComponent } from './assessments/summary-tables/cloud-migration-network-summary/cloud-migration-network-summary.component';
import { CloudMigrationComputeSummaryComponent } from './assessments/summary-tables/cloud-migration-compute-summary/cloud-migration-compute-summary.component';
import { CloudMigrationStoragePopupComponent } from './assessments/cloud-migration-storage-popup/cloud-migration-storage-popup.component';
import { CloudMigrationPricingSummaryComponent } from './assessments/summary-tables/cloud-migration-pricing-summary/cloud-migration-pricing-summary.component';
import { CloudMigrationCloudSolutionDetailsComponent } from './assessments/cloud-migration-assessment-detail/cloud-migration-cloud-solution-details/cloud-migration-cloud-solution-details.component';
import { CloudMigrationDiscoveryUploadComponent } from './discoveries/discovery-upload/discovery-upload.component';
import { CloudMigrationGenerateAssessmentComponent } from './assessments/generate-assessment/generate-assessment.component';
import { CloudMigrationDiscoveryWorkloadComponent } from './discoveries/cloud-migration-discovery-workload/cloud-migration-discovery-workload.component';
import { CloudMigrationDiscoveryNetworkprofileComponent } from './discoveries/cloud-migration-discovery-networkprofile/cloud-migration-discovery-networkprofile.component';
import { CloudMigrationPricingFormComponent } from './assessments/cloud-migration-assessment-detail/cloud-migration-pricing-form/cloud-migration-pricing-form.component';
import { CloudMigrationMatchCloudproductComponent } from './assessments/cloud-migration-match-cloudproduct/cloud-migration-match-cloudproduct.component';
import { CloudMigrationToolProductTableComponent } from './assessments/cloud-migration-tool-product-table/cloud-migration-tool-product-table.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CommonModule } from '@angular/common';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { CloudMigrationServiceEstimateDashboardComponent } from './service-estimates/cloud-migration-service-estimate-dashboard/cloud-migration-service-estimate-dashboard.component';
import { CloudMigrationAssessmentsTableComponent } from './assessments/cloud-migration-assessments-table/cloud-migration-assessments-table.component';
import { CloudMigrationDiscoveryNetworkComponent } from './discoveries/cloud-migration-discovery-network/cloud-migration-discovery-network.component';
import { CloudMigrationDiscoveryPerfdataComponent } from './discoveries/cloud-migration-discovery-perfdata/cloud-migration-discovery-perfdata.component';
import { CloudMigrationDiscoveryInterfacedataComponent } from './discoveries/cloud-migration-discovery-interfacedata/cloud-migration-discovery-interfacedata.component';
import { CloudMigrationDiscoveryPingdataComponent } from './discoveries/cloud-migration-discovery-pingdata/cloud-migration-discovery-pingdata.component';
import { CloudMigrationDiscoveryTraceroutedataComponent } from './discoveries/cloud-migration-discovery-traceroutedata/cloud-migration-discovery-traceroutedata.component';
import { CloudMigrationDiscoveryNetworkprofileListComponent } from './discoveries/cloud-migration-discovery-networkprofile-list/cloud-migration-discovery-networkprofile-list.component';
import { CloudMigrationDiscoveryWorkloadListComponent } from './discoveries/cloud-migration-discovery-workload-list/cloud-migration-discovery-workload-list.component';
import { CloudMigrationDiscoveryUploadDialogComponent } from './discoveries/cloud-migration-discovery-upload-dialog/cloud-migration-discovery-upload-dialog.component';
import { CloudMigrationAssessmentDiscoverySelectComponent } from './assessments/cloud-migration-assessment-discovery-select/cloud-migration-assessment-discovery-select.component';
import { CloudMigrationEnvironmentComponent } from './environments/cloud-migration-environment/cloud-migration-environment.component';
import { CloudMigrationEnvironmentListComponent } from './environments/cloud-migration-environment-list/cloud-migration-environment-list.component';
import { CloudMigrationProjectInfoComponent } from './shared/cloud-migration-project-info/cloud-migration-project-info.component';
import { CloudMigrationEnvironmentPopupComponent } from './environments/cloud-migration-environment-popup/cloud-migration-environment-popup.component';
import { CloudMigrationEnvironmentAddComponent } from './environments/cloud-migration-environment-add/cloud-migration-environment-add.component';
import { CloudMigrationDiscoveryPopupComponent } from './discoveries/cloud-migration-discovery-popup/cloud-migration-discovery-popup.component';
import { CloudMigrationWorkloadAddComponent } from './discoveries/workloads/cloud-migration-workload-add/cloud-migration-workload-add.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CloudMigrationContractPreviewComponent } from './assessments/cloud-migration-contract-preview/cloud-migration-contract-preview.component';
import { CloudMigrationContractLinesComponent } from './assessments/cloud-migration-contract-lines/cloud-migration-contract-lines.component';
import { CloudMigrationAnalysisDashboardComponent } from './analyses/cloud-migration-analysis-dashboard/cloud-migration-analysis-dashboard.component';
import { CloudMigrationAnalysisDataTransferComponent } from './analyses/cloud-migration-analysis-data-transfer/cloud-migration-analysis-data-transfer.component';
import { CloudMigrationAnalysisComponent } from './analyses/cloud-migration-analysis/cloud-migration-analysis.component';
import { CloudMigrationEnvironmentConfigPopupComponent } from './environments/configurations/cloud-migration-environment-config-popup/cloud-migration-environment-config-popup.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    CloudMigrationToolRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    //RouterModule
  ],
  declarations: [
    CloudMigrationToolLandingPageComponent,
    CloudMigrationDiscoveryComponent,
    CloudMigrationDiscoveryDetailComponent,
    CloudMigrationDiscoveryListComponent,
    CloudMigrationDiscoveryConfigurationPopupComponent,
    CloudMigrationEnvironmentComponent,
    CloudMigrationEnvironmentListComponent,
    CloudMigrationDashboardComponent,
    CloudMigrationDiscoveryDashboardComponent,
    CloudMigrationAssessmentsDashboardComponent,
    CloudMigrationAssessmentsListComponent,
    CloudMigrationAssessmentDetailComponent,
    CloudMigrationAssessmentDiscoverySelectComponent,
    CloudMigrationStorageSummaryComponent,
    CloudMigrationNetworkSummaryComponent,
    CloudMigrationComputeSummaryComponent,
    CloudMigrationStoragePopupComponent,
    CloudMigrationPricingSummaryComponent,
    CloudMigrationCloudSolutionDetailsComponent,
    CloudMigrationDiscoveryUploadComponent,
    CloudMigrationGenerateAssessmentComponent,
    CloudMigrationDiscoveryWorkloadComponent,
    CloudMigrationDiscoveryNetworkComponent,
    CloudMigrationDiscoveryNetworkprofileComponent,
    CloudMigrationPricingFormComponent,
    CloudMigrationToolProductTableComponent,
    CloudMigrationMatchCloudproductComponent,
    CloudMigrationServiceEstimateDashboardComponent,
    CloudMigrationAssessmentsTableComponent,
    CloudMigrationDiscoveryPerfdataComponent,
    CloudMigrationDiscoveryInterfacedataComponent,
    CloudMigrationDiscoveryPingdataComponent,
    CloudMigrationDiscoveryTraceroutedataComponent,
    CloudMigrationDiscoveryNetworkprofileComponent,
    CloudMigrationDiscoveryNetworkprofileListComponent,
    CloudMigrationDiscoveryWorkloadListComponent,
    CloudMigrationDiscoveryUploadDialogComponent,
    CloudMigrationProjectInfoComponent,
    CloudMigrationEnvironmentPopupComponent,
    CloudMigrationEnvironmentAddComponent,
    CloudMigrationEnvironmentPopupComponent,
    CloudMigrationDiscoveryPopupComponent,
    CloudMigrationWorkloadAddComponent,
    CloudMigrationDiscoveryUploadDialogComponent,
    CloudMigrationAnalysisComponent,
    CloudMigrationAnalysisDashboardComponent,
    CloudMigrationAnalysisDataTransferComponent,
    CloudMigrationContractPreviewComponent,
    CloudMigrationEnvironmentConfigPopupComponent, 
    CloudMigrationContractLinesComponent
  ],
  exports: [
    //RouterModule,
    CloudMigrationDiscoveryComponent,
    CloudMigrationDiscoveryConfigurationPopupComponent,
    CloudMigrationDiscoveryListComponent,
    CloudMigrationEnvironmentAddComponent,
  ],
  entryComponents: [CloudMigrationMatchCloudproductComponent],
})
export class CloudMigrationToolModule {}
