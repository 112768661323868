<mat-card>
  <mat-card-content>
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="registration.licensePlateNumber" />
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="registration.licensePlateState" />
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="registration.expirationDate" />
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions>
    <button type="button" (click)="updateRegistration()">Update</button>
    <button type="button" (click)="cancelUpdate()">Cancel</button>
  </mat-card-actions>
</mat-card>