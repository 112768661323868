import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { RoleService } from 'src/app/shared/services/role.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  hasSolPilarAccess: boolean;
  user: AccountInfo | null;

  constructor(private authService: MsalService, private roleService: RoleService) { }

  ngOnInit() {
    this.user = this.authService.instance.getActiveAccount();
    this.hasSolPilarAccess = this.hasRole('FieldServiceCoordinator') || this.hasRole('ProfessionalServiceCoordinator');
  }
  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, true);
  }

}
