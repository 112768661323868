import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceRoleDataSource } from '../../models/service-roles.datasource';
import { ServiceRoleService } from '../service-role.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { AdminService } from '../../admin.service';
import { Employee } from '../../../employee/employee-list/employee-list-datasource';
import { ProjectControlService } from '../../project-control/project-control.service';
import { MatSort } from '@angular/material/sort';
import { forkJoin } from 'rxjs';
import { SessionStorageService } from '../../../shared/services/session-storage.service';

@Component({
  selector: 'app-service-role-list',
  templateUrl: './service-role-list.component.html',
  styleUrls: ['./service-role-list.component.scss']
})
export class ServiceRoleListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  serviceRoles: any;
  displayedColumns: Array<string>;
  retiredServiceRoles: ServiceRoleDataSource;
  displayeRetireddColumns: Array<string>;
  filterForm: UntypedFormGroup;
  serviceRoleDataSource: ServiceRoleDataSource;
  employees: Employee[];
  projectControls: any;
  sessionInfo: any;
  sessionStorageKey: string;

  constructor(private service: ServiceRoleService, private dialog: MatDialog, private fb: UntypedFormBuilder, private adminService: AdminService,
    private projectControlService: ProjectControlService, private sessionStorageService: SessionStorageService) { }

  ngOnInit() {
    this.displayedColumns = [
      'serviceRoleName',
      'projectControl',
      'serviceRoleHourlyRate',
      'serviceRoleDescription',
      'service',
      'createdBy',
      'serviceRoleActions'
    ];
    this.displayeRetireddColumns = [
      'serviceRoleName',
      'projectControl',
      'serviceRoleHourlyRate',
      'serviceRoleDescription',
      'createdBy'
    ];
    this.filterForm = this.fb.group({
      roleName: [''],
      roleStatus: [0],
      createdBy: [''],
      projectControl: ['']
    });
    this.sessionStorageKey = 'serviceRolesData';
    let storageData = this.sessionStorageService.fetchStorageData(this.sessionStorageKey);
    if (storageData) {
      this.sessionInfo = this.sessionStorageService.parseStorageData(storageData);
    } else {
      this.sessionInfo = this.filterForm.value;
      this.sessionStorageService.setStorageData(this.sessionStorageKey, this.filterForm.value);
    }
    this.onFormValueChange();
    this.getProjectControlsAndEmployees();
    this.getServiceRoles();
  }

  getProjectControlsAndEmployees() {
    let employees = this.adminService.getAllEmployees();
    let projectControls = this.projectControlService.getProjectControls();
    forkJoin([employees, projectControls])
      .subscribe(res => {
        this.employees = res[0];
        this.projectControls = res[1];
      });
  }

  onFormValueChange() {
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.serviceRoleDataSource = new ServiceRoleDataSource(this.serviceRoleDataSource.filter(res, this.serviceRoles));
        this.sessionInfo = this.filterForm.value;
        this.sessionStorageService.setStorageData(this.sessionStorageKey, this.sessionInfo);
        this.setServiceRoleData(this.serviceRoleDataSource.data);
      });
  }

  getServiceRoles() {
    this.service.getAllServiceRoles()
      .subscribe(res => {
        this.serviceRoles = res;
        this.setServiceRoleData(res);
        this.updateForm();
      });
  }

  setServiceRoleData(data: any) {
    this.serviceRoleDataSource = new ServiceRoleDataSource(data);
    this.serviceRoleDataSource.sort = this.sort;
    this.serviceRoleDataSource.paginator = this.paginator;
  }

  deleteServiceRole(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.service.deleteServiceRole(id)
            .subscribe(httpResult => {
              this.getServiceRoles();
            });

        }
      });
  }

  resetFilters() {
    this.filterForm.controls['roleName'].setValue('');
    this.filterForm.controls['createdBy'].setValue('');
    this.filterForm.controls['projectControl'].setValue('');
    this.filterForm.controls['roleStatus'].setValue(0);

  }

  updateForm() {
    this.filterForm.patchValue({
      roleName: this.sessionInfo['roleName'] ? this.sessionInfo['roleName'] : '',
      createdBy: this.sessionInfo['createdBy'] == null || this.sessionInfo['createdBy'].toString() == "" ? '' : this.sessionInfo['createdBy'],
      projectControl: this.sessionInfo['projectControl'] == null || this.sessionInfo['projectControl'].toString() == "" ? '' : this.sessionInfo['projectControl'],
      roleStatus: this.sessionInfo['roleStatus'] == null || this.sessionInfo['roleStatus'].toString() == "" ? '' : this.sessionInfo['roleStatus']
    });
  }

  getDisplayedColumns() {
    return this.filterForm.controls['roleStatus'].value == 0 ? this.displayedColumns : this.displayeRetireddColumns;
  }

  getserviceEstimateType(estimateType: number){
    switch (estimateType) {
      case 0:
        return 'Fixed Fee';
      case 1:
        return 'Time & Materials';
      case 2:
        return 'Misc';
      case 3:
        return 'Managed Services';
      default: return 'Not Found';
    }
  }

}
