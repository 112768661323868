import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { ApproverThresholdService } from '../approver-threshold.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ServiceRoleService } from '../../service-role/service-role.service';
import { GraphApiService } from '../../../shared/services/graph-api.service';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-threshold-create',
  templateUrl: './threshold-create.component.html',
  styleUrls: ['./threshold-create.component.scss']
})
export class ThresholdCreateComponent implements OnInit {
  thresholdform: UntypedFormGroup;
  employees: any[];
  threshHoldId: any;
  serviceRoleArray: any[];
  selectedServiceRole: any;
  serviceRoles: any[];
  constructor(private fb: UntypedFormBuilder, private emplopyeeService: AdminService, private thesService: ApproverThresholdService, private location: Location, private route: ActivatedRoute, private service: ServiceRoleService, private graphApiService: GraphApiService) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {

        this.service.getServiceRoles()
          .subscribe(res => {
            this.serviceRoles = res;
            if (params['id']) {
              this.threshHoldId = params['id'];
            } else {
              this.thresholdform = this.fb.group({
                'amount': [null, Validators.required],
                'employeeId': [null, Validators.required]
              });
            }
            this.getThresholdData();
          });;

      });
  }
  getThresholdData() {
    let fetchEmployees = this.emplopyeeService.getEmployees();
    let fetchAdUsers = this.graphApiService.getUsers();
    let fetchThreshold = this.threshHoldId ? this.thesService.getThreshold(this.threshHoldId) : of(null);
    forkJoin([fetchEmployees, fetchAdUsers, fetchThreshold]).subscribe(results => {
      let azAdEmployees = results[1].filter(user => user.givenName != null && user.surname != null && user.accountEnabled != false);
      this.employees = results[0].filter(emp => azAdEmployees.find(azUser => azUser.id == emp.azureAdId));
      if (this.threshHoldId) {
        const threshHold = results[2];
        this.serviceRoles.forEach(sr => {
          threshHold.approverThreshHoldsServiceRoles.forEach((at: any) => {
            if (sr.serviceRoleId === at.serviceRoleId) {
              if (!this.serviceRoleArray) {
                this.serviceRoleArray = [];
              }
              this.serviceRoleArray.push(sr);
            }
          });
        });
        let isActiveEmployee = this.employees.map(apr => apr.employeeId).indexOf(threshHold.employeeId) != -1 ? true : false;
        this.thresholdform = this.fb.group({
          'id': [threshHold.id],
          'amount': [threshHold.amount, Validators.required],
          'employeeId': [isActiveEmployee? threshHold.employeeId: null, Validators.required]
        });
      }
    });
  }
  submitTHeshold() {
    if (this.thresholdform.valid) {
      if (this.threshHoldId) {
        this.thesService.updateThreshold(this.thresholdform.value)
          .subscribe(res => {
            this.location.back();

          });
      } else {
        this.thesService.createThreshold(this.thresholdform.value)
          .subscribe(res => {
            this.location.back();

          });
      }
    }
  }
  addServiceRole() {
    if (!this.serviceRoleArray) {
      this.serviceRoleArray = [];
    }
    console.log(this.selectedServiceRole);
    const serviceRoleThresh = {
      'approverThresholdId': this.threshHoldId,
      'serviceRoleId': this.selectedServiceRole
    }
    this.thesService.addThreshServiceRole(serviceRoleThresh)
      .subscribe(res => {
        this.serviceRoleArray.push(this.serviceRoles.find(sr => sr.serviceRoleId == this.selectedServiceRole));

      });
  }
  removeServiceRole(index: any) {
    const obj = this.serviceRoleArray[index];
    this.thesService.removeThreshServiceRole(this.threshHoldId, obj.serviceRoleId)
      .subscribe(res => {
        this.serviceRoleArray.splice(index, 1);

      });
  }

}
