import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../admin/admin.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FleetMangementService } from '../../fleet-mangement.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicle-driver-create',
  templateUrl: './vehicle-driver-create.component.html',
  styleUrls: ['./vehicle-driver-create.component.scss']
})
export class VehicleDriverCreateComponent implements OnInit {
  driver: any;
  employees: Array<any>;
  driverForm: UntypedFormGroup;

  constructor(private employeeService: AdminService, private fleetService: FleetMangementService, private router: Router, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.driverForm = this.fb.group({
      employeeId: ['', Validators.required]
    });
    this.driver = {};
    this.employeeService.getEmployees()
      .subscribe(employeeResult => {
        this.employees = employeeResult;
        this.fleetService.getDrivers()
          .subscribe(res => {
            this.employees.forEach(emp => {
              const drivers: Array<any> = res;
              drivers.forEach(driver => {
                if (driver.employeeId == emp.employeeId) {
                  var index = this.employees.indexOf(emp);
                  this.employees.splice(index, 1);
                }
              });
            });
          });
      });

  }
  onSubmit() {
    const selectedEmployee = this.employees.filter(emp => emp.employeeId === this.driver.employeeId)[0];
    this.driver.firstName = selectedEmployee.firstName;
    this.driver.lastName = selectedEmployee.lastName;
    this.fleetService.createDriver(this.driver)
      .subscribe(createResult => {
        this.router.navigate(['/fleetmanagement', 'drivers']);

      });
  }

}
