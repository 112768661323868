<div>
  <a mat-raised-button color="primary" id="portalEup"
     href="https://mitfleetmanagementhot.blob.core.windows.net/portaleup/Usermanual _ MUP Portal.pdf"
     target="_blank" matTooltip="Click to Preview"
     [matTooltipPosition]="'below'">
    <mat-icon class="visibility">visibility</mat-icon>
    Portal EUP
  </a>&emsp;
  <a mat-raised-button color="primary" id="fleetMngmtEup"
     href="https://mitfleetmanagementhot.blob.core.windows.net/portaleup/Portal EUP - Fleet Management Admin 20190418.pdf"
     target="_blank" matTooltip="Click to Preview"
     [matTooltipPosition]="'below'">
    <mat-icon class="visibility">visibility</mat-icon>
    Fleet Management EUP
  </a>
</div>
