import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FleetMangementService } from '../../fleet-mangement.service';

@Component({
  selector: 'app-vehicle-aging-report',
  templateUrl: './vehicle-aging-report.component.html',
  styleUrls: ['./vehicle-aging-report.component.scss']
})
export class VehicleAgingReportComponent implements OnInit {

  @ViewChild('donwloadCsvLink', { static: true }) donwloadCsvLink: ElementRef;

  constructor(private service: FleetMangementService) { }

  ngOnInit() {
  }
  runReport() {
    this.service.runAgingReport()
      .subscribe(res => {
          const reportResult: Array<any> = res;
          if (reportResult.length > 0) {
            const fileText = this.service.convertToCsv(JSON.stringify(reportResult));
            const blob = new Blob([fileText], {
              type: 'text/csv'
            });
            const url = window.URL.createObjectURL(blob);
            const link = this.donwloadCsvLink.nativeElement;
            link.href = url;
            link.download = 'agingReport.csv';
            link.click();
            window.URL.revokeObjectURL(url);
          }
        
      });
  }

}
