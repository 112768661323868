import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicle-maintenance-schedule',
  templateUrl: './vehicle-maintenance-schedule.component.html',
  styleUrls: ['./vehicle-maintenance-schedule.component.scss']
})
export class VehicleMaintenanceScheduleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
