import { AfterViewInit, Component, OnInit, ViewChild, Output, EventEmitter, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ClientsListDataSource, ClientsListItem } from '../clients-list/clients-list-datasource';
import { ClientsService } from '../clients.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'clients-list-url-filtering',
  templateUrl: './clients-list-url-filtering.component.html',
  styleUrls: ['./clients-list-url-filtering.component.css']
})
export class ClientsListUrlFilteringComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ClientsListItem>;
  @Output() selectedClient: EventEmitter<string> = new EventEmitter<string>();
  filterForm: UntypedFormGroup;
  dataSource: ClientsListDataSource;
  loading: boolean;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'commonName', 'legalName'];
  constructor(private clientsService: ClientsService, private fb: UntypedFormBuilder, public dialogRef: MatDialogRef<ClientsListUrlFilteringComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.loading = true;
    this.filterForm = this.fb.group({
      clientsCommonName: [],
      clientLegalName: [],
      clientType: [],
      customerId: []
    });
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        if (res) {
          this.getClients(res);
        }
      });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.getClients();
    });
  }
  getClients(filter?: any) {
    this.clientsService.getClientsWithFilter(filter)
      .pipe(tap(() => {
        this.loading = true;
      }))
      .subscribe(clientsResult => {
        this.dataSource = new ClientsListDataSource(clientsResult);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.table.dataSource = this.dataSource;
      },
        (error) => {

        }, () => {
          this.loading = false;
        });
  }
  selectClient(clientId: any) {
    this.dialogRef.close({ clientId });

  }
}
