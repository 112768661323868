import { Component, OnInit, ViewChild } from '@angular/core';
import { VehicleDataSource } from '../../models/vehicle-datasource';
import { FleetMangementService } from '../../fleet-mangement.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { Employee } from '../../../admin/models/employee';
import { AdminService } from '../../../admin/admin.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { AssignDriversPopupComponent } from 'src/app/shared/components/assign-drivers-popup/assign-drivers-popup.component';

@Component({
  selector: 'app-vehicles-list',
  templateUrl: './vehicles-list.component.html',
  styleUrls: ['./vehicles-list.component.scss']
})
export class VehiclesListComponent implements OnInit {
  dataSource: VehicleDataSource;
  employees: Array<Employee>;
  isNameAscending: boolean;
  vehiclesData: Array<any>;
  pagination: any;
  pageSize: number;
  displayedColumns = [
    'vehicleNumber',
    'vehicleVin',
    'licensePlate',
    'vehicleIsActive',
    'assignedDriver',
    'vehicleId'
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private service: FleetMangementService, public dialog: MatDialog, private adminService: AdminService) { }

  ngOnInit() {
    this.pageSize = 50;
    this.isNameAscending = true;
    this.service.getVehicles()
      .subscribe(res => {
        this.service.getAllDrivers()
          .subscribe(employeesResponse => {
            this.employees = employeesResponse;
            this.vehiclesData = res.sort((a, b) => (a.vehicleNumber < b.vehicleNumber ? -1 : 1) * 1);
            this.setTableData();
          });
      });
  }
  deleteVehicle(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.service.deleteVehicle(id)
            .subscribe(res => {
              this.service.getVehicles()
                .subscribe(res2 => {
                  this.vehiclesData = res2.sort((a, b) => (a.vehicleNumber < b.vehicleNumber ? -1 : 1) * 1);;
                  this.setTableData(this.vehiclesData);
                });
            });
        }
      });
  }
  unassignVehicle(vehicleId: string) {
    this.service.unassignVehicleById(vehicleId)
      .subscribe(() => {
        this.service.getVehicles()
          .subscribe(res2 => {
            this.vehiclesData = res2.sort((a, b) => (a.vehicleNumber < b.vehicleNumber ? -1 : 1) * 1);;
            this.setTableData(this.vehiclesData);
          })
      });
  }
  reassignVehicle(vehicleId: string) {
    const dialogRef = this.dialog.open(AssignDriversPopupComponent, {
      width: '470px',
      height: '235px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.service.reassignVehicleById(vehicleId, result.assignedEmployeeId)
            .subscribe(() => {
              this.service.getVehicles()
                .subscribe(res2 => {
                  this.vehiclesData = res2.sort((a, b) => (a.vehicleNumber < b.vehicleNumber ? -1 : 1) * 1);;
                  this.setTableData(this.vehiclesData);
                });
            });
        }
      });
  }

  filterEmployee(employeeId: string): Employee | undefined {
    const employee = this.employees.filter(_employee => _employee.vehicleDriverId === employeeId)[0];
    if (employee) {
      return employee;
    } else {
      return;
    }
  }
  sortByvehicleNumber() {
    this.isNameAscending = !this.isNameAscending;
    let startIndex = this.pagination ? this.pagination.pageIndex * this.pagination.pageSize : 0;
    let endIndex = this.pagination ? (this.pagination.pageIndex * this.pagination.pageSize) + this.pagination.pageSize : this.pageSize;
    if (this.vehiclesData) {
      if (this.isNameAscending) {
        this.setTableData(this.vehiclesData.sort((a, b) => {
          const nameOne = a.vehicleNumber;
          const nameTwo = b.vehicleNumber;
          if (nameOne < nameTwo) { return -1; }
          if (nameOne > nameTwo) { return 1; }
          return 1;
        })?.slice(startIndex, endIndex));
      } else {
        this.setTableData(this.vehiclesData.sort((a, b) => {
          const nameOne = a.vehicleNumber;
          const nameTwo = b.vehicleNumber;
          if (nameOne < nameTwo) { return 1; }
          if (nameOne > nameTwo) { return -1; }
          return 1;
        })?.slice(startIndex, endIndex));
      }
    }
  }
  setTableData(data?: Array<any>) {
    this.dataSource = new VehicleDataSource();
    let pageAmount = 50;
    if (!data) {
      if (this.pagination) {
        pageAmount = this.pagination.pageSize;
      }
      const initData = this.vehiclesData?.slice(0, pageAmount);
      this.dataSource.setData(initData);
    } else {
      this.dataSource.setData(data);
    }
  }
  pageChangedEventVehicles(event: any) {
    this.pagination = event;
    this.pageSize = event.pageSize;
    const vehicles = this.vehiclesData?.slice(event.pageIndex * event.pageSize, (event.pageIndex * event.pageSize) + event.pageSize);

    this.setTableData(vehicles);
  }
}
