import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { CloudMigrationToolAdminService } from '../cloud-migration-tool-admin.service';

@Component({
  selector: 'app-cloud-provider-pricing',
  templateUrl: './cloud-provider-pricing.component.html',
  styleUrls: ['./cloud-provider-pricing.component.scss'],
})
export class CloudProviderPricingComponent implements OnInit {
  formTitle: string;
  pricingRequestForm: UntypedFormGroup;
  pricingScheduleId: any;
  pricing: any;
  message: any;
  pricingGenerated: boolean;
  cloudProviderId: any;
  operatingSystemOptions: any;
  osReleaseOptions : any;
  
  constructor(
    private service: CloudMigrationToolAdminService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CloudProviderPricingComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    // console.log('pricing data: ', this.data);
    this.pricingRequestForm = this.createFormGroup(this.data);
    this.operatingSystemOptions = this.data?.hostedSystemOptions;
    this.osReleaseOptions = this.data?.osReleaseOptions;
    this.formTitle = 'Pricing Demo';
    this.pricingScheduleId = this.data?.id;
    this.cloudProviderId = this.data?.cloudProviderId;
  }

  createFormGroup(data: any): UntypedFormGroup {
    if (data) {
      console.log('data: ', data);
      let osReleaseData = data.osRelease;
      
      console.log('osReleaseData: ', osReleaseData);
      
      return this.fb.group({
        processors: new UntypedFormControl(data?.processors),
        mainStorage: new UntypedFormControl(data?.mainStorage),
        osLevel: new UntypedFormControl(data?.osLevel),
        osRelease: new UntypedFormControl(osReleaseData),
        osType: new UntypedFormControl(''),
        diskSpace: new UntypedFormControl(Number(data?.diskSpace)),
      });
    }
    return this.fb.group({
      processors: [''],
      mainStorage: [''],
      osLevel: [''],
      osRelease: [''],
      diskSpace: [''],
    });
  }

  price() {
    var formVal = this.pricingRequestForm.value;
    const payload = {
      processors: formVal.processors,
      mainStorage: formVal.mainStorage,
      operatingSystem: formVal.osType,
      osLevel: formVal.osLevel,
      osRelease: formVal.osRelease,
      diskSpace: formVal.diskSpace,
    };

    var data = {
      cloudProviderId: this.cloudProviderId,
      pricingScheduleId: this.pricingScheduleId,
      payload: payload,
    };

    console.log('Pricing Request: ', data);

    this.service.getPricing(data).then((res: any) => {
      this.pricing = res.data.result;
      this.message = res.data.message;
      this.pricingGenerated = res.data.success;
    });
  }

  save() {
    this.pricing = this.pricingRequestForm.value;
    let request = {
      pricingScheduleId: this.pricingScheduleId,
      payload: this.pricing,
    };
    this.service.getPricing(request).then((res: any) => {
      this.pricing = res.data.result;
      this.message = res.data.message;

    });
  }
  close() {
    this.dialogRef.close(this.pricingRequestForm.value);
  }
}
