import { Component, OnInit } from '@angular/core';
import { VehicleModel } from '../../../models/fleet-management-models';
import { FleetMangementService } from '../../../fleet-mangement.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-vehicle-models-update',
  templateUrl: './vehicle-models-update.component.html',
  styleUrls: ['./vehicle-models-update.component.scss']
})
export class VehicleModelsUpdateComponent implements OnInit {
  model: VehicleModel;
  modelForm: UntypedFormGroup;

  constructor(private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, private service: FleetMangementService, private location: Location) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        const id = params['id'];
        this.service.getVehicleModelByModelId(id)
          .subscribe(result => {
            this.model = result;
            this.modelForm = this.fb.group({
              vehicleModelId: [this.model.vehicleModelId],
              manufacturerName: [this.model.manufacturerName, Validators.required],
              vehicleModelName: [this.model.vehicleModelName, Validators.required],
              vehcileModelYear: [this.model.vehcileModelYear, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(4), Validators.minLength(4)]]
            });

          });
      });
  }
  updateModel() {
    this.service.updateVehicleModel(this.model)
      .subscribe(modelResult => {
          this.location.back();
        
      });
  }
  cancelUpdate() {
    this.location.back();
  }

}
