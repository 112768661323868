<h2 mat-dialog-title>{{copyTo? copyTo : ("Clone " + popupFor)}}</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Enter Unique Name</mat-label>
    <input required matInput type="text" [(ngModel)]="data['clonedName']" #clonedName="ngModel">
    <mat-error *ngIf="clonedName.hasError('required') && !isNotUnique">
      An Unique {{popupFor}} Name is required.
    </mat-error>
  </mat-form-field>
  <div class="error" *ngIf="isNotUnique">
    Alert: Cloned {{popupFor}} Name should be unique.
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button color="primary" [disabled]="!clonedName.valid" (click)="checkClonedNameExists()">Save</button>
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
