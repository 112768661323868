import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ClientsListDataSource, ClientsListItem } from './clients-list-datasource';
import { ClientsService } from '../clients.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AccountInfo } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from 'src/app/shared/models/id-token-claims';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<ClientsListItem>;
  filterForm: UntypedFormGroup;
  dataSource: ClientsListDataSource;
  fullData: any[];
  loading: boolean;

  currentUser: AccountInfo | null;
  isReadOnly: boolean;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'commonName', 'legalName', 'actions'];
  constructor(private clientsService: ClientsService, private fb: UntypedFormBuilder, private authService: MsalService) {

  }

  ngOnInit() {
    this.currentUser = this.authService.instance.getActiveAccount();
    this.isReadOnly = true;
    this.roleCheckFunction();
    this.loading = true;
    this.getClients();
    this.filterForm = this.fb.group({
      epicorIdFilter: [],
      commonNameFilter: [],
      legalNameFilter: []
    });
    this.filterForm.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(res => {
        this.dataSource = new ClientsListDataSource(this.dataSource.filter(res, this.fullData));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.table.dataSource = this.dataSource;
      });
  }
  getClients() {
    this.clientsService.getClients()
      .subscribe(clientsResult => {
        this.fullData = clientsResult;
        this.dataSource = new ClientsListDataSource(clientsResult);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.table.dataSource = this.dataSource;
      },
        (error) => {

        }, () => {
          this.loading = false;
        });

  }
  roleCheckFunction() {
    let idTokenClaims = <IdTokenClaims | undefined>this.currentUser?.idTokenClaims;
    if (idTokenClaims) {
      const roles = idTokenClaims['roles'];
      let updatedRoles: string[];
      if (typeof roles === 'string') {
        updatedRoles = [
          roles
        ];
      } else {
        updatedRoles = roles;
      }
      updatedRoles.forEach(r => {
        if (r.includes('PortalAdmin')) {
          this.isReadOnly = false;
        } if (r.includes('PortalSEDirector')) {
          this.isReadOnly = false;
        } if (r.includes('ProfessionalServiceCoordinator')) {
          this.isReadOnly = false;
        }
      });
    }
  }

}
