import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { forkJoin } from 'rxjs';
import { BusinessPartner } from '../../../business-partners/models/bp-model';
import { BusinessPartnerDataSource } from '../../../business-partners/models/business-partner-datatable.model';
import { Tag } from '../../../tags/models/tag.model';
import { TagsService } from '../../../tags/tags.service';
import { FilterByStateIdPipe } from '../../pipes/filter-by-state-id.pipe';
import { GeoFencingService } from '../../services/geo-fencing.service';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-business-partner-popup',
  templateUrl: './business-partner-popup.component.html',
  styleUrls: ['./business-partner-popup.component.scss']
})
export class BusinessPartnerPopupComponent implements OnInit {

  @Input() businessPartnerDataSource: BusinessPartnerDataSource;
  @Input() filteredPartners: Array<BusinessPartner>;
  @Input() states: Array<any>;
  @Input() tags: Array<Tag>;
  @Input() isGeoFence: boolean;
  @Input() isPopup: boolean;
  @Input() isBPDataLoading: boolean;
  @Input() geoFencedCities: Array<any>;
  //@Input() cities: Array<any>;
  @Output() selectedPartner: EventEmitter<string> = new EventEmitter<string>();
  businessPartnerBpNameOrderIsAsc: boolean;
  businessPartnerStateNameOrderIsAsc: boolean;
  businessPartnerCountryNameOrderIsAsc: boolean;
  displayedColumns: Array<any>;
  hasReadOnlyAccess: boolean;
  user: AccountInfo | null;

  constructor(private filterByStateIdPipe: FilterByStateIdPipe,
    private geoFencingService: GeoFencingService,
    private tagsService: TagsService,
    private roleService: RoleService,
    private authService: MsalService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.user = this.authService.instance.getActiveAccount();
    this.businessPartnerBpNameOrderIsAsc = false;
    this.businessPartnerStateNameOrderIsAsc = false;
    this.businessPartnerCountryNameOrderIsAsc = false;
    this.hasReadOnlyAccess = this.hasRole('FieldServiceCoordinator') || this.hasRole('ProfessionalServiceCoordinator');
    this.displayedColumns = [
      { def: 'BpName', hide: false },
      { def: 'Country', hide: false },
      { def: 'PrimaryState', hide: !this.isGeoFence },
      { def: 'PrimaryLocation', hide: !this.isGeoFence },
      { def: 'ServicableState', hide: false },
      { def: 'City', hide: !this.isGeoFence },
      { def: 'CityName', hide: this.isGeoFence },
      { def: 'Miles', hide: !this.isGeoFence },
      { def: 'overallRating', hide: false },
      { def: 'Status', hide: false },
      { def: 'TagName', hide: false },
      { def: 'AgreementType', hide: false },
      { def: 'Actions', hide: false },
    ];
    this.getStateTagData();

  }

  getDisplayedColumns(): string[] {
    return this.displayedColumns.filter(dc => !dc.hide).map(dc => dc.def);
  }

  getStateTagData() {
    if (this.isGeoFence) {
      let states = this.geoFencingService.getStates();
      let tags = this.tagsService.getTags();
      forkJoin([states, tags])
        .subscribe(results => {
          this.states = results[0];
          this.tags = results[1];
        }, (error) => {

        });
    }

  }

  getCountryName(country: any) {
    return country && country.toLowerCase() != "usa";
  }

  getAgreementType(agreementType: any) {
    switch (agreementType) {
      case 0:
        return 'Subcontractor Agreement';
      case 1:
        return 'Reverse Subcontractor Agreement';
      case 2:
        return 'Non-Disclosure Agreement';
      case 3:
        return 'Mutual Non-Disclosure Agreement';
      case 4:
        return 'Reseller Agreement';
      case 5:
        return 'Partner Agreement';
      case 6:
        return 'Sub Provided Agreement';
      case 7:
        return 'Other Agreement';
      default: return 'Not Found';
    }
  }

  closePopup(data: any) {
    this.selectedPartner.emit(data);
  }

  countrySortBy() {
    let order: string;
    this.businessPartnerCountryNameOrderIsAsc = !this.businessPartnerCountryNameOrderIsAsc;
    if (this.businessPartnerCountryNameOrderIsAsc) {
      order = 'asc';
    } else {
      order = 'desc';
    }
    this.filteredPartners.sort(this.compareCountryValues(order));
    const partners = this.filteredPartners;
    this.businessPartnerDataSource = new BusinessPartnerDataSource();
    this.businessPartnerDataSource.setData(partners);
  }
  stateSortBy() {
    const category = 'serviceableStates';
    let order: string;
    this.businessPartnerStateNameOrderIsAsc = !this.businessPartnerStateNameOrderIsAsc;
    if (this.businessPartnerStateNameOrderIsAsc) {

      order = 'asc';
    } else {
      order = 'desc';
    }
    this.filteredPartners.sort(this.compareStateValues(category, order));
    const partners = this.filteredPartners;
    this.businessPartnerDataSource = new BusinessPartnerDataSource();
    this.businessPartnerDataSource.setData(partners);
  }
  nameSortBy(category: string) {
    let order: string;
    this.businessPartnerBpNameOrderIsAsc = !this.businessPartnerBpNameOrderIsAsc;
    if (this.businessPartnerBpNameOrderIsAsc) {
      order = 'asc';
    } else {
      order = 'desc';
    }
    this.filteredPartners.sort(this.compareValues(category, order));
    const partners = this.filteredPartners;
    this.businessPartnerDataSource = new BusinessPartnerDataSource();
    this.businessPartnerDataSource.setData(partners);
  }
  compareValues(key: any, order = 'asc') {
    return (a: any, b: any) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order == 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  compareStateValues(key: any, order = 'asc') {
    return (a: any, b: any) => {

      let varA = this.filterByStateIdPipe.transform(this.states, a.serviceableStates);
      let varB = this.filterByStateIdPipe.transform(this.states, b.serviceableStates);

      if (varA.length > 0) {
        let states: Array<any> = [];
        varA.forEach((item: any) => {
          states.push(item.name);
        })
        varA = states.join(",");
      } else {
        varA = 'z';
      }

      if (varB.length > 0) {
        let states: Array<any> = [];
        varB.forEach((item: any) => {
          states.push(item.name);
        })
        varB = states.join(",");
      } else {
        varB = 'z';
      }

      let comparison = 0;
      if (varA.toUpperCase() > varB.toUpperCase()) {
        comparison = 1;
      } else if (varA.toUpperCase() < varB.toUpperCase()) {
        comparison = -1;
      }
      return (
        (order == 'desc') ? (comparison * -1) : comparison
      );
    };
  }
  compareCountryValues(order = 'asc') {
    return (a: any, b: any) => {
      let varA; let varB;
      let countries: Array<any> = [];
      a.locations.forEach((item: any) => {
        countries.push(item.country ? item.country : "usa");
      })
      varA = countries.join(",");

      countries = [];
      b.locations.forEach((item: any) => {
        countries.push(item.country ? item.country : "usa");
      })
      varB = countries.join(",");

      let comparison = 0;
      if (varA.toUpperCase() > varB.toUpperCase()) {
        comparison = 1;
      } else if (varA.toUpperCase() < varB.toUpperCase()) {
        comparison = -1;
      }
      return (
        (order == 'desc') ? (comparison * -1) : comparison
      );
    };
  }
  viewBp(id: string) {
    this.closePopup(id);
    this.router.navigate(['/partners/businessPartners', 'view', id]);
  }
  navigateToBp(id: string) {
    this.hasReadOnlyAccess ? this.router.navigate(['view', id], { relativeTo: this.route }) : this.router.navigate(['edit', id], { relativeTo: this.route });
  }
  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, true);
  }

}
