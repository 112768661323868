import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from '../shared/models/id-token-claims';

@Component({
  selector: 'app-fleet-management',
  templateUrl: './fleet-management.component.html',
  styleUrls: ['./fleet-management.component.scss']
})
export class FleetManagementComponent implements OnInit {
  roles: string[] | string;
  constructor(private authService: MsalService) { }

  ngOnInit() {
    let idTokenClaims = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
    if (idTokenClaims) {
      this.roles = idTokenClaims['roles'];
    }
  }
  isAdmin() {
    const isVehicleAdmin = this.roles.includes('FleetMangementAdmin');
    if (isVehicleAdmin) {
      return true;
    } else {
      return this.roles.includes('PortalAdmin');
    }

  }
  isUser() {
    this.roles.includes('FleetManagementUser');
  }
  isInsuranceAdmin() {
    return this.roles.includes('FleetManagementInsuranceAdmin');
  }

}
