import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Tag } from '../models/tag.model';
import { TagsService } from '../tags.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-tags-create',
  templateUrl: './tags-create.component.html',
  styleUrls: ['./tags-create.component.scss']
})
export class TagsCreateComponent implements OnInit {

  tagFormGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private tagsService: TagsService, private location: Location) { }

  ngOnInit(): void {
    this.tagFormGroup = this.fb.group({
      'name': ['', Validators.required]
    });
  }

  submitForm() {
    if (this.tagFormGroup.valid) {
      const _tag: Tag = this.tagFormGroup.value;      
      this.tagsService.createTag(_tag)
        .subscribe(createResult => {
          this.location.back();

        },
          (error) => {
            
          });
    }
  }
  goBack() {
    this.location.back();
  }

}
