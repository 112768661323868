import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import { Assessment } from '../../models/assessment-datasource';
import { UntypedFormBuilder } from '@angular/forms';
import { PricingFormData } from './cloud-migration-pricing-form/cloud-migration-pricing-form.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CloudMigrationContractPreviewComponent } from '../cloud-migration-contract-preview/cloud-migration-contract-preview.component';

@Component({
  selector: 'app-cloud-migration-assessment-detail',
  templateUrl: './cloud-migration-assessment-detail.component.html',
  styleUrls: ['./cloud-migration-assessment-detail.component.scss'],
})
export class CloudMigrationAssessmentDetailComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  resetForms: EventEmitter<null> = new EventEmitter<null>();
  pricingChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  pricingFormUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();
  assessmentDetailResetEvent: EventEmitter<any> = new EventEmitter<any>();

  //Flags Pricing
  platformCostUpdated: boolean;
  migrationCostUpdated: boolean;
  dataTransferCostUpdated: boolean = false;
  managedServiceCostUpdated: boolean = false;
  totalRecurringCostUpdated: boolean;
  totalOneTimeCostUpdated: boolean;

  //Network Summary Flags
  networkBandwidthUpdated: boolean = false;
  networkCostUpdated: boolean = false;
  //Storage Summary Flags
  storageCapacityUpdated: boolean = false;
  storageTypeUpdated: boolean = false;
  storageCostUpdated: boolean = false;

  //Initial Pricing, Network, Bandwidth
  initialPlatformCost = 0;
  initialMigrationCost: any;
  initialDataTransferCost: any;
  initialManagedServiceCost: any;
  initialTotalRecurringCost: any;
  initialTotalOneTimeCost: any;
  initialNetworkBandwidth: any;
  initialNetworkCost: any;
  initialStorageCapacity: any;
  initialStorageType: string;
  initialStorageCost: any;

  assessmentId: string;
  loading: boolean;
  assessment: Assessment;
  pricingSummaryData: PricingSummaryData;
  storageSummaryData: StorageSummaryData;
  networkSummaryData: NetworkSummaryData;
  priceSummaryRow: any;

  regions: any;
  targetEnvironmentSummary: any;
  updatedAssessmentItems: Array<any> = new Array<any>();
  matchedPlatformPricingUpdated: boolean = false;

  migrationCost: number = 0;
  selectedServiceEstimateId?: string;
  selectedNetworkPerformanceProfileId?: string;
  defaultStorageAmount = 0;
  dataTransferTime: any;
  selectedVpnProvider: string;
  serviceEstimateUpdated: boolean;
  regionUpdated: boolean;
  requiredBandwidthUpdated: boolean;
  licenseCostUpdated: boolean;
  clientName: any;
  currentUser: any;
  accountManager: any;
  priceSummaryOneTimeRow: any;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private cloudMigrationService: CloudMigrationToolService
  ) {}

  ngOnInit(): void {
    this.refreshView();
    this.loading = false;
  }

  refreshView() {
    this.loading = true;
    this.route.params.subscribe((params) => {
      if (params['assessmentId']) {
        this.getAssessment(params['assessmentId']);
      }
    });
  }

  getAssessment(id: string) {
    this.cloudMigrationService.getAssessment(id).subscribe(
      (res) => {
        if (res.serviceEstimateId) {
          this.selectedServiceEstimateId = res.serviceEstimateId;
        }

        this.setAssestmentValues(res);
        this.setInitialValues(res);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  setAssestmentValues(assessment: Assessment) {
    //Keep initial Pricing Row Values

    this.priceSummaryRow =
      this.priceSummaryRow ?? assessment.pricingSummary.rows[0];
    this.pricingSummaryData = {
      pricingSummaryRowData: assessment.pricingSummary.rows,
      displayedColumns: assessment.pricingSummary.displayedColumns,
    };
    this.priceSummaryOneTimeRow =
      this.priceSummaryRow ?? assessment.pricingSummary.rows[1];
    this.pricingSummaryData = {
      pricingSummaryRowData: assessment.pricingSummary.rows,
      displayedColumns: assessment.pricingSummary.displayedColumns,
    };

    this.storageSummaryData = {
      storageSummaryRows: assessment.storageSummary.rows,
      displayedColumns: assessment.storageSummary.displayedColumns,
    };
    this.networkSummaryData = {
      networkSummaryRows: assessment.networkSummary.rows,
      displayedColumns: assessment.networkSummary.displayedColumns,
    };

    this.assessment = assessment;
    this.assessmentId = assessment.id;
    this.assessment.pricingSummary.rows[0].migration = this.migrationCost;
    this.selectedServiceEstimateId = this.assessment.serviceEstimateId;
    this.selectedNetworkPerformanceProfileId =
      this.assessment.networkProfileId;
    this.dataTransferTime = this.assessment.dataTransferTimeMinutes;
    this.targetEnvironmentSummary = this.assessment.storageSummary.rows[1];
    this.defaultStorageAmount = this.assessment.storageSummary.rows[1].capacity;
    this.selectedVpnProvider = this.assessment.vpnProvider;

    this.loading = false;
  }

  setInitialValues(assessment: Assessment) {
    //Pricing Summary Initial Values
    //Recurring
    const pricingSummaryRecurring = assessment.pricingSummary.rows[0];
    this.initialPlatformCost = pricingSummaryRecurring.platform;
    this.initialManagedServiceCost = pricingSummaryRecurring.managedServices;
    this.initialTotalRecurringCost = pricingSummaryRecurring.cost;
    
    //One-Time
    const pricingSummaryOneTime = assessment.pricingSummary.rows[1];
    this.initialDataTransferCost = pricingSummaryOneTime.dataTransfer;
    this.initialMigrationCost = pricingSummaryOneTime.migrationCost;
    this.initialTotalOneTimeCost = pricingSummaryOneTime.cost;
    
    //Network Summary Initial Values
    const targetNetworkSummary = assessment.networkSummary.rows[1];
    this.initialNetworkBandwidth = targetNetworkSummary.bandwidth;
    this.initialNetworkCost = targetNetworkSummary.cost;

    //Network Summary Initial Values
    const targetStorageSummary = assessment.storageSummary.rows[1];
    this.initialStorageCapacity = targetStorageSummary.capacity;
    this.initialStorageType = targetStorageSummary.type;
    this.initialStorageCost = targetStorageSummary.cost;
  }

  /** Event raised form Pricing Form Component */
  onPricingFormUpdated(event: PricingFormData) {
   if(!this.loading){
    //We need an assessment editor
    let formvalues: any;
    formvalues = {
      id: this.assessment.id,
      cloudProviderId: this.assessment.cloudProviderId,
      region: event.selectedRegion,
      paymentOption: this.assessment.paymentOption,
      managedServicePercent: event.managedServicePercent,
      storageAmount: event.storageAmount ?? 0,
      storageType: event.selectedStorageType,
      serviceEstimateId: event.selectedServiceEstimate,
      bandwidth: event.bandwidthAmount ?? 0,
      networkProfileId: event.selectedNetworkProfile,
      licenseCost: event.licenseCost ?? 0,
    };

    this.cloudMigrationService
      .updatePaymentInfo(formvalues)
      .subscribe((res: Assessment) => {
        this.assessment = res;
        this.setAssestmentValues(res);
        this.setFlags(res);
      });
    }
  }
  setFlags(res: Assessment) {
    //Pricing Summary Flags
    const recurringPricingSummary = res.pricingSummary.rows[0];
    const oneTimePricingSummary = res.pricingSummary.rows[1];
    
    this.platformCostUpdated =
      this.initialPlatformCost != recurringPricingSummary.platform;
    this.migrationCostUpdated =
      this.initialMigrationCost != oneTimePricingSummary.migrationCost;
    this.dataTransferCostUpdated =
      this.initialDataTransferCost != oneTimePricingSummary.dataTransfer;
    this.managedServiceCostUpdated =
      this.initialManagedServiceCost != recurringPricingSummary.managedServices;
    
      this.totalRecurringCostUpdated = this.initialTotalRecurringCost != recurringPricingSummary.cost;
      this.totalOneTimeCostUpdated = this.initialTotalOneTimeCost != oneTimePricingSummary.cost;

    //Network Summary Flags
    const targetNetworkSummary = res.networkSummary.rows[1];
    this.networkBandwidthUpdated =
      this.initialNetworkBandwidth != targetNetworkSummary.bandwidth;
    this.networkCostUpdated =
      this.initialNetworkCost != targetNetworkSummary.cost;

    //Storage Summary Flags
    const targetStorageSummary = res.storageSummary.rows[1];
    this.storageCapacityUpdated =
      this.initialStorageCapacity != targetStorageSummary.capacity;
    this.storageTypeUpdated =
      this.initialStorageType != targetStorageSummary.type;
    this.storageCostUpdated =
      this.initialStorageCost != targetStorageSummary.cost;
  }

  // //Manual Matching (Auto-Matching Override)
  updatePriceTotalForPlatformCostChange = (changedRow: any): void => {
    this.updatedAssessmentItems.push(changedRow);

    const platformCost =
      this.assessment.cloudSolutionDetails.rows.reduce(
        (platformCost: number, current: { pricePerHour: string }) =>
          platformCost + Number.parseFloat(current.pricePerHour),
        0
      ) * 730.0;

    const migrationCost = parseFloat(
      this.assessment.pricingSummary.rows[1].migrationCost
    );
    const managedServicesCost =
      this.assessment.managedServicePercent * platformCost;
    const totalCost = managedServicesCost;
    const totalRecurringCost = migrationCost + platformCost + managedServicesCost;

    //Platform Cost Updated
    this.platformCostUpdated =
      this.initialPlatformCost != parseFloat(platformCost.toFixed(3));
    this.matchedPlatformPricingUpdated =
      this.initialPlatformCost != parseFloat(platformCost.toFixed(3));
    this.assessment.pricingSummary.rows[0].platform = platformCost.toFixed(3);
    this.assessment.pricingSummary.rows[0].managedServices =
      managedServicesCost.toFixed(3);
    this.assessment.pricingSummary.rows[0].total = totalCost.toFixed(3);
  };

  resetValues() {
    this.refreshView();
    this.resetFlags();
    this.resetForms.emit();
  }

  resetFlags() {
    //Flags Pricing
    this.platformCostUpdated = false;
    this.migrationCostUpdated = false;
    this.dataTransferCostUpdated = false;
    this.managedServiceCostUpdated = false;
    this.totalOneTimeCostUpdated = false;
    this.totalRecurringCostUpdated = false;
    //Network Summary Flags
    this.networkBandwidthUpdated = false;
    this.networkCostUpdated = false;
    //Storage Summary Flags
    this.storageCapacityUpdated = false;
    this.storageTypeUpdated = false;
    this.storageCostUpdated = false;
    this.licenseCostUpdated = false;
  }

  savePricingUpdates = (): void => {
    let formvalues: any;
    formvalues = {
      region: this.assessment.region,
      paymentOption: this.assessment.paymentOption,
      managedServicePercent: this.assessment.managedServicePercent,
      storageAmount: this.targetEnvironmentSummary.capacity,
      storageType: this.targetEnvironmentSummary.type,
      assessmentItemMatchUpdates: this.updatedAssessmentItems,
      serviceEstimateId: this.assessment.serviceEstimateId,
      vpnRate: this.assessment.vpnRate,
      bandwidth: this.assessment.bandwidth,
      networkProfileId: this.assessment.networkProfileId,
      dataTransferTimeMinutes: this.assessment.dataTransferTimeMinutes,
      cloudProviderId : this.assessment.cloudProviderId,
      discoveryId: this.assessment.discoveryId,
      licenseCost: this.assessment.licenseCost
    };
    console.log("formvalues: ", formvalues);
    this.cloudMigrationService
      .updatePaymentSummary(formvalues, this.assessment.id)
      .subscribe((res: Assessment) => {
        this.assessment = res;
        this.setInitialValues(res);
        this.resetFlags();
      });
  };

  reMatchWorkloadsToCloudOfferings = (newRegion: string): void => {
    let formvalues: any;
    formvalues = {
      cloudProviderId: this.assessment.cloudProviderId,
      region: newRegion,
      Id: this.assessmentId,
    };
    this.cloudMigrationService
      .reMatchWorkloadsForRegionChange(formvalues)
      .subscribe((res: Assessment) => {
        this.assessment = res;
      });
  };

  contractPreview(){
    console.log("this.assessment.id: ", this.assessment.id);
    this.cloudMigrationService.getContractPreviewRequest(this.assessment.id).subscribe(data => {
      const requestFormData = data;
      console.log("navigate to contract preview page!!!!!", requestFormData);
      this.openContractPreviewDialog( requestFormData);
    });

  }

  openContractPreviewDialog(data: any) {
    const dialogRef = this.dialog.open(CloudMigrationContractPreviewComponent, {
      height: '95%',
      width: '95%',
      data: data,
      disableClose: true,
      autoFocus: true,

    });

    dialogRef.afterClosed()
      .subscribe((result: any) => {
        if (result) {
          console.log('result', result);

          const projectId = result.cloudMigrationServiceProjectId;
          const id = result.id;
        }
      });
  }
}
export class PricingSummaryData {
  pricingSummaryRowData: any;
  displayedColumns: any[];
}
export class StorageSummaryData {
  storageSummaryRows: any[];
  displayedColumns: any[];
}

export class NetworkSummaryData {
  networkSummaryRows: any[];
  displayedColumns: any[];
}
