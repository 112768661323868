import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessPartnersComponent } from './business-partners.component';
import { BusinessPartnersLandingPageComponent } from './business-partners-landing-page/business-partners-landing-page.component';
import { BusinessPartnerCreateComponent } from './business-partner-create/business-partner-create.component';
import { BusinessPartnerDetailComponent } from './business-partner-detail/business-partner-detail.component';
import { BusinessPartnerEditComponent } from './business-partner-edit/business-partner-edit.component';

import { BusinessPartnersRoutingModule } from './business-partners-routing.module';
import { BusinessPartnerService } from './business-partner.service';
import { AdminService } from '../admin/admin.service';
import { SharedModule } from '../shared/shared.module';
import { BusinessPartnerListComponent } from './business-partner-list/business-partner-list.component';
import { SolutionPilarsComponent } from './solution-pilars/solution-pilars.component';
import { SolutionPilarCreateComponent } from './solution-pilars/solution-pilar-create/solution-pilar-create.component';
import { SolutionPilarListComponent } from './solution-pilars/solution-pilar-list/solution-pilar-list.component';
import { SolutionPilarEditComponent } from './solution-pilars/solution-pilar-edit/solution-pilar-edit.component';
import { SolutionPilarLandingPageComponent } from './solution-pilars/solution-pilar-landing-page/solution-pilar-landing-page.component';
import { BusinessPartnerSearchPopupComponent } from './business-partner-search-popup/business-partner-search-popup.component';
import { BusinessPartnerTaskComponent } from './business-partner-task/business-partner-task.component';
import { LocationService } from '../shared/services/location.service';
import { BusinessPartnerLocationItemComponent } from './business-partner-location-item/business-partner-location-item.component';
import { PartnersComponent } from './partners/partners.component';
import { BusinessPartnersStatusPopupComponent } from './business-partners-status-popup/business-partners-status-popup.component';

@NgModule({
  imports: [
    CommonModule,
    BusinessPartnersRoutingModule,
    SharedModule
  ],
  declarations: [
    BusinessPartnersComponent,
    BusinessPartnersLandingPageComponent,
    BusinessPartnerCreateComponent,
    BusinessPartnerDetailComponent,
    BusinessPartnerEditComponent,
    BusinessPartnerListComponent,
    SolutionPilarsComponent,
    SolutionPilarCreateComponent,
    SolutionPilarListComponent,
    SolutionPilarEditComponent,
    SolutionPilarLandingPageComponent,
    BusinessPartnerSearchPopupComponent,
    BusinessPartnerTaskComponent,
    BusinessPartnerLocationItemComponent,
    PartnersComponent,
    BusinessPartnersStatusPopupComponent
  ],
  providers: [
    AdminService,
    BusinessPartnerService,
    LocationService
  ]
})
export class BusinessPartnersModule { }
