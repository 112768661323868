import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cloud-migration-assessment-discovery-select',
  templateUrl: './cloud-migration-assessment-discovery-select.component.html',
  styleUrls: ['./cloud-migration-assessment-discovery-select.component.scss'],
})
export class CloudMigrationAssessmentDiscoverySelectComponent
  implements OnInit
{
  @Input() discoveryList: any[];
  @Input() selection: SelectionModel<any>;
  dataSource: any[];
  displayedColumns: any[];
  ;

  constructor() {}

  ngOnInit(): void {
    this.dataSource = this.discoveryList;
    this.displayedColumns = ['select','environment', 'environmentType', 'workloadCount', 'datePerformed'];

    console.log('dataSource ', this.dataSource );
    console.log('displayedColumns', this.displayedColumns);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.forEach(row => this.selection.select(row));
  }
}
