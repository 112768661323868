import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {
  roles: any[];

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.getRoles();

  }
  getRoles() {
    this.adminService.getRoles()
      .subscribe(httpResult => {
        this.roles = httpResult;
      });
  }
  deleteRole(id: string) {
    this.adminService.deleteRole(id)
      .subscribe(httpResult => {
        this.getRoles();

      })
  }

}
