import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-control-rate',
  templateUrl: './project-control-rate.component.html',
  styleUrls: ['./project-control-rate.component.scss']
})
export class ProjectControlRateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
