import { ElementRef } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})

export class BreadCrumbComponent implements OnInit {
  deliminator = '/ ';
  constructor(private router: Router, private route: ActivatedRoute) { }
  breadcrumbs: Array<{ label: string; url: string, isRoutable: boolean }>;
  ngOnInit(): void {
    this.getBreadcrumbData();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)).subscribe(event => {
          this.getBreadcrumbData();
        });
  }
  getBreadcrumbData() {
    this.breadcrumbs = [];
    let currentRoute: ActivatedRoute | null = this.route.root,
      url = "";
    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = null;
      childrenRoutes.forEach(route => {
        const routeSnapshot = route.snapshot;
        let pathSegment = routeSnapshot.url.map(segment => segment.path);
        url += pathSegment.length > 0 ? "/" + routeSnapshot.url.map(segment => segment.path).join("/") : '';
        this.breadcrumbs.push({
          label: route.snapshot.data.breadcrumb,
          url: url,
          isRoutable: route.snapshot.data.isRoutable
        });
        currentRoute = route;
      });
    } while (currentRoute);
    this.breadcrumbs = this.breadcrumbs.filter(bc => bc.label != null);


  }
  applyDeliminator(first: any, last: any) {
    return !last;
  }
  isLinkActive(link: string) : boolean{
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === link;
  }
  addClass(event: any): void {
    event.target.classList.add('hovered');
  }
  removeClass(event: any): void {
    event.target.classList.remove('hovered');
  }
}
