import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { VehicleExpense } from '../models/fleet-management-models';
import { FleetMangementService } from '../fleet-mangement.service';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from 'src/app/shared/models/id-token-claims';

@Component({
  selector: 'app-add-mileage-and-expense',
  templateUrl: './add-mileage-and-expense.component.html',
  styleUrls: ['./add-mileage-and-expense.component.scss']
})
export class AddMileageAndExpenseComponent implements OnInit {
  model: VehicleExpense;
  vehicleId: string;
  expenseForm: UntypedFormGroup;
  fileName: string | undefined;

  milage: any;
  id: string;
  currentMilage: any;
  mileageForm: UntypedFormGroup;
  vehicleUsageNote: UntypedFormControl;
  vehicleUsageMileageStart: UntypedFormControl;
  vehicleUsageMileageEnd: UntypedFormControl;
  vehicleUsageDate: UntypedFormControl;
  mileageConfirmationMessage: string;
  mileageUpdatedSuccesfully: boolean;
  expenseConfirmationMessage: string;
  expenseUpdatedSuccesfully: boolean;
  isAdmin: boolean;
  roles: string[] | string;
  drivers: Array<any>;

  constructor(private service: FleetMangementService, private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, private location: Location, private authService: MsalService) {
    let idTokenClaims = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
    if (idTokenClaims) {
      this.roles = idTokenClaims['roles'];
      this.checkIfAdmin();
    }
  }

  ngOnInit() {
    if (this.isAdmin) {
      this.service.getDrivers()
        .subscribe(driverResponse => {
          this.drivers = driverResponse;
        });
    }

    this.vehicleUsageNote = new UntypedFormControl('', Validators.required);
    this.vehicleUsageMileageStart = new UntypedFormControl('');
    this.vehicleUsageDate = new UntypedFormControl('', Validators.required);

    this.route.params
      .subscribe(params => {

        this.vehicleId = params['id'];
        this.model = new VehicleExpense();
        this.model.vehicleId = this.vehicleId;
        if (this.milage) {

        } else {
          this.milage = {};
        }
        this.id = params['id'];
        this.milage.vehicleId = this.id;
        this.service.getVehicleMilagesByVehicleId(this.id)
          .subscribe(res => {
            this.milage.vehicleUsageMileageStart = res;

            this.vehicleUsageMileageEnd = new UntypedFormControl('', [Validators.required, Validators.min(this.milage.vehicleUsageMileageStart)]);
            this.mileageForm = this.fb.group({
              vehicleUsageNote: this.vehicleUsageNote,
              vehicleUsageMileageStart: this.vehicleUsageMileageStart,
              vehicleUsageMileageEnd: this.vehicleUsageMileageEnd,
              vehicleUsageDate: this.vehicleUsageDate,
              vehicleUsageType: ['', Validators.required]
            });
          });
      });

    this.fileName = '';
    this.model = new VehicleExpense();
    this.expenseForm = this.fb.group({
      vehicleExpenseDescription: ['', Validators.required],
      vehicleExpenseCost: ['', Validators.required],
      vehicleExpenseType: ['', Validators.required]
    });
  }
  addVehicle() {
    if (this.expenseForm.valid) {
      this.service.createVehicleExpense(this.model)
        .subscribe(res => {
          this.expenseUpdatedSuccesfully = true;
          this.expenseConfirmationMessage = 'The expense was added successfully.';

        });
    }
  }
  updateReciptFile(event: any) {
    this.model.vehicleExpenseReceipt = event.target.files[0];
    this.fileName = this.model.vehicleExpenseReceipt?.name;
  }
  saveMileage() {
    if (this.mileageForm.valid) {
      this.service.getAzureId().subscribe(aId => {
        this.service.createVehicleUsages(this.milage, aId as string)
          .subscribe(res => {
            this.mileageUpdatedSuccesfully = true;
            this.mileageConfirmationMessage = 'The mileage was added successfully.';

          });
      });

    }
  }
  completeAddingMileandExpense() {
    this.location.back();
  }
  checkIfAdmin() {
    this.isAdmin = this.roles.includes('FleetMangementAdmin');
    if (!this.isAdmin) {
      this.isAdmin = this.roles.includes('PortalAdmin');
    }
  }

}
