import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgForm, UntypedFormArray } from '@angular/forms';
import { ProfessionalServicesService } from '../../../professional-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ClientsService } from '../../../../clients/clients.service';
import { RoleService } from '../../../../shared/services/role.service';
import { AccountInfo } from '@azure/msal-browser';
import { Location } from '@angular/common';
import { ClientsListUrlFilteringComponent } from '../../../../clients/clients-list-url-filtering/clients-list-url-filtering.component';
import { ProjectDocumentPopupComponent } from '../../../../shared/components/project-document-popup/project-document-popup.component';
import { ProjectDocumentService } from '../../../../shared/components/project-document-popup/project-document.service';
import { CloudMigrationToolService } from 'src/app/cloud-migration-tool/cloud-migration-tool.service';
import { Tag } from '../../../../tags/models/tag.model';
import { TagsService } from '../../../../tags/tags.service';
import { TagComponent } from '../../../../shared/components/tag/tag.component';
import { TagsPopupComponent } from '../../../../shared/components/tags-popup/tags-popup.component';
import { GraphApiService } from '../../../../shared/services/graph-api.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-project-create-form',
  templateUrl: './project-create-form.component.html',
  styleUrls: ['./project-create-form.component.scss']
})
export class ProjectCreateFormComponent implements OnInit {
  postSaleResources: any[];
  canAddPostSales: boolean;
  isReadyToAssignPostSalesResources: boolean;
  user: AccountInfo | null;
  uniqueName: boolean;
  projectId: string;
  projectForm: UntypedFormGroup;
  clientName: string;
  employees: Array<any>;
  actualEmployees: Array<any>;
  assignedEmployees: Array<any>;
  isLoading: boolean;
  @Input() projectCanBeClosed: boolean;
  @ViewChild('form') form: NgForm;
  @Input() businessPartnerItems: any[];
  isCmtProj: boolean;
  @Input() project: any;
  assignedEmployee: any;
  @Output() isBPScoreCardOpenPopup: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('tag') tagComponent: TagComponent;
  tagItems: UntypedFormArray;
  tags: Array<Tag>;
  actualTags: Array<Tag>;
  isSelectOpened: boolean;
  isRemoveBtnDisabled: boolean;
  isAssignBtnEnabled: boolean;

  constructor(private fb: UntypedFormBuilder,
    private location: Location,
    private projectService: ProfessionalServicesService,
    private projectDocumentService: ProjectDocumentService,
    private route: ActivatedRoute,
    private authService: MsalService,
    private dialog: MatDialog,
    private clientService: ClientsService,
    private roleService: RoleService,
    private cloudServiceProjectsService: CloudMigrationToolService,
    private router: Router,
    private tagsService: TagsService,
    private graphApiService: GraphApiService) { }

  ngOnInit(): void {
    this.tagItems = this.fb.array([]);
    this.canAddPostSales = false;
    this.user = this.authService.instance.getActiveAccount();
    if (this.hasRole('PortalAdmin')) {
      this.canAddPostSales = true;
    }
    if (this.hasRole('PortalSEDirector') || this.hasRole('projects.approver')) {
      this.canAddPostSales = true;
    }
    this.uniqueName = true;
    this.assignedEmployee = {};
    this.route.parent?.parent?.data.subscribe(data => {
      this.isCmtProj = data.isCmtProj;
      if (this.isCmtProj) {
        this.route.parent?.params
          .subscribe(param => {
            this.setProjectForm(param['id']);
          });
      } else {
        this.route.params
          .subscribe(param => {
            this.setProjectForm(param['id']);
          });
      }
    })

  }

  setProjectForm(projectId: string) {
    if (projectId) {
      this.isLoading = true;
      this.projectId = projectId;
      this.getProjectDetails();
    } else {
      this.projectForm = this.fb.group({
        projectName: ['', Validators.required],
        oppurtunityNumber: ['', Validators.compose([Validators.required, Validators.max(999999)])],
        serviceProjectStatus: [0],
        clientId: [, Validators.required],
        clientName: [],
        isAllResourcesAssigned: [false],
        scopeRiskPercentage: [],
        isScopeRiskEnabled: [false],
        projectType: [this.isCmtProj],
        serviceProjectTags: this.tagItems
      });
    }
  }

  getProjectDetails() {
    this.getAssignedEmployees();
    if (this.isCmtProj) {
      this.cloudServiceProjectsService.getProject(this.projectId)
        .subscribe(res => {
          this.project = res;
          this.getTags();
          this.getClientInfo();
        });
    }
    else {
      this.getTags();
      this.getClientInfo();
    }
  }

  refreshPostSalesData() {
    this.projectService.getProject(this.projectId)
      .subscribe(seResult => {
        this.project.serviceEstimates = seResult.serviceEstimates;
        this.project.serviceProjectPostSalesAssignedEmployees = seResult.serviceProjectPostSalesAssignedEmployees;
      });
  }

  getTags() {
    this.tagsService.getTags()
      .subscribe(res => {
        this.tags = res;
        this.actualTags = Object.assign([], this.tags);
        this.project.serviceProjectTags?.forEach((tag: any) => {
          this.addTagItem(tag);
          let tagIndex: number = this.actualTags.findIndex(x => x.id == tag.tagId);
          this.actualTags.splice(tagIndex, 1);
        });
      });
  }

  getClientInfo() {
    const data = this.project;
    if (data) {
      data.serviceEstimates.forEach((serviceEstimate: any) => {
        if (serviceEstimate.serviceEstimateStatus == 4) {
          this.isReadyToAssignPostSalesResources = true;
        }
      });
      this.clientService.getClient(data.clientId)
        .subscribe(res => {
          this.clientName = res.commonName;
          this.projectForm = this.fb.group({
            id: [data.id],
            projectName: [data.projectName, Validators.required],
            oppurtunityNumber: [data.oppurtunityNumber, Validators.compose([Validators.required, Validators.max(999999)])],
            serviceProjectStatus: [data.serviceProjectStatus],
            clientId: [data.clientId, Validators.required],
            clientName: [data.clientName],
            isAllResourcesAssigned: [data.isAllResourcesAssigned],
            createdByEmployeeId: [data.createdByEmployeeId],
            scopeRiskPercentage: [data.scopeRiskPercentage],
            isScopeRiskEnabled: [data.isScopeRiskEnabled],
            projectType: [data.projectType],
            serviceProjectTags: this.tagItems
          });
          this.getActiveEmployees();
        });
    }
  }

  getAssignedEmployees() {
    this.projectService.getAssignedEmployeesByProjectNumber(this.projectId)
      .subscribe(res => {
        this.assignedEmployees = res;
      });
  }
  getActiveEmployees() {
    let fetchEmployees = this.projectService.getActiveEmployees();
    let fetchAdUsers = this.graphApiService.getUsers();
    forkJoin([fetchEmployees, fetchAdUsers]).subscribe(results => {
      let azAdEmployees = results[1].filter(user => user.givenName != null && user.surname != null && user.accountEnabled != false);
      this.employees = results[0].filter(emp => azAdEmployees.find(azUser => azUser.id == emp.azureAdId));
      this.isLoading = false;
    });
  }
  isEmployeeDisabled(employeeId: string) {
    return this.assignedEmployees.map((esr: any) => esr.employeeId).indexOf(employeeId) >= 0;
  }
  assignEmployee() {
    let assignedEmployee = Object.assign({}, this.assignedEmployee);
    this.assignedEmployee = {};
    assignedEmployee.serviceProjectId = this.projectId;
    this.isAssignBtnEnabled = false;
    this.projectService.assignEmployeeToServiceProjects(assignedEmployee)
      .subscribe(res => {
        this.getAssignedEmployees();

      });
  }
  unassignEmployee(id: any) {
    this.projectService.unassignEmployeeFromServiceProject(id)
      .subscribe(res => {
        this.getAssignedEmployees();

      });
  }
  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, false);
  }
  createProject() {
    if (this.projectForm.valid) {
      if (!this.projectId) {
        if (this.isCmtProj) {
          this.cloudServiceProjectsService.addProject(this.projectForm.value)
            .subscribe(projects => {
              this.location.back();
            });
        }
        else {
          this.projectService.createProject(this.projectForm.value)
            .subscribe(projectRes => {
              this.location.back();
            },
              error => {
                if (error.status == 409) {
                  this.uniqueName = false;
                  this.projectForm.updateValueAndValidity();
                }
              }
            );
        }
      } else {
        if (this.isCmtProj) {
          this.cloudServiceProjectsService.updateProject(this.projectForm.value)
            .subscribe(res => {
              this.location.back();
            });
        }
        else {
          this.projectService.updateProject(this.projectForm.value)
            .subscribe(projectRes => {
              this.location.back();

            });

        }
      }
    }
  }

  getProjectForm() {
    return this.form;
  }

  getTagName(id: string) {
    return this.tags.find(tag => tag.id == id)?.name;
  }

  addTagItem(tagItem: any, isNewTag: boolean = false) {
    let item: UntypedFormGroup;
    if (!isNewTag) {
      item = this.fb.group({
        'id': [tagItem.id],
        'tagId': [tagItem.tagId],
        'serviceProjectId': [this.projectId],
        'CreatedByEmployeeId': [tagItem.createdByEmployeeId],
        'CreatedDate': [tagItem.createdDate]
      });
    } else {
      item = this.fb.group({
        'tagId': [tagItem.id],
        'serviceProjectId': [this.projectId]
      });
    }
    this.tagItems.push(item);
  }

  removeTagItem(index: number, event: Event) {
    this.isRemoveBtnDisabled = true;
    let tag = this.tagItems.at(index).value;
    event.stopPropagation();
    if (tag.id) {
      this.tagsService.removeProjectTag(tag.id)
        .subscribe(res => {
          this.tagItems.removeAt(index);
          this.isRemoveBtnDisabled = false;
      });
    } else {
      this.tagItems.removeAt(index);
      this.isRemoveBtnDisabled = false;
    }
    let tagToInsertIndex: number = this.tags.findIndex(t => t.id == tag.tagId);
    this.actualTags.push(this.tags[tagToInsertIndex]);
    this.tagComponent.setTagControlValue();
  }

  toggleSelect() {
    if (!this.isSelectOpened) {
      this.tagComponent.select.openPanel();
    }
    this.isSelectOpened = !this.isSelectOpened;
  }

  toggleSelectOpened() {
    this.isSelectOpened = false;
  }

  tagSelected(tag: Tag) {
    this.addTagItem(tag, true);
    this.toggleSelectOpened();
  }

  public canDeactivate(): boolean {
    let state: boolean;
    state = true;
    if (this.form) {
      state = this.form.submitted || !this.form.dirty;
    }
    const url = this.location.path();

    if (state) {

    } else {
      history.pushState(null, 'Meridian Unified Portal', url);
    }
    return state;
  }

  openPopup() {
    const popup = this.dialog.open(ClientsListUrlFilteringComponent, {
      height: '60%',
      minWidth: '60%',
      panelClass: 'no-padding'
    });
    popup.afterClosed()
      .subscribe(selectedPopupValue => {
        if (selectedPopupValue) {

          this.projectForm.controls['clientId'].setValue(selectedPopupValue.clientId.id);
          this.clientName = selectedPopupValue.clientId.commonName;
        }
      });

  }

  openProjectDocumentUploadPopup() {
    const popup = this.dialog.open(ProjectDocumentPopupComponent, {
      height: 'auto',
      minWidth: '400px',
      panelClass: 'no-padding',
      data: { 'id': this.projectId }
    });
    popup.afterClosed()
      .subscribe(selectedPopupValue => {
        console.log(selectedPopupValue);
        if (selectedPopupValue) {
          // this.scoreCardService.createBusinessPartnerScoreCard(selectedPopupValue);
          location.reload();
        }
      });

  }

  openBusinessPartnerScorecard() {
    this.isBPScoreCardOpenPopup.emit(true);
  }

  openTagPopup(tagId: string) {
    const popup = this.dialog.open(TagsPopupComponent, {
      height: '60%',
      minWidth: '60%',
      panelClass: 'no-padding',
      data: { 'tagId': tagId }
    });
    popup.afterClosed()
      .subscribe(selectedPopupValue => {
      });
  }

  selectedEmployee(event: any) {
    this.isAssignBtnEnabled = true;
  }
}
