import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FleetMangementService } from '../../fleet-mangement.service';
import { AdminService } from '../../../admin/admin.service';
import { VehicleManagerService } from '../../../admin/vehicle-manager/vehicle-manager.service';
import { FormBuilder, FormGroup, Validators } from '../../../../../node_modules/@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from '@azure/msal-common';

@Component({
  selector: 'app-mileage',
  templateUrl: './mileage.component.html',
  styleUrls: ['./mileage.component.scss']
})
export class MileageComponent implements OnInit {
  startDate: Date;
  endDate: Date;
  reportResult: any;
  selectedDrivers: Array<string>;
  drivers: Array<any>;
  manager: any;
  mileageReportFormGroup: FormGroup;

  @ViewChild('donwloadCsvLink') private donwloadCsvLink: ElementRef;
  constructor(private authService: MsalService, private adminService: AdminService, private vmService: VehicleManagerService, private fleetService: FleetMangementService, private fb: FormBuilder) { }

  ngOnInit() {
    let managerAzureId: any;
    let idTokenClaims = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
    if (idTokenClaims) {
      managerAzureId = idTokenClaims['oid'];
    }


    this.adminService.getEmployees()
      .subscribe(res => {
        const employees: Array<any> = res;
        const employee = employees.filter(emp => emp.azureAdId === managerAzureId)[0];
        if (employee) {
          this.vmService.getVehicleManagerByEmpId(employee.employeeId)
            .subscribe(vmResult => {
              this.manager = vmResult;
              this.setupForm();

            });
        } else {
          this.setupForm();
        }

      });
    this.fleetService.getDrivers()
      .subscribe(driverRes => {
        this.drivers = driverRes;

      });
  }
  setupForm() {
    if (this.manager) {
      this.mileageReportFormGroup = this.fb.group({
        'startDate': [null, Validators.required],
        'endDate': [null, Validators.required],
        'driverIds': [this.manager.drivers.map((d: any) => d.vehicleDriverId)]
      });
    } else {
      this.mileageReportFormGroup = this.fb.group({
        'startDate': [null, Validators.required],
        'endDate': [null, Validators.required],
        'driverIds': [null]
      });
    }
  }
  getReport() {
    if (this.mileageReportFormGroup.valid) {
      this.fleetService.getVehicleMileageReport(this.mileageReportFormGroup.value)
        .subscribe(res => {
          this.reportResult = res;
          const fileText = this.fleetService.convertToCsv(JSON.stringify(this.reportResult));
          const blob = new Blob([fileText], {
            type: 'text/csv'
          });
          const url = window.URL.createObjectURL(blob);
          const link = this.donwloadCsvLink.nativeElement;
          link.href = url;
          link.download = 'mileageReport.csv';
          link.click();
          window.URL.revokeObjectURL(url);

        });
    }
  }
  getMinDate() {
    return this.mileageReportFormGroup.value['startDate'];
  }
  getMaxDate() {
    return this.mileageReportFormGroup.value['endDate'];
  }

}
