<div class="col-md-12" *ngIf="insurance">
  <mat-card>
    <mat-card-content>

      <button mat-button mat-icon-button (click)="toggleEdit('edit')" color="primary" [disabled]="isEditing">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-button mat-icon-button (click)="toggleEdit('cancel')" color="accent" [disabled]="!isEditing">
        <mat-icon>cancel</mat-icon>
      </button>
      <button mat-button mat-icon-button (click)="updateInsurance()" color="accent" [disabled]="!isEditing">
        <mat-icon>save</mat-icon>
      </button>
      <br>
      <p *ngIf="updateMessage">{{updateMessage}}</p>
      <form [formGroup]="insuranceForm">
        <mat-form-field>
          <input matInput [readonly]="true" placeholder="Days Remaining" [(ngModel)]="insurance.vehicleInsuranceDaysRemaining"
            name="vehicleInsuranceDaysRemaining" formControlName="vehicleInsuranceDaysRemaining">
        </mat-form-field>
        <br>
        <mat-form-field>
          <input matInput [readonly]="!isEditing" placeholder="Company Name" [(ngModel)]="insurance.vehicleInsuranceCompanyName" name="vehicleInsuranceCompanyName" formControlName="vehicleInsuranceCompanyName">
        </mat-form-field>
        <br>
        <mat-form-field>
          <input matInput [readonly]="!isEditing" placeholder="Policy Number" [(ngModel)]="insurance.vehicleInsurancePolicyNumber"
            name="vehicleInsurancePolicyNumber" formControlName="vehicleInsurancePolicyNumber">
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-select [disabled]="!isEditing" placeholder="Status" type="text" [(ngModel)]="insurance.vehicleInsuranceState" name="vehicleInsuranceState" formControlName="vehicleInsuranceState" disableOptionCentering>
            <mat-option [value]="0">Active</mat-option>
            <mat-option [value]="1">Expiring</mat-option>
            <mat-option [value]="2">Expired</mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field>
          <input matInput [readonly]="!isEditing" [max]="getMaxDate()" [disabled]="!isEditing" [matDatepicker]="vehicleInsuranceStartDate" placeholder="Start Date" type="datetime"
            [(ngModel)]="insurance.vehicleInsuranceStartDate" name="vehicleInsuranceStartDate" formControlName="vehicleInsuranceStartDate" />
          <mat-datepicker-toggle matSuffix [for]="vehicleInsuranceStartDate"></mat-datepicker-toggle>
          <mat-datepicker #vehicleInsuranceStartDate></mat-datepicker>
        </mat-form-field>
        <br>
        <mat-form-field>
          <input matInput [readonly]="!isEditing" [min]="getMinDate()" [disabled]="!isEditing" [matDatepicker]="vehicleInsuranceEndDate" placeholder="End Date" type="datetime"
            [(ngModel)]="insurance.vehicleInsuranceEndDate" name="vehicleInsuranceEndDate" formControlName="vehicleInsuranceEndDate" />
          <mat-datepicker-toggle matSuffix [for]="vehicleInsuranceEndDate"></mat-datepicker-toggle>
          <mat-datepicker #vehicleInsuranceEndDate></mat-datepicker>
        </mat-form-field>
        <br>
        <div class="col-md-4" *ngIf="isEditing">
          <input [readOnly]="!isEditing" placeholder="Policy File" type="file" (change)="addFileToModel($event)" accept=".pdf" />
        </div>
        <a *ngIf="insurance.vehicleInsuranceCertificate && !isEditing" mat-raised-button color="accent" download [href]="insurance.vehicleInsuranceCertificate">Download Cerificate</a>
      </form>
    </mat-card-content>
  </mat-card>
</div>
