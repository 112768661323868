import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CloudMigrationToolAdminService } from '../../cloud-migration-tool-admin.service';
import { CloudProviderPricingScheduleComponent } from '../../cloud-provider-pricing-schedule/cloud-provider-pricing-schedule.component';
import { CloudProviderPricingComponent } from '../../cloud-provider-pricing/cloud-provider-pricing.component';
import { CloudProviderEditComponent } from '../cloud-provider-edit/cloud-provider-edit.component';

@Component({
  selector: 'app-cloud-provider-detail',
  templateUrl: './cloud-provider-detail.component.html',
  styleUrls: ['./cloud-provider-detail.component.scss'],
})
export class CloudProviderDetailComponent implements OnInit {
  loading: boolean;
  cloudProviderId: any;
  cloudProvider: any;
  pricingScheduleDataSource: any;
  displayedPricingScheduleColumns: string[] = ['serviceName','productCode', 'actions'];
  cloudProviderForm: UntypedFormGroup;
  hostedSystemOptions: any;
  cloudProviderMeridian: boolean;
  regionOptions: any;
  termOptions: any;
  osReleaseOptions: any;
  displayPricingScheduleSection: string;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private service: CloudMigrationToolAdminService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe((param) => {
      this.cloudProviderId = param['id'];
      if (this.cloudProviderId) {
        this.loadCloudProvider();
      }
    });
  }

  loadCloudProvider() {
    this.service.getCloudProvider(this.cloudProviderId).subscribe((res) => {
      const data = res;
      this.setCloudProviderData(data);
    });
  }

  setCloudProviderData(data: any) {
    console.log("setCloudProviderData data.termOptions: ",data.osReleaseOptions);
    if (data) {
      this.cloudProvider = data;
      this.regionOptions = data.regionOptions;
      this.termOptions = data.termOptions;
      this.pricingScheduleDataSource = data.pricingSchedules;
      this.hostedSystemOptions = data.hostedSystemOptions;
      this.osReleaseOptions = data.osReleaseOptions;
      this.cloudProviderMeridian = this.cloudProvider.name
        .toLowerCase()
        .includes('meridian');
      //this.displayPricingScheduleSection = this.cloudProviderMeridian ? '' : 'display: none';
      //Let this be visible for all Cloud Providers until all of CRUD is implemented etc...
      this.displayPricingScheduleSection = this.cloudProviderMeridian ? '' : '';

    }
  }

  editProvider() {
    this.openProviderEditDialog(this.cloudProvider.id);
  }

  addPriceSchedule() {
    const newRow = {
      id: '',
      cloudProviderId: this.cloudProviderId,
      hostedSystem: '',
      serviceName: '',
      partitionCosts: '',
      hostingLaborCosts: '',
      dRlaborCosts: '',
      haLaborCosts: '',
      setUpCosts: '',
      hostedSystemOptions: this.hostedSystemOptions,
      termOptions: this.termOptions

    };
    this.openPricingScheduleDialog(newRow);
  }

  openPricingScheduleDialog(row: any) {
    const popup = this.dialog.open(CloudProviderPricingScheduleComponent, {
      width: '80%',
      minWidth: '250px',
      data: {
        row:row,
        id: row.id,
        cloudProviderId: this.cloudProviderId,
        hostedSystem: row.hostedSystem,
        serviceName: row.serviceName,
        partitionCosts: row.partitionCosts,
        hostingLaborCosts: row.hostingLaborCosts,
        dRlaborCosts: row.dRlaborCosts,
        haLaborCosts: row.haLaborCosts,
        setUpCosts: row.setUpCosts,
        diskUnitPrice : row.diskUnitPrice,
        processingUnitPrice: row.processingUnitPrice,
        memoryUnitPrice : row.memoryUnitPrice,
        hostedSystemOptions: row.hostedSystemOptions,
        termOptions: this.termOptions
      },
      disableClose: true,
    });

    popup.afterClosed().subscribe((res) => {
      console.log('popup after close res:', res);
      if (res) {
        // this.loadData();
      }
    });
  }

  openPricingDialog(row: any) {
    const popup = this.dialog.open(CloudProviderPricingComponent, {
      width: '80%',
      minWidth: '250px',
      data: {
        id: row.id,
        cloudProviderId: this.cloudProviderId,
        hostedSystem: row.hostedSystem,
        serviceName: row.serviceName,
        partitionCosts: row.partitionCosts,
        hostingLaborCosts: row.hostingLaborCosts,
        dRlaborCosts: row.dRlaborCosts,
        haLaborCosts: row.haLaborCosts,
        setUpCosts: row.setUpCosts,
        hostedSystemOptions: row.hostedSystemOptions,
        osReleaseOptions: this.osReleaseOptions,
      },
      disableClose: true,
    });

    popup.afterClosed().subscribe((res) => {
      console.log('popup after close res:', res);
      if (res) {
        // this.loadData();
      }
    });
  }


  openProviderEditDialog(id: any) {
    const popup = this.dialog.open(CloudProviderEditComponent, {
      width: '60%',
      height: '35%',
      minWidth: '250px',
      minHeight: '50px',
      data: {
        id: id,
      },
      disableClose: true,
    });

    popup.afterClosed().subscribe((res) => {
      console.log('popup after close res:', res);
      if (res) {
        // this.loadData();
      }
    });
  }
}
