import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { CloudMigrationToolService } from 'src/app/cloud-migration-tool/cloud-migration-tool.service';

@Component({
  selector: 'cloud-migration-environment-config-popup',
  templateUrl: './cloud-migration-environment-config-popup.component.html',
  styleUrls: ['./cloud-migration-environment-config-popup.component.scss'],
})
export class CloudMigrationEnvironmentConfigPopupComponent implements OnInit {
  formTitle: string = ' Discovery Environment Configuration';
  environmentConfigForm: UntypedFormGroup;
  environmentConfigId: string;
  environmentConfigData: any;
  projectId: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<CloudMigrationEnvironmentConfigPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: CloudMigrationToolService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    console.log('config for popup: ', this.data);
    this.environmentConfigForm = this.createFormGroup(this.data?.configuration);
  }

  createFormGroup(res: any): UntypedFormGroup {
      return this.fb.group(
        {
          title: [res?.title, Validators.required],
          // virtualEnvironment: [res?.virtualEnvironment, Validators.required],
          hostName: [res?.hostName, Validators.required],
          environmentId: [res?.environmentId],
          projectId: [res?.projectId],
          userName: [res?.userName, Validators.required],
          password: [res?.password, Validators.required],
          confirmPassword: [res?.password, Validators.required],
          id: [res?.id],
        },
        { validator: passwordMatchValidator }
      );
  }

  save() {
    var formVal = this.environmentConfigForm.value;
    console.log('config form val: ', formVal);
    var data = {
      id: formVal.id,
      title: formVal.title,
      hostName: formVal.hostName,
      projectId: formVal.projectId,
      environmentId: formVal.environmentId,
      userName: formVal.userName,
      password: formVal.password,
      // virtualizationType: formVal.virtualizationType,
    };
    console.log('env data: ', data);
    this.service.updateEnvironmentConfig(data).subscribe({
      next: (res) => {
        this.close(res);
      },
    });
  }

  close(res?: any) {
    this.dialogRef.close(res);
  }
}
export function passwordMatchValidator(control: AbstractControl) {
  const password: string = control.get('password')?.value;
  const confirmPassword: string = control.get('confirmPassword')?.value;
  if (password !== confirmPassword) {
    control.get('confirmPassword')?.setErrors({ NoPassswordMatch: true });
  } else if (!confirmPassword) {
    control.get('confirmPassword')?.setErrors({ required: true });
  } else if (password === confirmPassword) {
    control.get('confirmPassword')?.setErrors(null);
  }
}
