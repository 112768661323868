import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FleetMangementService } from 'src/app/fleet-management/fleet-mangement.service';

@Component({
  selector: 'app-assign-drivers-popup',
  templateUrl: './assign-drivers-popup.component.html',
  styleUrls: ['./assign-drivers-popup.component.scss']
})
export class AssignDriversPopupComponent implements OnInit {
  employees: Array<any>;

  constructor(private fleetMgmtSerivce: FleetMangementService, public dialogRef: MatDialogRef<AssignDriversPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.data = {};
    this.fleetMgmtSerivce.getDrivers()
      .subscribe(employeeResult => {
        this.employees = employeeResult;
      });
  }
}
