<div class="breadcrumb header">
  <div>
    <a routerLink="/" (mouseover)="addClass($event)" (mouseleave)="removeClass($event)">Home</a>
    <span class="breadcrumb-separator">/</span>
  </div>
  <div *ngFor="let breadcrumb of breadcrumbs;last as isLast;first as isFirst">
    <a *ngIf='breadcrumb.isRoutable' [class.active]="isLinkActive(breadcrumb.url)" (mouseover)="addClass($event)" (mouseleave)="removeClass($event)"
       [routerLink]="breadcrumb.url" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{breadcrumb.label}}</a>
    <a *ngIf='!breadcrumb.isRoutable'>{{breadcrumb.label}}</a>
    <span class="breadcrumb-separator"
          *ngIf="!isLast">/</span>
  </div>
</div>
