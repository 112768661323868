<mat-card>
  <mat-card-content>
    <div
      [class.loading]="!loading"
      style="position: relative; margin: auto; width: calc(50% + 225px)"
    >
      <mat-spinner
        mode="indeterminate"
        width="450"
        height="450"
        style="margin: 0 auto"
      ></mat-spinner>
    </div>

    <div *ngIf="project" [class.loading]="loading">
      <fieldset>
        <legend>Project Details</legend>
        <div class="row adjust-linehgt">
          <p class="col-md-6">Project Name: {{ project.projectName }}</p>
          <p class="col-md-6">Opp Q#: {{ project.oppurtunityNumber }}</p>
        </div>

        <div class="row adjust-linehgt">
          <p class="col-md-6">Client Name: {{ project.client.commonName }}</p>
          <p class="col-md-6">
            Created Date: {{ project.createdDate | date : "shortDate" }}
          </p>
        </div>
        <div class="align-button">
          <a
            id="btn-add-discovery"
            mat-raised-button
            color="primary"
            (click)="addDiscovery()"
            style="float: right"
          >
            <mat-icon>add</mat-icon>Add Discovery
          </a>
        </div>
        <div class="align-button" style="float: right">
          <a
            mat-raised-button
            color="primary"
            (click)="openDiscoveryUploadPopup(null)"
            style="float: right"
            ><mat-icon>upload</mat-icon>Upload IBMI Discovery File</a
          >
        </div>
        <div class="align-button">
          <app-discovery-upload
            style="float: right"
            [projectId]="projectId"
            fileExtension=".json"
            title="Upload CMT Discovery File"
            (onUploadFinished)="onUploadFinished($event)"
          ></app-discovery-upload>
        </div>
      </fieldset>
    </div>

    <br />

    <div [class.loading]="loading">
      <fieldset>
        <legend>Discoveries</legend>
        <mat-table
          mat-table
          matSort
          class="full-width-table"
          aria-label="Discoveries Table"
          [dataSource]="discoveryDataSource"
          [class.loading]="loading"
        >
          <ng-container matColumnDef="environment">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Environment</mat-header-cell
            >
            <mat-cell *matCellDef="let row">
              {{ row.environment }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="environmentType">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Env. Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row">
              {{ row.environmentType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="datePerformed">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{
              row.datePerformed | date : "shortDate"
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="workloadCount">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Workloads</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{ row.workloadCount }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="performedBy">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >Performed By</mat-header-cell
            >
            <mat-cell *matCellDef="let row">{{ row.performedBy }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <ng-container>
                <button
                  id="btn-view-actions-{{ row.id }}"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [routerLink]="[row.id]">
                    <mat-icon>search</mat-icon>
                    <span>View Discovery</span>
                  </button>
                  <button
                    id="btn-delete-discovery-{{ row.id }}"
                    mat-menu-item
                    (click)="deleteRecord(row.id)"
                  >
                    <mat-icon>delete_outline</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </ng-container>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
        </mat-table>

        <mat-paginator
          [class.loading]="loading"
          #paginator
          [length]="discoveryList?.rows?.length"
          [pageIndex]="0"
          [pageSize]="50"
          [pageSizeOptions]="[5, 25, 50, 100]"
        >
        </mat-paginator>
      </fieldset>
    </div>
  </mat-card-content>
</mat-card>
