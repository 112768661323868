<h2 mat-dialog-title>Reason For Status Change</h2>
<mat-dialog-content>
  <mat-form-field>
    <textarea matInput [formControl]="note" placeholder="Notes" id="notes"></textarea>
    <mat-error *ngIf="note.hasError('required')">
      Notes
      is required
    </mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button (click)="saveNote()" [disabled]="!note.valid">Save</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="false">Cancel</button>
</mat-dialog-actions>
