<mat-card>
  <mat-card-header>
  </mat-card-header>
  <mat-card-content *ngIf="employees">
    <h2>Service Estimate Components Approved</h2>
    <div style="padding: 10px;">
      <mat-form-field class="normalSize">
        <mat-select [(ngModel)]="selectedEmployeeId" (selectionChange)="filterByEmployee()" disableOptionCentering #employee
          placeholder="Employee Filter">
          <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">{{emp.firstName + " " + emp.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button (click)="clearSelectedEmployee()" mat-icon-button type="button">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="projectName">
        <mat-header-cell *matHeaderCellDef (click)="sortByProjectName()" class="clickable"> Name <mat-icon
            class="twelve">
            sort
          </mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.componentName}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef (click)="sortByDate()" class="clickable"> Created Date <mat-icon
            class="twelve">sort
          </mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createdDate | date:'M/d/yyyy'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef> Created by</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: element.createdByEmployeeId}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item color="default" type="button" [routerLink]="[element.serviceEstimate.serviceProjectId]">
              <mat-icon>search</mat-icon>
              <span>Service Estimate</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="serviceEstimates">
      <mat-paginator (page)="pageChangedEventUsages($event)" *ngIf="dataSource" [length]="serviceEstimates.length"
        [pageSize]="100" [pageIndex]="0" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
