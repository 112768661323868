import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CloudMigrationToolService } from 'src/app/cloud-migration-tool/cloud-migration-tool.service';
import { ServiceEstimateSelectModel } from 'src/app/professional-services/service-estimates/_models/service-estimate-select-list.model';

@Component({
  selector: 'app-cloud-migration-pricing-form',
  templateUrl: './cloud-migration-pricing-form.component.html',
  styleUrls: ['./cloud-migration-pricing-form.component.scss']
})
export class CloudMigrationPricingFormComponent implements OnInit {
  @Input() resetFormsEvent: EventEmitter<null>;
  @Input() assessment: any;
  @Output() formValueEvent: EventEmitter<PricingFormData> = new EventEmitter<PricingFormData>();

  defaultAssessmentvalue: string = '';

  pricingFormGroup: UntypedFormGroup;

  serviceEstimatesList: Array<ServiceEstimateSelectModel>;

  serviceEstimateSelectList: Array<ServiceEstimateSelectModel>;

  regions: Array<any>;
  storageTypes: Array<any>;
  networkProfiles: Array<any>;
  vpnProviders: Array<any>;
  public formUpdated: boolean = false;
  public storageValueChanged: boolean;
  public dataTransferTimeValueChanged: boolean;
  public vpnRateValueChanged: boolean;


  constructor(private fb: UntypedFormBuilder, private cloudMigrationToolService: CloudMigrationToolService) { }

  ngOnInit(): void {
    this.resetFormsEvent?.subscribe(res => {
      console.log('pricing form:',res)
      this.reset();
    });
    const tempAssessment = JSON.stringify(this.assessment);
    this.defaultAssessmentvalue = tempAssessment;
    this.regions = this.assessment?.regionOptions;
    this.storageTypes = this.assessment?.storageTypeOptions;
    this.networkProfiles = this.assessment?.networkProfileOptions;
    this.vpnProviders = this.assessment?.vpnProviderOptions;

    const licenseCost = (this.assessment?.licenseCost==0)?null:this.assessment?.licenseCost;
    const storageAmount = (this.assessment?.storageAmount==0)?null:this.assessment?.storageAmount;
    const bandwidth = (this.assessment?.bandwidth==0)?null:this.assessment?.bandwidth;
    this.pricingFormGroup = this.fb.group({
      selectedServiceEstimate: [this.assessment?.serviceEstimateId],
      selectedRegion: [this.assessment?.region, Validators.required],
      managedServicePercent: [parseFloat(this.assessment?.managedServicePercent).toFixed(2)],
      storageAmount: [storageAmount],
      selectedStorageType: [this.assessment?.storageType],
      selectedNetworkProfile: [this.assessment?.networkProfileId],
      selectedVpnProvider: [this.assessment?.vpnProvider],
      bandwidthAmount: [bandwidth],
      licenseCost:[licenseCost]
    });
    this.cloudMigrationToolService.getServiceEstimateSelectListByProjectId(this.assessment?.projectId)
      .subscribe(serviceEstimateListItems => {
        this.serviceEstimatesList = serviceEstimateListItems;

      });
    this.pricingFormGroup.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500))
      .subscribe(res => {

        this.broadcastFormValues(res);
        this.formUpdated = this.pricingFormGroup.dirty;
        this.storageValueChanged = this.pricingFormGroup.controls['storageAmount'].dirty || this.pricingFormGroup.controls['selectedStorageType'].dirty;

      });
  }

  broadcastFormValues(formValue: any) {
    const pricingFormData = <PricingFormData>formValue;
    this.formValueEvent.emit(pricingFormData);

  }


  public reset() {
    console.log('hit');
    var parsedDefault = JSON.parse(this.defaultAssessmentvalue);
    this.pricingFormGroup.reset({
      selectedServiceEstimate: parsedDefault.serviceEstimateId,
      selectedRegion: parsedDefault.region,
      managedServicePercent: parseFloat(parsedDefault.managedServicePercent).toFixed(2),
      storageAmount: parsedDefault.storageAmount,
      selectedStorageType: parsedDefault.storageType,
    });
    this.broadcastFormValues(this.pricingFormGroup.value);
    
  }
}

export class PricingFormData {
  selectedServiceEstimate: string;
  selectedRegion: string;
  managedServicePercent: number;
  storageAmount: number;
  selectedStorageType: string;
  selectedNetworkProfile: string;
  selectedVpnProvider: string;
  bandwidthAmount: number;
  licenseCost: any;
}
