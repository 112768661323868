<div class="container-fluid">
  <mat-card>
    <button mat-raised-button color="primary" [routerLink]="['create']">Create</button>
    <mat-card-content>
      <mat-table #table matSort [dataSource]="documents">

        <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="module">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Module </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.module}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="gotoDocument(row)"></mat-row>
      </mat-table>


      <mat-paginator #paginator [length]="documents?.data.length" [pageIndex]="0" [pageSize]="50"
        [pageSizeOptions]="[5, 25, 50, 100]">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>