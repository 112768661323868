<mat-card>
  <mat-card-content>
    <form [formGroup]="driverForm" (ngSubmit)="onSubmit()">
      <mat-form-field *ngIf="employees">
        <mat-select [(ngModel)]="driver.employeeId" formControlName="employeeId" placeholder="Select an Employee" disableOptionCentering>
          <mat-option *ngFor="let employee of employees" [value]="employee.employeeId">{{employee.firstName}} {{employee.lastName}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button type="submit" [disabled]="!driverForm.valid">Create</button>
    </form>
  </mat-card-content>
</mat-card>
