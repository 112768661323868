import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-migration-discovery-traceroutedata',
  templateUrl: './cloud-migration-discovery-traceroutedata.component.html',
  styleUrls: ['./cloud-migration-discovery-traceroutedata.component.scss']
})
export class CloudMigrationDiscoveryTraceroutedataComponent implements OnInit {
  @Input() traceroutedata: any;

  tableDataSource: any;
  displayedColumns: string[] = ['senderIpV4','targetHostIpV4','lastHopIpV4','maxHops','packetSize' ]
  

  constructor() { }

  ngOnInit(): void {
    this.setData();
   
  }
  setData() {
    this.tableDataSource = this.traceroutedata;
  }

}
