import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ReleaseNotesService } from 'src/app/shared/services/release-notes.service';

@Component({
  selector: 'release-notes-workitem-details',
  templateUrl: './release-notes-workitem-details.component.html',
  styleUrls: ['./release-notes-workitem-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReleaseNotesWorkItemDetails implements OnInit {

  tagFormGroup: UntypedFormGroup;
  workItemHtml: string;
  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private releaseNotesService: ReleaseNotesService, private location: Location) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(routeParams => {
        const id = routeParams['id'];
        console.log(id);
        this.getWorkItemDetailsHtml(id);
      });
  }

  getWorkItemDetailsHtml(id: number){
    this.releaseNotesService.getWorkItemDetails(id).subscribe( data => {
      this.workItemHtml = data.fields.tutorial;
    })
  }
}
