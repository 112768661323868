<div class="container-fluid">
  <mat-card>
    <a id="newDriverCreate" mat-raised-button [routerLink]="['create']" color="primary" matTooltip="Create New Driver"
      [matTooltipPosition]="'right'">
      <mat-icon class="add-icon">add</mat-icon>
      Driver
    </a>
    <mat-card-content>
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="vehicleDriverName">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span> {{element.firstName}} {{element.lastName}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vehicleDriverActions">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button id="kebabMenu" [attr.data-id]="element.vehicleDriverId" mat-icon-button [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item color="warn" type="button" (click)="deleteDriver(element.vehicleDriverId)">
                <mat-icon>delete_outline</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </mat-card-content>
  </mat-card>
</div>