import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inactive-employee-list',
  templateUrl: './inactive-employee-list.component.html',
  styleUrls: ['./inactive-employee-list.component.scss']
})
export class InactiveEmployeeListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
