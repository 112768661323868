import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tag } from './models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(private http: HttpClient) { }

  getTags() {
    return this.http.get<any[]>(`/api/tags`);
  }
  getTag(id: string) {
    return this.http.get<any>(`/api/tags/${id}`);
  }
  getTagDetails(tagId: string) {
    return this.http.get<any>(`/api/tags/${tagId}/details`);
  }
  createTag(tag: Tag) {
    console.log('test')
    const body = JSON.stringify(tag);
    return this.http.post<any>(`/api/tag`, body);
  }
  updateTag(tag: Tag) {
    const body = JSON.stringify(tag);
    return this.http.put<any>(`/api/tags/${tag.id}`, body);
  }
  removeTag(id: string) {
    return this.http.delete(`/api/tags/${id}`);
  }
  removeProjectTag(id: string) {
    return this.http.delete(`/api/tags/${id}/projectTag`);
  }
  removeSETag(id: string) {
    return this.http.delete(`/api/tags/${id}/SETag`);
  }
  removeSEComponentTag(id: string) {
    return this.http.delete(`/api/tags/${id}/SEComponentTag`);
  }
}
