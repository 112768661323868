<button  id="btn-close-icon"  mat-icon-button  class="close-button"  (click)="close()">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{formTitle}}</h3>
<p></p>
<mat-card *ngIf="dicoveryConfigForm">
  <div>
    <mat-dialog-content [formGroup]="dicoveryConfigForm">
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Environment Name</mat-label>
            <input matInput formControlName="title" />
            <mat-error *ngIf="dicoveryConfigForm.controls['title'].hasError('required')">
              Environment Name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Virtual Environment</mat-label>
            <mat-select formControlName="virtualEnvironment" disableOptionCentering>
              <mat-option *ngFor="let virtualizationType of virtualizationTypes" [value]="virtualizationType?.value">
                {{virtualizationType?.text}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="dicoveryConfigForm.controls['virtualEnvironment'].hasError('required')">
              Virtual Environment is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Host Name</mat-label>
            <input matInput formControlName="hostName" />
            <mat-error *ngIf="dicoveryConfigForm.controls['hostName'].hasError('required')">
              Host Name is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>User Name</mat-label>
            <input matInput formControlName="userName" />
            <mat-error *ngIf="dicoveryConfigForm.controls['userName'].hasError('required')">
              User Name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" type="password" />
            <mat-error *ngIf="dicoveryConfigForm.controls['password'].hasError('required')">
              Password is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Confirm Password</mat-label>
            <input matInput formControlName="confirmPassword" type="password" />
            <mat-error *ngIf="dicoveryConfigForm.controls['confirmPassword'].hasError('required')">
              Confirm Password is required
            </mat-error>
            <mat-error *ngIf="dicoveryConfigForm.controls['confirmPassword'].hasError('NoPassswordMatch')">
              Password and Confirm Password must be match
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-card-actions align="right">
        <button class="mat-raised-button mat-primary" [disabled]="!dicoveryConfigForm.valid"
          (click)="save()">Save</button>
        <button class="mat-raised-button" (click)="close()">Close</button>
      </mat-card-actions>
    </div>
  </div>
  <b></b>
  <span style="font-weight: bold;">{{message}}</span>
</mat-card>