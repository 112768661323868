import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { EmployeeComponent } from './employee/employee.component';
import { RoleComponent } from './role/role.component';
import { EmployeeListComponent } from './employee/employee-list/employee-list.component';
import { InactiveEmployeeListComponent } from './employee/inactive-employee-list/inactive-employee-list.component';
import { EmployeeCreateComponent } from './employee/employee-create/employee-create.component';
import { EmployeeDetailsComponent } from './employee/employee-details/employee-details.component';
import { RoleListComponent } from './role/role-list/role-list.component';
import { RoleCreateComponent } from './role/role-create/role-create.component';
import { RoleDetailsComponent } from './role/role-details/role-details.component';
import { AdminComponent } from './admin.component';
import { AdminService } from './admin.service';
import { ExpenseCardComponent } from './expense-card/expense-card.component';
import { CreateCardComponent } from './expense-card/create-card/create-card.component';
import { ListCardComponent } from './expense-card/list-card/list-card.component';
import { DetailCardComponent } from './expense-card/detail-card/detail-card.component';
import { AdminLandingPageComponent } from './admin-landing-page/admin-landing-page.component';
import { ServiceRoleComponent } from './service-role/service-role.component';
import { ServiceRoleCreateComponent } from './service-role/service-role-create/service-role-create.component';
import { ServiceRoleEditComponent } from './service-role/service-role-edit/service-role-edit.component';
import { ServiceRoleListComponent } from './service-role/service-role-list/service-role-list.component';
import { ServiceRoleDetailComponent } from './service-role/service-role-detail/service-role-detail.component';
import { ServiceRoleLandingComponent } from './service-role/service-role-landing/service-role-landing.component';
import { LineOfBusinessComponent } from './line-of-business/line-of-business.component';
import { LineOfBusinessCreateComponent } from './line-of-business/line-of-business-create/line-of-business-create.component';
import { LineOfBusinessUpdateComponent } from './line-of-business/line-of-business-update/line-of-business-update.component';
import { LineOfBusinessListComponent } from './line-of-business/line-of-business-list/line-of-business-list.component';
import { LineOfBusinessDetailComponent } from './line-of-business/line-of-business-detail/line-of-business-detail.component';
import { ServiceRoleService } from './service-role/service-role.service';
import { ProjectControlModule } from './project-control/project-control.module';
import { ProjectControlRateModule } from './project-control-rate/project-control-rate.module';
import { VehicleManagerComponent } from './vehicle-manager/vehicle-manager.component';
import { VehicleManagerListComponent } from './vehicle-manager/vehicle-manager-list/vehicle-manager-list.component';
import { VehicleManagerDetailComponent } from './vehicle-manager/vehicle-manager-detail/vehicle-manager-detail.component';
import { VehicleManagerUpdateComponent } from './vehicle-manager/vehicle-manager-update/vehicle-manager-update.component';
import { VehicleManagerCreateComponent } from './vehicle-manager/vehicle-manager-create/vehicle-manager-create.component';
import { VehiclemanagerLandingPageComponent } from './vehicle-manager/vehiclemanager-landing-page/vehiclemanager-landing-page.component';
import { VehicleManagerService } from './vehicle-manager/vehicle-manager.service';
import { LocationComponent } from './location/location.component';
import { StatesComponent } from './location/states/states.component';
import { CitiesComponent } from './location/cities/cities.component';
import { CountiesComponent } from './location/counties/counties.component';
import { StateListComponent } from './location/states/state-list/state-list.component';
import { CountyListComponent } from './location/counties/county-list/county-list.component';
import { CityListComponent } from './location/cities/city-list/city-list.component';
import { LocationService } from './location/location.service';
import { ApproverThresholdComponent } from './approver-threshold/approver-threshold.component';
import { ThresholdCreateComponent } from './approver-threshold/threshold-create/threshold-create.component';
import { ThresholdListComponent } from './approver-threshold/threshold-list/threshold-list.component';
import { ApproverThresholdService } from './approver-threshold/approver-threshold.service';
import { CitiesTableComponent } from './location/cities/cities-table/cities-table.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { TempApproverListComponent } from './temp-approver/temp-approver-list/temp-approver-list.component';
import { TempApproverComponent } from './temp-approver/temp-approver.component';
import { TempApproverCreateComponent } from './temp-approver/temp-approver-create/temp-approver-create.component';
import { TempApproverService } from './temp-approver/temp-approver.service';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { ReleaseNotesListComponent } from './release-notes/release-notes-list/release-notes-list.component';
import { ReleaseNotesCreateComponent } from './release-notes/release-notes-create/release-notes-create.component';
import { RoleAdministrationComponent } from './role-administration/role-administration.component';
import { RoleAdminListComponent } from './role-administration/role-admin-list/role-admin-list.component';
import { RoleAdminCreateComponent } from './role-administration/role-admin-create/role-admin-create.component';
import { AdminNestedTabsComponent } from './admin-nested-tabs/admin-nested-tabs.component';
import { ApplicationConfigurationComponent } from './margin-adjustments/application-configuration.component';
import { ApplicationConfigurationCreateComponent } from './margin-adjustments/application-configuration-create/application-configuration-create.component';
import { ApplicationConfigurationService } from './margin-adjustments/application-configuration.service';


@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    ProjectControlModule,
    ProjectControlRateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],
  declarations: [
    EmployeeComponent,
    RoleComponent,
    EmployeeListComponent,
    EmployeeCreateComponent,
    EmployeeDetailsComponent,
    RoleListComponent,
    RoleCreateComponent,
    RoleDetailsComponent,
    AdminComponent,
    ExpenseCardComponent,
    CreateCardComponent,
    ListCardComponent,
    DetailCardComponent,
    AdminLandingPageComponent,
    ServiceRoleComponent,
    ServiceRoleCreateComponent,
    ServiceRoleEditComponent,
    ServiceRoleListComponent,
    ServiceRoleDetailComponent,
    ServiceRoleLandingComponent,
    LineOfBusinessComponent,
    LineOfBusinessCreateComponent,
    LineOfBusinessUpdateComponent,
    LineOfBusinessListComponent,
    LineOfBusinessDetailComponent,
    VehicleManagerComponent,
    VehicleManagerListComponent,
    VehicleManagerDetailComponent,
    VehicleManagerUpdateComponent,
    VehicleManagerCreateComponent,
    VehiclemanagerLandingPageComponent,
    LocationComponent,
    StatesComponent,
    CitiesComponent,
    CountiesComponent,
    StateListComponent,
    CountyListComponent,
    CityListComponent,
    ApproverThresholdComponent,
    ThresholdCreateComponent,
    ThresholdListComponent,
    CitiesTableComponent,
    InactiveEmployeeListComponent,
    TempApproverComponent,
    TempApproverListComponent,
    TempApproverCreateComponent,
    ReleaseNotesComponent,
    ReleaseNotesListComponent,
    ReleaseNotesCreateComponent,
    RoleAdministrationComponent,
    RoleAdminListComponent,
    RoleAdminCreateComponent,
    AdminNestedTabsComponent,
    ApplicationConfigurationComponent,
    ApplicationConfigurationCreateComponent
  ],
  providers: [
    AdminService,
    ServiceRoleService,
    VehicleManagerService,
    LocationService,
    ApproverThresholdService,
    TempApproverService,
    ApplicationConfigurationService
  ]
})
export class AdminModule { }
