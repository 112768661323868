export class VehicleUsageFull {
  vehicleUsageId: string;
  vehicleId: string;
  vehicleUsageMileageStart: number;
  vehicleUsageMileageEnd: number;
  vehicleUsageDate: Date;
  vehicleUsageNote: string;
  vehicleUsageTripMileage: number;
  vehicleUsageState: VehicleUsageState;
  CreatedByEmployeeId: string;
  vehicleUsageType: VehicleUsageTypes;
}
export class VehicleUsageEditViewModel {
  vehicleUsageId: string;
  vehicleIncorrectEndMileage: number;
  vehicleId: string;
  vehicleUsageMileageStart: number;
  vehicleUsageMileageEnd: number;
  vehicleUsageDate: Date;
  vehicleUsageNote: string;
  vehicleUsageTripMileage: number;
  vehicleUsageState: VehicleUsageState;
  CreatedByEmployeeId: string;
  vehicleUsageType: VehicleUsageTypes;
}
export enum VehicleUsageState {
  Created,
  Submitted
}
export enum VehicleUsageTypes {
  Work,
  Personal
}
