<mat-card>
  <mat-card-content>
    <div>
      <form [formGroup]="modelForm" (ngSubmit)="onCreate()">
        <div class="col-md-4">
          <mat-form-field>
            <input type="text" matInput placeholder="Manufacturer Name" formControlName="manufacturerName" name="manufacturerName"
              [(ngModel)]="model.manufacturerName" />
            <mat-error *ngIf="manufacturerNameControl.hasError('required')">The manufacturer name is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input type="text" matInput placeholder="Model Name" formControlName="vehicleModelName" name="vehicleModelName"
              [(ngModel)]="model.vehicleModelName" />
            <mat-error *ngIf="vehicleModelNameControl.hasError('required')">The model name is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <input type="text" matInput placeholder="Model Year" formControlName="vehcileModelYear" name="vehcileModelYear"
                   [(ngModel)]="model.vehcileModelYear" />
            <mat-error *ngIf="vehcileModelYearControl.hasError('required')">The model year is required</mat-error>
            <mat-error *ngIf="modelForm.get('vehcileModelYear')?.hasError('pattern') || modelForm.controls['vehcileModelYear'].errors?.maxlength || modelForm.controls['vehcileModelYear'].errors?.minlength">Enter valid year in 'YYYY' format</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <button mat-raised-button type="submit" [disabled]="!modelForm.valid">Save</button>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
