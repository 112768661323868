<form *ngIf="thresholdform" [formGroup]="thresholdform" (ngSubmit)="submitTHeshold()">
  <mat-toolbar color="accent">
    <span>
      <button mat-icon-button type="submit">
        <mat-icon>save</mat-icon>
      </button>
    </span>
  </mat-toolbar>
  <mat-card>
    <mat-card-content>
      <mat-form-field>
        <span matPrefix>$</span>
        <input matInput currencyMask placeholder="Enter a threshold" formControlName="amount" />
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Select Approver" formControlName="employeeId" disableOptionCentering id="approver">
          <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">{{emp.firstName + " " + emp.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field *ngIf="threshHoldId">
        <mat-select placeholder="Select Service Roles" [(ngModel)]="selectedServiceRole"
          [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let emp of serviceRoles" [value]="emp.serviceRoleId">{{emp.serviceRoleName}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <!-- <button *ngIf="threshHoldId" title="Add Service Role" type="button" mat-icon-button (click)="addServiceRole()">
        <mat-icon>add</mat-icon>
      </button> -->
      <br>
      <div class="col-md-4">
        <mat-list *ngIf="threshHoldId">
          <mat-list-item *ngFor="let servicerole of serviceRoleArray;let i = index"> {{servicerole.serviceRoleName}}
            <button (click)="removeServiceRole(i)" type="button" mat-icon-button>
              <mat-icon>remove</mat-icon>
            </button> </mat-list-item>
        </mat-list>
      </div>
    </mat-card-content>
  </mat-card>

</form>
