import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectDocumentFilter'
})
export class ProjectDocumentFilterPipe implements PipeTransform {

  transform(documentList: Array<any>, documentType: any): any {
    return documentList.filter(document => document.projectDocumentType == documentType);
  }

}
