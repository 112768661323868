<h2 mat-dialog-title>Approval</h2>
<mat-dialog-content>
  <mat-form-field>
    <textarea matInput [(ngModel)]="note" placeholder="Approval Notes"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button (click)="onNoClick()">Save</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="false">Cancel</button>
</mat-dialog-actions>