import { Component, OnInit } from '@angular/core';
import { UserIdleService } from '../../../core/services/user-idle/user-idle.service';


@Component({
  selector: 'app-timeout-popup',
  templateUrl: './timeout-popup.component.html',
  styleUrls: ['./timeout-popup.component.scss']
})
export class TimeoutPopupComponent implements OnInit {
  timer: number;
  constructor() { }

  ngOnInit() {
    // this.userService.onTimerStart()
    //   .subscribe(timer => { this.timer = timer; });
  }

}
