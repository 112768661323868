
<button  id="btn-close-icon"  mat-icon-button  class="close-button"  (click)="close()">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{ formTitle }}
  </h3>
</div>
<p></p>
<div>
<!-- <div class="card-container"  > -->
<mat-card   [formGroup]="contractPreviewForm">
  <!-- <mat-dialog-content> -->
    <div class="block">

    </div>  <fieldset>
    <legend>Contract Info</legend>


    <div class="row" >
    <div class="col-md-3">
      <div>
        <!-- Customer Name -->
        <mat-form-field>
            <input matInput formControlName="customerName" placeholder="Customer Name" />
            <mat-error *ngIf="contractPreviewForm.controls['customerName'].hasError('required')">
                Customer Name \is required
            </mat-error>
            <span matSuffix></span>
            </mat-form-field>
      </div>
      <div>
        <!-- Quote Expiration  -->
        <mat-form-field>
            <!-- <input matInput type="date" placeholder="Quote Expiration Date" formControlName="quoteExpirationDate"/> -->
            <input [matDatepicker]="picker" matInput placeholder="Quote Expiration Date" type="datetime"
            name="releaseDate" formControlName="quoteExpirationDate" /> <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-error *ngIf="contractPreviewForm.controls['quoteExpirationDate'].hasError('required')">Quote Expiration Date is Required</mat-error>
      </div>
      <div>
        <!-- Quoted By -->
        <mat-form-field>
        <input matInput formControlName="quotedBy" placeholder="Quoted By" />
        </mat-form-field>
      </div>
       <div>
        <!-- Account Manager -->
        <mat-form-field>
        <input matInput formControlName="accountManager" placeholder="Account Manager" />
        </mat-form-field>
      </div>
      <p></p>
    </div>
    <div class="col-md-3">
      <div>
        <!-- Contract Term  -->
        <mat-form-field>
            <mat-label>Contract Term (Years)</mat-label>
            <mat-select formControlName="contractTermYears">
              <mat-option *ngFor="let termYears of contractTermOptions"
                [value]="termYears.value">
                {{ termYears.text }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="contractPreviewForm.controls['contractTermYears'].hasError('required')">
              Contract Term is required
            </mat-error>
          </mat-form-field>
     </div>
    <div>
        <!-- Invoice TCV Upfront -->
        <mat-form-field>
            <mat-label>Invoice TCV Upfront For Full Term</mat-label>
            <mat-select formControlName="fullTermPaymentRequired">
              <mat-option *ngFor="let paymentRequirement of paymentRequirementOptions"
                [value]="paymentRequirement.value">
                {{ paymentRequirement.text }}
            </mat-option>
            </mat-select>
            <mat-error *ngIf="contractPreviewForm.controls['fullTermPaymentRequired'].hasError('required')">
                Invoice TCV Upfront For Full Term is required
            </mat-error>
        </mat-form-field>
      </div>
      <div>
        <!-- Invoicing Frequency  -->
        <mat-form-field>
            <mat-label>Invoicing Frequency </mat-label>
            <mat-select formControlName="invoicingFrequency">
              <mat-option *ngFor="let invoicingFrequency of invoicingFrequencyOptions"
                [value]="invoicingFrequency.value">
                {{ invoicingFrequency.text }}
            </mat-option>
            </mat-select>
            <mat-error *ngIf="contractPreviewForm.controls['invoicingFrequency'].hasError('required')">
                Invoicing Frequency is required
            </mat-error>
        </mat-form-field>
      </div>
  <div>
    <!-- New/Renewal -->
        <mat-form-field>
            <mat-label>New/Renewal</mat-label>
            <mat-select formControlName="newOrRenewal">
              <mat-option *ngFor="let newOrRenewal of newOrRenewalOptions"
                [value]="newOrRenewal.value">
                {{ newOrRenewal.text }}
            </mat-option>
            </mat-select>
            <mat-error *ngIf="contractPreviewForm.controls['newOrRenewal'].hasError('required')">
                New/Renewal is required
            </mat-error>
        </mat-form-field>
      </div>  
    </div>
    <div class="col-md-3">
      <div>
        <!-- sellPriceAdustmentPercentage -->
        <mat-form-field>
          <input matInput type="number" min="0" max="1" step=".01"
          formControlName="sellPriceAdustmentPercentage" placeholder="Sell Price Adjustment(%)"/>
        </mat-form-field>
      </div>
    </div>
  </div>
  </fieldset>   
       
  <div class="row">
    <div class="col-12" align="end">
      <button id="btn-preview" type="submit" mat-raised-button mat-primary (click)="run()" [disabled]="!contractPreviewForm?.valid"><mat-icon>view_list</mat-icon><span>View..</span>.</button>
      <button id="btn-export" type="submit" mat-raised-button mat-primary (click)="exportPreview()">
        <mat-icon>download</mat-icon><span>Export</span>
      </button>
    <a #donwloadExcelLink></a>
    </div>
  </div>
  <br/>
<div>  
    <fieldset>
    <legend>One-Time Setup Fees</legend>
    <cloud-migration-contract-lines *ngIf="oneTimeFees" [contractLines]="oneTimeFees"></cloud-migration-contract-lines>
    </fieldset>
  </div>
  <br/>
  <div>  
    <fieldset>
    <legend>Recurring Fees</legend>
    <cloud-migration-contract-lines *ngIf="recurringFees" [contractLines]="recurringFees"></cloud-migration-contract-lines>
    </fieldset>
  </div>

  <!-- </mat-dialog-content> -->
</mat-card>  <div class="row">
    <div class="col-12">
      <mat-dialog-actions align="end">
        <button
          id="btn-close-dialog"
          class="mat-raised-button"
          (click)="close()"
        >
          Close
        </button>
        <!-- <button
          id="btn-save-contract-preview"
          type="submit"
          mat-raised-button
          mat-primary
          [disabled]="true"
          (click)="save()"
        >
          Save
        </button> -->
      </mat-dialog-actions>
    </div>
  </div>
</div>
<!-- </div> -->
