import { Component, OnInit } from '@angular/core';
import { VehicleScheduleRecordDataSource } from '../../models/vehicle-schedule-record-datasource';
import { FleetMangementService } from '../../fleet-mangement.service';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-vehicle-maintenance-schedule-list',
  templateUrl: './vehicle-maintenance-schedule-list.component.html',
  styleUrls: ['./vehicle-maintenance-schedule-list.component.scss']
})
export class VehicleMaintenanceScheduleListComponent implements OnInit {
  scheduleRecords: VehicleScheduleRecordDataSource;
  displayedColumns = [
    'vehicleMaintenanceScheduleName',
    'vehicleMaintenanceScheduleId',
    'vehicleId'
  ];

  constructor(private fleetService: FleetMangementService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getSchedules();

  }
  deleteScheduleRecord(id: any) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '250px;'
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          this.fleetService.deleteVehicleMaintenanceSchedule(id)
            .subscribe(httpRes => {
              this.getSchedules();
            });

        }
      });
  }
  getSchedules() {
    this.fleetService.getVehicleMaitenanceSchedules()
      .subscribe(res => {
        try {
          const data: any[] = res;
          if (data.length > 0) {
            this.scheduleRecords = new VehicleScheduleRecordDataSource();
            this.scheduleRecords.setData(data);
          }
        } catch (error) {

        }
      });
  }

}
