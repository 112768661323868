<div class="mat-elevation-z8">
  <mat-card id="projectRevenuesCard">
    <h4 class="text-color">Projects Revenue</h4>
    <div class="row">
      <div class="col-md-12 align-left">
        <form [formGroup]="revenueRequestFromGroup" class="dashboard-font row">
          <div class="col-md-5">
            <div>
              <b>Day Range:</b>
              <br />
              <br />
              <mat-radio-group formControlName="noOfDays" class="margin days-margin"
                               (change)="filterRevenueData($event, 'noOfDays')" id="dayRange">
                <mat-radio-button value="90">90 Days</mat-radio-button>
                <mat-radio-button value="180">180 Days</mat-radio-button>
                <mat-radio-button value="360">360 Days</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div>
              <b>Date Range:</b>
              <br />
              <mat-form-field appearance="fill" class="days-margin">
                <mat-label>Choose Date</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate placeholder="Start date" #startDate formControlName="startDate">
                  <input matEndDate placeholder="End date" #endDate formControlName="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div>
              <b>Status:</b>
              <br />
              <br />
              <mat-radio-group formControlName="projectStatus" class="margin days-margin"
                               (change)="filterRevenueData($event, 'projectStatus')" id="projectStatus">
                <mat-radio-button value="0">Active</mat-radio-button>
                <mat-radio-button value="3">Complete</mat-radio-button>
                <mat-radio-button value="2">Lost</mat-radio-button>
              </mat-radio-group>
            </div>
            <br />
            <div>
              <b>Service Role:</b>
              <br />
              <mat-form-field>
                <mat-select placeholder="Select Service Role" formControlName="serviceRoleIds" disableOptionCentering multiple id="serviceRoleIds"
                  (selectionChange)="filterRevenueData($event, 'serviceRoleIds')">
                  <mat-option *ngFor="let sr of serviceRoles" [value]="sr.serviceRoleId">
                    {{sr.serviceRoleName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="reset-btn">
            <button mat-icon-button type="button" id="resetBtn" (click)="resetFilters()" title="Clear Filters">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </form>
      </div>

    </div>
    <div>
      <!-- <h4 class="align-center text-color"><span class="text-color" *ngIf="getProjectStatus() == 0">Active</span><span
          class="lost" *ngIf="getProjectStatus() == 2">Lost</span><span *ngIf="getProjectStatus() == 3"
          class="complete">Complete</span> Projects Revenue</h4> -->
      <div *ngIf="revenueData?.length == 0 && !isLoading" class="align-center">
        No records found
      </div>
      <div *ngIf="isLoading" class="no-records">
        ...Loading the records. Please wait
      </div>

      <div [hidden]="revenueData?.length == 0 || isLoading">
        <canvas id="barChart" #barChart [attr.barChart-data]="getRevenueData(true)"></canvas>
      </div>
    </div>
  </mat-card>
</div>
