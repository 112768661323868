import { Component, OnInit } from '@angular/core';
import { VehicleDataSource } from './../models/vehicle-datasource';
import { VehicleUsageDataSource } from './../models/vehicle-usage-datacource';
import { FleetMangementService } from './../fleet-mangement.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MileageConfirmationDialogComponent } from '../../shared/components/mileage-confirmation-dialog/mileage-confirmation-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Employee } from '../../shared/models/employee';
import { AdminService } from '../../admin/admin.service';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from 'src/app/shared/models/id-token-claims';


@Component({
  selector: 'app-fleet-management-landing-page',
  templateUrl: './fleet-management-landing-page.component.html',
  styleUrls: ['./fleet-management-landing-page.component.scss']
})
export class FleetManagementLandingPageComponent implements OnInit {
  employees: Array<any>;
  isDriver: boolean;

  dataSource: VehicleDataSource;
  adminDataSource: VehicleDataSource;
  roles: string[] | string;
  isUser: boolean;
  isAdmin: boolean;
  usageDataqSource: VehicleUsageDataSource;
  hasCheckedOutCar: boolean;
  checkedOutVehicles: VehicleDataSource;
  displayedColumns = [
    'vehicleNumber',
    'vehicleStatus',
    'vehicleMileageCurrent',
    'vehicleId'
  ];
  usageColumns = [
    'vehicleUsageNote',
    'vehicleUsageId'
  ];
  constructor(private service: FleetMangementService,
    private authService: MsalService,
    public dialog: MatDialog,
    private router: Router,
    private adminService: AdminService,
    private route: ActivatedRoute) {

  }

  ngOnInit() {
    let idTokenClaims = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
    if (idTokenClaims) {
      this.roles = idTokenClaims['roles'];
      this.checkIfAdmin();
      this.checkIfUser();
    }
    this.hasCheckedOutCar = false;
    this.isDriver = true;


    if (this.isUser) {
      this.service.getAzureId()
        .subscribe(res => {
          this.service.checkIfDriver(res as string)
            .subscribe(driverResult => {
              if (driverResult) {
                this.isDriver = true;
                this.runCarCheck();
              } else {
                this.isDriver = false;
              }

            });
        });
    }
    if (this.isAdmin) {
      this.runAdminCheck();
    }

  }
  deleteVehicle(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.service.deleteVehicle(id)
            .subscribe(res => {
              if (this.isUser && this.isDriver) {
                this.runCarCheck();
              } if (this.isAdmin) {
                this.service.getVehicles()
                  .subscribe(res2 => {
                    this.refreshAdminData(res2);
                    this.checkedOutVehicles = new VehicleDataSource();
                  });
              }
            });
        }
      });

  }
  checkIfAdmin() {
    this.isAdmin = this.roles.includes('FleetMangementAdmin');
    if (!this.isAdmin) {
      this.isAdmin = this.roles.includes('PortalAdmin');
    }

    // if (isVehicleAdmin) {
    //   return true;
    // } else {
    //   // return this.roles.includes('PortalAdmin');
    //   return false;
    // }
  }
  runAdminCheck() {
    this.service.getVehicles()
      .subscribe(res => {
        this.service.getDrivers()
          .subscribe(employeesResponse => {
            this.employees = employeesResponse;

            // this.dataSource = new VehicleDataSource();
            // this.dataSource.setData(res);
          });
        this.refreshAdminData(res);
        this.checkedOutVehicles = new VehicleDataSource();
      });
  }
  checkIfUser() {
    // this.roles.includes('FleetManagementUser');
    // const isUser = true;
    // if (isUser) {
    //   // this.hasCarCheckedOut();
    //   return isUser;
    // }
    this.isUser = this.roles.includes('FleetManagementUser');
  }
  runCarCheck() {
    this.service.getAzureId().subscribe(aId => {
      this.service.checkForCheckedOutCar(aId)
        .subscribe(response => {
          this.hasCheckedOutCar = response;
          if (this.hasCheckedOutCar) {
            this.service.getCheckedOutCarsByUser(aId as string)
              .subscribe(res => {
                this.checkedOutVehicles = new VehicleDataSource();
                const data = res;
                this.checkedOutVehicles.setData(data);
              });
          } else {
            this.service.getVehicles()
              .subscribe(res => {
                this.refreshVehicleData(res);
              });
          }

        });
    });
  }
  checkOutVehicle(id: string) {

    this.service.getAzureId().subscribe(aId => {
      this.service.checkoutVehicle(id, aId as string)
        .subscribe(resp => {
          this.service.getCheckedOutCarsByUser(aId as string)
            .subscribe(res => {
              if (this.isAdmin) {
                this.service.getVehicles()
                  .subscribe(res2 => {
                    this.refreshAdminData(res2);
                  });
              }
              this.checkedOutVehicles = new VehicleDataSource();
              const data = res;
              this.checkedOutVehicles.setData(data);
              this.hasCheckedOutCar = true;
            });

        });
    });
  }
  checkInVehicle(id: string) {
    const dialogRef = this.dialog.open(MileageConfirmationDialogComponent, {});
    dialogRef.disableClose = true;
    dialogRef.afterClosed()
      .subscribe((dialogResult: boolean) => {
        if (dialogResult) {
          this.router.navigate(['addExpenseAndMileage', id], { relativeTo: this.route });
        } else {
          this.service.checkInVehicle(id)
            .subscribe(resp => {
              this.runCarCheck();
              if (this.isAdmin) {
                this.service.getVehicles()
                  .subscribe(res2 => {
                    this.refreshAdminData(res2);
                    this.checkedOutVehicles = new VehicleDataSource();
                  });
              }
            });
        }
      });
  }
  filterEmployee(employeeId: string) {
    const employee = this.employees.filter(_employee => _employee.vehicleDriverId === employeeId)[0];
    if (employee) {
      return employee.firstName + ' ' + employee.lastName;
    } else {
      return '';
    }

  }
  refreshAdminData(res: any) {
    let data: Array<any> = res;
    this.adminDataSource = new VehicleDataSource();
    if (this.isUser) {
      data = data.filter(v => v.vehicleStatus === 0);
    }
    this.adminDataSource.setData(data);
  }

  refreshVehicleData(res: any) {
    this.dataSource = new VehicleDataSource();
    let data: Array<any> = res;
    data = data.filter(v => v.vehicleStatus === 0);
    this.dataSource.setData(data);
  }

}
