import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-business-partner-landing-report',
  templateUrl: './business-partner-landing-report.component.html',
  styleUrls: ['./business-partner-landing-report.component.scss']
})
export class BusinessPartnerLandingReportComponent implements OnInit {

  reportOption: UntypedFormControl;
  currentRoute: any;
  routeUrlPath: string;
  constructor(private router: Router, private reportsService: ReportsService) { }

  ngOnInit(): void {
    this.reportOption = new UntypedFormControl();
    let routeUrl = this.router.url.split("/");
    this.routeUrlPath = routeUrl[routeUrl.length - 1];
    this.currentRoute = this.reportsService.getReportState(this.routeUrlPath);
    this.setData();
    this.reportOption.valueChanges.subscribe(val => {
      this.reportsService.setReportState(this.routeUrlPath, val);
    });
  }
  setData() {
    this.reportOption.setValue(this.currentRoute);
  }
}
