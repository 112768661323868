<div *ngIf="cloudProviders">
    <div class="container-fluid">
        <mat-card>
            <mat-card-content>
                <h4>Cloud Providers</h4>
                <!-- <a mat-raised-button [routerLink]="['create']" color="primary">Create</a> -->
                <mat-table #table [dataSource]="cloudProviders" id="tbl-cloud-providers">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span> {{element.name}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="cloudProviderActions">
                        <mat-header-cell *matHeaderCellDef>Options</mat-header-cell>
                        <mat-cell *matCellDef="let element" id="kebabMenu">
                            <ng-container>
                                <button mat-icon-button [matMenuTriggerFor]="menu" [attr.data-id]="element.id">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item [routerLink]="['detail', element.id]">
                                        <mat-icon>edit</mat-icon>
                                        <span>View</span>
                                    </button>
                                </mat-menu>
                            </ng-container>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </mat-card-content>
        </mat-card>
    </div>
</div>
