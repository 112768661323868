import { Employee } from '../../shared/models/employee';


export class VehicleModel {
  vehicleModelId: string;
  manufacturerName: string;
  vehicleModelName: string;
  vehcileModelYear: number;
  vehicleMaintenanceMasterSchedule: VehicleMaintenanceSchedule;
}
export class Vehicle {
  vehicleId: string;
  assignedEmployeeId: string;
  vin: string;
  vehicleNumber: string;
  primaryLocationId: string;
  vehicleManagerId: string;
  vehicleIsActive: boolean;
  vehicleRegistrationId: string;
  vehicleMaintenanceScheduleId: string | null;
  vehicleMileageStart: number;
  vehicleMileageCurrent: number;
  vehicleModelId: string;
  vehicleRegistraionId: string;
  vehicleRegistration: VehicleRegistration;
  vehicleEzPass: string;
  vehicleAssignedToEmployeeId: string;
  softDelete: boolean;
  licensePlateNumber: string;
  vehicleColor: string;
  vehicleGasCards: VehicleGasCard[];
  vehicleValvolineCards: VehicleValvolineCard[];
  vehicleRegionStateId: string;
  vehicleWrightPromptId: string;
  vehicleRegionCityId: string;
  vehiclePin: string;
  vehicleAAAMembership: string;
}
export class VehicleRegistration {
  vehicleRegistrationId: string;
  vehicle: Vehicle;
  expirationDate: Date;
  licensePlateNumber: string;
  licensePlateState: string;
}
export class VehicleInsurance {
  vehicleInsuranceCompanyName: string;
  vehicleInsuranceId: string;
  vehicleInsuranceCertificate: string;
  vehicleInsuranceStartDate: Date;
  vehicleInsuranceEndDate: Date;
  vehicleInsurancePolicyNumber: string;
  vehicleInsuranceDaysRemaining: number;
  vehicleInsuranceState: InsuranceState;

}
export class VehicleCreateInsurance {
  vehicleInsuranceId: string;
  vehicleInsuranceCompanyName: string;
  vehicleInsuranceCertificate: string;
  vehicleInsuranceStartDate: Date;
  vehicleInsuranceEndDate: Date;
  vehicleInsurancePolicyNumber: string;
  vehicleInsuranceDaysRemaining: number;
  vehicleInsuranceState: InsuranceState;
  insurancePhoto: File;
}
export class VehicleMaintenanceSchedule {
  constructor(scheduleItem?: any) {
    if (scheduleItem) {
      this.vehicleMaintenanceScheduleId = scheduleItem.vehicleMaintenanceScheduleId ? scheduleItem.vehicleMaintenanceScheduleId : null;
      this.vehicleMaintenanceScheduleName = scheduleItem.vehicleMaintenanceScheduleName ? scheduleItem.vehicleMaintenanceScheduleName : null;
      if (scheduleItem.vehicleMaintenanceScheduleItems) {
        this.vehicleMaintenanceScheduleItems = [];
        scheduleItem.vehicleMaintenanceScheduleItems.forEach((item: any) => {
          const _item = new VehicleMaintenanceScheduleItem(item);

          this.vehicleMaintenanceScheduleItems.push(_item);
        });
      }
    }
  }
  vehicleMaintenanceScheduleId: string;
  vehicleMaintenanceScheduleName: string;
  vehicleMaintenanceScheduleItems: Array<VehicleMaintenanceScheduleItem>;
}

export class VehicleMaintenanceScheduleItem {
  constructor(item?: any) {
    if (item) {
      this.vehicleMaintenanceItemName = item.vehicleMaintenanceItemName ? item.vehicleMaintenanceItemName : null;
      this.vehcileMaintenanceItemMileageInterval = item.vehcileMaintenanceItemMileageInterval ? item.vehcileMaintenanceItemMileageInterval : null;
      this.vehcileMaintenanceItemDateIntervalInDays = item.vehcileMaintenanceItemDateIntervalInDays ? item.vehcileMaintenanceItemDateIntervalInDays : null;
      this.vehicleMaintenanceSchedule = item.vehicleMaintenanceSchedule ? item.vehicleMaintenanceSchedule : null;
      this.vehicleMaintenanceItemNextMileageInterval = item.vehicleMaintenanceItemNextMileageInterval ? item.vehicleMaintenanceItemNextMileageInterval : null;
      this.vehcileMaintenanceItemNextDateIntervalInDays = item.vehcileMaintenanceItemNextDateIntervalInDays ? item.vehcileMaintenanceItemNextDateIntervalInDays : null;
      this.vehicleMaintenanceScheduleId = item.vehicleMaintenanceScheduleId ? item.vehicleMaintenanceScheduleId : null;
    }
  }
  vehicleMaintenanceScheduleItemId: string;
  vehicleMaintenanceItemName: string;
  vehcileMaintenanceItemMileageInterval: number;
  vehcileMaintenanceItemDateIntervalInDays: number;
  vehicleMaintenanceSchedule: VehicleMaintenanceSchedule;
  vehicleMaintenanceItemNextMileageInterval: number;
  vehcileMaintenanceItemNextDateIntervalInDays: number;
  vehicleMaintenanceScheduleId: string;
}
export class VehicleMaintenanceRecord {
  vehicleMaintenanceRecordId: string;
  vehicle: Vehicle;
  vehicleMaintenanceScheduleItem: VehicleMaintenanceScheduleItem;
  vehicleMaintenanceDate: Date;
  vehicleMileage: number;
}
export class VehicleExpense {
  vehicleId: string;
  vehicleExpenseId: string;
  vehicle: Vehicle;
  vehicleExpenseType: VehicleExpenseType;
  vehicleExpenseReceipt?: File;
  vehicleExpenseDescription: string;
  vehicleExpenseCost: number;
  vehicleExpenseDate: Date;
  createdByEmployeeId: string;
}
export class VehicleUsage {
  vehicleUsageId: string;
  vehicle: Vehicle;
  vehicleUsageMileageStart: number;
  vehicleUsageMileageEnd: number;
  vehicleUsageNote: string;
  vehicleUsageTripMileage: number;
  vehicleUsageDate: Date;
  vehicleUsageDescription: string;
}
export class VehicleSell {
  vehicleSellId: string;
  vehicle: Vehicle;
  vehicleSellNotes: string;
}
export class VehicleIncident {
  vehicleIncidentId: string;
  vehicle: Vehicle;
  vehicleIncidentDate: Date;
  vehicleIncidentNotes: string;
  vehicleIncidentDriver: Employee;
}
export enum VehicleModelType {
  Car,
  Truck,
  SUV,
  Van,
  Other
}
export enum VehicleExpenseType {
  Fuel,
  Maintenance,
  Other
}
export class VehicleAdmin {
  vehicleAdminId: string;
  userId: string;
}
export class VehicleInsuranceAdmin {
  vehicleInsuranceAdminId: string;
  userId: string;
}
export enum InsuranceState {
  active,
  expiring,
  expired
}

export class VehicleValvolineCard {
  VehicleCardId: string;
  VehicleCardName: string;
  VehicleCardNumber: string;
  ExpirationDate: Date;
  AssignedToVehicleId: string;
  AssignedVehicle: Vehicle;
  ValvolinePin: string;
}

export class VehicleGasCard {
  VehicleCardId: string;
  VehicleCardName: string;
  VehicleCardNumber: string;
  ExpirationDate: Date;
  AssignedToVehicleId: string;
  AssignedVehicle: Vehicle;
  GasStationPin: string;
}