import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-migration-discovery-networkprofile-list',
  templateUrl: './cloud-migration-discovery-networkprofile-list.component.html',
  styleUrls: ['./cloud-migration-discovery-networkprofile-list.component.scss']
})
export class CloudMigrationDiscoveryNetworkprofileListComponent implements OnInit {
  @Input() networkProfiles: any;
  displayedColumns: string[];
  discoveryId: any;
  loading: boolean;
  dataSource: any[];
   
  constructor() { }

  ngOnInit(): void {
    this.dataSource = this.networkProfiles?.rows;
    this.displayedColumns = this.networkProfiles?.displayedColumns;
  }
  

}
