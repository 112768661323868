import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-approval-component',
  templateUrl: './approval-component.component.html',
  styleUrls: ['./approval-component.component.scss']
})
export class ApprovalComponentComponent implements OnInit {

  note: string;

  constructor(public dialogRef: MatDialogRef<ApprovalComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.note = "";
  }
  onNoClick() {
    this.dialogRef.close(this.note);
  }
}
