import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Assessment } from '../../../models/assessment-datasource';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { StorageSummaryData } from '../../cloud-migration-assessment-detail/cloud-migration-assessment-detail.component';

@Component({
  selector: 'app-cloud-migration-storage-summary',
  templateUrl: './cloud-migration-storage-summary.component.html',
  styleUrls: ['./cloud-migration-storage-summary.component.scss']
})
export class CloudMigrationStorageSummaryComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  tableDataSource: any;
  @Input() storageSummaryData: StorageSummaryData;
  @Input() storageCapacityUpdated: boolean;
  @Input() storageTypeUpdated: boolean;
  displayedColumns: string[];
  constructor() { }

  ngOnInit(): void {
    this.setData();
   
  }
  setData() {
    this.tableDataSource = this.storageSummaryData?.storageSummaryRows;
    this.displayedColumns = this.storageSummaryData?.displayedColumns;
  }

  ngOnChanges(){
    this.setData();
  }

}
