import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-migration-analysis-complexity',
  templateUrl: './cloud-migration-analysis-complexity.component.html',
  styleUrls: ['./cloud-migration-analysis-complexity.component.scss']
})
export class CloudMigrationAnalysisComplexityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
