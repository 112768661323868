import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnterpriseUnifiedProcessComponent } from './enterprise-unified-process/enterprise-unified-process.component';
import { HelpComponent } from './help.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { VideosComponent } from './videos/videos.component';
import { ReleaseNotesWorkItemDetails } from './release-notes/release-notes-workitem-details/release-notes-workitem-details.component';
import { ReleaseNotesHistoryComponent } from './release-notes/release-notes-history/release-notes-history.component';

export const routes: Routes = [
  {
    path: 'help', component: HelpComponent, data: {
      breadcrumb: 'Resources', isRoutable: true
    }, children: [
      {
        path: '', redirectTo: 'enterpriseUnifiedProcess', pathMatch: 'full'
      },
      {
        path: 'enterpriseUnifiedProcess', component: EnterpriseUnifiedProcessComponent, data: {
          breadcrumb: 'Enterprise Unified Process', isRoutable: true
        }
      },
      {
        path: 'videos', component: VideosComponent, data: {
          breadcrumb: 'Help Videos', isRoutable: true
        }
      },
      {
        path: 'releaseHistory', component: ReleaseNotesComponent, data: {
          breadcrumb: 'Release History', isRoutable: true
        }, children: [
          {
            path: '', component: ReleaseNotesHistoryComponent,
            data: {
              breadcrumb: null, isRoutable: true
            }
          },
          {
            path: 'details/:id', component: ReleaseNotesWorkItemDetails, data: {
              breadcrumb: 'Release Note Details', isRoutable: true
            }
          }
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HelpRoutingModule { }
