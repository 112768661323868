import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';

export class MileageEmployeeDataSource extends DataSource<MileageEmployeeDataResult> {
    data: Array<MileageEmployeeDataResult>;
    connect(): Observable<MileageEmployeeDataResult[]> {

        return of(this.data);
    }
    disconnect(collectionViewer: CollectionViewer): void {

    }
    setData(data: Array<MileageEmployeeDataResult>) {
        this.data = data;
    }

}
export class MileageEmployeeDataResult {
    vehicleNumber: string;
    totalMiles: number;
    dateEntered: Date;
}
