import { VehicleInsurance } from './fleet-management-models';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
export class VehicleInsuranceDataSource extends DataSource<any> {
    data: Array<VehicleInsurance>;
    connect(): Observable<VehicleInsurance[]> {

        return of(this.data);
    }
    disconnect(collectionViewer: CollectionViewer): void {

    }
    setData(data: Array<VehicleInsurance>) {
        this.data = data;
    }

}
