<mat-card>
  <mat-card-content>
    <mat-form-field>
      <input [(ngModel)]="oppurtunityNumber" matInput placeholder="BF Number" type="text" />
    </mat-form-field>
    <button (click)="searchSEs()" mat-icon-search type="button">
      <mat-icon>search</mat-icon>
    </button>
    <mat-table matSort #table [dataSource]="dataSource">
      <ng-container matColumnDef="projectName">
        <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header> Created Date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createdDate | date:'shortDate'}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="expirationDate">
        <mat-header-cell *matHeaderCellDef class="clicker" mat-sort-header> Expiration Date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.expirationDate | date:'shortDate'}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="element.serviceEstimateStatus == 0">Components still in progress</span>
          <span *ngIf="element.serviceEstimateStatus == 1">Pending Director Approval/Override</span>
          <span *ngIf="element.serviceEstimateStatus == 2">Approved</span>
          <span *ngIf="element.serviceEstimateStatus == 3">Expired</span>
          <span *ngIf="element.serviceEstimateStatus == 4">Contract Signed</span>
          <span *ngIf="element.serviceEstimateStatus == 5">Pending PM Approval/Override</span>
          <span *ngIf="element.serviceEstimateStatus == 6">Pending VP Threshold Approval</span>
          <span *ngIf="element.serviceEstimateStatus == 7">SE needs submission</span>
          <span *ngIf="element.serviceEstimateStatus == 8">Component Pending Rate Approval</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-raised-button color="accent" type="button" [disabled]="element.serviceEstimateId == data?.seId ? true : false"
                  (click)="closePopup(element)">
            {{element.serviceEstimateId == data?.seId ? 'Currrent' : 'Select'}}
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="!(dataSource && dataSource.data.length > 0)" class="no-records">
      No records found
    </div>
    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [ngStyle]="{display: dataSource?.data.length > 0 ? 'block' : 'none'}"
                     [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>
