import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardRequestModel } from './models/dashboard-request.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getProjectInfo(dashboardRequestModel: any) {
    const queryString = this.convertToQueryString(dashboardRequestModel);
    return this.http.get(`/api/dashboard/serviceProjects?${queryString}`);
  }

  getSEInfo(dashboardRequestModel: any) {
    const queryString = this.convertToQueryString(dashboardRequestModel);
    return this.http.get(`/api/dashboard/serviceEstimates?${queryString}`);
  }

  getSECInfo(dashboardRequestModel: any) {
    const queryString = this.convertToQueryString(dashboardRequestModel);
    return this.http.get(`/api/dashboard/serviceEstimateComponents?${queryString}`);
  }

  getActiveProjects(dashboardRequestModel: any) {
    const queryString = this.convertToQueryString(dashboardRequestModel);
    return this.http.get(`/api/dashboard/activeServiceProjects?${queryString}`);
  }

  getServiceRoleRevenueInfo(dashboardRequestModel?: any) {
    const queryString = this.convertToQueryString(dashboardRequestModel);
    return this.http.get(`/api/dashboard/serviceEstimateComponentRevenues?${queryString}`);
  }

  convertToQueryString(parameters: any) {
    let entries: any = [];
    const params = new URLSearchParams();
    for (const key in parameters) {
      if (parameters[key]) {
        if (typeof (parameters[key]) != 'string') {
          if (parameters[key]?.length > 0) {
            entries = [];
            parameters[key].forEach((item: any) => {
              params.append(key, item);
            })
          }
          //Object.entries(parameters[key]).forEach((x) => entries.push(["serviceRoleIds", x[1]]));
          //const subParams = new URLSearchParams(entries);
          //params.set(subParams.toString(), '');
        }else
        params.set(key, parameters[key]);
      }
    }
    return params.toString();
  }
}
