import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { AssignDriversPopupComponent } from 'src/app/shared/components/assign-drivers-popup/assign-drivers-popup.component';
import { AdminService } from '../../admin/admin.service';
import { FleetMangementService } from '../../fleet-management/fleet-mangement.service';
import { VehicleDataSource } from '../../fleet-management/models/vehicle-datasource';
import { ConfirmDeleteDialogComponent } from '../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { Employee } from '../../shared/models/employee';
import { SalesforceTestingDatasource } from '../models/salesforce-testing-datasource';
import { SalesforceTestingData } from '../models/salesforce-testing.models';
import { SalesforceTestingService } from '../salesforce-testing-service/salesforce-testing.service';

@Component({
  selector: 'app-salesforce-testing-list',
  templateUrl: './salesforce-testing-list.component.html',
  styleUrls: ['./salesforce-testing-list.component.scss']
})
export class SalesForceTestingListComponent implements OnInit {
  dataSource: SalesforceTestingDatasource;
  isNameAscending: boolean;
  pagination: any;
  pageSize: number;
  salesforceTestingData: SalesforceTestingData[];
  displayedColumns = [
    'createdDate',
    'rowKey',
    'contactSalesforceID',
    'customerSalesforceID',
    'epicorCustomerID',
    'externalID',
    'status',
    'customerDataError',
    'contactDataError',
    'shippingLocationDataError',
    'billingLocationDataError'
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isLoading: boolean;
  constructor(private _salesforceTestingService: SalesforceTestingService, public dialog: MatDialog) { }

  ngOnInit() {
    this.isLoading = false;
    this.pageSize = 50;
    this.isNameAscending = true;
    this._salesforceTestingService.getData().subscribe( data => {
      console.log('data', data)
      this.salesforceTestingData = data;
      this.setTableData(this.salesforceTestingData);
      this.isLoading = true;
    })
  }

  setTableData(data?: Array<any>) {
    this.dataSource = new SalesforceTestingDatasource();
    let pageAmount = 50;
    if (!data) {
      if (this.pagination) {
        pageAmount = this.pagination.pageSize;
      }
      const initData = this.salesforceTestingData?.slice(0, pageAmount);
      this.dataSource.setData(initData);
    } else {
      this.dataSource.setData(data);
    }
  }
}