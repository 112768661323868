import { Component, OnInit, Inject} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cloud-migration-discovery-popup',
  templateUrl: './cloud-migration-discovery-popup.component.html',
  styleUrls: ['./cloud-migration-discovery-popup.component.scss']
})
export class CloudMigrationDiscoveryPopupComponent implements OnInit{
  discoveryForm: UntypedFormGroup;
  environmentList: any = [];
  projectId: any;
  formTitle: string = "Create Discovery";
  environmentListOptions: any;
  createdDiscovery: any;

  constructor(
    public dialogRef: MatDialogRef<CloudMigrationDiscoveryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private router: Router,
    private service: CloudMigrationToolService
  ){}

  ngOnInit(): void {
    console.log("create discovery data: ", this.data);
    
    this.environmentList = this.data?.environmentList?.rows;
    this.projectId = this.data?.projectId;
    this.discoveryForm = this.createForm();
    this.environmentListOptions = this.environmentList?.map((s: any) => ({value: s.id, text: s.name}));
  }

  createForm(): UntypedFormGroup{
    return new UntypedFormGroup({
      environmentId: new UntypedFormControl("", Validators.required)
    })
  }

  save(){
    let environmentId = this.discoveryForm.value.environmentId;
    let discovery = {
      projectId: this.projectId,
      environmentId: environmentId
    }
    this.service.addProjectDiscovery(discovery)
      .then((res: any) => {
        this.createdDiscovery = res.data;
        this.close(this.createdDiscovery);
    });

  }

  close(result?:any){
    if(result){
      this.router.navigate(['','cmt','projects',this.projectId,'view','discoveries',this.createdDiscovery.id]);
      this.dialogRef.close();
    }
    else{
      this.dialogRef.close();
    }
  }
}
