import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AppRoleDataSource } from '../../models/app-role.datasource';
import { AppRole } from '../models/appRole';
import { GraphApiService } from '../../../shared/services/graph-api.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { RoleAdministrationService } from '../role-administration.service';

@Component({
  selector: 'app-role-admin-list',
  templateUrl: './role-admin-list.component.html',
  styleUrls: ['./role-admin-list.component.scss']
})
export class RoleAdminListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<AppRole>;
  appRoleDataSource: AppRoleDataSource;
  isLoading: boolean;
  appRoles: any;
  filterForm: UntypedFormGroup;
  canBeDeleted: boolean;

  displayedColumns = ['displayName', 'description', 'allowedMemberTypes', 'value', 'state', 'actions'];

  constructor(private fb: UntypedFormBuilder, private dialog: MatDialog, private roleAdminService: RoleAdministrationService) { this.isLoading = true; }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      displayNameFilter: [''],
      stateFilter: ['']
    });
    this.canBeDeleted = true;
    this.filterForm.valueChanges
      .subscribe(res => {
        this.appRoleDataSource = new AppRoleDataSource(this.appRoleDataSource.filter(res, this.appRoles));
        this.appRoleDataSource.sort = this.sort;
        this.appRoleDataSource.paginator = this.paginator;
        this.table.dataSource = this.appRoleDataSource;
      });
    this.getAppRolesData();
  }
  getAppRolesData() {
    this.roleAdminService.getAppRoles().subscribe(res => {
      this.appRoles = res;
      this.appRoleDataSource = new AppRoleDataSource(this.appRoles);
      this.appRoleDataSource.sort = this.sort;
      this.appRoleDataSource.paginator = this.paginator;
      this.table.dataSource = this.appRoleDataSource;
    }, (error) => {

    }, () => {
      this.isLoading = false;
    });

  }

  deleteRecord(roleId: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.roleAdminService.getAssignedAppRoleUsers(roleId)
            .subscribe((res: any) => {
              if (res.length == 0) {
                this.canBeDeleted = true;
                this.roleAdminService.deleteAppRole(roleId)
                  .subscribe((res: any) => {
                    this.getAppRolesData();
                  });
              } else {
                this.canBeDeleted = false;
              }
            });
        }
      });
  }
  resetFilters() {
    this.filterForm.controls['displayNameFilter'].setValue('');
    this.filterForm.controls['stateFilter'].setValue('');
  }

}
