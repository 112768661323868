import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByCityId'
})
export class FilterByCityIdPipe implements PipeTransform {

  transform(serviceableCities: any, cities?: any): any {
    let _cities: Array<any>;
    _cities = [];
    cities.forEach((t: any) => {
      serviceableCities.forEach((sc: any) => {
        if (t.cityId == sc.cityId) {
          _cities.push(t);
        }
      });
    });
    return [...new Set(_cities.map(item => item))];
  }

}
