import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ProjectControlRateService } from '../project-control-rate.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-control-rate-create',
  templateUrl: './project-control-rate-create.component.html',
  styleUrls: ['./project-control-rate-create.component.scss']
})
export class ProjectControlRateCreateComponent implements OnInit {
  projectControlRateFormGroup: UntypedFormGroup;
  minHourValue: number;

  constructor(private fb: UntypedFormBuilder, private projectControlRateService: ProjectControlRateService, private router: Router, private location: Location) { }

  ngOnInit() {
    this.projectControlRateFormGroup = this.fb.group({
      'projectControlRateName': ['', Validators.required],
      'projectControlRateDescription': ['', Validators.required],
      'projectControlRateMinHours': [null, Validators.compose([Validators.required, Validators.min(1), Validators.pattern("^[0-9]+$")])],
      'projectControlRateMaxHours': [null, Validators.compose([Validators.required, Validators.pattern("^[0-9]+$")])],
      'projectControlRatePercentage': [null, Validators.required]
    });
    this.projectControlRateFormGroup.controls['projectControlRateMinHours'].valueChanges
      .subscribe(formValue => {
        let maxHourControl = this.projectControlRateFormGroup.controls['projectControlRateMaxHours'];
        let validators = [Validators.required, Validators.min(this.convertFloat2int(formValue) + 1), Validators.pattern("^[0-9]+$")];

        maxHourControl.setValidators(Validators.compose(validators));
        maxHourControl.updateValueAndValidity();
      });
  }
  submitForm() {
    if (this.projectControlRateFormGroup.valid) {
      this.projectControlRateService.createProjectControlRate(this.projectControlRateFormGroup.value)
        .subscribe(createResult => {
          const projectControlRate = createResult;
          // this.router.navigate(['/admin', 'projectControlRates', 'detail', projectControlRate.projectControlRateId]);
          this.location.back();

        });
    }
  }
  convertFloat2int(value: any) {
    return value | 0;
  }

}
