import { Component, OnInit } from '@angular/core';

import { FleetMangementService } from '../fleet-mangement.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-fuel',
  templateUrl: './add-fuel.component.html',
  styleUrls: ['./add-fuel.component.scss']
})
export class AddFuelComponent implements OnInit {
  fuel: any;
  id: any;
  constructor(private vehicleService: FleetMangementService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        if (this.fuel) {

        } else {
          this.fuel = {};
        }
        this.id = params['id'];
        this.fuel.vehicleId = this.id;

      });
  }
  saveFuel() {
    this.vehicleService.addRefuelItem(this.fuel);
  }

}
