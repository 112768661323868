import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-component-changes',
  templateUrl: './confirm-component-changes.component.html',
  styleUrls: ['./confirm-component-changes.component.scss']
})
export class ConfirmComponentChangesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponentChangesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

}
