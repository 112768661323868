<div class="mat-elevation-z8">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Employee Details</mat-card-title>
    </mat-card-header>
    <div [class.loading]="!loading" style="position: relative; margin:auto;width:calc(50% + 225px)">
      <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
    </div>
    <mat-card-content *ngIf="!loading">
      <mat-list>
        <mat-list-item> First Name:  {{getEmployeeBasicDetails().givenName}} </mat-list-item>
        <mat-list-item> Last Name:  {{getEmployeeBasicDetails().surname}} </mat-list-item>
        <mat-list-item> Email:  {{getEmployeeBasicDetails().mail}} </mat-list-item>
      </mat-list>
      <div class="row">
        <div class="limitHeight col-sm-4">
          <h4>Assigned Application Roles</h4>
          <div cdkDropList #rolesDragAndDrop="cdkDropList" [cdkDropListData]="roles" class="list"
               (cdkDropListDropped)="dropAppRole($event)">
            <div class="list-item" *ngFor="let role of roles" [cdkDragData]="role" cdkDrag [cdkDragDisabled]="true">
              <div class="example-handle cursor-sym" cdkDragHandle>
                <mat-icon>drag_indicator</mat-icon>
              </div>
              <span> {{role.displayName}}</span>
              <button mat-icon-button type="button" class="reset-icon" (click)="removeRole(role)" [ngClass]="{disabled: isViewOnly}" [disabled]="isViewOnly" matTooltip="Remove Role" [matTooltipPosition]="'after'">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="limitHeight col-sm-4">
          <h4>Available Application Roles</h4>
          <div cdkDropList #appRolesDragAndDrop="cdkDropList" [cdkDropListData]="appRoles" class="list"[cdkDropListConnectedTo]="[rolesDragAndDrop]" (cdkDropListExited)="moved($event)" (cdkDropListEntered)="dragStart($event)">
            <div class="list-item" *ngFor="let role of appRoles" [ngClass]="{disabled: isDisabled(role)}"
                 [cdkDragData]="role" cdkDrag [cdkDragDisabled]="isDisabled(role)">
              <div class="example-handle" cdkDragHandle  matTooltip="Drag and Drop Roles to Assign" [matTooltipPosition]="'below'">
                <mat-icon>drag_indicator</mat-icon>
              </div>
              <span> {{role.displayName}}</span>
            </div>
          </div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</div>
