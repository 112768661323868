<div class="mat-elevation-z8">
  <div [class.loading]="!loading" style="position: relative; margin:auto;width:calc(50% + 225px)">
    <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
  </div>
  <mat-card [class.loading]="loading">
    <mat-card-title>Clients</mat-card-title>
    <mat-card-content>
      <form [formGroup]="filterForm">
        <mat-form-field>
          <mat-label> Epicor Id Filter</mat-label>
          <input matInput formControlName="epicorIdFilter" type="text">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Common Name Filter</mat-label>
          <input matInput formControlName="commonNameFilter" type="text">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Legal Name Filter</mat-label>
          <input matInput formControlName="legalNameFilter" type="text">
        </mat-form-field>
      </form>
    <button *ngIf="dataSource" [disabled]="!paginator.hasPreviousPage()" mat-icon-button (click)="paginator.previousPage()">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button *ngIf="dataSource" [disabled]="!paginator.hasNextPage()" mat-icon-button (click)="paginator.nextPage()">
      <mat-icon>navigate_next</mat-icon>
    </button>


  <mat-table mat-table matSort class="full-width-table" aria-label="Client Table" [class.loading]="loading">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Epicor Id</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.externalCustomerId}}</mat-cell>
      <mat-footer-cell *cdkFooterCellDef> Epicor Id </mat-footer-cell>
    </ng-container>

    <!--Common Name Column -->
    <ng-container matColumnDef="commonName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Common Name</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.commonName}}</mat-cell>
      <mat-footer-cell *cdkFooterCellDef> Epicor Id </mat-footer-cell>
    </ng-container>
    <!-- Legal Name Column -->
    <ng-container matColumnDef="legalName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Legal Name</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.legalName}}</mat-cell>
      <mat-footer-cell *cdkFooterCellDef> Epicor Id </mat-footer-cell>
    </ng-container>
    <!-- Legal Name Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container>
          <button mat-icon-button id="kebabMenu" [matMenuTriggerFor]="menu" [attr.data-id]="row.id">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [disabled]="isReadOnly" [routerLink]="[row.id,'edit']">
              <mat-icon>create</mat-icon>
              <span>Edit Client</span>
            </button>
          </mat-menu>
        </ng-container>
      </mat-cell>
      <mat-footer-cell *cdkFooterCellDef> Epicor Id </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <div *ngIf="!(dataSource && dataSource.data.length > 0)" class="no-records">
    No records found
  </div>

  <mat-paginator [class.loading]="loading" #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="50"
    [pageSizeOptions]="[5, 25, 50, 100]">
  </mat-paginator>
</mat-card-content>
</mat-card>
</div>
