<div class="col-md-12 container-fluid">
  <mat-card>
    <mat-card-content [class.hide-content]="!(projectControls && employees)">
      <a id="projectControlCreate" mat-raised-button [routerLink]="['create']" color="primary"
         matTooltip="Create Project Control" [matTooltipPosition]="'right'">
        <mat-icon class="add-icon">add</mat-icon>
        Project Control
      </a>

      <br />
      <div class="col-md-12">
        <fieldset>
          <legend>
            Search Selection
          </legend>
          <form [formGroup]="filterForm">
            <mat-form-field>
              <input matInput type="text" placeholder="Filter by Project Control Name" id="projectControlName" formControlName="projectControlName" />
            </mat-form-field>
            <mat-form-field>
              <mat-select formControlName="projectControlRate" placeholder="Filter By Project Control Rate" disableOptionCentering id='projectControlRate'>
                <mat-option [value]="''">
                  None
                </mat-option>
                <mat-option *ngFor="let projectControlRate of projectControlRates" [value]="projectControlRate.projectControlRateId">{{projectControlRate.projectControlRateName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select formControlName="createdBy" id="createdBy" disableOptionCentering #employee placeholder="Filter By Created By">
                <mat-option [value]="''">
                  None
                </mat-option>
                <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">
                  {{emp.firstName + " " + emp.lastName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters" id="resetBtn">
              <mat-icon>clear</mat-icon>
            </button>
          </form>
        </fieldset>
      </div>
      <br />
      <div class="col-md-12">
        <mat-table #table matSort [dataSource]="projectControlDataSource" class="apply-border">
          <ng-container matColumnDef="projectControlName">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="sortable"> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span> {{element.projectControlName}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="projectControlRates">
            <mat-header-cell *matHeaderCellDef> Project Control Rates </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngFor="let pcr of element.projectControlProjectControlRates | filterByActiveRecords; first as isFirst">
                  <span *ngIf="!isFirst">, </span><span> {{pcr.projectControlRate.projectControlRateName}}</span>
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="projectControlDescription">
            <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span> {{element.projectControlDescription}} </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef> Created By</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: element.createdByEmployeeId}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="projectControlActions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element" id="kebabMenu">
              <ng-container>
                <button mat-icon-button [matMenuTriggerFor]="menu"
                        [attr.data-id]="element.projectControlId">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [routerLink]="['edit', element.projectControlId]">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item (click)="deleteProjectControl(element.projectControlId)">
                    <mat-icon>delete_outline</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </ng-container>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <div>
        <mat-paginator #paginator [length]="projectControlDataSource?.data.length" [pageIndex]="0" [pageSize]="50"
                       [pageSizeOptions]="[5, 25, 50, 100]">
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
