<mat-card>
  <mat-card-content *ngIf="employees">
    <div class="row">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedEmployeeId" (selectionChange)="filterByEmployee()" disableOptionCentering #employee
          placeholder="Employee Filter">
          <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">{{emp.firstName + " " + emp.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button (click)="clearSelectedEmployee()" mat-icon-button type="button"><mat-icon>clear</mat-icon></button>
    </div>
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="projectName">
        <mat-header-cell *matHeaderCellDef (click)="sortByProjectName()" class="clickable"> Name <mat-icon
          class="twelve">
          sort
        </mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.componentName}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef (click)="sortByDate()" class="clickable"> Created Date <mat-icon
            class="twelve">
            sort
          </mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createdDate | date:'shortDate'}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef> Created by</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: element.createdByEmployeeId}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a mat-raised-button color="default" type="button"
            [routerLink]="['editComponent',element.id]">Edit</a>
          <button mat-raised-button type="button" color="warn" (click)="deleteRecord(element.id)">Delete</button>
          <button mat-raised-button type="button" color="accent" (click)="cloneRecord(element.id)">Clone</button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="serviceEstimates">
      <mat-paginator (page)="pageChangedEventUsages($event)" *ngIf="dataSource" [length]="serviceEstimates.length"
        [pageSize]="5" [pageIndex]="0" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
