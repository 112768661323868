
<fieldset>
  <legend>
    iPerf Data
  </legend>


    <mat-table *ngIf="tableDataSource" class="full-width-table mat-elevation-z8" [dataSource]="tableDataSource">

      <ng-container matColumnDef="senderIpV4">
        <mat-header-cell *matHeaderCellDef>Sender IP</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.senderIpV4}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="targetHostIpV4">
        <mat-header-cell *matHeaderCellDef>Target IP</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.targetHostIpV4}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="senderPort">
        <mat-header-cell *matHeaderCellDef>Sender Port</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.senderPort}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="targetHostPort">
        <mat-header-cell *matHeaderCellDef>Target Host Port</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.targetHostPort}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="targetHostUrl">
        <mat-header-cell *matHeaderCellDef>Target Host Url</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.targetHostUrl}}</mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</fieldset>
