<fieldset>
  <legend>
    Compute Summary
  </legend>
  <mat-table mat-table matSort class="full-width-table" aria-label="Compute Summary Table" [dataSource]="tableDataSource">
    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.label}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="vmServers">
      <mat-header-cell *matHeaderCellDef>VM Servers</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.vmServers}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="vmCores">
      <mat-header-cell *matHeaderCellDef>VM CPUs</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.vmCores}}</mat-cell>
    </ng-container>

    <!--<ng-container matColumnDef="vmCoresAtUtil">
      <mat-header-cell *matHeaderCellDef>VM Cores @Util</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.vmCoresAtUtil}}</mat-cell>
    </ng-container>-->

    <ng-container matColumnDef="vmRam">
      <mat-header-cell *matHeaderCellDef>VM RAM(GB)</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.vmRam}}</mat-cell>
    </ng-container>

    <!--<ng-container matColumnDef="vmRamAtUtil">
      <mat-header-cell *matHeaderCellDef>VM RAM(GB) @Util</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.vmRamAtUtil}}</mat-cell>
    </ng-container>-->

    <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator style="visibility:hidden; height:0px; margin:0px 0px 0px 0px">
  </mat-paginator>
</fieldset>
