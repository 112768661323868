import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectDocumentService {

  constructor(private http: HttpClient) { }
  createProjectDocument(projectDocument: any) {
    console.log(projectDocument);
    const body: FormData = new FormData();
    var documentName: string = projectDocument.documentFile.name;
    const filenameArray = documentName.split(".");
    const fileType = filenameArray[filenameArray.length - 1];
    body.append('DocumentFile', projectDocument.documentFile, projectDocument.name + '.' + fileType);
    body.append('ProjectDocumentType', projectDocument.projectDocumentType);
    body.append('Name', projectDocument.name);
    body.append('ServiceProjectId', projectDocument.serviceProjectId);

    return this.http.post(`/api/ProjectDocuments`, body);
  }
  deleteDocument(projectDocumentId: string) {
    return this.http.delete(`/api/ProjectDocuments/${projectDocumentId}`);
  }
}
