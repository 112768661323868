import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectControlRate, ProjectControl, ProjectControlProjectControlRates } from '../../models/service-role';
import { ProjectControlRateService } from '../../project-control-rate/project-control-rate.service';
import { ProjectControlService } from '../project-control.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project-control-update',
  templateUrl: './project-control-update.component.html',
  styleUrls: ['./project-control-update.component.scss']
})
export class ProjectControlUpdateComponent implements OnInit {

  projectControlFormGroup: UntypedFormGroup;
  projectControlProjectControlRates: Array<ProjectControlProjectControlRates>;
  isCreateError: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private projectControlRateService: ProjectControlRateService,
    private projectControlService: ProjectControlService,
    private location: Location) { }

  ngOnInit() {


    this.route.params
      .subscribe(routeParams => {
        this.isCreateError = false;
        this.projectControlRateService.getProjectControlRates()
          .subscribe(controlRatesResult => {
            this.projectControlProjectControlRates = controlRatesResult;

          });
        const id = routeParams['id'];
        this.projectControlService.getProjectControl(id)
          .subscribe(projectControlResult => {
            const data: ProjectControl = projectControlResult;
            const rateIds: Array<string> = [];
            data.projectControlProjectControlRates.forEach(pcr => {
              rateIds.push(pcr.projectControlRate.projectControlRateId);
            });
            this.projectControlFormGroup = this.fb.group({
              'projectControlId': [data.projectControlId],
              'projectControlName': [data.projectControlName, Validators.required],
              'projectControlDescription': [data.projectControlDescription],
              'projectControlHourlyRate': [data.projectControlHourlyRate, Validators.required],
              'projectControlProjectControlRates': [rateIds],
              'createdDate': [data.createdDate],
              'softDelete': [data.softDelete],
              'createdByEmployeeId': [data.createdByEmployeeId]
            });

          });
      });
  }
  submitForm() {
    if (this.projectControlFormGroup.valid) {
      const _projectControl: ProjectControl = this.projectControlFormGroup.value;
      _projectControl.projectControlProjectControlRates = [];
      const projectControlRateIds: Array<string> = this.projectControlFormGroup.controls['projectControlProjectControlRates'].value;

      projectControlRateIds.forEach(id => {
        _projectControl.projectControlProjectControlRates.push(this.projectControlProjectControlRates.filter(pcr => pcr.projectControlRateId === id)[0]);
      });
      this.projectControlService.updateProjectControl(_projectControl)
        .subscribe(createResult => {
          this.location.back();
        },
          (error) => {
            this.isCreateError = true;
          });
    } else {
      this.isCreateError = true;
    }
  }
}
