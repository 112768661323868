import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectControlRateDetailComponent } from './project-control-rate-detail/project-control-rate-detail.component';
import { ProjectControlRateUpdateComponent } from './project-control-rate-update/project-control-rate-update.component';
import { ProjectControlRateListComponent } from './project-control-rate-list/project-control-rate-list.component';
import { ProjectControlRateCreateComponent } from './project-control-rate-create/project-control-rate-create.component';
import { ProjectControlRateLandingPageComponent } from './project-control-rate-landing-page/project-control-rate-landing-page.component';
import { ProjectControlRateComponent } from './project-control-rate.component';
import { ProjectControlRateService } from './project-control-rate.service';
import { ProjectControlRateRoutingModule } from './project-control-rate-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
    ProjectControlRateComponent,
    ProjectControlRateLandingPageComponent,
    ProjectControlRateCreateComponent,
    ProjectControlRateListComponent,
    ProjectControlRateUpdateComponent,
    ProjectControlRateDetailComponent
  ],
  providers: [
    ProjectControlRateService
  ]
})
export class ProjectControlRateModule { }
