<fieldset>
  <legend>
    Ping Data
  </legend>

  <mat-table *ngIf="tableDataSource" class="full-width-table mat-elevation-z8" [dataSource]="tableDataSource">
    <ng-container matColumnDef="targetHostIpV4">
      <mat-header-cell *matHeaderCellDef> Host IP </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.targetHostIpV4}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="maxRoundTripTime">
      <mat-header-cell *matHeaderCellDef> Max RTT </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.maxRoundTripTime}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="meanRoundTripTime">
      <mat-header-cell *matHeaderCellDef> Mean RTT </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.meanRoundTripTime}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="minRoundTripTime">
      <mat-header-cell *matHeaderCellDef> Min RTT </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.minRoundTripTime}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="packetSize">
      <mat-header-cell *matHeaderCellDef> Packet Size </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.packetSize}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="packetLoss">
      <mat-header-cell *matHeaderCellDef> Packet Loss </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.packetLoss}} </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</fieldset>
