import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
export class TemplateComponentsDataSource extends DataSource<any> {
  data: Array<any>;
  paginator: MatPaginator;
  sort: MatSort;
  nonFilteredData;

  constructor(private clients: Array<any>) {
    super();
    this.data = clients;
    this.nonFilteredData = clients;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }
  filter(field: any, fullData: any) {

    let filterApplied = false;
    let filterData: any[] = [];
    if (field['projectName']) {
      filterApplied = true;
      filterData = this.nonFilteredData.filter(bp => {
        return bp.externalCustomerId.toLocaleLowerCase().indexOf(field['projectName'].toLocaleLowerCase()) > -1;
      });
    }
    if (field['clientName']) {
      filterApplied = true;
      filterData = this.nonFilteredData.filter(bp => {
        return bp.commonName.toLocaleLowerCase().indexOf(field['clientName'].toLocaleLowerCase()) > -1;
      });
    }
    if (field['oppurtunityNumber']) {
      filterApplied = true;
      filterData = this.nonFilteredData.filter(bp => {
        return bp.legalName.toLocaleLowerCase().indexOf(field['oppurtunityNumber'].toLocaleLowerCase()) > -1;
      });
    }
    if (field['createdDate']) {
      filterApplied = true;
      filterData = this.nonFilteredData.filter(bp => {
        return bp.legalName.toLocaleLowerCase().indexOf(field['createdDate'].toLocaleLowerCase()) > -1;
      });
    }
    if (field['legalNameFilter']) {
      filterApplied = true;
      filterData = this.nonFilteredData.filter(bp => {
        return bp.field['legalNameFilter'];
      });
    }
    if (!filterApplied) {
      filterData = fullData;
    }
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: any[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: any[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'componentName': return compare(a.componentName, b.componentName, isAsc);
        case 'createdDate': return compare(+a.createdDate, +b.createdDate, isAsc);
        default: return 0;
      }
    });
  }

}
/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
