<div class="container-fluid">
  <mat-card>
    <mat-card-content>
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="assignedDriver">
          <mat-header-cell *matHeaderCellDef> Assigned Driver </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="element.assignedEmployeeId">{{element.vehicleDriver?.firstName}}
              {{element.vehicleDriver?.lastName}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="vehicleNumber">
          <mat-header-cell *matHeaderCellDef> Vehicle Number </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.vehicleNumber}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="vehicleVin">
          <mat-header-cell *matHeaderCellDef>VIN</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.vin}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="vehicleIsActive">
          <mat-header-cell *matHeaderCellDef> Vehicle Status </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="element.vehicleStatus === 0">Available</span>
            <span *ngIf="element.vehicleStatus === 1">Assigned</span>
            <span *ngIf="element.vehicleStatus === 2">Maintenance</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="licensePlate">
          <mat-header-cell *matHeaderCellDef> License Plate Number </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.vehicleRegistration?.licensePlateNumber}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="vehicleId">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button id="kebabMenu" [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu" [attr.data-id]="element.vehicleId">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['detail',element.vehicleId]">
                <mat-icon>edit</mat-icon>
                <span> Details </span>
              </button>
              <button mat-menu-item (click)="updateVehicle(element)">
                <mat-icon>search</mat-icon>
                <span> Restore </span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </mat-card-content>
  </mat-card>
</div>
