import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-cloud-migration-discovery-upload-dialog',
  templateUrl: './cloud-migration-discovery-upload-dialog.component.html',
  styleUrls: ['./cloud-migration-discovery-upload-dialog.component.scss']
})
export class CloudMigrationDiscoveryUploadDialogComponent implements OnInit {
  @Output() selectedEnvironment: EventEmitter<string> = new EventEmitter<string>();
  formTitle: string = 'Upload IBMi Discovery File';
  discoveryUploadForm: UntypedFormGroup;
  configOptions: any = [];
  configurationId: any;
  projectId: any;

  constructor(
    public dialogRef: MatDialogRef<CloudMigrationDiscoveryUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

     }

  ngOnInit(): void {
    console.log("uploadData: ", this.data);
 
    this.configOptions = this.data?.configOptions;
    this.projectId = this.data?.projectId;
    this.discoveryUploadForm = this.createFormGroup();

  }

  selectEnvironment(data: any) {
    this.configurationId = data.value;
    console.log(data.value);
    this.selectedEnvironment.emit(data.value);
  }

  createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      configurationEnvironment: new UntypedFormControl(''),
    })
  }

  onUploadFinished(event: Event) {
   this.close(); 
  }

  close(res?: any) {
    this.dialogRef.close(res);
  }
}
