import { Component, OnInit, HostListener, HostBinding, ViewChild, ElementRef, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, NavigationCancel, ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, SilentRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { RoleService } from './shared/services/role.service';
import { GraphApiService } from './shared/services/graph-api.service';
import { IdTokenClaims } from './shared/models/id-token-claims';
import { ReleaseNotesDisplayComponent } from './shared/components/release-notes-display/release-notes-display.component';
import { ReleaseNotesService } from './shared/services/release-notes.service';
import { BuildInfoService } from './shared/services/build-info.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ApplicationInsightsService } from './core/services/telemetry.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  @ViewChild('sideNavContainer') sideNavContainer: ElementRef;
  @HostBinding('class.dark-theme') isDarkThemeEnabled: boolean = false;
  isDashboardViewEnabled: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  isIframe = false;
  loginDisplay = false;
  currentUser: AccountInfo | null;
  @ViewChild("notesMenuTrigger", { static: false }) notesMenuTrigger: MatMenuTrigger;

  @ViewChild(ReleaseNotesDisplayComponent) releaseNotesDisplayComponent: ReleaseNotesDisplayComponent;

  atTop: boolean;
  title = 'app';
  sideNavOpened: boolean;
  isBackButtonVisible: boolean;

  isLoggedIn: boolean;
  isSuperAdmin: boolean;
  isFleetManagementUser: boolean;
  isServiceEstimateUser: boolean;
  hasBusinessPartnerAccess: boolean;
  hasClientAccess: boolean;
  isDashbboardViewVisible: boolean;
  displayReleaseNotes: boolean;

  isAdmin: boolean;
  hasVideosAccess: boolean;
  isOfferLetterAccess: boolean;
  isArchitectEngineer: boolean;
  isAvayaArchitectEngineer: boolean;
  isProjectManager: boolean;
  isServicesOperations: boolean;
  isSalesSupport: boolean;
  isDirector: boolean;
  isSales: boolean;
  isRightSideNavOpen: boolean;
  isCloudMigrationUser: boolean;
  isBusinessOperationsSpecialist: boolean;
  appInsights: ApplicationInsightsService;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private graphApi: GraphApiService,
    private roleService: RoleService,
    private route: ActivatedRoute,
    private releaseNotesService: ReleaseNotesService,
    private buildInfoService: BuildInfoService) {
    this.appInsights = new ApplicationInsightsService(router);
  }

  ngOnInit() {
    this.isCloudMigrationUser = false;
    this.hasClientAccess = false;
    this.hasBusinessPartnerAccess = false;
    this.isServiceEstimateUser = false;
    this.isDashbboardViewVisible = false;
    this.displayReleaseNotes = false;
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    //this.msalBroadcastService.msalSubject$
    //  .pipe(
    //    filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    //  )
    //  .subscribe((result: any) => {
    //    this.checkReleaseNoteAcknowledged();
    //  });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.setLoginDisplay();
        this.setBuildVersion();
      });
    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
      },
      error: (error: any) => {
      }
    });
    this.atTop = true;
    window.addEventListener('scroll', this.scrollEvent, true);
    this.isDarkThemeEnabled = false;
    this.isDashboardViewEnabled = false;
    this.isRightSideNavOpen = false;
    let darkTheme; let isEnabledDashboardView;
    var darkThemeLocalStorageValue = localStorage.getItem('useDarkTheme');
    var dashboardViewStorageValue = localStorage.getItem('isDashboardViewEnabled');
    if (darkThemeLocalStorageValue) {
      darkTheme = JSON.parse(darkThemeLocalStorageValue);
    }
    if (dashboardViewStorageValue) {
      isEnabledDashboardView = JSON.parse(dashboardViewStorageValue);
    }
    if (darkTheme) {
      this.isDarkThemeEnabled = darkTheme['useDarkTheme'];
    }
    if (isEnabledDashboardView)
      this.isDashboardViewEnabled = isEnabledDashboardView['isDashboardViewEnabled'];
    this.isSuperAdmin = false;
    this.isFleetManagementUser = false;
    this.isOfferLetterAccess = false;

    this.isLoggedIn = false;
    this.isBackButtonVisible = true;
    this.sideNavOpened = false;
    this.router.events.subscribe((routerEvent) => {
      if (!(routerEvent instanceof NavigationCancel)) {

        // this.sideNavOpened = true;
      }
      if (routerEvent instanceof NavigationEnd) {
        const route = this.router.routerState.snapshot.url;
        const routerSegments = route.split('/');
        if (route.length > 1) {
          this.isBackButtonVisible = true;
        }
        else {
          this.isBackButtonVisible = false;
        }

        let queryParamsIndex = routerSegments[1].indexOf('?');
        let routerSegment = queryParamsIndex != -1 ? routerSegments[1].slice(0, queryParamsIndex) : routerSegments[1];
        if (routerSegment == 'admin' || routerSegment == 'professionalServices' || routerSegment == 'partners'
          || routerSegment == 'documentcreator' || routerSegment == 'fleetmanagement' || routerSegment == 'adminHelpVideos'
          || routerSegment == 'tags' || routerSegment == 'employee' || routerSegment == 'reports' || routerSegment == 'help'
          || routerSegment == 'clients' || routerSegment == 'myProfile' || routerSegment == 'displayImage') {
          this.isBackButtonVisible = false;
        }

        this.sideNavOpened = false;
      }
    });
  }

  checkReleaseNoteAcknowledged() {
    this.releaseNotesService.isAcknowledged().subscribe((isAck: boolean) => {
      if (!isAck)
        this.displayNotesPopup();
    });
  }

  setBuildVersion() {
    this.releaseNotesService.getCurrentReleaseInfo().subscribe((res: any) => {
      this.buildInfoService.setVersion(res?.version);
    })
  }

  flipTheme() {
    var obj = { 'useDarkTheme': this.isDarkThemeEnabled };
    localStorage.setItem('useDarkTheme', JSON.stringify(obj));
    location.reload();

  }
  toggleDashboardView() {
    var obj = { 'isDashboardViewEnabled': this.isDashboardViewEnabled };
    localStorage.setItem('isDashboardViewEnabled', JSON.stringify(obj));
    location.reload();
  }

  roleCheckFunction() {
    this.isDirector = this.hasRole('Director');
    this.isSales = this.hasRole('Sales');
    this.isSalesSupport = this.hasRole('SalesSupport');
    this.isArchitectEngineer = this.hasRole('Architect');
    this.isAvayaArchitectEngineer = this.hasRole('AvayaArchitect');
    this.isProjectManager = this.hasRole('ProjectManager');
    this.isServicesOperations = this.hasRole('ServicesOperations');
    this.isAdmin = this.hasRole('PortalAdmin');

    let roles;
    /**
     * Claims from the id token
     */
    var idTokenClaims = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
    if (idTokenClaims) {
      roles = idTokenClaims['roles'];
    } else {
      roles = '';
    }

    let updatedRoles: string[];
    if (typeof roles === 'string') {
      updatedRoles = [
        roles
      ];
    } else {
      updatedRoles = roles;
    }

    updatedRoles.forEach(r => {
      if (r.includes('Fleet')) {
        this.isFleetManagementUser = true;

      } if (r.includes('PortalSEPostsalesEngineerSA')) {
        this.isServiceEstimateUser = true;
        this.isDashbboardViewVisible = true;
      }
      if (r.includes('Projects.Approver')) {
        this.isServiceEstimateUser = true;
        this.hasVideosAccess = true;
        this.isDashbboardViewVisible = true;
      }
      if (r.includes('SE')) {
        this.isServiceEstimateUser = true;
        this.hasBusinessPartnerAccess = true;
      } if (r.includes('PortalAdmin')) {
        this.isFleetManagementUser = true;
        this.isServiceEstimateUser = true;
        this.isSuperAdmin = true;
        this.hasBusinessPartnerAccess = true;
        this.isOfferLetterAccess = true;
        this.hasClientAccess = true;
        this.hasVideosAccess = true;
        this.isCloudMigrationUser = true;
        this.isDashbboardViewVisible = true;

      } if (r.includes('PortalSEDirector')) {
        this.hasBusinessPartnerAccess = true;
        this.hasClientAccess = true;
        this.isDirector = true;
        this.isDashbboardViewVisible = true;
      } if (r.includes('PortalSEServiceOperations')) {
        this.hasBusinessPartnerAccess = true;
      } if (r.includes('PortalSEProjectManager')) {
        this.hasBusinessPartnerAccess = true;
        this.isServiceEstimateUser = true;
        this.isDashbboardViewVisible = true;
      } if (r.includes('PortalSEPresalesArchitect')) {
        this.hasBusinessPartnerAccess = true;
        this.isDashbboardViewVisible = true;
      } if (r.includes('PortalSEPostsalesEngineer')) {
        this.hasBusinessPartnerAccess = true;
        this.isDashbboardViewVisible = true;
      } if (r.includes('PortalSEServiceOperations')) {
        this.isServiceEstimateUser = true;
        this.hasBusinessPartnerAccess = true;
      } if (r.includes('PortalOfferLetterUser')) {
        this.isOfferLetterAccess = true;
      }
      if (r.includes('PortalSalesSupport') || r.includes('PortalSEProjectManagerOfficer')) {
        this.isDashbboardViewVisible = true;
      }
      if (r.includes('BusinessOperationsSpecialist')) {
        this.isBusinessOperationsSpecialist = true;
        this.hasBusinessPartnerAccess = true;
      }
      if (r.includes('FieldServiceCoordinator')) {
        this.hasBusinessPartnerAccess = true;
      }
      if (r.includes('ProfessionalServiceCoordinator')) {
        this.hasBusinessPartnerAccess = true;
        this.isServiceEstimateUser = true;
        this.hasClientAccess = true;
        this.isDashbboardViewVisible = true;
      }
    });
  }

  userHasRole(role: string) {
    var idTokenClaims = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
    let roles: any[] | any;
    if (idTokenClaims) {
      roles = idTokenClaims['roles'];
    } else {
      roles = '';
    }
    const roleCheck = (roles as string[])?.find(_role => _role === role);
    if (roleCheck) {
      return true;
    } else {
      return false;
    }
  }

  toggleSideNav() {
    this.sideNavOpened = !this.sideNavOpened;
  }

  goBack() {
    this.location.back();
  }

  hasRole(role: string) {
    if (this.roleService.checkRoleExists(role, this.authService.instance.getActiveAccount(), true)) {
      return this.roleService.checkRoleExists(role, this.authService.instance.getActiveAccount(), true);
    }
    return false;
  }

  gotoTop() {
    window.document.getElementById('topSection')?.scrollIntoView();
  }

  scrollEvent = (event: any): void => {
    //In chrome and some browser scroll is given to body tag
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    document.scrollingElement?.scrollTop
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if (pos == max) {
      this.atTop = false;
    }
    if (document.body.scrollTop === 0) {
      this.atTop = true;
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (activeAccount) {
      this.appInsights.clearAuthenticatedUserContext();
      this.currentUser = activeAccount;
      this.roleCheckFunction();
      this.appInsights.setAuthenticatedUserContext(this.currentUser.username,this.currentUser.tenantId,true);
    } else {
      if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();

        this.authService.instance.setActiveAccount(accounts[0]);
        this.isLoggedIn = true;
        this.currentUser = this.authService.instance.getActiveAccount();

        this.roleCheckFunction();
        if (this.isDashbboardViewVisible) {
          this.isDashboardViewEnabled = true;
          this.toggleDashboardView();
        }
      }
      else {
        if (!this.isIframe) {
          this.loginRedirect();
        }

      }
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  logout(popup?: boolean) {
    localStorage.clear();
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  displayNotesPopup() {
    const dialogRef = this.dialog.open(ReleaseNotesDisplayComponent, {
      minWidth: 'calc(30%)',
      width: 'auto',
      minHeight: 'calc(100vh - 620px)',
      height: 'auto',
    });
    dialogRef.afterClosed()
      .subscribe(result => {
      });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  displayNotes() {
    this.releaseNotesDisplayComponent?.ngOnInit();
    this.displayReleaseNotes = true;
  }

  closeNotes() {
    this.releaseNotesDisplayComponent?.ngOnDestroy();
    this.notesMenuTrigger.closeMenu();
  }

  ignoreMenuEvent(event: Event) {
    event.stopPropagation()
  }
}

