import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProfessionalServicesService } from '../../../professional-services/professional-services.service';
@Component({
  selector: 'app-cloning-popup',
  templateUrl: './cloning-popup.component.html',
  styleUrls: ['./cloning-popup.component.scss']
})
export class CloningPopupComponent implements OnInit {

  employees: Array<any>;
  @Output() clonedName: EventEmitter<string> = new EventEmitter<string>();
  isNotUnique: boolean;
  popupFor: string;
  copyTo: string;

  constructor(public dialogRef: MatDialogRef<CloningPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProfessionalServicesService) { }

  ngOnInit(): void {
    this.data.clonedName = null;
    this.popupFor = this.data.popupFor;
    this.copyTo = this.data.copyTo;
  }

  checkClonedNameExists() {
    switch (this.popupFor) {
      case 'Template': this.projectService.CheckTemplateNameExists(this.data.clonedName)
        .subscribe(res => {
          this.isNotUnique = res;
          if(!this.isNotUnique) {
            this.dialogRef.close(this.data);
          }
        });
        break;
      case 'Service Estimate':
      case 'Change Order':
        this.projectService.CheckSENameExists(this.data.clonedName)
        .subscribe(res => {
          this.isNotUnique = res;
          if (!this.isNotUnique) {
            this.dialogRef.close(this.data);
          }
        });
        break;
      case 'Service Estimate Component':
        this.projectService.CheckSECNameExists(this.data.clonedName)
        .subscribe(res => {
          this.isNotUnique = res;
          if (!this.isNotUnique) {
            this.dialogRef.close(this.data);
          }
        });
        break;

    }
    
  }
}
