<h2>Mileage Entries</h2>
<mat-table #table [dataSource]="mileageDataSource">
  <ng-container matColumnDef="vehicleNumber">
    <mat-header-cell *matHeaderCellDef> Vehicle Number </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.vehicleNumber}} </mat-cell>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="milesStart">
    <mat-header-cell *matHeaderCellDef> Mileage Start </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.vehicleUsageMileageStart | number}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="milesEnd">
    <mat-header-cell *matHeaderCellDef> Mileage End </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.vehicleUsageMileageEnd | number}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="mileageDate">
    <mat-header-cell *matHeaderCellDef> Entry Date </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.vehicleUsageDate | date}} </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>