<mat-table id="activeProjectsTable" *ngIf="activeProjectsData" mat-table class="full-width-table" aria-label="Active Projects Table" [dataSource]="activeProjectsData">
  <ng-container matColumnDef="clientName">
    <mat-header-cell *matHeaderCellDef>Client Name</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.serviceProject.client?.commonName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="projectName">
    <mat-header-cell *matHeaderCellDef>Project Name</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.serviceProject.projectName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="oppQ">
    <mat-header-cell *matHeaderCellDef>BF Number</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.serviceProject.oppurtunityNumber}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdDate">
    <mat-header-cell *matHeaderCellDef>Created Date</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.serviceProject.createdDate | date: 'M/d/yyyy'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.serviceProject.serviceProjectStatus == 0">Active</span>
      <span *ngIf="row.serviceProject.serviceProjectStatus == 1">Inactive</span>
      <span *ngIf="row.serviceProject.serviceProjectStatus == 2">Lost</span>
      <span *ngIf="row.serviceProject.serviceProjectStatus == 3">Complete</span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<div *ngIf="!(activeProjectsData && activeProjectsData.length > 0)" class="no-records">
  No records found
</div>
