<!-- <mat-dialog-title></mat-dialog-title> -->
<h3 mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle>
  {{ formTitle }}
  <button class="close" mat-button (click)="close()">X</button>
</h3>
<!-- <mat-dialog-content> -->
<mat-card style="width: 100%; height: 105%"
          *ngIf="networkDataForm  && environmentOptions && networkDataOptions" >
  <mat-dialog-content  [formGroup]="networkDataForm">
    <fieldset>

      <legend>Data Source</legend>
      <h4>Select Network Data Source and Enter Data to Ingest</h4>
      <mat-form-field *ngIf="environmentOptions" style="min-width: 200px;">
        <mat-label>Source Environmnent/OS</mat-label>
        <mat-select formControlName="environment" ngDefaultControl>
          <mat-option *ngFor="let environmentOption of environmentOptions"
                      [value]="environmentOption?.value">
            {{ environmentOption?.text }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="networkDataForm.controls['environment'].hasError('required')">
          Source Environmnent is required
        </mat-error>
      </mat-form-field> 
      <!--  -->
	  <mat-form-field *ngIf="networkDataOptions">
        <mat-label>Data Source</mat-label>
        <mat-select formControlName="dataSource" ngDefaultControl>
          <mat-option *ngFor="let networkDataOption of networkDataOptions"
                      [value]="networkDataOption?.value">
            {{ networkDataOption?.text }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="networkDataForm.controls['dataSource'].hasError('required')">
          Network Data Source is required
        </mat-error>
      </mat-form-field>
    </fieldset>
    <br />
    <div>
      <div class="row">
        <div class="col-6">
          <fieldset class="input-output">
            <legend>Input</legend>

            <mat-form-field class="text-data-input" appearance="fill">
              <mat-label>Network Data</mat-label>
              <textarea matInput
                        formControlName="networkData"
                        placeholder="Ex. Tracecert output.."></textarea>
            </mat-form-field>
          </fieldset>

        </div>
        <div class="col-6">
          <fieldset class="output">
            <legend>Output</legend>
            <ngx-json-viewer *ngIf="loadResult"
                             [json]="loadResult"
                             [expanded]="false">
            </ngx-json-viewer>
          </fieldset>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <button type="submit" mat-raised-button mat-primary [disabled]="!networkDataForm?.valid" (click)="load()">
            Load
          </button>
          <button class="mat-raised-button mat-primary" (click)="refresh()">Clear</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">

    <button type="submit" mat-raised-button mat-primary [disabled]="!dataLoaded" (click)="save()">
      Save
    </button>
    <button class="mat-raised-button" (click)="close()">Close</button>
  </mat-dialog-actions>
  <b></b>
  <span style="font-weight: bold">{{ message }}</span>
</mat-card>
<!-- </mat-dialog-content> -->
