import { Component, OnInit, Input } from '@angular/core';
import { ProfessionalServicesService } from '../../professional-services.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectsDataSource } from '../../models/professional-services-projects.datasource';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { RoleService } from '../../../shared/services/role.service';

@Component({
  selector: 'app-service-estimates-pending-threshold-approval',
  templateUrl: './service-estimates-pending-threshold-approval.component.html',
  styleUrls: ['./service-estimates-pending-threshold-approval.component.css']
})
export class ServiceEstimatesPendingThresholdApprovalComponent implements OnInit {

  @Input() serviceEstimates: Array<any>;
  @Input() changeOrders: Array<any>;
  displayedColumns: Array<string>;
  dataSource: ProjectsDataSource;
  changeOrderDataSource: ProjectsDataSource;
  seId: string;
  project: any;
  isSales: boolean;
  user: AccountInfo | null;
  isAbleToSignContract: boolean;
  constructor(private projectService: ProfessionalServicesService, private route: ActivatedRoute, public dialog: MatDialog, private authService: MsalService, private roleService: RoleService) { }

  ngOnInit() {
    this.isAbleToSignContract = false;


    this.user = this.authService.instance.getActiveAccount();

    if (this.hasRole('PortalSEServiceOperations')) {
      this.isAbleToSignContract = true;
    }
    if (this.hasRole('PortalAdmin')) {
      this.isAbleToSignContract = true;
    }

    this.isSales = this.hasRole('PortalSEPostsalesEngineer');
    this.displayedColumns = [
      'projectName',
      'createdDate',
      'isChangeOrder',
      'status',
      'expirationDate',
      'actions'
    ];
    this.route.params.subscribe(param => {
      this.seId = param['projectId'];
      this.projectService.getServiceEstimatesPendingThresholdApproval()
        .subscribe(projectResult => {
          this.project = projectResult.serviceProject;
        });
      this.getTableData();
    });
  }
  getTableData() {
    this.projectService.getServiceEstimatesPendingThresholdApproval()
      .subscribe(res => {
        const data = res;
        this.serviceEstimates = data;//.filter((se: any) => se.isChangeOrder == false);
        this.changeOrders = data.filter((se: any) => se.isChangeOrder == true);
        this.setTableData();
        this.setChangeOrderTableData(this.changeOrders);

      });

  }
  setTableData(data?: Array<any>) {
    this.dataSource = new ProjectsDataSource();
    this.dataSource.setData(this.serviceEstimates);
  }
  setChangeOrderTableData(data?: Array<any>) {
    this.changeOrderDataSource = new ProjectsDataSource();
    this.changeOrderDataSource.setData(data);
  }
  deleteRecord(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {

          this.projectService.deleteSE(id)
            .subscribe(res => {
              this.getTableData();

            });
        }
      });
  }
  markContractSigned(id: string) {
    this.projectService.markContractSigned(id)
      .subscribe(res => {
        this.getTableData();

      });
  }
  //cloneRecord(id: string) {
  //  this.projectService.cloneSE(id)
  //    .subscribe(res => {
  //      this.getTableData();

  //    });
  //}
  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, false);
  }

}
