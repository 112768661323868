
<mat-spinner *ngIf="isLoading">
</mat-spinner>
<div *ngIf="expenseForm">
<mat-card>
  <form [formGroup]="expenseForm">
    <mat-card-content [formGroup]="expenseForm">
      <p class="success" *ngIf="addedSuccesfully">The expense was added successfully</p>
      <div class="col-md-12">
        <mat-form-field>
          <input placeholder="Description" type="text" matInput [(ngModel)]="model.vehicleExpenseDescription" formControlName="vehicleExpenseDescription" />
          <mat-error *ngIf="expenseForm.controls['vehicleExpenseDescription'].hasError('required')">A description is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field>
          <span matPrefix>$ </span>
          <input placeholder="Cost" currencyMask matInput [(ngModel)]="model.vehicleExpenseCost" formControlName="vehicleExpenseCost" />
          <mat-error *ngIf="expenseForm.controls['vehicleExpenseCost'].hasError('required')">A cost is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field>
          <mat-select placeholder="Expense Type" [(ngModel)]="model.vehicleExpenseType" formControlName="vehicleExpenseType" disableOptionCentering>
            <mat-option [value]="0">Fuel</mat-option>
            <mat-option [value]="1">Maintenance</mat-option>
            <mat-option [value]="2">Other</mat-option>
          </mat-select>
          <mat-error *ngIf="expenseForm.controls['vehicleExpenseCost'].hasError('required')">A expense type is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field>
          <input matInput [matDatepicker]="picker" [(ngModel)]="model.vehicleExpenseDate" placeholder="Vehicle Expense Date" formControlName="vehicleExpenseDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="expenseForm.controls['vehicleExpenseDate'].hasError('required')">A expense date is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <div>
          <!--<mat-form-field>
            <input [readonly]="true" matInput type="text" [value]="fileName" placeholder="Select a File" (focus)="imgFileInput.click()" />
            <span matSuffix>
              <button mat-button mat-icon-button color="accent">
                <mat-icon>attachment</mat-icon>
              </button>
            </span>
          </mat-form-field>-->
          <div class="upload">
            <input type="file" #fileRef id="fileRef" (change)="updateReciptFile($event)" title="Select a Expense receipt" value="fileName" (blur)="setErrorStatus()" />

            <label for="fileRef">Browse for receipt<mat-icon class="icon">attachment</mat-icon></label>
          </div>
          <div class="single-file">
            <div class="info">
              <h4 class="name">
                {{ fileName }}
              </h4>
            </div>
          </div>
          <!--<p class="error" *ngIf="reciptError">A receipt is required</p>-->
          <!--<input hidden type="file" #imgFileInput (change)="updateReciptFile($event)" />-->
        </div>
        <!-- <input (change)="updateReciptFile($event)" placeholder="Receipt" type="file" /> -->
      </div>
      <div class="col-md-4" *ngIf="isAdmin">
        <ng-template #loading>
          <p>Loading the list of drivers</p>
        </ng-template>
        <mat-form-field *ngIf="drivers; else loading">
          <mat-select [(ngModel)]="model.createdByEmployeeId" formControlName="createdByEmployeeId" placeholder="Select a driver" disableOptionCentering>
            <mat-option *ngFor="let driver of drivers" [value]="driver.employeeId">{{driver.firstName + ' ' + driver.lastName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <br>
      <button mat-raised-button type="button" (click)="addVehicle()" [disabled]="!expenseForm.valid">Save</button>
    </mat-card-content>
  </form>
</mat-card>
</div>
