<mat-card>
  <mat-card-content *ngIf="model">
    <form [formGroup]="modelForm">
      <mat-form-field>
        <input type="text" matInput placeholder="Manufacturer Name" formControlName="manufacturerName" name="manufacturerName"
               [(ngModel)]="model.manufacturerName" />
        <mat-error *ngIf="modelForm.get('manufacturerName').hasError('required')">The manufacturer name is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput placeholder="Model Year" formControlName="vehcileModelYear" name="vehcileModelYear"
               [(ngModel)]="model.vehcileModelYear" />
        <mat-error *ngIf="modelForm.get('vehcileModelYear')?.hasError('required')">The model year is required</mat-error>
        <mat-error *ngIf="modelForm.get('vehcileModelYear')?.hasError('pattern') || modelForm.controls['vehcileModelYear'].errors?.maxlength || modelForm.controls['vehcileModelYear'].errors?.minlength">Enter valid year in 'YYYY' format</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput placeholder="Model Name" formControlName="vehicleModelName" name="vehicleModelName"
               [(ngModel)]="model.vehicleModelName" />
        <mat-error *ngIf="modelForm.get('vehicleModelName').hasError('required')">The model name is required</mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" type="button" (click)="updateModel()" [disabled]="!modelForm.valid || !modelForm.dirty">Update</button>
      <button mat-raised-button color="primary" type="button" (click)="cancelUpdate()">Cancel</button>
      </form>
  </mat-card-content>
</mat-card>
