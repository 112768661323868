<ng-template #loading>
  <mat-spinner></mat-spinner>
</ng-template>
<form *ngIf="summaryForm; else loading" [formGroup]="summaryForm" (ngSubmit)="updateSummary()" class="prettyprint">
  <mat-toolbar color="accent" class="hidden-print">
    <button title="Save Summary" mat-icon-button type="submit">
      <mat-icon>save</mat-icon>
    </button>
    <span class="spacer"></span>
    <button title="Print View" mat-icon-button type="button" (click)="print()">
      <mat-icon>print</mat-icon>
    </button>
  </mat-toolbar>
  <!-- <mat-toolbar color="accent"><button mat-raised-button color="primary" type="button" [disabled]="summaryForm">Generate Summary</button></mat-toolbar> -->
  <mat-card>
    <div class="show-print">
      <img class="reduce-mit-logo" src="/assets/MIT-US-Logo-Color.png" />
    </div>
    <mat-card-content>
      <div class="row">
        <div class="background show-print">
          <p class="bg-text show-print">Internal Document Only <br />
            Not Final Pricing-Not Official</p>
        </div>
        <div class="col-md-12">
          <h2 class="show-print">{{serviceEstimate.name}}</h2>
          <div class="col-md-12">
            <mat-form-field [floatLabel]="'always'" class="larger">
              <mat-label class="no-prefix">Date</mat-label>
              <input class="no-prefix" matInput [matDatepicker]="summaryPicker" formControlName="summaryDate" />
              <mat-datepicker-toggle matSuffix [for]="summaryPicker" class="hidden-print"></mat-datepicker-toggle>
              <mat-datepicker #summaryPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field [floatLabel]="'always'" class="larger">
              <mat-label class="no-prefix">Price Valid Thru</mat-label>
              <input class="no-prefix" matInput readonly [matDatepicker]="expirationPicker"
                     formControlName="expirationDate" />
              <mat-datepicker-toggle disabled readonly matSuffix [for]="expirationPicker" class="hidden-print">
              </mat-datepicker-toggle>
              <mat-datepicker #expirationPicker readonly></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <mat-form-field [floatLabel]="'always'" class="larger">
              <mat-label class="no-prefix">Customer</mat-label>
              <input class="no-prefix" readonly formControlName="customer" matInput type="text" />
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <mat-form-field [floatLabel]="'always'" class="larger">
              <mat-label class="no-prefix">Project Name</mat-label>
              <input class="no-prefix" readonly formControlName="projectName" matInput type="text" />
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <mat-form-field [floatLabel]="'always'" class="larger">
              <mat-label class="no-prefix">BF Number</mat-label>
              <input class="no-prefix" readonly formControlName="oppurtunityNumber" matInput type="text" />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field [floatLabel]="'always'" class="larger">
              <mat-label class="no-prefix">Service Estimate Billing Type</mat-label>
              <input class="no-prefix" matInput readonly [(ngModel)]="billingType"
                     placeholder="Service Estimate Billing Type" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field [floatLabel]="'always'" style="display: none;">
              <mat-select readonly placeholder="Service Estimate Billing Type" formControlName="serviceEstimateType"
                          disableOptionCentering>
                <mat-option [value]="0">Fixed Fee</mat-option>
                <mat-option [value]="1">Time & Materials</mat-option>
                <mat-option [value]="2">Misc</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="col-md-12" *ngIf="checkComponents() && serviceEstimatePhaseDetails.controls.length">
          <div class="col-md-12" formArrayName="serviceEstimatePhaseDetails">
            <h4 class="hidden-print">Service Estimate Components</h4>
            <div class="display-inline">
              <mat-checkbox class="hidden-print" [checked]="displayItems" (change)="selectAll($event.checked)">
                Display Hours/Billable Rate/Sell Price
              </mat-checkbox>
            </div>
            <div>
              <div *ngFor="let item of selectionItems" class="display-inline">
                <mat-checkbox [(ngModel)]="item.isVisible" [ngModelOptions]="{standalone: true}" class="hidden-print"
                              (ngModelChange)="updateAll()">
                  {{item.name}}
                </mat-checkbox>
              </div>

            </div>
            <div *ngFor="let phaseItems of summaryForm.get('serviceEstimatePhaseDetails')['controls'];let i = index"
                 [formGroupName]="i">
              <!-- <h4>{{phaseItems.controls.componentName.value}}</h4> -->
              <div formArrayName="planItems" *ngIf="phaseItemsExists(i, 'planItems')">
                <h4 [ngClass]="{'hidden-print': displayPlanItems || displayPlanTitle}" class="display-inline-block">Plan
                  Phase</h4>
                <mat-checkbox class="hidden-print checkbox-inline" [ngModelOptions]="{standalone: true}"
                              [(ngModel)]="displayPlanTitle" [disabled]="displayPlanItems">
                  Hide Title
                </mat-checkbox>
                <mat-checkbox class="full-width hidden-print" [checked]="displayPlanItems"
                              (change)="selectAllItems($event.checked, 'planItems')">
                  Hide Plan Items
                </mat-checkbox>
                <div class="adjustment-repeat"
                     *ngFor="let phaseItem of phaseItems.get('planItems').controls;let planIndex = index"
                     [formGroupName]="planIndex">
                  <div class="display-flex" [ngClass]="{'hidden-print': phaseItem.value.isVisible}">
                    <app-professional-service-estimate-detailed-quote [phaseItemGroup]="phaseItem"
                                                                      [selectionItems]="selectionItems" (valueChanged)="isChecked($event, 'planItems', i)">
                    </app-professional-service-estimate-detailed-quote>
                  </div>
                </div>
              </div>
              <div formArrayName="executeItems" *ngIf="phaseItemsExists(i, 'executeItems')">
                <h4 [ngClass]="{'hidden-print': displayExecuteItems || displayExecuteTitle}"
                    class="display-inline-block">Execute Phase</h4>
                <mat-checkbox class="hidden-print checkbox-inline" [ngModelOptions]="{standalone: true}"
                              [(ngModel)]="displayExecuteTitle" [disabled]="displayExecuteItems">
                  Hide Title
                </mat-checkbox>
                <mat-checkbox class="full-width hidden-print" [checked]="displayExecuteItems"
                              (change)="selectAllItems($event.checked, 'executeItems')">
                  Hide Execute Items
                </mat-checkbox>
                <div class="adjustment-repeat"
                     *ngFor="let phaseItem of phaseItems.get('executeItems').controls;let exeIndex = index"
                     [formGroupName]="exeIndex">
                  <div class="display-flex" [ngClass]="{'hidden-print': phaseItem.value.isVisible}">
                    <app-professional-service-estimate-detailed-quote [phaseItemGroup]="phaseItem"
                                                                      [selectionItems]="selectionItems" (valueChanged)="isChecked($event, 'executeItems', i)">
                    </app-professional-service-estimate-detailed-quote>
                  </div>
                </div>
              </div>
              <div formArrayName="closeItems" *ngIf="phaseItemsExists(i, 'closeItems')">
                <h4 [ngClass]="{'hidden-print': displayCloseItems || displayCloseTitle}" class="display-inline-block">
                  Close Phase</h4>
                <mat-checkbox class="hidden-print checkbox-inline" [ngModelOptions]="{standalone: true}"
                              [(ngModel)]="displayCloseTitle" [disabled]="displayCloseItems">
                  Hide Title
                </mat-checkbox>
                <mat-checkbox class="full-width hidden-print" [checked]="displayCloseItems"
                              (change)="selectAllItems($event.checked, 'closeItems')">
                  Hide Close Items
                </mat-checkbox>
                <div class="adjustment-repeat"
                     *ngFor="let phaseItem of phaseItems.get('closeItems').controls;let closeIndex = index"
                     [formGroupName]="closeIndex">
                  <div class="display-flex" [ngClass]="{'hidden-print': phaseItem.value.isVisible}">
                    <app-professional-service-estimate-detailed-quote [phaseItemGroup]="phaseItem"
                                                                      [selectionItems]="selectionItems" (valueChanged)="isChecked($event, 'closeItems', i)">
                    </app-professional-service-estimate-detailed-quote>
                  </div>
                </div>
              </div>
              <div formArrayName="bpItems" *ngIf="phaseItemsExists(i, 'bpItems')">
                <h4 [ngClass]="{'hidden-print': displayBPItems || displayBpTitle}" class="display-inline-block">Business
                Partner Items</h4>
                <mat-checkbox class="hidden-print checkbox-inline" [ngModelOptions]="{standalone: true}"
                              [(ngModel)]="displayBpTitle" [disabled]="displayBPItems">
                  Hide Title
                </mat-checkbox>
                <mat-checkbox class="full-width hidden-print" [checked]="displayBPItems"
                              (change)="selectAllItems($event.checked, 'bpItems')">
                  Hide Business Partner Items
                </mat-checkbox>
                <div class="adjustment-repeat"
                     *ngFor="let phaseItem of phaseItems.get('bpItems').controls;let bpIndex = index"
                     [formGroupName]="bpIndex">
                  <div class="display-flex" [ngClass]="{'hidden-print': phaseItem.value.isVisible}">
                    <app-professional-service-estimate-detailed-quote [phaseItemGroup]="phaseItem"
                                                                      [selectionItems]="selectionItems" (valueChanged)="isChecked($event, 'bpItems', i)">
                    </app-professional-service-estimate-detailed-quote>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="col-md-12"
             [ngClass]="{'extra-space': !displayPlanItems || !displayExecuteItems || !displayCloseItems || !displayBPItems}">
          <div>
              <div class="col-md-12 no-wrap-flex scopeRiskPercentageArea" *ngIf="isScopeRiskEnabled"
                   [ngClass]="{'hidden-print': summaryForm.get('hideScopeRiskPercentage')['value']}">
                <mat-form-field [floatLabel]="'always'" class="no-wrap">
                  <mat-label class="mat-prefix">Scope Risk Percentage</mat-label>
                  <span class="mat-prefix-remmargin display-flex">
                    <input matInput readonly formControlName="scopeRiskPercentage" id="scopeRiskPercentage" />
                    <span matSuffix>%</span>
                  </span>
                </mat-form-field>
                <mat-checkbox formControlName="hideScopeRiskPercentage" class="hidden-print" id="hideScopeRiskPercentage" >
                  Hide Scope Risk Percentage
                </mat-checkbox>
              </div>

            <div class="col-md-12 no-wrap-flex scopeRiskPriceArea"
                 [ngClass]="{'hidden-print': summaryForm.get('hideScopeRiskPrice')['value']}" *ngIf="isScopeRiskEnabled">
              <mat-form-field [floatLabel]="'always'" class="no-wrap">
                <mat-label class="mat-prefix">Scope Risk Price</mat-label>
                <span class="mat-prefix-remmargin display-flex">
                  <span matPrefix>$&nbsp;</span>
                  <input matInput readonly currencyMask formControlName="scopeRiskPrice" id="scopeRiskPrice" />
                </span>
              </mat-form-field>
              <mat-checkbox formControlName="hideScopeRiskPrice" class="hidden-print" id="hideScopeRiskPrice">
                Hide Scope Risk Price
              </mat-checkbox>
            </div>
            <div class="col-md-12 no-wrap-flex"
                 [ngClass]="{'hidden-print': summaryForm.get('hideProfessionalServices')['value']}">
              <mat-form-field [floatLabel]="'always'" class="no-wrap">
                <mat-label class="mat-prefix">Engineering Services</mat-label>
                <span class="mat-prefix-remmargin display-flex">
                  <span matPrefix>$&nbsp;</span>
                  <input matInput currencyMask readonly formControlName="professionalServices" />
                </span>
              </mat-form-field>
              <mat-form-field [floatLabel]="'always'" class="larger">
                <mat-label>Notes</mat-label>
                <input matInput formControlName="professionalServicesNotes" type="text" />
              </mat-form-field>
              <mat-checkbox formControlName="hideProfessionalServices" class="hidden-print">
                Hide Engineering Services
              </mat-checkbox>
            </div>

            <div class="col-md-12 no-wrap-flex"
                 [ngClass]="{'hidden-print': summaryForm.get('hideSpecialApplicationAndPremierServices')['value']}">
              <mat-form-field [floatLabel]="'always'" class="no-wrap">
                <mat-label class="mat-prefix">Special Application & Premier Services</mat-label>
                <span class="mat-prefix-remmargin display-flex">
                  <span matPrefix>$&nbsp;</span>
                  <input readonly matInput formControlName="specialApplicationAndPremierServices" currencyMask />
                </span>
              </mat-form-field>
              <mat-form-field [floatLabel]="'always'" class="larger">
                <mat-label>Notes</mat-label>
                <input matInput formControlName="specialApplicationAndPremierServicesNotes" type="text" />
              </mat-form-field>
              <mat-checkbox formControlName="hideSpecialApplicationAndPremierServices" class="hidden-print">
                Hide Special
                Application & Premier Services
              </mat-checkbox>
            </div>
            <div class="col-md-12 no-wrap-flex"
                 [ngClass]="{'hidden-print': summaryForm.get('hideProjectManagement')['value']}">
              <mat-form-field [floatLabel]="'always'" class="no-wrap">
                <mat-label class="mat-prefix">PM Services</mat-label>
                <span class="mat-prefix-remmargin display-flex">
                  <span matPrefix>$&nbsp;</span>
                  <input matInput readonly currencyMask formControlName="projectManagement" />
                </span>
              </mat-form-field>
              <mat-form-field [floatLabel]="'always'" class="larger">
                <mat-label>Notes</mat-label>
                <input matInput formControlName="projectManagementNotes" type="text" />
              </mat-form-field>
              <mat-checkbox formControlName="hideProjectManagement" class="hidden-print">Hide PM Services</mat-checkbox>
            </div>
            <div class="col-md-12 no-wrap-flex"
                 [ngClass]="{'hidden-print': summaryForm.get('hideMiscCharges')['value']}">
              <mat-form-field [floatLabel]="'always'" class="no-wrap">
                <mat-label class="mat-prefix">Material Item Charges</mat-label>
                <span class="mat-prefix-remmargin display-flex">
                  <span matPrefix>$&nbsp;</span>
                  <input matInput readonly currencyMask formControlName="miscCharges" />
                </span>
              </mat-form-field>
              <mat-form-field [floatLabel]="'always'" class="larger">
                <mat-label>Notes</mat-label>
                <input matInput formControlName="miscChargesNotes" type="text" />
              </mat-form-field>
              <mat-checkbox formControlName="hideMiscCharges" class="hidden-print">
                Hide Material Item Charges
              </mat-checkbox>
            </div>
            <div class="col-md-12 no-wrap-flex"
                 [ngClass]="{'hidden-print': summaryForm.get('hidetravelExpense')['value']}">
              <mat-form-field [floatLabel]="'always'" class="no-wrap">
                <mat-label class="mat-prefix">Travel Expenses</mat-label>
                <span class="mat-prefix-remmargin display-flex">
                  <span matPrefix>$&nbsp;</span>
                  <input matInput readonly currencyMask formControlName="travelExpense" />
                </span>
              </mat-form-field>
              <mat-form-field [floatLabel]="'always'" class="larger">
                <mat-label>Notes</mat-label>
                <input matInput formControlName="travelExpenseNotes" type="text" />
              </mat-form-field>
              <mat-checkbox formControlName="hidetravelExpense" class="hidden-print">Hide Travel Expenses</mat-checkbox>
            </div>
            <div class="col-md-12 no-wrap-flex"
                 [ngClass]="{'hidden-print': summaryForm.get('hideTotalBpCharges')['value']}">
              <mat-form-field [floatLabel]="'always'" class="no-wrap">
                <mat-label class="mat-prefix">Total BP charges</mat-label>
                <span class="mat-prefix-remmargin display-flex">
                  <span matPrefix>$&nbsp;</span>
                  <input matInput readonly currencyMask formControlName="totalBpCharges" />
                </span>
              </mat-form-field>
              <mat-form-field [floatLabel]="'always'" class="larger">
                <mat-label>Notes</mat-label>
                <input matInput formControlName="totalBpChargesNotes" type="text" />
              </mat-form-field>
              <mat-checkbox formControlName="hideTotalBpCharges" class="hidden-print">
                Hide Total BP charges
              </mat-checkbox>
            </div>
            <div class="col-md-12 no-wrap-flex"
                 [ngClass]="{'hidden-print': summaryForm.get('hideTotalBpHours')['value']}"
                 *ngIf="summaryForm.controls['serviceEstimateType'].value == 1">
              <mat-form-field [floatLabel]="'always'" class="no-wrap">
                <mat-label class="mat-prefix">Total BP Hours</mat-label>
                <span class="mat-prefix-remmargin display-flex">
                  <input matInput readonly type="number" formControlName="totalBpHours" />
                </span>
              </mat-form-field>
              <mat-form-field [floatLabel]="'always'" class="larger">
                <mat-label>Notes</mat-label>
                <input matInput formControlName="totalBpHoursNotes" type="text" />
              </mat-form-field>
              <mat-checkbox formControlName="hideTotalBpHours" class="hidden-print">
                Hide Total BP Hours
              </mat-checkbox>
            </div>
          </div>
          <div class="col-md-12 no-wrap-flex"
               [ngClass]="{'hidden-print': summaryForm.get('hideSEPriceAdjustments')['value']}">
            <mat-form-field [floatLabel]="'always'" class="no-wrap">
              <mat-label class="mat-prefix">Service Estimate Price Adjustments</mat-label>
              <span class="mat-prefix-remmargin display-flex">
                <span matPrefix>$&nbsp;</span>
                <input matInput readonly currencyMask formControlName="serviceEstimatePriceAdjustments" />
              </span>
            </mat-form-field>
            <mat-form-field [floatLabel]="'always'" class="larger">
              <mat-label>Notes</mat-label>
              <input matInput formControlName="serviceEstimatePriceAdjustmentsNote" type="text" />
            </mat-form-field>
            <mat-checkbox formControlName="hideSEPriceAdjustments" class="hidden-print">
              Hide SE Price Adjustments
            </mat-checkbox>
          </div>
          <div class="col-md-12 no-wrap-flex compPriceArea" [ngClass]="{'hidden-print': summaryForm.get('hideSEComponentPriceAdjustments')['value']}">
            <mat-form-field [floatLabel]="'always'" class="no-wrap lg">
              <mat-label class="mat-prefix">Service Estimate Component Price Adjustments</mat-label>
              <span class="mat-prefix-remmargin display-flex">
                <span matPrefix>$&nbsp;</span>
                <input id="compPriceInput" matInput readonly currencyMask formControlName="seComponentPriceAdjustment" />
              </span>
            </mat-form-field>
            <mat-checkbox formControlName="hideSEComponentPriceAdjustments" class="hidden-print" id="compPriceCheckBox">
              Hide SE Component Price Adjustments
            </mat-checkbox>
          </div>
          <div class="col-md-12 no-wrap-flex"
               [ngClass]="{'hidden-print': summaryForm.get('hideTotalInstallationPrice')['value']}">
            <mat-form-field [floatLabel]="'always'" class="no-wrap">
              <mat-label class="mat-prefix">Total Estimate</mat-label>
              <span class="mat-prefix-remmargin display-flex">
                <span matPrefix>$&nbsp;</span>
                <input matInput readonly currencyMask formControlName="totalInstallationPrice" />
              </span>
            </mat-form-field>
            <mat-form-field [floatLabel]="'always'" class="larger">
              <mat-label>Notes</mat-label>
              <input matInput formControlName="totalInstallationPriceNotes" type="text" />
            </mat-form-field>
            <mat-checkbox formControlName="hideTotalInstallationPrice" class="hidden-print">
              Hide Total Estimate
            </mat-checkbox>
          </div>
          <div class="col-md-12 no-wrap-flex"
               [ngClass]="{'hidden-print': summaryForm.get('hideForeignCurrency')['value']}">
            <mat-form-field [floatLabel]="'always'" class="no-wrap lg">
              <mat-label class="mat-prefix">Total Estimate Selected Foreign Currency Price</mat-label>
              <span class="mat-prefix-remmargin align-currency-symbol">
                <span matPrefix class="display-flex">{{getCurrenctCurrencyCode()}}&nbsp;</span>
                <input matInput readonly currencyMask formControlName="currencyPrice" />
              </span>
            </mat-form-field>
            <mat-checkbox formControlName="hideForeignCurrency" class="hidden-print">
              Hide Foreign Currency
            </mat-checkbox>
          </div>
          <div class="col-md-12" [ngClass]="{'hidden-print': summaryForm.get('hideComponentNotes')['value']}">
            <mat-form-field [floatLabel]="'always'" class="largest">
              <mat-label class="no-prefix mat-prefix">Component Notes</mat-label>
              <span class="mat-prefix-remmargin display-flex">
                <textarea class="no-prefix" matInput rows="15" formControlName="componentNotes" readonly></textarea>
              </span>
            </mat-form-field>
            <mat-checkbox formControlName="hideComponentNotes" class="hidden-print">
              Hide Component Notes
            </mat-checkbox>
          </div>
          <div class="col-md-12" [ngClass]="{'hidden-print': summaryForm.get('hideAdditonalNotes')['value']}">
            <mat-form-field [floatLabel]="'always'" class="largest">
              <mat-label class="no-prefix mat-prefix">SOW and Engineering Notes</mat-label>
              <span class="mat-prefix-remmargin display-flex">
                <textarea class="no-prefix" matInput rows="15" formControlName="additonalNotes"></textarea>
              </span>
            </mat-form-field>
            <mat-checkbox formControlName="hideAdditonalNotes" class="hidden-print">
              Hide SOW and Engineering Notes
            </mat-checkbox>
          </div>
          <p style="bottom: 20px;margin:10px;" class="show-print">
            <b>
              <i>
                <small>
                  The pricing herein is
                  confidential
                  and
                  proprietary
                  information of Meridian.
                </small>
              </i>
            </b>
          </p>
        </div>
      </div>
</mat-card-content>
  </mat-card>
</form>
