import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from "../shared/shared.module";

import { ManagedServicesProviderRoutingModule } from './managed-services-provider-routing.module';
import { ManagedServicesProviderComponent } from './managed-services-provider.component';
import { ManagedServicesProviderService } from './managed-services-provider.service';

@NgModule({
  imports: [
    CommonModule,
    ManagedServicesProviderRoutingModule,
    SharedModule
  ],
  declarations: [
    ManagedServicesProviderComponent
  ],
  providers: [ManagedServicesProviderService]
})
export class ManagedServicesProviderModule { }
