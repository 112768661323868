<div class="container-fluid">
  <mat-card>
    <button mat-raised-button color="accent" [routerLink]="['create']">Create New Test Customer</button>
    <div *ngIf="!isLoading" style="position: relative; margin:auto;width:calc(50% + 225px)">
      <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
    </div>
    <mat-card-content>
      <mat-table #table [dataSource]="dataSource" *ngIf="isLoading">
        <ng-container matColumnDef="rowKey">
          <mat-header-cell *matHeaderCellDef>
            Testing ID
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.rowKey}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef>
            Created Date
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.timestamp | date}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="contactSalesforceID">
          <mat-header-cell *matHeaderCellDef>
            Contact Salesforce ID
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.contactSalesforceID}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customerSalesforceID">
          <mat-header-cell *matHeaderCellDef>
            Customer Salesforce ID
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.customerSalesforceID}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="epicorCustomerID">
          <mat-header-cell *matHeaderCellDef>
            Epicor Customer ID
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.epicorCustomerID}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="externalID">
          <mat-header-cell *matHeaderCellDef>
            External ID
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.externalID}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>
            Status
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customerDataError">
          <mat-header-cell *matHeaderCellDef>
            Customer Data Error
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.customerDataError}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="contactDataError">
          <mat-header-cell *matHeaderCellDef>
            Contact Data Error
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.contactDataError}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="shippingLocationDataError">
          <mat-header-cell *matHeaderCellDef>
            Shipping Location Data Error
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.shippingLocationDataError}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="billingLocationDataError">
          <mat-header-cell *matHeaderCellDef>
            Billing Location Data Error
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.billingLocationDataError}} </mat-cell>
        </ng-container>
        <!--<ng-container matColumnDef="vehicleId">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button id="kebabMenu" [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu" [attr.data-id]="element.vehicleId">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item color="accent" type="button" [routerLink]="['detail',element.vehicleId]">
                  <mat-icon>edit</mat-icon>
                  <span>Details/Edit</span>
                </button>
                <button mat-menu-item color="primary" type="button" [routerLink]="['addMileage',element.vehicleId]">
                  <mat-icon>add</mat-icon>
                  <span>Add Mileage</span>
                </button>
                <button mat-menu-item color="warn" type="button" (click)="deleteVehicle(element.vehicleId)">
                  <mat-icon>delete_outline</mat-icon>
                  <span>Retire</span>
                </button>
                <button mat-menu-item color="warn" type="button" (click)="unassignVehicle(element.vehicleId)"
                  *ngIf="element.vehicleStatus == 1">
                  <mat-icon>cancel</mat-icon>
                  <span>Unassign Driver</span>
                </button>
                <button mat-menu-item color="warn" type="button" (click)="reassignVehicle(element.vehicleId)"
                  *ngIf="element.vehicleStatus !== 1">
                  <mat-icon>assignment_ind</mat-icon>
                  <span>Assign Driver</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container> -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <div *ngIf="!(dataSource && dataSource.data.length > 0) && isLoading" class="no-records">
        No records found
      </div>
      <mat-paginator *ngIf="isLoading" #paginator
                   [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="50"
                   [pageSizeOptions]="[5, 25, 50, 100]">
    </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>