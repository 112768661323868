<div [formGroup]="phaseItemGroup">
  <mat-form-field [floatLabel]="'always'" class="larger remove-space">
    <mat-label class="no-prefix">Activity Name</mat-label>
    <textarea class="no-prefix expand" matInput readonly cdkTextareaAutosize formControlName="activityName"></textarea>
  </mat-form-field>
  <mat-form-field [floatLabel]="'always'" *ngIf="selectionItems[0].isVisible" class="remove-space">
    <mat-label class="no-prefix">Total Hours</mat-label>
    <input class="no-prefix" matInput currencyMask readonly formControlName="hours" />
  </mat-form-field>
  <mat-form-field [floatLabel]="'always'" *ngIf="selectionItems[1].isVisible" class="remove-space">
    <mat-label>Billable Rate</mat-label>
    <span matPrefix>$&nbsp;</span>
    <input matInput currencyMask readonly formControlName="billrate" currencyMask />
  </mat-form-field>
  <mat-form-field [floatLabel]="'always'" *ngIf="selectionItems[2].isVisible" class="remove-space">
    <mat-label>Total Sell Price</mat-label>
    <span matPrefix>$&nbsp;</span>
    <input matInput currencyMask readonly formControlName="total" currencyMask />
  </mat-form-field>
  <mat-checkbox formControlName="isVisible" class="hidden-print" (ngModelChange)="isChecked($event)">
    Hide Item
  </mat-checkbox>
</div>
