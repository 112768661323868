<!-- <button type="button" (click)="sendEmail()">Send Da Email</button> -->
<div id="landing-page">
  <div id="default-view" class="display-flex" *ngIf="!isDashboardViewEnabled">
    <div class="container">
      <br>
      <div class="row">
        <div class="col-md-4 col-sm-12" *ngIf="isFleetManagementUser">
          <mat-card id="fleetmanagementCard">
            <mat-card-header>
              <mat-card-title>Fleet Management</mat-card-title>
              <mat-card-subtitle> Place to record expenses, log and track vehicle usage.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
              <a mat-button color="accent" routerLinkActive="active" [routerLink]="['fleetmanagement']">Go</a>
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="col-md-4 col-sm-12" *ngIf="isSuperAdmin || isDirector">
          <mat-card id="adminCard">
            <mat-card-header>
              <mat-card-title>Admin</mat-card-title>
              <mat-card-subtitle> Place to manage Employees, Cards and Roles.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
              <a mat-button color="accent" routerLinkActive="active" [routerLink]="['admin']">Go</a>
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="col-md-4 col-sm-12" *ngIf="isServiceEstimateUser || isDirector">
          <mat-card id="projectsCard">
            <mat-card-header>
              <mat-card-title>Projects</mat-card-title>
              <mat-card-subtitle> Place to quote and manage services estimates.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
              <a mat-button color="accent" routerLinkActive="active"
                [routerLink]="['professionalServices']">Go</a>
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="col-md-4 col-sm-12">
          <mat-card id="supportCard">
            <mat-card-header>
              <mat-card-title>Support</mat-card-title>
              <mat-card-subtitle> Place to submit Portal Support Request and Incident Reports.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
              <a mat-button color="accent" routerLinkActive="active" [routerLink]="['support']">Go</a>
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="col-md-4 col-sm-12" *ngIf="hasBusinessPartnerAccess">
          <mat-card id="businessPartnersCard">
            <mat-card-header>
              <mat-card-title>Business Partners</mat-card-title>
              <mat-card-subtitle>Place to manage business partners, and solution pillars.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>

              <a mat-button color="accent" routerLinkActive="active" [routerLink]="['partners']"
                *ngIf="hasBusinessPartnerAccess">
                Go
              </a>
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="col-md-4 col-sm-12" *ngIf="isSuperAdmin || isDirector || hasClientAccess">
          <mat-card id="clientsCard">
            <mat-card-header>
              <mat-card-title>Clients</mat-card-title>
              <mat-card-subtitle>Place to manage clients, and special client settings.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>

              <a mat-button color="accent" routerLinkActive="active" [routerLink]="['clients']">
                Go
              </a>
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="col-md-4 col-sm-12" *ngIf="isSuperAdmin || isDirector">
          <mat-card id="reportsCard">
            <mat-card-header>
              <mat-card-title>Reports</mat-card-title>
              <mat-card-subtitle>Place to submit Reports.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>

              <a mat-button color="accent" routerLinkActive="active" [routerLink]="['reports']">
                Go
              </a>
            </mat-card-actions>
          </mat-card>
        </div>
        <!-- <div class="col-md-12" *ngIf="!hasVisited">
          <mat-card>
              <mat-card-content>
                  <h4>If this is the first login since an update please empty your cache so you have the most recent
                      verison of the web application.</h4>
                  <p>Update 3-19-2019
                      Fleet Management: Reports
                  </p>
              </mat-card-content>
          </mat-card>
      </div> -->
      </div>
    </div>
    <!--<a [routerLink]="[{ outlets: { popup: ['compose'] } }]">Contact</a>
  <a [routerLink]="[{ outlets: { releaseNotes: ['releaseNotesDisplay'] } }]">releaseNotes</a>
  <router-outlet name="popup"></router-outlet>
  <router-outlet name="releaseNotes"></router-outlet>-->
    <!--<a [routerLink]="['/lazymember', {outlets: {lazyright: ['abc']}}]">Edit works - No, no longer</a>-->

  </div>
  <mat-card id="dashboard-view" *ngIf="isDashboardViewEnabled">
    <app-dashboard></app-dashboard>
  </mat-card>
  <div class="maintenence-content" *ngIf="currentRelease?.releaseDate && currentRelease?.isMaintenanceNotified">
    <b><span style="color:red;">Maintenance Alert:</span> Scheduled Maintenence Activity on
      {{currentRelease?.releaseDate|
      date:'shortDate'}}</b>
  </div>
</div>
