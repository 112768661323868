import { Component, OnInit } from '@angular/core';
import { ConfirmDeleteDialogComponent } from 'src/app/shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { ApproverThresholdService } from '../approver-threshold.service';
import { ThresholdDataSource } from '../threshold.datasource';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-threshold-list',
  templateUrl: './threshold-list.component.html',
  styleUrls: ['./threshold-list.component.scss']
})
export class ThresholdListComponent implements OnInit {
  threshHolds: ThresholdDataSource;
  displayedColumns: string[];
  constructor(private service: ApproverThresholdService, private dialog: MatDialog) { }

  ngOnInit() {
    this.displayedColumns = [
      'amount',
      'employee',
      'actions'
    ];
    this.getThreshHoldsAndUpdateTable();
  }
  deleteThreshHold(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.service.deleteThreshold(id)
            .subscribe(res => {
              this.getThreshHoldsAndUpdateTable();
            });
        }
      });
  }
  getThreshHoldsAndUpdateTable() {
    this.service.getThresholds()
      .subscribe(res => {
        const data = res;
        this.updateTable(data);

      });
  }
  updateTable(data: any) {
    this.threshHolds = new ThresholdDataSource();
    if (data) {
      this.threshHolds.setData(data);
    }

  }

}
