export enum MarginOptionType {
  Uplift = 0,
  GrossMargin = 1
}

export class MarginOption {
  UpliftPercentage: number;
  GrossMarginPercentage: number;
  MarginOptionType: MarginOptionType;

  toJSON(): any {
    return {
      ...this,
      MarginOptionType: this.MarginOptionType.valueOf()
    };
  }
}
