<mat-card>
  <mat-card-content>
    <!-- <p>
      <button mat-mini-fab type="button" (click)="onUndo($event)">
        <mat-icon>undo</mat-icon>
      </button>
    </p> -->
    <form *ngIf="mileageForm" [formGroup]="mileageForm" (ngSubmit)="updateMileage($event)">
      <p *ngIf="isUpdateError">There was an error updating the mileage. Please try again if the error persist contact support.</p>
      <div class="col-md-12">
        <mat-form-field>
          <input matInput type="number" formControlName="vehicleStartingMileage" name="vehicleStartingMileage" [(ngModel)]="mileage.vehicleUsageMileageStart"
            placeholder="Starting Mileage" />
        </mat-form-field>
      </div>
      <br>

      <div class="col-md-12">
        <mat-form-field>
          <input matInput type="number" formControlName="incorrectMileage" name="incorrectMileage" [(ngModel)]="editViewModel.vehicleIncorrectEndMileage"
            placeholder="Incorrect End Mileage" />
        </mat-form-field>
      </div>
      <br>
      <div class="col-md-12">
        <mat-form-field>
          <input matInput type="number" formControlName="mileageToUpdate" name="mileageToUpdate" [(ngModel)]="mileage.vehicleUsageMileageEnd"
            placeholder="Correct End Mileage" />
          <mat-error *ngIf="mileageForm.controls['mileageToUpdate'].hasError('min')">The ending mileage must be more than the starting mileage.</mat-error>
        </mat-form-field>
      </div>
      <br>
      <button mat-raised-button type="submit" [disabled]="!isSubmitAvailable">Update Mileage</button>
    </form>
  </mat-card-content>
</mat-card>