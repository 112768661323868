import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { interval, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ReleaseNote, ReleaseNotesAcknowledgement, ReleaseNotesIteration } from '../../../admin/release-notes/models/releaseNotes';
import { AzureDevOpsWorkItem, AzureDevOpsWorkItemData, AzureDevOpsWorkItemFields } from '../../models/iteration';
import { ReleaseNotesService } from '../../services/release-notes.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-release-notes-display',
  templateUrl: './release-notes-display.component.html',
  styleUrls: ['./release-notes-display.component.scss']
})
export class ReleaseNotesDisplayComponent implements OnInit, OnDestroy {
  currentRelease?: ReleaseNote | null;
  workItems: Array<AzureDevOpsWorkItemFields>;
  userstories: Array<AzureDevOpsWorkItemFields>;
  bugs: Array<AzureDevOpsWorkItemFields>;
  isLoading: boolean;
  disableAckBtn: boolean;
  noRecords: boolean;
  iterationWorkItems: Array<AzureDevOpsWorkItem> | null;
  iterationCount: number;
  batchCount: number;
  ids: number[];
  imageUrls: string[];
  workItemsData: Array<AzureDevOpsWorkItemFields>;
  //iterations: Array<ReleaseNotesIteration>;
  @Output() clearNotes: EventEmitter<boolean> = new EventEmitter<boolean>();
  isDarkViewEnabled: boolean;
  private destroySubject: Subject<boolean>;

  constructor(private releaseNotesService: ReleaseNotesService, private router: Router,      
    private sanitizer: DomSanitizer, private overlayContainer: OverlayContainer) { }

  ngOnInit(): void {
    this.isDarkViewEnabled = this.overlayContainer.getContainerElement().classList.contains("dark-theme");
    this.isLoading = true;
    this.workItems = [];
    this.userstories = [];
    this.bugs = [];
    this.noRecords = false;
    this.ids = [];
    this.iterationCount = 0;
    this.destroySubject = new Subject<boolean>();
    this.isAcknowledged();
    this.getCurrentReleaseInfo();
  }

  getCurrentReleaseInfo() {
    this.releaseNotesService.getCurrentReleaseInfo().pipe(
      takeUntil(this.destroySubject)
    ).subscribe((res: ReleaseNote | null) => {
      this.currentRelease = res;
      //this.iterations = res.iterations;
      if (this.currentRelease && this.currentRelease.isReleaseNoteNotified) {        
        this.getIterations();

      } else {
        this.noRecords = true;
        this.isLoading = false;
      }
    }, (error) => {
      this.isLoading = false;
    })
  }

  getIterations() {
    this.currentRelease?.iterations.forEach((iteration: ReleaseNotesIteration, index: number) => {
      this.getIterationWorkItems(iteration.azureIterationId);
    });
  }

  getIterationWorkItems(iterationId: string) {
    this.releaseNotesService.getIterationWorkItems(iterationId).pipe(
      takeUntil(this.destroySubject)
    ).subscribe((res: Array<AzureDevOpsWorkItem> | null) => {
      this.iterationWorkItems = res;
      this.iterationWorkItems?.map((x: AzureDevOpsWorkItem) => {
        if (this.ids.indexOf(x.target.id) == -1)
          this.ids.push(x.target.id);
      });
      if (++this.iterationCount == this.currentRelease?.iterations.length && this.ids.length > 0) {
        let wiBatches: any = [];
        if (this.ids.length > 200) {
          wiBatches = this.getWIBatches(this.ids, 200);
        } else {
          wiBatches = [this.ids];
        }
        this.batchCount = 0;
        this.workItemsData = [];
        for (let i = 0; i < wiBatches.length; i++) {
          this.getWorkItemsData(wiBatches[i], wiBatches.length);
        }
      } else if (this.ids.length == 0) {
        this.noRecords = true;
        this.isLoading = false;
      }
    }, (error) => {
      this.isLoading = false;
    });
  }

  getWorkItemsData(wiBatches: any, wiLength: number) {
    this.releaseNotesService.getWorkItemsData(wiBatches).pipe(
      takeUntil(this.destroySubject)
    ).subscribe((res: Array<AzureDevOpsWorkItemData>) => {
      this.workItemsData = res.map((x: AzureDevOpsWorkItemData) => x.fields).filter(x => x.includeinReleaseNotes);
      this.workItemsData.forEach((item: AzureDevOpsWorkItemFields) => {
        let releaseNoteDetails: any = item.releaseNoteDetails;
        item.imgDetails = this.loadImgData(releaseNoteDetails);
        item.releaseNoteDetails = this.getReleaseNoteDetails(releaseNoteDetails);
        this.workItems.push(item);
      })
      if (++this.batchCount == wiLength) {
        //this.workItems = workItemsData;
        this.bugs = this.workItems.filter((wi: AzureDevOpsWorkItemFields) => wi.workItemType == "Bug");
        this.userstories = this.workItems.filter((wi: AzureDevOpsWorkItemFields) => wi.workItemType == "User Story");
        this.isLoading = false;
      }
    }
      , (error) => {
        this.isLoading = false;
      })
  }

  getWIBatches(wiIds: any[], size: number): any[] {
    const batches: any[] = [],
      wiCount = wiIds.length;
    let wiIndex = 0,
      batchIndex = 0;
    while (wiIndex < wiCount) {
      batches[batchIndex++] = wiIds.slice(
        wiIndex,
        (wiIndex += size)
      );
    }
    return batches;
  }

  loadImgData(details: string) {
    var rex = /<img[^>]+src="?([^"\s]+)"?\s*/gi;
    let urlArray: any;
    var imageUrls = [];
    while (urlArray = rex.exec(details)) {
      imageUrls.push(urlArray[1]);
    }
    return imageUrls;
  }

  viewMockup(details: any) {
    const imageUrlsQueryParam = details.join(',');
    const url = `displayImage?imageUrls=${imageUrlsQueryParam}`;
    window.open(url, '_blank');
  }

  getReleaseNoteDetails(details: string | null) {
    let actualData = details;
    return actualData ? this.sanitizer.bypassSecurityTrustHtml(actualData) : null;
  }

  isAckBtnDisplayed() {
    return this.userstories?.length > 0 || this.bugs?.length > 0;
  }

  acknowledge() {
    this.disableAckBtn = true;
    if (this.currentRelease)
      this.releaseNotesService.acknowledge(this.currentRelease.id).pipe(
        takeUntil(this.destroySubject)
      ).subscribe((res: ReleaseNotesAcknowledgement) => {
        this.closePopup();
      });
  }

  isAcknowledged() {
    this.releaseNotesService.isAcknowledged().pipe(
      takeUntil(this.destroySubject)
    ).subscribe((res: boolean) => {
      this.disableAckBtn = res;
    });
  }

  closePopup() {
    this.clearNotes.emit(true);
  }

  getFontColor() {
    return !this.isDarkViewEnabled ? 'theme' : 'dark-theme';
  }

  ngOnDestroy() {
    this.destroySubject.next(true);
    this.destroySubject.complete();
  }

  navigateToWorkItemDetails(workItemId: number){
    const url = this.router.serializeUrl(this.router.createUrlTree(['help', 'releaseHistory', 'details', workItemId]));
    window.open(url, '_blank');
  }
}
