import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-select-check-all',
  templateUrl: './select-check-all.component.html',
  styleUrls: ['./select-check-all.component.scss']
})
export class SelectCheckAllComponent {
  @Input() model: UntypedFormControl;
  @Input() values = [] as any[];
  @Input() text = 'Select All'; 
  @Output() isAllSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {    
  }

  isChecked(): boolean {
    return this.model?.value && this.values?.length
      && this.model?.value?.length === this.values?.length;
  }

  isIndeterminate(): boolean {
    if (this.model?.value?.length == 0) {
      return false;
    }
    return this.model?.value && this.values?.length && this.model?.value?.length
      && this.model?.value?.length < this.values?.length;
  }

  toggleSelection(change: MatCheckboxChange): void {
    this.isAllSelected.emit(change.checked);   
  }
}
