import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBySoulthinPillarId'
})
export class FilterBySoulthinPillarIdPipe implements PipeTransform {

  transform(solutionPilars: any, solutionPilarBusinessPartners?: any): any {
    let _sps: Array<any>;
    _sps = [];
    solutionPilars.forEach((s: any) => {
      solutionPilarBusinessPartners.forEach((sid: any) => {
        if (s.solutionPilarId == sid.solutionPilarId) {
          _sps.push(s);
        }
      });
    });
    return _sps;
  }

}
