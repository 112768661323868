import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildInfoService {

  constructor(private httpClient: HttpClient) { }
 
  private data = new BehaviorSubject('');
  data$ = this.data.asObservable();

  setVersion(data: string) {
    this.data.next(data)
  }
}
