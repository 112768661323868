import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-migration-discovery-dashboard',
  templateUrl: './cloud-migration-discovery-dashboard.component.html',
})
export class CloudMigrationDiscoveryDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
