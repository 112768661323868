<mat-table id="projectsTable" *ngIf="projectsData" mat-table class="full-width-table" aria-label="Projects Table" [dataSource]="rowData">
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.title}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="projectCount">
    <mat-header-cell *matHeaderCellDef>Projects Count</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getProjectStatusCount(null, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="active">
    <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getProjectStatusCount(0, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="lost">
    <mat-header-cell *matHeaderCellDef>Lost</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getProjectStatusCount(2, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="complete">
    <mat-header-cell *matHeaderCellDef>Complete</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getProjectStatusCount(3, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="inActive">
    <mat-header-cell *matHeaderCellDef>InActive</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getProjectStatusCount(1, row.id)}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
