import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Tag } from '../models/tag.model';
import { TagsService } from '../tags.service';
import { ActivatedRoute} from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-tags-edit',
  templateUrl: './tags-edit.component.html',
  styleUrls: ['./tags-edit.component.scss']
})
export class TagsEditComponent implements OnInit {

  tagFormGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private tagsService: TagsService, private location: Location) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(routeParams => {
        const id = routeParams['id'];
        this.tagsService.getTag(id)
          .subscribe(res => {
            const data: Tag = res;
            this.tagFormGroup = this.fb.group({
              'id': [data.id],
              'name': [data.name, Validators.required],
              'createdDate': [data.createdDate],
              'createdByEmployeeId': [data.createdByEmployeeId]
            });

          });
      });
  }

  submitForm() {
    if (this.tagFormGroup.valid) {
      const _tag: Tag = this.tagFormGroup.value;
      this.tagsService.updateTag(_tag)
        .subscribe(createResult => {          
          this.location.back();

        },
          (error) => {

          });
    }
  }
  goBack() {
    this.location.back();
  }


}
