<div *ngIf="fuel">
  <div class="col-md-4">
    <mat-form-field>
      <input type="text"
             placeholder="Amount"
             [(ngModel)]="fuel.amount"
             matInput />
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <mat-form-field>
      <input type="text"
             placeholder="Gallons"
             [(ngModel)]="fuel.gallons"
             matInput />

    </mat-form-field>
  </div>
  <div class="col-md-4">
    <mat-form-field>
      <input type="text"
             placeholder="Gas Card Id"
             [(ngModel)]="fuel.gasCardId"
             matInput />
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <mat-form-field>
      <input matInput
             [(ngModel)]="fuel.driversName"
             placeholder="Drivers Name" />
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <button mat-raised-button
            color="primary"
            (click)="saveFuel()"
            type="button">Save</button>
  </div>
</div>