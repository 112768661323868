<fieldset>
  <legend>
    Network Summary
  </legend>
  <mat-table mat-table matSort class="full-width-table" aria-label="Network Summary Table" [dataSource]="tableDataSource">
    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.label}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bandwidth">
      <mat-header-cell *matHeaderCellDef>Bandwidth(GB/mo)</mat-header-cell>
      <mat-cell [ngClass]="{highlightUpdated: networkRequiredBandwitdhUpdated && odd}" *matCellDef="let row; let odd = odd;" >{{row.bandwidth ? +row.bandwidth : 'N/A'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef>Network Cost($)</mat-header-cell>
      <mat-cell [ngClass]="{highlightUpdated: networkRequiredBandwitdhUpdated && odd}" *matCellDef="let row; let odd = odd;" >{{row.cost ? '$'+row.cost : 'N/A'}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator style="visibility:hidden; height:0px; margin:0px 0px 0px 0px">
  </mat-paginator>
</fieldset>
