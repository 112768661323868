<mat-card *ngIf="vmForm">
  <form [formGroup]="vmForm" (ngSubmit)="saveManager()">
    
    <mat-card-content>
      <div class="col-xs-6 col-sm-4 col-md-2">
        <mat-form-field>
          <mat-select multiple formControlName="driverIds" placeholder="Please select managed drivers" disableOptionCentering>
            <mat-option *ngFor="let driver of drivers" [value]="driver.vehicleDriverId"> {{driver.firstName + ' ' + driver.lastName}} </mat-option>
          </mat-select>
          <mat-error *ngIf="vmForm.controls['driverIds'].hasError('required')">At least one driver must be selected.</mat-error>
        </mat-form-field>
        <!-- <button mat-raised-button color="primary" type="button" (click)="clearDrivers()">Clear Drivers</button> -->
      </div>
    </mat-card-content>
    <button mat-raised-button color="primary" type="submit" [disabled]="!vmForm.valid">Save</button>
  </form>
</mat-card>
