import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Employee } from './employee';
import { of, Observable } from 'rxjs';
export class EmployeeModelDataSource extends DataSource<any> {
    data: Array<Employee>;
    connect(): Observable<Employee[]> {

        return of(this.data);
    }
    disconnect(collectionViewer: CollectionViewer): void {

    }
    setData(data: Array<Employee>) {
        this.data = data;
    }

}
