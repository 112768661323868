import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  Assessment,
} from '../../../models/assessment-datasource';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CloudMigrationToolService } from 'src/app/cloud-migration-tool/cloud-migration-tool.service';
import {
  CloudMigrationMatchCloudproductComponent,
  CloudProductMatchingCriteria,
} from '../../cloud-migration-match-cloudproduct/cloud-migration-match-cloudproduct.component';

@Component({
  selector: 'app-cloud-migration-cloud-solution-details',
  templateUrl: './cloud-migration-cloud-solution-details.component.html',
  styleUrls: ['./cloud-migration-cloud-solution-details.component.scss'],
})
export class CloudMigrationCloudSolutionDetailsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  cloudSolDetailsDataSource: any;
  @Input() assessment: Assessment;
  @Input() priceUpdate: (args: any) => void;
  cloudSolcolumns = Array<any>();
  cloudProductMatchingCriteria: CloudProductMatchingCriteria;
  cloudProductMatchingRow: any;
  lparLabeling: any;
  initialLoad: boolean = true;
  itemCount: any;

  constructor(
    private service: CloudMigrationToolService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setDataSource();
    this.initialLoad = false;
  }

  ngOnChanges() {
    if (!this.initialLoad) {
      this.setDataSource();
    }
  }

  setDataSource() {
    let envDetails = this.assessment?.cloudSolutionDetails;
    this.cloudSolDetailsDataSource = envDetails?.rows;
    this.itemCount = envDetails?.rows.length;
    
    this.cloudSolcolumns =
      this.assessment?.cloudSolutionDetails?.displayedColumns;
    if (this.cloudSolDetailsDataSource) {
      this.cloudSolDetailsDataSource.sort = this.sort;
      this.cloudSolDetailsDataSource.paginator = this.paginator;
    }
    this.lparLabeling = this.assessment?.cloudSolutionDetails.lparLabeling;
  }

  changeMatchedProduct(rowData: any) {
    this.cloudProductMatchingCriteria = new CloudProductMatchingCriteria();
    this.cloudProductMatchingCriteria.environment = rowData.environment;
    this.cloudProductMatchingCriteria.vmId = rowData.vmId;
    this.cloudProductMatchingCriteria.assessmentItemId = rowData.id;
    this.cloudProductMatchingCriteria.cloudProviderName =
      this.assessment.cloudProviderName;
    this.cloudProductMatchingCriteria.cloudProviderId =
      this.assessment.cloudProviderId;
    this.cloudProductMatchingCriteria.region = this.assessment.region;
    this.cloudProductMatchingCriteria.vCPU = rowData.vCPU;
    this.cloudProductMatchingCriteria.vmRam = rowData.vmRam;

    this.cloudProductMatchingRow = rowData;
    this.openMatchCloudproductDialog(this.cloudProductMatchingCriteria);
  }

  openMatchCloudproductDialog(rowData: CloudProductMatchingCriteria) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '90%';
    dialogConfig.width = '90%';

    dialogConfig.data = {
      rowData: rowData,
    };
    const dialogRef = this.dialog.open(
      CloudMigrationMatchCloudproductComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        console.log('return data from dialog: ', res);
        console.log(
          'this.cloudProductMatchingRow: ',
          this.cloudProductMatchingRow
        );
        this.cloudProductMatchingRow.instanceType = res.instanceType;
        this.cloudProductMatchingRow.osSoftware = res.osSoftware;
        this.cloudProductMatchingRow.category = res.category;
        this.cloudProductMatchingRow.ram = res.ram;
        this.cloudProductMatchingRow.vCPU = res.vCPU;
        this.cloudProductMatchingRow.pricePerHour = res.pricePerHour;
        this.cloudProductMatchingRow.annualPrice = res.annualPrice;
        this.cloudProductMatchingRow.cloudProductId = res.cloudProductId;
        this.cloudProductMatchingRow.cloudPricingId = res.cloudPricingId;
        
        this.priceUpdate(this.cloudProductMatchingRow);
      }
    });
  }
}
