import { Employee } from '../../shared/models/employee';
export class ServiceEstimate {
    phase: string;
    activity: string;
    resourceType: string;
    estimatiedHours: number;
    rate: number;
    serviceRole: string;
    serviceRate: number;
    serviceOverrideRate?: number;
    estimatedPrice: number;
    bpName: string;
    projectName: string;
}
export class ServiceEstimates {
    planningItems: Array<ServiceEstimate>;
    executingItems: Array<ServiceEstimate>;
    controllingItems: Array<ServiceEstimate>;
    closingItems: Array<ServiceEstimate>;
    otherItems: Array<ServiceEstimate>;
    businessItems: Array<ServiceEstimate>;
}
export class ServiceEstimateSummary {
    projectName: string;
    clientName: string;
    serviceEstimateDate: string;
    prevailingWage: number;
    accountManager: string;
    architect: string;
    srvNumber: string;
    estimates: ServiceEstimates;
    margin: number;
    totalMargin: number;
    totalCost: number;
    totalEstimatedPrice: number;
}
export class ServiceEstimateSummaryDataBaseItem {
    projectName: string;
    clientName: string;
    serviceEstimateDate: string;
    prevailingWage: number;
    accountManagerId: string;
    architectId: string;
    additionalArchitects: Array<string>;
    srvNumber: string;
    estimates: ServiceEstimates;
    margin: number;
    totalMargin: number;

    totalCost: number;
    totalEstimatedPrice: number;
    serviceEstimateDetails: Array<ServiceEstimateDetail>;
    nextApproverEmployeeId: string;
    status: number;
}
export class ServiceEstimateDetail {
    serviceEstimateDetailId: string;
    projectPhase: ProjectPhase;
    activity: string;
    resourceType: ResourceType;
    businessPartner: BusinessPartner;
    estimatedHours: number;
    estimatedPrice: number;
    actualRate: number;
    serviceRole: ServiceRole;
    overrideRate: number;
    multiplier: number;
    lineOfBusinessId: string;
}
export class BusinessPartner {
    businessPartnerId: string;
    name: string;
}
export enum ResourceType {
    MIT,
    BusinessPartner
}
export enum ProjectPhase {
    Plan = 0,
    Execute = 1,
    Close = 2
}
export enum PrevailingWages {
    NA,
    Federal,
    State
}
export class ServiceEstimateCreateViewData {
    serviceRoles: Array<ServiceRole>;
}
export class ServiceRole {

    name: string;
    rate: number;
}


