import { Routes, RouterModule } from '@angular/router';
import { ClientsComponent } from './clients.component';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { NgModule } from '@angular/core';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientUploadComponent } from './client-upload/client-upload.component';
import { ClientEditComponent } from './client-edit/client-edit.component';

export const routes: Routes = [
    {
    path: 'clients', component: ClientsComponent, data: {
      breadcrumb: 'Clients', isRoutable: true
    }, children: [
        {
          path: '', component: ClientsListComponent, data: {
            breadcrumb: null, isRoutable: false
          } },
        {
          path: 'create', component: ClientCreateComponent, data: {
            breadcrumb: 'Create Client', isRoutable: true
          } },
        {
          path: 'upload', component: ClientUploadComponent, data: {
            breadcrumb: 'Upload Client CSV', isRoutable: true
          } },
        {
          path: ':id/edit', component: ClientEditComponent, data: {
            breadcrumb: 'Edit Client', isRoutable: true
          } }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientsRoutingModule { }
