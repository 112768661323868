import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByStateId'
})
export class FilterByStateIdPipe implements PipeTransform {

  transform(stateList: Array<any>, stateIds: Array<any>): any {
    let _states: Array<any>;
    _states = [];
    stateList.forEach(s => {
      stateIds.forEach(sid => {
        if (s.stateId == sid.stateId) {
          _states.push(s);
        }
      });
    });
    return _states;
  }

}
