import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfessionalServicesService } from '../../professional-services.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { ProjectPhase } from '../../models/service-estimate';

@Component({
  selector: 'app-professional-services-estimate-summary',
  templateUrl: './professional-services-estimate-summary.component.html',
  styleUrls: ['./professional-services-estimate-summary.component.scss']
})
export class ProfessionalServicesEstimateSummaryComponent implements OnInit {
  secId: string;
  summaryForm: UntypedFormGroup;
  serviceEstimatePhaseDetails: UntypedFormArray;
  displayItems: boolean;
  displayPlanItems: boolean;
  displayCloseItems: boolean;
  displayExecuteItems: boolean;
  displayBPItems: boolean;
  selectionItems: any;
  serviceEstimate: any;
  currencies: Array<any>;
  billingType: string;
  selectedCurrency: any;
  displayExecuteTitle: string;
  displayCloseTitle: string;
  displayPlanTitle: string;
  displayBpTitle: string;
  currencyPrice: any;
  isScopeRiskEnabled: boolean;
  constructor(private route: ActivatedRoute, private serviceEstimateService: ProfessionalServicesService, private fb: UntypedFormBuilder, private _snackBar: MatSnackBar, private titleService: Title) { }

  ngOnInit() {

    this.titleService.setTitle('');
    this.serviceEstimatePhaseDetails = this.fb.array([]);
    this.displayItems = false;
    this.displayPlanItems = true;
    this.displayCloseItems = true;
    this.displayExecuteItems = true;
    this.displayBPItems = true;
    this.selectionItems = [{ name: 'Display Hours', isVisible: false }, { name: 'Display Billable Rate', isVisible: false }, { name: 'Display Sell Price', isVisible: false }];
    this.route.params
      .subscribe(res => {
        this.secId = res['serviceEstimateId'];
        this.getSummaryDetails();
        this.getCurrencies();
      });
  }
  getSummaryDetails() {
    this.serviceEstimateService.getServiceEstimateSummary(this.secId)
      .subscribe(res => {
        this.serviceEstimate = res.serviceEstimate;
        this.updateFormGroup(res);
        this.setPhaseItems();

      });
  }
  getCurrencies() {
    this.serviceEstimateService.getCurrencies()
      .subscribe((res: any) => {
        this.currencies = res;
      })
  }
  updateFormGroup(data: any) {
    console.log(data);
    switch (data.serviceEstimate.serviceEstimateType) {
      case 0:
        this.billingType = "Fixed Fee";
        break;
      case 1:
        this.billingType = "Time & Materials";
        break;
      case 2:
        this.billingType = "Misc";
        break;
    };
    this.getCurrencyPrice(data);
    this.isScopeRiskEnabled = this.serviceEstimate.serviceProject.isScopeRiskEnabled,
    this.summaryForm = this.fb.group({
      'id': [data.id ? data.id : null],
      'summaryDate': [data.summaryDate ? data.summaryDate : new Date()],
      'customer': [data.customer ? data.customer : null],
      'projectName': [data.projectName ? data.projectName : null],
      'oppurtunityNumber': [data.oppurtunityNumber ? data.oppurtunityNumber : null],
      'serviceEstimateType': [data.serviceEstimate.serviceEstimateType || data.serviceEstimate.serviceEstimateType >= 0 ? data.serviceEstimate.serviceEstimateType : null],
      'expirationDate': [data.expirationDate ? data.expirationDate : null],
      'professionalServices': [data.professionalServices ? data.professionalServices : null],
      'specialApplicationAndPremierServices': [data.specialApplicationAndPremierServices ? data.specialApplicationAndPremierServices : null],
      'projectManagement': [data.projectManagement ? data.projectManagement : null],
      'travelExpense': [data.travelExpense ? data.travelExpense : null],
      'totalBpCharges': [data.totalBpCharges ? data.totalBpCharges : null],
      'totalBpHours': [data.totalBpHours ? data.totalBpHours : null],
      'miscCharges': [data.miscCharges ? data.miscCharges : '000'],
      'installationSubTotal': [data.installationSubTotal ? data.installationSubTotal : null],
      'totalInstallationPrice': [data.totalInstallationPrice ? data.totalInstallationPrice + (data.serviceEstimate.serviceEstimatePriceAdjustments ? data.serviceEstimate.serviceEstimatePriceAdjustments : 0) : null],
      'serviceEstimatePriceAdjustments': [data.serviceEstimate.serviceEstimatePriceAdjustments ? data.serviceEstimate.serviceEstimatePriceAdjustments : null],
      'seComponentPriceAdjustment' : [data.serviceEstimate.priceAdjustments ? data.serviceEstimate.priceAdjustments : null],
      'professionalServicesNotes': [data.professionalServicesNotes ? data.professionalServicesNotes : null],
      'specialApplicationAndPremierServicesNotes': [data.specialApplicationAndPremierServicesNotes ? data.specialApplicationAndPremierServicesNotes : null],
      'projectManagementNotes': [data.projectManagementNotes ? data.projectManagementNotes : null],
      'travelExpenseNotes': [data.travelExpenseNotes ? data.travelExpenseNotes : null],
      'totalBpChargesNotes': [data.totalBpChargesNotes ? data.totalBpChargesNotes : null],
      'totalBpHoursNotes': [data.totalBpHoursNotes ? data.totalBpHoursNotes : null],
      'miscChargesNotes': [data.miscChargesNotes ? data.miscChargesNotes : null],
      'installationSubTotalNotes': [data.installationSubTotalNotes ? data.installationSubTotalNotes : null],
      'totalInstallationPriceNotes': [data.totalInstallationPrice ? data.totalInstallationPriceNotes : null],
      'serviceEstimatePriceAdjustmentsNote': [data.serviceEstimate.serviceEstimatePriceAdjustmentsNote ? data.serviceEstimate.serviceEstimatePriceAdjustmentsNote : null],
      'additonalNotes': [data.additonalNotes ? data.additonalNotes : null],
      'serviceEstimateId': [data.serviceEstimateId ? data.serviceEstimateId : this.secId],
      'serviceEstimatePhaseDetails': this.serviceEstimatePhaseDetails,
      'componentNotes': data.serviceEstimate.componentNotes ? data.serviceEstimate.componentNotes : null,
      'serviceEstimateCurrencies': [data.serviceEstimateCurrencies ? data.serviceEstimateCurrencies : null],
      'currencyPrice': [this.currencyPrice],
      'scopeRiskPercentage': [this.serviceEstimate.serviceProject.scopeRiskPercentage ? this.serviceEstimate.serviceProject.scopeRiskPercentage : null],
      'scopeRiskPrice': this.calculateScopeRiskPerct(this.serviceEstimate.estimatedPrice, this.serviceEstimate.serviceProject.scopeRiskPercentage),
      'hideProfessionalServices': true,
      'hideSpecialApplicationAndPremierServices': true,
      'hideProjectManagement': true,
      'hideMiscCharges': true,
      'hidetravelExpense': true,
      'hideTotalBpCharges': true,
      'hideTotalBpHours': true,
      'hideTotalInstallationPrice': true,
      'hideSEPriceAdjustments': true,
      'hideScopeRiskPrice': true,
      'hideScopeRiskPercentage': true,
      'hideSEComponentPriceAdjustments': true,
      'hideAdditonalNotes': true,
      'hideComponentNotes': true,
      'hideForeignCurrency': true
    });
  }
  checkComponents() {
    return this.serviceEstimate.serviceEstimateComponents.length > 0;
  }
  setPhaseItems() {
    if (this.checkComponents()) {
      let comp;
      let planItems: UntypedFormArray = this.fb.array([]);
      let executeItems: UntypedFormArray = this.fb.array([]); let businessPartnerItems: UntypedFormArray = this.fb.array([]);
      let closeItems: UntypedFormArray = this.fb.array([]);
      this.serviceEstimate.serviceEstimateComponents.forEach((item: any, index: any) => {
        if (item.isAttached) {
          item.phaseItems.forEach((itemx: any, index: any) => {
            if (itemx.quantity) {
              let phaseItem = this.fb.group({
                'activityName': itemx.activity,
                'hours': (itemx.quantity * itemx.multiplier).toFixed(2),
                'billrate': itemx.billedRate,
                'total': itemx.estimatiedPrice,
                'phase': itemx.phase,
                'isVisible': true
              });
              if (phaseItem.controls.phase.value == ProjectPhase.Plan)
                planItems.push(phaseItem);
              else if (phaseItem.controls.phase.value == ProjectPhase.Execute)
                executeItems.push(phaseItem);
              else
                closeItems.push(phaseItem);
            }
          });
          if (item.businessPartnerItems) {
            item.businessPartnerItems.forEach((itemy: any, index: any) => {
              let bpItem = this.fb.group({
                'activityName': itemy.activity,
                'hours': itemy.estimatedHours,
                'billrate': itemy.sell,
                'total': item.businessPartnerTotal,
                'isVisible': true
              });
              businessPartnerItems.push(bpItem);
            });
          }
        }
      });
      comp = this.fb.group({
        'bpItems': businessPartnerItems,
        'planItems': planItems,
        'executeItems': executeItems,
        'closeItems': closeItems
      });
      if (businessPartnerItems.controls.length || planItems.controls.length || executeItems.controls.length || closeItems.controls.length)
        this.serviceEstimatePhaseDetails.push(comp);
    }
  }
  updateSummary() {
    const summaryId: number = this.summaryForm.controls['id'].value;
    this.serviceEstimateService.updateServiceEstimateSummary(summaryId, this.summaryForm.value)
      .subscribe(res => {
        this.getSummaryDetails();
        this.openSnackBar('Summary updated successfully', 'dismiss', 5000);

      });
  }
  openSnackBar(message: string, action: string, duration = 2000) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  phaseItemsExists(compIndex: number, itemType: string) {
    return (<UntypedFormArray>(<UntypedFormArray>this.summaryForm.get('serviceEstimatePhaseDetails')).controls[compIndex].get(itemType)).controls.length > 0;
  }
  updateAll() {
    this.displayItems = this.selectionItems && this.selectionItems.every((t: any) => t.isVisible);
  }
  selectAll(isVisible: boolean) {
    this.displayItems = isVisible;
    this.selectionItems.forEach((t: any) => (t.isVisible = isVisible));
  }
  //isFixedFee() {
  //  return this.summaryForm.controls.serviceEstimateType.value == 0;
  //}
  isChecked(isChecked: any, phase: string, index: number) {
    let flag: boolean = this.summaryForm.get('serviceEstimatePhaseDetails')?.value[index][phase].every((t: any) => t.isVisible);
    switch (phase) {
      case 'planItems':
        this.displayPlanItems = flag;
        break;
      case 'executeItems':
        this.displayExecuteItems = flag;
        break;
      case 'closeItems':
        this.displayCloseItems = flag;
        break;
      case 'bpItems':
        this.displayBPItems = flag;
        break;
    }

  }
  selectAllItems(isVisible: boolean, phase: string) {
    switch (phase) {
      case 'planItems':
        this.displayPlanItems = isVisible;
        break;
      case 'executeItems':
        this.displayExecuteItems = isVisible;
        break;
      case 'closeItems':
        this.displayCloseItems = isVisible;
        break;
      case 'bpItems':
        this.displayBPItems = isVisible;
        break;
    }
    this.serviceEstimatePhaseDetails.value.forEach((t: any) => t[phase].forEach((item: any) => item.isVisible = isVisible));
    this.summaryForm.get('serviceEstimatePhaseDetails')?.setValue(this.serviceEstimatePhaseDetails.value);
  }
  print() {
    window.print();
  }
  getCurrenctCurrencyCode() {
    return this.selectedCurrency ? this.selectedCurrency.isoCode + " " + this.selectedCurrency.symbol : "";
  }
  getCurrencyPrice(data: any) {
    data.serviceEstimate.serviceEstimateCurrencies?.forEach((curr: any, index: number) => {
      this.selectedCurrency = curr.currencyConversionRate.currency;
      let convertedRate = curr.currencyConversionRate.conversionRate;
      let convertedCurrencyPrice = data.serviceEstimate.estimatedPrice * convertedRate;
      this.currencyPrice = convertedCurrencyPrice;
    });
  }
  calculateScopeRiskPerct(estimatedPrice: any, scopeRiskPerct: any) {
    return estimatedPrice && scopeRiskPerct ? scopeRiskPerct * (estimatedPrice / 100) : 0;
  }
}
