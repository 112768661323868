import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, FormGroup } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Tag } from '../../../tags/models/tag.model';
import { TagsService } from '../../../tags/tags.service';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

  @Input() actualTags: Array<Tag>;
  @Output() tagSelected: EventEmitter<Tag> = new EventEmitter<Tag>();
  filteredOptionTags: Observable<Array<Tag>>;
  @ViewChild('select')
  select: MatAutocompleteTrigger;
  @Input() isSelectOpened: boolean;
  tag: UntypedFormControl;
  @Input() tagItems: UntypedFormArray;

  constructor(private tagsService: TagsService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.tag = new UntypedFormControl();
    this.filteredOptionTags = this.tag.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  _filter(value: any): Tag[] {
    const filterValue = value ? value.toLowerCase() : "";
    return this.actualTags?.filter(x => x.name.toLowerCase().includes(filterValue)).sort((a, b) => { return a.name < b.name ? -1 : 1 });
  }

  selectedOtopn(event: MatAutocompleteSelectedEvent) {
    let tagIndex: number = this.actualTags.findIndex(tag => tag.id == event.option.id);
    this.tagSelected.emit(this.actualTags[tagIndex]);
    this.actualTags.splice(tagIndex, 1);
    this.setTagControlValue();
  }

  setTagControlValue() {
    this.tag.setValue(null);
  }

  insertRemovedTag(tag: any) {
    this.actualTags.push(tag);
  }

}
