import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ReleaseNote, ReleaseNotesAcknowledgement } from '../../admin/release-notes/models/releaseNotes';
import { AzureDevOpsWorkItem, AzureDevOpsWorkItemData } from '../models/iteration';
@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {
  constructor(private httpClient: HttpClient) { }

  isAcknowledged() {
    return this.httpClient.get<boolean>(`/api/releaseNotes/isReleaseNoteAcknowledged`);
  }

  acknowledge(releaseId: string) {
    var releaseNoteAck = {
      releaseNoteId: releaseId,
      isAcknowledged: true
    };
    const body = JSON.stringify(releaseNoteAck);
    return this.httpClient.post<ReleaseNotesAcknowledgement>(`/api/releaseNotes/acknowledgeReleaseNotes`, body);
  }

  getIterationWorkItems(iterationId: string) {
    return this.httpClient.get<Array<AzureDevOpsWorkItem> | null>(`/api/releaseNotes/IterationWorkItems/${iterationId}`);
  }

  getCurrentReleaseInfo() {
    return this.httpClient.get<ReleaseNote | null>(`/api/releaseNotes/CurrentRelease`);
  }

  getWorkItemDetails(workItemId: number) {
    return this.httpClient.get<any>(`/api/releaseNotes/WorkItemDetails/${workItemId}`);
  }

  getWorkItemsData(ids: number[]) {
    let requestData = {
      ids: ids
    }
    const body = JSON.stringify(requestData);
    return this.httpClient.post<Array<AzureDevOpsWorkItemData>>(`/api/releaseNotes/workItems`, body);
  }
}
