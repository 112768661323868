import { Component, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { PricingSummaryData } from '../../cloud-migration-assessment-detail/cloud-migration-assessment-detail.component';


@Component({
  selector: 'app-cloud-migration-pricing-summary',
  templateUrl: './cloud-migration-pricing-summary.component.html',
  styleUrls: ['./cloud-migration-pricing-summary.component.scss']
})
export class CloudMigrationPricingSummaryComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() pricingSummaryData: PricingSummaryData;
  @Input() pricingChangeEvent: EventEmitter<any>;
  @Input() managedServiceCostUpdated: boolean;
  @Input() migrationCostUpdated: boolean;
  @Input() platformCostUpdated: boolean;
  @Input() dataTransferCostUpdated: boolean;
  formUpdated: boolean;
  tableDataSource: any;
  @ViewChild('form') form: NgForm;
  displayedColumns: any[];
  defaultPricing: any = {};
  totalCostUpdated: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.displayedColumns = this.pricingSummaryData?.displayedColumns;
    this.setData();
  }

  ngOnChanges(){
    this.setData();
  }

  setData(){
    this.tableDataSource = this.pricingSummaryData?.pricingSummaryRowData;
  }

  isNumber(number: any) {
    number = Number(number);
    if(number){
      return true
    }
    return false
  }
}
