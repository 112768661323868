import { Component, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-simpplr-integration',
  templateUrl: './simpplr-integration.component.html',
  styleUrls: ['./simpplr-integration.component.scss']
})
export class SimpplrIntegrationComponent implements OnInit {
  private window: Window;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView as Window;
   }

  ngOnInit(): void {
    this.window.onmessage = function (e) {
      window.document.getElementById('iframeId')?.setAttribute('style', `height: ${e.data}px`);
    };
  }

}
