import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.scss']
})
export class RoleDetailsComponent implements OnInit {
  role: any;
  id: string;

  constructor(private adminService: AdminService, private location: Location, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        if (params['id']) {
          this.id = params['id'];
          this.adminService.getRole(this.id)
            .subscribe(httpResult => {
              this.role = httpResult;
            });
        }
      });
  }
  updateRole() {
    this.adminService.updateRole(this.role)
      .subscribe(httpResult => {

      });
  }

}
