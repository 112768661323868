import { Component, OnInit, ViewChild } from '@angular/core';
import { ReleaseNotesDataSource } from '../../models/release-notes.datasource';
import { ReleaseNotesService } from '../release-notes.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { forkJoin } from 'rxjs';
import { ReleaseNote } from '../models/releaseNotes';
import { AzureDevOpsIteration } from '../../../shared/models/iteration';
import { FilterByIterationIdPipe } from '../../../shared/pipes/filter-by-iteration-id.pipe';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SessionStorageService } from '../../../shared/services/session-storage.service';

@Component({
  selector: 'app-release-notes-list',
  templateUrl: './release-notes-list.component.html',
  styleUrls: ['./release-notes-list.component.scss']
})
export class ReleaseNotesListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  releaseNotesDataSource: ReleaseNotesDataSource;
  displayedColumns: string[];
  iterations: Array<AzureDevOpsIteration>;
  isLoading: boolean;
  filterForm: UntypedFormGroup;
  releaseNotes: any;
  sessionInfo: any;
  sessionStorageKey: string;

  constructor(private releaseNotesService: ReleaseNotesService,
    private dialog: MatDialog, private fb: UntypedFormBuilder, private sessionStorageService: SessionStorageService) { }

  ngOnInit() {
    this.isLoading = true;
    this.displayedColumns = [
      'iteration',
      'releaseDate',
      'version',
      'notes',
      'actions'
    ];
    this.filterForm = this.fb.group({
      iteration: [''],
      version: [''],
      startDate: [''],
      endDate: ['']
    });
    this.sessionStorageKey = 'releaseNotesData';
    let storageData = this.sessionStorageService.fetchStorageData(this.sessionStorageKey);
    if (storageData) {
      this.sessionInfo = this.sessionStorageService.parseStorageData(storageData);
    } else {
      this.sessionInfo = this.filterForm.value;
      this.sessionStorageService.setStorageData(this.sessionStorageKey, this.filterForm.value);
    }
    this.onFormValueChange();
    let releaseNotes = this.releaseNotesService.getReleaseNotes();
    let iterations = this.releaseNotesService.getIterations();
    forkJoin([releaseNotes, iterations])
      .subscribe(results => {
        this.releaseNotes = results[0];
        this.iterations = results[1].filter(x => x.attributes.timeFrame.toLowerCase() != 'future').sort((a, b) => {
          return this.compare(a.attributes.startDate, b.attributes.startDate, false)
        });
        this.setTableData(results[0]);
        this.updateForm();
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
  }

  onFormValueChange() {
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        const data = this.releaseNotesDataSource.filter(res, this.releaseNotes);
        this.sessionInfo = this.filterForm.value;
        this.sessionStorageService.setStorageData(this.sessionStorageKey, this.sessionInfo);
        this.setTableData(data);
      });
  }

  setTableData(data: Array<ReleaseNote>) {
    this.releaseNotesDataSource = new ReleaseNotesDataSource(data, this.iterations, new FilterByIterationIdPipe);
    this.releaseNotesDataSource.sort = this.sort;
    this.releaseNotesDataSource.paginator = this.paginator;
  }

  getReleaseNotes() {
    this.releaseNotesService.getReleaseNotes()
      .subscribe(result => {
        this.releaseNotes = result;
        this.setTableData(result);
        this.updateForm();
      });
  }

  deleteRecord(releaseNoteId: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.releaseNotesService.deleteReleaseNote(releaseNoteId)
            .subscribe((res: any) => {
              this.getReleaseNotes();
            });
        }
      });
  }

  compare(a: string | number, b: string | number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  resetFilters() {
    this.filterForm.controls['iteration'].setValue('');
    this.filterForm.controls['version'].setValue('');
    this.filterForm.controls['startDate'].setValue('');
    this.filterForm.controls['endDate'].setValue('');
  }

  updateForm() {
    this.filterForm.patchValue({
      version: this.sessionInfo['version'] ? this.sessionInfo['version'] : '',
      startDate: this.sessionInfo['startDate'] ? this.sessionInfo['startDate'] : '',
      endDate: this.sessionInfo['endDate'] ? this.sessionInfo['endDate'] : '',
      iteration: this.sessionInfo['iteration'] == null || this.sessionInfo['iteration'].toString() == "" ? '' : this.sessionInfo['iteration']
    });
  }
}
