import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { BusinessPartnerService } from '../../business-partner.service';
import { SolutionPilar } from '../../models/solution-pilar';
import { Location } from '@angular/common';
import { checkMaxMinValidator } from '../solution-pilar-edit/solution-pilar-edit.component';

@Component({
  selector: 'app-solution-pilar-create',
  templateUrl: './solution-pilar-create.component.html',
  styleUrls: ['./solution-pilar-create.component.scss']
})
export class SolutionPilarCreateComponent implements OnInit {
  solutionPilarFormGroup: UntypedFormGroup;
  solutionPilarRatesArray: UntypedFormArray;
  isUniqueName: boolean;
  constructor(private fb: UntypedFormBuilder, private businessService: BusinessPartnerService, private location: Location) { }

  ngOnInit() {
    this.isUniqueName = true;
    this.solutionPilarRatesArray = this.fb.array([]);
    this.solutionPilarFormGroup = this.fb.group({
      'solutionPilarName': ['', Validators.required],
      'solutionPilarTag': ['', Validators.required],
      'solutionPilarRates': this.solutionPilarRatesArray
    });

  }

  addSolutionPilarRate(solutionPilarRate?: any) {
    this.solutionPilarRatesArray.push(this.buildSolutionPilarRate());
  }
  buildSolutionPilarRate(solutionPilarRate?: any) {
    let group: UntypedFormGroup;
    if (solutionPilarRate) {
      group = this.fb.group({
        'id': [solutionPilarRate.id ? solutionPilarRate.id : null],
        'solutionPilarId': [solutionPilarRate.solutionPilarId ? solutionPilarRate.solutionPilarId : null],
        'projectManagementPercentage': [solutionPilarRate.projectManagementPercentage ? solutionPilarRate.projectManagementPercentage : null, Validators.required],
        'maxCharge': [solutionPilarRate.maxCharge ? solutionPilarRate.maxCharge : null, Validators.required],
        'minimumCharge': [solutionPilarRate.minimumCharge ? solutionPilarRate.minimumCharge : null, Validators.required],
        'baseHourlyRate': [solutionPilarRate.baseHourlyRate ? solutionPilarRate.baseHourlyRate : null, Validators.required]
      }, { validator: checkMaxMinValidator });
    } else {
      solutionPilarRate = {};
      group = this.fb.group({
        'solutionPilarId': [],
        'projectManagementPercentage': [null, Validators.required],
        'maxCharge': [null, Validators.required],
        'minimumCharge': [null, Validators.required],
        'baseHourlyRate': [null, Validators.required]
      }, { validator: checkMaxMinValidator });
    }
    return group;
  }
  removeSolutionPilarRate(index: any) {
    this.solutionPilarRatesArray.removeAt(index);
  }
  onSubmit() {
    if (this.solutionPilarFormGroup.valid) {
      this.businessService.createSolutionPilars(this.solutionPilarFormGroup.value)
        .subscribe(res => {
          this.location.back();
        }, error => {
          if (error.status == 409) {
            this.isUniqueName = false;
          }
        });

    }
  }

}
