<mat-card>
  <form [formGroup]="managerForm" (ngSubmit)="saveManager()">
    <mat-card-content>
      <mat-form-field>
        <mat-select formControlName="empId" placeholder="Please select a Employee" disableOptionCentering>
          <mat-option *ngFor="let employee of employees" [value]="employee.employeeId"> {{employee.firstName + ' ' + employee.lastName}} </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button type="submit" mat-raised-button>Save</button>
    </mat-card-actions>
  </form>
</mat-card>
