<mat-card>
  <mat-card-content>
    <form [formGroup]="vehicleForm" (ngSubmit)="onSubmit()">
      <div *ngIf="model">
        <div *ngIf="gasCards">
          <div *ngIf="valvoCards">
            <div *ngIf="vehicleModels">
              <div class="col-md-12">
                <mat-form-field>
                  <input type="text" id="vNum" matInput placeholder="Vehicle Number" [(ngModel)]="model.vehicleNumber"
                    formControlName="vehicleNumber" />
                  <mat-error *ngIf="vehicleForm.controls['vehicleNumber'].hasError('required')">A vehicle number is
                    required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input type="text" id="vinNum" matInput placeholder="Vin" [(ngModel)]="model.vin"
                    formControlName="vin" />
                  <mat-error *ngIf="vehicleForm.controls['vin'].hasError('required')">A vin is required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input type="text" id="vColor" matInput placeholder="Color" [(ngModel)]="model.vehicleColor"
                    formControlName="vehicleColor" />
                </mat-form-field>
                <mat-form-field>
                  <input type="text" id="vehEzPass" matInput [maxLength] = "9" placeholder="EZ Pass" [(ngModel)]="model.vehicleEzPass"
                    formControlName="vehicleEzPass" />
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field>
                  <mat-select [(ngModel)]="model.assignedEmployeeId" formControlName="vehicleAssignedToEmployeeId"
                    placeholder="Assigned Driver" disableOptionCentering>
                    <mat-option *ngFor="let employee of employees" [value]="employee.vehicleDriverId">
                      {{employee.firstName}} {{employee.lastName}} </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-select placeholder="Model" [(ngModel)]="model.vehicleModelId" formControlName="vehicleModelId"
                    disableOptionCentering>
                    <mat-option *ngFor="let model of vehicleModels" [value]="model.vehicleModelId">
                      {{ model.vehcileModelYear + " " + model.manufacturerName + " " + model.vehicleModelName }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="vehicleForm.controls['vehicleModelId'].hasError('required')">A vehicle model is
                    required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input type="text" id="gasCard" matInput [maxLength] = "4" placeholder="Gas Card"
                    [(ngModel)]="gasCards.VehicleCardNumber" formControlName="vehicleGasCardNumber" />
                </mat-form-field>
                <mat-form-field>
                  <input type="text" id="valvoCard" matInput [maxLength] = "6" placeholder="Valvoline Card"
                    [(ngModel)]="valvoCards.VehicleCardNumber"
                    formControlName="vehicleValvolineCardNumber" />
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field>
                  <mat-select #stateInp id="vState" matInput placeholder="State"
                    [(ngModel)]="model.vehicleRegionStateId" formControlName="vehicleRegionStateId"
                    disableOptionCentering>
                    <mat-option *ngFor="let state of states" [value]="state.stateId">
                      {{state.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <input type="number" id="mile" matInput placeholder="Mileage"
                    [(ngModel)]="model.vehicleMileageCurrent" formControlName="vehicleMileageCurrent" />
                  <mat-error *ngIf="vehicleForm.controls['vehicleMileageCurrent'].hasError('required')">The current
                    milage is required</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input type="text" id="wrPrompId" matInput [maxLength] = "4" placeholder="Wright Prompt Id"
                    [(ngModel)]="model.vehicleWrightPromptId" formControlName="vehicleWrightPromptId" />
                </mat-form-field>
                <mat-form-field>
                  <input type="text" id="valvoPin" matInput [maxLength] = "4" placeholder="Valvoline Pin"
                    [(ngModel)]="valvoCards.ValvolinePin"
                    formControlName="vehicleValvolineCardPinNumber" />
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field>
                  <mat-select id="vCity" formControlName="vehicleRegionCityId" placeholder="City"
                    [(ngModel)]="model.vehicleRegionCityId" disableOptionCentering>
                    <mat-option *ngFor="let city of cities" [value]="city.cityId">
                      {{city.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <input type="text" id="vehiclePin" matInput placeholder="PIN" [maxLength] = "4" [(ngModel)]="model.vehiclePin"
                    formControlName="vehiclePin" />
                </mat-form-field>
                <mat-form-field>
                  <input type="text" id="aaaMembership" matInput [maxLength] = "10" placeholder="AAA Membership"
                    [(ngModel)]="model.vehicleAAAMembership" formControlName="vehicleAAAMembership" />
                </mat-form-field>
              </div>
              <h2>Registration</h2>
              <div class="col-md-4">
                <mat-form-field>
                  <input matInput id="date" type="text" [matDatepicker]="registrationExpirationDate"
                    placeholder="Expiration Date" [(ngModel)]="model.vehicleRegistration.expirationDate"
                    formControlName="expirationDate" />
                  <mat-datepicker-toggle matSuffix [for]="registrationExpirationDate"></mat-datepicker-toggle>
                  <mat-datepicker #registrationExpirationDate></mat-datepicker>
                  <mat-error *ngIf="vehicleForm.controls['expirationDate'].hasError('required')">A Expiration Date is
                    required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <input type="text" id="plateNum" matInput [maxLength] = "15" placeholder="License Plate Number"
                    [(ngModel)]="model.vehicleRegistration.licensePlateNumber" formControlName="licensePlateNumber" />
                  <mat-error *ngIf="vehicleForm.controls['licensePlateNumber'].hasError('required')">A license plate
                    number is required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <input type="text" id="plateState" matInput placeholder="License Plate State"
                    [(ngModel)]="model.vehicleRegistration.licensePlateState" formControlName="licensePlateState" />
                  <mat-hint>I.E. AL, Mi</mat-hint>
                  <mat-error *ngIf="vehicleForm.controls['licensePlateState'].hasError('required')">A state is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="col-md-4">
        <button mat-raised-button [disabled]="!vehicleForm.valid">Save</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>