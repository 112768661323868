import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { NetworkSummaryData } from '../../cloud-migration-assessment-detail/cloud-migration-assessment-detail.component';

@Component({
  selector: 'app-cloud-migration-network-summary',
  templateUrl: './cloud-migration-network-summary.component.html',
  styleUrls: ['./cloud-migration-network-summary.component.scss']
})
export class CloudMigrationNetworkSummaryComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  tableDataSource: any;
  @Input() networkSummaryData: NetworkSummaryData;
  @Input() networkRequiredBandwitdhUpdated: boolean;

  displayedColumns: string[];
  constructor() { }

  ngOnInit(): void {
    this.setData();
  }

  ngOnChanges(){
    this.setData();
  }

  setData() {
    this.tableDataSource = this.networkSummaryData?.networkSummaryRows;
    this.displayedColumns = this.networkSummaryData?.displayedColumns;
  }

}
