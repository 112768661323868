import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { ComponentCanDeactivate } from './component-can-deactivate';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
    canDeactivate(component: ComponentCanDeactivate): boolean {

        if (!component.canDeactivate()) {
            if (confirm('You have unsaved data. Are you sure you wish to leave this page?')) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}
