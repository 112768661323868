<mat-card>
  <mat-card-content>
    <a id="thresholdCreate" mat-raised-button [routerLink]="['create']" color="primary"
    matTooltip="Create Threshold" [matTooltipPosition]="'right'">
      <mat-icon class="add-icon">add</mat-icon>
      Threshold
    </a>
    <mat-table #table [dataSource]="threshHolds">
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span> {{element.amount | currency:'USD':'symbol'}}</span>

        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="employee">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span> {{element.employee.firstName + " " + element.employee.lastName}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let element" id="kebabMenu">
          <ng-container>
            <button mat-icon-button [matMenuTriggerFor]="menu" [attr.data-id]="element.id">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['edit', element.id]">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteThreshHold(element.id)">
                <mat-icon>delete_outline</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
