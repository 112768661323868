<mat-card>
  <mat-card-content [class.hide-content]="!solutionPilars">
    <h2 class="col-md-12" *ngIf="!canModify">
      <span>
        <mat-hint id="solPilarHint">
          Alert: Please Contact PortalAdmin/Director for Updating Solution Pillar Information
        </mat-hint>
      </span>
    </h2>
    <a id="createSolutionPillar" mat-raised-button [routerLink]="['create']" color="primary"
       matTooltip="Create Solution Pillar" [matTooltipPosition]="'right'">
      <mat-icon class="add-icon">add</mat-icon>
      Solution Pilllar
    </a>
    <br />
    <div class="col-md-12">
      <fieldset>
        <legend>
          Search Selection
        </legend>
        <form [formGroup]="filterForm">
          <mat-form-field>
            <input matInput type="text" placeholder="Filter by Name" id="solPilarName" formControlName="solPilarName" />
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" placeholder="Filter by Tag" id="solPilarTag" formControlName="solPilarTag" />
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Filter by Status" id="solPilarStatus" formControlName="solPilarStatus" disableOptionCentering>
              <mat-option [value]="0">Active</mat-option>
              <mat-option [value]="1">Inactive</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters" id="resetBtn">
            <mat-icon>clear</mat-icon>
          </button>
        </form>
      </fieldset>
    </div>
    <br />
    <div class="col-md-12">
      <mat-table #table [dataSource]="solutionPilarDataSource" class="apply-border">

        <!-- Weight Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.solutionPilarName}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="tag">
          <mat-header-cell *matHeaderCellDef> Tag </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.solutionPilarTag}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button id="kebabMenu" [attr.data-id]="element.solutionPilarId" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item color="primary" type="button" [routerLink]="['edit',element.solutionPilarId]">
                <mat-icon>edit</mat-icon>
                <span>Details/Edit</span>
              </button>
              <button mat-menu-item color="warn" type="button" (click)="deleteSolutionPilar(element.solutionPilarId)">
                <mat-icon>delete_outline</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: getDisplayedColumns();"></mat-row>
      </mat-table>
      <div *ngIf="!(solutionPilarDataSource && solutionPilarDataSource.data.length > 0)" class="no-records">
        No records found
      </div>
    </div>
  </mat-card-content>
</mat-card>
