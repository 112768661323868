import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { FleetMangementService } from './../fleet-mangement.service';


@Component({
  selector: 'app-usage-record-update',
  templateUrl: './usage-record-update.component.html',
  styleUrls: ['./usage-record-update.component.scss']
})
export class UsageRecordUpdateComponent implements OnInit {
  model: any;
  currentMileage: number;
  vehicleName: string;
  constructor(private service: FleetMangementService, private route: ActivatedRoute, private location: Location) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        const id = params['id'];
        this.service.getVehicleUsage(id)
          .subscribe(res => {
            this.service.getVehicle(res.vehicleId)
              .subscribe(res2 => {
                this.currentMileage = res2.vehicleMileageCurrent;
                this.vehicleName = res2.vehicleNumber;
                this.model = res;
              });
          });
      });
  }
  updateMilage() {
    this.service.updateVehicleUsages(this.model)
      .subscribe(res => {
        this.location.back();
      });
  }

}
