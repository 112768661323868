import { Vehicle } from './fleet-management-models';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
export class VehicleDataSource extends DataSource<any> {
    data: Array<Vehicle>;
    connect(): Observable<Vehicle[]> {

        return of(this.data);
    }
    disconnect(collectionViewer: CollectionViewer): void {

    }
    setData(data: Array<any> | null | undefined) {
      if (data) {
        this.data = data;
      } else {
        this.data = [];
      }
    }
}
