import { Component, OnInit } from '@angular/core';
import { LineOfBusinessDataSource } from '../../models/line-of-business.datasource';

@Component({
  selector: 'app-line-of-business-list',
  templateUrl: './line-of-business-list.component.html',
  styleUrls: ['./line-of-business-list.component.scss']
})
export class LineOfBusinessListComponent implements OnInit {
  lineOfBusinesses: LineOfBusinessDataSource;
  displayedColumns: Array<string>;
  constructor() { }

  ngOnInit() {
  }
  deleteLineOfBusiness(id: string) {

  }

}
