<mat-card>
  <mat-card-header>
    <h2>Support Request</h2>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-select [(ngModel)]="formType" placeholder="Select a request type" disableOptionCentering>
        <mat-option [value]="0">GSC Incident Report</mat-option>
        <mat-option [value]="1">Portal Support Request</mat-option>
      </mat-select>
    </mat-form-field>
    <hr />
    <app-incident-report-form *ngIf="formType === 0"></app-incident-report-form>
    <app-support-request-form *ngIf="formType === 1"></app-support-request-form>
  </mat-card-content>
</mat-card>
