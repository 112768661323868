<mat-card *ngIf="projectControlRateFormGroup">
  <form [formGroup]="projectControlRateFormGroup" (ngSubmit)="submitForm()" >
    <mat-card-content>
      <mat-form-field>
        <input matInput type="text" placeholder="Name" formControlName="projectControlRateName" />
        <mat-error *ngIf="projectControlRateFormGroup.controls['projectControlRateName'].hasError('required')">A name is required.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" placeholder="Description" formControlName="projectControlRateDescription" />
        <mat-error *ngIf="projectControlRateFormGroup.controls['projectControlRateDescription'].hasError('required')">A description is required.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number" placeholder="Minimum Hours" formControlName="projectControlRateMinHours" />
        <mat-error *ngIf="projectControlRateFormGroup.controls['projectControlRateMinHours'].hasError('required')">Minimum hours are required.</mat-error>
        <mat-error *ngIf="projectControlRateFormGroup.controls['projectControlRateMinHours'].hasError('min')">The minimum hours cannot be less than 1.</mat-error>
        <mat-error *ngIf="!projectControlRateFormGroup.controls['projectControlRateMinHours'].hasError('min') && projectControlRateFormGroup.controls['projectControlRateMinHours'].hasError('pattern')">Only integer numbers are allowed.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number" placeholder="Maximum hours" formControlName="projectControlRateMaxHours" />
        <mat-error *ngIf="projectControlRateFormGroup.controls['projectControlRateMaxHours'].hasError('required')">Maximum hours are required.</mat-error>
        <mat-error *ngIf="projectControlRateFormGroup.controls['projectControlRateMaxHours'].hasError('min')">The maximum hours cannot be less than 1 and must be greater than the minimum hours.</mat-error>
        <mat-error *ngIf="!projectControlRateFormGroup.controls['projectControlRateMaxHours'].hasError('min') && projectControlRateFormGroup.controls['projectControlRateMaxHours'].hasError('pattern')">Only integer numbers are allowed.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="number" placeholder="Percentage" formControlName="projectControlRatePercentage" />
        <mat-error *ngIf="projectControlRateFormGroup.controls['projectControlRatePercentage'].hasError('required')">A percentage is required.</mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" [disabled]="isSubmitDisabled">Update</button>
    </mat-card-actions>
  </form>
</mat-card>
