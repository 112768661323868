import { Pipe, PipeTransform } from '@angular/core';
import { ProjectPhase } from '../../../professional-services/models/service-estimate';
import { FormArray, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'serviceEstimatiorItemFilter',
  pure: false
})
export class ServiceEstimatiorItemFilterPipe implements PipeTransform {

  transform(items: UntypedFormGroup[]) {
    if (!items) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    if (Array.isArray(items)) {
      return items.filter(fg => fg.controls['projectPhase'].value === ProjectPhase.Plan);
    }
    return items;

  }

}
