import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-mileage-confirmation-popup',
  templateUrl: './mileage-confirmation-popup.component.html',
  styleUrls: ['./mileage-confirmation-popup.component.scss']
})
export class MileageConfirmationPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: number) { }

  ngOnInit() {
  }

}
