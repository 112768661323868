import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { DocumentCreatorRoutingModule } from './document-creator-routing.module';
import { DocumentCreatorComponent } from './document-creator.component';
//import { CKEditorModule } from 'ngx-ckeditor';
import { DocumentCreatorService } from './document-creator.service';
import { DocumentCreatorListComponent } from './document-creator-list/document-creator-list.component';
import { DocumentCreatorCreateComponent } from './document-creator-create/document-creator-create.component';
import { DocumentCreatorDetailsComponent } from './document-creator-details/document-creator-details.component';

@NgModule({
  imports: [
    CommonModule,
    DocumentCreatorRoutingModule,
    SharedModule,
   // CKEditorModule
  ],
  declarations: [
    DocumentCreatorComponent,
    DocumentCreatorListComponent,
    DocumentCreatorCreateComponent,
    DocumentCreatorDetailsComponent
  ],
  providers: [DocumentCreatorService]
})
export class DocumentCreatorModule { }
