import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ResourceItemService {

  constructor(private http: HttpClient) { }

  deleteResouirceItems(id: any) {
    return this.http.delete(`/api/ServiceEstimateResources/${id}`);
  }

}
