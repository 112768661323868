    <mat-table mat-table matSort class="full-width-table" aria-label="Contract Lines" [dataSource]="dataSource" >
      <ng-container  matColumnDef="lineNumber">
        <mat-header-cell style="width: 10%;" *matHeaderCellDef mat-sort-header>Line</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.lineNumber}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="partNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Part</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.partNumber}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.quantity}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="retailPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header>MSRP</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.retailPrice | currency}}</mat-cell>
      </ng-container>
	  
      <ng-container matColumnDef="unitPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Unit Price</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.unitPrice | currency}}</mat-cell>
      </ng-container>
	  
      <ng-container matColumnDef="extendedSalesPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Extended</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.extendedSalesPrice| currency}}</mat-cell>
      </ng-container>
	  
      <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
