import { Component, OnInit } from '@angular/core';
import { Video } from './video/video.model';
import { VideosService } from './videos.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  isLoading: boolean;
  breakpoint: number;
  videos: Video[];
  constructor(private videoService: VideosService) { }

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 450) ? 1 : 3;
    this.getVideos();
  }

  onResize(event: any) {
    this.breakpoint = (event.target.innerWidth <= 450) ? 1 : 3;
  }

  getVideos() {
    this.isLoading = true;
    this.videoService.getVideos().subscribe(res => {
      this.videos = res;
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });

  }

}
