import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { Observable, Subscription } from 'rxjs';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import { CloudProductDataSource, CloudProductMatchingCriteria } from '../cloud-migration-match-cloudproduct/cloud-migration-match-cloudproduct.component';

@Component({
  selector: 'app-cloud-migration-tool-product-table',
  templateUrl: './cloud-migration-tool-product-table.component.html',
  styleUrls: ['./cloud-migration-tool-product-table.component.scss']
})
export class CloudMigrationToolProductTableComponent implements OnInit {
  @Input() matchingCriteria: CloudProductMatchingCriteria;
  @Input() events: Observable<CloudProductMatchingCriteria>;
  @Input() selectedProduct: (args: any) => void;
  @ViewChild(MatTable) table: MatTable<any>;
  private eventsSubscription: Subscription;
  cloudProductTableDataSource: CloudProductDataSource;
  displayedColumns = [
    'instanceType', 'osSoftware', 'category', 'vCPU', 'ram', 'pricePerHour', 'pricePerMonth', 'annualPrice'];

  constructor(private service: CloudMigrationToolService) { }

  ngOnInit(): void {
    this.eventsSubscription = this.events.subscribe((data) => this.getCloudProducts(data));
    this.getCloudProducts(this.matchingCriteria);
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  getCloudProducts(data: CloudProductMatchingCriteria) {
    this.service.getMatchingCloudProducts(data).subscribe(res => {
      this.cloudProductTableDataSource = new CloudProductDataSource(res);
      this.table.dataSource = this.cloudProductTableDataSource;
    }, (error) => {
    });
  }

  productSelected(row: any){
    this.selectedProduct(row);
  }

}
