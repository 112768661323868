<mat-card>
  <mat-card-title>Employees</mat-card-title>
  <mat-card-content>
    <div class="mat-elevation-z8">
      <div [class.loading]="!loading" style="position: relative; margin:auto;width:calc(50% + 225px)">
        <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
      </div>
      <mat-card [class.loading]="loading">
        <a id="employeeCreate" mat-raised-button [routerLink]="['create']" color="primary" matTooltip="Create Employee"
          [matTooltipPosition]="'right'">
          <mat-icon class="add-icon">add</mat-icon>
          Employee
        </a>
        <mat-card-content>
          <form [formGroup]="filterForm">
            <mat-form-field>
              <mat-label> First Name Filter</mat-label>
              <input matInput formControlName="firstNameFilter" type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Last Name Filter</mat-label>
              <input matInput formControlName="lastNameFilter" type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Email Filter</mat-label>
              <input matInput formControlName="emailFilter" type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Employee Status Filter</mat-label>
              <mat-select placeholder="Filter by Employee Status" formControlName="employeeStatusFilter"
                disableOptionCentering>
                <mat-option [value]="1">Active</mat-option>
                <mat-option [value]="0">Inactive</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Application Role Filter</mat-label>
              <mat-select placeholder="Application Role Filter" id="appRoleFilter" formControlName="appRoleFilter"
                disableOptionCentering>
                <mat-option *ngFor="let role of appRoles" [value]="role.id">
                  {{role.displayName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters">
              <mat-icon>clear</mat-icon>
            </button>
          </form>
          <mat-table mat-table matSort class="full-width-table" aria-label="Employee Table" [class.loading]="loading">
            <ng-container matColumnDef="firstName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>First Name</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.firstName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.lastName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="appRoleId">
              <mat-header-cell *matHeaderCellDef>Application Role</mat-header-cell>
              <mat-cell *matCellDef="let row">{{getAppRoles(row.appRoles)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.email}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.softDelete? 'Active' : 'In Active'}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <button id="kebabMenu" [attr.data-id]="row.principalId" mat-icon-button [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item color="default" type="button" [routerLink]="['details',row.principalId]">
                    <mat-icon>edit</mat-icon>
                    <span>Details</span>
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <div *ngIf="!(userListDataSource && userListDataSource.data.length > 0)" class="no-records">
            No records found
          </div>
          <mat-paginator [class.loading]="loading" #paginator [length]="userListDataSource?.data.length" [pageIndex]="0"
            [pageSize]="50" [pageSizeOptions]="[5, 25, 50, 100]">
          </mat-paginator>
        </mat-card-content>
      </mat-card>


    </div>
  </mat-card-content>
</mat-card>
