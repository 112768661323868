import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByActiveRecords'
})
export class FilterByActiveRecordsPipe implements PipeTransform {

  transform(unfilteredRecords: any): any {
    return [...new Set(unfilteredRecords.filter((rec: any) => rec.projectControlRate.softDelete == false))];
  }

}
