<mat-card>
  <mat-card-content>
    <mat-form-field>
      <mat-select placeholder="Select a Report" #reportsSelect disableOptionCentering [formControl]="reportOption">
        <mat-option>None</mat-option>
        <mat-option [value]="0">Approved Service Estimates</mat-option>
      </mat-select>
    </mat-form-field>
    <app-approved-service-estimates-report *ngIf="reportsSelect.value == 0"></app-approved-service-estimates-report>
  </mat-card-content>
</mat-card>
