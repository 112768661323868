import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CloudMigrationToolAdminService } from '../../cloud-migration-tool-admin.service';

@Component({
  selector: 'app-cloud-provider-edit',
  templateUrl: './cloud-provider-edit.component.html',
  styleUrls: ['./cloud-provider-edit.component.scss']
})
export class CloudProviderEditComponent implements OnInit {
  cloudProviderForm: UntypedFormGroup;
  formTitle: string;
  cloudProvider: any;
  cloudProviderId: any;

  constructor( private service: CloudMigrationToolAdminService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CloudProviderEditComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    this.formTitle = 'Cloud Provider';
    this.cloudProviderId = this.data.id;
    this.loadCloudProvider();
  }
  
  createForm() {
    this.cloudProviderForm = this.fb.group({
      id: [this.cloudProvider?.id],
      name: [this.cloudProvider?.name],
      vpnRate: [parseFloat(this.cloudProvider.vpnRate).toFixed(2)],
      bandwidthRate: [parseFloat(this.cloudProvider.bandwidthRate).toFixed(2)],
    });
  }

  loadCloudProvider() {
    this.service.getCloudProvider(this.cloudProviderId)
      .subscribe(res => {
        const data = res;
        this.cloudProvider = data;
        this.createForm();
      });
  }
  close() {

    this.dialogRef.close(this.cloudProviderForm?.value);
  }

  save() {
    this.cloudProvider = this.cloudProviderForm.value;
    let request = {
      cloudProviderId: this.cloudProviderId,
      payload: this.cloudProvider,
    };
    this.service.saveCloudProvider(request).then((res: any) => {
      this.cloudProvider = res.data.result;

    });
  }
}
