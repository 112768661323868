import { Component, OnInit, ViewChild } from '@angular/core';
import { TempApproverService } from '../temp-approver.service';
import { AdminService } from '../../admin.service';
import { Employee } from '../../../employee/employee-list/employee-list-datasource';
import { ConfirmDeleteDialogComponent } from 'src/app/shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TemporaryApproverDataSource } from '../../models/temporary-approver.datasource';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { SessionStorageService } from '../../../shared/services/session-storage.service';

@Component({
  selector: 'app-temp-approver-list',
  templateUrl: './temp-approver-list.component.html',
  styleUrls: ['./temp-approver-list.component.scss']
})
export class TempApproverListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  tempApproverDataSource: TemporaryApproverDataSource;
  tempApprovers: any;
  displayedColumns: string[];
  employees: Employee[];
  filterForm: UntypedFormGroup;
  sessionInfo: any;
  sessionStorageKey: string;


  constructor(private tempApproverService: TempApproverService, private adminService: AdminService, private dialog: MatDialog,
    private fb: UntypedFormBuilder, private sessionStorageService: SessionStorageService) { }

  ngOnInit() {
    this.displayedColumns = [
      'temporaryApproverEmployeeId',
      'startDate',
      'endDate',
      'approverForEmployeeId',
      'createdBy',
      'actions'
    ];
    this.filterForm = this.fb.group({
      tempApprover: [''],
      backupEmployee: [''],
      createdBy: ['']
    });
    this.sessionStorageKey = 'tempApproversData';
    let storageData = this.sessionStorageService.fetchStorageData(this.sessionStorageKey);
    if (storageData) {
      this.sessionInfo = this.sessionStorageService.parseStorageData(storageData);
    } else {
      this.sessionInfo = this.filterForm.value;
      this.sessionStorageService.setStorageData(this.sessionStorageKey, this.filterForm.value);
    }
    this.onFormValueChange();
    this.getTemporaryApprovers();
    this.getEmployees();
  }

  onFormValueChange() {
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.tempApproverDataSource = new TemporaryApproverDataSource(this.tempApproverDataSource.filter(res, this.tempApprovers));
        this.sessionInfo = this.filterForm.value;
        this.sessionStorageService.setStorageData(this.sessionStorageKey, this.sessionInfo);
        this.setTempApproverDataSource(this.tempApproverDataSource.data);
      });
  }

  getEmployees() {
    this.adminService.getAllEmployees()
      .subscribe(httpResult => {
        this.employees = httpResult;
      });
  }
  deleteTemporaryApprover(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.tempApproverService.deleteTemporaryApprover(id)
            .subscribe(res => {
              this.getTemporaryApprovers();
            });
        }
      });
  }
  getTemporaryApprovers() {
    this.tempApproverService.getTemporaryApprovers()
      .subscribe(res => {
        this.tempApprovers = res.filter(items => items.softDelete == false);
        this.setTempApproverDataSource(res);
        this.updateForm();

      });
  }
  setTempApproverDataSource(data: any) {
    this.tempApproverDataSource = new TemporaryApproverDataSource(data);
    this.tempApproverDataSource.paginator = this.paginator;
  }

  resetFilters() {
    this.filterForm.controls['tempApprover'].setValue('');
    this.filterForm.controls['backupEmployee'].setValue('');
    this.filterForm.controls['createdBy'].setValue('');
  }

  updateForm() {
    this.filterForm.patchValue({
      tempApprover: this.sessionInfo['tempApprover'] == null || this.sessionInfo['tempApprover'].toString() == "" ? '' : this.sessionInfo['tempApprover'],
      backupEmployee: this.sessionInfo['backupEmployee'] == null || this.sessionInfo['backupEmployee'].toString() == "" ? '' : this.sessionInfo['backupEmployee'],
      createdBy: this.sessionInfo['createdBy'] == null || this.sessionInfo['createdBy'].toString() == "" ? '' : this.sessionInfo['createdBy']
    });
  }

}
