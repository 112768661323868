<h1>Cloud Migration Analysis</h1>
<p></p>
<div class="row">
  <div class="col-md-3">
    <mat-card>
      <h3>Client Migration Complexity Analysis</h3>
      <p>How complex is this Migration likely to be as compared to other Migrations?</p>
      <a id="btn-complexity" [routerLink]="['complexity']" mat-raised-button color="primary">view...</a>
  </mat-card>
  </div>
  <div class="col-md-3">
    <mat-card>
      <h3>Data Transfer Network Profiling</h3>
      <p>What is the estimated Data Transfer time?</p>
      <p>What are the availabe and viable mitigation strategies?</p>
      <a id="btn-datatransfer" [routerLink]="['datatransfer']" mat-raised-button color="primary">view...</a>
    </mat-card>
  </div>
</div>
<div class="row">
    <div class="col-md-3">
        <mat-card>
          <h3>Client Workload Analysis</h3>
          <p>How does current utilization align with currently provisioned VMs or LPARs?</p>
        </mat-card>
      </div>
      <div class="col-md-3">
      <mat-card>
      <h3>Workload Matching</h3>
      <p>What should the target provisioning look like?</p>
    </mat-card>
  </div>
</div>