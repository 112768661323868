import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-creator',
  templateUrl: './document-creator.component.html',
  styleUrls: ['./document-creator.component.scss']
})
export class DocumentCreatorComponent implements OnInit {

  ngOnInit() {

  }
}
