import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CloudMigrationToolAdminRoutingModule } from './cloud-migration-tool-admin-routing.module';
import { CloudMigrationToolAdminComponent } from './cloud-migration-tool-admin.component';
import { CloudProviderComponent } from './cloud-provider/cloud-provider.component';
import { CloudProviderPricingScheduleComponent } from './cloud-provider-pricing-schedule/cloud-provider-pricing-schedule.component';
import { CloudProviderProductComponent } from './cloud-provider-product/cloud-provider-product.component';
import { CloudProviderPricingComponent } from './cloud-provider-pricing/cloud-provider-pricing.component';
import { SharedModule } from '../shared/shared.module';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CloudProviderListComponent } from './cloud-provider/cloud-provider-list/cloud-provider-list.component';
import { CloudProviderDetailComponent } from './cloud-provider/cloud-provider-detail/cloud-provider-detail.component';
import { CloudProviderEditComponent } from './cloud-provider/cloud-provider-edit/cloud-provider-edit.component';
import { CloudProviderComputeProductListComponent } from './cloud-provider-compute-product-list/cloud-provider-compute-product-list.component';


@NgModule({ 
  imports: [
    CommonModule,
    CloudMigrationToolAdminRoutingModule,
    SharedModule,
    MatTableModule
  ],
   declarations: [
    CloudMigrationToolAdminComponent,
    CloudProviderComponent,
    CloudProviderPricingScheduleComponent,
    CloudProviderProductComponent,
    CloudProviderPricingComponent,
    CloudProviderListComponent,
    CloudProviderDetailComponent,
    CloudProviderEditComponent,
    CloudProviderComputeProductListComponent
  ],
  providers: [
  ]
})
export class CloudMigrationToolAdminModule { }
