<div *ngIf="project" [class.loading]="loading">
    <fieldset>
      <legend>Project Details</legend>
      <div class="row">
        <div class="col-md-4">
          <p>Project Name: {{ project.projectName }}</p>
          <p>Opp Q#: {{ project.oppurtunityNumber }}</p>
        </div>
        <div class="col-md-4">
          <p>Client Name: {{ project.client.commonName }}</p>
          <p>Created Date: {{ project.createdDate | date: "shortDate" }}</p>
        </div>
      </div>