<button id="btn-close-icon" mat-icon-button class="close-button" (click)="close()">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{ formTitle }}
  </h3>
</div>
<mat-card>
  <mat-dialog-content [formGroup]="networkprofileRequestForm">
    <div>
      <fieldset>
        <legend>Network Data</legend>
        <h4>Profiling Input Parameters</h4>
        <div class="row">
          <div class="col-md-3">
            <mat-form-field>
              <!-- Bandwidth -->
              <input matInput type="number" min="0" step="50" formControlName="bandwidth" placeholder="Bandwidth" />
              <mat-error *ngIf="networkprofileRequestForm.controls['bandwidth'].hasError('required')">
                Bandwidth is required
              </mat-error>
              <span matSuffix>(Mb/s)</span>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!-- Transfer Data Size -->
            <mat-form-field>
              <input matInput type="number" min="0" step=".001" formControlName="migrationDataSizeInTerabytes" placeholder="Migration Data Size" />
              <mat-error *ngIf="networkprofileRequestForm.controls['migrationDataSizeInTerabytes'].hasError('required')">
                Migration Data Size is required
              </mat-error>
              <span matSuffix>(TB)</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <mat-form-field>
              <input style="font-weight:bold" matInput placeholder="Measured Latency/RTT - Delay" formControlName="measuredDelay" />
              <span matSuffix>(ms)</span>
            </mat-form-field>
            <!-- <read-only-field style="font-weight: bold;" [value]='networkDataRTT' title='Measured Latency/RTT - Delay' suffix="(ms)"></read-only-field> -->
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <input matInput type="number" min="0" step=".1" formControlName="estimatedLossPercentage" placeholder="Measured/Estimated Loss" />
              <mat-error *ngIf="networkprofileRequestForm.controls['estimatedLossPercentage'].hasError('required')">
                Migration Data Size is required
              </mat-error>
              <span matSuffix>(%)</span>
            </mat-form-field>
            <!-- <read-only-field style="font-weight: bold;" [value]='networkDataPacketLoss' title='Observed Packet Loss' suffix="(%)"></read-only-field> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!-- Window Size -->
            <mat-form-field>
              <input matInput formControlName="tCPWindowSize" placeholder="TCP Window Size" />
              <mat-error *ngIf="networkprofileRequestForm.controls['tCPWindowSize'].hasError('required')">
                TCP Window Size is required
              </mat-error>
              <span matSuffix>(Bytes)</span>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!-- Window Scaling -->
            <mat-form-field>
              <input type="number" min="0" max="4" matInput formControlName="windowScalingExponent" placeholder="Window Scaling Exponent" />
              <mat-error *ngIf="networkprofileRequestForm.controls['windowScalingExponent'].hasError('required')">
                Window Scaling Exponent is required
              </mat-error>
              <span matSuffix>(2<sup>exp</sup>)</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <mat-form-field>
              <input matInput type="number" min="0" formControlName="ethernetHeaderBytes" placeholder="Ethernet Header" />
              <mat-error *ngIf="networkprofileRequestForm.controls['ethernetHeaderBytes'].hasError('required')">
                Ethernet Header Bytes is required
              </mat-error>
              <span matSuffix>(Bytes)</span>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <input matInput type="number" min="0" formControlName="ipHeaderBytes" placeholder="IP Header" />
              <mat-error *ngIf="networkprofileRequestForm.controls['ipHeaderBytes'].hasError('required')">
                IP Header Bytes is required
              </mat-error>
              <span matSuffix>(Bytes)</span>
            </mat-form-field>
          </div>

        </div>
        <div class="row">
          <div class="col-md-3">
            <mat-form-field>
              <input matInput type="number" min="0" formControlName="tCPHeaderBytes" placeholder="TCP Header" />
              <mat-error *ngIf="networkprofileRequestForm.controls['tCPHeaderBytes'].hasError('required')">
                TCP Header Bytes is required
              </mat-error>
              <span matSuffix>(Bytes)</span>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <input matInput type="number" min="0" formControlName="vPNHeaderBytes" placeholder="VPN Header" />
              <!-- <mat-error *ngIf="networkprofileRequestForm.controls['vPNHeaderBytes'].hasError('required')">
                VPN Header Bytes is required
              </mat-error> -->
              <span matSuffix>(Bytes)</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12" align="end">
            <button type="submit" mat-raised-button mat-primary (click)="run()" [disabled]="!networkprofileRequestForm?.valid">Run...</button>
          </div>
        </div>
      </fieldset>

    </div>
    <br />
    <div>
      <fieldset>
        <legend>Network Profile</legend>
        <h4>Calucated Values and Prediction Output</h4>
        <span style="font-weight:bold; color: red;" *ngIf="!profileGenerated">{{message}}</span>
        <div *ngIf="networkprofile">
          <div class="row">
            <div class="col-2">
              <read-only-field [value]='networkprofile.totalDataTransferTime' title='Total Data Transfer Time' suffix="(secs)"></read-only-field>
            </div>
            <!-- <div class="col-2">
              <read-only-field style="font-weight: bold;" [value]='networkprofile.bandwidthStreamTime' title='Bandwidth Stream Time' suffix="(sec)"></read-only-field>
            </div>
            <div class="col-2">
              <read-only-field [value]='networkprofile.tcpackTime' title='TCP ACK Time' suffix="(sec)"></read-only-field>
            </div>
            <div class="col-2">
              <read-only-field [value]='networkprofile.retransmissionTime' title='Retransmission Time'></read-only-field>
            </div> -->
          </div>
          <div class="row">
            <div class="col-2">
              <read-only-field [value]='networkprofile.totalDataTransferTimeMinutes' title='Total Data Transfer Time Minutes' suffix="(mins)"></read-only-field>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <read-only-field [value]='networkprofile.totalDataTransferTimeDays' title='Total Data Transfer Time Days' suffix="(days)"></read-only-field>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-2">
              <read-only-field [value]='networkprofile.maxTransferUnits' title='Max Transfer Units'></read-only-field>
             </div>
             <div class="col-2">
              <read-only-field [value]='networkprofile.maxSegmentSize' title='Max Segment Size'></read-only-field>
             </div>
             <div class="col-2">
              <read-only-field [value]='networkprofile.tcpWindow' title='Tcp Window'></read-only-field>
             </div>  -->
            <div class="col-2">
              <read-only-field [value]='networkprofile.lossOrDelayCompensationRequired' title='Loss Or Delay Compensation Required'></read-only-field>
              <!-- <read-only-field [value]='networkprofile.bandwidth' title='Bandwidth'></read-only-field> -->
              <!-- <read-only-field [value]='networkprofile.packetLoss' title='Packet Loss'></read-only-field> -->
              <!-- <read-only-field [value]='networkprofile.delay' title='Delay'></read-only-field> -->
              <!-- <read-only-field [value]='networkprofile.transferDataSizeInTeraBytes' title='Transfer Data Size In Tera Bytes'></read-only-field> -->
              <!-- <read-only-field [value]='networkprofile.ethernetHeader' title='Ethernet Header'></read-only-field>
              <read-only-field [value]='networkprofile.ipHeader' title='Ip Header'></read-only-field>
              <read-only-field [value]='networkprofile.tcpHeader' title='Tcp Header'></read-only-field>
              <read-only-field [value]='networkprofile.tcpWindowSize' title='Tcp Window Size'></read-only-field> -->
              <!-- <read-only-field [value]='networkprofile.numberOfFrames' title='Number Of Frames'></read-only-field>
              <read-only-field [value]='networkprofile.totalBytes' title='Total Bytes'></read-only-field> -->
              <!-- <read-only-field [value]='networkprofile.performanceRating' title='Performance Rating'></read-only-field> -->
              <!-- <read-only-field [value]='networkprofile.windowScalingExponent' title='Window Scaling Exponent'></read-only-field> -->
            </div>

            <div class="col-2">
              <!-- <read-only-field [value]='networkprofile.totalPackets' title='Total Packets'></read-only-field>
              <read-only-field [value]='networkprofile.totalHeaderBytes' title='Total Header Bytes'></read-only-field>
              <read-only-field [value]='networkprofile.totalBits' title='Total Bits'></read-only-field>
              <read-only-field [value]='networkprofile.maxPossibleBandwidth' title='Max Possible Bandwidth'></read-only-field>
              <read-only-field [value]='networkprofile.segmentsPerAck' title='Segments Per Ack'></read-only-field>
              <read-only-field [value]='networkprofile.outstandingSegments' title='Outstanding Segments'></read-only-field>
              <read-only-field [value]='networkprofile.tcpSegments' title='Tcp Segments'></read-only-field>
              <read-only-field [value]='networkprofile.windowSizeCompensation' title='Window Size Compensation'></read-only-field>
              <read-only-field [value]='networkprofile.numberOfRetransmits' title='Number Of Retransmits'></read-only-field>
              <read-only-field [value]='networkprofile.packetHeaderBytes' title='Packet Header Bytes'></read-only-field> -->
            </div>
          </div>
        </div>
      </fieldset>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="submit" mat-raised-button mat-primary [disabled]="!networkprofileRequestForm?.valid" (click)="save()">Save</button>
    <button class="mat-raised-button" (click)="close()">Close</button>
  </mat-dialog-actions>
</mat-card>
