import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cloud-migration-contract-lines',
  templateUrl: './cloud-migration-contract-lines.component.html',
  styleUrls: ['./cloud-migration-contract-lines.component.scss']
})
export class CloudMigrationContractLinesComponent implements OnInit {
  @Input() contractLines: any;
  displayedColumns: string[];
  discoveryId: any;
  loading: boolean;
  dataSource: any[];
   
  constructor() { }

  ngOnInit(): void {
    this.setData();
  }
  
  ngOnChanges(){
    this.setData();
  }

  setData() {
    this.dataSource = this.contractLines?.rows;
    this.displayedColumns = this.contractLines?.displayedColumns;
  }

}
