<mat-table mat-table>
  <ng-container matColumnDef="instanceType">
    <mat-header-cell *matHeaderCellDef>Instance</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.instanceType}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="osSoftware">
    <mat-header-cell *matHeaderCellDef>OS</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.osSoftware}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="category">
    <mat-header-cell *matHeaderCellDef>Category</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.category}}
    </mat-cell>
  </ng-container>
   <ng-container matColumnDef="vCPU">
    <mat-header-cell style="width: fit-content;" *matHeaderCellDef>CPU</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.vCPU}}
    </mat-cell>
  </ng-container> 
  <ng-container matColumnDef="ram">
    <mat-header-cell *matHeaderCellDef>RAM</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{row.ram}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="pricePerHour">
    <mat-header-cell class="mat-decimal-cell" *matHeaderCellDef>Price/Hour($)</mat-header-cell>
    <mat-cell  class="mat-decimal-cell" *matCellDef="let row">
      {{row.pricePerHour | number:'1.3-3'}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="pricePerMonth">
    <mat-header-cell class="mat-decimal-cell" *matHeaderCellDef>Price/Month($)</mat-header-cell>
    <mat-cell  class="mat-decimal-cell" *matCellDef="let row">
      {{row.pricePerMonth  | number:'1.3-3'}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="annualPrice">
    <mat-header-cell class="mat-decimal-cell" *matHeaderCellDef>Annual Price($)</mat-header-cell>
    <mat-cell  class="mat-decimal-cell" *matCellDef="let row">
      {{row.annualPrice  | number:'1.3-3'}}
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="productSelected(row)">
  </mat-row>
</mat-table>