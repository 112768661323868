export const requestors = [
  { name: 'Joel Andersen', id: 0, active: false },
  { name: 'Michelle Fuller', id: 1, active: false },
  { name: 'Ivaylo Georgiev', id: 2, active: true  },
  { name: 'Earle Holbrooks', id: 3, active: false },
  { name: 'Joe Kolonko', id: 4, active: false  },
  { name: 'Erick Michalski', id: 5, active: false },
  { name: 'Christian Peterson', id: 6, active: false },
  { name: 'Kristy Sholett', id: 7 , active: true },
  { name: 'Paula Walsh', id: 8 , active: false},
  { name: 'Paul Stengel', id: 9, active: false },
  { name: 'Dispatch', id: 10, active: false },
  { name: 'Services Ops/PMO', id: 11, active: false },
  { name: 'Unknown', id: 12, active: false },
  { name: 'Pat Barbosa', id: 13, active: true },
  { name: 'Joe Jones', id: 14, active: false },
  { name: 'Raviraj Kulkarni', id: 15, active: true },
  { name: 'Mike Mistretta', id: 16, active: true },
  { name: 'Jordan Ryan', id: 17, active: true },
  { name: 'Tim Krull', id: 18, active: true },
];

export const signers = [
  { name: 'Joel Andersen', id: 0, active: false  },
  { name: 'Steve Bickford', id: 1, active: false },
  { name: 'Tom Horan', id: 3, active: true  },
  { name: 'Tim McDermott', id: 4 , active: false},
  { name: 'William Patterson', id: 5, active: true  },
  { name: 'Christian Peterson', id: 6, active: true },
  { name: 'Gail Schy', id: 7, active: false },
  { name: 'Rochelle Slater', id: 8 , active: false},
  { name: 'Tim Krull', id: 9, active: true  },
  { name: 'Tim DeLisle', id: 10, active: false  },
  { name: 'Kristy Sholett', id: 11, active: true  },
  { name: 'Mike Mistretta', id: 12, active: true  },
  { name: 'Mark Hetu', id: 13, active: true  },
  { name: 'Raviraj Kulkarni', id: 14, active: true  }
];


