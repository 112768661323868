import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VehicleManagerService {

  constructor(private http: HttpClient) { }

  saveVehicleManager(manager: any) {
    const body = JSON.stringify(manager);
    return this.http.post(`/api/VehicleManagers`, body);
  }
  updateVehicleManager(manager: any) {
    const body = JSON.stringify(manager);
    return this.http.put(`/api/VehicleManagers/${manager.vehicleMangerId}`, body);
  }
  getVehicleManager(managerId: string) {
    return this.http.get<any>(`/api/VehicleManagers/${managerId}`);
  }
  getVehicleManagers() {
    return this.http.get<any[]>(`/api/VehicleManagers`);
  }
  deleteVehicleManager(managerId: string) {
    return this.http.delete(`/api/VehicleManagers/${managerId}`);
  }
  getVehicleManagerByEmpId(empId: string) {
    return this.http.get(`/api/VehicleManagers/filter?employeeId=${empId}`);
  }

}
