import { Injectable } from '@angular/core';



import { DocumentCreator } from './models/document-creator';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DocumentCreatorService {

  constructor(private http: HttpClient) { }
  getDocument(id: string) {
    return this.http.get<any>(`/api/documentcreator/${id}`);
  }
  getDocuments() {
    return this.http.get<any[]>(`/api/documentcreator`);
  }
  createDocument(data: DocumentCreator) {
    const body = JSON.stringify(data);
    return this.http.post(`/api/documentcreator`, body);
  }
  deleteDocument(id: string) {
    return this.http.delete(`/api/documentcreator/${id}`);
  }

}
