import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { FleetManagementRoutingModule } from './fleet-management-routing.module';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { FleetManagementComponent } from './fleet-management.component';
import { FleetMangementService } from './fleet-mangement.service';
import { VehicleCreateComponent } from './vehicles/vehicle-create/vehicle-create.component';
import { VehicleInsuranceComponent } from './vehicles/vehicle-insurance/vehicle-insurance.component';
import { VehicleMaintenanceScheduleComponent } from './vehicle-maintenance-schedule/vehicle-maintenance-schedule.component';
import { VehicleMaintenanceScheduleCreateComponent } from './vehicle-maintenance-schedule/vehicle-maintenance-schedule-create/vehicle-maintenance-schedule-create.component';
import { VehiclesListComponent } from './vehicles/vehicles-list/vehicles-list.component';
import { VehicleRegistrationComponent } from './vehicles/vehicle-registration/vehicle-registration.component';
import { VehicleRegistrationCreateComponent } from './vehicles/vehicle-registration/vehicle-registration-create/vehicle-registration-create.component';
import { RegistrationLandingPageComponent } from './vehicles/vehicle-registration/registration-landing-page/registration-landing-page.component';
import { VehicleModelsComponent } from './vehicles/vehicle-models/vehicle-models.component';
import { VehicleModelsCreateComponent } from './vehicles/vehicle-models/vehicle-models-create/vehicle-models-create.component';
import { FleetManagementLandingPageComponent } from './fleet-management-landing-page/fleet-management-landing-page.component';
import { VehicleModelsLandingPageComponent } from './vehicles/vehicle-models/vehicle-models-landing-page/vehicle-models-landing-page.component';
import { VehicleModelsListComponent } from './vehicles/vehicle-models/vehicle-models-list/vehicle-models-list.component';
import { AddMileageComponent } from './add-mileage/add-mileage.component';
import { VehicleDetailComponent } from './vehicles/vehicle-detail/vehicle-detail.component';
import { UsageRecordUpdateComponent } from './usage-record-update/usage-record-update.component';
import { VehicleModelDetailComponent } from './vehicles/vehicle-models/vehicle-model-detail/vehicle-model-detail.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { VehicleInsuranceCreateComponent } from './vehicles/vehicle-insurance/vehicle-insurance-create/vehicle-insurance-create.component';
import { VehicleInsuranceDetailComponent } from './vehicles/vehicle-insurance/vehicle-insurance-detail/vehicle-insurance-detail.component';
import { VehicleInsuranceListComponent } from './vehicles/vehicle-insurance/vehicle-insurance-list/vehicle-insurance-list.component';
import { VehicleExpensesCreateComponent } from './expenses/vehicle-expenses-create/vehicle-expenses-create.component';
import { VehicleExpensesListComponent } from './expenses/vehicle-expenses-list/vehicle-expenses-list.component';
import { VehicleExpensesDetailComponent } from './expenses/vehicle-expenses-detail/vehicle-expenses-detail.component';
import { VehicleRegistrationListComponent } from './vehicles/vehicle-registration/vehicle-registration-list/vehicle-registration-list.component';
import { VehicleRegistrationUpdateComponent } from './vehicles/vehicle-registration/vehicle-registration-update/vehicle-registration-update.component';
import { VehicleModelsUpdateComponent } from './vehicles/vehicle-models/vehicle-models-update/vehicle-models-update.component';
import { AddFuelComponent } from './add-fuel/add-fuel.component';
import { AttachMaintenanceScheduleComponent } from './vehicles/attach-maintenance-schedule/attach-maintenance-schedule.component';
import { VehicleMaintenanceScheduleListComponent } from './vehicle-maintenance-schedule/vehicle-maintenance-schedule-list/vehicle-maintenance-schedule-list.component';
import { ConfirmDeleteDialogComponent } from '../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MileageConfirmationDialogComponent } from '../shared/components/mileage-confirmation-dialog/mileage-confirmation-dialog.component';
import { AdminService } from '../admin/admin.service';
import { AddMileageAndExpenseComponent } from './add-mileage-and-expense/add-mileage-and-expense.component';
import { DriversComponent } from './drivers/drivers.component';
import { DriversListComponent } from './drivers/drivers-list/drivers-list.component';
import { VehicleDriverCreateComponent } from './drivers/vehicle-driver-create/vehicle-driver-create.component';
import { VehicleMileageUpdateComponent } from './vehicles/vehicle-mileage-update/vehicle-mileage-update.component';
import { VehicleReportsComponent } from './vehicle-reports/vehicle-reports.component';
import { MileageConfirmationPopupComponent } from './add-mileage/mileage-confirmation-popup/mileage-confirmation-popup.component';
import { ExpensePerMileComponent } from './vehicle-reports/expense-per-mile/expense-per-mile.component';
import { RegistrationExpirationComponent } from './vehicle-reports/registration-expiration/registration-expiration.component';
import { MileageComponent } from './vehicle-reports/mileage/mileage.component';
import { MilageTableComponent } from './add-mileage/milage-table/milage-table.component';
import { VehicleExpenseReportComponent } from './vehicle-reports/vehicle-expense-report/vehicle-expense-report.component';
import { VehicleManagerService } from '../admin/vehicle-manager/vehicle-manager.service';
import { VehicleAgingReportComponent } from './vehicle-reports/vehicle-aging-report/vehicle-aging-report.component';
import { AuditReportComponent } from './vehicle-reports/audit-report/audit-report.component';
import { RetiredComponent } from './retired/retired.component';
import { RetiredListComponent } from './retired/retired-list/retired-list.component';
import { FleetManagementHomeComponent } from './fleet-management-home/fleet-management-home.component';





@NgModule({
    imports: [
        CommonModule,
        FleetManagementRoutingModule,
        SharedModule
    ],
    declarations: [
        VehiclesComponent,
        FleetManagementComponent,
        VehicleCreateComponent,
        VehicleInsuranceComponent,
        VehicleMaintenanceScheduleComponent,
        VehicleMaintenanceScheduleCreateComponent,
        VehiclesListComponent,
        VehicleRegistrationCreateComponent,
        VehicleRegistrationComponent,
        RegistrationLandingPageComponent,
        VehicleModelsComponent,
        VehicleModelsCreateComponent,
        FleetManagementLandingPageComponent,
        VehicleModelsLandingPageComponent,
        VehicleModelsListComponent,
        AddMileageComponent,
        VehicleDetailComponent,
        UsageRecordUpdateComponent,
        VehicleModelDetailComponent,
        ExpensesComponent,
        VehicleInsuranceCreateComponent,
        VehicleInsuranceDetailComponent,
        VehicleInsuranceListComponent,
        VehicleExpensesCreateComponent,
        VehicleExpensesListComponent,
        VehicleExpensesDetailComponent,
        VehicleRegistrationListComponent,
        VehicleRegistrationUpdateComponent,
        VehicleModelsUpdateComponent,
        AddFuelComponent,
        AttachMaintenanceScheduleComponent,
        VehicleMaintenanceScheduleListComponent,
        AddMileageAndExpenseComponent,
        DriversComponent,
        DriversListComponent,
        VehicleDriverCreateComponent,
        VehicleMileageUpdateComponent,
        VehicleReportsComponent,
        MileageConfirmationPopupComponent,
        ExpensePerMileComponent,
        RegistrationExpirationComponent,
        MileageComponent,
        MilageTableComponent,
        VehicleExpenseReportComponent,
        VehicleAgingReportComponent,
        AuditReportComponent,
        RetiredComponent,
        RetiredListComponent,
        FleetManagementHomeComponent
    ],
    providers: [
        FleetMangementService,
        AdminService,
        VehicleManagerService
    ]
})
export class FleetManagementModule { }
