<mat-card>
    <mat-card-header>
        <h3>Release Note Details</h3>
    </mat-card-header>
    <mat-card-content>
        <div class="padding" [innerHTML]="workItemHtml">
            
        </div>
    </mat-card-content>


</mat-card>