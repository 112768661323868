import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    values: any;
    sections: any[];
    constructor(private service: HomeService) { }

    ngOnInit() {
        this.sections = [];
        var _test = '';
        var test: any = '';
        // this.service.getValues()
        //     .subscribe(res => {
        //         this.values = res;
        //     });

    }
    addSection(){
        this.sections.push('section');
    }
    addImage(){
        this.sections.push('image');
    }

}
