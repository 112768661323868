import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { DiscoveryConfiguration } from './configuration-datasource';
import { Employee } from '../../employee/employee-list/employee-list-datasource';
import { Workload } from './workload-datasource';


export class Discovery {
  id: string;
  clientId: string;
  host: string;
  environmentType: string;
  configuration: DiscoveryConfiguration;
  workloads: Array<Workload>;
  projectInfo: any;//comment
  projectId: string;//comment
  datePerformed: Date;
  // performedBy: Employee;
  performedBy: string;
  performedByUserName: string;
  storageType: StorageType;
  assessments: any;
  networkData: any;
  networkProfiles: any;
}
export class NetworkProfileGenerationRequest {
  discoveryId: string;
  networkDataId: string;
  bandwidth: number;
  tCPWindowSize: number;
  windowScalingExponent: number;
  ethernetHeaderBytes: number;
  ipHeaderBytes: number;
  tCPHeaderBytes: number;
  migrationDataSizeInTerabytes: number;
}

export enum StorageType {
  HHD,
  SSD
}

export class DiscoveryDataSource extends DataSource<any> {
  data: Array<any>;
  paginator: MatPaginator;
  sort: MatSort;
  nonFilteredData;

  constructor(private discoveries: Array<any>) {
    super();
    this.data = discoveries;
    this.nonFilteredData = discoveries;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

  filter(field: any, fullData: any) {

    let filterApplied = false;
    let filterData: any[] = fullData;
    //if (field['namefilter']) {
    //  filterdata = filterdata.filter(x => {
    //    return x.name.tolocalelowercase().indexof(field['namefilter'].tolocalelowercase()) > -1;
    //  });
    //}
    return filterData;
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: any[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: any[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'host': return compare(a.configuration.hostName, b.configuration.hostName, isAsc);
        case 'datePerformed': return compare(a.datePerformed, b.datePerformed, isAsc);
        case 'workloadCount': return compare(a.workloads.length, b.workloads.length, isAsc);
        case 'performedBy': return compare(a.performedBy.firstName, b.performedBy.firstName, isAsc);
        default: return 0;
      }
    });
  }

}
/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
