import { Component, OnInit } from '@angular/core';

import { VehicleRegistration, Vehicle } from '../../../models/fleet-management-models';
import { FleetMangementService } from '../../../fleet-mangement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-vehicle-registration-update',
  templateUrl: './vehicle-registration-update.component.html',
  styleUrls: ['./vehicle-registration-update.component.scss']
})
export class VehicleRegistrationUpdateComponent implements OnInit {
  registration: VehicleRegistration;

  constructor(private service: FleetMangementService, private route: ActivatedRoute, private location: Location) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        const id = params['id'];
        this.service.getVehicleRegistration(id)
          .subscribe(httpResult => {
            this.registration = httpResult;
          });
      });
  }
  updateRegistration() {
    this.service.updateVehicleRegistration(this.registration)
      .subscribe(res => {
        this.location.back();

      });
  }
  cancelUpdate() {
    this.location.back();
  }

}
