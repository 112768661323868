import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss']
})
export class ClientCreateComponent implements OnInit {
  clientForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.clientForm = this.fb.group({
      'id': [],
      'externalCustomerId': [],
      'customerType': [],
      'commonName': [],
      'legalName': [],
      'addressLine1': [],
      'addressLine2': [],
      'addressLine3': [],
      'city': [],
      'state': [],
      'zip': [],
      'country': [],
      'industryClassType': [],
      'industryClassCode': [],
      'industryClassDescription': [],
      'phone': [],
      'email': [],
      'website': [],
      'territoryDescription': [],
      'region': [],
      'dateCreatedExternally': [],
      'notes': [],
      'description': [],
      'customSowTemplate': [],
      'clientRates': []
    });
  }

}
