import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { ProfessionalServicesService } from '../../../professional-services/professional-services.service';
import {
  DiscoveryDataSource,
  Discovery,
} from '../../models/discovery-datasource';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import { forkJoin } from 'rxjs';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CloudMigrationDiscoveryPopupComponent } from '../cloud-migration-discovery-popup/cloud-migration-discovery-popup.component';

@Component({
  selector: 'app-cloud-migration-discovery',
  templateUrl: './cloud-migration-discovery.component.html',
  styleUrls: ['./cloud-migration-discovery.component.scss'],
})
export class CloudMigrationDiscoveryComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  project: any;
  discoveryDataSource: DiscoveryDataSource;
  loading: boolean;
  // discoveries: Array<Discovery>;
  discoveryId: string;
  discoveryList: any;

  displayedColumns = [
    'environment',
    'environmentType',
    'workloadCount',
    'performedBy',
    'datePerformed',
    'actions',
  ];
  projectId: any;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private cloudMigrationService: CloudMigrationToolService
  ) {}

  ngOnInit(): void {
    this.setData();
  }

  setData() {
    this.loading = true;
    this.route.parent?.parent?.params.subscribe(
      (param) => {
        this.projectId = param['id'];

        let discoveryList = this.cloudMigrationService.getDiscoveries(this.projectId);
        let project = this.cloudMigrationService.getProject(this.projectId);
        forkJoin([discoveryList, project]).subscribe((results) => {
          this.loading = false;
          this.project = results[1];
          this.discoveryList = results[0];
          console.log('this.discoveries: ', this.discoveryList);
          this.discoveryDataSource = new DiscoveryDataSource(this.discoveryList.rows);
          console.log('this.discoveryDataSource:', this.discoveryDataSource);
          this.discoveryDataSource.sort = this.sort;
          this.discoveryDataSource.paginator = this.paginator;
        });
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  getDiscoveries() {
    this.cloudMigrationService.getDiscoveries(this.project.id).subscribe(
      (res) => {
        this.discoveryList = res;
        this.discoveryDataSource = new DiscoveryDataSource(this.discoveryList);
        this.discoveryDataSource.sort = this.sort;
        this.discoveryDataSource.paginator = this.paginator;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  deleteRecord(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;
        this.cloudMigrationService.deleteDiscovery(id).subscribe((res) => {
          this.getDiscoveries();
        });
      }
    });
  }
  
  openDiscoveryUploadPopup(){}

  onUploadFinished(event: Event) {
    this.loading = true;
    this.getDiscoveries();
  }

  addDiscovery(){
    this.cloudMigrationService.getDiscoveryEnvironments(this.projectId).subscribe(data => {
      let environmentList = data;

      this.openCreateDiscoveryDialog(data);
    });
  }

  openCreateDiscoveryDialog(data: any) {
    const dialogRef = this.dialog.open(CloudMigrationDiscoveryPopupComponent, {
      height: '20%',
      width: '35%',
      data: {
       environmentList: data,
        projectId: this.projectId,
      },
      disableClose: true,
      autoFocus: true,

    });

    dialogRef.afterClosed()
      .subscribe((result: any) => {
        if (result) {
          console.log('result', result);

          const projectId = result.cloudMigrationServiceProjectId;
          const id = result.id;
          // this.router.navigateByUrl(`cmt/projects/${projectId}/view/assessments/${id}`);
          this.router.navigate(['','cmt','projects',this.projectId,'view','discoveries',id]);
   }
      });  }
  
  uploadDiscovery(){

  }
}
