import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, NgForm } from '@angular/forms';
import { ServiceEstimateDetail, ProjectPhase } from '../../models/service-estimate';
import { ServiceRole } from '../../../admin/models/service-role';
import { ServiceRoleService } from '../../../admin/service-role/service-role.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BusinessPartnerSearchPopupComponent } from '../../../business-partners/business-partner-search-popup/business-partner-search-popup.component';
import { SolutionPilar } from '../../../business-partners/models/solution-pilar';
import { BusinessPartnerService } from '../../../business-partners/business-partner.service';
import { ActivatedRoute } from '@angular/router';
import { ProfessionalServicesService } from '../../professional-services.service';
import { Location } from '@angular/common';
import { MaterialItemService } from '../../material-item.service';
import { ServiceEstimatorService } from '../../_services/service-estimator.service';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-change-order-create',
  templateUrl: './change-order-create.component.html',
  styleUrls: ['./change-order-create.component.scss']
})
export class ChangeOrderCreateComponent implements OnInit {
  serviceEstimateForm: UntypedFormGroup;
  serviceEstimateItems: Array<any>;
  serviceRoles: Array<ServiceRole>;
  serviceEstimateDetailsPlanning: UntypedFormArray;
  serviceEstimateDetailsExecute: UntypedFormArray;
  serviceEstimateDetailsClosing: UntypedFormArray;
  businessPartnerItems: UntypedFormArray;
  materialItems: UntypedFormArray;
  isValid: boolean;
  isNotesRequired = false;
  totalHours = 0;

  planningPhase = ProjectPhase.Plan;
  solutionPilars: Array<SolutionPilar>;
  seId: string;
  secId: string;
  travelExpenseGroup: UntypedFormGroup;
  @ViewChild('form')
  form: NgForm;
  pricingNotes: Array<any>;

  constructor(private fb: UntypedFormBuilder,
    private serviceRolesService: ServiceRoleService,
    private businessPartnerService: BusinessPartnerService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private psService: ProfessionalServicesService,
    private location: Location) { }

  ngOnInit() {
    this.isValid = true;
    this.route.params
      .subscribe(param => {
        this.seId = param['serviceEstimateId'];
        this.secId = param['secId'];
        this.serviceEstimateDetailsPlanning = this.fb.array([]);
        this.serviceEstimateDetailsClosing = this.fb.array([]);
        this.serviceEstimateDetailsExecute = this.fb.array([]);
        this.businessPartnerItems = this.fb.array([]);
        this.materialItems = this.fb.array([]);

        this.serviceRolesService.getServiceRoles()
          .subscribe(res => {
            this.serviceRoles = res;

            this.businessPartnerService.getSolutionPilars()
              .subscribe(pilarResult => {
                this.solutionPilars = pilarResult;
              },
                (error) => {
                  this.solutionPilars = [];
                });
            if (this.secId) {
              this.psService.getChangeOrderComponentById(this.secId)
                .subscribe(secResult => {
                  const data = secResult;
                  this.pricingNotes = data.pricingNotes;
                  data.phaseItems.forEach((item: any) => {
                    if (item.phase == 0) {
                      this.addPlanningItem(item);

                    } else if (item.phase == 1) {
                      this.addExecuteItem(item);

                    } else if (item.phase == 2) {
                      this.addClosingItem(item);
                    }
                  });
                  data.materialItems.forEach((mi: any) => {
                    this.addMeterialItem(mi);
                  });
                  data.businessPartnerItems.forEach((bi: any) => {
                    this.addBusinessItem(bi);
                  });
                  if (data.travelExpense) {
                    this.travelExpenseGroup = this.fb.group({
                      id: [data.travelExpense.id ? data.travelExpense.id : null],
                      airfare: [data.travelExpense ? data.travelExpense.airfare : ''],
                      auto: [data.travelExpense ? data.travelExpense.auto : ''],
                      hotel: [data.travelExpense ? data.travelExpense.hotel : ''],
                      gas: [data.travelExpense ? data.travelExpense.gas : ''],
                      meals: [data.travelExpense ? data.travelExpense.meals : ''],
                      other: [data.travelExpense ? data.travelExpense.other : ''],
                      travelHours: [data.travelExpense && data.travelExpense.travelHours ? data.travelExpense.travelHours : 0],
                      perHourCharge: [data.travelExpense && data.travelExpense.perHourCharge ? data.travelExpense.perHourCharge : 100]
                    });
                  } else {
                    this.travelExpenseGroup = this.fb.group({
                      airfare: [],
                      auto: [],
                      hotel: [],
                      gas: [],
                      meals: [],
                      other: [],
                      travelHours: [0],
                      perHourCharge: [100]
                    });

                  }

                  const totalsForView = this.calculateTotalsForView(data);
                  this.isNotesRequired = data.priceAdjustment ? true : false;
                  this.serviceEstimateForm = this.fb.group({
                    id: data.id,
                    serviceRoleId: [data.serviceRoleId, Validators.required],
                    phaseItems: [],
                    componentName: [data.componentName, Validators.required],
                    totalAmount: data.totalAmount,
                    businessPartnerTotal: data.businessPartnerTotal,
                    notes: [data.notes],
                    createdByEmployeeId: [data.createdByEmployeeId],
                    priceAdjustment: data.priceAdjustment,
                    serviceEstimateDetailsPlanning: this.serviceEstimateDetailsPlanning,
                    serviceEstimateDetailsClosing: this.serviceEstimateDetailsClosing,
                    serviceEstimateDetailsExecute: this.serviceEstimateDetailsExecute,
                    businessPartnerItems: this.businessPartnerItems,
                    materialItems: this.materialItems,
                    travelExpense: this.travelExpenseGroup,
                    serviceEstimateId: [this.seId],
                    version: [data.version],
                    'isAttached': [data.isAttached],
                    'isApproved': [data.isApproved],
                    'isReadyForApproval': [data.isReadyForApproval],
                    totalHours: [totalsForView.totalHours],
                    'isRejected': [data.isRejected]
                  });
                  if (!this.serviceEstimateForm.controls['totalAmount'].value) {
                    this.serviceEstimateForm.controls['totalAmount'].setValue(0);
                  }
                  if (!this.serviceEstimateForm.controls['totalHours'].value) {
                    this.serviceEstimateForm.controls['totalHours'].setValue(0);
                  }
                  this.serviceEstimateForm.valueChanges
                    .pipe(debounceTime(500))
                    .subscribe(formVa => {
                      this.isValid = this.serviceEstimateForm.valid;
                      const priceAdjustValue = this.serviceEstimateForm.controls['priceAdjustment'];
                      const notesControl = this.serviceEstimateForm.controls['notes'];
                      if (priceAdjustValue.dirty) {
                        if (priceAdjustValue.value) {
                          notesControl.setValidators(Validators.required);
                          notesControl.updateValueAndValidity();
                        } else {
                          notesControl.clearValidators();
                          notesControl.updateValueAndValidity();
                        }
                      }
                      this.calculateTotal();
                    });
                },
                  (error) => {

                    this.serviceEstimateForm = this.fb.group({
                      componentName: ['', Validators.required],
                      serviceRoleId: [null, Validators.required],
                      totalAmount: null,
                      notes: [''],
                      createdByEmployeeId: [],
                      priceAdjustment: null,
                      phaseItems: [],
                      serviceEstimateDetailsPlanning: this.serviceEstimateDetailsPlanning,
                      serviceEstimateDetailsClosing: this.serviceEstimateDetailsClosing,
                      serviceEstimateDetailsExecute: this.serviceEstimateDetailsExecute,
                      businessPartnerItems: this.businessPartnerItems,
                      materialItems: this.materialItems,
                      travelExpense: this.travelExpenseGroup,
                      serviceEstimateId: [this.seId],
                      businessPartnerTotal: [],
                      totalHours: []
                    });

                    if (!this.serviceEstimateForm.controls['totalAmount'].value) {
                      this.serviceEstimateForm.controls['totalAmount'].setValue(0);
                    }
                    if (!this.serviceEstimateForm.controls['totalHours'].value) {
                      this.serviceEstimateForm.controls['totalHours'].setValue(0);
                    }
                    this.serviceEstimateForm.valueChanges
                      .pipe(debounceTime(500))
                      .subscribe(formVa => {
                        this.isValid = this.serviceEstimateForm.valid;
                        const priceAdjustValue = this.serviceEstimateForm.controls['priceAdjustment'];
                        const notesControl = this.serviceEstimateForm.controls['notes'];
                        if (priceAdjustValue.dirty) {
                          if (priceAdjustValue.value) {
                            notesControl.setValidators(Validators.required);
                            notesControl.updateValueAndValidity();
                          } else {
                            notesControl.clearValidators();
                            notesControl.updateValueAndValidity();
                          }
                        }
                      });
                  });
            } else {
              this.serviceEstimateForm = this.fb.group({
                componentName: ['', Validators.required],
                serviceRoleId: [null, Validators.required],
                totalAmount: null,
                notes: [''],
                createdByEmployeeId: [],
                priceAdjustment: null,
                serviceEstimateDetailsPlanning: this.serviceEstimateDetailsPlanning,
                serviceEstimateDetailsClosing: this.serviceEstimateDetailsClosing,
                serviceEstimateDetailsExecute: this.serviceEstimateDetailsExecute,
                businessPartnerItems: this.businessPartnerItems,
                materialItems: this.materialItems,
                travelExpense: this.fb.group({
                  airfare: [],
                  auto: [],
                  hotel: [],
                  gas: [],
                  meals: [],
                  other: [],
                  travelHours: [0],
                  perHourCharge: [100]
                }),
                serviceEstimateId: [this.seId],
                businessPartnerTotal: [],
                totalHours: []
              });
              if (!this.serviceEstimateForm.controls['totalAmount'].value) {
                this.serviceEstimateForm.controls['totalAmount'].setValue(0);
              }
              if (!this.serviceEstimateForm.controls['totalHours'].value) {
                this.serviceEstimateForm.controls['totalHours'].setValue(0);
              }
              this.serviceEstimateForm.valueChanges
                .subscribe(formVa => {
                  this.isValid = this.serviceEstimateForm.valid;
                });
              this.serviceEstimateForm.valueChanges
                .pipe(debounceTime(500))
                .subscribe(formVa => {
                  this.isValid = this.serviceEstimateForm.valid;
                  const priceAdjustValue = this.serviceEstimateForm.controls['priceAdjustment'];
                  const notesControl = this.serviceEstimateForm.controls['notes'];
                  if (priceAdjustValue.dirty) {
                    if (priceAdjustValue.value) {
                      notesControl.setValidators(Validators.required);
                      notesControl.updateValueAndValidity();
                    } else {
                      notesControl.clearValidators();
                      notesControl.updateValueAndValidity();
                    }
                  }
                });
            }
          });
      });

  }
  addBusinessItem(serviceDetailItem?: any) {

    if (!serviceDetailItem) {
      serviceDetailItem = {};
      serviceDetailItem.phase = 3;
    }
    const groupItem = this.buildBPGroup(serviceDetailItem);
    if (!this.businessPartnerItems) {
      this.businessPartnerItems = this.fb.array([groupItem]);
    } else {
      this.businessPartnerItems.push(groupItem);
    }
  }
  addExecuteItem(serviceDetailItem?: any) {
    if (!serviceDetailItem) {
      serviceDetailItem = new ServiceEstimateDetail();
    }
    serviceDetailItem.phase = ProjectPhase.Execute;
    if (!this.secId) {
      serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
    } else {
      if (this.serviceEstimateForm) {
        if (this.serviceEstimateForm.controls['serviceRoleId'].value) {
          serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
        } else {
          serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
        }
      } else {
        serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
      }
    }
    const groupItem = this.buildGroup(serviceDetailItem);
    if (!this.serviceEstimateDetailsExecute) {
      this.serviceEstimateDetailsExecute = this.fb.array([]);
    } else {
    }
    this.serviceEstimateDetailsExecute.push(groupItem);
  }
  addClosingItem(serviceDetailItem?: any) {
    if (!serviceDetailItem) {
      serviceDetailItem = new ServiceEstimateDetail();
    }
    serviceDetailItem.phase = ProjectPhase.Close;
    if (!this.secId) {
      serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
    } else {
      if (this.serviceEstimateForm) {
        if (this.serviceEstimateForm.controls['serviceRoleId'].value) {
          serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
        } else {
          serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
        }
      } else {
        serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
      }
    }
    const groupItem = this.buildGroup(serviceDetailItem);
    serviceDetailItem.phase = ProjectPhase.Close;
    if (!this.serviceEstimateDetailsClosing) {
      this.serviceEstimateDetailsClosing = this.fb.array([]);
    }

    this.serviceEstimateDetailsClosing.push(groupItem);
  }
  addPlanningItem(serviceDetailItem?: any) {
    if (!serviceDetailItem) {
      serviceDetailItem = new ServiceEstimateDetail();
    }
    serviceDetailItem.phase = ProjectPhase.Plan;
    if (!this.secId) {
      serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
    } else {
      if (this.serviceEstimateForm) {
        if (this.serviceEstimateForm.controls['serviceRoleId'].value) {
          serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
        } else {
          serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
        }
      } else {
        serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
      }
    }
    const groupItem = this.buildGroup(serviceDetailItem);
    if (!this.serviceEstimateDetailsPlanning) {
      this.serviceEstimateDetailsPlanning = this.fb.array([groupItem]);
    } else {
      this.serviceEstimateDetailsPlanning.push(groupItem);
    }
  }
  addMeterialItem(mi?: any) {
    let item: UntypedFormGroup;
    if (mi) {
      item = this.fb.group({
        'id': [mi.id],
        'name': [mi.name],
        'cost': [mi.cost],
        'sell': [mi.sell]
      });
    } else {
      item = this.fb.group({
        'name': [],
        'cost': [],
        'sell': []
      });
    }
    this.materialItems.push(item);

  }
  removeMaterialItem(index: number) {
    const id = this.materialItems.at(index).value['id'];
    if (id) {
      this.psService.deleteMaterialItemById(id)
        .subscribe(res => {

          this.materialItems.removeAt(index);
        });
    } else {

      this.materialItems.removeAt(index);
    }
  }
  buildBPGroup(serviceEstimateDetail?: any) {
    let group: UntypedFormGroup;
    if (serviceEstimateDetail.id) {
      this.totalHours += serviceEstimateDetail.quantity;
      group = this.fb.group({
        id: serviceEstimateDetail.id ? serviceEstimateDetail.id : null,
        'businessPartnerId': serviceEstimateDetail.businessPartnerId ? serviceEstimateDetail.businessPartnerId : '',
        resourceType: 1,
        'activity': [serviceEstimateDetail.activity ? serviceEstimateDetail.activity : '', Validators.required],
        bpName: [serviceEstimateDetail.businessPartner ? serviceEstimateDetail.businessPartner.businessPartnerLegalName : '', Validators.required],
        estimatiedPrice: [serviceEstimateDetail.estimatiedPrice ? serviceEstimateDetail.estimatiedPrice : 0, Validators.required],
        rate: serviceEstimateDetail.actualRate ? serviceEstimateDetail.actualRate : 0,
        serviceRatePerQuanity: serviceEstimateDetail.actualRate ? serviceEstimateDetail.actualRate : 0,
        overrideRate: serviceEstimateDetail.actualRate ? serviceEstimateDetail.actualRate : 0,
        expenseEstimate: serviceEstimateDetail.estimatiedPrice ? serviceEstimateDetail.estimatiedPrice : 0,
        multiplier: 1,
        'isFixedCost': serviceEstimateDetail.isFixedCost ? serviceEstimateDetail.isFixedCost : 0,
        'cost': [serviceEstimateDetail.cost ? serviceEstimateDetail.cost : 0, Validators.required],
        'sell': [serviceEstimateDetail.sell ? serviceEstimateDetail.sell : 0, Validators.required],
        estimatedHours: [serviceEstimateDetail.isFixedCost ? { value: null, disabled: true } : (serviceEstimateDetail.estimatedHours ? serviceEstimateDetail.estimatedHours : 0), Validators.required],
        solutionPilarId: [serviceEstimateDetail.solutionPilarId ? serviceEstimateDetail.solutionPilarId : 0, Validators.required]
      });
    } else {
      group = this.fb.group({
        'businessPartnerId': '',
        'solutionPilarId': [null, Validators.required],
        'activity': [serviceEstimateDetail.activity ? serviceEstimateDetail.activity : '', Validators.required],
        'bpName': ['', Validators.required],
        'estimatiedPrice': [0, Validators.required],
        'rate': 0,
        'cost': [null, Validators.required],
        'sell': [null, Validators.required],
        'expenseEstimate': 0,
        'multiplier': 1,
        'isFixedCost': 0,
        'estimatedHours': [null, Validators.required]
      });
    }

    this.businessPartnerCalulcationOnGroup(group);
    return group;

  }
  buildGroup(serviceEstimateDetail?: any) {
    const _sr = this.serviceRoles.filter(sr => sr.serviceRoleId == serviceEstimateDetail.serviceRoleId)[0];
    if (serviceEstimateDetail.id) {
      let group: UntypedFormGroup;
      const phase1 = serviceEstimateDetail.phase ? serviceEstimateDetail.phase : null;
      group = this.fb.group({
        id: serviceEstimateDetail.id ? serviceEstimateDetail.id : null,
        'activity': [serviceEstimateDetail.activity ? serviceEstimateDetail.activity : 'Default', Validators.required],
        quantity: [serviceEstimateDetail.quantity ? serviceEstimateDetail.quantity : 0, Validators.required],
        estimatiedPrice: serviceEstimateDetail.estimatiedPrice ? serviceEstimateDetail.estimatiedPrice : 0,
        billedRate: serviceEstimateDetail.billedRate ? serviceEstimateDetail.billedRate : 0,
        serviceRoleId: serviceEstimateDetail.serviceRoleId ? serviceEstimateDetail.serviceRoleId : null,
        serviceRatePerQuanity: serviceEstimateDetail.serviceRatePerQuanity ? serviceEstimateDetail.serviceRatePerQuanity : 0,
        overrideRate: serviceEstimateDetail.overrideRate ? serviceEstimateDetail.overrideRate : 0,
        expenseEstimate: serviceEstimateDetail.estimatiedPrice ? serviceEstimateDetail.estimatiedPrice : 0,
        multiplier: serviceEstimateDetail.multiplier ? serviceEstimateDetail.multiplier : 1,
        phase: serviceEstimateDetail.phase,
        activityNotes: serviceEstimateDetail.activityNotes,
        isInstallation: serviceEstimateDetail.isInstallation,
        isSpecialApplicationAndPremierService: serviceEstimateDetail.isSpecialApplicationAndPremierService
      });
      this.setupCalculationOnGroup(group);
      return group;
    } else {
      const group = this.fb.group({
        'activity': ['Default', Validators.required],
        'bpName': '',
        'quantity': [0, Validators.required],
        'estimatiedPrice': 0,
        'billedRate': _sr ? _sr.serviceRoleHourlyRate : 0,
        'serviceRoleId': serviceEstimateDetail.serviceRoleId ? serviceEstimateDetail.serviceRoleId : null,
        'serviceRatePerQuanity': _sr ? _sr.serviceRoleHourlyRate : 0,
        'overrideRate': _sr ? _sr.serviceRoleHourlyRate : 0,
        'expenseEstimate': 0,
        'multiplier': 1,
        phase: serviceEstimateDetail.phase,
        activityNotes: '',
        isInstallation: false,
        isSpecialApplicationAndPremierService: false
      });
      this.setupCalculationOnGroup(group);
      return group;
    }

  }
  buildBusinessGroup(businessPartnerItem?: any) {
    let group: UntypedFormGroup;
    if (businessPartnerItem) {
      group = this.fb.group({

      });
    } else {

    }
  }
  businessPartnerCalulcationOnGroup(group: UntypedFormGroup) {
    group.controls['isFixedCost'].valueChanges
      .subscribe(res => {
        if (res) {
          group.controls['estimatedHours'].clearValidators();
          group.controls['estimatedHours'].clearAsyncValidators();
          group.controls['estimatedHours'].disable();
          group.controls['estimatedHours'].updateValueAndValidity();
        } else {
          group.controls['estimatedHours'].setValidators(Validators.required);
          group.controls['estimatedHours'].enable();
          group.controls['estimatedHours'].updateValueAndValidity();


        }
      });
    // if (this.serviceEstimateForm) {

    //   this.calculateTotal();
    // }
    this.calculateTotal();

  }
  setupCalculationOnGroup(group: UntypedFormGroup) {
    group.controls['serviceRoleId'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        const selectedRole = this.serviceRoles.filter(sr => sr.serviceRoleId === res)[0];
        console.log(group.controls);
        group.controls['billedRate'].setValue(selectedRole.serviceRoleHourlyRate);
        group.controls['serviceRatePerQuanity'].setValue(selectedRole['serviceRoleHourlyRate']);

        this.performEstimatiedPriceCalulation(group);
      });
    group.controls['quantity'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.performEstimatiedPriceCalulation(group);
      });
    group.controls['multiplier'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.performEstimatiedPriceCalulation(group);
      });
    group.controls['overrideRate'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.performEstimatiedPriceCalulation(group);
      });
  }
  performEstimatiedPriceCalulation(formGroup: UntypedFormGroup) {
    if (formGroup.controls['overrideRate']) {
      if (formGroup.controls['overrideRate'].value === 0) {
        formGroup.controls['billedRate'].setValue(0);
      }
      if (formGroup.controls['overrideRate'].value) {
        if (formGroup.controls['overrideRate'].value !== 0) {
          if (formGroup.controls['overrideRate'].value !== '0') {
            if (formGroup.controls['overrideRate'].value !== '') {
              const rate = formGroup.controls['overrideRate'].value;
              formGroup.controls['billedRate'].setValue(rate);
            }
          }
        }
      }
    } else {
      // formGroup.controls['billedRate'].setValue(formGroup.controls['serviceRatePerQuanity'].value);
    }
    let quantity = formGroup.controls['quantity'].value;
    const multiplier = formGroup.controls['multiplier'].value;

    quantity = quantity * multiplier;
    let billRate;
    billRate = formGroup.controls['billedRate'].value;
    const cost = quantity * billRate;
    // formGroup.controls['rate'].value;
    if (quantity !== 0) {
      formGroup.controls['estimatiedPrice'].setValue(cost);
    }

    this.calculateTotal();
  }
  calculateTotals() {
    if (this.serviceEstimateForm.valid) {
      let total: number;
      total = 0;
      let totalHours: number;
      totalHours = 0;
      const closingItems: Array<any> = this.serviceEstimateDetailsClosing.value;
      const executeItems: Array<any> = this.serviceEstimateDetailsExecute.value;
      const planningItems: Array<any> = this.serviceEstimateDetailsPlanning.value;
      const businessPartnerItems: Array<any> = this.businessPartnerItems.value;
      // const businessItems: Array<any> = this.businessPartnerItems.value;
      closingItems.forEach(item => {
        totalHours += Number.parseInt(Number.parseInt(item['quantity']).toFixed(2));
        total += Number.parseInt(item['estimatiedPrice']);
      });
      executeItems.forEach(item => {
        totalHours += Number.parseInt(Number.parseInt(item['quantity']).toFixed(2));
        total += Number.parseInt(item['estimatiedPrice']);
      });
      planningItems.forEach(item => {
        totalHours += Number.parseInt(Number.parseInt(item['quantity']).toFixed(2));
        total += Number.parseInt(item['estimatiedPrice']);
      });
      let businessPartnerTotal = 0;
      businessPartnerItems.forEach(i => {
        if (!i.isFixedCost) {

          totalHours += Number.parseInt(Number.parseInt(i['quantity']).toFixed(2));
          total += Number.parseInt(Number.parseInt(i['estimatiedPrice']).toFixed(2));
        } else {
          total += Number.parseInt(Number.parseInt(i['sell']).toFixed(2));
        }
        businessPartnerTotal += i.sell;

      });
      const priceAdjustment = Number.parseInt(this.serviceEstimateForm.controls['priceAdjustment'].value);
      if (priceAdjustment) {
        total += priceAdjustment;
      }
      this.serviceEstimateForm.controls['totalAmount'].setValue(total);
      this.serviceEstimateForm.controls['totalHours'].setValue(totalHours);
      total += this.getTravelTotal();
      this.serviceEstimateForm.controls['totalAmount'].setValue(total);
      let formValue: any;
      formValue = this.serviceEstimateForm.value;
      formValue['phaseItems'] = [];
      closingItems.forEach(item => {
        formValue['phaseItems'].push(item);
      });
      executeItems.forEach(item => {
        formValue['phaseItems'].push(item);
      });
      planningItems.forEach(item => {
        formValue['phaseItems'].push(item);
      });
      this.serviceEstimateForm.controls['businessPartnerTotal'].setValue(businessPartnerTotal);
      if (!this.secId) {
        this.psService.createChangeOrderComponent(formValue)
          .subscribe(res => {
            this.location.back();
          });
      } else {
        this.psService.updateChangeOrderComponent(formValue)
          .subscribe(res => {
            this.location.back();
          });
      }
    } else {
      this.isValid = false;
      console.log(this.serviceEstimateForm.errors);
    }
  }

  calculateTotal() {
    // if (this.serviceEstimateForm.valid) {
    this.isValid = true;
    let total: number;
    total = 0;
    let totalHours: number;
    totalHours = 0;
    const closingItems: Array<any> = this.serviceEstimateDetailsClosing.value;
    const executeItems: Array<any> = this.serviceEstimateDetailsExecute.value;
    const planningItems: Array<any> = this.serviceEstimateDetailsPlanning.value;
    const businessPartnerItems: Array<any> = this.businessPartnerItems.value;
    // const businessItems: Array<any> = this.businessPartnerItems.value;
    let businessPartnerTotal = 0;
    businessPartnerItems.forEach(i => {
      if (!i.isFixedCost) {
        totalHours += Number.parseInt(Number.parseInt(i['estimatedHours']).toFixed(2));
        total += Number.parseInt(Number.parseInt(i['estimatedHours']).toFixed(2)) * Number.parseInt(Number.parseInt(i['sell']).toFixed(2));
      } else {
        total += Number.parseInt(Number.parseInt(i['sell']).toFixed(2));
      }
      businessPartnerTotal += i.sell;

    });
    closingItems.forEach(item => {
      totalHours += Number.parseInt(Number.parseInt(item['quantity']).toFixed(2)) * Number.parseInt(Number.parseInt(item['multiplier']).toFixed(2));
      total += Number.parseInt(Number.parseInt(item['estimatiedPrice']).toFixed(2));
    });
    executeItems.forEach(item => {
      totalHours += Number.parseInt(Number.parseInt(item['quantity']).toFixed(2)) * Number.parseInt(Number.parseInt(item['multiplier']).toFixed(2));
      total += Number.parseInt(Number.parseInt(item['estimatiedPrice']).toFixed(2));
    });
    planningItems.forEach(item => {
      totalHours += Number.parseInt(Number.parseInt(item['quantity']).toFixed(2)) * Number.parseInt(Number.parseInt(item['multiplier']).toFixed(2));
      total += Number.parseInt(Number.parseInt(item['estimatiedPrice']).toFixed(2));
    });
    const priceAdjustment = Number.parseInt(Number.parseInt(this.serviceEstimateForm.controls['priceAdjustment'].value).toFixed(2));
    if (priceAdjustment) {
      total += priceAdjustment;
    }
    total += this.getTravelTotal();
    this.serviceEstimateForm.controls['totalAmount'].setValue(total);
    this.serviceEstimateForm.controls['totalHours'].setValue(totalHours);
    let formValue: any;
    this.serviceEstimateForm.controls['businessPartnerTotal'].setValue(businessPartnerTotal);
    formValue = this.serviceEstimateForm.value;
    formValue['phaseItems'] = [];
    closingItems.forEach(item => {
      formValue['phaseItems'].push(item);
    });
    executeItems.forEach(item => {
      formValue['phaseItems'].push(item);
    });
    planningItems.forEach(item => {
      formValue['phaseItems'].push(item);
    });
    return formValue;
    // }
  }
  calculateMaterialTotal() {
    const matItems: Array<any> = this.materialItems.value;
    let total = 0;
    matItems.forEach(item => {
      total += item['sell'];
    });
    const totalValue = this.serviceEstimateForm.controls['totalAmount'].value;

    this.serviceEstimateForm.controls['totalAmount'].setValue(totalValue + total);
  }
  getTravelTotal() {

    const travelGroup: UntypedFormGroup = this.serviceEstimateForm.controls['travelExpense'] as UntypedFormGroup;
    let total = 0;
    total += travelGroup.controls['airfare'].value;
    total += travelGroup.controls['auto'].value;
    total += travelGroup.controls['hotel'].value;
    total += travelGroup.controls['gas'].value;
    total += travelGroup.controls['meals'].value;
    total += travelGroup.controls['other'].value;
    total += travelGroup.controls['travelHours'].value * travelGroup.controls['perHourCharge'].value;
    return total;
  }
  setupCalculationOnBPGroup(group: UntypedFormGroup) {

  }
  removeControl(control: any, index: any) {
    const id = this.businessPartnerItems.controls[index].value['id'];
    if (id) {
      this.psService.deleteTemplateBusinessPartnerItemById(id)
        .subscribe(res => {
          this.businessPartnerItems.removeAt(index);

        });
    } else {
      this.businessPartnerItems.removeAt(index);
    }
  }
  openBusinessSearch(control: any, index: any) {
    const popup = this.dialog.open(BusinessPartnerSearchPopupComponent);
    popup.afterClosed().subscribe(res => {
      console.log(res);
      let item: any;
      item = this.businessPartnerItems.at(index).value;
      this.businessPartnerItems.at(index).patchValue({ 'bpName': res.data.businessPartnerLegalName, 'businessPartnerId': res.data.id });
    });
  }

  public canDeactivate(): boolean {
    const state = this.form.submitted || !this.form.dirty;
    const url = this.location.path();

    if (state) {

    } else {
      history.pushState(null, 'Meridian Unified Portal', url);
    }
    return state;
  }


  private getBusinessPartnerTotal() {
    let totalHours: any;
    let total: any;
    total = 0;
    totalHours = 0;

    const businessPartnerItems: Array<any> = this.businessPartnerItems.value;
    businessPartnerItems.forEach(i => {
      if (!i.isFixedCost) {

        totalHours += Number.parseInt(Number.parseInt(i['quantity']).toFixed(2));
        const quanity = Number.parseInt(i['quantity']).toFixed(2);
        const sell = Number.parseInt(i['sell']).toFixed(2);
        total += Number.parseInt(quanity) * Number.parseInt(sell);
      } else {
        total += Number.parseInt(Number.parseInt(i['sell']).toFixed(2));
      }

    });
    return { totalHours, total };
  }
  private getPhaseItemsTotal() {
    let totalHours: any;
    let total: any;
    total = 0;
    totalHours = 0;
    const closingItems: Array<any> = this.serviceEstimateDetailsClosing.value;
    const executeItems: Array<any> = this.serviceEstimateDetailsExecute.value;
    const planningItems: Array<any> = this.serviceEstimateDetailsPlanning.value;

    closingItems.forEach(item => {
      totalHours += Number.parseInt(Number.parseInt(item['quantity']).toFixed(2));
      total += Number.parseInt(Number.parseInt(item['estimatiedPrice']).toFixed(2));
    });
    executeItems.forEach(item => {
      totalHours += Number.parseInt(Number.parseInt(item['quantity']).toFixed(2));
      total += Number.parseInt(Number.parseInt(item['estimatiedPrice']).toFixed(2));
    });
    planningItems.forEach(item => {
      totalHours += Number.parseInt(Number.parseInt(item['quantity']).toFixed(2));
      total += Number.parseInt(Number.parseInt(item['estimatiedPrice']).toFixed(2));
    });
    return { totalHours, total };
  }
  private getTravelTotals() {
    let total;
    total = 0;
    total += this.travelExpenseGroup.controls['airfare'].value;
    total += this.travelExpenseGroup.controls['auto'].value;
    total += this.travelExpenseGroup.controls['hotel'].value;
    total += this.travelExpenseGroup.controls['gas'].value;
    total += this.travelExpenseGroup.controls['meals'].value;
    total += this.travelExpenseGroup.controls['other'].value;
    total += this.travelExpenseGroup.controls['travelHours'].value * this.travelExpenseGroup.controls['perHourCharge'].value;
    return total;
  }
  private getMaterialItemsTotal() {
    let total: any;
    total = 0;
    this.materialItems.controls.forEach(c => {
      if (c) {
        let sellItem = c.get('sell');
        if (sellItem) {
          const sellValue: any = sellItem['value'];
          total += Number.parseInt(sellValue);
        }
      }
    });
    return total;
  }
  calculateTotalsForView(data?: any) {
    let totalHours: any;
    let total: any;
    const priceAdjustment = data ? data['priceAdjustment'] : (this.serviceEstimateForm ? this.serviceEstimateForm.controls['priceAdjustment'].value : 0);
    if (priceAdjustment) {
      total += Number.parseInt(Number.parseInt(priceAdjustment).toFixed(2));
    }
    total = 0;
    totalHours = 0;
    totalHours = 0;
    total += this.getMaterialItemsTotal();
    //total += this.getTravelTotals();
    total += this.getPhaseItemsTotal().total;
    total += this.getBusinessPartnerTotal().total;
    totalHours += this.getPhaseItemsTotal().totalHours;
    totalHours += this.getBusinessPartnerTotal().totalHours;

    return { total, totalHours };
  }


}
