import { Component, OnInit } from '@angular/core';
import { CityDataSource } from '../../models/city.model';
import { LocationService } from '../../location.service';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.scss']
})
export class CityListComponent implements OnInit {
  displayedColumns: string[];
  cities: CityDataSource;

  constructor(private locationService: LocationService) { }

  ngOnInit() {
    this.displayedColumns = [
      'name',
      'state',
      'actions'
    ];
  }
  deleteCity(id: string) {

  }

}
