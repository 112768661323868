<mat-card>
  <mat-card-content>
    <a id="appRoleCreate" mat-raised-button [routerLink]="['create']" color="primary"
       matTooltip="Create App Role" [matTooltipPosition]="'right'">
      <mat-icon class="add-icon">add</mat-icon>
      Create App Role
    </a>
    <div [class.loading]="!isLoading" style="position: relative; margin:auto;width:calc(50% + 225px)">
      <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
    </div>
    <br />
    <div class="col-md-12" [class.loading]="isLoading">
      <fieldset>
        <legend>
          Search Selection
        </legend>
        <form [formGroup]="filterForm">
          <mat-form-field>
            <mat-label>Filter By Name</mat-label>
            <input matInput formControlName="displayNameFilter" type="text" id="displayName">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Filter By State</mat-label>
            <mat-select placeholder="Filter by State" formControlName="stateFilter" id="roleState"
                        disableOptionCentering>
              <mat-option [value]="true">Enabled</mat-option>
              <mat-option [value]="false">Disabled</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters" id="resetBtn">
            <mat-icon>clear</mat-icon>
          </button>
        </form>
        <div class="error align-margin" *ngIf="!canBeDeleted">Selected Application Role cannot be deleted</div>
      </fieldset>
    </div>
    <br />
    <div class="col-md-12">
      <mat-table #table matSort class="apply-border" aria-label="App Roles Table" [class.loading]="isLoading">
        <ng-container matColumnDef="displayName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Display Name</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.displayName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="allowedMemberTypes">
          <mat-header-cell *matHeaderCellDef>Allowed Member Types</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.allowedMemberTypes}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.value}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.isEnabled? 'Enabled' : 'Disabled'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button id="kebabMenu" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" [attr.data-id]="element.id">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item color="default" type="button" [routerLink]="['edit',element.id]">
                <mat-icon>edit</mat-icon>
                <span>Edit/View</span>
              </button>
              <button mat-menu-item (click)="deleteRecord(element.id)">
                <mat-icon>delete_outline</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator #paginator [class.loading]="isLoading"
                   [length]="appRoleDataSource?.data.length" [pageIndex]="0" [pageSize]="50"
                   [pageSizeOptions]="[5, 25, 50, 100]">
    </mat-paginator>
  </mat-card-content>
</mat-card>
