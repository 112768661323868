import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-nested-tabs',
  templateUrl: './admin-nested-tabs.component.html',
  styleUrls: ['./admin-nested-tabs.component.scss']
})
export class AdminNestedTabsComponent {

}
