import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temp-approver',
  templateUrl: './temp-approver.component.html',
  styleUrls: ['./temp-approver.component.scss']
})
export class TempApproverComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
