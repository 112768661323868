<mat-card>
  <form [formGroup]="appRoleFormGroup" #form="ngForm" (ngSubmit)="createAppRole()" *ngIf="appRoleFormGroup">
    <mat-card-content>
      <mat-form-field>
        <input matInput type="text" formControlName="displayName" placeholder="Display Name" id='displayName' />
        <mat-error *ngIf="appRoleFormGroup.controls['displayName'].hasError('required')">A display name is required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <textarea matInput formControlName="description" columns="10"
                  placeholder="Description"></textarea>
        <mat-error *ngIf="appRoleFormGroup.controls['description'].hasError('required')">A description is required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" formControlName="value" placeholder="Value" id='value' />
        <mat-error *ngIf="appRoleFormGroup.controls['value'].hasError('required')">A value is required</mat-error>
        <mat-error *ngIf="appRoleFormGroup.controls['value'].hasError('duplicateValue')">Duplicate value not allowed</mat-error>
        <mat-error *ngIf="appRoleFormGroup.controls['value'].hasError('whitespace')">No spaces allowed</mat-error>
      </mat-form-field>

      <mat-form-field class="col-md-3">
        <mat-select formControlName="allowedMemberTypes" placeholder="Select Member Type" disableOptionCentering>
          <mat-option value="User">Users/Groups</mat-option>
          <mat-option value="Application">Applications</mat-option>
          <mat-option value="Both">Both Users/Groups + Applications</mat-option>
        </mat-select>
        <mat-error *ngIf="appRoleFormGroup.controls['allowedMemberTypes'].hasError('required')">An Member Type is required</mat-error>
      </mat-form-field>

      <div class="col-md-3">
        <mat-checkbox formControlName="isEnabled">Enable this Role?</mat-checkbox>
      </div>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" [disabled]="!appRoleFormGroup.valid">Save</button>
    </mat-card-actions>
  </form>
</mat-card>
