<mat-card *ngIf="states">
  <mat-card-content *ngIf="businessPartnerForm">
    <form *ngIf="filteredRequestorEmployees" [formGroup]="businessPartnerForm" (ngSubmit)="submitPartner()">
      <div class="col-md-12">
        <mat-form-field>
          <input id="bpLegalName" matInput formControlName="businessPartnerLegalName"
            placeholder="Business Partner Legal Name" />
          <mat-error *ngIf="businessPartnerForm.controls['businessPartnerLegalName'].hasError('required')">
            Mandatory Fields
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Website URL" type="url" formControlName="websiteUri" />
          <mat-error *ngIf="businessPartnerForm.controls['websiteUri'].hasError('required')">
            The website is
            required
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-select formControlName="requestor" placeholder="Requestor" disableOptionCentering>
            <mat-option *ngFor="let opt of filteredRequestorEmployees" [value]="opt.id" [disabled]="!opt.active">
              {{opt.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="businessPartnerForm.controls['requestor'].hasError('required')">
            Mandatory Fields
          </mat-error>
        </mat-form-field>
        <!-- <mat-form-field>
      <mat-select multiple formControlName="requestorEmployeeId" placeholder="Requestor" multiple>
          <mat-option *ngFor="let opt of filteredRequestorEmployees" [value]="opt.employeeId">
              {{opt.firstName + ' ' + opt.lastName}}
          </mat-option>
      </mat-select>
  </mat-form-field> -->

        <mat-form-field *ngIf="solutionPilars">
          <mat-select formControlName="solutionPilarsBusinessPartners" placeholder="Solution Pillar" multiple
            disableOptionCentering>
            <mat-option *ngFor="let solutionPilar of solutionPilars" [value]="solutionPilar.solutionPilarId">
              {{solutionPilar.solutionPilarName}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="tags">
          <mat-select formControlName="businessPartnerTags" placeholder="Tag" multiple disableOptionCentering
            id="bpTag">
            <mat-option *ngFor="let tag of tags" [value]="tag.id">
              {{tag.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select matInput placeholder="Serviceable States" multiple disableOptionCentering
            formControlName="serviceableStates" id="serviceableStates">
            <mat-option *ngFor="let state of states" [value]="state.stateId">
              {{state.abbreviation}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input type="text" placeholder="Select Cities" aria-label="Select Cities" matInput
            formControlName="serviceableCities" [matAutocomplete]="auto"
            [value]="displayAutoCompleteForServiceableCity()"
            [disabled]="!businessPartnerForm.get('serviceableStates')?.value || businessPartnerForm.get('serviceableStates')?.value.length === 0">
        </mat-form-field>


        <mat-autocomplete #auto="matAutocomplete">
          <div class="sticky-input">
            <input type="text" placeholder="Search cities..." mat-input class="city-filter"
              (input)="onInputChange($event.target.value)" id="servicableCities">

          </div>
          <mat-option class="fake-disable" [disabled]="true" *ngFor="let city of filteredServiceableCities"
            [value]="city.cityId" (click)="test()">
            <div (click)="$event.stopPropagation()">

              <mat-checkbox [checked]="city.selected" (change)="optionClicked($event, city)">
                {{ city.name }}
              </mat-checkbox>
            </div>
          </mat-option>
          <mat-option disabled *ngIf="filteredServiceableCities?.length === 0">
            No cities found
          </mat-option>
        </mat-autocomplete>

        <mat-form-field>
          <input matInput formControlName="supplierNumber" placeholder="Supplier Number" />
        </mat-form-field>

        <mat-form-field>
          <input disabled="true" matInput formControlName="scoreCard" placeholder="Score Card" />
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Status" formControlName="businessPartnerStatus" disableOptionCentering>
            <mat-option [value]="0">In Process </mat-option>
            <mat-option [value]="1">Approved - In Good Standing</mat-option>
            <mat-option [value]="3">Approved - Awaiting Renewed COI</mat-option>
            <mat-option [value]="2">Do Not Use/Accept</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <div style="display: inline-block;">
          <h3>
            Contact Info
            <button id="addContactInfoBtn" mat-icon-button type="button" (click)="addContactItem()">
              <mat-icon>add</mat-icon>
            </button>
          </h3>
          <div class="col-md-12">
          </div>
          <div [formArrayName]="'contacts'" class="col-md-12">
            <div class="" *ngFor="let item of contacts.controls;let i = index" [formGroupName]="i">
              <mat-form-field>
                <input matInput type="text" placeholder="First Name" formControlName="firstName" />
                <mat-error *ngIf="item.controls['firstName'].hasError('required')">
                  Mandatory Fields
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="text" placeholder="Last Name" formControlName="lastName" />
                <mat-error *ngIf="item.controls['lastName'].hasError('required')">
                  Mandatory Fields
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="text" placeholder="Title/Role" formControlName="title" />
              </mat-form-field>
              <mat-form-field>
                <input type="email" matInput formControlName="email" placeholder="Email" />
              </mat-form-field>
              <mat-form-field>
                <input maxlength="11" type="tel" matInput formControlName="primaryPhoneNumber"
                  placeholder="Primary Phone Number" />
                <mat-error *ngIf="item.controls['primaryPhoneNumber'].hasError('required')">
                  Mandatory Fields
                </mat-error>
                <mat-error *ngIf="item.controls['primaryPhoneNumber'].hasError('pattern')">
                  Phone number must be at least 10 digits
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input type="tel" matInput formControlName="secondaryPhoneNumber"
                  placeholder="Secondary Phone Number" />
                <mat-error *ngIf="item.controls['secondaryPhoneNumber'].hasError('pattern')">
                  Phone number must be at least 10 digits
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <input type="tel" matInput formControlName="faxNumber" placeholder="Fax Number" />
              </mat-form-field>
              <mat-checkbox formControlName="isPrimaryContact">Primary Contact?</mat-checkbox>
              <button mat-icon-button (click)="removeContact(i)" type="button">
                <mat-icon>close</mat-icon>
              </button>

              <!-- <mat-form-field>
                  <input matInput formControlName="websiteUrl" placeholder="Website Url" />
              </mat-form-field> -->
            </div>
          </div>
        </div>
        <hr>
      </div>

      <div class="col-md-12">
        <h3>
          Primary Location
        </h3>

        <div class="col-md-12" formGroupName="primaryLocation"
          *ngIf="!primaryLocation.controls['isInternational'].value == true">
          <mat-checkbox id="isInternationalCheckbox" class="full-width"
            formControlName="isInternational">International?</mat-checkbox>
          <mat-form-field>
            <input matInput placeholder="Street" formControlName="streetOne" />
            <mat-error *ngIf="primaryLocation.controls['streetOne'].hasError('required')">
              Mandatory Fields
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Secondary Street" formControlName="streetTwo" />
            <mat-error *ngIf="primaryLocation.controls['streetTwo'].hasError('required')">
              Mandatory Fields
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-select id="bpStateDropdown" #stateInp matInput placeholder="State" formControlName="stateId"
              disableOptionCentering>
              <mat-option *ngFor="let state of states" [value]="state.stateId">
                {{state.abbreviation}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="primaryLocation.controls['stateId'].hasError('required')">
              Mandatory Fields
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input id="bpCityDropdown" type="text" placeholder="Select a City" aria-label="City" matInput
              formControlName="cityId" [matAutocomplete]="autoCompleteCity" autocomplete="off" />
            <mat-autocomplete autoActiveFirstOption #autoCompleteCity="matAutocomplete" autocomplete="off"
              [panelWidth]="180" [displayWith]="displayAutoCompleteForCity.bind(this)">
              <mat-option *ngFor="let city of citiesAutoCompleteOptions | async" [value]="city.cityId"
                [id]="city.cityId" [matTooltip]="city.name">
                {{city.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="primaryLocation.controls['cityId'].hasError('required')">
              Mandatory Fields
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input type="string" matInput placeholder="Zip Code" formControlName="zipCode" id="zipCode" />
            <mat-error *ngIf="primaryLocation.controls['zipCode'].hasError('required')">
              Mandatory Fields
            </mat-error>
            <mat-error
              *ngIf="!primaryLocation.controls['zipCode'].hasError('maxlength') && primaryLocation.controls['zipCode'].hasError('pattern')">
              Please enter a valid zip code either in xxxxx or xxxxx-xxxx digits format
            </mat-error>
            <mat-error *ngIf="primaryLocation.controls['zipCode'].hasError('maxlength')">
              Max Limit 10 Characters only
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12" formGroupName="primaryLocation"
          *ngIf="primaryLocation.controls['isInternational'].value == true">
          <mat-checkbox id="isInternationalCheckbox" class="full-width"
            formControlName="isInternational">International?</mat-checkbox>
          <mat-form-field>
            <input matInput placeholder="Address Line1" formControlName="streetOne" />
            <mat-error *ngIf="primaryLocation.controls['streetOne'].hasError('required')">
              Mandatory Fields
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Address Line2" formControlName="streetTwo" />
            <mat-error *ngIf="primaryLocation.controls['streetTwo'].hasError('required')">
              Mandatory Fields
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="City" formControlName="city" />
            <mat-error *ngIf="primaryLocation.controls['city'].hasError('required')">
              Mandatory Fields
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Province/State/County" formControlName="state" />
            <mat-error *ngIf="primaryLocation.controls['state'].hasError('required')">
              Mandatory Fields
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input type="string" matInput placeholder="Zip/Postal Code" formControlName="zipCode" id="zipCode" />
            <mat-error *ngIf="primaryLocation.controls['zipCode'].hasError('required')">
              Mandatory Fields
            </mat-error>
            <mat-error
              *ngIf="!primaryLocation.controls['zipCode'].hasError('maxlength') && primaryLocation.controls['zipCode'].hasError('pattern')">
              Please enter a valid zipcode
            </mat-error>
            <mat-error *ngIf="primaryLocation.controls['zipCode'].hasError('maxlength')">
              Max Limit 10 Characters only
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Country" formControlName="country" />
            <mat-error *ngIf="primaryLocation.controls['country'].hasError('required')">
              Mandatory Fields
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-12">
        <div>
          <h3>
            Additional Locations
            <button mat-icon-button id="addLocationBtn" type="button" (click)="addAddressItem()">
              <mat-icon>add</mat-icon>
            </button>
          </h3>
          <div [formArrayName]="'additionalLocations'" class="col-md-12">
            <div class="" *ngFor="let item of addresses.controls;let i = index" [formGroupName]="i">
              <br>
              <app-business-partner-location-item [formArray]="addresses" [index]="i">
              </app-business-partner-location-item>
              <button mat-icon-button (click)="removeAddressItem(i)" type="button">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <hr>
      </div>
      <!-- <input type="hidden" [value]="stateInp.value" formControlName="businessPartnerLocationState" placeholder="State" /> -->
      <!-- <div class="col-md-12">
          <mat-form-field>
              <mat-select matInput formControlName="businessPartnerResourceType" placeholder="Resource Type">
                  <mat-option [value]="0">MIT</mat-option>
                  <mat-option [value]="1">BP</mat-option>
              </mat-select>
          </mat-form-field>
      </div> -->
      <div class="col-md-12">
        <div>
          <h3>
            Agreements
            <button mat-icon-button type="button" (click)="addAgreementItem()">
              <mat-icon>add</mat-icon>
            </button>
          </h3>
          <div [formArrayName]="'agreements'" class="col-md-12">
            <div class="" *ngFor="let item of businessAgreements.controls;let i = index" [formGroupName]="i">
              <mat-form-field>
                <mat-select matInput formControlName="agreementType" placeholder="Agreement Type"
                  disableOptionCentering>
                  <mat-option [value]="0">Subcontractor Agreement</mat-option>
                  <mat-option [value]="1">Reverse Subcontractor Agreement</mat-option>
                  <mat-option [value]="2">Non-Disclosure Agreement</mat-option>
                  <mat-option [value]="3">Mutual Non-Disclosure Agreement</mat-option>
                  <mat-option [value]="4">Reseller Agreement</mat-option>
                  <mat-option [value]="5">Partner Agreement</mat-option>
                  <mat-option [value]="6">Sub Provided Agreement</mat-option>
                  <mat-option [value]="7">Other Agreement</mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field>
                <input matInput formControlName="dateOnAgreement" [matDatepicker]="dateOfAgreement" autocomplete="off"
                  placeholder="Date On Agreement" />
                <mat-datepicker-toggle matSuffix [for]="dateOfAgreement"></mat-datepicker-toggle>
                <mat-datepicker #dateOfAgreement></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <input matInput formControlName="dateAgreementSent" [matDatepicker]="dateAgreementSent"
                  autocomplete="off" placeholder="Date Agreement Sent" />
                <mat-datepicker-toggle matSuffix [for]="dateAgreementSent"></mat-datepicker-toggle>
                <mat-datepicker #dateAgreementSent></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <input matInput formControlName="dateSignedByMit" autocomplete="off"
                  [matDatepicker]="dateSignedByMitPicker" placeholder="Date Signed By MIT" />
                <mat-datepicker-toggle matSuffix [for]="dateSignedByMitPicker"></mat-datepicker-toggle>
                <mat-datepicker #dateSignedByMitPicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <mat-select formControlName="signer" placeholder="Approved By" disableOptionCentering>
                  <mat-option *ngFor="let opt of filteredSignedByEmployees" [value]="opt.id" [disabled]="!opt.active">
                    {{opt.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- <mat-form-field>
                  <mat-select formControlName="signByEmployeeId" placeholder="Signed By">
                      <mat-option *ngFor="let opt of filteredSignedByEmployees" [value]="opt.employeeId">
                          {{opt.firstName + ' ' + opt.lastName}}
                      </mat-option>
                  </mat-select>
              </mat-form-field> -->
              <button mat-icon-button type="button" (click)="removeAgreementItem(i)">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <hr>
      </div>

      <div class="col-md-12">

        <mat-form-field>
          <input matInput formControlName="generalLiabilityExpirationDate" autocomplete="off"
            [matDatepicker]="insuranceDateOfCertificatePicker" placeholder="COI General Liability Exp" />
          <mat-datepicker-toggle matSuffix [for]="insuranceDateOfCertificatePicker"></mat-datepicker-toggle>
          <mat-datepicker #insuranceDateOfCertificatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input matInput [matDatepicker]="workmansCompPicker" placeholder="COI Workers Comp Exp" autocomplete="off"
            formControlName="workersCompExpiration" />
          <mat-datepicker-toggle matSuffix [for]="workmansCompPicker"></mat-datepicker-toggle>
          <mat-datepicker #workmansCompPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <span matPrefix>$&nbsp;</span>
          <input matInput currencyMask placeholder="COI Liability Limit" formControlName="liabilityLimit" />
        </mat-form-field>

        <mat-form-field>
          <span matPrefix>$&nbsp;</span>
          <input matInput currencyMask placeholder="COI Workers Comp Limit" formControlName="workersCompLimit" />
        </mat-form-field>

        <mat-form-field>
          <input matInput formControlName="w9Date" [matDatepicker]="dateOfW9" placeholder="Select Date of W8/W9"
            id="dateOfW9" autocomplete="off" />
          <mat-datepicker-toggle matSuffix [for]="dateOfW9"></mat-datepicker-toggle>
          <mat-datepicker #dateOfW9></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <h4>Cyber Risk Insurance</h4>
        <mat-form-field>
          <input matInput [matDatepicker]="cyberSecurityExpirationDate" placeholder="Cyber Risk Insurance Exp"
            autocomplete="off" formControlName="cyberSecurityExpirationDate" />
          <mat-datepicker-toggle matSuffix [for]="cyberSecurityExpirationDate"></mat-datepicker-toggle>
          <mat-datepicker #cyberSecurityExpirationDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <span matPrefix>$&nbsp;</span>
          <input matInput currencyMask placeholder="Cyber Risk Insurance Liability Limit"
            formControlName="cyberSecurityLiabilityLimit" />
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field class="wide">
          <textarea matInput formControlName="notes" placeholder="Notes" rows="10"></textarea>
        </mat-form-field>
      </div>

      <button mat-raised-button type="submit" [disabled]="disableSubmit">Submit</button>
    </form>
  </mat-card-content>
</mat-card>
