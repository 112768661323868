import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DashboardService } from '../dashboard.service';
import { DashboardRequestModel } from '../models/dashboard-request.model';

@Component({
  selector: 'app-dashboard-landing-page',
  templateUrl: './dashboard-landing-page.component.html',
  styleUrls: ['./dashboard-landing-page.component.scss']
})
export class DashboardLandingPageComponent implements OnInit {
  projectsData: any;
  serviceEstimateData: any;
  seComponentData: any;
  loading: boolean;
  serviceRoles: any;
  activeProjectsData: any;
  dashboardRequestFromGroup: UntypedFormGroup;

  constructor(private dashboardService: DashboardService, private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.loading = true;
    this.dashboardRequestFromGroup = this.fb.group({
      'noOfDays': ['90'],
      'startDate': [''],
      'endDate': ['']
    });
    this.getProjectData(this.dashboardRequestFromGroup.value);
    this.formValueChanges();
  }

  getProjectData(dashboardRequestModel: DashboardRequestModel) {
    let seInfo = this.dashboardService.getSEInfo(dashboardRequestModel);
    let secInfo = this.dashboardService.getSECInfo(dashboardRequestModel);
    let projectInfo = this.dashboardService.getProjectInfo(dashboardRequestModel);
    let activeProjectsData = this.dashboardService.getActiveProjects(dashboardRequestModel);
    forkJoin([projectInfo, seInfo, secInfo, activeProjectsData])
      .subscribe((results: any) => {
        this.projectsData = results[0];
        this.serviceEstimateData = results[1];
        this.seComponentData = results[2];
        this.serviceRoles = this.fetchServiceRoles();
        this.activeProjectsData = results[3];
        this.loading = false;
      });
  }

  filterProjectData(event: any) {
    this.dashboardRequestFromGroup.patchValue({ startDate: null, endDate: null });
    this.getProjectData(this.dashboardRequestFromGroup.value);
  }

  formValueChanges() {
    this.dashboardRequestFromGroup.controls['startDate'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.checkForValidDateRange();
      });
    this.dashboardRequestFromGroup.controls['endDate'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.checkForValidDateRange();
      });
  }

  checkForValidDateRange() {
    let startDate = this.dashboardRequestFromGroup.controls['startDate'];
    let endDate = this.dashboardRequestFromGroup.controls['endDate'];
    if (startDate.value && endDate.value && startDate.valid && endDate.valid) {
      this.dateRangeChange(startDate.value, endDate.value);
    }
  }

  dateRangeChange(startDate: Date, endDate: Date) {
    this.dashboardRequestFromGroup.patchValue({ noOfDays: null });
    let dashboardRequestModel: DashboardRequestModel = Object.assign({}, this.dashboardRequestFromGroup.value);
    dashboardRequestModel.startDate = new Date(startDate).toLocaleDateString('en-US');
    dashboardRequestModel.endDate = new Date(endDate).toLocaleDateString('en-US');
    this.getProjectData(dashboardRequestModel);
  }

  fetchServiceRoles() {
    let roles = [...new Map(this.seComponentData?.selfApprovedServiceRoles.concat(this.seComponentData?.pendingApprovalServiceRoles)
      .map((item: any) =>
        [item['serviceRoleId'], item])).values()];
    return roles.map((role: any) => ({
      serviceRoleId: role.serviceRoleId,
      serviceRoleName: role.serviceRoleName
    })).sort((a, b) => (a.serviceRoleName > b.serviceRoleName ? 1 : -1));
  }

  resetFilters() {
    this.dashboardRequestFromGroup.reset({ noOfDays: '90' });
    this.getProjectData(this.dashboardRequestFromGroup.value);
  }
}
