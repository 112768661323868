<div class="fluid-container">
  <mat-card *ngIf="schedule">
    <form [formGroup]="scheduleForm" (ngSubmit)="addSchedule()">
      <mat-card-content *ngIf="schedule">
        <div class="col-md-12">
          <button mat-raised-button color="primary" (click)="addScheduleItemToFormArray()">Add Item</button>
        </div>
        <br>
        <div class="col-md-12">
          <mat-form-field>
            <input matInput type="text" formControlName="vehicleMaintenanceScheduleName" placeholder="Schedule Name" />
          </mat-form-field>
        </div>
        <br>
        <hr>
        <div class="row" formArrayName="vehicleMaintenanceScheduleItems" *ngFor="let item of getScheduleItemsArray(); let i = index">
          <div class="col-md-12" [formGroupName]="i">
            <div class="col-md-2">
              <button title="Remove Item" aria-label="Remove Item" color="warn" mat-button mat-icon-button (click)="removeScheduleItem(i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
            <div class="col-md-8">
              <div class="col-md-12">
                <mat-form-field>
                  <input placeholder="Item Name" matInput type="text" formControlName="vehicleMaintenanceItemName" />
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field>
                  <input placeholder="Mileage Interval" matInput type="text" formControlName="vehcileMaintenanceItemMileageInterval" />
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field>
                  <input placeholder="Date Interval In Days" matInput type="text" formControlName="vehcileMaintenanceItemDateIntervalInDays"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field>
                  <input placeholder="Next Date Interval In Days" matInput type="text" formControlName="vehcileMaintenanceItemNextDateIntervalInDays"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field>
                  <input placeholder="Next Mileage Interval" matInput type="text" formControlName="vehicleMaintenanceItemNextMileageInterval"
                  />
                </mat-form-field>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" type="submit">Save</button>
      </mat-card-actions>

    </form>
  </mat-card>
</div>