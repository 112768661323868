<mat-card *ngIf="storageForm">
  <h3>{{storageId? 'Update': 'Create'}} Storage</h3>
  <form [formGroup]="storageForm" #form="ngForm">
    <div>
      <div>
        <mat-card-content>
          <mat-grid-list cols="2" rowHeight="4:1">
            <mat-grid-tile>
              <mat-form-field>
                <mat-select placeholder="Storage Type" formControlName="storageType" disableOptionCentering>
                  <mat-option [value]="0"> HHD </mat-option>
                  <mat-option [value]="1"> SSD </mat-option>
                </mat-select>
                <mat-error *ngIf="wrkloadForm.controls['storageType'].hasError('required')">
                  Storage Type is required
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <input matInput formControlName="capacity" placeholder="Capacity"/>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <input matInput formControlName="cost" placeholder="Cost" />
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
        <mat-card-actions>
          <button type="submit" mat-raised-button [disabled]="!storageForm.valid " (click)="saveStorage()">Save</button>
          <button type="button" mat-raised-button mat-dialog-close>Cancel</button>
        </mat-card-actions>
      </div>
    </div>
  </form>
</mat-card>
