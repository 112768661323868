<mat-card *ngIf="insurances">
  <mat-card-content *ngIf="vehicle">
    <p>
      <button mat-mini-fab type="button" id="editButtonId" aria-label="Edit" title="Edit" [disabled]="isEditing"
        (click)="toggleEdit('edit')" color="primary">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-mini-fab type="button" aria-label="Cancel" title="Cancel" [disabled]="!isEditing"
        (click)="toggleEdit('cancel')">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-mini-fab type="button" aria-label="Save" title="Save" [disabled]="!isSaveable"
        (click)="updateVehicle()">
        <mat-icon>save</mat-icon>
      </button>
    </p>
    <p *ngIf="isFinishUpdating" class="returnMessage" [class.error]="!errorOccurred" [class.success]="errorOccurred">
      {{finishUpdatingMessage}}
    </p>
    <div class="col-md-12">
      <mat-form-field>
        <input matInput type="text" [(ngModel)]="vehicle.vehicleNumber" placeholder="Vehicle Number" [disabled]="true"
          name="formStuff" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" [(ngModel)]="vehicle.vin" placeholder="Vin" [disabled]="true" />
      </mat-form-field>
      <mat-form-field>
        <form [formGroup]="vehicleForm">
          <input matInput type="text" id="vColor" [(ngModel)]="vehicle.vehicleColor" placeholder="Color"
            [readonly]="!isEditing" nameme="formStuff" formControlName="vehicleColor" />
        </form>
      </mat-form-field>
      <mat-form-field>
        <form [formGroup]="vehicleForm">
          <input id="vehEzPass" matInput [maxLength]="9" type="text" [(ngModel)]="vehicle.vehicleEzPass"
            placeholder="EZ Pass" [readonly]="!isEditing" name="formStuff" formControlName="vehicleEzPass" />
        </form>
      </mat-form-field>
    </div>
    <form [formGroup]="vehicleForm">
      <div class="col-md-12">
        <mat-form-field>
          <mat-select placeholder="Assigned To" [(ngModel)]="vehicle.assignedEmployeeId" [disabled]="!isEditing"
            name="formStuff" formControlName="vehicleAssignedToEmployeeId" disableOptionCentering>
            <mat-option [value]="''">None</mat-option>
            <mat-option *ngFor="let employee of employees" [value]="employee.vehicleDriverId">{{employee.firstName}}
              {{employee.lastName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="models">
          <mat-select placeholder="Vehicle Model" [(ngModel)]="vehicle.vehicleModelId" [disabled]="!isEditing"
            name="formStuff" formControlName="vehicleModelId" disableOptionCentering>
            <mat-option *ngFor="let model of models" [value]="model.vehicleModelId">{{model.vehcileModelYear}}
              {{model.manufacturerName}} {{model.vehicleModelName}} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="valvoCard">
          <input type="text" id="valvoCard" matInput [maxLength]="6" placeholder="Valvoline Card Number"
            [(ngModel)]="valvoCard.vehicleCardNumber" formControlName="vehicleValvolineCardNumber"
            [readonly]="!isEditing" />
        </mat-form-field>
        <mat-form-field *ngIf="valvoCard">
          <input type="text" id="valvoPin" matInput [maxLength]="4" placeholder="Valvoline Card Pin"
            [(ngModel)]="valvoCard.valvolinePin" formControlName="vehicleValvolineCardPinNumber"
            [readonly]="!isEditing" />
        </mat-form-field>
      </div>
    </form>
    <div class="col-md-12">
      <mat-form-field>
        <input matInput type="text" [(ngModel)]="vehicle.vehicleMileageCurrent" placeholder="Current Miles"
          [disabled]="true" name="formStuff" />
      </mat-form-field>
      <mat-form-field>
        <form [formGroup]="vehicleForm">
          <input matInput [maxLength]="4" type="text" id="wrPrompId" [(ngModel)]="vehicle.vehicleWrightPromptId"
            placeholder="Wright Prompt Id" [readonly]="!isEditing" name="formStuff"
            formControlName="vehicleWrightPromptId" />
        </form>
      </mat-form-field>
      <mat-form-field *ngIf="gasCard">
        <form [formGroup]="vehicleForm">
          <input type="text" id="gasCard" matInput [maxLength]="4" placeholder="Gas Card Number"
            [(ngModel)]="gasCard.vehicleCardNumber" formControlName="vehicleGasCardNumber" [readonly]="!isEditing" />
        </form>
      </mat-form-field>
    </div>
    <form [formGroup]="vehicleForm">
      <div class="col-md-12">
        <mat-form-field>
          <input type="text" id="vehiclePin" matInput [maxLength]="4" placeholder="PIN" [(ngModel)]="vehicle.vehiclePin"
            formControlName="vehiclePin" [readonly]="!isEditing" />
        </mat-form-field>
        <mat-form-field>
          <input type="text" id="aaaMembership" matInput [maxLength]="10" placeholder="AAA Membership"
            [(ngModel)]="vehicle.vehicleAAAMembership" formControlName="vehicleAAAMembership" [readonly]="!isEditing" />
        </mat-form-field>
        <mat-form-field>
          <mat-select [disabled]="!isEditing" #stateInp id="vState" matInput placeholder="State"
            [(ngModel)]="vehicle.vehicleRegionStateId" formControlName="vehicleRegionStateId"
            disableOptionCentering >
            <mat-option *ngFor="let state of states" [value]="state.stateId" >
              {{state.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select [disabled]="!isEditing"  id="vCity" matInput placeholder="City"
            [(ngModel)]="vehicle.vehicleRegionCityId" formControlName="vehicleRegionCityId"
            disableOptionCentering>
            <mat-option *ngFor="let city of cities" [value]="city.cityId">
              {{city.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="col-md-12">
      <mat-form-field>
        <input matInput type="text" [(ngModel)]="vehicle.vehicleRegistration.licensePlateNumber" [disabled]="!isEditing"
          name="licPlateNumber" placeholder="License Plate Number" />
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field>
        <input matInput [readonly]="!isEditing" [matDatepicker]="picker"
          [(ngModel)]="vehicle.vehicleRegistration.expirationDate" placeholder="Registration Expiration Date"
          name="licExpDate">
        <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="!isEditing"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field>
        <input matInput type="text" [(ngModel)]="vehicle.vehicleRegistration.licensePlateState"
          [readonly]="!isEditing" name="licPlateState" placeholder="License Plate State" />
      </mat-form-field>
    </div>

    <form [formGroup]="vehicleForm">
      <div class="col-md-12">
        <mat-form-field *ngIf="records">
          <mat-select placeholder="Vehicle Maintenance Schedule" [(ngModel)]="vehicle.vehicleMaintenanceScheduleId"
            [disabled]="!isEditing" disableOptionCentering name="formStuff"
            formControlName="vehicleMaintenanceScheduleId">
            <mat-option *ngFor="let record of records" [value]="record.vehicleMaintenanceScheduleId">
              {{record.vehicleMaintenanceScheduleName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="col-md-12">
      <h3>Mileage</h3>
      <mat-table matSort id="VehicleMileageTable" #table [dataSource]="vehicleUsages">

        <ng-container matColumnDef="vehicleUsageTripMileage">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Mileage </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.vehicleUsageMileageEnd}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vehicleUsageNote">
          <mat-header-cell id="noteHeader" *matHeaderCellDef> Notes </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.vehicleUsageNote}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vehicleUsageDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Usage Date
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.vehicleUsageDate | date:'shortDate'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vehicleActions">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button id="kebabMenu" [matMenuTriggerFor]="menu" aria-label="kebab menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item type="button"
                [routerLink]="['/fleetmanagement','vehicles', 'mileage','update',element.vehicleUsageId]"
                [disabled]="!isEditing ? 'disabled' : null">
                <mat-icon> cached </mat-icon>
                <span> Update </span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="usageColumns"></mat-header-row>
        <mat-row id="testRow" *matRowDef="let row; columns: usageColumns;"></mat-row>
      </mat-table>
      <div>
        <mat-paginator #paginator [length]="vehicleUsages?.data.length" [pageIndex]="0" [pageSize]="5"
          [pageSizeOptions]="[5, 25, 50, 100]">
        </mat-paginator>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>