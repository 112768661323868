import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceProjectPostSalesAssignedEmployeesService {

  constructor(private http: HttpClient) { }

  getServiceProjectPostSalesAssignedEmployee(id: string) {
    return this.http.get<any>(`/api/ServiceProjectPostSalesAssignedEmployees/${id}`);
  }
  getServiceProjectPostSalesAssignedEmployees() {
    return this.http.get<any>(`/api/ServiceProjectPostSalesAssignedEmployees`);
  }
  createServiceProjectPostSalesAssignedEmployee(serviceProjectPostSalesAssignedEmployee: any) {
    const body = JSON.stringify(serviceProjectPostSalesAssignedEmployee);
    return this.http.post<any>(`/api/ServiceProjectPostSalesAssignedEmployees`, body);
  }
  updateServiceProjectPostSalesAssignedEmployee(serviceProjectPostSalesAssignedEmployee: any) {
    const body = JSON.stringify(serviceProjectPostSalesAssignedEmployee);
    return this.http.post<any>(`/api/ServiceProjectPostSalesAssignedEmployees/${serviceProjectPostSalesAssignedEmployee.id}`, body);
  }
  deleteServiceProjectPostSalesAssignedEmployee(id: string) {
    return this.http.delete<any>(`/api/ServiceProjectPostSalesAssignedEmployees/${id}`);
  }
}
