import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AdminService } from '../../../admin/admin.service';
import { VehicleManagerService } from '../../../admin/vehicle-manager/vehicle-manager.service';
import { FleetMangementService } from '../../fleet-mangement.service';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  reportResult: any;
  selectedDrivers: Array<string>;
  drivers: Array<any>;
  manager: any;
  mileageReportFormGroup: UntypedFormGroup;

  @ViewChild('donwloadCsvLink', { static: true }) private donwloadCsvLink: ElementRef;
  constructor(private adminService: AdminService, private vmService: VehicleManagerService, private fleetService: FleetMangementService, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    // const managerAzureId = this.authService.loginResponse.profile.azureAdId;
  }
  getReport() {
    this.fleetService.getVehicleAuditReport()
      .subscribe(res => {
          this.reportResult = res;
          const fileText = this.fleetService.convertToCsv(JSON.stringify(this.reportResult));
          const blob = new Blob([fileText], {
            type: 'text/csv'
          });
          const url = window.URL.createObjectURL(blob);
          const link = this.donwloadCsvLink.nativeElement;
          link.href = url;
          link.download = 'auditReport.csv';
          link.click();
          window.URL.revokeObjectURL(url);

      });

  }

}
