import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { ReportsService } from '../../reports/reports.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  reportOption: UntypedFormControl;
  currentRoute: any;
  routeUrlPath: string;
  constructor(private router: Router, private reportsService: ReportsService) { }

  ngOnInit() {
    this.reportOption = new UntypedFormControl();
    let routeUrl = this.router.url.split("/");
    this.routeUrlPath = routeUrl[routeUrl.length - 1];
    this.currentRoute = this.reportsService.getReportState(this.routeUrlPath);
    this.setData();
    this.reportOption.valueChanges.subscribe(val => {
      this.reportsService.setReportState(this.routeUrlPath, val);
    });
  }
  setData() {
    this.reportOption.setValue(this.currentRoute);
  }
}
