import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from '../clients.service';
import { ServiceRoleService } from '../../admin/service-role/service-role.service';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit {

  clientForm: UntypedFormGroup;
  clientRates: UntypedFormArray;
  clientId: string;
  serviceRoles: Array<any>;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private clientsService: ClientsService, private serviceRoleService: ServiceRoleService) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        this.clientId = params['id'];
        this.clientsService.getClient(this.clientId)
          .subscribe(data => {
            this.serviceRoleService.getServiceRoles()
              .subscribe(res => {
                this.serviceRoles = res;
              });

            this.clientRates = this.fb.array([]);
            if (data.clientRates) {
              data.clientRates.forEach((clientRate: any) => {
                this.addClientRate(clientRate);
              });
            }
            this.clientForm = this.fb.group({
              'id': [{ value: data.id, disabled: true }],
              'externalCustomerId': [{ value: data.externalCustomerId, disabled: true }],
              'customerType': [{ value: data.customerType, disabled: true }],
              'commonName': [{ value: data.commonName, disabled: true }],
              'legalName': [{ value: data.legalName, disabled: true }],
              'addressLine1': [{ value: data.addressLine1, disabled: true }],
              'addressLine2': [{ value: data.addressLine2, disabled: true }],
              'addressLine3': [{ value: data.addressLine3, disabled: true }],
              'city': [{ value: data.city, disabled: true }],
              'state': [{ value: data.state, disabled: true }],
              'zip': [{ value: data.zip, disabled: true }],
              'country': [{ value: data.country, disabled: true }],
              'industryClassType': [{ value: data.industryClassType, disabled: true }],
              'industryClassCode': [{ value: data.industryClassCode, disabled: true }],
              'industryClassDescription': [{ value: data.industryClassDescription, disabled: true }],
              'phone': [{ value: data.phone, disabled: true }],
              'email': [{ value: data.email, disabled: true }],
              'website': [{ value: data.website, disabled: true }],
              'territoryDescription': [{ value: data.territoryDescription, disabled: true }],
              'region': [{ value: data.region, disabled: true }],
              'dateCreatedExternally': [{ value: data.dateCreatedExternally, disabled: true }],
              'notes': [data.notes],
              'description': [data.description],
              'customSowTemplate': [data.customSowTemplate],
              'clientRates': this.clientRates
            });
          });
      });
  }

  addClientRate(mi?: any) {
    let item: UntypedFormGroup;
    if (mi) {
      item = this.fb.group({
        'id': [mi.id],
        'serviceRoleId': [mi.serviceRoleId, Validators.required],
        'clientId': [mi.clientId, Validators.required],
        'clientHourlyRate': [mi.clientHourlyRate, Validators.required]
      });
    } else {
      item = this.fb.group({
        'serviceRoleId': [null, Validators.required],
        'clientId': [this.clientId, Validators.required],
        'clientHourlyRate': [null, Validators.required]
      });
    }
    this.clientRates.push(item);

  }
  removeClientRate(index: number) {
    const id = this.clientRates.at(index).value['id'];
    if (id) {
      this.clientsService.removeClientRate(id)
        .subscribe(res => {
          this.clientRates.removeAt(index);
        });
    } else {
      this.clientRates.removeAt(index);
    }
  }
  updateClient() {
    if (this.clientForm.valid) {
      this.clientsService.updateClient(this.clientForm.getRawValue())
        .subscribe(res => {

        });
    }
  }
}
