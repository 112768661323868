import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BusinessPartnerScoreScoreCardService {

  constructor(private http: HttpClient) { }
  getBusinessPartnerScoreCards() {

    return this.http.get<any[]>(`/api/BusinessPartnerScoreCards/`);
  }
  getBusinessPartnerScoreCard(scoreCardId: string) {

    return this.http.get<any>(`/api/BusinessPartnerScoreCards/${scoreCardId}`);
  }
  getBusinessPartnerScoreCardsByBusinessPartnerId(businessPartnerId: string) {

    return this.http.get<any[]>(`/api/BusinessPartnerScoreCards/BusinessPartner/${businessPartnerId}`);
  }
  createBusinessPartnerScoreCard(businessPartnerScoreCard: any) {
    const body = JSON.stringify(businessPartnerScoreCard);
    return this.http.post<any>(`/api/BusinessPartnerScoreCards`, body);
  }
  deleteBusinessPartnerScoreCard(id: string) {
    return this.http.delete(`/api/BusinessPartnerScoreCards/${id}`);
  }
}
