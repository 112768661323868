import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ReportsComponent } from './reports.component';
import { BusinessPartnerReportsComponent } from './business-partner-reports/business-partner-reports.component';
import { BusinessPartnerLandingReportComponent } from './business-partner-landing-report/business-partner-landing-report.component';


@NgModule({
  declarations: [ReportsComponent, BusinessPartnerReportsComponent, BusinessPartnerLandingReportComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReportsRoutingModule
  ]
})
export class ReportsModule { }
