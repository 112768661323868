  <mat-card>
    <mat-card-header>
      <h2>MUP Help Videos</h2>
    </mat-card-header>
    <mat-card-content>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
      <mat-grid-list *ngIf="!isLoading"
        [cols]="breakpoint"
        rowHeight="14:10"
        [gutterSize]="'1.5rem'"
        (window:resize)="onResize($event)">
        <mat-grid-tile *ngFor="let video of videos">
          <app-video [video]="video"></app-video>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
  </mat-card>
