<div id="div-project-info" *ngIf="project">
  <mat-card>
    <cloud-migration-project-info [project]="project">
    </cloud-migration-project-info>
  </mat-card>
</div>
<p></p>
<div *ngIf="newEnvironment">
  <h4 class="col-md-2 card-label-text">Add Environment</h4>
  <div>
    <span style="font-weight: bold; color:red">{{message}}</span>
  </div>
  <div>
    <cloud-migration-environment-add id="add-env"
                                     [environment]="newEnvironment"
                                     [showAdd]=true
                                     (addEnvironmentEvent)="onAddEnvironment($event)">
      >
    </cloud-migration-environment-add>

  </div>
</div>
<p></p>
<div *ngIf="environmentList" id="discovery-environment-list">
  <mat-card>
    <cloud-migration-environment-list [projectId]="projectId"
                                      [environmentList]="environmentList"
                                      (editEnvironmentEvent)="onEditEnvironmentClick($event)"
                                      (editEnvironmentConfigEvent)="onEditEnvironmentConfigClick($event)"></cloud-migration-environment-list>
  </mat-card>
</div>
