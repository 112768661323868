import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
export declare const EMPTY_GUID: string;

@Component({
  selector: 'app-cloud-migration-discovery-workload',
  templateUrl: './cloud-migration-discovery-workload.component.html',
  styleUrls: ['./cloud-migration-discovery-workload.component.scss'],
})
export class CloudMigrationDiscoveryWorkloadComponent implements OnInit {
  wrkload: any;
  formTitle: string = 'Add/Edit Workload';
  wrkloadForm: UntypedFormGroup;
  discoveryId: string;
  projectId: any;
  operatingSystemTypes: any[];
  optimizationTypes: any[];
  environmentTypes: any[];
  virtualizationTypes: any[];
  message: string;
  isLPAR: boolean = true;
  workloadData: any;

  constructor(
    private cloudMigrationService: CloudMigrationToolService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CloudMigrationDiscoveryWorkloadComponent>,
    private router: Router,
    private activeRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.setState(this.data);
  }

  setState(data: any) {
    this.workloadData = data.rowData;
    this.discoveryId = data.discoveryId;
    this.isLPAR = data.isLPAR;
    this.formTitle =
      data?.rowData?.id == '00000000-0000-0000-0000-000000000000'
        ? 'Add Workload'
        : 'Edit Workload';

    this.activeRoute.parent?.params.subscribe((rp) => {
      this.projectId = rp['id'] ?? '';
    });
  }

  onEditWorkload($event: any) {
    this.workloadData = $event;
  }

  onFormUpdated($event: any) {
    this.workloadData = $event;
  }

  save() {
    var formVal = this.workloadData;
    var discoveryId = this.discoveryId;

    var data = {
      id: formVal.id,
      optimizationType: formVal.optimizationType,
      operatingSystemType: formVal.operatingSystemType,
      discoveryId: discoveryId,
      operatingSystemRelease: formVal.operatingSystemRelease,
      serverId: formVal.serverId,
      processors: formVal.processors,
      coresPerProcessor: formVal.coresPerProcessor,
      ram: formVal.ram,
      storageUtilizationInBytes: formVal.storageUtilizationInBytes,
      name: formVal.name,
    };
    this.message = '';
    this.cloudMigrationService.saveDiscoveryWorkload(data).then((res: any) => {
      this.discoveryId = res;
      this.close();
      this.message = 'Workload Saved!';
    });
  }

  close(res?: any) {
    this.dialogRef.close(res);
  }
}
