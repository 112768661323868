import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cloud-migration-discovery-list',
  templateUrl: './cloud-migration-discovery-list.component.html',
  styleUrls: ['./cloud-migration-discovery-list.component.scss']
})
export class CloudMigrationDiscoveryListComponent implements OnInit {
  projectId: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(param => {
      this.projectId = param['id'];
    });
  }

}
