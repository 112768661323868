import { Component } from '@angular/core';

@Component({
  selector: 'app-role-administration',
  templateUrl: './role-administration.component.html',
  styleUrls: ['./role-administration.component.scss']
})
export class RoleAdministrationComponent {

}
