import { DataSource } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Observable, of as observableOf, merge } from 'rxjs';
import { map } from 'rxjs/operators';

export class TemporaryApproverDataSource extends DataSource<any> {
  data: Array<any>;
  nonFilteredData;
  paginator: MatPaginator;

  constructor(private tempApprovers: Array<any>) {
    super();
    this.data = tempApprovers;
    this.nonFilteredData = tempApprovers;
  }

  connect(): Observable<any[]> {
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page
    ];

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData([...this.data]);
    }));
  }

  disconnect(): void {

  }

  setData(data: Array<any>) {
    this.data = data;
  }
  filter(field: any, fullData: any) {
    let filterData: any[] = fullData;
    if (field['tempApprover']) {
      filterData = filterData.filter(x => {
        return x.temporaryApproverEmployeeId == field['tempApprover'];
      });
    }
    if (field['backupEmployee']) {
      filterData = filterData.filter(x => {
        return x.approverForEmployeeId == field['backupEmployee'];
      });
    }
    if (field['createdBy']) {
      filterData = filterData.filter(x => {
        return x.createdByEmployeeId == field['createdBy'];
      });
    }
    if (Number.parseInt(field['tempApproverStatus']?.toString()) >= 0) {
      filterData = filterData.filter(x => {
        return x.softDelete == field['tempApproverStatus'];
      })
    }
    return filterData;
  }

  private getPagedData(data: any[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }
}
