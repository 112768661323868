import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { SolutionPilar } from './solution-pilar';
import { of, Observable } from 'rxjs';
export class SolutionPilarDataSource extends DataSource<SolutionPilar> {
  data: Array<SolutionPilar>;
  nonFilteredData;

  constructor(private solPilars: Array<any>) {
    super();
    this.data = solPilars;
    this.nonFilteredData = solPilars;
  }
  connect(): Observable<SolutionPilar[]> {

    return of(this.data);
  }
  disconnect(collectionViewer: CollectionViewer): void {

  }
  setData(data: Array<SolutionPilar>) {
    this.data = data;
  }

  filter(field: any, fullData: any) {
    let filterData: any[] = fullData;
    if (field['solPilarName']) {
      filterData = filterData.filter(x => {
        return x.solutionPilarName.toLocaleLowerCase().indexOf(field['solPilarName'].toLocaleLowerCase()) > -1;
      });
    }
    if (Number.parseInt(field['solPilarStatus']?.toString()) >= 0) {
      filterData = filterData.filter(x => {
        return x.softDelete == field['solPilarStatus'];
      })
    }
    if (field['solPilarTag']) {
      filterData = filterData.filter(x => {
        return x.solutionPilarTag.toLocaleLowerCase().indexOf(field['solPilarTag'].toLocaleLowerCase()) > -1;
      })
    }
    return filterData;
  }

}
