import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { FleetMangementService } from '../fleet-mangement.service';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MileageConfirmationPopupComponent } from './mileage-confirmation-popup/mileage-confirmation-popup.component';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from 'src/app/shared/models/id-token-claims';

@Component({
  selector: 'app-add-mileage',
  templateUrl: './add-mileage.component.html',
  styleUrls: ['./add-mileage.component.scss']
})
export class AddMileageComponent implements OnInit {
  milage: any;
  id: string;
  currentMilage: any;
  mileageForm: UntypedFormGroup;
  vehicleUsageNote: UntypedFormControl;
  vehicleUsageMileageStart: UntypedFormControl;
  vehicleUsageMileageEnd: UntypedFormControl;
  vehicleUsageDate: UntypedFormControl;
  vehicleUsageDescription: UntypedFormControl;
  isAdmin: boolean;
  roles: string[] | string;
  drivers: Array<any>;

  constructor(private route: ActivatedRoute, private service: FleetMangementService, private location: Location, private fb: UntypedFormBuilder, private dialog: MatDialog, private authService: MsalService) {
    let idTokenClaims = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
    if (idTokenClaims) {
      this.roles = idTokenClaims['roles'];
      this.checkIfAdmin();
    }
  }

  ngOnInit() {
    if (this.isAdmin) {
      this.service.getDrivers()
        .subscribe(driverResponse => {
          this.drivers = driverResponse;
        });
    }
    this.vehicleUsageDescription = new UntypedFormControl('', Validators.required);
    this.vehicleUsageMileageStart = new UntypedFormControl('');
    this.vehicleUsageDate = new UntypedFormControl('', Validators.required);
    this.vehicleUsageNote = new UntypedFormControl('');

    this.route.params
      .subscribe(params => {
        if (this.milage) {

        } else {
          this.milage = {};
        }
        this.id = params['id'];
        this.milage.vehicleId = this.id;
        this.service.getVehicleMilagesByVehicleId(this.id)
          .subscribe(res => {
            this.milage.vehicleUsageMileageStart = res;

            this.vehicleUsageMileageEnd = new UntypedFormControl('', [Validators.required, Validators.min(this.milage.vehicleUsageMileageStart)]);
            this.mileageForm = this.fb.group({
              vehicleUsageNote: this.vehicleUsageNote,
              vehicleUsageMileageStart: this.vehicleUsageMileageStart,
              vehicleUsageMileageEnd: this.vehicleUsageMileageEnd,
              vehicleUsageDate: this.vehicleUsageDate,
              vehicleUsageType: new UntypedFormControl('', [Validators.required]),
              vehicleUsageDescription: this.vehicleUsageDescription,
              createdByEmployeeId: [null]
            });
          });
      });
  }
  saveMileage() {
    if (this.mileageForm.valid) {
      const totalMiles = this.milage.vehicleUsageMileageEnd - this.milage.vehicleUsageMileageStart;
      if (totalMiles > 99) {
        const dialogRef = this.dialog.open(MileageConfirmationPopupComponent, { data: totalMiles });
        dialogRef.disableClose = true;
        dialogRef.afterClosed()
          .subscribe(res => {
            if (res) {
              this.submitMileage();
            } else {

            }
          });
      } else {
        this.submitMileage();
      }
    }
  }

  private submitMileage() {
    this.service.getAzureId()
      .subscribe(aId => {
        this.service.createVehicleUsages(this.milage, aId as string)
          .subscribe(res => {
            this.location.back();
          });
      });
  }
  checkIfAdmin() {
    this.isAdmin = this.roles.includes('FleetMangementAdmin');
    if (!this.isAdmin) {
      this.isAdmin = this.roles.includes('PortalAdmin');
    }
  }
}
