import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LocationService {

  constructor(private http: HttpClient) { }
  getCities() {
    return this.http.get<any[]>(`/api/cities`);
  }
  getStates() {
    return this.http.get(`/api/states`);
  }
  getCounties() {
    return this.http.get(`/api/counties`);
  }


  getCity(id: string) {
    return this.http.get(`/api/cities/${id}`);
  }
  getState(id: string) {
    return this.http.get(`/api/states/${id}`);
  }
  getCounty(id: string) {
    return this.http.get(`/api/counties/${id}`);
  }

}
