import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProjectControlRateService {

  constructor(private http: HttpClient) { }
  getProjectControlRates() {
    return this.http.get<any[]>(`/api/solutions/projectcontrolrates`);
  }
  getProjectControlRate(id: string) {
    return this.http.get<any>(`/api/solutions/projectcontrolrates/${id}`);
  }
  getProjectControlRatesByProjectControlId(projectControlId: string) {
    return this.http.get(`/api/solutions/projectcontrol/${projectControlId}/projectcontrolrates`);
  }
  updateProjectControlRate(projectControlRate: any) {
    const body = JSON.stringify(projectControlRate);
    return this.http.put(`/api/solutions/projectcontrolrates/${projectControlRate.projectControlRateId}`, body);
  }
  createProjectControlRate(projectControlRate: any) {
    const body = JSON.stringify(projectControlRate);
    return this.http.post<any>(`/api/solutions/projectcontrolrates`, body);
  }
  deleteProjectControlRate(projectControlRateId: any) {
    return this.http.delete(`/api/solutions/projectcontrolrates/${projectControlRateId}`);
  }
  deleteAllProjectControlRatesByProjectControlId(projectControlId: string) {
    return this.http.delete(`/api/solutions/projectcontrol/${projectControlId}/projectcontrolrates`);
  }

}
