<div class="container-fluid">
  <mat-card>
    <a id="newMaintenanceScheduleCreate" mat-raised-button [routerLink]="['create']" color="primary" matTooltip="Create New Maintenance Schedule"
      [matTooltipPosition]="'right'">
      <mat-icon class="add-icon">add</mat-icon>
      Maintenance Schedule
    </a>
    <mat-card-content *ngIf="scheduleRecords">
      <mat-table #table [dataSource]="scheduleRecords">
        <ng-container matColumnDef="vehicleMaintenanceScheduleName">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.vehicleMaintenanceScheduleName}} </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="vehicleMaintenanceScheduleId">
          <mat-header-cell *matHeaderCellDef> Schedule Id</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.vehicleMaintenanceScheduleId}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vehicleId">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button id = "kebabMenu" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['update',element.vehicleMaintenanceScheduleId]">
                <mat-icon> edit </mat-icon>
                <Span> Details/Edit </Span>
              </button>
              <button mat-menu-item (click)="deleteScheduleRecord(element.vehicleMaintenanceScheduleId)">
                <mat-icon> delete_outline </mat-icon>
                <Span> Delete </Span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </mat-card-content>
  </mat-card>
</div>