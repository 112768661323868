<!--<h2>Business Partner Reports</h2>-->
<mat-divider></mat-divider>
<form [formGroup]="formGroup" (ngSubmit)="onReportComplete()">
  <mat-form-field>
    <mat-select placeholder="Status" multiple formControlName="businessPartnerStatus" disableOptionCentering>
      <mat-option [value]="0">In Process </mat-option>
      <mat-option [value]="1">Approved - In Good Standing</mat-option>
      <mat-option [value]="3">Approved - Awaiting Renewed COI</mat-option>
      <mat-option [value]="2">Do Not Use/Accept</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select matInput formControlName="agreementTypes" multiple placeholder="Select Agreement Type" disableOptionCentering>
      <mat-option [value]="0">Subcontractor Agreement</mat-option>
      <mat-option [value]="1">Reverse Subcontractor Agreement</mat-option>
      <mat-option [value]="2">Non-Disclosure Agreement</mat-option>
      <mat-option [value]="3">Mutual Non-Disclosure Agreement</mat-option>
      <mat-option [value]="4">Reseller Agreement</mat-option>
      <mat-option [value]="5">Partner Agreement</mat-option>
      <mat-option [value]="6">Sub Provided Agreement</mat-option>
      <mat-option [value]="7">Other Agreement</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input matInput formControlName="generalLiabilityExpirationDate"
           [matDatepicker]="insuranceDateOfCertificatePicker" placeholder="COI General Liability Exp" />
    <mat-datepicker-toggle matSuffix [for]="insuranceDateOfCertificatePicker"></mat-datepicker-toggle>
    <mat-datepicker #insuranceDateOfCertificatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input matInput [matDatepicker]="workmansCompPicker" placeholder="COI Workers Comp Exp"
           formControlName="workersCompExpiration" />
    <mat-datepicker-toggle matSuffix [for]="workmansCompPicker"></mat-datepicker-toggle>
    <mat-datepicker #workmansCompPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input matInput [matDatepicker]="cyberSecurityExpirationDate" placeholder="Cyber Risk Insurance Exp"
           formControlName="cyberSecurityExpirationDate" />
    <mat-datepicker-toggle matSuffix [for]="cyberSecurityExpirationDate"></mat-datepicker-toggle>
    <mat-datepicker #cyberSecurityExpirationDate></mat-datepicker>
  </mat-form-field>
  <button mat-raised-button [disabled]="!formGroup.valid" type="submit" color="primary">Run Report</button>
  <button mat-raised-button type="button" color="primary" title="Clear Filters" (click)="resetFilters()">Refresh</button>
</form>
<app-pivot #pivot1 [toolbar]="true" [width]="'100%'" [height]="500" [customizeCell]="onCustomizeCell" (ready)="onReady()"
           (reportcomplete)="onReportComplete()"></app-pivot>
<a #donwloadCsvLink></a>
