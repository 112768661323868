import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-migration-discovery-pingdata',
  templateUrl: './cloud-migration-discovery-pingdata.component.html',
  styleUrls: ['./cloud-migration-discovery-pingdata.component.scss']
})
export class CloudMigrationDiscoveryPingdataComponent implements OnInit {
  @Input() pingdata: any;

  tableDataSource: any;
  displayedColumns: string[] = ['targetHostIpV4','maxRoundTripTime', 'meanRoundTripTime','minRoundTripTime','packetSize','packetLoss', ]
  

  constructor() { }

  ngOnInit(): void {
    this.setData();
   
  }
  setData() {
    this.tableDataSource = this.pingdata;
  }

}
