import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LocationService } from 'src/app/shared/services/location.service';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { FleetMangementService } from '../../fleet-mangement.service';
import { Vehicle, VehicleRegistration, VehicleModel, VehicleInsurance, VehicleGasCard, VehicleValvolineCard } from '../../models/fleet-management-models';
import { AdminService } from '../../../admin/admin.service';
import { State } from 'src/app/admin/location/models/state.model';
import { City } from 'src/app/admin/location/models/city.model';



@Component({
  selector: 'app-vehicle-create',
  templateUrl: './vehicle-create.component.html',
  styleUrls: ['./vehicle-create.component.scss']
})
export class VehicleCreateComponent implements OnInit {
  oilCards: Array<any>;
  valvoCards: VehicleValvolineCard;
  gasCards: VehicleGasCard;
  states: Array<State>;
  cities: Array<City>;
  model: Vehicle;
  vehicleModels: Array<VehicleModel>;
  selectedModelId: string;
  insuranceRecords: Array<VehicleInsurance>;
  vehicleForm: UntypedFormGroup;
  employees: Array<any>;
  constructor(
    private service: FleetMangementService,
    private location: Location,
    private locationService: LocationService,
    private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.vehicleForm = this.fb.group({
      vin: ['', Validators.required],
      vehicleNumber: ['', Validators.required],
      vehicleMileageCurrent: ['', Validators.required],
      primaryLocationId: [''],
      vehicleModelId: ['', Validators.required],
      expirationDate: ['', Validators.required],
      licensePlateNumber: ['', Validators.required],
      licensePlateState: ['', Validators.required],
      vehicleEzPass: [''],
      vehicleAssignedToEmployeeId: [null],
      vehicleValvolineCardNumber: [''],
      vehicleValvolineCardPinNumber: [''],
      vehicleRegionStateId: [null],
      vehicleRegionCityId: [null],
      vehicleColor: [''],
      vehicleWrightPromptId: [''],
      vehiclePin: [''],
      vehicleAAAMembership: [''],
      vehicleGasCardNumber: [''],
    });
    // this.service.getVehicleInsuranceRecords()
    //   .subscribe(res => {
    //     this.insuranceRecords = res;
    //   });
    this.service.getVehicleModels()
      .subscribe(res => {
        this.vehicleModels = res;
        if (this.model) {

        } else {

          this.model = new Vehicle();
          this.model.vehicleRegistration = new VehicleRegistration();
          this.gasCards = new VehicleGasCard();
          this.valvoCards = new VehicleValvolineCard();

        }
      });
    this.service.getDrivers()
      .subscribe(employeeResult => {
        this.employees = employeeResult;
      });

    this.service.getStates()
      .subscribe(stateResult => {
        this.states = stateResult;
      });

    this.vehicleForm.controls['vehicleRegionStateId'].valueChanges.subscribe(v => {
      this.vehicleForm.controls["vehicleRegionCityId"].patchValue('');
      this.locationService.getCitiesByStateId(v)
        .subscribe(res => {
          this.cities = res;
        });
    });

  }

  onSubmit() {
    if (this.vehicleForm.valid) {
      // this.model.vehicleModel = this.vehicleModels.filter(mods => mods.vehicleModelId === this.selectedModelId)[0];
      this.model.vehicleIsActive = true;
      this.model.vehicleValvolineCards = [this.valvoCards];
      this.model.vehicleGasCards = [this.gasCards];
      this.service.createVehicle(this.model)
        .subscribe(res => {
          this.location.back();
        });
    }
  }

}
