import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RoleAdministrationService } from '../role-administration.service';
import { Location } from '@angular/common';
import { GraphApiService } from 'src/app/shared/services/graph-api.service';
import { AppRole } from '../models/appRole';

@Component({
  selector: 'app-role-admin-create',
  templateUrl: './role-admin-create.component.html',
  styleUrls: ['./role-admin-create.component.scss']
})
export class RoleAdminCreateComponent implements OnInit {
  appRoleFormGroup: UntypedFormGroup;
  approleId: any;
  appRole: AppRole;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private roleAdminService: RoleAdministrationService, private graphApiService: GraphApiService, private location: Location) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        if (params['id']) {
          this.approleId = params['id'];
          this.roleAdminService.getAppRole(this.approleId)
            .subscribe(res => {
              this.appRole = res;
              this.appRoleFormGroup = this.fb.group({
                'id': [res.id],
                'displayName': [res.displayName, Validators.required],
                'description': [res.description, Validators.required],
                'value': [res.value, [Validators.required, this.noWhitespaceValidator]],
                'allowedMemberTypes': [{ value: 'User', disabled: true }, Validators.required],
                'isEnabled': [res.isEnabled],
                'origin': [res.origin]
              });
              this.duplicateValueValidation();
            });
        } else {
          this.appRoleFormGroup = this.fb.group({
            'displayName': [null, Validators.required],
            'description': [null, Validators.required],
            'value': [null, [Validators.required, this.noWhitespaceValidator]],
            'allowedMemberTypes': [{ value: 'User', disabled: true }, Validators.required],
            'isEnabled': [false]
          });
          this.duplicateValueValidation();
        }
      });
    
  }


  duplicateValueValidation() {
    this.appRoleFormGroup.controls['value'].valueChanges.subscribe(value => {
      this.graphApiService.getApplicationRoles().subscribe(res => {
        const rolesArray = res.filter(role => role.id != this.approleId).map(role => role.value);
        if (rolesArray.includes(value)) {
          this.appRoleFormGroup.get('value')?.setErrors({ 'duplicateValue': true });
        }
      });
    })
  }

  createAppRole() {
    if (this.appRoleFormGroup.valid) {
      let appRole = this.appRoleFormGroup.value;
      if (this.approleId) {
        appRole.allowedMemberTypes = this.appRole.allowedMemberTypes;
        this.roleAdminService.updateAppRole(appRole)
          .subscribe(res => {
            this.location.back();

          });
      } else {
        appRole.allowedMemberTypes = ["User"];
        this.roleAdminService.createAppRole(appRole).subscribe(res => {
          this.location.back();
        });
      }
      
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    if (control.value?.indexOf(' ') > -1) {
      return { 'whitespace': true };
    }
    return null;
  }
}
