import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-migration-service-estimate-dashboard',
  template: '<router-outlet></router-outlet>'
})
export class CloudMigrationServiceEstimateDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
