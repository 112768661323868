import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { ProfessionalServicesService } from '../../../professional-services/professional-services.service';
import { BusinessPartnerService } from '../../../business-partners/business-partner.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  templateUrl: './score-card-popup.component.html',
  styleUrls: ['./score-card-popup.component.scss']
})
export class ScoreCardPopupComponent implements OnInit {
  projectId: string;
  businessPartners: any[];
  solutionPilars: any[];
  scorecardForm: UntypedFormGroup;
  disabled: boolean;
  constructor(public dialogRef: MatDialogRef<ScoreCardPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private route: ActivatedRoute,
    private businessPartnerService: BusinessPartnerService, private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.disabled = false;
    this.projectId = this.data['id'];
    if (this.data.scoreCard) {
      this.disabled = this.data.disabled;
      this.scorecardForm = this.fb.group({
        "id": [this.data.scoreCard.id],
        "businessPartnerId": [{ value: this.data.scoreCard.businessPartnerId, disabled: this.data.disabled }, Validators.required],
        "solutionPilarId": [{ value: this.data.scoreCard.solutionPilarId, disabled: this.data.disabled }, Validators.required],
        "isInvoicingExcluded": [{ value: this.data.scoreCard.isInvoicingExcluded, disabled: this.data.disabled }],
        "invoicingQuality": [{ value: this.data.scoreCard.invoicingQuality, disabled: this.data.disabled || this.data.scoreCard.isInvoicingExcluded }],
        "invoicingTimeliness": [{ value: this.data.scoreCard.invoicingTimeliness, disabled: this.data.disabled || this.data.scoreCard.isInvoicingExcluded }],
        "isPreSalesExcluded": [{ value: this.data.scoreCard.isPreSalesExcluded, disabled: this.data.disabled }],
        "preSalesAcurracyAndDetail": [{ value: this.data.scoreCard.preSalesAcurracyAndDetail, disabled: this.data.disabled || this.data.scoreCard.isPreSalesExcluded }],
        "preSalesTimeliness": [{ value: this.data.scoreCard.preSalesTimeliness, disabled: this.data.disabled || this.data.scoreCard.isPreSalesExcluded }],
        "isPostSalesExcluded": [{ value: this.data.scoreCard.isPostSalesExcluded, disabled: this.data.disabled }],
        "postSalesSkillsAndQuality": [{ value: this.data.scoreCard.postSalesSkillsAndQuality, disabled: this.data.disabled || this.data.scoreCard.isPostSalesExcluded }],
        "postSalesTimeliness": [{ value: this.data.scoreCard.postSalesTimeliness, disabled: this.data.disabled || this.data.scoreCard.isPostSalesExcluded }],
        "isDocumentationExcluded": [{ value: this.data.scoreCard.isDocumentationExcluded, disabled: this.data.disabled }],
        "documentationQuality": [{ value: this.data.scoreCard.documentationQuality, disabled: this.data.disabled || this.data.scoreCard.isDocumentationExcluded }],
        "documentationTimeliness": [{ value: this.data.scoreCard.documentationTimeliness, disabled: this.data.disabled || this.data.scoreCard.isDocumentationExcluded }],
        "isCommunicationExcluded": [{ value: this.data.scoreCard.isCommunicationExcluded, disabled: this.data.disabled }],
        "communicationQuality": [{ value: this.data.scoreCard.communicationQuality, disabled: this.data.disabled || this.data.scoreCard.isCommunicationExcluded }],
        "communicationTimeliness": [{ value: this.data.scoreCard.communicationTimeliness, disabled: this.data.disabled || this.data.scoreCard.isCommunicationExcluded }],
        "serviceProjectId": this.projectId,
        "comments": [{ value: this.data.scoreCard.comments, disabled: this.data.disabled }],
        'isComplete': [{ value: this.data.scoreCard.isComplete, disabled: this.data.disabled }],
        'createdByEmployeeId': [this.data.scoreCard.createdByEmployeeId],
        'createdDate': [this.data.scoreCard.createdDate]
      });
      this.setupChangeDetection();
    } else {
      this.scorecardForm = this.fb.group({
        "businessPartnerId": [null, Validators.required],
        "solutionPilarId": [null, Validators.required],
        "isInvoicingExcluded": false,
        "invoicingQuality": null,
        "invoicingTimeliness": null,
        "isPreSalesExcluded": false,
        "preSalesAcurracyAndDetail": null,
        "preSalesTimeliness": null,
        "isPostSalesExcluded": false,
        "postSalesSkillsAndQuality": null,
        "postSalesTimeliness": null,
        "isDocumentationExcluded": false,
        "documentationQuality": null,
        "documentationTimeliness": null,
        "isCommunicationExcluded": false,
        "communicationQuality": null,
        "communicationTimeliness": null,
        "serviceProjectId": this.projectId,
        "comments": null,
        "isComplete": [false]
      });
      this, this.setupChangeDetection();
    }
    this.businessPartners = [];
    this.solutionPilars = [];
    this.businessPartnerService.getBusinessPartnerByServiceProjectId(this.projectId)
      .subscribe(res => {
        res.forEach(bpi => {
          this.businessPartners.push(bpi.businessPartner);
          this.solutionPilars.push(bpi.solutionPilar);
        });
      });
    //this.projectService.proje

    this.data = {
      "businessPartnerId": null,
      "solutionPillarId": null,
      "isInvoicingExcluded": false,
      "invoicingQuality": null,
      "invoicingTimeliness": null,
      "isPreSalesExcluded": false,
      "preSalesAcurracyAndDetail": null,
      "preSalesTimeliness": null,
      "isPostSalesExcluded": false,
      "postSalesSkillsAndQuality": null,
      "postSalesTimeliness": null,
      "isDocumentationExcluded": false,
      "documentationQuality": null,
      "documentationTimeliness": null,
      "isCommunicationExcluded": false,
      "communicationQuality": null,
      "communicationTimeliness": null,
      "serviceProjectId": this.projectId,
      "comments": null,
      "isComplete": [false]
    }

  }
  setupChangeDetection() {
    this.scorecardForm.controls['isInvoicingExcluded'].valueChanges
      .subscribe(res => {
        if (res === true) {
          this.scorecardForm.controls['invoicingQuality'].disable();
          this.scorecardForm.controls['invoicingTimeliness'].disable();
        } else {

          this.scorecardForm.controls['invoicingQuality'].enable();
          this.scorecardForm.controls['invoicingTimeliness'].enable();
        }
      });
    this.scorecardForm.controls['isPreSalesExcluded'].valueChanges
      .subscribe(res => {
        if (res === true) {
          this.scorecardForm.controls['preSalesAcurracyAndDetail'].disable();
          this.scorecardForm.controls['preSalesTimeliness'].disable();
        } else {

          this.scorecardForm.controls['preSalesAcurracyAndDetail'].enable();
          this.scorecardForm.controls['preSalesTimeliness'].enable();
        }
      });
    this.scorecardForm.controls['isPostSalesExcluded'].valueChanges
      .subscribe(res => {
        if (res === true) {
          this.scorecardForm.controls['postSalesSkillsAndQuality'].disable();
          this.scorecardForm.controls['postSalesTimeliness'].disable();
        } else {

          this.scorecardForm.controls['postSalesSkillsAndQuality'].enable();
          this.scorecardForm.controls['postSalesTimeliness'].enable();
        }
      });
    this.scorecardForm.controls['isDocumentationExcluded'].valueChanges
      .subscribe(res => {
        if (res === true) {
          this.scorecardForm.controls['documentationQuality'].disable();
          this.scorecardForm.controls['documentationTimeliness'].disable();
        } else {

          this.scorecardForm.controls['documentationQuality'].enable();
          this.scorecardForm.controls['documentationTimeliness'].enable();
        }
      });
    this.scorecardForm.controls['isCommunicationExcluded'].valueChanges
      .subscribe(res => {
        if (res === true) {
          this.scorecardForm.controls['communicationQuality'].disable();
          this.scorecardForm.controls['communicationTimeliness'].disable();
        } else {

          this.scorecardForm.controls['communicationQuality'].enable();
          this.scorecardForm.controls['communicationTimeliness'].enable();
        }
      });
  }
  markAsComplete() {
    this.scorecardForm.controls['isComplete'].setValue(true);
    if (this.scorecardForm.valid) {
      if (this.scorecardForm.controls['id']) {
        this.businessPartnerService.updateBusinessPartnerScoreCard(this.scorecardForm.value)
          .subscribe(res => {
            this.dialogRef.close(this.scorecardForm.value);
          });
      } else {

        this.businessPartnerService.createBusinessPartnerScoreCard(this.scorecardForm.value)
          .subscribe(res => {
            this.dialogRef.close(this.scorecardForm.value);
          });
      }
    }
  }
  saveCard() {
    if (this.scorecardForm.valid) {
      if (this.scorecardForm.controls['id']) {
        this.businessPartnerService.updateBusinessPartnerScoreCard(this.scorecardForm.value)
          .subscribe(res => {
            this.dialogRef.close(this.scorecardForm.value);
          });
      } else {

        this.businessPartnerService.createBusinessPartnerScoreCard(this.scorecardForm.value)
          .subscribe(res => {
            this.dialogRef.close(this.scorecardForm.value);
          });
      }
    }
  }

}
