<mat-card>
  <form [formGroup]="releaseNotesFormGroup" #form="ngForm" (ngSubmit)="submitForm()" *ngIf="releaseNotesFormGroup">
    <mat-card-content>
      <mat-form-field class="col-md-3">
        <mat-select formControlName="iterations" multiple placeholder="Select Iteration" disableOptionCentering>
          <mat-option *ngFor="let it of devopsIterations" [value]="it.id" [matTooltip]="it.path">{{it.path}}</mat-option>
        </mat-select>
        <mat-error *ngIf="releaseNotesFormGroup.controls['iterations'].hasError('required')">An Iteration is required</mat-error>
        <!--<mat-error *ngIf="releaseNotesFormGroup.controls['iterations'].hasError('notCurrentIteration')">The user cannot Publish Iterations that are in the Previous Sprint</mat-error>-->
      </mat-form-field>
      <p class="error" *ngIf="!isUniqueRecord">
        {{errorText}}
      </p>
      <!--<div [formArrayName]="'iterations'">
    <div *ngFor="let item of releaseNoteIterations.controls;let i = index" [formGroupName]="i">
      <mat-form-field>
        <mat-select [disabled]='isLocked' placeholder="Select Iteration"
                    disableOptionCentering formControlName="azureIterationId">
          <mat-option *ngFor="let itr of iterations" [value]="itr.id" [matTooltip]="itr.path">
            {{itr.path}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="releaseNoteIterations.at(i).hasError('required', ['azureIterationId'])">
          An Iteration is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <p class="error" *ngIf="!isUniqueRecord">
    Iteration record should be unique.
  </p>-->

      <mat-form-field class="col-md-2">
        <input [matDatepicker]="releaseDate" matInput placeholder="Select Date" type="datetime"
               name="releaseDate" formControlName="releaseDate" />
        <mat-error *ngIf="releaseNotesFormGroup.controls['releaseDate'].hasError('required') && !releaseNotesFormGroup.controls['releaseDate'].hasError('matDatepickerParse')">A Date is required</mat-error>
        <mat-error *ngIf="releaseNotesFormGroup.controls['releaseDate'].hasError('invalidDate')">Iteration and Release Date should be matched</mat-error>
        <mat-error *ngIf="releaseNotesFormGroup.controls['releaseDate'].hasError('matDatepickerParse')">Please enter a valid Date</mat-error>
        <mat-datepicker-toggle matSuffix [for]="releaseDate"></mat-datepicker-toggle>
        <mat-datepicker #releaseDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="col-md-2 version-form-field">
        <input matInput type="text" placeholder="Enter Version Number" formControlName="version" id="versionNo" />
        <div class="radio-btn row">
          <mat-radio-group formControlName="versionType" class="col-md-12" (change)="versionTypeChanged()" name="versionType"
                           [ngClass]="{'invalid': releaseNotesFormGroup.get('versionType').touched && releaseNotesFormGroup.get('versionType').invalid}">
            <mat-radio-button value="0">Major Version</mat-radio-button>
            <mat-radio-button value="1">Minor Version</mat-radio-button>
            <mat-radio-button value="2">Patch Version</mat-radio-button>
          </mat-radio-group>
          <mat-error class="version-error" *ngIf="releaseNotesFormGroup.get('versionType').touched && releaseNotesFormGroup.controls['versionType'].hasError('required')">A Version Number is required</mat-error>
        </div>
      </mat-form-field>

      <mat-form-field class="col-md-3">
        <textarea matInput formControlName="notes" columns="10"
                  placeholder="Enter Notes"></textarea>
      </mat-form-field>
      <br />
      <mat-slide-toggle id="maintenanceToggle" formControlName="isMaintenanceNotified">Maintenance Alert</mat-slide-toggle>
      <br />
      <mat-slide-toggle id="releaseNotesToggle" formControlName="isReleaseNoteNotified">Release Notes Notification</mat-slide-toggle>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" [disabled]="!releaseNotesFormGroup.valid || isSaveDisabled">Save</button>
      <button mat-raised-button type="button" (click)="goBack()">Cancel</button>
    </mat-card-actions>
  </form>
</mat-card>
