<mat-table id="serviceEstimatesTable" *ngIf="serviceEstimateData" mat-table class="full-width-table" aria-label="SEs Table" [dataSource]="rowData">
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.title}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="seCount">
    <mat-header-cell *matHeaderCellDef>SE/CO Count</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getSEStatusCount(null, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="inProgress">
    <mat-header-cell *matHeaderCellDef>Components in progress</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getSEStatusCount(0, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="pendingApproval">
    <mat-header-cell *matHeaderCellDef>Pending Director Approval/Override</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getSEStatusCount(1, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="needsSubmission">
    <mat-header-cell *matHeaderCellDef>SE needs submission</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getSEStatusCount(7, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="approved">
    <mat-header-cell *matHeaderCellDef>Approved</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getSEStatusCount(2, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="pmApproval">
    <mat-header-cell *matHeaderCellDef>PM Approval/Override (Pending)</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getSEStatusCount(5, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="contractSigned">
    <mat-header-cell *matHeaderCellDef>Contract Signed</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getSEStatusCount(4, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="thresholdApproval">
    <mat-header-cell *matHeaderCellDef>Threshold Approval (Pending)</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getSEStatusCount(6, row.id)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="expired">
    <mat-header-cell *matHeaderCellDef>Expired</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getSEStatusCount(3, row.id)}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
