import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { FleetMangementService } from "../../fleet-mangement.service";
import { VehicleExpense } from "../../models/fleet-management-models";

@Component({
  selector: 'app-vehicle-expenses-detail',
  templateUrl: './vehicle-expenses-detail.component.html',
  styleUrls: ['./vehicle-expenses-detail.component.scss']
})
export class VehicleExpensesDetailComponent implements OnInit {
  model: VehicleExpense;
  constructor(private service: FleetMangementService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        this.service.getVehicleExpense(params['id'])
          .subscribe(res => {
            this.model = res;
          });
      });
  }

}
