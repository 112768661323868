import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TagsListComponent } from './tags-list/tags-list.component';
import { TagsEditComponent } from './tags-edit/tags-edit.component';
import { TagsComponent } from './tags.component';
import { TagsCreateComponent } from './tags-create/tags-create.component';

export const routes: Routes = [{
  path: 'tags', component: TagsComponent, data: {
    breadcrumb: 'Tags', isRoutable: true
  },
  children: [
    {
      path: '', component: TagsListComponent, data: {
        breadcrumb: null, isRoutable: false
      } },
    {
      path: 'create', component: TagsCreateComponent, data: {
        breadcrumb: 'Create Tag', isRoutable: true
      } },
    {
      path: 'edit/:id', component: TagsEditComponent, data: {
        breadcrumb: 'Edit Tag', isRoutable: true
      } }
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TagsRoutingModule { }
