import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AzureDevOpsIteration } from '../../shared/models/iteration';
import { ReleaseNote } from './models/releaseNotes';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {

  constructor(private http: HttpClient) { }

  getReleaseNotes() {
    return this.http.get<Array<ReleaseNote>>(`/api/releaseNotes`);
  }

  getIterations() {
    return this.http.get<Array<AzureDevOpsIteration>>(`/api/releaseNotes/iterations`);
  }

  getCurrentReleaseInfo() {
    return this.http.get<ReleaseNote | null>(`/api/releaseNotes/CurrentRelease`);
  }

  getReleaseNote(releaseNotesId: string) {
    return this.http.get<ReleaseNote>(`/api/releaseNotes/${releaseNotesId}`);
  }

  createReleaseNotes(releaseNote: any) {
    const body = JSON.stringify(releaseNote);
    return this.http.post(`/api/releaseNotes`, body);
  }

  updateReleaseNotes(releaseNote: any) {
    const body = JSON.stringify(releaseNote);
    return this.http.put(`/api/releaseNotes/${releaseNote.id}`, body);
  }

  deleteReleaseNote(releaseNoteId: string) {
    return this.http.delete(`/api/releaseNotes/${releaseNoteId}`);
  }

  removeReleaseNoteIteration(releaseNoteIterationId: string) {
    return this.http.delete(`/api/releaseNotes/releaseNotesIteration/${releaseNoteIterationId}`);
  }
}
