<mat-card>
  <mat-card-content>
    <!--<h2>Reports</h2>-->
    <mat-form-field>
      <mat-select placeholder="Select a Report" #reportsSelect disableOptionCentering [formControl]="reportOption">
        <mat-option>None</mat-option>
        <mat-option [value]="0">Business Partner Raw Report</mat-option>
      </mat-select>
    </mat-form-field>
    <app-business-partner-reports *ngIf="reportsSelect.value == 0"></app-business-partner-reports>
  </mat-card-content>
</mat-card>
