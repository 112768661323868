import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GraphApiService } from '../../shared/services/graph-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList, copyArrayItem, CdkDragStart, CdkDragMove, CdkDragExit } from '@angular/cdk/drag-drop';
import { MatLegacyList as MatList } from '@angular/material/legacy-list';
import { AdminService } from '../../admin/admin.service';
import { AccountInfo } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss']
})
export class EmployeeDetailComponent implements OnInit {
  appRoles: any[];
  employee: any;
  roles: any;
  loading: boolean;
  userId: string;
  isViewOnly: boolean;
  currentUser: AccountInfo | null;
  currentEmployee: any;

  constructor(private route: ActivatedRoute, private graphApiService: GraphApiService, private adminService: AdminService, private authService: MsalService) { }

  ngOnInit(): void {
    this.loading = true;
    this.isViewOnly = false;
    this.currentUser = this.authService.instance.getActiveAccount();
    this.route.params
      .subscribe(params => {
        if (params['userId']) {
          this.userId = params['userId'];
          let fetchAppRoles = this.graphApiService.getApplicationRoles();
          let fetchUsers = this.graphApiService.getApplicationUsers();
          let fetchUser = this.graphApiService.getUser(this.userId);
          let fetchEmp = this.adminService.getEmployeeByAzureAdId(this.currentUser?.localAccountId);
          forkJoin([fetchAppRoles, fetchUsers, fetchUser, fetchEmp]).subscribe(results => {
            this.employee = results[2];
            this.appRoles = results[0];
            this.getRoles(results[1]);
            this.currentEmployee = results[3];
            this.isViewOnly = this.currentUser?.localAccountId == this.currentEmployee.azureAdId && this.currentEmployee.softDelete;
            this.loading = false;
          }, (error) => {
            this.loading = false;
          });

        }
      });

  }
  getRoles(roles: any) {
    this.roles = [];
    let userRolesInfo = roles.filter((x: any) => x.principalId == this.userId);
    userRolesInfo.forEach((item: any) => {
      let role = this.appRoles.find(x => x.id == item.appRoleId);
      let displayName = role?.displayName;
      let roleId = role?.id;
      displayName && roleId ? this.roles.push({ roleId: roleId, "displayName": displayName, id: item.id }) : '';
    });
  }
  getAppRole() {
    return this.roles.join(", ");
  }
  getEmployeeBasicDetails() {
    return this.employee;
  }
  dropAppRole(event: CdkDragDrop<Array<any>>) {
    if (!(event.previousContainer === event.container)) {
      copyArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.graphApiService.assignUserToApplication(<string>this.employee['id'], event.item.data.id)
        .subscribe(result => {
          this.graphApiService.getApplicationUsers().subscribe(res => {
            this.getRoles(res);
          })
        });
    }
    if (event.previousContainer.data)
      this.appRoles = this.appRoles.filter(x => x.temp != true);
  }
  isDisabled(item: any) {
    return (this.roles.findIndex((x: any) => x.roleId == item.id) != -1 || this.isViewOnly || !this.employee.accountEnabled);
  }
  dragStart(event: CdkDragStart) {
    this.appRoles = this.appRoles.filter(x => x.temp != true);
  }
  moved(event: CdkDragExit<any>) {
    this.appRoles.splice(event.container.data.findIndex((x: any) => x == event.item.data) + 1, 0, { ...event.item.data, temp: true });
  }
  removeRole(item: any) {
    this.graphApiService.unassignUserToApplication(item.id).subscribe(res => {
      this.roles.splice(this.roles.findIndex((x: any) => x.id == item.id), 1);
     });
  }
}
