import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-line-of-business-create',
  templateUrl: './line-of-business-create.component.html',
  styleUrls: ['./line-of-business-create.component.scss']
})
export class LineOfBusinessCreateComponent implements OnInit {

  lineOfBusinessFormGroup: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.lineOfBusinessFormGroup = this.fb.group({});
  }
  submitForm() {

  }

}
