<button id="btn-close-icon" mat-icon-button class="close-button" (click)="close()">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{ formTitle }}
  </h3>
</div>
<p></p>
<mat-card *ngIf="
    assessmentCreateForm && cloudProviderOptions && paymentOptions && regions && assessment
  ">
  <mat-label class="card-label-text">Select a Target Cloud Provider and Options to Create a Migration Assessment for the Selected Discoveries</mat-label>
  
  <div>
    <mat-dialog-content [formGroup]="assessmentCreateForm">
        <div class="row">
          <div class="col-6">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Title</mat-label>
              <input matInput formControlName="title" />
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field>
              <mat-label>Cloud Provider</mat-label>
              <mat-select formControlName="cloudProviderName" disableOptionCentering>
                <mat-option *ngFor="let provider of cloudProviderOptions" [value]="provider.value">
                  {{ provider.text }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  assessmentCreateForm.controls['cloudProviderName'].hasError(
                    'required'
                  )
                ">
                Cloud Provider is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>Payment Options</mat-label>
              <mat-select formControlName="paymentOption" disableOptionCentering>
                <mat-option *ngFor="let paymentOption of paymentOptions" [value]="paymentOption?.value">
                  {{ paymentOption?.text }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  assessmentCreateForm.controls['paymentOption'].hasError(
                    'required'
                  )
                ">
                Payment Option is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field>
              <mat-label>Region</mat-label>
              <mat-select formControlName="region" disableOptionCentering id="regionsDropdown">
                <mat-option *ngFor="let region of regions" [value]="region.value" id="regions">
                  {{ region.value }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="assessmentCreateForm.controls['region'].hasError('required')              ">
                Region is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-dialog-content>
  </div>
  <div>
    <cloud-migration-assessment-discovery-select [discoveryList]="discoveryList" [selection]="selection">
    </cloud-migration-assessment-discovery-select>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-dialog-actions align="end">
        <button id="btn-close-dialog" class="mat-raised-button" (click)="close()">Close</button>
        <button id="btn-run-assessment-matching" type="submit" mat-raised-button mat-primary
          [disabled]="!(assessmentCreateForm.valid && selection.selected.length>0)" (click)="save()">
          Run...
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</mat-card>