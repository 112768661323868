import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TagsRoutingModule } from './tags-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TagsListComponent } from './tags-list/tags-list.component';
import { TagsCreateComponent } from './tags-create/tags-create.component';
import { TagsEditComponent } from './tags-edit/tags-edit.component';
import { TagsComponent } from './tags.component';


@NgModule({
  declarations: [
    TagsListComponent,
    TagsCreateComponent,
    TagsEditComponent,
    TagsComponent
  ],
  imports: [
    SharedModule,
    TagsRoutingModule
  ]
})
export class TagsModule { }
