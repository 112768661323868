import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ServiceRoleService } from '../../../admin/service-role/service-role.service';
import { ProfessionalServicesService } from '../../professional-services.service';
import { PivotComponent } from '../../../shared/components/pivot/pivot.component';

@Component({
  selector: 'app-approved-service-estimates-report',
  templateUrl: './approved-service-estimates-report.component.html',
  styleUrls: ['./approved-service-estimates-report.component.css']
})
export class ApprovedServiceEstimatesReportComponent implements OnInit {

  @ViewChild('donwloadCsvLink', { static: true }) private donwloadCsvLink: ElementRef;

  @ViewChild('pivot1') child: PivotComponent;

  formGroup: UntypedFormGroup;
  serviceRoles: Array<any>;
  reportRan:boolean;

  constructor(private fb: UntypedFormBuilder, private serviceRoleService: ServiceRoleService, private profServices: ProfessionalServicesService) { }

  ngOnInit() {
    this.reportRan = false;
    this.serviceRoleService.getServiceRoles()
      .subscribe(res => {
        this.serviceRoles = res;

      });
    this.formGroup = this.fb.group({
      startDate: [null],
      endDate: [null],
      signedContractOnly: [null],
      serviceRoleId: [null]
    });
  }
  submitForm() {
    this.profServices.runApprovesServiceEstimatesReport(this.formGroup.value)
      .subscribe(res => {
        const fileText = this.profServices.convertToCsvReports(JSON.stringify(res));
        const blob = new Blob([fileText], {
          type: 'text/csv'
        });
        const url = window.URL.createObjectURL(blob);
        const link = this.donwloadCsvLink.nativeElement;
        link.href = url;
        link.download = 'approvedServiceEstimateReport.csv';
        link.click();
        window.URL.revokeObjectURL(url);
      });
  }


  onPivotReady(pivot: WebDataRocks.Pivot): void {
  }

  onCustomizeCell(cell: WebDataRocks.CellBuilder, data: WebDataRocks.CellData): void {
    if (data.isClassicTotalRow) cell.addClass("fm-total-classic-r");
    if (data.isGrandTotalRow) cell.addClass("fm-grand-total-r");
    if (data.isGrandTotalColumn) cell.addClass("fm-grand-total-c");
  }

  onReportComplete(): void {
    this.profServices.runApprovesServiceEstimatesReport(this.formGroup.value)
      .subscribe(res => {
        this.child.webDataRocks.off("reportcomplete");
        this.reportRan = true;
        this.child.webDataRocks.setReport({
          dataSource: {
            data: res
          }
        });
      });
}

}
