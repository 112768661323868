import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Video } from '../admin-help-videos-list/admin-help-video-datasource';
import { AdminHelpVideosService } from '../admin-help-videos.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-admin-help-videos-create',
  templateUrl: './admin-help-videos-create.component.html',
  styleUrls: ['./admin-help-videos-create.component.scss']
})
export class AdminHelpVideosCreateComponent implements OnInit {
  videoForm: UntypedFormGroup;
  videoUriError: boolean;
  model: Video;
  fileName: string;
  videoId: string; file: any;
  isLoading: boolean;
  isErrorFile: boolean;
  @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;

  constructor(private fb: UntypedFormBuilder, private location: Location, private route: ActivatedRoute, private videoService: AdminHelpVideosService) { }

  ngOnInit(): void {
    this.fileName = '';
    this.model = new Video();
    this.isLoading = false;
    this.isErrorFile = false;
    this.route.params
      .subscribe(params => {
        if (params['videoId']) {
          this.videoId = params['videoId'];
          this.videoService.getVideo(this.videoId)
            .subscribe(res => {
              this.fileName = res.videoURI;
              this.videoForm = this.fb.group({
                'videoFile': [this.fileName, [Validators.required, Validators.maxLength(128)]],
                'videoURI': [this.fileName],
                'title': [res.title, [Validators.required, Validators.maxLength(32)]],
                'description': [res.description, [Validators.required, Validators.maxLength(255)]],
                'section': [res.section, [Validators.required, Validators.maxLength(128)]],
                'name': [res.name, [Validators.required, Validators.maxLength(128)]],
                'isActive': [res.isActive],
                'version': [res.version, [Validators.required, Validators.pattern("^[0-9]*$")]],
                'isVisible': [res.isVisible],
                'id': [res.id],
                'createdByEmployeeId': [res.createdByEmployeeId]
              });
            });
        }
        else {
          this.videoForm = this.fb.group({
            'videoFile': [null, [Validators.required, Validators.maxLength(128)]],
            'title': [null, [Validators.required, Validators.maxLength(32)]],
            'description': [null, [Validators.required, Validators.maxLength(255)]],
            'section': [null, [Validators.required, Validators.maxLength(128)]],
            'name': [null, [Validators.required, Validators.maxLength(128)]]
          });
        }
      });
  }

  toggleVideo(event?: any) {
    this.videoplayer.nativeElement.play();
  }

  updateVideoControl(event: any) {
    let file = event.target.files[0];
    if (file) {
      this.videoForm.patchValue({ videoFile: file });
      this.videoForm.updateValueAndValidity();
      this.fileName = file?.name;
      this.setErrorStatus();

      this.videoplayer.nativeElement.src = URL.createObjectURL(file);
    }
  }
  uploadFiles() {

  }
  saveVideo() {
    this.isLoading = true;
    this.videoForm.disable();
    if (this.videoId) {
      this.videoService.updateVideo(this.videoForm.value)
        .subscribe(res => {
          this.location.back();
        }, () => this.isLoading = false)
    } else {
      this.videoService.uploadVideo(this.videoForm.value)
        .subscribe(res => {
          this.location.back();
        }, () => this.isLoading = false)
    }
  }
  isVideoUriExceeds() {
    return this.fileName.length > 128;
  }
  setErrorStatus() {
    if (this.fileName)
      this.videoUriError = false;
    else
      this.videoUriError = true;
  }
  isDirty() {
    if (this.videoId)
      return this.videoForm.dirty;
    return false;
  }
  handleError(event: any) {
    this.isErrorFile = true;
  }
}
