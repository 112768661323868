import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../admin.service';
import { VehicleGasCard, VehicleOilCard } from '../../models/gas-card';
import { Employee } from '../../models/employee';

@Component({
  selector: 'app-detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss']
})
export class DetailCardComponent implements OnInit {
  card: VehicleGasCard | VehicleOilCard;
  id: string;
  isEditing: boolean;
  employees: Employee[];
  statusMessage: string;
  hasUpdateError: boolean;
  cardType: string;
  tempCard: VehicleGasCard | VehicleOilCard;
  color: string;
  constructor(private route: ActivatedRoute, private adminService: AdminService) { }

  ngOnInit() {
    this.color = 'primary';
    this.isEditing = false;
    this.route.queryParams
      .subscribe(queryParams => {
        this.cardType = queryParams['cardType'];
        this.route.params
          .subscribe(params => {
            if (params['id']) {
              this.id = params['id'];
              this.adminService.getEmployees()
                .subscribe(res => {
                  this.employees = res;
                });
              if (this.cardType === 'gas') {
                this.adminService.getCard(this.id)
                  .subscribe(httpResponse => {
                    this.card = httpResponse;

                  });
              } else {
                this.adminService.getOilCard(this.id)
                  .subscribe(httpResponse => {
                    this.card = httpResponse;

                  });
              }
            }
          });
      });
  }
  startEditMode() {
    this.isEditing = true;
  }
  endEditMode() {
    this.isEditing = false;
  }
  updateCard() {
    if (this.cardType === 'gas') {
      this.adminService.updateCard(this.card)
        .subscribe(res => {
          this.statusMessage = 'The card was updated successfully';
        },
          (error) => {
            this.statusMessage = 'There was an error updating the card, please try again and if the problem continues contact support.';
          });
    } else {
      this.adminService.updateOilCard(this.card)
        .subscribe(res => {
          this.statusMessage = 'The card was updated successfully';
        },
          (error) => {
            this.statusMessage = 'There was an error updating the card, please try again and if the problem continues contact support.';
          });
    }
  }


}
