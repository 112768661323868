import { Injectable } from '@angular/core';
import { HttpResponse, HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {

  private requests: Array<CachedData> = [];

  constructor() { }

  put(url: string, response: HttpEvent<any>): void {
    var cacheData = new CachedData();
    cacheData.url = url;
    cacheData.dateStored = Date.now();
    cacheData.data = response;
    this.requests.push(cacheData);
  }

  get(url: string): HttpResponse<any> | undefined | null {
    var cacheData = this.requests.filter(req => {
      if (req.url === url) {
        return req;
      } else {
        return null;
      }
    })[0];
    if (cacheData) {
      const timeDifference = (Date.now() - cacheData.dateStored);
      if (timeDifference < 86400000) {
        return cacheData.data;
      }
      return null;
    }
    return null;
  }

  invalidateCache(): void {
    this.requests = [];
  }
}
export class CachedData {
  url: string;
  dateStored: number;
  data: any;
}
