<div>
  <fieldset>
    <legend>
      Interface Data
    </legend>
    <mat-table class="full-width-table mat-elevation-z8" [dataSource]="tableDataSource">
      <ng-container matColumnDef="inetIP">
        <mat-header-cell *matHeaderCellDef> INet IP </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.inetIP}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="broadcastIp">
        <mat-header-cell *matHeaderCellDef> Broadcast IP</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.broadcastIp}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="netMaskIp">
        <mat-header-cell *matHeaderCellDef> Net MaskIp </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.netMaskIp}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="rxErrors">
        <mat-header-cell *matHeaderCellDef> Rx Errors  </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.rxErrors}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="rxPackets">
        <mat-header-cell *matHeaderCellDef> Rx Packets </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.rxPackets}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="txErrors">
        <mat-header-cell *matHeaderCellDef> Tx Errors  </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.txErrors}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="txPackets">
        <mat-header-cell *matHeaderCellDef> Tx Packets </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.txPackets}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </fieldset>
</div>
