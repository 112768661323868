<button
  id="btn-close-icon"
  mat-icon-button
  class="close-button"
  (click)="close()"
>
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div>
  <h3
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    {{ formTitle }}
  </h3>
</div>
<p></p>
<div>
    <cloud-migration-workload-add *ngIf="workloadData"
    [workload]="workloadData"
    [isLPAR]="isLPAR"
    [showAddAction]="false"
    (OnAdd)="workloadAdded($event)"
    (OnUpdated)="onFormUpdated($event)"
  >
  </cloud-migration-workload-add>
</div>
<div class="row">
  <div class="col-12">
    <mat-dialog-actions align="end">
	<button class="mat-raised-button" (click)="close()">Close</button>
	    <button 
		id="btn-save-discovery-workload"
		class="mat-raised-button mat-primary" 
		(click)="save()">Save</button>
    
</mat-dialog-actions>
  </div>
  <div col="col-3" style="width: 1%;"><span style="font-weight: bold;">{{message}}</span></div>
</div>
  

