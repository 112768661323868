import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { SuccessDialogComponent } from './components/success-dialog/success-dialog.component';
import { ConfirmDeleteDialogComponent } from './components/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmRestoreDialogComponent } from './components/confirm-restore-dialog/confirm-restore-dialog.component';
import { MileageConfirmationDialogComponent } from './components/mileage-confirmation-dialog/mileage-confirmation-dialog.component';
import { TimeoutPopupComponent } from './components/timeout-popup/timeout-popup.component';
import { ServiceEstimatiorItemFilterPipe } from './pipes/service-estimator/service-estimatior-item-filter.pipe';
import { LocationCreateComponent } from './components/location-create/location-create.component';
import { FilterByStateIdPipe } from './pipes/filter-by-state-id.pipe';
import { FilterBySoulthinPillarIdPipe } from './pipes/filter-by-soulthin-pillar-id.pipe';
import { CurrencyMaskDirective } from './directives/currency/currency.directive';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from './directives/currency/currency-mask.config';
import { EmployeFilterPipe } from './pipes/employe-filter.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { ContractSignedDateDialogComponent } from './components/contract-signed-date-dialog/contract-signed-date-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { PivotComponent } from './components/pivot/pivot.component';
import { MatTreeModule } from '@angular/material/tree';
import { ComponentServiceRoleMapPipe } from './pipes/component-service-role-map.pipe';
import { AssignEmployeePopupComponent } from './components/assign-employee-popup/assign-employee-popup.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { DocumentViewerPopupComponent } from './components/document-viewer-popup/document-viewer-popup.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ScoreCardPopupComponent } from './components/score-card-popup/score-card-popup.component';
import { ProjectDocumentPopupComponent } from './components/project-document-popup/project-document-popup.component';
import { ProjectDocumentFilterPipe } from './pipes/project-document-filter.pipe';
import { ServiceEstimatePopupComponent } from './components/service-estimate-popup/service-estimate-popup.component';
import { ServiceEstimateComponentPopupComponent } from './components/service-estimate-component-popup/service-estimate-component-popup.component';
import { FilterByTagIdPipe } from './pipes/filter-by-tag-id.pipe';
import { ConfirmComponentChangesComponent } from './components/confirm-component-changes/confirm-component-changes.component';
import { StepCountDirective } from './directives/step-count/step-count.directive';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { RouterModule } from '@angular/router';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { TagComponent } from './components/tag/tag.component';
import { TagsPopupComponent } from './components/tags-popup/tags-popup.component';
import { CloudMigrationMatchCloudproductComponent } from '../cloud-migration-tool/assessments/cloud-migration-match-cloudproduct/cloud-migration-match-cloudproduct.component';
import { CloningPopupComponent } from './components/cloning-popup/cloning-popup.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ReleaseNotesDisplayComponent } from './components/release-notes-display/release-notes-display.component';
import { FilterByIterationIdPipe } from './pipes/filter-by-iteration-id.pipe';
import { GeoFencingPopupComponent } from './components/geo-fencing-popup/geo-fencing-popup.component';
import { BusinessPartnerPopupComponent } from './components/business-partner-popup/business-partner-popup.component';
import { FilterByCityIdPipe } from './pipes/filter-by-city-id.pipe';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ReadOnlyFieldComponent } from './components/read-only-field/read-only-field.component';
import { AssignDriversPopupComponent } from './components/assign-drivers-popup/assign-drivers-popup.component';
import { FilterByActiveRecordsPipe } from './pipes/filter-by-active-records.pipe';
import { SelectCheckAllComponent } from './components/select-check-all/select-check-all.component';
import { ExportQuotePopupComponent } from '../professional-services/service-estimates/export-quote-popup/export-quote-popup.component';
import { ComponentDetailsPopupComponent } from '../professional-services/service-estimates/component-details-popup/component-details-popup.component';
import { UpdateExpirationDateDialogComponent } from './components/update-expiration-date-dialog/update-expiration-date-dialog.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ",",
  allowZero: true,
  nullable: true
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatTableModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatPaginatorModule,
    DragDropModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDividerModule,
    MatMenuModule,
    MatTooltipModule,
    MatSortModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatTabsModule,
    NgxDocViewerModule,
    MatGridListModule,
    MatMomentDateModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatPaginatorModule,
    DragDropModule,
    MatExpansionModule,
    MatCheckboxModule,
    ServiceEstimatiorItemFilterPipe,
    FilterByStateIdPipe,
    FilterBySoulthinPillarIdPipe,
    MatProgressSpinnerModule,
    CurrencyMaskDirective,
    MatSnackBarModule,
    EmployeFilterPipe,
    MatDividerModule,
    MatMenuModule,
    MatTooltipModule,
    MatSortModule,
    MatSlideToggleModule,MatInputModule,
    PivotComponent,
    MatTreeModule,
    ComponentServiceRoleMapPipe,
    MatTabsModule,NgxJsonViewerModule,
    DocumentViewerPopupComponent,
    ScoreCardPopupComponent,
    ProjectDocumentPopupComponent,
    ProjectDocumentFilterPipe,
    ServiceEstimatePopupComponent,
    ServiceEstimateComponentPopupComponent,
    FilterByTagIdPipe,
    MatGridListModule,
    StepCountDirective,
    BreadCrumbComponent,
    ClickOutsideDirective,
    TagComponent,
    TagsPopupComponent,
    CloningPopupComponent,
    MatMomentDateModule,
    ReleaseNotesDisplayComponent, 
    FilterByIterationIdPipe,
    GeoFencingPopupComponent,
    ExportQuotePopupComponent,
    ComponentDetailsPopupComponent,
    BusinessPartnerPopupComponent,
    FilterByCityIdPipe,
    ReadOnlyFieldComponent,
    FilterByActiveRecordsPipe,
    SelectCheckAllComponent
  ],
  declarations: [
    SuccessDialogComponent,
    AssignDriversPopupComponent,
    ConfirmDeleteDialogComponent,
    ConfirmRestoreDialogComponent,
    MileageConfirmationDialogComponent,
    TimeoutPopupComponent,
    ServiceEstimatiorItemFilterPipe,
    LocationCreateComponent,
    FilterByStateIdPipe,
    FilterBySoulthinPillarIdPipe,
    CurrencyMaskDirective,
    EmployeFilterPipe,
    ContractSignedDateDialogComponent,
    PivotComponent,
    ComponentServiceRoleMapPipe,
    AssignEmployeePopupComponent,
    DocumentViewerPopupComponent,
    ScoreCardPopupComponent,
    ProjectDocumentPopupComponent,
    ProjectDocumentFilterPipe,
    ServiceEstimatePopupComponent,
    ServiceEstimateComponentPopupComponent,
    FilterByTagIdPipe,
    ConfirmComponentChangesComponent,
    StepCountDirective,
    BreadCrumbComponent,
    ClickOutsideDirective,
    TagComponent,
    TagsPopupComponent,
    CloningPopupComponent,
    ReleaseNotesDisplayComponent,
    FilterByIterationIdPipe,
    GeoFencingPopupComponent,
    ExportQuotePopupComponent,
    ComponentDetailsPopupComponent,
    BusinessPartnerPopupComponent,
    FilterByCityIdPipe,
    ReadOnlyFieldComponent,
    FilterByActiveRecordsPipe,
    SelectCheckAllComponent,
    UpdateExpirationDateDialogComponent
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    FilterByStateIdPipe
  ]

})
export class SharedModule {
}
