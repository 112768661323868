import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-business-partners-status-popup',
  templateUrl: './business-partners-status-popup.component.html',
  styleUrls: ['./business-partners-status-popup.component.scss']
})
export class BusinessPartnersStatusPopupComponent implements OnInit {

  note: UntypedFormControl;

  constructor(public dialogRef: MatDialogRef<BusinessPartnersStatusPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.note = new UntypedFormControl(this.data.note, Validators.required);
  }
  saveNote() {
    this.dialogRef.close(this.note.value);
  }
}
