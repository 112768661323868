<div class="container-fluid">
  <mat-card>
    <a id="newModelCreate" mat-raised-button [routerLink]="['create']" color="primary" matTooltip="Create New Model"
      [matTooltipPosition]="'right'">
      <mat-icon class="add-icon">add</mat-icon>
      Model
    </a>
    <mat-card-content>
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="manufacturerName">
          <mat-header-cell *matHeaderCellDef> Manufacturer </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.manufacturerName}} </mat-cell>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="vehicleModelName">
          <mat-header-cell *matHeaderCellDef> Model </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.vehicleModelName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vehcileModelYear">
          <mat-header-cell *matHeaderCellDef> Year </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.vehcileModelYear}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vehicleModelId">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button id="kebabMenu" [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item type="button" [routerLink]="['update',element.vehicleModelId]">
                <mat-icon> edit </mat-icon>
                <span> Details/Edit </span>
              </button>
              <button mat-menu-item type="button" (click)="deleteVM(element.vehicleModelId)">
                <mat-icon> delete_outline </mat-icon>
                <Span> Delete </Span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </mat-card-content>
  </mat-card>
</div>