<mat-card>
  <div *ngIf="regionOptions" [formGroup]="cloudProductFilterForm">
  <mat-grid-list cols="2" rowHeight="6:1">
    <mat-form-field style="min-width:300px">
      <mat-label>Region</mat-label>
      <mat-select formControlName="region">
        <mat-option *ngFor="let region of regionOptions" [value]="region.value">{{region.text}}</mat-option>
      </mat-select>
    </mat-form-field>
      <mat-form-field>
        <mat-label>CPU</mat-label>
        <input matInput formControlName="cpu" placeholder="cpu" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ram</mat-label>
        <input matInput formControlName="ram" placeholder="ram" />
      </mat-form-field>
    </mat-grid-list>
   
<mat-table mat-table>
    <ng-container matColumnDef="computeProductId">
      <mat-header-cell *matHeaderCellDef>Instance</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.computeProductId}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="productName">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.productName}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="operatingSystem">
      <mat-header-cell *matHeaderCellDef>OS</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.operatingSystem}}
      </mat-cell>
    </ng-container>
     <ng-container matColumnDef="cpu">
      <mat-header-cell style="width: fit-content;" *matHeaderCellDef>CPU</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.cpu}}
      </mat-cell>
    </ng-container> 
    <ng-container matColumnDef="ram">
      <mat-header-cell *matHeaderCellDef>RAM(GB)</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.ram}}
      </mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="pricePerHour">
      <mat-header-cell class="mat-decimal-cell" *matHeaderCellDef>Price/Hour($)</mat-header-cell>
      <mat-cell  class="mat-decimal-cell" *matCellDef="let row">
        {{row.pricePerHour | number:'1.3-3'}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="pricePerMonth">
      <mat-header-cell class="mat-decimal-cell" *matHeaderCellDef>Price/Month($)</mat-header-cell>
      <mat-cell  class="mat-decimal-cell" *matCellDef="let row">
        {{row.pricePerMonth  | number:'1.3-3'}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="annualPrice">
      <mat-header-cell class="mat-decimal-cell" *matHeaderCellDef>Annual Price($)</mat-header-cell>
      <mat-cell  class="mat-decimal-cell" *matCellDef="let row">
        {{row.annualPrice  | number:'1.3-3'}}
      </mat-cell>
    </ng-container> -->
    <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
</div>
</mat-card>
