import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SalesforceTestingService {

  constructor(private http: HttpClient) { }

  getData() {
    return this.http.get<any>(`/api/SalesforceTesting`);
  }

  runPipeline(id: string) {
    return this.http.get<any>(`/api/SalesforceTesting/runPipeline?testingID=${id}`);
  }

  addData(json: any) {
    return this.http.post<any>(`/api/SalesforceTesting`, json);
  }
}
