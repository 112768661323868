import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-migration-discovery-interfacedata',
  templateUrl: './cloud-migration-discovery-interfacedata.component.html',
  styleUrls: ['./cloud-migration-discovery-interfacedata.component.scss']
})
export class CloudMigrationDiscoveryInterfacedataComponent implements OnInit {
  @Input() interfacedata: any;

  tableDataSource: any;
  displayedColumns : string[] = ['inetIP','netMaskIp','broadcastIp','rxPackets','rxErrors','txPackets','txErrors', ];
  
  constructor() { }

  ngOnInit(): void {
    this.setData();
   console.log('interfacedata')
  }
  setData() {
    this.tableDataSource = this.interfacedata;
  }

}
