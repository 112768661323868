import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SupportService } from '../support.service';

@Component({
  selector: 'app-support-request-form',
  templateUrl: './support-request-form.component.html',
  styleUrls: ['./support-request-form.component.scss']
})
export class SupportRequestFormComponent implements OnInit {
  supportIssue: any;

  @ViewChild('form', { static: true })
  form: NgForm;
  isSuccessful: boolean;
  constructor(private supportService: SupportService) { }

  ngOnInit() {
    this.supportIssue = { 'levelOfSupport': null, 'description': '' };
  }
  submitIssue() {
    // const obj = { body: JSON.stringify(this.supportIssue), subject: 'Support Issue', sendTo: 'MITUPSupport@meridianitinc.com' };
    this.supportService.sendSupportEmail(this.supportIssue)
      .subscribe(res => {
        this.isSuccessful = true;
      });
  }

}
