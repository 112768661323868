<div *ngIf="model">
  <div class="col-md-4">
    <p>Name: {{vehicleName}}</p>
    <p>Current Mileage: {{currentMileage | number}}</p>
    <mat-form-field>
      <input matInput
             [(ngModel)]="model.vehicleUsageMileageEnd"
             placeholder="End Milage" />
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <button mat-raised-button
            (click)="updateMilage()"
            type="button">Update Milage</button>
  </div>

</div>