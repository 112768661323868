import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
export declare const EMPTY_GUID: string;

@Component({
  selector: 'app-generate-assessment',
  templateUrl: './generate-assessment.component.html',
  styleUrls: ['./generate-assessment.component.scss']
})
export class CloudMigrationGenerateAssessmentComponent implements OnInit {
  assessment: any;
  formTitle: string = 'Generate Assessment';
  assessmentCreateForm: UntypedFormGroup;
  paymentOptions: any = [];
  regions: any = [];
  discoveryId: any;
  clientId: any;
  projectId: string;
  cloudProviderOptions: any[];
  discoveryList: any;
  selection = new SelectionModel<any>(true, []);
  noRegionCloudProviderIds: any;


  constructor(private cloudMigrationService: CloudMigrationToolService, private dialogRef: MatDialogRef<CloudMigrationGenerateAssessmentComponent>,
    private router: Router, @Inject(MAT_DIALOG_DATA) private data: any) {

  }

  ngOnInit(): void {
    this.projectId = this.data?.projectId;
    this.assessment = this.data?.assessment;
    this.discoveryList = this.assessment?.discoveryList.rows;
    
    this.paymentOptions = [];
    this.regions = [];
    let formData = this.assessment;
    this.assessmentCreateForm = this.createFormGroup(formData);
    this.assessmentCreateForm.controls['region'].disable();
    this.cloudProviderOptions = formData?.cloudProviderRegions.map((s: any) => ({value: s.cloudProviderId, text: s.cloudProviderName}));
    this.paymentOptions = formData?.paymentOptions;
    
    let noRegionCloudProviders = formData?.cloudProviderRegions.filter(function (region: any){
      return region.cloudProviderName === 'Meridian Cloud'|| region.cloudProviderName === 'Skytap';
    });

    this.noRegionCloudProviderIds = noRegionCloudProviders.map((s: { cloudProviderId: any; }) => { return s.cloudProviderId});

    this.assessmentCreateForm.controls['cloudProviderName'].valueChanges.subscribe(cloudProviderId => { console.log(cloudProviderId)
      this.regions = formData?.cloudProviderRegions.find((obj: { cloudProviderId: string; }) => obj?.cloudProviderId == cloudProviderId)?.regions
      this.assessmentCreateForm.controls['region'].enable()
    });
  }

  createFormGroup(data: any): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(data?.id),
      title: new UntypedFormControl(data?.title, Validators.required),
      discoveryId: new UntypedFormControl(data?.discoveryId, Validators.required),
      cloudProviderName: new UntypedFormControl(data?.cloudProviderName, Validators.required),
      paymentOption: new UntypedFormControl(data?.paymentOption, Validators.required),
       region: new UntypedFormControl(data?.region, requiredIfValidator(() => !this.noRegionCloudProviderIds?.includes(this.assessmentCreateForm?.get('cloudProviderName')?.value.toLowerCase()))) ,
    })
  }

  close(res?: any) {
    console.log("closing..", res);
    if (res) {
      this.router.navigateByUrl(`cmt/projects/${this.projectId}/view/assessments/${this.assessment.id}`);
    }
    this.dialogRef.close(res);
  }

  save() {
    var formVal = this.assessmentCreateForm.value;
    const selectedDiscoveries = this.selection.selected.map(x=>x.id);
    var data = {
      'id': formVal.id,
      "title": formVal.title,
      "region": formVal.region,
      "paymentOption": formVal.paymentOption,
       "discoveryId": formVal.discoveryId,
      "projectId": this.projectId,
      "cloudProviderName": formVal.cloudProviderName,
      // "cloudProviderId": formVal.cloudProviderName,
      "selectedDiscoveries": selectedDiscoveries
    };
    this.cloudMigrationService.generateAssessment(data).subscribe({
      next: res => { this.assessment.id = res; this.close(res) },
    });

  }
  
}
function requiredIfValidator(predicate: { (): any; (): any; }) {
  return ((formControl: AbstractControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      
      return Validators.required(formControl); 
    }
    return null;
  })
}