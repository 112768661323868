<mat-card>
  <mat-card-content [class.hide-content]="!(employees && tempApprovers)">
    <a id="temporaryApproverCreate" mat-raised-button [routerLink]="['create']" color="primary"
       matTooltip="Create Temporary Approver" [matTooltipPosition]="'right'">
      <mat-icon class="add-icon">add</mat-icon>
      Temporary Approver
    </a>
    <br />
    <div class="col-md-12">
      <fieldset>
        <legend>
          Search Selection
        </legend>
        <form [formGroup]="filterForm">
          <mat-form-field>
            <mat-select formControlName="tempApprover" id="tempApprover" disableOptionCentering #employee placeholder="Filter By Temporary Approver Name">
              <mat-option [value]="''">
                None
              </mat-option>
              <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">
                {{emp.firstName + " " + emp.lastName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="backupEmployee" id="backupEmployee" disableOptionCentering #employee placeholder="Filter By Backup For Employee">
              <mat-option [value]="''">
                None
              </mat-option>
              <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">
                {{emp.firstName + " " + emp.lastName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="createdBy" id="createdBy" disableOptionCentering #employee placeholder="Filter By Created By">
              <mat-option [value]="''">
                None
              </mat-option>
              <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">
                {{emp.firstName + " " + emp.lastName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters" id="resetBtn">
            <mat-icon>clear</mat-icon>
          </button>
        </form>
      </fieldset>
    </div>
    <br />
    <div class="col-md-12">
      <mat-table #table [dataSource]="tempApproverDataSource" class="apply-border">
        <ng-container matColumnDef="temporaryApproverEmployeeId">
          <mat-header-cell *matHeaderCellDef> Temporary Approver </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span> {{this.employees | employeeFilter: element.temporaryApproverEmployeeId}}</span>

          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span> {{element.startDate | date:'shortDate'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <mat-header-cell *matHeaderCellDef> End Date </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span> {{element.endDate | date:'shortDate'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="approverForEmployeeId">
          <mat-header-cell *matHeaderCellDef> BackUp For Employee </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span> {{this.employees | employeeFilter: element.approverForEmployeeId}}</span>

          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <mat-header-cell *matHeaderCellDef> Created By</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: element.createdByEmployeeId}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element" id="kebabMenu">
            <ng-container>
              <button mat-icon-button [matMenuTriggerFor]="menu" [attr.data-id]="element.id">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="['edit', element.id]">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="deleteTemporaryApprover(element.id)">
                  <mat-icon>delete_outline</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <div>
      <mat-paginator #paginator [length]="tempApproverDataSource?.data.length" [pageIndex]="0" [pageSize]="50"
                     [pageSizeOptions]="[5, 25, 50, 100]">
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
