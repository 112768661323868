import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../admin.service';
import { EmployeeModelDataSource } from '../../models/employee-datasource';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {
  @Input() showInactive: boolean = false;
  employees: EmployeeModelDataSource;
  displayedColumns: Array<string>;

  constructor(private route: ActivatedRoute, private adminService: AdminService) { }

  ngOnInit() {

    this.displayedColumns = ['vehicleDriverName', 'vehicleDriverActions'];
    !this.showInactive ? this.getEmployees() : this.getInactiveEmployees();
  }
  deleteEmployee(id: string) {
    this.adminService.deleteEmployee(id)
      .subscribe(httpResult => {
        this.getEmployees();
      });
  }
  getEmployees() {
    this.adminService.getEmployees()
      .subscribe(httpResult => {

        const data = httpResult;
        this.employees = new EmployeeModelDataSource();
        this.employees.setData(data);

      });
  }
  activateEmployee(id: string) {
    this.adminService.activateEmployee(id)
      .subscribe(httpResult => {
        this.getInactiveEmployees();
      });
  }
  getInactiveEmployees() {
    this.adminService.getInactiveEmployees()
      .subscribe(httpResult => {

        const data = httpResult;       
        this.employees = new EmployeeModelDataSource();
        this.employees.setData(data);

      });
  }

}
