import { Injectable } from '@angular/core';

@Injectable()
export class ManagedServicesProviderService {
  phases: Array<string>;
  resourceTypes: Array<string>;
  serviesRates: Array<EstimateService>;
  constructor() {
    this.phases = [
      'Plan',
      'Execute',
      'Control',
      'Close',
      'Other'
    ];
    this.resourceTypes = [
      'MIT',
      'Business Partner'
    ];
    this.serviesRates = [
      { serviceRole: 'Business Apps / Software', serviceRate: 200 },
      { serviceRole: 'Collaboration (Avaya CM Basic)', serviceRate: 112 }
    ];
    // Fill up the database with 100 users.
  }

  /** Adds a new user to the database. */

  }


export class EstimateService {
  serviceRole: string;
  serviceRate: number;
  overrideRate?: number;
}
export class ServiceEstimateActivity {
  phase: string;
  activity: string;
  resourceType: string;
  bpName: string;
  estimatiedhours: number;
  estimatiedPrice: number;
  rate: number;
  serviceRate: EstimateService;
  overrideRate: number;
}
