import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { ReleaseNote, ReleaseNotesIteration } from '../release-notes/models/releaseNotes';
import { AzureDevOpsIteration } from '../../shared/models/iteration';
import { FilterByIterationIdPipe } from '../../shared/pipes/filter-by-iteration-id.pipe';
export class ReleaseNotesDataSource extends DataSource<any> {
    data: Array<any>;
    paginator: MatPaginator;
    sort: MatSort;
    nonFilteredData;
  iterations: Array<any>;
  filterByIterationId: FilterByIterationIdPipe;

  constructor(private releaseNotes: Array<ReleaseNote>, private _iterations: Array<AzureDevOpsIteration>, private _filterByIterationId: FilterByIterationIdPipe) {
        super();
      this.data = releaseNotes;
      this.nonFilteredData = releaseNotes;
    this.iterations = _iterations;
    this.filterByIterationId = _filterByIterationId;
    }

    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    connect(): Observable<any[]> {
        // Combine everything that affects the rendered data into one update
        // stream for the data-table to consume.
        const dataMutations = [
            observableOf(this.data),
            this.paginator.page,
            this.sort.sortChange
        ];

        return merge(...dataMutations).pipe(map(() => {
            return this.getPagedData(this.getSortedData([...this.data]));
        }));
    }

    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
  disconnect() { }

  filter(field: any, fullData: any) {
    let filterData: any[] = fullData;
    if (field['version']) {
      filterData = filterData.filter(x => {
        return x.version.indexOf(field['version']) > -1;
      });
    }
    if (field['iteration']) {
      filterData = filterData.filter(pc => {
        if (pc.iterations.length > 0) {
          let doesExist = false;
          pc.iterations.forEach((pcr: any) => {
            if (!doesExist) {
              doesExist = pcr.azureIterationId == field['iteration'];
            }
          });
          return doesExist;
        } else
          return false;
      });
    }
    if (field['startDate'] && field['endDate']) {
      filterData = filterData.filter(x => {
        return new Date(x.releaseDate) >= new Date(field['startDate']) && new Date(x.releaseDate) <= new Date(field['endDate']);
      });
    }
    return filterData;
  }
   
    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
  private getPagedData(data: ReleaseNote[]) {
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        return data.splice(startIndex, this.paginator.pageSize);
    }

    /**
     * Sort the data (client-side). If you're using server-side sorting,
     * this would be replaced by requesting the appropriate data from the server.
     */
  private getSortedData(data: ReleaseNote[]) {
        if (!this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const isAsc = this.sort.direction === 'asc';
          switch (this.sort.active) {
            case 'releaseDate': return compare(a.releaseDate, b.releaseDate, isAsc);
              case 'version': return compare(a.version, b.version, isAsc);
              case 'iteration': return this.compareIteration(a.iterations, b.iterations, isAsc, this.iterations);
                default: return 0;
            }
        });
  }

  compareIteration(a: Array<ReleaseNotesIteration>, b: Array<ReleaseNotesIteration>, isAsc: boolean, iterations: Array<AzureDevOpsIteration>) {
    let varA = this.filterByIterationId?.transform(iterations, a);
    let varB = this.filterByIterationId?.transform(iterations, b);

    if (varA.length > 0) {
      let _iterations: Array<any> = [];
      varA.forEach((item: any) => {
        _iterations.push(item.name);
      })
      varA = _iterations.join(",");
    } else {
      varA = 'z';
    }

    if (varB.length > 0) {
      let _iterations: Array<any> = [];
      varB.forEach((item: any) => {
        _iterations.push(item.name);
      })
      varB = _iterations.join(",");
    } else {
      varB = 'z';
    }

    let comparison = 0;
    if (varA.toUpperCase() > varB.toUpperCase()) {
      comparison = 1;
    } else if (varA.toUpperCase() < varB.toUpperCase()) {
      comparison = -1;
    }
    return (
      (isAsc == false) ? (comparison * -1) : comparison
    );
  }

}
/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


