<mat-card *ngIf="employees && tempApproverFormGroup">
  <form [formGroup]="tempApproverFormGroup" (ngSubmit)="submitForm()">
    <mat-card-content>
      <mat-form-field>
        <mat-select formControlName="temporaryApproverEmployeeId" placeholder="Select Temporary Approver" disableOptionCentering id="temporaryApproverEmployeeId">
          <mat-option *ngFor="let emp of employees" [value]="emp.employeeId" [disabled]="isDisabled('temporaryApproverEmployeeId',emp.employeeId)">{{emp.firstName + " " + emp.lastName}}</mat-option>
        </mat-select>
        <mat-error *ngIf="tempApproverFormGroup.controls['temporaryApproverEmployeeId'].hasError('required')">A temporary approver is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input [matDatepicker]="startDate" [max]="getMaxDate()" matInput placeholder="Start Date" type="datetime"
               name="startDate" formControlName="startDate" id="startDate" />
        <mat-error *ngIf="tempApproverFormGroup.controls['startDate'].hasError('required')">A start date is required</mat-error>
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input [matDatepicker]="endDate" [min]="getMinDate()" matInput placeholder="End Date" type="datetime"
               name="endDate" formControlName="endDate" id="endDate" />
        <mat-error *ngIf="tempApproverFormGroup.controls['endDate'].hasError('required')">A end date is required</mat-error>
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-select formControlName="approverForEmployeeId" placeholder="Select Approving For" disableOptionCentering id="approverForEmployeeId">
          <mat-option *ngFor="let emp of employees" [value]="emp.employeeId" [disabled]="isDisabled('approverForEmployeeId',emp.employeeId)">{{emp.firstName + " " + emp.lastName}}</mat-option>
        </mat-select>
        <mat-error *ngIf="tempApproverFormGroup.controls['approverForEmployeeId'].hasError('required')">A employee is required</mat-error>
      </mat-form-field>
      <div class="row" *ngIf="tempApproverId">
        <h4 class="col-sm-1">Service Roles -</h4>
        <mat-list class="col-sm-5" *ngIf="serviceRoles.length > 0">
          <mat-list-item *ngFor="let role of serviceRoles">{{role}}</mat-list-item>
        </mat-list>
        <mat-list class="col-sm-5" *ngIf="serviceRoles.length == 0">
          <mat-list-item>Not assigned to any Service role</mat-list-item>
        </mat-list>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" [disabled]="!tempApproverFormGroup.valid" id="saveBtn">Save</button>
    </mat-card-actions>
  </form>
</mat-card>
