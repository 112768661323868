<app-breadcrumb></app-breadcrumb>
<mat-card>
  <mat-card-content>
    <nav mat-tab-nav-bar *ngIf="isAdmin()">
      <a mat-tab-link [routerLink]="['home']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Home</a>
      <a mat-tab-link [routerLink]="['vehicles']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Vehicle Management</a>
      <a mat-tab-link [routerLink]="['models']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Models</a>
      <a mat-tab-link [routerLink]="['insurance']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Insurance Policy Management</a>
      <a mat-tab-link [routerLink]="['schedule']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Maintenance Schedule</a>
      <a mat-tab-link [routerLink]="['drivers']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Drivers</a>
      <a mat-tab-link [routerLink]="['retired']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Retired</a>
    </nav>
    <nav mat-tab-nav-bar *ngIf="isUser()">
      <a mat-tab-link [routerLink]="['home']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Home</a>
      <a mat-tab-link [routerLink]="['vehicles']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Vehicle Management</a>
      <a mat-tab-link [routerLink]="['schedule']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Maintenance Schedule</a>
    </nav>
    <nav mat-tab-nav-bar *ngIf="isInsuranceAdmin()">
      <a mat-tab-link [routerLink]="['vehicles']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Vehicle Management</a>
      <a mat-tab-link [routerLink]="['insurance']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">nsurance Policy Management</a>
      <a mat-tab-link [routerLink]="['drivers']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }">Drivers</a>
    </nav>
    <router-outlet></router-outlet>
  </mat-card-content>
</mat-card>