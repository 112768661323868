<!-- <form> -->
<h3 mat-dialog-title>Business Partner Score Card</h3>
<p><small>1 is the worst and 5 is the best</small></p>
<form [formGroup]="scorecardForm" (ngSubmit)="saveCard()">
    <mat-dialog-content class="mat-typography">
        <mat-form-field>
            <mat-label>Business Partner</mat-label>
            <mat-select formControlName="businessPartnerId" disableOptionCentering id="businessPartner">
                <mat-option *ngFor="let businessPartner of businessPartners" [value]="businessPartner.id">
                    {{businessPartner.businessPartnerLegalName}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Solution Pillar</mat-label>
            <mat-select formControlName="solutionPilarId" disableOptionCentering id="solPilar">
                <mat-option *ngFor="let solutionPilar of solutionPilars" [value]="solutionPilar.solutionPilarId">
                    {{solutionPilar.solutionPilarName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <h4> Invoicing </h4>
        <mat-checkbox  formControlName="isInvoicingExcluded"
            labelPosition="before">Exclude from rating</mat-checkbox>
        <br />
        <mat-form-field>
            <mat-label>Quality</mat-label>
            <mat-select formControlName="invoicingQuality" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
            </mat-select>
            <mat-hint>Accuracy and level of detail</mat-hint>
        </mat-form-field>
        <br />
        <mat-form-field>
            <mat-label>Timeliness</mat-label>
            <mat-select formControlName="invoicingTimeliness" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-divider></mat-divider>
        <h4> Pre-Sales </h4>
        <mat-checkbox formControlName="isPreSalesExcluded" labelPosition="before">
            Exclude from rating</mat-checkbox>
        <br />
        <mat-form-field>
            <mat-label>Accuracy and level of detail in SOW</mat-label>
            <mat-select formControlName="preSalesAcurracyAndDetail" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
            </mat-select>
        </mat-form-field>
        <br />
        <mat-form-field>
            <mat-label>Timeliness of delivering SOW to MIT</mat-label>
            <mat-select formControlName="preSalesTimeliness" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-divider></mat-divider>
        <h4> Post-Sales </h4>
        <mat-checkbox formControlName="isPostSalesExcluded"
            labelPosition="before">Exclude from rating</mat-checkbox>
        <br />
        <mat-form-field>
            <mat-label>Technical skills and quality of engineering work</mat-label>
            <mat-select formControlName="postSalesSkillsAndQuality" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
            </mat-select>
        </mat-form-field>
        <br />
        <mat-form-field>
            <mat-label>Technical delivery timeliness: satisfactory/poor</mat-label>
            <mat-select formControlName="postSalesTimeliness" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-divider></mat-divider>
        <h4> Documentation </h4>
        <mat-checkbox formControlName="isDocumentationExcluded"
            labelPosition="before">
            Exclude from rating
        </mat-checkbox>
        <br />
        <mat-form-field>
            <mat-label>Quality</mat-label>
            <mat-select formControlName="documentationQuality" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
            </mat-select>
            <mat-hint>Accuracy and level of detail</mat-hint>
        </mat-form-field>
        <br />
        <mat-form-field>
            <mat-label>Timeliness</mat-label>
            <mat-select formControlName="documentationTimeliness" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-divider></mat-divider>
        <h4> Communication </h4>
        <mat-checkbox  formControlName="isCommunicationExcluded"
            labelPosition="before">
            Exclude from rating
        </mat-checkbox>
        <br />
        <mat-form-field>
            <mat-label>Quality</mat-label>
            <mat-select formControlName="communicationQuality" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
            </mat-select>
        </mat-form-field>
        <br />
        <mat-form-field>
            <mat-label>Timeliness</mat-label>
            <mat-select formControlName="communicationTimeliness" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-divider></mat-divider>
        <mat-form-field>
            <mat-label>Comments</mat-label>
            <textarea matInput formControlName="comments"></textarea>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button type="button" type="submit" *ngIf="!disabled">Save</button>
        <button mat-raised-button type="button" type="button" (click)="markAsComplete()" *ngIf="!disabled">Mark
            Complete</button>
        <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</form>
<!-- </form> -->
