import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BuildInfoService } from '../shared/services/build-info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  buildVersion$: Observable<any>;
  year: number;
  constructor(private buildInfoService: BuildInfoService) { }

  ngOnInit(): void {
    this.buildInfoService.data$.subscribe( data => {
      this.buildVersion$ = of(data);
    }) 
    this.year = this.getYear();
  }
  getYear() {
    return new Date().getFullYear();
  }
}
