import { Component, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ProjectControlDataSource } from '../../models/project-control.datasource';
import { ProjectControlService } from '../project-control.service';
import { ConfirmDeleteDialogComponent } from 'src/app/shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ProjectControlRateService } from '../../project-control-rate/project-control-rate.service';
import { ProjectControlRate } from '../../models/service-role';
import { AdminService } from '../../admin.service';
import { forkJoin } from 'rxjs';
import { Employee } from '../../../employee/employee-list/employee-list-datasource';
import { MatSort } from '@angular/material/sort';
import { SessionStorageService } from '../../../shared/services/session-storage.service';

@Component({
  selector: 'app-project-control-list',
  templateUrl: './project-control-list.component.html',
  styleUrls: ['./project-control-list.component.scss']
})
export class ProjectControlListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  projectControlDataSource: ProjectControlDataSource;
  projectControls: any;
  displayedColumns: Array<string>;
  filterForm: UntypedFormGroup;
  projectControlRates: Array<ProjectControlRate>;
  employees: Employee[];
  sessionInfo: any;
  sessionStorageKey: string;

  constructor(private projectControlService: ProjectControlService, private dialog: MatDialog, private fb: UntypedFormBuilder, private projectControlRateService: ProjectControlRateService, private adminService: AdminService, private sessionStorageService: SessionStorageService) { }

  ngOnInit() {
    this.displayedColumns = [
      'projectControlName',
      'projectControlRates',
      'projectControlDescription',
      'createdBy',
      'projectControlActions'
    ];
    this.filterForm = this.fb.group({
      projectControlName: [''],
      projectControlRate: [''],
      createdBy: ['']
    });
    this.sessionStorageKey = 'projectControlData';
    let storageData = this.sessionStorageService.fetchStorageData(this.sessionStorageKey);
    if (storageData) {
      this.sessionInfo = this.sessionStorageService.parseStorageData(storageData);
    } else {
      this.sessionInfo = this.filterForm.value;
      this.sessionStorageService.setStorageData(this.sessionStorageKey, this.filterForm.value);
    }
    this.onFormValueChange();
    this.getProjectControlRatesAndEmployees();
    this.getProjectControls();
  }

  getProjectControlRatesAndEmployees() {
    let employees = this.adminService.getAllEmployees();
    let projectControlRates = this.projectControlRateService.getProjectControlRates()
    forkJoin([employees, projectControlRates])
      .subscribe(res => {
        this.employees = res[0];
        this.projectControlRates = res[1];
      });
  }

  onFormValueChange() {
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.projectControlDataSource = new ProjectControlDataSource(this.projectControlDataSource.filter(res, this.projectControls));
        this.sessionInfo = this.filterForm.value;
        this.sessionStorageService.setStorageData(this.sessionStorageKey, this.sessionInfo);
        this.setProjectControlsData(this.projectControlDataSource.data);
      });
  }

  getProjectControls() {
    this.projectControlService.getProjectControls()
      .subscribe(res => {
        this.projectControls = res;
        this.setProjectControlsData(res);
        this.updateForm();

      });
  }

  setProjectControlsData(data?: any) {
    this.projectControlDataSource = new ProjectControlDataSource(data);
    this.projectControlDataSource.paginator = this.paginator;
    this.projectControlDataSource.sort = this.sort;
  }

  deleteProjectControl(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.projectControlService.deleteProjectControl(id)
            .subscribe(deleteResult => {
              this.getProjectControls();
            });
        }
      })
  }

  sortData(sort: Sort) {
  }

  resetFilters() {
    this.filterForm.controls['projectControlName'].setValue('');
    this.filterForm.controls['projectControlRate'].setValue('');
    this.filterForm.controls['createdBy'].setValue('');
  }

  updateForm() {
    this.filterForm.patchValue({
      projectControlName: this.sessionInfo['projectControlName'] ? this.sessionInfo['projectControlName'] : '',
      createdBy: this.sessionInfo['createdBy'] == null || this.sessionInfo['createdBy'].toString() == "" ? '' : this.sessionInfo['createdBy'],
      projectControlRate: this.sessionInfo['projectControlRate'] == null || this.sessionInfo['projectControlRate'].toString() == "" ? '' : this.sessionInfo['projectControlRate']
    });
  }

}
