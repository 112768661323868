import { Component, OnInit } from '@angular/core';
import { VehicleDriverDataSource } from '../../models/vehicle-driver-datasource';
import { FleetMangementService } from '../../fleet-mangement.service';

@Component({
  selector: 'app-drivers-list',
  templateUrl: './drivers-list.component.html',
  styleUrls: ['./drivers-list.component.scss']
})
export class DriversListComponent implements OnInit {
  displayedColumns: Array<string>;
  dataSource: VehicleDriverDataSource;
  constructor(private driversService: FleetMangementService) { }

  ngOnInit() {
    this.displayedColumns = ['vehicleDriverName', 'vehicleDriverActions'];
    this.getDrivers();
  }
  deleteDriver(id: string) {
    this.driversService.deleteDriver(id)
      .subscribe(httpRes => {
        this.getDrivers();
      });
  }
  getDrivers() {
    this.driversService.getDrivers()
      .subscribe(getDriversResult => {
        const data = getDriversResult;        
        this.dataSource = new VehicleDriverDataSource();
        if (data) {
          this.dataSource.setData(data);
        }
      });
  }

}
