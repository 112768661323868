import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectControlRateService } from '../project-control-rate.service';
import { ProjectControlRate } from '../../models/service-role';

@Component({
  selector: 'app-project-control-rate-detail',
  templateUrl: './project-control-rate-detail.component.html',
  styleUrls: ['./project-control-rate-detail.component.scss']
})
export class ProjectControlRateDetailComponent implements OnInit {
  projectControlRate: ProjectControlRate;
  constructor(private route: ActivatedRoute, private projectControlRateService: ProjectControlRateService) { }

  ngOnInit() {
    this.route.params
      .subscribe(routeParams => {
        const id = routeParams['id'];
        this.projectControlRateService.getProjectControlRate(id)
          .subscribe(res => {
              this.projectControlRate = res;
            
          });
      });
  }

}
