<app-breadcrumb></app-breadcrumb>
<mat-card>
  <mat-card-title>
    Reports
  </mat-card-title>
  <mat-card-content>
      <nav mat-tab-nav-bar>
        <a mat-tab-link routerLink="businessPartnerReports" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">Business Partners</a>
        <a mat-tab-link routerLink="serviceEstimateReports" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">Projects</a>
        <a mat-tab-link routerLink="fleetManagementReports" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">Fleet Management</a>
      </nav>
        <router-outlet></router-outlet>
  </mat-card-content>
</mat-card>
