import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ServiceEstimateService } from './service-estimate.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ServiceEstimateDataSource } from '../../../professional-services/models/service-estimate.datasource';
import { MatSort } from '@angular/material/sort';

@Component({
  templateUrl: './service-estimate-popup.component.html',
  styleUrls: ['./service-estimate-popup.component.scss']
})
export class ServiceEstimatePopupComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  oppurtunityNumber: string;
  dataSource: ServiceEstimateDataSource;
  displayedColumns: Array<string>;

  constructor(public dialogRef: MatDialogRef<ServiceEstimatePopupComponent>, private serviceEstimateService: ServiceEstimateService) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'projectName',
      'createdDate',
      'status',
      'expirationDate',
      'actions'
    ]
    this.oppurtunityNumber = "";
  }

  searchSEs() {
    if (this.oppurtunityNumber != "") {
      this.serviceEstimateService.getServiceEstimates(this.oppurtunityNumber).subscribe(res => {
        const data = res;
        this.dataSource = new ServiceEstimateDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    } else {
      this.dataSource = new ServiceEstimateDataSource([]);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
  closePopup(data: string) {
    this.dialogRef.close({ data });
  }
}
