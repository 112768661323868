import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BusinessPartnerService } from '../business-partner.service';
import { BusinessPartner, ServiceableCity, City } from '../models/bp-model';
import { Employee } from '../../shared/models/employee';
import { SolutionPilar } from '../models/solution-pilar';
import { LocationService } from '../../shared/services/location.service';
import { signers, requestors } from '../models/requestors.model';
import { Location } from '@angular/common';
import { BusinessPartnerScoreCardAverage } from '../models/business-partner-scorecard-average.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ScoreCardPopupComponent } from '../../shared/components/score-card-popup/score-card-popup.component';
import { Tag } from '../../tags/models/tag.model';
import { TagsService } from '../../tags/tags.service';
import { Observable, forkJoin, of } from 'rxjs';
import { BusinessPartnersStatusPopupComponent } from '../business-partners-status-popup/business-partners-status-popup.component';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'app-business-partner-edit',
  templateUrl: './business-partner-edit.component.html',
  styleUrls: ['./business-partner-edit.component.scss']
})
export class BusinessPartnerEditComponent implements OnInit {
  businessPartnerForm: UntypedFormGroup;
  filteredRequestorEmployees: Array<any>;
  filteredSignedByEmployees: Array<any>;
  tempList: Array<Employee>;
  solutionPilars: Array<SolutionPilar>;
  tags: Array<Tag>;
  businessAgreements: UntypedFormArray;
  addresses: UntypedFormArray;
  contacts: UntypedFormArray;
  serviceableStatesFormArray: UntypedFormArray;
  serviceableCitiesFormArray: UntypedFormArray;
  tagsFormArray: UntypedFormArray;
  solutionPilarsBusinessPartners: UntypedFormArray;
  states: Array<any>;
  cities: Array<any>;
  serviceableCities: Array<any>;
  mappedServiceableCities : Array<any>;
  cityFormControl: UntypedFormControl;
  primaryLocation: UntypedFormGroup;
  filteredCities: Array<any>;
  // filteredServiceableCities: Array<any>;
  businessPartner: BusinessPartner;
  disableSubmit: boolean;
  businessPartnerScoreCardAverage: BusinessPartnerScoreCardAverage;
  overallRating: number;
  scorecardDatasource: any[];
  citiesAutoCompleteOptions: Observable<Array<City>>;
  serviceableCitiesAutoCompleteOptions: Array<Observable<Array<City>>>;
  displayedColumns: string[] = ['projectName', 'solutionPillar', 'createdBy', 'createdDate', 'actions'];
  isViewOnly: boolean;
  filterStrings: string[] = ['','',''];
  constructor(
    private dialog: MatDialog,
    private businessPartnerService: BusinessPartnerService,
    private fb: UntypedFormBuilder,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private location: Location,
    private tagsService: TagsService) { }

  ngOnInit() {
    this.disableSubmit = true;
    this.locationService.getStates()
      .subscribe(res => {
        this.states = res;
      });
    this.businessAgreements = this.fb.array([]);
    this.addresses = this.fb.array([]);
    this.contacts = this.fb.array([]);
    this.solutionPilarsBusinessPartners = this.fb.array([]);
    this.serviceableStatesFormArray = this.fb.array([]);
    this.serviceableCitiesFormArray = this.fb.array([]);
    this.tagsFormArray = this.fb.array([]);
    this.serviceableCitiesAutoCompleteOptions = [];
    this.filteredRequestorEmployees = requestors;
    this.filteredSignedByEmployees = signers;
    this.businessPartnerService.getSolutionPilars()
      .subscribe(res => {
        this.solutionPilars = res;

      });
    this.tagsService.getTags()
      .subscribe(res => {
        this.tags = res.sort((a, b) => {
          return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
        });
      });
    this.route.data.subscribe(data => {
      this.isViewOnly = data.isViewOnly;
    })

    this.route.params.subscribe(params => {
      const id = params['id'];
      this.businessPartnerService.getBusinessPartnerScoreCardAverage(id)
        .subscribe(res => {
          this.businessPartnerScoreCardAverage = res;
        });

      this.businessPartnerService.getBusinessPartnerScoreCardAverageTotal(id)
        .subscribe(res => {
          this.overallRating = res;
        });
      this.businessPartnerService.getBusinessPartnerScoreCardsByBusinessPartnerId(id)
        .subscribe(res => {
          this.scorecardDatasource = res;
        });
      this.businessPartnerService.getBusinessPartner(id)
        .subscribe(res => {
          const bp = res;
          this.businessPartner = bp;

          if (!this.businessPartner) {
            this.businessPartner = new BusinessPartner();
          }
          this.businessPartner.locations.forEach(l => {
            if (l.isPrimary) {
              this.businessPartner.primaryLocation = l;
            } else {
              this.addAddressItem(l);
            }
          });
          let states = this.businessPartner.serviceableStates.map((x: any) => x.stateId);
          let fetchServiceableCities = this.locationService.getCitiesByStateIds(states);
          let fetchPrimaryCities = this.locationService.getCitiesByStateId(this.businessPartner.primaryLocation.stateId);
          forkJoin([fetchPrimaryCities, fetchServiceableCities])
          .subscribe(results => {
            this.serviceableCities = results[1];
            this.cities = results[0];
            this.filteredCities = [];
            // this.filteredServiceableCities = [];
            Object.assign(this.filteredCities, this.cities);
            const isInternational: boolean = this.businessPartner.primaryLocation?.isInternational;
            this.primaryLocation = this.fb.group({
              id: [this.businessPartner.primaryLocation ? this.businessPartner.primaryLocation.id : null, Validators.required],
              'streetOne': [this.businessPartner.primaryLocation ? this.businessPartner.primaryLocation.streetOne : null, Validators.required],
              'streetTwo': [this.businessPartner.primaryLocation ? this.businessPartner.primaryLocation.streetTwo : null],
              'stateId': [this.businessPartner.primaryLocation ? this.businessPartner.primaryLocation.stateId : null, isInternational ? null : Validators.required],
              'cityId': [this.businessPartner.primaryLocation ? this.businessPartner.primaryLocation.cityId : null, isInternational ? null : Validators.required],
              'zipCode': [this.businessPartner.primaryLocation ? this.businessPartner.primaryLocation.zipCode : null, isInternational ? Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9\-\ ]+$'), Validators.maxLength(10)]) : Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'), Validators.maxLength(10)])],
              'businessPartnerId': [this.businessPartner.id],
              'isPrimary': [true],
              'isInternational': [this.businessPartner.primaryLocation ? this.businessPartner.primaryLocation.isInternational : null, Validators.required],
              country: [this.businessPartner.primaryLocation.country ? this.businessPartner.primaryLocation.country : null],
              city: [this.businessPartner.primaryLocation.city ? this.businessPartner.primaryLocation.city : null],
              state: [this.businessPartner.primaryLocation.state ? this.businessPartner.primaryLocation.state : null]
            });
            this.primaryLocation.controls['stateId'].valueChanges
              .subscribe(v => {
                this.primaryLocation.controls["cityId"].patchValue('');
                this.getCitiesByStateId(v);
              });
            this.primaryLocation.controls['isInternational'].valueChanges.subscribe(isInternational => {
              if (isInternational) {
                this.primaryLocation.controls["country"].setValidators(Validators.required);
                this.primaryLocation.controls["state"].setValidators(Validators.required);
                this.primaryLocation.controls["city"].setValidators(Validators.required);
                this.primaryLocation.controls["zipCode"].setValidators(Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9\-\ ]+$'), Validators.maxLength(10)]));
                this.primaryLocation.controls["stateId"].clearValidators();
                this.primaryLocation.controls["stateId"].patchValue(null);
                this.primaryLocation.controls["cityId"].clearValidators();
                this.primaryLocation.controls["cityId"].patchValue(null);
                this.primaryLocation.controls["country"].updateValueAndValidity();
                this.primaryLocation.controls["state"].updateValueAndValidity();
                this.primaryLocation.controls["city"].updateValueAndValidity();
                this.primaryLocation.controls["zipCode"].updateValueAndValidity();
              } else {
                this.primaryLocation.controls["stateId"].setValidators(Validators.required);
                this.primaryLocation.controls["cityId"].setValidators(Validators.required);
                this.primaryLocation.controls["zipCode"].setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'), Validators.maxLength(10)]));
                this.primaryLocation.controls["country"].clearValidators();
                this.primaryLocation.controls["country"].patchValue("");
                this.primaryLocation.controls["state"].clearValidators();
                this.primaryLocation.controls["state"].patchValue("");
                this.primaryLocation.controls["city"].clearValidators();
                this.primaryLocation.controls["city"].patchValue("");
                this.primaryLocation.controls["stateId"].updateValueAndValidity();
                this.primaryLocation.controls["cityId"].updateValueAndValidity();
                this.primaryLocation.controls["zipCode"].updateValueAndValidity();
              }
            })

            this.primaryLocation.controls["cityId"].valueChanges
              .subscribe(updatedSelectedCity => {
                if (this.cities) {
                  if (updatedSelectedCity) {
                    if (typeof updatedSelectedCity == 'string') {
                      this.filteredCities = this.cities.filter(s => s.name.toLowerCase().includes(updatedSelectedCity.toLowerCase()));
                      this.citiesAutoCompleteOptions = of(this.filteredCities);
                    } else {
                      this.citiesAutoCompleteOptions = of([]);
                    }
                  }
                }
              });

            if (this.businessPartner.agreements) {
              this.businessPartner.agreements.forEach(a => {
                this.addAgreementItem(a);
              });
            }
            if (this.businessPartner.contacts) {
              this.businessPartner.contacts.forEach(c => {
                this.addContactItem(c);
              });
            }
            if (this.businessPartner.serviceableStates) {
              this.businessPartner.serviceableStates.forEach(s => {
                this.addServiceableStateItem(s);
              });
            }
            if (this.businessPartner.serviceableCities) {
              this.businessPartner.serviceableCities?.forEach((selectedCity: any, index: number) => {
                this.buildServiceableCityItem(selectedCity);
              });
            }
            if (this.businessPartner.businessPartnerTags) {
              this.businessPartner.businessPartnerTags.forEach(tag => {
                this.addTagItem(tag);
              });
            }
            if (this.businessPartner.solutionPilarsBusinessPartners) {
              this.businessPartner.solutionPilarsBusinessPartners.forEach(sp => {
                this.addSolutionPilarItem(sp);
              });
            }
            this.businessPartnerForm = this.fb.group({
              id: [this.businessPartner.id],
              businessPartnerLegalName: [this.businessPartner.businessPartnerLegalName, Validators.required],
              websiteUri: [this.businessPartner.websiteUri],
              businessPartnerStatus: [this.businessPartner.businessPartnerStatus],
              requestor: [this.businessPartner.requestor, Validators.required],
              scoreCard: [{ value: this.businessPartner.scoreCardId, disabled: true }],
              notes: [this.businessPartner.notes],
              w9Date: [this.businessPartner.w9Date],
              supplierNumber: [this.businessPartner.supplierNumber],
              primaryLocation: this.primaryLocation,
              liabilityLimit: [this.businessPartner.liabilityLimit],
              workersCompLimit: [this.businessPartner.workersCompLimit],
              workersCompExpiration: [this.businessPartner.workersCompExpiration],
              generalLiabilityExpirationDate: [this.businessPartner.generalLiabilityExpirationDate],
              serviceableStates: this.serviceableStatesFormArray,
              serviceableCities: this.serviceableCitiesFormArray,
              businessPartnerTags: this.tagsFormArray,
              contacts: this.contacts,
              additionalLocations: this.addresses,
              agreements: this.businessAgreements,
              solutionPilarsBusinessPartners: this.solutionPilarsBusinessPartners,
              cyberSecurityLiabilityLimit: [this.businessPartner.cyberSecurityLiabilityLimit],
              cyberSecurityExpirationDate: [this.businessPartner.cyberSecurityExpirationDate],
              statusNotes: [this.businessPartner.statusNotes]
            });

            this.businessPartnerForm.markAllAsTouched();
            this.businessPartnerForm.valueChanges.subscribe(fs => {
              this.disableSubmit = !this.businessPartnerForm.valid;
            });

            this.businessPartnerForm.controls['businessPartnerStatus'].valueChanges.subscribe(bpStatus => {
              if (bpStatus == 2) {
                this.businessPartnerForm.controls['statusNotes'].setValidators(Validators.required);
                this.businessPartnerForm.controls['statusNotes'].updateValueAndValidity();
                this.openBPStatusPopup();
              }
              else {
                this.clearStatusNotes();
              }
            });
            if (this.isViewOnly) {
              this.businessPartnerForm.disable({ emitEvent: false });
            }

          });
        });
    });
  }

  onStateChange(){
    this.setServiceableCities();
  }

  setServiceableCities() {
    let states: any[] = this.businessPartnerForm.controls.serviceableStates.value.map((x: any) => x.stateId).filter((x: any) => x != null);
    this.getCities(states);
  }
  getCities(states: any[]){
    this.locationService.getCitiesByStateIds(states)
      .subscribe(res => {
        const cities = res;
        this.serviceableCities = cities;
        // Object.assign(this.filteredServiceableCities, this.cities);
      });
  }
  getServiceableCities(index: number){
    let stateId = this.serviceableStatesFormArray.controls[index].value.stateId;
    let citiesSelected = this.serviceableStatesFormArray.controls[index].value.city;

    let filteredCities = this.serviceableCities?.filter(x => x.stateId == stateId);
    if (this.filterStrings[index]) {
      filteredCities = filteredCities?.filter(city => city.name.toLowerCase().includes(this.filterStrings[index].toLowerCase()));
    }

    // Check if citiesSelectedArray exists and is not empty
    if (citiesSelected && citiesSelected.length > 0) {
      filteredCities?.forEach(city => {
        // Check if the cityId exists in citiesSelectedArray
        if (citiesSelected.includes(city.cityId)) {
          // Update the 'checked' property to true
          city.selected = true;
        }
      });
    }
    else {
      filteredCities?.forEach(city => {
        city.selected = false;
      });
    }

    return filteredCities;
  }



  onInputChange(inputValue: string, index: number) {
    this.filterStrings[index] = inputValue;
  }

  getFormValidationErrors(controls: any): any[] {
    let errors: any[] = [];
    Object.keys(controls).forEach(key => {
      const control = controls[key];
      if (control instanceof UntypedFormGroup) {
        errors = errors.concat(this.getFormValidationErrors(control.controls));
      }
      const controlErrors: any = controls[key].errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          errors.push({
            control_name: key,
            error_name: keyError,
            error_value: controlErrors[keyError]
          });
        });
      }
    });
    return errors;
  }
  getCitiesByStateId(v: any) {
    this.locationService.getCitiesByStateId(v)
      .subscribe(citiesResult => {
        this.cities = citiesResult;
        this.filteredCities = [];
        Object.assign(this.filteredCities, this.cities);
        this.citiesAutoCompleteOptions = of(this.cities);
      });
  }
  displayAutoCompleteForCity(input: any){
    const city = this.cities?.find( city => city.cityId == input);
    return city && city.name ? city.name : '';
  }
  displayAutoCompleteForServiceableCity(index: number) {
    let stateId = this.serviceableStatesFormArray.controls[index].value.stateId;
    let cities = this.serviceableCities?.filter(x => x.stateId == stateId);
    let cityIds: string[] = (<UntypedFormGroup>this.serviceableStatesFormArray.controls[index])?.controls['city'].value;
    if (cities && cityIds) {
      // Filter cities based on cityIds and retrieve their names
      let selectedCities = cities.filter(city => cityIds.includes(city.cityId));

      // Extract city names
      let cityNames = selectedCities.map(city => city.name);

      // Create a comma-separated string of city names
      let commaSeparatedNames = cityNames.join(', ');

      return commaSeparatedNames;
    }

    return ''; // Return empty string if cities or cityIds are not available
  }

  addAgreementItem(item: any) {
    if (!this.businessAgreements) {
      this.businessAgreements = this.fb.array([]);
    }
    this.businessAgreements.push(this.buildAgreemtnItem(item));
  }
  addContactItem(item: any) {
    if (!this.contacts) {
      this.contacts = this.fb.array([]);
    }
    this.contacts.push(this.buildContactItem(item));
  }
  addAddressItem(item: any) {
    if (!this.addresses) {
      this.addresses = this.fb.array([]);
    }
    this.addresses.push(this.buildAdressItem(item));
  }
  addSolutionPilarItem(item: any) {
    if (!this.solutionPilarsBusinessPartners) {
      this.solutionPilarsBusinessPartners = this.fb.array([]);
    }
    this.solutionPilarsBusinessPartners.push(this.buildSolutionPilarItem(item));
  }
  addServiceableStateItem(item: any) {
    this.mappedServiceableCities = this.serviceableCities?.filter(sc=>this.businessPartner.serviceableCities.map(bpsp=>bpsp.cityId).includes(sc.cityId));
    if (!this.serviceableStatesFormArray) {
      this.serviceableStatesFormArray = this.fb.array([]);
    }
    this.serviceableStatesFormArray.push(this.buildServiceableStateItem(item));
  }
  addServiceableCityItem(item: any) {
    if (!this.serviceableCitiesFormArray) {
      this.serviceableCitiesFormArray = this.fb.array([]);
    }
    this.serviceableCitiesFormArray.push(this.buildServiceableCityItem(item));
  }
  addTagItem(item: any) {
    if (!this.tagsFormArray) {
      this.tagsFormArray = this.fb.array([]);
    }
    this.tagsFormArray.push(this.buildTagItem(item));
  }
  buildContactItem(contactItem?: any) {
    let item: UntypedFormGroup;
    if (contactItem) {
      item = this.fb.group({
        id: [contactItem.id],
        'firstName': [contactItem.firstName, Validators.required],
        'lastName': [contactItem.lastName, Validators.required],
        'title': contactItem.title,
        'email': contactItem.email,
        'primaryPhoneNumber': [contactItem.primaryPhoneNumber, Validators.compose([Validators.required, Validators.pattern('[0-9]{10,}')])],
        'secondaryPhoneNumber': [contactItem.secondaryPhoneNumber, Validators.pattern('[0-9]{10,}')],
        'faxNumber': contactItem.faxNumber,
        'isPrimaryContact': contactItem.isPrimaryContact
      });
    } else {
      item = this.fb.group({
        'firstName': [null, Validators.required],
        'lastName': [null, Validators.required],
        'title': null,
        'email': null,
        'primaryPhoneNumber': [null, Validators.compose([Validators.required, Validators.pattern('[0-9]{10,}')])],
        'secondaryPhoneNumber': [null, Validators.pattern('[0-9]{10,}')],
        'faxNumber': null,
        'isPrimaryContact': null
      });
    }
    item.markAllAsTouched();
    return item;
  }
  buildAdressItem(addressItem?: any) {
    let item: UntypedFormGroup;
    if (addressItem) {
      item = this.fb.group({
        id: [addressItem.id],
        'streetOne': [addressItem.streetOne, Validators.required],
        'streetTwo': [addressItem.streetTwo],
        'cityId': [addressItem.cityId, Validators.required],
        'stateId': [addressItem.stateId, Validators.required],
        'zipCode': [addressItem.zipCode, addressItem.isInternational ? Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9\-\ ]+$'), Validators.maxLength(10)]) : Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'), Validators.maxLength(10)])],
        'businessPartnerId': [this.businessPartner.id],
        isPrimary: false,
        'isInternational': [addressItem.isInternational],
        country: [addressItem.country],
        city: [addressItem.city],
        state: [addressItem.state]
      });
    } else {
      item = this.fb.group({
        'streetOne': [null, Validators.required],
        'streetTwo': [null],
        'cityId': [null, Validators.required],
        'stateId': [null, Validators.required],
        'zipCode': [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'), Validators.maxLength(10)])],
        'businessPartnerId': [this.businessPartner.id],
        isPrimary: false,
        'isInternational': [false],
        country: [''],
        city: [''],
        state: ['']
      });
    }
    this.businessPartnerPostalCodeValidationOnGroup(item);
    item.markAllAsTouched();
    return item;
  }
  buildAgreemtnItem(agreementItem?: any) {
    let item: UntypedFormGroup;
    if (agreementItem) {
      item = this.fb.group({
        id: [agreementItem.id],
        'agreementType': agreementItem.agreementType,
        'dateOnAgreement': agreementItem.dateOnAgreement,
        'dateSignedByMit': agreementItem.dateSignedByMit,
        'signer': agreementItem.signer,
        'dateAgreementSent': agreementItem.dateAgreementSent
      });
    } else {
      item = this.fb.group({
        'agreementType': 0,
        'dateOnAgreement': null,
        'dateSignedByMit': null,
        'signer': null,
        'dateAgreementSent': null
      });
    }
    return item;
  }
  buildSolutionPilarItem(stateItem?: any) {
    let item: UntypedFormGroup;
    if (stateItem) {
      item = this.fb.group({
        'id': [stateItem.id],
        'businessPartnerId': [this.businessPartner.id],
        'solutionPilarId': [stateItem.solutionPilarId]
      });
    } else {
      item = this.fb.group({
        id: [],
        'businessPartnerId': [this.businessPartner.id],
        'solutionPilarId': []
      });
    }
    return item;
  }
  buildServiceableStateItem(stateItem?: any) {
    let item: UntypedFormGroup;
    if (stateItem) {
      item = this.fb.group({
        'id': [stateItem.id],
        'businessPartnerId': [this.businessPartner.id],
        'stateId': [stateItem.stateId, Validators.required],
        'cityData': [],
        'city': [this.mappedServiceableCities?.filter((sc:any)=>sc.stateId == stateItem.stateId)?.map((x: any) => x.cityId)],
        'allCities': [stateItem.allCities]
      });
    } else {
      item = this.fb.group({
        'id': [],
        'businessPartnerId': [this.businessPartner.id],
        'stateId': ['', Validators.required],
        'cityData': [],
        'city': [],
        'allCities': [false]
      });
    }
    return item;
  }
  buildServiceableCityItem(cityItem?: any) {
    let item: UntypedFormGroup;
    let ctyItem;
    if (typeof (cityItem) == 'string') {
      ctyItem = this.serviceableCitiesFormArray.value.find((x: any) => x.CityId == cityItem);
    if (ctyItem) {
        item = this.fb.group({
          'id': [ctyItem.id],
          'businessPartnerId': [this.businessPartner.id],
          'CityId': [{ value: ctyItem.cityId, disabled: this.isViewOnly }]
        });
      }
      else{
        item = this.fb.group({
          'id': [],
          'businessPartnerId': [this.businessPartner.id],
          'CityId': [{ value: cityItem, disabled: this.isViewOnly }]
        });
      }
    }
    else if (cityItem) {
      item = this.fb.group({
        'id': [cityItem.id],
        'businessPartnerId': [this.businessPartner.id],
        'CityId': [{ value: cityItem.cityId, disabled: this.isViewOnly }]
      });
    } else {
      item = this.fb.group({
        'id': [],
        'businessPartnerId': [this.businessPartner.id],
        'CityId': []
      });
    }
    ctyItem ? "" : this.serviceableCitiesFormArray.push(item);
  }
  buildTagItem(tagItem?: any) {
    let item: UntypedFormGroup;
    if (tagItem) {
      item = this.fb.group({
        'id': [tagItem.id],
        'tagId': [tagItem.tagId],
        'businessPartnerId': [this.businessPartner.id],
        'CreatedByEmployeeId': [tagItem.createdByEmployeeId],
        'CreatedDate': [tagItem.createdDate]
      });
    } else {
      item = this.fb.group({
        'id': [],
        'tagId': [],
        'businessPartnerId': [this.businessPartner.id]
      });
    }
    return item;
  }
  removeContact(index: number) {
    const id = (<UntypedFormGroup>this.contacts.controls[index]).value['id'];
    if (id) {
      this.businessPartnerService.removeContact(id)
        .subscribe(res => {
          this.contacts.removeAt(index);

        });
    } else {
      this.contacts.removeAt(index);
    }
  }
  removeAddressItem(index: number) {
    const id = (<UntypedFormGroup>this.addresses.controls[index]).value['id'];
    if (id) {
      this.businessPartnerService.removeLocation(id)
        .subscribe(res => {
          this.addresses.removeAt(index);

        });
    } else {
      this.addresses.removeAt(index);
    }
  }
  removeAgreementItem(index: number) {
    const id = (<UntypedFormGroup>this.businessAgreements.controls[index]).value['id'];
    if (id) {
      this.businessPartnerService.removeAgreemnt(id)
        .subscribe(res => {
          this.businessAgreements.removeAt(index);

        });
    } else {
      this.businessAgreements.removeAt(index);
    }
  }
  removeSolutionPilar(index: number) {
    const id = (<UntypedFormGroup>this.solutionPilarsBusinessPartners.controls[index]).controls['id'].value;
    if (id) {
      this.businessPartnerService.removeBPPilar(id)
        .subscribe(res => {
          this.solutionPilarsBusinessPartners.removeAt(index);

        });
    } else {
      this.solutionPilarsBusinessPartners.removeAt(index);
    }
  }
  removeServiceableState(index: number) {
    const id = (<UntypedFormGroup>this.serviceableStatesFormArray.controls[index]).controls['id'].value;
    if (id) {
      this.removeServiceableCities(index);
      this.businessPartnerService.removeServiceableState(id)
        .subscribe(res => {
          this.serviceableStatesFormArray.removeAt(index);

        });
    } else {
      this.serviceableStatesFormArray.removeAt(index);
    }
  }

  removeServiceableCities(stateIndex: number) {
    const cityArray = (<UntypedFormGroup>this.serviceableStatesFormArray.controls[stateIndex]).controls['city'].value;
    cityArray?.forEach((cityId: any) => {
      let cityIndex = this.serviceableCitiesFormArray.value.findIndex((sc: any) => sc.CityId == cityId);
      this.removeServiceableCity(cityIndex);
    });
  }

  removeServiceableCity(cityIndex: number){
    let cityItem = this.serviceableCitiesFormArray.value[cityIndex];
    if (cityItem) {
      this.serviceableCitiesFormArray.removeAt(cityIndex);
      this.businessPartnerService.removeServiceableCity(cityItem.id).subscribe(res => {

      });
    }
  }

  removeTag(index: number) {
    const id = (<UntypedFormGroup>this.tagsFormArray.controls[index]).controls['id'].value;
    if (id) {
      this.businessPartnerService.removeTag(id)
        .subscribe(res => {
          this.tagsFormArray.removeAt(index);

        });
    } else {
      this.tagsFormArray.removeAt(index);
    }
  }
  filterEmployees(value: any) {

  }
  filter(val: string): Array<Employee> {
    return this.tempList.filter(option =>
      option.firstName.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }
  submitPartner() {
    if (this.businessPartnerForm.valid) {
      this.disableSubmit = true;
      let formvalues: any;
      formvalues = {};
      Object.assign(formvalues, this.businessPartnerForm.value);

      this.serviceableStatesFormArray.value.map((x: any)=>x.city).forEach((cityArray: any, index: number) => {
        cityArray?.forEach((city: any, id: number) => {
          this.buildServiceableCityItem(city);
        });
      });
      const removeableCities = this.serviceableCitiesFormArray.value.filter((city: any) =>
       !([].concat(...this.serviceableStatesFormArray.value.map((state: any) => state.city))).some(cityId => city.CityId === cityId));

       removeableCities.forEach((city: any) => {
        let cityIndex = this.serviceableCitiesFormArray.value.findIndex((sc: any) => sc.id == city.id);
        this.removeServiceableCity(cityIndex);
      })

      formvalues['locations'] = [];
      formvalues['additionalLocations'].forEach((l: any) => {
        formvalues['locations'].push(l);
      });
      formvalues['serviceableCities'] = this.serviceableCitiesFormArray.value;
      formvalues['locations'].push(this.primaryLocation.value);
      this.businessPartnerService.updateBusinessPartners(formvalues)
        .subscribe(bpCreateRes => {
          this.location.back();
        },
          (error) => {
            this.disableSubmit = false;
          });
    } else {
      const error: any = this.getFormValidationErrors(this.businessPartnerForm.controls).shift();
      if (error) {
        let text;
        switch (error.error_name) {
          case 'required': text = `${error.control_name} is required!`; break;
          case 'pattern': text = `${error.control_name} has wrong pattern!`; break;
          case 'email': text = `${error.control_name} has wrong email format!`; break;
          case 'minlength': text = `${error.control_name} has wrong length! Required length: ${error.error_value.requiredLength}`; break;
          case 'areEqual': text = `${error.control_name} must be equal!`; break;
          default: text = `${error.control_name}: ${error.error_name}: ${error.error_value}`;
        }
      }

    }
  }
  openBusinessPartnerScorecardWithData(data: any) {
    console.log(data);
    const popup = this.dialog.open(ScoreCardPopupComponent, {
      height: '60%',
      minWidth: '400px',
      panelClass: 'no-padding',
      data: { 'id': data.serviceProjectId, 'scoreCard': data, 'disabled': true }
    });

  }
  isNan(obj: any) {
    return isNaN(obj);
  }

  clearStatusNotes() {
    this.businessPartnerForm.controls['statusNotes'].clearValidators();
    this.setStatusNotesValueAndValidity('');
  }

  openBPStatusPopup() {
    const dialogRef = this.dialog.open(BusinessPartnersStatusPopupComponent, {
      width: '475px',
      height: 'auto', data: {
        note: this.businessPartnerForm.controls['statusNotes'].value,
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res != null && res != undefined && typeof(res) != 'boolean') {
        this.setStatusNotesValueAndValidity(res);
      }
    });
  }

  setStatusNotesValueAndValidity(res: string) {
    this.businessPartnerForm.controls['statusNotes'].setValue(res);
    this.businessPartnerForm.controls['statusNotes'].updateValueAndValidity();
  }
  businessPartnerPostalCodeValidationOnGroup(group: UntypedFormGroup) {
    group.controls['isInternational'].valueChanges
      .subscribe(isInternational => {
        if (isInternational) {
          group.controls["zipCode"].setValidators(Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9\-\ ]+$'), Validators.maxLength(10)]));
          group.controls["zipCode"].updateValueAndValidity();
        } else {
          group.controls["zipCode"].setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$'), Validators.maxLength(10)]));
          group.controls["zipCode"].updateValueAndValidity();
        }
      });
  }
  isSolutionPilarDisabled(pilarId: string) {
    return this.solutionPilarsBusinessPartners.value.map((sp: any) => sp.solutionPilarId).indexOf(pilarId) >= 0;
  }
  isTagDisabled(tagId: string) {
    return this.tagsFormArray.value.map((sp: any) => sp.tagId).indexOf(tagId) >= 0;
  }
  isStateDisabled(stateId: string) {
    return this.serviceableStatesFormArray.value.map((ss: any) => ss.stateId).indexOf(stateId) >= 0;
  }
  getServiceableCitiesArray(index: number){
    return (this.serviceableStatesFormArray.controls[index] as UntypedFormGroup).controls['city'];
  }
  onSelectAll(isAllStatusSelected: boolean, index: number){
    let formGrp: UntypedFormGroup = this.serviceableStatesFormArray?.controls[index] as UntypedFormGroup;
    let stateId = formGrp.value.stateId;

    if (isAllStatusSelected) {
      formGrp.controls['city'].setValue(this.serviceableCities.filter(x=>x.stateId == stateId).map(sc => sc.cityId));
    } else {
      formGrp.controls['city'].setValue([]);
    }
    formGrp.controls['city'].updateValueAndValidity();
  }
  cityChange(cityEvent: any, index: number){
    let formGrp: UntypedFormGroup = this.serviceableStatesFormArray?.controls[index] as UntypedFormGroup;
    let cities: any = this.getServiceableCities(index);
      if (cities) {
          if (typeof cityEvent == 'string') {
            let filteredServiceableCities = cities.filter((s: any) => s.name.toLowerCase().includes(cityEvent.toLowerCase()));
            this.serviceableCitiesAutoCompleteOptions[index] ? this.serviceableCitiesAutoCompleteOptions[index] = of(filteredServiceableCities) : this.serviceableCitiesAutoCompleteOptions.push(of(filteredServiceableCities)) ;
          } else {
            this.serviceableCitiesAutoCompleteOptions[index] ? this.serviceableCitiesAutoCompleteOptions[index] = of([]) : [];
          }
      }

  }
  onOptionSelected(citySelected: any, index: number){
   //this.cityChange(citySelected.option.viewValue, index);
  }
  isSelected(city: any, index: number): boolean {
    let formGrp: UntypedFormGroup = this.serviceableStatesFormArray?.controls[index] as UntypedFormGroup;
    let formArray = formGrp.controls['city'] as UntypedFormArray;
    return formArray.value?.includes(city.cityId);
  }


  optionClicked(event: MatCheckboxChange, city: any, index: number) {
    this.toggleSelection(event, city, index);
  }

  allCitiesOptionClicked(event: MatCheckboxChange, index: number) {
    let formGrp: UntypedFormGroup = this.serviceableStatesFormArray?.controls[index] as UntypedFormGroup;

    //Need to clear the cities array and add a special value to the array to indicate that all cities are selected
    if(event.checked){
      formGrp.patchValue({city: []});
    }
    formGrp.patchValue({allCities: event.checked});
    formGrp.updateValueAndValidity();
  }

  toggleSelection(event: MatCheckboxChange, city: any, index: number) {
    let formGrp: UntypedFormGroup = this.serviceableStatesFormArray?.controls[index] as UntypedFormGroup;

    let formArray = formGrp.controls['city'] as UntypedFormArray;
    if (!formGrp.controls['city']) {
      // formArray = this.fb.array([]);
    }
    if (event.checked) {
      if(formArray.value){
        formArray.patchValue([...formArray.value, city.cityId]);
        formArray.updateValueAndValidity();
      }
      else {
        formArray = this.fb.array([city.cityId]);
        formArray.updateValueAndValidity();
        formGrp.patchValue({city: formArray.value});
        formGrp.updateValueAndValidity();
      }

    } else if (!event.checked) {
      const updatedValue = formArray.value.filter((x:any) => x !== city.cityId);
      formArray.setValue(updatedValue);
      formArray.updateValueAndValidity();
    }
  };
}
