import { Component, OnInit } from '@angular/core';
import { VehicleMaintenanceSchedule, VehicleMaintenanceScheduleItem } from '../../models/fleet-management-models';
import { FleetMangementService } from '../../fleet-mangement.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormArray, FormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-vehicle-maintenance-schedule-create',
  templateUrl: './vehicle-maintenance-schedule-create.component.html',
  styleUrls: ['./vehicle-maintenance-schedule-create.component.scss']
})
export class VehicleMaintenanceScheduleCreateComponent implements OnInit {
  schedule: VehicleMaintenanceSchedule;
  scheduleForm: UntypedFormGroup;
  scheduleItemsFormArray: UntypedFormArray;
  isUpdating: boolean;
  workingId: string;
  constructor(private service: FleetMangementService, private route: ActivatedRoute, private location: Location, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.route.params
      .subscribe(res => {
        if (res['id']) {
          this.isUpdating = true;
          this.workingId = res['id'];
          this.service.getVehicleMaitenanceSchedule(res['id'])
            .subscribe(httpResp => {
              this.schedule = httpResp;
              this.initalCreateFormValues();
            });

        } else {

          this.isUpdating = false;
          this.schedule = new VehicleMaintenanceSchedule();
          this.schedule.vehicleMaintenanceScheduleItems = [];
          this.addScheduleItem();
        }
        this.initalCreateFormValues();
      });
  }
  createScheduleitem(itemValue?: any) {
    if (itemValue) {
      return this.fb.group({
        vehicleMaintenanceItemName: [itemValue.vehicleMaintenanceItemName, Validators.required],
        vehcileMaintenanceItemMileageInterval: [itemValue.vehcileMaintenanceItemMileageInterval],
        vehcileMaintenanceItemDateIntervalInDays: [itemValue.vehcileMaintenanceItemDateIntervalInDays],
        vehcileMaintenanceItemNextDateIntervalInDays: [itemValue.vehcileMaintenanceItemNextDateIntervalInDays],
        vehicleMaintenanceItemNextMileageInterval: [itemValue.vehicleMaintenanceItemNextMileageInterval]
      });
    } else {
      return this.fb.group({
        vehicleMaintenanceItemName: ['', Validators.required],
        vehcileMaintenanceItemMileageInterval: ['', Validators.required],
        vehcileMaintenanceItemDateIntervalInDays: [''],
        vehcileMaintenanceItemNextDateIntervalInDays: [''],
        vehicleMaintenanceItemNextMileageInterval: ['']
      });
    }
  }
  addScheduleItemToFormArray(itemValue?: any) {
    if (itemValue) {
      this.scheduleItemsFormArray.push(this.createScheduleitem(itemValue));
    } else {
      this.scheduleItemsFormArray.push(this.createScheduleitem());
    }
  }
  addScheduleItem() {
    const item = new VehicleMaintenanceScheduleItem();
    this.schedule.vehicleMaintenanceScheduleItems.push(item);
  }
  removeScheduleItem(index: number) {
    this.scheduleItemsFormArray.removeAt(index);
  }
  addSchedule() {
    // console.log(this.schedule);
    if (this.scheduleForm.valid) {
      this.schedule = new VehicleMaintenanceSchedule(this.scheduleForm.value);
      if (this.isUpdating) {
        this.schedule.vehicleMaintenanceScheduleId = this.workingId;
        this.service.updateVehicleMaitenanceSchedule(this.schedule)
          .subscribe(res => {
            this.location.back();

          });
      } else {
        this.service.createVehicleMaitenanceSchedule(this.schedule)
          .subscribe(res => {
            this.location.back();

          });
      }
    }
  }
  initalCreateFormValues() {

    this.scheduleItemsFormArray = this.fb.array([]);
    if (this.schedule) {

      if (this.schedule.vehicleMaintenanceScheduleItems) {
        this.schedule.vehicleMaintenanceScheduleItems.forEach(item => {
          this.addScheduleItemToFormArray(item);
        });
      } else {
        this.addScheduleItemToFormArray();
      }
      this.scheduleForm = this.fb.group({
        vehicleMaintenanceScheduleName: [this.schedule.vehicleMaintenanceScheduleName, Validators.required],
        vehicleMaintenanceScheduleItems: this.scheduleItemsFormArray
      });
    }
  }
  getScheduleItemsArray() {
    return (<UntypedFormArray>this.scheduleForm.get('vehicleMaintenanceScheduleItems')).controls;
  }

}
