<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        {{node.name}}        
        <mat-checkbox disabled [checked]="node.isShadowPhase">Shadow Phase</mat-checkbox>
        <button mat-icon-button type="button" title="Remove Resource" [disabled]="isCmtViewOnly" id="removePostSalesResource"
            (click)="removeServiceProjectPostSalesResource(node.id)">
            <mat-icon>remove</mat-icon>
        </button>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" [matTreeNodePadding]="'10px'">
        {{node.name}}

        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename" type="button" [disabled]="isCmtViewOnly">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <mat-checkbox title="All resources assigned" [checked]="node.isAllResourcesAssigned" [disabled]="isCmtViewOnly"
            (change)="updateComponent($event, node)">All resources are assigned</mat-checkbox>
        <button mat-icon-button *ngIf="!node.isAllResourcesAssigned" (click)="addPostSalesResource(node)" [disabled]="isCmtViewOnly" type="button" id="addPostSalesResource">
            <mat-icon>add</mat-icon>
        </button>
    </mat-tree-node>
</mat-tree>
