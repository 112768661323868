<!-- <div [formGroup]="formGroup">
  <div [formArrayName]="formArrayName" class="col-md-12"> -->
<div [formGroup]="formGroupName" *ngIf="formGroupName">

  <mat-checkbox class="full-width" formControlName="isInternational" id="isInternational">International?</mat-checkbox>
  <mat-form-field *ngIf="formGroupName.controls['isInternational'].value == true">
    <input matInput placeholder="Address Line1" formControlName="streetOne" />
    <mat-error *ngIf="formGroupName.controls['streetOne'].hasError('required')">
      Mandatory Fields
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="formGroupName.controls['isInternational'].value == true">
    <input matInput placeholder="Address Line2" formControlName="streetTwo" />
    <mat-error *ngIf="formGroupName.controls['streetTwo'].hasError('required')">
      Mandatory Fields
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="formGroupName.controls['isInternational'].value == true">
    <input matInput placeholder="City" formControlName="city" />
    <mat-error *ngIf="formGroupName.controls['city'].hasError('required')">
      Mandatory Fields
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="formGroupName.controls['isInternational'].value == true">
    <input matInput placeholder="Province/State/County" formControlName="state" />
    <mat-error *ngIf="formGroupName.controls['state'].hasError('required')">
      Mandatory Fields
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="formGroupName.controls['isInternational'].value == true">
    <input type="string" matInput placeholder="Zip/Postal Code" formControlName="zipCode" id="zipCode"  />
    <mat-error *ngIf="formGroupName.controls['zipCode'].hasError('required')">
      Mandatory Fields
    </mat-error>
    <mat-error *ngIf="!formGroupName.controls['zipCode'].hasError('maxlength') && formGroupName.controls['zipCode'].hasError('pattern')">
      Please enter a valid zipcode
    </mat-error>
    <mat-error *ngIf="formGroupName.controls['zipCode'].hasError('maxlength')">
      Max Limit 10 Characters only
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="formGroupName.controls['isInternational'].value == true">
    <input matInput placeholder="Country" formControlName="country" />
    <mat-error *ngIf="formGroupName.controls['country'].hasError('required')">
      Mandatory Fields
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="!formGroupName.controls['isInternational'].value == true">
    <input matInput formControlName="streetOne" placeholder="Street" />
    <mat-error *ngIf="formGroupName.controls['streetOne'].hasError('required')">
      Mandatory Fields
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="!formGroupName.controls['isInternational'].value == true">
    <input matInput formControlName="streetTwo" placeholder="Secondary Street" />
    <mat-error *ngIf="formGroupName.controls['streetTwo'].hasError('required')">
      Mandatory Fields
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="!formGroupName.controls['isInternational'].value == true">
    <mat-select id="bpAdditionalStateDropdown"  #stateInp matInput formControlName="stateId" placeholder="State" disableOptionCentering>
      <mat-option *ngFor="let state of states" [value]="state.stateId">{{state.abbreviation}}</mat-option>
    </mat-select>
    <mat-error *ngIf="formGroupName.controls['stateId'].hasError('required')">
      Mandatory Fields
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="!formGroupName.controls['isInternational'].value == true && citiesAutoCompleteOptions | async">
    <input id="bpAdditionalCityDropdown" type="text"
           placeholder="Select a City"
           aria-label="City"
           matInput
           formControlName="cityId"
           [matAutocomplete]="autoCompleteCity" autocomplete="off" />
    <mat-autocomplete autoActiveFirstOption #autoCompleteCity="matAutocomplete" autocomplete="off" [panelWidth]="180" [displayWith]="displayAutoCompleteForCity.bind(this)">
      <mat-option *ngFor="let city of citiesAutoCompleteOptions | async" [value]="city.cityId" [id]="city.cityId" [matTooltip]="city.name">
        {{city.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="formGroupName.controls['cityId'].hasError('required')">
      Mandatory Fields
    </mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="!formGroupName.controls['isInternational'].value == true">
    <input type="string" matInput formControlName="zipCode" placeholder="Zip Code" id="zipCode" />
    <mat-error *ngIf="formGroupName.controls['zipCode'].hasError('required')">
      Mandatory Fields
    </mat-error>
    <mat-error *ngIf="!formGroupName.controls['zipCode'].hasError('maxlength') && formGroupName.controls['zipCode'].hasError('pattern')">
      Please enter a valid zip code either in xxxxx or xxxxx-xxxx digits format
    </mat-error>
    <mat-error *ngIf="formGroupName.controls['zipCode'].hasError('maxlength')">
      Max Limit 10 Characters only
    </mat-error>
  </mat-form-field>
</div>
<!-- </div>
</div> -->
