import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { ProjectControl, ServiceRole } from '../../models/service-role';
import { ProjectControlService } from '../../project-control/project-control.service';
import { ServiceRoleService } from '../service-role.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from '../../admin.service';
import { Location } from '@angular/common';
import { GraphApiService } from '../../../shared/services/graph-api.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-service-role-create',
  templateUrl: './service-role-create.component.html',
  styleUrls: ['./service-role-create.component.scss']
})
export class ServiceRoleCreateComponent implements OnInit {
  serviceRoleFormGroup: UntypedFormGroup;
  projectControls: Array<ProjectControl>;
  approvers: any[];
  componentId: string;
  employeeServiceRoles: UntypedFormArray;
  serviceRoleEstimateTypes: UntypedFormArray;
  constructor(private formBuilder: UntypedFormBuilder,
    private projectControlService: ProjectControlService,
    private serviceRoleService: ServiceRoleService,
    private router: Router,
    private adminServie: AdminService,
    private route: ActivatedRoute,
    private location: Location,
    private graphApiService: GraphApiService) { }

  ngOnInit() {
    this.route.params
      .subscribe(param => {
        this.componentId = param['id'];
        this.getProjectControls();
        this.serviceRoleEstimateTypes = this.formBuilder.array([]);
        if (this.componentId) {
          this.serviceRoleService.getServiceRole(this.componentId)
            .subscribe(_res => {
              const data = _res;
              this.getEmployees();
              if (data.employeeServiceRoles) {
                data.employeeServiceRoles.forEach((esr: any) => {
                  this.addApprover(esr);
                });
              } else {
                this.addApprover();
              }
              this.serviceRoleFormGroup = this.formBuilder.group({
                'serviceRoleName': [data?.serviceRoleName, Validators.required],
                'serviceRoleDescription': [data?.serviceRoleDescription, Validators.required],
                'serviceRoleHourlyRate': [data?.serviceRoleHourlyRate, Validators.required],
                'projectControlId': [data?.projectControlId, Validators.required],
                'epicorPartNumberId': [data?.epicorPartNumberId, Validators.required],
                'employeeServiceRoles': this.employeeServiceRoles,
                'serviceRoleEstimateTypes': this.serviceRoleEstimateTypes
              });
            });
        } else {
          this.addApprover();
          this.getEmployees();
          this.serviceRoleFormGroup = this.formBuilder.group({
            'serviceRoleName': ['', Validators.required],
            'serviceRoleDescription': ['', Validators.required],
            'serviceRoleHourlyRate': [null, Validators.required],
            'projectControlId': [null, Validators.required],
            'epicorPartNumberId': [null, Validators.required],
            'employeeServiceRoles': this.employeeServiceRoles,
            'serviceRoleEstimateTypes': [this.serviceRoleEstimateTypes.value.map((x: any) => x.serviceEstimateType)]
          });
        }
      });

  }
  getEmployees() {
    let fetchEmployees = this.adminServie.getEmployees();
    let fetchAdUsers = this.graphApiService.getUsers();
    forkJoin([fetchEmployees, fetchAdUsers]).subscribe(results => {
      let azAdEmployees = results[1].filter(user => user.givenName != null && user.surname != null && user.accountEnabled != false);
      this.approvers = results[0].filter(emp => azAdEmployees.find(azUser => azUser.id == emp.azureAdId));
    });
  }
  submitForm() {
    if (this.serviceRoleFormGroup.valid) {
      const serviceRole: ServiceRole = this.serviceRoleFormGroup.value;
      serviceRole.serviceRoleEstimateTypes?.forEach((seType: any, index: number) => {
        this.addServiceRoleEstimateType(seType);
      });
      serviceRole.serviceRoleEstimateTypes = this.serviceRoleEstimateTypes.value;
      serviceRole.projectControl = this.projectControls.filter(pc => pc.projectControlId === this.serviceRoleFormGroup.controls['projectControlId'].value)[0];
      this.serviceRoleService.createServiceRole(serviceRole)
        .subscribe(result => {
          const data: ServiceRole = result;
          this.location.back();

        });
    }
  }
  addApprover(approver?: any) {
    if (this.employeeServiceRoles) {
      this.formBuilder.group({

      });
    } else {
      this.employeeServiceRoles = this.formBuilder.array([]);
    }
    if (approver) {
      this.employeeServiceRoles.push(this.formBuilder.group({
        id: [approver.id],
        employeeId: [approver.employeeId, Validators.required],
        serviceRoleId: [approver.serviceRoleId]
      }));
    } else {
      this.employeeServiceRoles.push(this.formBuilder.group({
        employeeId: [null, Validators.required],
        serviceRoleId: []
      }));
    }
  }
  addServiceRoleEstimateType(seType: number){
    this.serviceRoleEstimateTypes.push(this.formBuilder.group({
      serviceEstimateType: [seType]
    }));
  }
  removeApprover(index: number) {
    // this.approversArray.controls
    const id = this.employeeServiceRoles.at(index).value['id'];
    const employeeId = this.employeeServiceRoles.at(index).value['employeeId'];
    if (id && employeeId) {
      this.serviceRoleService.removeServiceRoleApprover(this.componentId, employeeId)
        .subscribe(res => {
          this.employeeServiceRoles.removeAt(index);
        });
    } else {
      this.employeeServiceRoles.removeAt(index);
    }
  }

  getProjectControls() {
    this.projectControlService.getProjectControls()
      .subscribe(res => {
        this.projectControls = res;
      });
  }
  isApproverDisabled(approverId: string) {
    return this.employeeServiceRoles.value.map((esr: any) => esr.employeeId).indexOf(approverId) >= 0;
  }
}
