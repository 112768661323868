import { Component, OnInit, Input } from '@angular/core';
import { ProfessionalServicesService } from '../../professional-services.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectsDataSource } from '../../models/professional-services-projects.datasource';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { AdminService } from '../../../admin/admin.service';


@Component({
  selector: 'app-change-order-list',
  templateUrl: './change-order-list.component.html',
  styleUrls: ['./change-order-list.component.scss']
})
export class ChangeOrderListComponent implements OnInit {
  @Input() serviceEstimates: Array<any>;
  displayedColumns: Array<string>;
  dataSource: ProjectsDataSource;
  seId: string;
  isDateAscending: boolean;
  isNameAscending: boolean;
  pageSize: number;
  employees: any[];
  selectedEmployeeId: string | null;

  constructor(private projectService: ProfessionalServicesService, private route: ActivatedRoute, private dialog: MatDialog, private adminService: AdminService) { }

  ngOnInit() {
    this.isDateAscending = true;
    this.isNameAscending = true;
    this.pageSize = 5;
    this.displayedColumns = [
      'projectName',
      'createdDate',
      'createdBy',
      'actions'
    ];
    this.getEmployees();
    this.getTableData();
  }

  clearSelectedEmployee() {
    this.selectedEmployeeId = null;
    this.filterByEmployee();
  }
  getEmployees() {
    this.adminService.getEmployees()
      .subscribe(httpResult => {
        this.employees = httpResult;
      });
  }
  getTableData() {
    this.route.params.subscribe(param => {
      this.seId = param['serviceEstimateId'];
      this.projectService.getSEChangeOrdersBySeId(param['serviceEstimateId'])
        .subscribe(res => {
          this.serviceEstimates = res;
          this.setTableData();
        });

    });

  }
  setTableData(data?: Array<any>) {
    this.dataSource = new ProjectsDataSource();
    if (!data) {
      const initData = this.serviceEstimates.slice(0, 5);
      this.dataSource.setData(initData);
    } else {
      this.dataSource.setData(data);
    }
  }
  deleteRecord(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.projectService.deleteChangeOrder(id)
            .subscribe(res => {
              this.getTableData();

            });
        }
      });
  }
  cloneRecord(id: string) {
    this.projectService.cloneChangeOrderComponentById(id)
      .subscribe(res => {
        this.getTableData();

      });
  }
  pageChangedEventUsages(event: any) {
    this.pageSize = event.pageSize;
    const pagedData = this.serviceEstimates.slice(event.pageIndex * event.pageSize, (event.pageIndex * event.pageSize) + event.pageSize);
    this.setTableData(pagedData);
  }
  sortList(sortType: string) {
    switch (sortType) {
      case 'projectName':

        break;

      case 'createdDate':

        break;
    }
  }

  filterByEmployee() {
    if (this.selectedEmployeeId) {
      const filteredData = this.serviceEstimates.filter(temp => {
        if (temp.createdByEmployeeId) {
          return temp.createdByEmployeeId == this.selectedEmployeeId
        } else {
          return false;
        }
      });
      this.setTableData(filteredData);
    } else {
      this.setTableData(this.serviceEstimates);
    }
  }
  sortByProjectName() {
    this.isNameAscending = !this.isNameAscending;
    if (this.isNameAscending) {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const nameOne = a.componentName;
        const nameTwo = b.componentName;
        if (nameOne < nameTwo) return -1;
        if (nameOne > nameTwo) return 1;
        return 1;
      }).slice(0, this.pageSize));
    } else {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const nameOne = a.componentName;
        const nameTwo = b.componentName;
        if (nameOne < nameTwo) return 1;
        if (nameOne > nameTwo) return -1;
        return 1;
      }).slice(0, this.pageSize));
    }
  }
  sortByDate() {
    this.isDateAscending = !this.isDateAscending;
    if (this.isDateAscending) {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const dateOne: any = new Date(a.createdDate);
        const dateTwo: any = new Date(b.createdDate);

        return dateTwo - dateOne;
      }).slice(0, this.pageSize));
    } else {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const dateOne: any = new Date(a.createdDate);
        const dateTwo: any = new Date(b.createdDate);

        return dateOne - dateTwo;
      }).slice(0, this.pageSize));
    }
  }

}
