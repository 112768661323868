<mat-card>
  <form *ngIf="solutionPilarFormGroup" [formGroup]="solutionPilarFormGroup" (ngSubmit)="updateSolutionPilar()">
    <mat-card-content>
      <mat-form-field>
        <input type="text" matInput formControlName="solutionPilarName" id="solPilarName" />
        <mat-error *ngIf="solutionPilarFormGroup.controls['solutionPilarName'].hasError('required')">
          A Name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput formControlName="solutionPilarTag" id="solPilarTag" />
        <mat-error *ngIf="solutionPilarFormGroup.controls['solutionPilarTag'].hasError('required')">
          A Tag is required
        </mat-error>
      </mat-form-field>
      <p class="error" *ngIf="!isUniqueName">
        Alert: This Solution Pillar name already exists.
      </p>
      <div>
        <div style="display: inline-block;">
          <h3>
            Solution Pillar Rate
            <button mat-icon-button type="button" (click)="addSolutionPilarRate()" id="addSolPilarRate">
              <mat-icon>add</mat-icon>
            </button>
          </h3>
          <div class="col-md-12">
          </div>
          <div [formArrayName]="'solutionPilarRates'" class="col-md-12">
            <div class="" *ngFor="let item of solutionPilarRatesArray.controls;let i = index" [formGroupName]="i">
              <mat-form-field>
                <input matInput type="number" placeholder="Project Management Percentage" formControlName="projectManagementPercentage" />
                <mat-error *ngIf="solutionPilarRatesArray.at(i).hasError('required', ['projectManagementPercentage'])">
                  A PM Percentage is required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <span matPrefix>$ </span>
                <input matInput currencyMask placeholder="Minimum Charge" formControlName="minimumCharge" />
                <mat-error *ngIf="solutionPilarRatesArray.at(i).hasError('required', ['minimumCharge'])">
                  A Minimum Charge is required
                </mat-error>
                <mat-error *ngIf="solutionPilarRatesArray.at(i).hasError('checkChargeMinRange', ['minimumCharge'])">
                  Minimum Charge should be less than Max charge
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <span matPrefix>$ </span>
                <input matInput currencyMask placeholder="Max Charge" formControlName="maxCharge" />
                <mat-error *ngIf="solutionPilarRatesArray.at(i).hasError('required', ['maxCharge'])">
                  A Max Charge is required
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <span matPrefix>$ </span>
                <input matInput currencyMask placeholder="Base Hourly Rate" formControlName="baseHourlyRate" />
                <mat-error *ngIf="solutionPilarRatesArray.at(i).hasError('required', ['baseHourlyRate'])">
                  A Base Hourly Rate is required
                </mat-error>
              </mat-form-field>
              <button mat-icon-button (click)="removeSolutionPilarRate(i)" type="button">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" [disabled]="!solutionPilarFormGroup.valid" id="updateSolPilar">Save</button>
    </mat-card-actions>
  </form>
</mat-card>
