import { DataSource } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Observable, of as observableOf, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';

export class ProjectControlRateDataSource extends DataSource<any> {
  data: Array<any>;
  nonFilteredData;
  paginator: MatPaginator;
  sort: MatSort;

  constructor(private projectControlRates: Array<any>) {
    super();
    this.data = projectControlRates;
    this.nonFilteredData = projectControlRates;
  }

  connect(): Observable<any[]> {
    const dataMutations = [
      observableOf(this.data),
      this.sort.sortChange,
      this.paginator.page
    ];

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  disconnect(): void {

  }

  setData(data: Array<any>) {
    this.data = data;
  }

  filter(field: any, fullData: any) {
    let filterData: any[] = fullData;
    if (field['projectControlRateName']) {
      filterData = filterData.filter(x => {
        return x.projectControlRateName.toLocaleLowerCase().indexOf(field['projectControlRateName'].toLocaleLowerCase()) > -1;
      });
    }
    if (field['createdBy']) {
      filterData = filterData.filter(x => {
        return x.createdByEmployeeId == field['createdBy'];
      });
    }
    return filterData;
  }

  private getPagedData(data: any[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  private getSortedData(data: any[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }
    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'projectControlRateName': return compare(a.projectControlRateName, b.projectControlRateName, isAsc);
        default: return 0;
      }
    });
  }
}
function compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

