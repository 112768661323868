import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Tag } from './tags-datasource';
import { TagsService } from '../../../tags/tags.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-tags-popup',
  templateUrl: './tags-popup.component.html',
  styleUrls: ['./tags-popup.component.scss']
})
export class TagsPopupComponent implements OnInit {

  isLoading: boolean;
  tagDetails: Tag[];

  constructor(private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TagsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tagsService: TagsService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.getTagDetails();
  }
  getTagDetails() {
    this.tagsService.getTagDetails(this.data.tagId).subscribe(res => {
      this.tagDetails = res;
      this.isLoading = false;
    }, (error) => {
      //this.loading = false;
    });

  }
}
