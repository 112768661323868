import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApplicationConfigurationService } from '../application-configuration.service';
import { MarginOptionType } from '../models/marginOption';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-application-configuration-create',
  templateUrl: './application-configuration-create.component.html',
  styleUrls: ['./application-configuration-create.component.scss']
})
export class ApplicationConfigurationCreateComponent implements OnInit {

  marginOptionsFormGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
    private applicationConfigurationService: ApplicationConfigurationService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getMarginOptions();
  }

  savedUpliftPercentage: number | null = null;
  savedGrossMarginPercentage: number | null = null;

  getMarginOptions() {
    this.applicationConfigurationService.getMarginOptions().subscribe((res: any) => {
      if (res != null) {
        this.savedUpliftPercentage = res.marginOptionType === 0 ? res.upliftPercentage : null;
        this.savedGrossMarginPercentage = res.marginOptionType === 1 ? res.grossMarginPercentage : null;

        this.marginOptionsFormGroup = this.fb.group({
          marginOptionType: [res.marginOptionType === 0 ? 'uplift' : 'grossMargin', Validators.required],
          upliftPercentage: [this.savedUpliftPercentage],
          grossMarginPercentage: [this.savedGrossMarginPercentage],
          serviceEstimateExpiration: [res.serviceEstimateExpiration || null, [Validators.required, Validators.pattern('^[0-9]+$')]]
        });
      } else {
        this.savedUpliftPercentage = null;
        this.savedGrossMarginPercentage = null;

        this.marginOptionsFormGroup = this.fb.group({
          marginOptionType: [null, Validators.required],
          upliftPercentage: [null],
          grossMarginPercentage: [null],
          serviceEstimateExpiration: [null, [Validators.required, Validators.pattern('^[0-9]+$')]]
        });
      }
      this.onMarginOptionChange(); // Attach the change handler
    });
  }

  mapMarginOptionTypeStringToEnum(value: string): MarginOptionType {
    return value === 'uplift' ? MarginOptionType.Uplift : MarginOptionType.GrossMargin;
  }

  submitForm(){
    if (this.marginOptionsFormGroup.valid) {
      var formValue = this.marginOptionsFormGroup.value;
      formValue.marginOptionType = this.mapMarginOptionTypeStringToEnum(formValue.marginOptionType);
      this.applicationConfigurationService.updateMarginOptions(formValue)
        .subscribe((res: any) => {
          this.getMarginOptions();
          this._snackBar.open("Your changes have been saved.", "Dismiss", {
            duration: 5000,
          });
        });
    }
  }

  onMarginOptionChange() {
    this.marginOptionsFormGroup.controls['marginOptionType'].valueChanges.subscribe((res: any) => {
      if (res === "uplift") {
        // Reset upliftPercentage to saved value
        this.marginOptionsFormGroup.controls['upliftPercentage'].setValue(this.savedUpliftPercentage);
        this.marginOptionsFormGroup.controls['upliftPercentage'].setValidators(Validators.required);

        // Clear grossMarginPercentage
        this.marginOptionsFormGroup.controls['grossMarginPercentage'].setValue(null);
        this.marginOptionsFormGroup.controls['grossMarginPercentage'].clearValidators();
      } else if (res === "grossMargin") {
        // Reset grossMarginPercentage to saved value
        this.marginOptionsFormGroup.controls['grossMarginPercentage'].setValue(this.savedGrossMarginPercentage);
        this.marginOptionsFormGroup.controls['grossMarginPercentage'].setValidators(Validators.required);

        // Clear upliftPercentage
        this.marginOptionsFormGroup.controls['upliftPercentage'].setValue(null);
        this.marginOptionsFormGroup.controls['upliftPercentage'].clearValidators();
      }

      // Update validity for both fields
      this.marginOptionsFormGroup.controls['upliftPercentage'].updateValueAndValidity();
      this.marginOptionsFormGroup.controls['grossMarginPercentage'].updateValueAndValidity();
    });
  }

  onInputChange(controlName: string): void {
    const control = this.marginOptionsFormGroup.controls[controlName];
    if (control) {
      const value = (document.getElementById(controlName) as HTMLInputElement).value;
      control.setValue(value);
      control.markAsTouched();
      control.updateValueAndValidity();
    }
  }

  goBack() {
    this.marginOptionsFormGroup.reset();
  }
}
