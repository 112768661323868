import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MaterialItemService {

  constructor(private http: HttpClient) { }

  deleteMaterialItems(id: any) {
    return this.http.delete(`/api/MaterialItems/${id}`);
  }

}
