import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { ActivatedRoute } from '@angular/router';
import { TempApproverService } from '../temp-approver.service';
import { Location, DatePipe } from '@angular/common';
import { ServiceRoleService } from '../../service-role/service-role.service';
import { Employee } from '../../../employee/employee-list/employee-list-datasource';
import { forkJoin, of } from 'rxjs';
import { ServiceRole } from '../../models/service-role';
import { TemporaryApprover } from '../../models/employee';
import { GraphApiService } from '../../../shared/services/graph-api.service';

@Component({
  selector: 'app-temp-approver-create',
  templateUrl: './temp-approver-create.component.html',
  styleUrls: ['./temp-approver-create.component.scss']
})
export class TempApproverCreateComponent implements OnInit {

  tempApproverFormGroup: UntypedFormGroup;
  employees: Employee[];
  tempApproverId: any;
  serviceRoles: ServiceRole[];
  constructor(private fb: UntypedFormBuilder, private employeeService: AdminService, private serviceRoleService: ServiceRoleService, private location: Location, private route: ActivatedRoute, private tempApproverService: TempApproverService, private graphApiService: GraphApiService) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        this.serviceRoles = [];
        if (params['id']) {
          this.tempApproverId = params['id'];
        } else {
          this.tempApproverFormGroup = this.fb.group({
            'startDate': [null, Validators.required],
            'endDate': [null, Validators.required],
            'temporaryApproverEmployeeId': [null, Validators.required],
            'approverForEmployeeId': [null, Validators.required]
          });
        }
        this.getTempApproversData();
      });
  }
  getTempApproversData() {
    let fetchEmployees = this.employeeService.getEmployees();
    let fetchAdUsers = this.graphApiService.getUsers();
    let fetchTempApprover = this.tempApproverId ? this.tempApproverService.getTemporaryApprover(this.tempApproverId) : of(null);
    let fetchServiceRoles = this.tempApproverId ? this.serviceRoleService.getServiceRoles() : of([]);
    forkJoin([fetchEmployees, fetchAdUsers, fetchTempApprover, fetchServiceRoles]).subscribe(results => {
      let azAdEmployees = results[1].filter(user => user.givenName != null && user.surname != null && user.accountEnabled != false);
      this.employees = results[0].filter(emp => azAdEmployees.find(azUser => azUser.id == emp.azureAdId));
      if (this.tempApproverId) {
        const threshHold = results[2];
        results[3].forEach(item => {
          let roleInfo = item.employeeServiceRoles.filter((x: any) => x.employeeId == threshHold.approverForEmployeeId);
          if (roleInfo.length > 0)
            this.serviceRoles.push(item.serviceRoleName);
        })
        let isActiveTempApprover = this.employees.map(apr => apr.employeeId).indexOf(threshHold.temporaryApproverEmployeeId) != -1 ? true : false;
        let isActiveApproverEmpId = this.employees.map(apr => apr.employeeId).indexOf(threshHold.approverForEmployeeId) != -1 ? true : false;
        this.tempApproverFormGroup = this.fb.group({
          'startDate': [threshHold.startDate, Validators.required],
          'endDate': [threshHold.endDate, Validators.required],
          'temporaryApproverEmployeeId': [isActiveTempApprover ? threshHold.temporaryApproverEmployeeId: null, Validators.required],
          'approverForEmployeeId': [isActiveApproverEmpId ? threshHold.approverForEmployeeId: null, Validators.required],
          'id': [threshHold.id],
          'createdByEmployeeId': [threshHold.createdByEmployeeId]
        });
      }
    });
  }
  submitForm() {
    if (this.tempApproverFormGroup.valid) {
      let tempApprover: TemporaryApprover = Object.assign({}, this.tempApproverFormGroup.value)
     if (this.tempApproverId) {
        this.tempApproverService.updateTemporaryApprover(tempApprover)
          .subscribe(res => {
            this.location.back();

          });
      } else {
        this.tempApproverService.createTemporaryApprover(tempApprover)
          .subscribe(res => {
            this.location.back();

          });
      }
    }
  }
  isDisabled(formControlName: string, id: any) {
    let approverForEmployeeId = this.tempApproverFormGroup.value['approverForEmployeeId'];
    let temporaryEmployeeId = this.tempApproverFormGroup.value['temporaryApproverEmployeeId'];
    if (formControlName == 'temporaryApproverEmployeeId')
      return approverForEmployeeId == id;
    else if (formControlName == 'approverForEmployeeId')
      return temporaryEmployeeId == id;
    else return false;
    //else
    //  return this.tempApproverFormGroup.value[formControlName] == this.tempApproverFormGroup.value['temporaryEmployeeId'];
  }
  getMinDate() {
    return this.tempApproverFormGroup.value['startDate'];
  }
  getMaxDate() {
    return this.tempApproverFormGroup.value['endDate'];
  }
}
