import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsComponent } from './clients.component';
import { SharedModule } from '../shared/shared.module';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { ClientsRoutingModule } from './clients-routing.module';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientUploadComponent } from './client-upload/client-upload.component';
import { ClientsService } from './clients.service';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { ClientsListUrlFilteringComponent } from './clients-list-url-filtering/clients-list-url-filtering.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ClientsRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],
  declarations: [
    ClientsComponent,
    ClientsListComponent,
    ClientCreateComponent,
    ClientUploadComponent,
    ClientEditComponent,
    ClientsListUrlFilteringComponent
  ]
})
export class ClientsModule { }
