<h2 mat-dialog-title>Edit Expiration Date</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Enter Date</mat-label>
    <input
      required
      matInput
      [matDatepicker]="picker"
      [(ngModel)]="data['expirationDate']"
      [min]="minDate"
      [max]="maxDate"
      readonly
      #expirationDate="ngModel"/>
<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="data" color="primary" [disabled]="!expirationDate.valid">Save</button>
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
