import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-control-detail',
  templateUrl: './project-control-detail.component.html',
  styleUrls: ['./project-control-detail.component.scss']
})
export class ProjectControlDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
