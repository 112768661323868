<div class="mat-elevation-z8">
  <mat-card id="projectStatusesCard">
    <h4 class="text-color">Projects Status Percentage</h4>
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="pieRequestFromGroup" class="dashboard-font row">
          <div class="col-md-5">
            <b>Day Range:</b>
            <br />
            <br />
            <mat-radio-group formControlName="noOfDays" class="margin days-margin" (change)="filterProjectData($event)"
                             id="dayRange">
              <mat-radio-button value="90">90 Days</mat-radio-button>
              <mat-radio-button value="180">180 Days</mat-radio-button>
              <mat-radio-button value="360">360 Days</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-md-6">
            <b>Date Range:</b>
            <br />
            <mat-form-field appearance="fill" class="days-margin">
              <mat-label>Choose Date</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" #startDate formControlName="startDate">
                <input matEndDate placeholder="End date" #endDate formControlName="endDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="reset-btn">
            <button mat-icon-button type="button" id="resetBtn" (click)="resetFilters()" title="Clear Filters">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </form>
      </div>

    </div>
    <div>
      <h4 class="align-center"><span class="text-color">Active</span> vs <span class="inactive">InActive</span> vs <span
          class="lost">Lost</span> vs <span class="complete">Complete</span> Projects</h4>
      <div *ngIf="projectsData?.length == 0" class="align-center add-mb">
        No records found
      </div>
      <div [hidden]="projectsData?.length == 0">
        <canvas id="pieChart" #pieChart
                [attr.pieChart-data]="[getProjectStatusCount(0, null), getProjectStatusCount(1, null), getProjectStatusCount(2, null), getProjectStatusCount(3, null)]"></canvas>
      </div>
    </div>
  </mat-card>
</div>
