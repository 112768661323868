import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fleet-management-home',
  templateUrl: './fleet-management-home.component.html',
  styleUrls: ['./fleet-management-home.component.scss']
})
export class FleetManagementHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
