import { Injectable } from '@angular/core';
import { BusinessPartner } from './models/bp-model';
import { SolutionPilar } from './models/solution-pilar';
import { HttpClient } from '@angular/common/http';
import { BusinessPartnerScoreCardAverage } from './models/business-partner-scorecard-average.model';
import { BusinessPartnerScoreCard } from './models/business-partner-scorecard.model';
import { map } from 'rxjs/operators';

@Injectable()
export class BusinessPartnerService {

  constructor(private http: HttpClient) { }

  updateBusinessPartnerScoreCard(businessPartnerScoreCard: BusinessPartnerScoreCard) {
    const body = JSON.stringify(businessPartnerScoreCard);
    return this.http.put<BusinessPartnerScoreCard>(`/api/BusinessPartnerScoreCards/${businessPartnerScoreCard.id}`, body);
  }
  createBusinessPartnerScoreCard(businessPartnerScoreCard: BusinessPartnerScoreCard) {
    const body = JSON.stringify(businessPartnerScoreCard);
    return this.http.post<BusinessPartnerScoreCard>(`/api/BusinessPartnerScoreCards`, body);
  }
  getBusinessPartnerScoreCardsByBusinessPartnerId(businessPartnerId: string) {
    return this.http.get<any[]>(`/api/BusinessPartnerScoreCards/BusinessPartners/${businessPartnerId}`);
  }
  getBusinessPartnerScoreCardsByServiceProjectId(businessPartnerId: string) {
    return this.http.get<any[]>(`/api/BusinessPartnerScoreCards/ServiceProjects/${businessPartnerId}`);
  }
  getBusinessPartnerScoreCardAverage(businessPartnerId: string) {
    return this.http.get<BusinessPartnerScoreCardAverage>(`/api/BusinessPartnerScoreCards/BusinessPartners/${businessPartnerId}/average`)
  }

  getBusinessPartnerScoreCardAverageTotal(businessPartnerId: string) {
    return this.http.get<BusinessPartnerScoreCardAverage>(`/api/BusinessPartnerScoreCards/BusinessPartners/${businessPartnerId}/average`)
      .pipe(map(res => {
        let numberOfItems = 0;
        let total = 0;
        if (res.communicationQualityAverage) {
          if (!isNaN(res.communicationQualityAverage)) {
            numberOfItems++;
            total += res.communicationQualityAverage;
          }
        }
        if (res.communicationTimelinessAverage) {
          if (!isNaN(res.communicationTimelinessAverage)) {
            numberOfItems++;
            total += res.communicationTimelinessAverage;
          }
        }
        if (res.documentationQualityAverage) {
          if (!isNaN(res.documentationQualityAverage)) {
            numberOfItems++;
            total += res.documentationQualityAverage;
          }
        }

        if (res.documentationTimelinessAverage) {
          if (!isNaN(res.documentationTimelinessAverage)) {
            numberOfItems++;
            total += res.documentationTimelinessAverage;
          }
        }
        if (res.invoicingQualityAverage) {
          if (!isNaN(res.invoicingQualityAverage)) {
            numberOfItems++;
            total += res.invoicingQualityAverage;
          }
        }

        if (res.invoicingTimelinessAverage) {
          if (!isNaN(res.invoicingTimelinessAverage)) {
            numberOfItems++;
            total += res.invoicingTimelinessAverage;
          }
        }

        if (res.postSalesSkillsAndQualityAverage) {
          if (!isNaN(res.postSalesSkillsAndQualityAverage)) {
            numberOfItems++;
            total += res.postSalesSkillsAndQualityAverage;
          }
        }

        if (res.postSalesTimelinessAverage) {
          if (!isNaN(res.postSalesTimelinessAverage)) {
            numberOfItems++;
            total += res.postSalesTimelinessAverage;
          }
        }

        if (res.preSalesAcurracyAndDetailAverage) {
          if (!isNaN(res.preSalesAcurracyAndDetailAverage)) {
            numberOfItems++;
            total += res.preSalesAcurracyAndDetailAverage;
          }
        }

        if (res.preSalesTimelinessAverage) {
          if (!isNaN(res.preSalesTimelinessAverage)) {
            numberOfItems++;
            total += res.preSalesTimelinessAverage;
          }
        }

        return total / numberOfItems;
      }));
  }

  getBusinessPartnerByServiceProjectId(projectId: string) {
    return this.http.get<any[]>(`/api/businesspartners/serviceprojects/${projectId}`);
  }
  getBusinessPartners() {
    return this.http.get<any[]>(`/api/businessPartners`);
  }
  getBusinessPartnersLocations() {
    return this.http.get<any[]>(`/api/businessPartners/Locations`);
  }
  getBusinessPartner(id: string) {
    return this.http.get<any>(`/api/businessPartners/${id}`);
  }
  updateBusinessPartners(businessPartner: any) {
    const body = JSON.stringify(businessPartner);

    return this.http.put(`/api/businessPartners/${businessPartner.id}`, body);
  }
  deleteBusinessPartners(id: string) {
    return this.http.delete(`/api/businessPartners/${id}`);
  }
  createBusinessPartner(businessPartner: any) {
    const body = JSON.stringify(businessPartner);

    return this.http.post(`/api/businessPartners`, body);
  }
  getSolutionPilar(id: string) {
    return this.http.get<any>(`/api/Solutions/solutionPilars/${id}`);
  }
  getSolutionPilars() {
    return this.http.get<any[]>(`/api/Solutions/solutionPilars`);
  }
  getAllSolutionPilars() {
    return this.http.get<any[]>(`/api/Solutions/allSolutionPilars`);
  }
  updateSolutionPilars(solutionPilar: SolutionPilar) {
    const body = JSON.stringify(solutionPilar);
    return this.http.put(`/api/Solutions/solutionPilars/${solutionPilar.solutionPilarId}`, body);
  }
  createSolutionPilars(solutionPilar: SolutionPilar) {
    const body = JSON.stringify(solutionPilar);
    return this.http.post(`/api/Solutions/solutionPilar`, body);
  }
  deleteSolutionPilar(id: string) {
    return this.http.delete(`/api/Solutions/solutionPilars/${id}`);
  }
  removeLocation(id: string) {
    return this.http.delete(`/api/BusinessPartners/businesslocations/${id}`);
  }
  removeBPPilar(id: string) {
    return this.http.delete(`/api/BusinessPartners/pilars/${id}`);
  }
  removeAgreemnt(id: string) {
    return this.http.delete(`/api/BusinessPartners/agreements/${id}`);
  }
  removeContact(id: string) {
    return this.http.delete(`/api/BusinessPartners/contacts/${id}`);
  }
  removeServiceableState(id: string) {
    return this.http.delete(`/api/businesspartners/servieablestates/${id}`);
  }
  removeServiceableCity(id: string) {
    return this.http.delete(`/api/businesspartners/serviceablecities/${id}`);
  }
  deleteSolutionPilarRate(id: any) {
    return this.http.delete(`/api/solutionPilarRates/${id}`);
  }
  removeTag(id: string) {
    return this.http.delete(`/api/BusinessPartners/tags/${id}`);
  }

}
