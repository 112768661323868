<mat-card>
  <mat-card-content>
    <div
      *ngIf="cloudProviderForm"
      [class.loading]="!loading"
      style="position: relative; margin: auto; width: calc(50% + 225px)"
    >
      <!-- <mat-spinner
        mode="indeterminate"
        width="450"
        height="450"
        style="margin: 0 auto"
      ></mat-spinner> -->
    </div>

    <div *ngIf="cloudProvider" [class.loading]="loading">
      <div [formGroup]="cloudProviderForm">
        <fieldset>
          <legend>Cloud Provider</legend>

          <div class="row adjust-linehgt">
            <p id="lbl-cloud-provider-name" class="col-md-6">Name: {{ cloudProvider.name }}</p>
          </div>
          <!-- <mat-form-field>
            <mat-label>VPN Rate</mat-label>
            <input mat-input type="number" formControlName="vpnRate" placeholder="VPN Rate"/>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Bandwidth Rate</mat-label>
            <input mat-input formControlName="bandwidthRate" placeholder="Bandwidth Rate"/>
          </mat-form-field-->

          <div class="align-button">
            <a id="btn-edit"
              mat-raised-button
              color="primary"
              (click)="editProvider()"
              style="float: right"
            >
              <mat-icon>edit</mat-icon>Edit
            </a>
          </div>
        </fieldset>
      </div>
      <!-- Pricing Schedules -->
      <div id="box-pricing-schedule" style="{{ displayPricingScheduleSection }};" *ngIf="pricingScheduleDataSource">
        <fieldset>
          <legend>Pricing Schedules</legend>
          <div class="align-button">
            <a id="btn-add-pricing-schedule"
              mat-raised-button
              color="primary"
              (click)="addPriceSchedule()"
              style="float: right"
            >
              <mat-icon>add</mat-icon>Add Pricing Schedule
            </a>
          </div>
          <mat-table
            mat-table
            matSort
            class="full-width-table"
            aria-label="Pricing Schedule Table"
            [dataSource]="pricingScheduleDataSource"
            [class.loading]="loading"
          >
            <ng-container matColumnDef="serviceName">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >Service Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.serviceName }}-{{row.hostedSystem}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="productCode">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >Product</mat-header-cell
              >
              <mat-cell *matCellDef="let row">{{ row.productCode }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let row" style="text-align: right">
                <ng-container>
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      (click)="openPricingScheduleDialog(row)"
                    >
                      <mat-icon>search</mat-icon>
                      <span>View</span>
                    </button>
                    <button
                      mat-menu-item
                      (click)="openPricingDialog(row)"
                    >
                      <mat-icon>search</mat-icon>
                      <span>Price</span>
                    </button>
                    <button mat-menu-item (click)="deleteRecord(row.id)">
                      <mat-icon>delete_outline</mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </ng-container>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedPricingScheduleColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedPricingScheduleColumns"
            ></mat-row>
          </mat-table>

          <mat-paginator
            [class.loading]="loading"
            #paginator
            [length]="pricingScheduleDataSource?.length"
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 25, 50, 100]"
          >
          </mat-paginator>
        </fieldset>
      </div>
      <!--Compute-->
      <div>
        <mat-expansion-panel id="expnl-compute" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> Compute </mat-panel-title>
            <mat-panel-description>Cloud Provider Compute Offerings Product/Pricing Search.... </mat-panel-description>
          </mat-expansion-panel-header>

          <div id="box-compute-product-list" [class.loading]="loading">
             <app-cloud-provider-compute-product-list [regionOptions]="regionOptions"></app-cloud-provider-compute-product-list>
          </div></mat-expansion-panel
        >
      </div>
      <!--Network-->
      <div>
        <mat-expansion-panel id="expnl-network" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> Network </mat-panel-title>
            <mat-panel-description>Cloud Provider Network Offerings Product/Pricing Search....</mat-panel-description>
          </mat-expansion-panel-header>

          <div id="box-network-product-list" [class.loading]="loading">
            <span>Network Product List Goes Here..</span>
          </div></mat-expansion-panel
        >
      </div>
      <!--Storage-->
      <div>
        <mat-expansion-panel id="expnl-storage" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> Storage </mat-panel-title>
            <mat-panel-description>Cloud Provider Storage Offerings Product/Pricing Search.... </mat-panel-description>
          </mat-expansion-panel-header>

          <div id="box-storage-product-list" [class.loading]="loading">
            <span>Storage Product List Goes Here..</span>
          </div></mat-expansion-panel
        >
      </div>
    </div>
  </mat-card-content>
</mat-card>
