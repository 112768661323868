<app-breadcrumb></app-breadcrumb>
<mat-card>
  <mat-card-content>
    <nav mat-tab-nav-bar>
      <a mat-tab-link [routerLink]="['projects']" routerLinkActive="active-link" id="projectsTab">Projects</a>
      <a mat-tab-link [routerLink]="['releaseNotes']" routerLinkActive="active-link" id="releaseNotesTab">Release Notes</a>
      <a mat-tab-link [routerLink]="['appRoles']" routerLinkActive="active-link" id="appRolesTab">Role Administration</a>
    </nav>
    <router-outlet></router-outlet>
  </mat-card-content>
</mat-card>
