import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';

@Component({
  selector: 'cloud-migration-environment-popup',
  templateUrl: './cloud-migration-environment-popup.component.html',
  styleUrls: ['./cloud-migration-environment-popup.component.scss'],
})
export class CloudMigrationEnvironmentPopupComponent {
  environmentId: string;
  environmentTypeOptions: any = [];
  virtualizationTypeOptions: any = [];
  projectId: string;
  formTitle: string = 'Environment';
  discoveryEnvironment: any;
  environment: any;
  responseMessage: string;

  constructor(
    private dialogRef: MatDialogRef<CloudMigrationEnvironmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cloudMigrationService: CloudMigrationToolService
  ) {}

  ngOnInit(): void {
    this.setState(this.data?.environment);
  }

  setState(environment: any) {
    this.environment = environment;
    this.projectId = environment?.projectId;
  }

  onAddEnvironment($event: any) {
    console.log('onAddEnvironment: ', $event);
  }
  onEditEnvironment($event: any) {
    console.log('onEditEnvironment: ', $event);
    this.environment = $event;
  }

  save() {
    var formVal = this.environment;
    var data = {
      id: formVal.id,
      name: formVal.name,
      projectId: this.projectId,
      environmentType: formVal.environmentType,
      virtualizationType: formVal.virtualizationType,
    };
    this.responseMessage = '';
    this.cloudMigrationService
      .updateEnvironment(data)
      .then((response: any) => {
        let message: string = response?.data?.message;
        if (message && message != 'Success') {
          this.responseMessage = message;
        } else {
          this.dialogRef.close(response);
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  }

  close(res?: any) {
    this.dialogRef.close(res);
  }
}
