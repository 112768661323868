import { Component, OnInit } from '@angular/core';
import { AccountInfo } from '@azure/msal-common';
import { MsalService } from '@azure/msal-angular';
import { RoleService } from '../../../shared/services/role.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-professional-services-project-lp',
  templateUrl: './professional-services-project-lp.component.html',
  styleUrls: ['./professional-services-project-lp.component.scss']
})
export class ProfessionalServicesProjectLpComponent implements OnInit {
  isSales: boolean;
  isSalesSupport: boolean;
  isDirector: boolean;
  isProjectManager: boolean;
  isAdmin: boolean;
  user: AccountInfo | null;
  isArchitectEngineer: boolean;
  isAvayaArchitectEngineer: boolean;
  isServicesOperations: boolean;
  canCreateProject: boolean;
  isCmtProj: boolean;
  constructor(
    private authService: MsalService,
    private roleService: RoleService,
    private activatedRoute: ActivatedRoute) { }


  ngOnInit() {

    this.user = this.authService.instance.getActiveAccount();
    this.activatedRoute.data.subscribe(res => {
      this.isCmtProj = res.isCmtProj;
    });
    this.isSales = false;
    this.isSalesSupport = false;
    this.isProjectManager = false;
    this.isDirector = false;
    this.isAdmin = false;
    this.isProjectManager = false;
    this.canCreateProject = true;
    if (this.hasRole('PortalSalesSupport')) {
      this.canCreateProject = false;
    }
    if (this.hasRole('PortalSEProjectManager')) {
      this.canCreateProject = true;
    }
    this.isDirector = this.hasRole('PortalSEDirector') || this.hasRole('projects.approver');
    this.isSalesSupport = this.hasRole('PortalSalesSupport');
    this.isAdmin = this.hasRole('PortalAdmin');
    this.isArchitectEngineer = this.hasRole('PortalSEPresalesArchitect');
    if (this.hasRole('PortalSEProjectManager')) {
      this.isProjectManager = true;
    } if (this.hasRole('PortalSEProjectManagerOfficer')) {
      this.isProjectManager = true;
    }
    if (this.hasRole('PortalSEServiceOperations') || this.hasRole('ProfessionalServiceCoordinator')) {
      this.isServicesOperations = true;
    }
  }
  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, false);
  }

}
export class TestSend {
  sendToEmail: string;
}
