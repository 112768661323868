<div class="row m-2">
  <div [class.loading]="!loading" style="position: relative; margin:auto;width:calc(50% + 225px)">
    <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
  </div>
  <div class="col-md-7" [class.loading]="loading">
    <mat-card class="mat-elevation-z8" id="dashboardCard">
      <mat-card-content>
        <div class="row">
          <h4 class="col-md-1 text-color">Projects</h4>
          <div class="col-md-10 align-right">
            <form [formGroup]="dashboardRequestFromGroup" class="dashboard-font">
              <b>Day Range:</b>
              <mat-radio-group formControlName="noOfDays" class="margin" (change)="filterProjectData($event)" id="dayRange">
                <mat-radio-button value="90">90 Days</mat-radio-button>
                <mat-radio-button value="180">180 Days</mat-radio-button>
                <mat-radio-button value="360">360 Days</mat-radio-button>
              </mat-radio-group>
              <b>Date Range:</b>
              <mat-form-field appearance="fill" class="days-margin">
                <mat-label>Choose Date</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate placeholder="Start date" #startDate formControlName="startDate">
                  <input matEndDate placeholder="End date" #endDate formControlName="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </form>
          </div>
          <div class="reset-btn">
            <button mat-icon-button type="button" id="resetBtn" (click)="resetFilters()" title="Clear Filters">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </div>
        <app-projects-list [projectsData]="projectsData"></app-projects-list>
        <!--</mat-card-content>
        </mat-card>-->
        <br />
        <!--<mat-card>
        <mat-card-content>-->
        <h4 class="text-color">Service Estimates/Change Orders</h4>
        <app-service-estimates-list [serviceEstimateData]="serviceEstimateData"></app-service-estimates-list>
        <!--</mat-card-content>
        </mat-card>-->
        <br />
        <!--<mat-card>
        <mat-card-content>-->
        <h4 class="text-color">Component Details</h4>
        <app-service-estimate-components-list [seComponentData]="seComponentData" [serviceRoles]="serviceRoles"></app-service-estimate-components-list>
        <!--</mat-card-content>
        </mat-card>-->
        <br />
        <!--<mat-card>
        <mat-card-content>-->
        <h4 class="text-color">Active Projects</h4>
        <app-active-projects-list [activeProjectsData]="activeProjectsData"></app-active-projects-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-5" *ngIf="!loading">
    <app-pie-chart></app-pie-chart>
    <br />
    <app-revenue-bar-chart></app-revenue-bar-chart>
  </div>
</div>
