<div>
  <mat-card>
    <mat-card-content>
      <!-- need to disable with can create flag -->
      <a id="createBP" mat-raised-button [routerLink]="['create']" [attr.disabled]="!canCreate" color="primary" *ngIf="!isPopup"
         matTooltip="Create Business Partner" [matTooltipPosition]="'right'">
        <mat-icon class="add-icon">add</mat-icon>
        Business Partner
      </a>
      <div [class.loading]="!isLoading" style="position: relative; margin:auto;width:calc(50% + 225px)">
        <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
      </div>
      <div class="col-md-12" *ngIf="!isLoading">
        <fieldset>
          <legend>
            Search Selection
          </legend>
          <form [formGroup]="filterForm">
            <mat-form-field>
              <input matInput placeholder="Filter By Name" formControlName="name">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Filter By Country</mat-label>
              <input type="text"
                     placeholder="Country"
                     aria-label="Country"
                     matInput
                     formControlName="country"
                     id='bpCountryFilterInput'
                     [matAutocomplete]="auto" (keydown)="clearRouteChangeValue()">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let country of filteredOptions | async" [value]="country.country == 'usa'? 'USA' : (country.country | titlecase)" [id]="country.id">
                  {{country.country == 'usa'? 'USA' : (country.country | titlecase)}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Filter By State" formControlName="state" disableOptionCentering (selectionChange)="onStateChange()">
                <mat-option *ngFor="let state of states" [value]="state.stateId">
                  {{state.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Filter By City" formControlName="city" disableOptionCentering>
                <mat-option *ngFor="let city of cities" [value]="city.cityId">
                  {{city.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <mat-form-field>
            <mat-select placeholder="Filter By Pillar" formControlName="soultionPilar">
                <mat-option *ngFor="let pilar of solutionPilars" [value]="pilar.solutionPilarId">
                    {{pilar.solutionPilarName}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
            <mat-form-field *ngIf="!isPopup">
              <mat-select placeholder="Filter By Status" formControlName="status" multiple disableOptionCentering>
                <mat-option *ngFor="let st of bpStatuses" [value]="st.value">
                  {{ st.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="Filter By Tag" formControlName="tag" disableOptionCentering>
                <mat-option *ngFor="let tag of tags" [value]="tag.id">
                  {{tag.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select matInput formControlName="agreementType" multiple placeholder="Filter By Agreement Type" disableOptionCentering>
                <mat-option [value]="0">Subcontractor Agreement</mat-option>
                <mat-option [value]="1">Reverse Subcontractor Agreement</mat-option>
                <mat-option [value]="2">Non-Disclosure Agreement</mat-option>
                <mat-option [value]="3">Mutual Non-Disclosure Agreement</mat-option>
                <mat-option [value]="4">Reseller Agreement</mat-option>
                <mat-option [value]="5">Partner Agreement</mat-option>
                <mat-option [value]="6">Sub Provided Agreement</mat-option>
                <mat-option [value]="7">Other Agreement</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters">
              <mat-icon>clear</mat-icon>
            </button>
            <button mat-raised-button type="button" color="primary" *ngIf="!hideGeoFenceOpt"
                    (click)="openGeoFencingPopup()">
              GeoFencing
            </button>
          </form>
        </fieldset>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="!isLoading">
    <mat-card-content>
      <app-business-partner-popup [businessPartnerDataSource]="businessPartnerDataSource"
                                  [filteredPartners]="filteredPartners" [states]="states" [tags]="tags"
                                  (selectedPartner)="closePopUp($event)" [isPopup]="isPopup">

      </app-business-partner-popup>
      <!-- <mat-paginator (page)="pageChangedEventUsages($event)" *ngIf="filteredPartners" [length]="filteredPartners.length"
          [pageSize]="10" [pageIndex]="0" [pageSizeOptions]="[10]"> </mat-paginator> -->
    </mat-card-content>
  </mat-card>
</div>
