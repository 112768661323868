import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectControlLandingPageComponent } from './project-control-landing-page/project-control-landing-page.component';
import { ProjectControlComponent } from './project-control.component';
import { ProjectControlListComponent } from './project-control-list/project-control-list.component';
import { ProjectControlCreateComponent } from './project-control-create/project-control-create.component';
import { ProjectControlUpdateComponent } from './project-control-update/project-control-update.component';
import { ProjectControlDetailComponent } from './project-control-detail/project-control-detail.component';
import { ProjectControlService } from './project-control.service';
import { ProjectControlRoutingModule } from './project-control-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
    ProjectControlComponent,
    ProjectControlLandingPageComponent,
    ProjectControlListComponent,
    ProjectControlCreateComponent,
    ProjectControlUpdateComponent,
    ProjectControlDetailComponent,
  ],
  providers: [
    ProjectControlService
  ]
})
export class ProjectControlModule { }
