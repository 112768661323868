import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { RoleService } from '../shared/services/role.service';

@Component({
  selector: 'app-business-partners',
  templateUrl: './business-partners.component.html',
  styleUrls: ['./business-partners.component.scss']
})
export class BusinessPartnersComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}
