import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ServiceEstimateComponentService } from 'src/app/shared/components/service-estimate-component-popup/service-estimate-component.service';

@Component({
  selector: 'component-details-popup',
  templateUrl: './component-details-popup.component.html',
  styleUrls: ['./component-details-popup.component.scss']
})
export class ComponentDetailsPopupComponent implements OnInit {

  componentDetails: any[] = [];
  displayedColumns: string[] = ['componentName', 'status', 'approvers'];
  loading = true;  // Start with loading spinner

  constructor(
    public dialogRef: MatDialogRef<ComponentDetailsPopupComponent>,
    private serviceEstimateComponentService: ServiceEstimateComponentService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data && this.data.seId) {
      this.serviceEstimateComponentService.getComponentDetailsByServiceEstimateId(this.data.seId)
        .subscribe((response: any) => {
          this.componentDetails = response;
          this.loading = false; // Stop spinner when data is loaded
        }, (error) => {
          this.loading = false;  // Stop spinner even if error occurs
        });
    }
  }

}
