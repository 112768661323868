import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  templateUrl: './document-viewer-popup.component.html',
  styleUrls: ['./document-viewer-popup.component.scss']
})
export class DocumentViewerPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DocumentViewerPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
