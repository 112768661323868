import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ProfessionalServicesComponent } from '../../../../professional-services/professional-services.component';
import { ProfessionalServicesService } from '../../../../professional-services/professional-services.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AssignEmployeePopupComponent } from '../../../../shared/components/assign-employee-popup/assign-employee-popup.component';
import { AdminService } from '../../../../admin/admin.service';
import { ServiceProjectPostSalesAssignedEmployeesService } from '../../../../professional-services/_services/service-project-post-sales-assigned-employees.service';

@Component({
  selector: 'app-post-sales-tree',
  templateUrl: './post-sales-tree.component.html',
  styleUrls: ['./post-sales-tree.component.scss']
})
export class PostSalesTreeComponent implements OnInit {
  @Input() serviceEstimateComponents: Array<any>;
  @Input() postSaleAssignedResources: Array<any>;
  @Input() projectId: string;
  @Input() isCmtViewOnly: string;
  @Output() isResourceDataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  dataSource: MatTreeFlatDataSource<FlatTreeControl<any>, MatTreeFlattener<any, any>>;
  treeControl: FlatTreeControl<any>;
  treeFlatner: MatTreeFlattener<any, any>;
  flatNodeMap: Map<any, any>;
  constructor(
    private professionalService: ProfessionalServicesService,
    private serviceProjectPostSalesAssignedEmployeeService: ServiceProjectPostSalesAssignedEmployeesService,
    public dialog: MatDialog,
    private adminService: AdminService) { }



  ngOnInit(): void {
    this._transFormData();
    this.treeControl = new FlatTreeControl<any>(node => node.level, node => node.expandable);
    this.treeFlatner = new MatTreeFlattener(this._transformer, node => node.level, node => node.expandable, node => node.children);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlatner);
    this.dataSource.data = this.serviceEstimateComponents;
  }
  addPostSalesResource(data: any) {
    const popupRef = this.dialog.open(AssignEmployeePopupComponent, {
      width: '400px',
      height: 'auto',
      data: {
        component: this.serviceEstimateComponents.filter(sec => sec.id === data.id)[0]
      }
    });
    popupRef.afterClosed()
      .subscribe(dialogCloseRef => {
        if (dialogCloseRef) {
          var component = this.serviceEstimateComponents.filter(sec => sec.id === data.id)[0];
          let sppsae: any = {
            'serviceRoleId': component.serviceRoleId,
            'employeeId': dialogCloseRef.employeeId,
            'serviceProjectId': this.projectId,
            'isScoreCardGrader': dialogCloseRef.isScoreCardGrader,
            'isShadowPhase': dialogCloseRef.isShadowPhase
          };
          this.serviceProjectPostSalesAssignedEmployeeService.createServiceProjectPostSalesAssignedEmployee(sppsae)
            .subscribe(sppsaeResult => {
              this.isResourceDataUpdated.emit(true);
            });

        }
      });
  }
  removeServiceProjectPostSalesResource(id: any) {
    this.serviceProjectPostSalesAssignedEmployeeService.deleteServiceProjectPostSalesAssignedEmployee(id)
      .subscribe(sppsaeResult => {
        this.isResourceDataUpdated.emit(true);
      });
  }
  updateComponent(event: any, component: any) {
    const currentComponent = this.serviceEstimateComponents.filter(sec => sec.id === component.id)[0];
    this.professionalService.updateAllResourcesSigned(component.id, event.checked)
      .subscribe(componentUpdateData => {

        currentComponent.isAllResourcesAssigned = event.checked;
        this.dataSource.data = this.serviceEstimateComponents;
      });
  }
  private _transFormData() {
    this.serviceEstimateComponents.forEach(sec => {

      sec['children'] = this.postSaleAssignedResources.filter(psar => psar.serviceRoleId === sec.serviceRole['serviceRoleId']);
    });
  }
  private _transformer = (node: any, level: number) => {
    if (level === 1) {
      return {
        expandable: !!node.children && node.children.length > 0,
        name: node.employee?.firstName + ' ' + node.employee?.lastName,
        level: level,
        id: node.id ? node.id : null,
        isScoreCardGrader: node.isScoreCardGrader,
        isShadowPhase: node.isShadowPhase
      };
    } else {
      return {
        expandable: true,
        isAllResourcesAssigned: node.employee ? false : node.isAllResourcesAssigned,
        name: node.serviceRole.serviceRoleName,
        level: level,
        id: node.id ? node.id : null
      };
    }
  }
  hasChild = (_: number, node: any) => node.expandable;

}
