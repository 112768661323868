import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { VirtualizationType } from './configuration-datasource';

export class Workload {
  id: string;
  environment: string;
  serverId: string;
  coresPerProcessor: number;
  processors: number;
  coresAtUtil: number;
  ram: number;
  ramAtUtil: number;
  storageUtilizationInBytes: number;
  operatingSystemType: OperatingSystemType;
  graphicsCardType?: GraphicsCardType;
  optimizationType?: OptimizationType;
  environmentType?: EnvironmentType;
  workloadType?: WorkloadType;
  operatingSystemLicenseType?: OperatingSystemLicenseType;
  virtualizationType?: VirtualizationType;
  databaseType?: DatabaseType;
  isWindows2008?: boolean;
  disks: Array<Disk>;
  name: string;
}

export class Disk {
  diskId: string;
  name: string;
  sizeInMegaBytes: bigint;
  type: string;
}

export enum OperatingSystemType {
  Windows,
  Linux,
  Undetermined
}

export enum GraphicsCardType {
  None,
  K80,
  M60
}

export enum OptimizationType {
  CPU = 2,
  RAM = 8,
  GeneralPurpose = 4
}

export enum OperatingSystemLicenseType {
  Datacenter,
  Standard
}

export enum WorkloadType {
  WebApp,
  Server
}
export enum EnvironmentType {
  PhysicalServer,
  VirtualMachine
}

export enum DatabaseType {
  MicrsoftSqlServer,
  MySql,
  PostgresSql,
  SqlServerAps,
  OracleExData
}


export class WorkloadDataSource extends DataSource<any> {
  data: Array<any>;
  paginator: MatPaginator;
  sort: MatSort;
  nonFilteredData;

  constructor(private workloads: Array<any>) {
    super();
    this.data = workloads;
    this.nonFilteredData = workloads;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

  filter(field: any, fullData: any) {

    let filterApplied = false;
    let filterData: any[] = fullData;
    //if (field['namefilter']) {
    //  filterdata = filterdata.filter(x => {
    //    return x.name.tolocalelowercase().indexof(field['namefilter'].tolocalelowercase()) > -1;
    //  });
    //}
    return filterData;
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: any[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: any[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'vmId': return compare(a.serverId, b.serverId, isAsc);
        default: return 0;
      }
    });
  }

}
/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
