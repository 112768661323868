import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VehicleReportsComponent } from '../fleet-management/vehicle-reports/vehicle-reports.component';
import { ReportsComponent as Reports} from '../professional-services/reports/reports.component';
import { ReportsComponent } from './reports.component';
import { BusinessPartnerLandingReportComponent } from './business-partner-landing-report/business-partner-landing-report.component';

export const routes: Routes = [{
  path: 'reports', component: ReportsComponent, data: {
    breadcrumb: 'Reports', isRoutable: true
  }, children: [
    {
      path: 'businessPartnerReports', component: BusinessPartnerLandingReportComponent, data: {
        breadcrumb: 'Business Partner Reports', isRoutable: true
      }
    }, {
      path: 'fleetManagementReports', component: VehicleReportsComponent, data: {
        breadcrumb: 'Fleet Management Reports', isRoutable: true
      }
    }, {
      path: 'serviceEstimateReports', component: Reports, data: {
        breadcrumb: 'Project Reports', isRoutable: true
      }
    },
    { path: '', redirectTo: 'businessPartnerReports', pathMatch: 'full' }]

}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
