import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-professional-service-estimate-detailed-quote',
  templateUrl: './professional-service-estimate-detailed-quote.component.html',
  styleUrls: ['./professional-service-estimate-detailed-quote.component.scss']
})
export class ProfessionalServiceEstimateDetailedQuoteComponent implements OnInit {

  @Input() phaseItemGroup: UntypedFormGroup;
  @Input() selectionItems: any;
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }
  isChecked(isChecked: any) {
    this.valueChanged.emit(isChecked);
  }
}
