import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-contract-signed-date-dialog',
  templateUrl: './contract-signed-date-dialog.component.html',
  styleUrls: ['./contract-signed-date-dialog.component.css']
})
export class ContractSignedDateDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ContractSignedDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.data = {
      'contractSignDate': null
    };
  }
  onNoClick() {
    this.dialogRef.close();
  }
  getMaxDate() {
    return new Date();
  }

}
