import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ProfessionalServicesService } from '../../professional-services.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectsDataSource } from '../../models/professional-services-projects.datasource';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { AdminService } from '../../../admin/admin.service';
import { ServiceEstimateComponentPopupComponent } from '../../../shared/components/service-estimate-component-popup/service-estimate-component-popup.component';
import { CloningPopupComponent } from '../../../shared/components/cloning-popup/cloning-popup.component';

@Component({
  selector: 'app-service-estimate-component-list',
  templateUrl: './service-estimate-component-list.component.html',
  styleUrls: ['./service-estimate-component-list.component.scss']
})
export class ServiceEstimateComponentListComponent implements OnInit {

  @Input() serviceEstimates: Array<any>;
  displayedColumns: Array<string>;
  dataSource: ProjectsDataSource;
  seId: string;
  isDateAscending: boolean;
  isNameAscending: boolean;
  pageSize: number;
  pagination: any;
  employees: any[];
  selectedEmployeeId: string | null;

  constructor(private projectService: ProfessionalServicesService, private route: ActivatedRoute, private dialog: MatDialog, private adminService: AdminService,
    private location: Location,) { }

  ngOnInit() {

    this.isDateAscending = true;
    this.isNameAscending = true;
    this.pageSize = 5;
    this.displayedColumns = [
      'projectName',
      'createdDate',
      'createdBy',
      'approvedBy',
      'tags',
      'actions'
    ];
    this.getEmployees();
    this.getTableData();
  }
  clearSelectedEmployee() {
    this.selectedEmployeeId = null;
    this.filterByEmployee();
  }
  getTableData() {
    this.route.params.subscribe(param => {
      this.seId = param['serviceEstimateId'];
      this.projectService.getSEComponentsBySeId(param['serviceEstimateId'])
        .subscribe(res => {
          this.serviceEstimates = res;
          this.setTableData();

        });

    });

  }
  filterByEmployee() {
    if (this.selectedEmployeeId) {
      const filteredData = this.serviceEstimates.filter(temp => {
        if (temp.createdByEmployeeId) {
          return temp.createdByEmployeeId == this.selectedEmployeeId
        } else {
          return false;
        }
      });
      this.setTableData(filteredData);
    } else {
      this.setTableData(this.serviceEstimates);
    }
  }

  getEmployees() {
    this.adminService.getAllEmployees()
      .subscribe(httpResult => {
        this.employees = httpResult;
      });
  }
  setTableData(data?: Array<any>) {
    this.dataSource = new ProjectsDataSource();
    let pageAmount = 5;
    if (!data) {
      if (this.pagination) {
        pageAmount = this.pagination.pageSize;
      }
      const initData = this.serviceEstimates.slice(0, pageAmount);
      this.dataSource.setData(initData);
    } else {
      this.dataSource.setData(data);
    }
  }
  deleteRecord(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.projectService.deleteSEC(id)
            .subscribe(res => {
              this.getTableData();

            });
        }
      });
  }
  cloneComponent(id: string, isChangeOrder?: boolean) {
    const dialogRef = this.dialog.open(CloningPopupComponent, {
      width: '30%',
      height: '35%',
      data: { popupFor: "Service Estimate Component" }
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.projectService.cloneSEComponentsById(id, result.clonedName)
            .subscribe(res => {
              this.getTableData();

            });
        }
      });
  }
  viewHistory(id: string) {
    const component = this.serviceEstimates.find(x => x.id == id);
    const dialogref = this.dialog.open(ServiceEstimateComponentPopupComponent, {
      width: '100%',
      height: '500px',
      data: {
        secId: id, componentName: component.componentName,
        createdBy: component.createdByEmployeeId,
        createdDate: component.createdDate
      }
    });
    dialogref.afterClosed()
      .subscribe(res => {
        if (res) {
          this.location.back();
        }
      });
  }
  pageChangedEventUsages(event: any) {
    this.pagination = event;
    this.pageSize = event.pageSize;
    const pagedData = this.serviceEstimates.slice(event.pageIndex * event.pageSize, (event.pageIndex * event.pageSize) + event.pageSize);
    this.setTableData(pagedData);

  }
  sortByProjectName() {
    this.isNameAscending = !this.isNameAscending;
    if (this.isNameAscending) {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const nameOne = a.componentName;
        const nameTwo = b.componentName;
        if (nameOne < nameTwo) { return -1; }
        if (nameOne > nameTwo) { return 1; }
        return 1;
      }).slice(0, this.pageSize));
    } else {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const nameOne = a.componentName;
        const nameTwo = b.componentName;
        if (nameOne < nameTwo) { return 1; }
        if (nameOne > nameTwo) { return -1; }
        return 1;
      }).slice(0, this.pageSize));
    }
  }
  sortByDate() {
    this.isDateAscending = !this.isDateAscending;
    if (this.isDateAscending) {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const dateOne: any = new Date(a.createdDate);
        const dateTwo: any = new Date(b.createdDate);

        return dateTwo - dateOne;
      }).slice(0, this.pageSize));
    } else {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const dateOne: any = new Date(a.createdDate);
        const dateTwo: any = new Date(b.createdDate);

        return dateOne - dateTwo;
      }).slice(0, this.pageSize));
    }
  }
  getApprover(data: any) {
    let approval = data.sort((a: any, b: any) => {
      const dateOne: any = new Date(a.approvedDate);
      const dateTwo: any = new Date(b.approvedDate);
      return dateTwo - dateOne;
    });
    return approval[0]?.approvedById;
  }

}
