import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardLandingPageComponent } from './dashboard-landing-page/dashboard-landing-page.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { ServiceEstimatesListComponent } from './service-estimates-list/service-estimates-list.component';
import { ServiceEstimateComponentsListComponent } from './service-estimate-components-list/service-estimate-components-list.component';
import { SharedModule } from '../shared/shared.module';
import { ActiveProjectsListComponent } from './active-projects-list/active-projects-list.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { RevenueBarChartComponent } from './revenue-bar-chart/revenue-bar-chart.component';


@NgModule({
  declarations: [
    DashboardComponent,
    DashboardLandingPageComponent,
    ProjectsListComponent,
    ServiceEstimatesListComponent,
    ServiceEstimateComponentsListComponent,
    ActiveProjectsListComponent,
    PieChartComponent,
    RevenueBarChartComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule
  ],
  exports: [DashboardComponent]
})
export class DashboardModule { }
