import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {
  id: string;
  employee: any;

  constructor(private route: ActivatedRoute, private adminService: AdminService) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        if (params['id']) {
          this.id = params['id'];
          this.adminService.getEmployee(this.id)
            .subscribe(httpResult => {
              this.employee = httpResult;
            });
        }

      });

  }
  updateEmployee() {
    this.adminService.updateEmployee(this.employee);
  }

}
