<form #form="ngForm" *ngIf="serviceEstimateForm" [formGroup]="serviceEstimateForm" (ngSubmit)="calculateTotals()">
  <mat-toolbar color="accent">
    <span>
      <button
        [disabled]="(serviceEstimateDetailsClosing.length == 0 && serviceEstimateDetailsExecute.length == 0 && serviceEstimateDetailsPlanning.length == 0 && businessPartnerItems.length == 0 && !serviceEstimateForm.valid) || !serviceEstimateForm.valid"
        title="Save Component" mat-icon-button type="submit">
        <mat-icon>save</mat-icon>
      </button>
    </span>
  </mat-toolbar>
  <mat-card class="top-level">
    <mat-card-content *ngIf="!serviceRoles">
      <mat-spinner></mat-spinner>
    </mat-card-content>
    <mat-card-content *ngIf="serviceRoles">
      <h3><span *ngIf="!templateId">Create</span> <span *ngIf="templateId">Update</span> Template Component</h3>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <mat-select type="text" formControlName="serviceRoleId" placeholder="Service Role" disableOptionCentering required>
              <mat-option *ngFor="let sr of serviceRoles" [value]="sr.serviceRoleId">
                {{sr.serviceRoleName}}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="serviceEstimateForm.controls['serviceRoleId'].hasError('required')">
              Service Role
              is
              required
            </mat-error>
          </mat-form-field>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <input matInput [value]="selectedServiceRole?.serviceRoleHourlyRate | currency" placeholder="Hourly Rate" readonly />
              </mat-form-field>
            </div>
      
            <div class="col-md-6">
              <mat-form-field>
                <span matPrefix>$&nbsp;</span>
                <input 
                  type="string" 
                  currencyMask 
                  matInput 
                  placeholder="Override Rate" 
                  formControlName="componentOverrideRate" 
                  [readonly]="!selectedServiceRole" 
                  matTooltip="A service role is required to enter override rate"
                />
              </mat-form-field>                    
            </div>
          </div>

          <mat-form-field>
            <input matInput type="text" formControlName="componentName" placeholder="Name" required />

            <mat-error *ngIf="serviceEstimateForm.controls['componentName'].hasError('required')">
              Name
              is
              required
            </mat-error>
          </mat-form-field>
          <p class="error" *ngIf="!isUniqueName">
            Unique template name required. Please check template name and try
            again.
          </p>
        </div>

        <div class="col-md-4">

          <div>
            <mat-card class="customSizingCard_MI">
              <mat-card-header>
                <h3>Pricing Notes</h3>
                <button mat-icon-button (click)="addPricingNote()" color="primary" type="button">
                  <mat-icon>add</mat-icon>
                </button>
              </mat-card-header>
              <mat-card-content>
                <div class="col-md-12" [formArrayName]="'pricingNotes'">
                  <div *ngFor="let item of pricingNotes.controls;let i = index;" [formGroupName]="i">
                    <p class="header5">
                      Pricing Note {{i + 1}}
                      <mat-icon class="md-18 cursor" (click)="removePricingNote(i)">clear</mat-icon>
                    </p>
                    <mat-form-field class="full-width">
                      <input matInput type="text" placeholder="Note" formControlName="note">
                    </mat-form-field>
                    <hr>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
      <div cdkDropListGroup>
        <div class="expansionGap" *ngIf="serviceEstimateForm.controls['serviceRoleId'].value" cdkDropList
             #planList="cdkDropList" id="planItems" [cdkDropListData]="serviceEstimateDetailsPlanning.value"
             [cdkDropListConnectedTo]="[executeList, closeList]" (cdkDropListDropped)="drop($event)">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Planning Phase</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-professional-services-estimate-detail-item #planningPhase [phaseItemType]="'template'" [isMutilplierReadOnly]="false"
                                                            (addPlanItem)="addPlanningItem();updatePhaseIndex(0)" [serviceRoles]="serviceRoles"
                                                            [formArrayName]="'serviceEstimateDetailsPlanning'" [items]="serviceEstimateDetailsPlanning"
                                                            [formGroup]="serviceEstimateForm" [phase]="'Plan'">
            </app-professional-services-estimate-detail-item>
          </mat-expansion-panel>
          <br />
        </div>
        <div class="expansionGap" *ngIf="serviceEstimateForm.controls['serviceRoleId'].value" cdkDropList
             #executeList="cdkDropList" id="executeItems" [cdkDropListData]="serviceEstimateDetailsExecute.value"
             [cdkDropListConnectedTo]="[planList, closeList]" (cdkDropListDropped)="drop($event)">

          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Executing Phase</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-professional-services-estimate-detail-item #executingPhase [phaseItemType]="'template'" [isMutilplierReadOnly]="false"
                                                            (addExecuteItem)="addExecuteItem();updatePhaseIndex(1)" [serviceRoles]="serviceRoles"
                                                            [formArrayName]="'serviceEstimateDetailsExecute'" [items]="serviceEstimateDetailsExecute"
                                                            [formGroup]="serviceEstimateForm" [phase]="'Execute'">
            </app-professional-services-estimate-detail-item>
          </mat-expansion-panel>
          <br />
        </div>
        <div class="expansionGap" *ngIf="serviceEstimateForm.controls['serviceRoleId'].value" cdkDropList
             #closeList="cdkDropList" id="closeItems" [cdkDropListData]="serviceEstimateDetailsClosing.value"
             [cdkDropListConnectedTo]="[planList, executeList]" (cdkDropListDropped)="drop($event)">

          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3>Closing Phase</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-professional-services-estimate-detail-item #closingPhase [phaseItemType]="'template'" [isMutilplierReadOnly]="false"
                                                            (addCloseItem)="addClosingItem();updatePhaseIndex(2)" [serviceRoles]="serviceRoles"
                                                            [formArrayName]="'serviceEstimateDetailsClosing'" [items]="serviceEstimateDetailsClosing"
                                                            [formGroup]="serviceEstimateForm" [phase]="'Close'">
            </app-professional-services-estimate-detail-item>
          </mat-expansion-panel>
          <br />
        </div>
      </div>
        <div class="col-md-12" style="padding: 0 !important;">
          <mat-accordion *ngIf="serviceRoles">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h3>Business Partner Items</h3>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row" *ngIf="businessPartnerItems.length > 0" style="width:85%;">
                <div class="col-xl-5">
                  <mat-form-field>
                    <input id="totalBPGrossMargin" matInput currencyMask placeholder="Total BP Gross Margin%" formControlName="grossMarginPercentage" />
                    <span matSuffix>%</span>
                    <mat-error *ngIf="serviceEstimateForm.controls['grossMarginPercentage'].hasError('required')">
                      Total BP Gross Margin% is required
                    </mat-error>
                    <mat-error *ngIf="serviceEstimateForm.controls['grossMarginPercentage'].hasError('max')">
                      Total BP Gross Margin cannot be equal to or greater than 100 %. Please input a lower value.
                    </mat-error>
                    <mat-error *ngIf="serviceEstimateForm.controls['grossMarginPercentage'].hasError('min')">
                      Total BP Gross Margin cannot be less than 0 %. Please input a higher value.
                    </mat-error>
                    <!-- <mat-error *ngIf="!serviceEstimateForm.controls['grossMarginPercentage'].hasError('min') && !serviceEstimateForm.controls['grossMarginPercentage'].hasError('required') && serviceEstimateForm.controls['grossMarginPercentage'].hasError('isNotValid')">
                      Alert: This SE Component Contains Non-Standard Pricing for Total BP Gross Margin%
                    </mat-error> -->
                    <mat-hint *ngIf="!isNotValidTotalGrossMarginPerct()">
                      This field represents the cumulative value for all Business Partner Items.
                    </mat-hint>
                  </mat-form-field>
                  <div class="error" *ngIf="!serviceEstimateForm.controls['grossMarginPercentage'].hasError('min') && !serviceEstimateForm.controls['grossMarginPercentage'].hasError('required') && isNotValidTotalGrossMarginPerct()">
                    Alert: This SE Component Contains Non-Standard Pricing for Total BP Gross Margin%
                  </div>
                </div>
                <div class="col-xl-5">
                  <mat-form-field>
                    <input id="totalUpliftMargin" matInput currencyMask placeholder="Total Uplift Margin%" formControlName="upliftPercentage" />
                    <span matSuffix>%</span>
                    <mat-error *ngIf="serviceEstimateForm.controls['upliftPercentage'].hasError('required')">
                      Total Uplift Margin% is required
                    </mat-error>
                    <mat-error *ngIf="serviceEstimateForm.controls['upliftPercentage'].hasError('min')">
                      Total Uplift Margin cannot be less than 0 %. Please input a higher value
                    </mat-error>
                    <mat-hint *ngIf="!isNotValidTotalUpliftPerct()">
                      This field represents the cumulative value for all Business Partner Items.
                    </mat-hint>
                  </mat-form-field>
                  <div class="error" *ngIf="!serviceEstimateForm.controls['upliftPercentage'].hasError('min') && !serviceEstimateForm.controls['upliftPercentage'].hasError('required') && isNotValidTotalUpliftPerct()">
                    Alert: This SE Component Contains Non-Standard Pricing for Total Uplift%
                  </div>
                </div>
              </div>
              <div style="width:90%;">
                <button mat-icon-button type="button" (click)="addBusinessItem()" color="primary"
                        title="Add Business Partner Item">
                  <mat-icon>add</mat-icon>
                </button>
                <div *ngIf="businessPartnerItems.length > 0" [formArrayName]="'businessPartnerItems'" class="col-md-12">
                  <div *ngFor="let item of businessPartnerItems.controls;let i = index" [formGroupName]="i">
                    <p class="header5">
                      Business Partner Item {{ i + 1}}
                      <mat-icon class="md-18 cursor" (click)="removeControl(businessPartnerItems,i)">
                        clear
                      </mat-icon>
                    </p>
                    <div class="col-xl-12">
                      <div class="row">
                        <div class="col-xl-4">
                          <input type="hidden" formControlName="businessPartnerId" />

                          <mat-form-field>
                            <input disabled readonly type="text" matInput placeholder="Business Partner"
                                   formControlName="bpName" required />

                            <mat-hint>Click the magnifying glass to select a business partner</mat-hint>
                            <span matSuffix>
                              <button mat-icon-button type="button" (click)="openBusinessSearch(businessPartnerItems,i)">
                                <mat-icon>search</mat-icon>
                              </button>
                            </span>

                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('required', ['bpName'])">
                              Business Partner is required
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 checkboxSpacing">
                      <mat-checkbox formControlName="isFixedCost">Has Fixed Cost?</mat-checkbox>
                    </div>
                    <br>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-xl-2">
                          <mat-form-field>
                            <input type="number" matInput placeholder="Estimated Hours" formControlName="estimatedHours"
                                   required />
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('required', ['estimatedHours'])">
                              Estimated
                              Hours are required
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-xl-3">
                          <mat-form-field>
                            <input matInput formControlName="activity" type="text" placeholder="Activity" required />
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('required', ['activity'])">
                              Activity is required
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-xl-2">
                          <mat-form-field *ngIf="solutionPilars">
                            <mat-select placeholder="Solution Pillar" formControlName="solutionPilarId" disableOptionCentering>
                              <mat-option *ngFor="let serviceRole of solutionPilars"
                                          [value]="serviceRole.solutionPilarId">
                                {{serviceRole.solutionPilarName}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                        </div>
                        <div class="col-xl-2">
                          <mat-form-field>
                            <span matPrefix>$ </span>
                            <input currencyMask matInput placeholder="Cost" formControlName="cost" required />
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('max', ['cost'])">
                              Cost value is greater than solution pillar max charge value
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-xl-2">
                          <mat-form-field>
                            <span matPrefix>$ </span>
                            <input currencyMask matInput placeholder="Sell Price" formControlName="sell" required />
                          </mat-form-field>
                        </div>
                        <div class="col-xl-2">
                          <mat-form-field>
                            <input matInput currencyMask placeholder="%Uplift" formControlName="upliftPercentage"  [id]="'upliftPerct'+(i+1)" />
                            <span matSuffix>%</span>
                          </mat-form-field>
                          <div class="error" *ngIf="isNotValidUpliftPerct(i)">
                            Alert: This SE Component contains a decrease in the Default Uplift % Value and needs Approval
                          </div>
                        </div>
                        <div class="col-xl-2">
                          <mat-form-field>
                            <input id="bpGrossMargin" matInput currencyMask placeholder="BP Gross Margin%" formControlName="bpGrossMarginPercentage" />
                            <span matSuffix>%</span>
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('required', ['bpGrossMarginPercentage'])">
                              BP Gross Margin% is required
                            </mat-error>
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('max', ['bpGrossMarginPercentage'])">
                              Margin cannot be equal to or greater than 100 %. Please input a lower value
                            </mat-error>
                            <mat-error *ngIf="businessPartnerItems.at(i).hasError('min', ['bpGrossMarginPercentage'])">
                              Margin cannot be less than 0 %. Please input a higher value
                            </mat-error>
                            <!-- <mat-error *ngIf="!businessPartnerItems.at(i).hasError('min', ['bpGrossMarginPercentage']) && !businessPartnerItems.at(i).hasError('required', ['bpGrossMarginPercentage']) && businessPartnerItems.at(i).hasError('isNotValid', ['bpGrossMarginPercentage'])">
                              Alert: This SE Component Contains Non-Standard Pricing for BP Gross Margin%
                            </mat-error> -->
                          </mat-form-field>
                          <div class="error" *ngIf="!businessPartnerItems.at(i).hasError('min', ['bpGrossMarginPercentage']) && !businessPartnerItems.at(i).hasError('required', ['bpGrossMarginPercentage']) && isNotValidBpGrossMarginPerct(i)">
                            Alert: This SE Component contains a decrease in the Default Gross Margin % Value and needs Approval
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <br>
        <div class="col-md-12" style="padding: 0 !important;">
          <mat-accordion *ngIf="serviceRoles">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h3>SKU Based Items</h3>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-divider></mat-divider>
              <app-professional-service-estimate-component-sku-item (addSkuItem)="addSkuItem()" [phaseItemType]="'template'" [solutionPilars]="skuSolPilars" [formGroup]="serviceEstimateForm"
                                                                    [formArrayName]="'skuItems'" [skuItems]="skuItems">
              </app-professional-service-estimate-component-sku-item>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <br>
        <div class="col-md-12" style="padding: 0 !important;">
          <div>
            <mat-card class="customSizingCard_MI">
              <mat-card-header>
                <h3>Material Items</h3>
                <button mat-icon-button (click)="addMeterialItem()" color="primary" type="button">
                  <mat-icon>add</mat-icon>
                </button>
              </mat-card-header>
              <mat-card-content>
                <div class="col-md-4" [formArrayName]="'materialItems'">
                  <div *ngFor="let item of materialItems.controls;let i = index;" [formGroupName]="i">
                    <p class="header5">
                      Item {{i + 1}}

                      <mat-icon class="md-18 cursor" (click)="removeMaterialItem(i)">clear</mat-icon>
                    </p>
                    <mat-form-field>
                      <mat-select placeholder="Expense Type" formControlName="materialItemType" required>
                        <mat-option
                          *ngFor="let materialItemType of materialItemsExpenseTypes"
                          [disabled]="materialItemType === 'Business Partner' && selectableBusinessPartners.length == 0"
                          [value]="materialItemType">
                          {{ materialItemType }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="item.get('materialItemType')?.value == 'Business Partner'">
                      <mat-select id="materialItemBusinessPartner" type="text"
                        formControlName="materialItemBusinessPartnerId" placeholder="Business Partner"
                        disableOptionCentering>
                        <mat-option *ngFor="let bp of selectableBusinessPartners" [value]="bp.businessPartnerId">
                          {{bp.bpName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput type="text" placeholder="Name" formControlName="name" required>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Cost" formControlName="cost" required>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Sell" formControlName="sell" required>
                    </mat-form-field>
                    <hr>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <br>
        <div class="col-md-12">
          <mat-card class="customSizingCard_MI">
            <mat-card-header>
              <h3>Travel Expenses</h3>
            </mat-card-header>
            <mat-card-content>
              <button style="margin-left: 15px !important;" mat-icon-button type="button" (click)="addTravelExpense()"
                      color="primary" title="Add Travel Expense">
                <mat-icon>add</mat-icon>
              </button>
              <div *ngIf="travelExpenses.length > 0" [formArrayName]="'travelExpenses'" class="travel-expense-row">
                <div *ngFor="let item of travelExpenses.controls;let i = index" [formGroupName]="i" class="travel-expense-item">
                  <p class="header5">
                    Trip- {{ i + 1}}
                    <mat-icon class="md-18 cursor" (click)="removeTEControl(travelExpenses,i)">
                      clear
                    </mat-icon>
                  </p>
                  <div>
                    <div class="add-margin-top">
                      <mat-form-field>
                        <mat-select id="travelExpenseType" type="text" formControlName="travelExpenseType"
                          placeholder="Expense Type" required disableOptionCentering
                          (selectionChange)="onTravelExpenseTypeChanged(i)">
                          <mat-option *ngFor="let travelExpenseType of travelExpenseTypes" [value]="travelExpenseType" [disabled]="travelExpenseType === 'Business Partner' && selectableBusinessPartners.length == 0">
                            {{travelExpenseType}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                        <mat-form-field *ngIf="item.get('travelExpenseType')?.value == 'Business Partner'">
                        <mat-select id="travelExpenseBusinessPartner" type="text"
                          formControlName="travelExpenseBusinessPartnerId" placeholder="Business Partner" required
                          disableOptionCentering>
                          <mat-option *ngFor="let bp of selectableBusinessPartners" [value]="bp.businessPartnerId">
                            {{bp.bpName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Airfare" formControlName="airfare" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Airfare Notes" formControlName="airfareNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Auto" formControlName="auto" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Auto Notes" formControlName="autoNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Hotel" formControlName="hotel" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Hotel Notes" formControlName="hotelNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Gas" formControlName="gas" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Gas Notes" formControlName="gasNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Meals" formControlName="meals" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Meals Notes" formControlName="mealNotes"></textarea>
                    </mat-form-field>
                    <mat-form-field>
                      <span matPrefix>$&nbsp;</span>
                      <input matInput currencyMask placeholder="Other/Misc" formControlName="other" />
                    </mat-form-field>
                    <mat-form-field>
                      <textarea matInput placeholder="Other/Misc Notes" formControlName="otherNotes"></textarea>
                    </mat-form-field>
                  </div>
                  </div>
                </div>
            </mat-card-content>
          </mat-card>
        </div>
        <br>
        <div class="col-md-12">

          <mat-card class="customSizingCard_MI">
            <mat-card-header>
              <h3>Additional Items</h3>
            </mat-card-header>
            <mat-card-content>
              <div class="col-md-12">
                <mat-form-field color="warn">
                  <span matPrefix>$&nbsp;</span>
                  <input matInput currencyMask type="text" placeholder="Price Adjustment" formControlName="priceAdjustment" />
                </mat-form-field>
                <mat-form-field>
                  <textarea matInput formControlName="notes" placeholder="Notes" rows="10"></textarea>
                  <mat-error *ngIf="serviceEstimateForm.controls['notes'].hasError('required')">
                    Notes
                    are
                    required for Price Adjustment
                  </mat-error>
                </mat-form-field>
                <!-- <mat-form-field>
                            <span matPrefix>$&nbsp;</span>
                            <input matInput readonly placeholder="Total" formControlName="totalAmount" />
                        </mat-form-field> -->
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngIf="!isValid">
          <h3 class="red-text">Check required fields.</h3>
        </div>
    </mat-card-content>
    <mat-card-actions>
      <button type="submit" mat-raised-button
        [disabled]="(serviceEstimateDetailsClosing.length == 0 && serviceEstimateDetailsExecute.length == 0 && serviceEstimateDetailsPlanning.length == 0 && businessPartnerItems.length == 0 && !serviceEstimateForm.valid) || !serviceEstimateForm.valid"><span
          *ngIf="templateId">Update</span><span *ngIf="!templateId">Create</span></button>
    </mat-card-actions>

  </mat-card>
</form>
