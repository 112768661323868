<mat-card>
  <form [formGroup]="projectControlFormGroup" (ngSubmit)="submitForm()">
    <mat-card-content>
      <mat-form-field>
        <input matInput type="text" formControlName="projectControlName" placeholder="Name" id='projectControlName'/>
        <mat-error *ngIf="projectControlFormGroup.controls['projectControlName'].hasError('required')">A name is required</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" formControlName="projectControlDescription" placeholder="Description" id='projectControlDescription' />
      </mat-form-field>
      <mat-form-field>
        <span matPrefix>$ </span>
        <input matInput currencyMask formControlName="projectControlHourlyRate" placeholder="Hourly Rate" id='projectControlHourlyRate' />
        <mat-error *ngIf="projectControlFormGroup.controls['projectControlHourlyRate'].hasError('required')">A hourly rate is required.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-select formControlName="projectControlProjectControlRates" multiple placeholder="Select Project Control Rate(s)" disableOptionCentering id='projectControlProjectControlRates'>
          <mat-option *ngFor="let projectControlRate of projectControlProjectControlRates" [value]="projectControlRate.projectControlRateId">{{projectControlRate.projectControlRateName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" [disabled]="!projectControlFormGroup.valid">Save</button>
    </mat-card-actions>
  </form>
</mat-card>
