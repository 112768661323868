
<mat-card class="add-margin">
  <div [class.loading]="!isLoading" style="position: relative; margin:auto;width:calc(50% + 225px)">
    <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
  </div>
  <div *ngIf="!isLoading">
    <h4><span>Tag Details: </span>{{tagDetails.name  | titlecase}}</h4>
    <mat-card-content>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Service Projects
            </mat-panel-title>
            <mat-panel-description>
              Displays Projects assosiated with the {{tagDetails.name  | titlecase}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list>
            <mat-list role="list" class="display-flex" *ngFor="let proj of tagDetails.serviceProjectTags">
              <mat-list-item role="listitem">
                <mat-icon matListIcon>fiber_manual_record</mat-icon>
                {{proj.serviceProject.projectName}}
              </mat-list-item>
            </mat-list>
          </mat-list>
          <p *ngIf="!tagDetails.serviceProjectTags.length > 0">No records found</p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header class="expansion-panel-header-odd">
            <mat-panel-title>
              Service Estimates
            </mat-panel-title>
            <mat-panel-description>
              Displays Service Estimates assosiated with the {{tagDetails.name | titlecase}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list role="list" class="display-flex" *ngFor="let se of tagDetails.serviceEstimateTags">
            <mat-list-item role="listitem">
              <mat-icon matListIcon>fiber_manual_record</mat-icon>
              {{se.serviceEstimate.name}}
            </mat-list-item>
          </mat-list>
          <p *ngIf="!tagDetails.serviceEstimateTags.length > 0">No records found</p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              SE Components
            </mat-panel-title>
            <mat-panel-description>
              Displays Service Estimate Components assosiated with the {{tagDetails.name | titlecase}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list role="list" class="display-flex" *ngFor="let sec of tagDetails.serviceEstimateComponentTags">
            <mat-list-item role="listitem">
              <mat-icon matListIcon>fiber_manual_record</mat-icon>
              {{sec.serviceEstimateComponent.componentName}}
            </mat-list-item>
          </mat-list>
          <p *ngIf="!tagDetails.serviceEstimateComponentTags.length > 0">No records found</p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header class="expansion-panel-header-odd">
            <mat-panel-title>
              Business Partners
            </mat-panel-title>
            <mat-panel-description>
              Displays Business Partners assosiated with the {{tagDetails.name | titlecase}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-list role="list" class="display-flex" *ngFor="let bp of tagDetails.businessPartnerTags">
            <mat-list-item role="listitem">
            <mat-icon matListIcon>fiber_manual_record</mat-icon>
            {{bp.businessPartner.businessPartnerLegalName}}
            </mat-list-item>
          </mat-list>
          <p *ngIf="!tagDetails.businessPartnerTags.length > 0">No records found</p>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </div>
</mat-card>


