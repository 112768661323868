import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpRoutingModule } from './help-routing.module';
import { SharedModule } from '../shared/shared.module';
import { HelpComponent } from './help.component';
import { VideosComponent } from './videos/videos.component';
import { VideoComponent } from './videos/video/video.component';
import { ReleaseNotesHistoryComponent } from './release-notes/release-notes-history/release-notes-history.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { EnterpriseUnifiedProcessComponent } from './enterprise-unified-process/enterprise-unified-process.component';
import { ReleaseNotesWorkItemDetails } from './release-notes/release-notes-workitem-details/release-notes-workitem-details.component';


@NgModule({
  imports: [
    CommonModule,
    HelpRoutingModule,
    SharedModule
  ],
  declarations: [HelpComponent, VideosComponent, VideoComponent, ReleaseNotesHistoryComponent, ReleaseNotesComponent, EnterpriseUnifiedProcessComponent, ReleaseNotesWorkItemDetails]
})
export class HelpModule { }
