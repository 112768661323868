import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../admin.service';
import { VehicleManagerDataSource } from '../../models/vehicle-manager.datasource';
import { VehicleManagerService } from '../vehicle-manager.service';
import { Employee } from '../../models/employee';

@Component({
  selector: 'app-vehicle-manager-list',
  templateUrl: './vehicle-manager-list.component.html',
  styleUrls: ['./vehicle-manager-list.component.scss']
})
export class VehicleManagerListComponent implements OnInit {

  vms: VehicleManagerDataSource;
  employees: Array<Employee>;
  displayedColumns: Array<string>;

  constructor(private route: ActivatedRoute, private adminService: AdminService, private vmService: VehicleManagerService) { }

  ngOnInit() {

    this.displayedColumns = ['vehicleDriverName', 'vehicleDriverActions'];
    this.getEmployees();
    this.getVms();
  }
  deleteEmployee(id: string) {
    this.vmService.deleteVehicleManager(id)
      .subscribe(httpResult => {
        this.getVms();

      });
  }
  getVms() {
    this.vmService.getVehicleManagers()
      .subscribe(res => {
        this.vms = new VehicleManagerDataSource();
        this.vms.setData(res);

      });
  }
  getEmployees() {
    this.adminService.getEmployees()
      .subscribe(httpResult => {

        const data = httpResult;
        if (this.employees) {
        } else {
          this.employees = data;
        }

      });
  }
  getEmployee(id: string): Employee {
    return this.employees.filter(emp => emp.employeeId === id)[0];
  }
  getVehicleManagers() {
    this.vmService.getVehicleManagers()
      .subscribe(res => {
        this.vms = new VehicleManagerDataSource();
        this.vms.setData(res);
      });
  }

}
