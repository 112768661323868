<fieldset>
  <legend>
    Pricing Summary
  </legend>
  <mat-table mat-table matSort class="full-width-table" aria-label="Pricing Summary Table"
    [dataSource]="tableDataSource">
    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.label}}(s)</mat-cell>
    </ng-container>

    <ng-container matColumnDef="platform">
      <mat-header-cell *matHeaderCellDef>Platform/Mo.</mat-header-cell>
      <mat-cell [ngClass]="{highlightUpdated: platformCostUpdated}" *matCellDef="let row">{{ isNumber(row.platform) ? (row.platform | currency) : "--"}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="migration">
      <mat-header-cell *matHeaderCellDef>Migration</mat-header-cell>
      <mat-cell [ngClass]="{highlightUpdated: migrationCostUpdated}" *matCellDef="let row">{{ isNumber(row.migrationCost) ? (row.migrationCost | currency) : "--"}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="dataTransfer">
      <mat-header-cell *matHeaderCellDef>Data Transfer</mat-header-cell>
      <mat-cell [ngClass]="{highlightUpdated: dataTransferCostUpdated}" *matCellDef="let row">{{ isNumber(row.dataTransfer) ? (row.dataTransfer | currency) : "--"}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="managedServices">
      <mat-header-cell *matHeaderCellDef>Managed Services</mat-header-cell>
      <mat-cell [ngClass]="{highlightUpdated: managedServiceCostUpdated}" *matCellDef="let row">{{ isNumber(row.managedServices) ? (row.managedServices | currency) : "--"}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef>Total Cost</mat-header-cell>
      <mat-cell [ngClass]="{highlightUpdated: ((platformCostUpdated || managedServiceCostUpdated) && !odd) || ((migrationCostUpdated || dataTransferCostUpdated) && odd)}" *matCellDef="let row; let odd=odd">{{row.total  | currency}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-paginator style="visibility: hidden; height: 0px; margin: 0px 0px 0px 0px">
  </mat-paginator>

</fieldset>
