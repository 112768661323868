import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient) { }
  getClientsWithFilter(filters?: any) {
    if (filters) {
      var keys = Object.keys(filters);
      var query = keys.map((key) => {

        if (filters[key] || filters[key] === 0) {
          const index = keys.indexOf(key);
          if (index < keys.length - 1) {

            return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key]) + '&';
          }
          return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key]);
        } else {
          return null;
        }
      });
      query = query.filter(function (el) {
        return el != null;
      });
      var modifiedQuery = query.join('');
      return this.http.get<Array<any>>(`/api/clients/filter?${modifiedQuery}`);
    } else {

      return this.http.get<Array<any>>(`/api/clients/filter`);
    }
  }
  getClients() {
    return this.http.get<Array<any>>(`/api/clients`);
  }
  getClient(id: any) {
    return this.http.get<any>(`/api/clients/${id}`);
  }
  uploadClients(client: any) {
    const body: FormData = new FormData();
    body.append('file', client, client.name);
    //body.append('uploadDate', client.uploadDate);
    return this.http.post(`/api/clients/upload`, body);
  }
  removeClientRate(id: any) {
    return this.http.delete(`/api/clientrates/${id}`);
  }
  updateClient(client: any) {
    console.log(client);
    return this.http.put(`/api/clients/${client.id}`, client);
  }

}
