<div>
  <div class="col-md-4">
    <mat-form-field>
      <input type="text" matInput placeholder="License Plate Number" [(ngModel)]="model.licensePlateNumber" />
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <mat-form-field>
      <input type="text" matInput placeholder="License Plate State" [(ngModel)]="model.licensePlateState" />
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <mat-form-field>
      <input type="text" matInput placeholder="License Plate State" [(ngModel)]="model.expirationDate" />
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <button (click)="onSubmit()">Save</button>
  </div>
</div>