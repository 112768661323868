<fieldset>
    <legend>
      Network Profiles
    </legend>
    <mat-table mat-table matSort class="full-width-table" aria-label="Network Profile Table" [dataSource]="dataSource" >
      <ng-container matColumnDef="bandwidth">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Bandwidth</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.bandwidth}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="delay">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Delay</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.delay}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="packetLoss">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Loss %</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.packetLoss}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="maxBandwidth">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Max Bandwidth</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.maxBandwidth}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="dataSizeInTeraBytes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Transfer Data</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataSizeInTeraBytes}}</mat-cell>
      </ng-container>
	  
      <ng-container matColumnDef="dataTransferTimeSeconds">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Transfer Time(s)</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataTransferTimeSeconds}}</mat-cell>
      </ng-container>
	  
      <ng-container matColumnDef="dataTransferTimeMinutes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Transfer Time (m)</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataTransferTimeMinutes}}</mat-cell>
      </ng-container>
	  
	  
      <ng-container matColumnDef="dataTransferTimeDays">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Transfer Time (d)</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataTransferTimeDays}}</mat-cell>
      </ng-container>
	  
      <!-- <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="navigateMenu(row.id)">
                <mat-icon>search</mat-icon>
                <span>View Assessment</span>
              </button>
              <button mat-menu-item (click)="deleteRecord(row.id)">
                <mat-icon>delete_outline</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>

          </ng-container> -->
        <!-- </mat-cell>
      </ng-container> -->

      <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <!-- <mat-paginator [class.loading]="loading" #paginator [length]="networkProfiles?.rows?.length" [pageIndex]="0" [pageSize]="50"
                   [pageSizeOptions]="[5, 25, 50, 100]">
    </mat-paginator> -->
  </fieldset>
