<mat-card>
  <mat-card-content>
    <div>
      <mat-radio-group [(ngModel)]="cardTypes" (change)="updateView($event)">
        <mat-radio-button value="0">Gas Card</mat-radio-button>
        <mat-radio-button value="1">Oil Card</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="isGasCard">
      <div class="col-md-8">
        <mat-form-field>
          <input matInput type="text" [(ngModel)]="gasCard.vehicleGasCardNumber" placeholder="Card Number" />
          <mat-hint align="start">
            <strong>The trailing six to eight numbers. Ex. 0016-1</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-md-8">
        <mat-form-field>
          <input matInput [matDatepicker]="picker" placeholder="Expiration Date" [(ngModel)]="gasCard.vehicleGasCardExpirationDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field *ngIf="employees">
          <mat-select [(ngModel)]="gasCard.assignedToEmployeeId" placeholder="Select an Employee" disableOptionCentering>
            <mat-option *ngFor="let employee of employees" [value]="employee.employeeId">{{employee.firstName}} {{employee.lastName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="isOilCard">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput type="text" [(ngModel)]="oilCard.vehicleOilCardNumber" placeholder="Card Number" />
          <mat-hint align="start">
            <strong>The trailing four numbers. Ex. 16-1</strong>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <input matInput [matDatepicker]="picker" placeholder="Expiration Date" [(ngModel)]="oilCard.vehicleOilCardExpirationDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field *ngIf="employees">
          <mat-select [(ngModel)]="oilCard.assignedToEmployeeId" placeholder="Select an Employee" disableOptionCentering>
            <mat-option *ngFor="let employee of employees" [value]="employee.employeeId">{{employee.firstName}} {{employee.lastName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" type="submit" (click)="createCard()">Save</button>
  </mat-card-actions>
</mat-card>
