<div *ngIf="employees">
<h1 mat-dialog-title>Select an Employee to assign.</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Select an Employee</mat-label>
        <mat-select required [(ngModel)]="empData.employeeId" disableOptionCentering #selectedEmployee="ngModel" id="assignEmployee">
            <mat-option *ngFor="let employee of employees" [value]="employee.employeeId" [disabled]="isEmployeeDisabled(employee.employeeId)">{{employee.firstName}}
                {{employee.lastName}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="empData" type="button" color="accent" cdkFocusInitial [disabled]="!selectedEmployee?.valid" id="saveEmployee">Save</button>
    <button mat-raised-button (click)="createAsShadowPhase()" type="button" color="primary" id="createShadow">Create Shadow Phase</button>
    <button mat-raised-button mat-dialog-close color="warn" type="button" id="closePopup">Cancel</button>
</div>
</div>
