import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDeleteDialogComponent } from 'src/app/shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { ProjectControlRateDataSource } from '../../models/project-control-rate.datasource';
import { ProjectControlRateService } from '../project-control-rate.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AdminService } from '../../admin.service';
import { Employee } from '../../../employee/employee-list/employee-list-datasource';
import { MatSort } from '@angular/material/sort';
import { SessionStorageService } from '../../../shared/services/session-storage.service';

@Component({
  selector: 'app-project-control-rate-list',
  templateUrl: './project-control-rate-list.component.html',
  styleUrls: ['./project-control-rate-list.component.scss']
})
export class ProjectControlRateListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: Array<string>;
  projectControlRates: any;
  projectControlRateDataSource: ProjectControlRateDataSource;
  filterForm: UntypedFormGroup;
  employees: Employee[];
  sessionInfo: any;
  sessionStorageKey: string;

  constructor(private projectControlRateService: ProjectControlRateService, private dialog: MatDialog, private fb: UntypedFormBuilder, private adminService: AdminService, private sessionStorageService: SessionStorageService) { }

  ngOnInit() {
    this.displayedColumns = [
      'projectControlRateName',
      'projectControlRateDescription',
      'createdBy',
      'projectControlRateActions'
    ];
    this.filterForm = this.fb.group({
      projectControlRateName: [''],
      createdBy: ['']
    });
    this.sessionStorageKey = 'projectControlRatesData';
    let storageData = this.sessionStorageService.fetchStorageData(this.sessionStorageKey);
    if (storageData) {
      this.sessionInfo = this.sessionStorageService.parseStorageData(storageData);
    } else {
      this.sessionInfo = this.filterForm.value;
      this.sessionStorageService.setStorageData(this.sessionStorageKey, this.filterForm.value);
    }
    this.onFormValueChange();
    this.getEmployees();
    this.getProjectControlRates();
  }

  getEmployees() {
    this.adminService.getAllEmployees().subscribe(res => {
      this.employees = res;
    })
  }

  onFormValueChange() {
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
          this.projectControlRateDataSource = new ProjectControlRateDataSource(this.projectControlRateDataSource.filter(res, this.projectControlRates));
        this.sessionInfo = this.filterForm.value;
        this.sessionStorageService.setStorageData(this.sessionStorageKey, this.sessionInfo);
        this.setupProjectControlRatesListData(this.projectControlRateDataSource.data);
      });
  }

  deleteProjectControlRate(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.projectControlRateService.deleteProjectControlRate(id)
            .subscribe(res => {
              this.getProjectControlRates();
            });
        }
      });
  }
  getProjectControlRates() {
    this.projectControlRateService.getProjectControlRates()
      .subscribe(res => {
        this.projectControlRates = res;
        this.setupProjectControlRatesListData(res);
        this.updateForm();
      });
  }
  setupProjectControlRatesListData(data?: any) {
    this.projectControlRateDataSource = new ProjectControlRateDataSource(data);
    this.projectControlRateDataSource.paginator = this.paginator;
    this.projectControlRateDataSource.sort = this.sort;
  }

  resetFilters() {
    this.filterForm.controls['projectControlRateName'].setValue('');
    this.filterForm.controls['createdBy'].setValue('');
  }

  updateForm() {
    this.filterForm.patchValue({
      projectControlRateName: this.sessionInfo['projectControlRateName'] ? this.sessionInfo['projectControlRateName'] : '',
      createdBy: this.sessionInfo['createdBy'] == null || this.sessionInfo['createdBy'].toString() == "" ? '' : this.sessionInfo['createdBy']
    }); 
  }

}
