import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { Video, VideoListDataSource } from './admin-help-video-datasource';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { AccountInfo } from '@azure/msal-browser';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { AdminHelpVideosService } from '../admin-help-videos.service';

@Component({
  selector: 'app-admin-help-videos-list',
  templateUrl: './admin-help-videos-list.component.html',
  styleUrls: ['./admin-help-videos-list.component.scss']
})
export class AdminHelpVideosListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Video>;
  videoListDataSource: VideoListDataSource;
  loading: boolean;
  fullData: Video[];
  currentUser: AccountInfo | null;
  filterForm: UntypedFormGroup;

  displayedColumns = ['name', 'isActive', 'section', 'description', 'actions'];

  constructor(private fb: UntypedFormBuilder, private authService: MsalService, private videoService: AdminHelpVideosService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.instance.getActiveAccount();
    this.loading = true;
    this.filterForm = this.fb.group({
      nameFilter: [],
      sectionFilter: []
    });
    this.filterForm.valueChanges
      .subscribe(res => {
        this.videoListDataSource = new VideoListDataSource(this.videoListDataSource.filter(res, this.fullData));
        this.videoListDataSource.sort = this.sort;
        this.videoListDataSource.paginator = this.paginator;
        this.table.dataSource = this.videoListDataSource;
      });
    this.getVideos();
  }
  getVideos() {
    this.videoService.getVideos().subscribe(res => {
      this.fullData = res;
      this.videoListDataSource = new VideoListDataSource(this.fullData);
      this.videoListDataSource.sort = this.sort;
      this.videoListDataSource.paginator = this.paginator;
      this.updateForm();
      //this.table.dataSource = this.videoListDataSource;
      this.loading = false;
    }, (error) => {
        //this.loading = false;
    });

  }
  resetFilters() {
    this.filterForm.controls['nameFilter'].setValue('');
    this.filterForm.controls['sectionFilter'].setValue('');

  }
  deleteVideo(id: string) {
    this.videoService.deleteVideo(id).subscribe(res => {
      this.getVideos();
    });
  }

  updateForm() {
    this.filterForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

}
