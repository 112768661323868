<mat-card *ngIf="empFormGroup && azAdEmployees && approles">
  <form [formGroup]=" empFormGroup" (ngSubmit)="submitForm()">
    <mat-card-content>
      <mat-form-field class="col-md-3">
        <input type="text" #select="matAutocompleteTrigger" id="empName"
               placeholder="Select User"
               aria-label="Select User"
               matInput
               formControlName="user"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedEmployee($event)">
          <mat-option *ngFor="let emp of filteredOptionEmps | async" [value]="emp.givenName + ' ' + emp.surname" [disabled]="isDisabled(emp)"
                      [matTooltip]="emp.givenName + ' ' + emp.surname">
            {{emp.givenName + " " + emp.surname}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="empFormGroup.controls['user'].hasError('required')">A User selection is required</mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-3">
        <mat-select id="selectApplicationRoleDropdown" placeholder="Select Application Role" formControlName="appRole" disableOptionCentering>
          <mat-option *ngFor="let role of approles" [value]="role">
            {{role.displayName}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="empFormGroup.controls['appRole'].hasError('required')">An Application role is required
        </mat-error>
      </mat-form-field>
      <mat-error>{{statusMsg}}</mat-error>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" [disabled]="!empFormGroup.valid || !this.isSelected">Save</button>
      <button mat-raised-button type="button" (click)="goBack()">Cancel</button>
    </mat-card-actions>
  </form>
</mat-card>
