import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { AdminService } from '../../../admin/admin.service';
import { VehicleManagerService } from '../../../admin/vehicle-manager/vehicle-manager.service';
import { FleetMangementService } from '../../fleet-mangement.service';
import { FormGroup, FormBuilder, Validators } from '../../../../../node_modules/@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { RoleService } from 'src/app/shared/services/role.service';

@Component({
  selector: 'app-vehicle-expense-report',
  templateUrl: './vehicle-expense-report.component.html',
  styleUrls: ['./vehicle-expense-report.component.scss']
})
export class VehicleExpenseReportComponent implements OnInit {
  manager: any;
  drivers: Array<any>;
  selectedDriverIds: Array<any>;
  expenseReportFormGroup: FormGroup;
  @ViewChild('donwloadCsvLink') donwloadCsvLink: ElementRef;

  constructor(
    private authService: MsalService,
    private adminService: AdminService,
    private vmService: VehicleManagerService,
    private fleetService: FleetMangementService,
    private fb: FormBuilder,
    private roleService: RoleService) { }

  ngOnInit() {
    let managerAzureId: any;
    let idTokenClaims = this.roleService.idToken();
    if (idTokenClaims) {
      managerAzureId = idTokenClaims['oid'];
    }

    this.adminService.getEmployees()
      .subscribe(res => {
        const employees: Array<any> = res;
        const employee = employees.filter(emp => emp.azureAdId === managerAzureId)[0];
        if (employee) {
          this.vmService.getVehicleManagerByEmpId(employee.employeeId)
            .subscribe(vmResult => {
              this.manager = vmResult;
              this.setupForm();

            });
        } else {
          this.setupForm();
        }

      });
    this.fleetService.getDrivers()
      .subscribe(driverRes => {
        this.drivers = driverRes;

      });
  }
  setupForm() {
    if (this.manager) {
      this.expenseReportFormGroup = this.fb.group({
        'startDate': [null, Validators.required],
        'endDate': [null, Validators.required],
        'driverIds': [this.manager.drivers.map((d: any) => d.vehicleDriverId)]
      });
    } else {
      this.expenseReportFormGroup = this.fb.group({
        'startDate': [null, Validators.required],
        'endDate': [null, Validators.required],
        'driverIds': [null]
      });
    }
  }
  runReport() {
    if (this.expenseReportFormGroup.valid) {
      this.fleetService.runExpenseReport(this.expenseReportFormGroup.value)
        .subscribe(fleetReportResult => {
          const reportResult: Array<any> = fleetReportResult;
          if (reportResult.length > 0) {
            const fileText = this.fleetService.convertToCsv(JSON.stringify(reportResult));
            const blob = new Blob([fileText], {
              type: 'text/csv'
            });
            const url = window.URL.createObjectURL(blob);
            const link = this.donwloadCsvLink.nativeElement;
            link.href = url;
            link.download = 'expenseReport.csv';
            link.click();
            window.URL.revokeObjectURL(url);
          }

        });
    }
  }

}
