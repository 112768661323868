import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByTagId'
})
export class FilterByTagIdPipe implements PipeTransform {

  transform(tags: any, businessPartnerTags?: any): any {
    let _tags: Array<any>;
    _tags = [];
    if (tags) {
      tags.forEach((t: any) => {
        businessPartnerTags.forEach((tid: any) => {
          if (t.id == tid.tagId) {
            _tags.push(t);
          }
        });
      });
    }
    return _tags;
  }

}
