import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { CitiesTableDataSource, CitiesTableItem } from './cities-table-datasource';
import { LocationService } from '../../location.service';

@Component({
  selector: 'cities-table',
  templateUrl: './cities-table.component.html',
  styleUrls: ['./cities-table.component.scss']
})
export class CitiesTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<CitiesTableItem>;
  dataSource: CitiesTableDataSource;
  dataLoaded: boolean;

  /**
   *
   */
  constructor(private locationService: LocationService) {
    this.setTableData();

  }
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name'];

  ngOnInit() {
    this.dataLoaded = false;
    this.dataSource = new CitiesTableDataSource();
    this.setTableData();
  }
  setTableData() {
    this.locationService.getCities()
      .subscribe(res => {
        this.dataLoaded = true;
        this.dataSource.setData(res);
      });
  }

  ngAfterViewInit() {

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    //this.table.dataSource = this.dataSource;
  }
}
