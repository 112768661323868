import { Component, OnInit, ViewChild } from '@angular/core';
import { SupportService } from '../support.service';
import { NgForm } from '@angular/forms';
import { SupportFormTypes } from '../_models/support-form-types';

@Component({
  selector: 'app-support-landing-page',
  templateUrl: './support-landing-page.component.html',
  styleUrls: ['./support-landing-page.component.scss']
})
export class SupportLandingPageComponent implements OnInit {

  formType: SupportFormTypes;

  constructor() { }
  ngOnInit() { }

}
