import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, of, Subject } from 'rxjs';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';

@Component({
  selector: 'app-cloud-migration-match-cloudproduct',
  templateUrl: './cloud-migration-match-cloudproduct.component.html',
  styleUrls: ['./cloud-migration-match-cloudproduct.component.scss']
})
export class CloudMigrationMatchCloudproductComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any>;
  cloudProductSelectionForm: UntypedFormGroup;
  cloudProductTableDataSource: CloudProductDataSource;
  cloudProductSelection: any;
  selectedCloudProduct: any;
  selectedAssessmentItem: any;
  matchingCriteria: CloudProductMatchingCriteria;
  formTitle: string = 'Cloud Product Matching';
  cloudProvider: string;
  loading: boolean;
  displayedColumns: ['instanceType'];
  region: string;

  constructor(private service: CloudMigrationToolService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CloudMigrationMatchCloudproductComponent>,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.matchingCriteria = data.rowData;
  }

  ngOnInit(): void {
    this.cloudProductSelectionForm = this.createFormGroup(this.matchingCriteria);
    this.region = this.matchingCriteria?.region;
    this.cloudProvider = this.matchingCriteria?.cloudProviderName;

  }

  eventsSubject: Subject<CloudProductMatchingCriteria> = new Subject<CloudProductMatchingCriteria>();

  emitEventToChild() {
    this.eventsSubject.next();
  }



  createFormGroup(data: CloudProductMatchingCriteria): UntypedFormGroup {
    return new UntypedFormGroup({
      assessmentId: new UntypedFormControl(data?.assessmentItemId),
      vmId: new UntypedFormControl(data?.vmId),
      region: new UntypedFormControl(data?.region),
      cloudProviderName: new UntypedFormControl(data?.cloudProviderName),
      cloudProviderId: new UntypedFormControl(data?.cloudProviderId),
      cpu: new UntypedFormControl(data?.vCPU),
      ram: new UntypedFormControl(data?.vmRam),
    })
  }

  productSelected = (rowData: any) : void => {
    //Close dialog and return data
    this.selectedCloudProduct = rowData;
    this.close();

  }

  close() {
    this.dialogRef.close(this.selectedCloudProduct);
  }

  refresh() {

    var formVal = this.cloudProductSelectionForm.value;

    this.matchingCriteria = new CloudProductMatchingCriteria();
    this.matchingCriteria.environment = formVal?.environment;
    this.matchingCriteria.vmId = formVal?.vmId;
    this.matchingCriteria.assessmentItemId = formVal?.assessmentItemId;
    this.matchingCriteria.cloudProviderName = formVal?.cloudProviderName;
    this.matchingCriteria.cloudProviderId = formVal?.cloudProviderId;
    this.matchingCriteria.region = formVal?.region;
    this.matchingCriteria.vCPU = formVal?.cpu;
    this.matchingCriteria.vmRam = formVal?.ram;
    this.eventsSubject.next(this.matchingCriteria); 
  }

}

export class CloudProductDataSource extends DataSource<any>
{
  data: Cp[];
  nonFilteredData;

  constructor(private clients: Array<any>) {
    super();
    this.data = clients;
    this.nonFilteredData = clients;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<Cp[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      of(this.data)
    ];

    return merge(...dataMutations);
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

}
export class CloudProductMatchingCriteria {
  environment: string;
  cloudProviderName: string;
  cloudProviderId: string;
  region: string;
  vmId: string;
  vCPU: number;
  vmRam: number;
  assessmentItemId: string;
}


export class Cp {
  instanceType: string;
}
