import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BusinessPartnerDataSource } from '../models/business-partner-datatable.model';
import { BusinessPartnerService } from '../business-partner.service';
import { BusinessPartner, BusinessLocation, BusinessPartnerLocationModel } from '../models/bp-model';
import { UntypedFormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';
import { SolutionPilar } from '../models/solution-pilar';
import { LocationService } from '../../shared/services/location.service';
import { TagsService } from '../../tags/tags.service';
import { Tag } from '../../tags/models/tag.model';
import { ActivatedRoute } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { FilterByStateIdPipe } from '../../shared/pipes/filter-by-state-id.pipe';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GeoFencingPopupComponent } from '../../shared/components/geo-fencing-popup/geo-fencing-popup.component';
import { ViewChild } from '@angular/core';
import { BusinessPartnerPopupComponent } from '../../shared/components/business-partner-popup/business-partner-popup.component';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from 'src/app/shared/models/id-token-claims';

@Component({
  selector: 'app-business-partner-list',
  templateUrl: './business-partner-list.component.html',
  styleUrls: ['./business-partner-list.component.scss'],
  providers: [TitleCasePipe, FilterByStateIdPipe]
})
export class BusinessPartnerListComponent implements OnInit {
  @Input() isPopup: boolean;
  @Input() clientData: any;
  @Input() hideGeoFenceOpt: boolean;
  @Output() selectedPartner: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('BusinessPartnerPopupComponent', { static: true }) businessPartnerPopupComponent: BusinessPartnerPopupComponent;
  dialogRef: MatDialogRef<any>;

  businessPartnerDataSource: BusinessPartnerDataSource;
  filteredPartners: Array<BusinessPartner>;
  paginatiedPartners: Array<BusinessPartner>;
  allPartners: Array<BusinessPartner>;
  businessPartners: Array<BusinessPartner>;
  filterForm: UntypedFormGroup;
  tags: Array<Tag>;
  states: Array<any>;
  cities: Array<any>;

  solutionPilars: Array<SolutionPilar>;
  isLoading: boolean;
  routeCountryValue: string;
  countries: Array<BusinessPartnerLocationModel>;
  urlInfo: any;
  filteredOptions: Observable<Array<BusinessPartnerLocationModel>>;
  canCreate = false;
  roles: Array<string> | string;
  bpStatuses = [
    { value: 0, viewValue: 'In Process' },
    { value: 1, viewValue: 'Approved - In Good Standing' },
    { value: 2, viewValue: 'Do Not Use/Accept' },
    { value: 3, viewValue: 'Approved - Awaiting Renewed COI' }
  ];
  
  constructor(private buisnessPartnerService: BusinessPartnerService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private tagsService: TagsService,
    private titleCase: TitleCasePipe,
    private filterByStateIdPipe: FilterByStateIdPipe,
    private dialog: MatDialog,
    private authService: MsalService) { }

  ngOnInit() {
    this.isLoading = true;
    this.countries = [];
    this.roles = [];
    let idTokenClaims: IdTokenClaims | undefined = <IdTokenClaims | undefined>this.authService.instance.getActiveAccount()?.idTokenClaims;
    if (idTokenClaims) {
      this.roles = idTokenClaims['roles'];
    }
    let updatedRoles: string[];
    if (typeof this.roles === 'string') {
      updatedRoles = [
        this.roles
      ];
    } else {
      updatedRoles = this.roles;
    }
    if (updatedRoles.includes('PortalSEServiceOperations') || updatedRoles.includes('PortalAdmin') || updatedRoles.includes('BusinessOperationsSpecialist')) {
      this.canCreate = true;
    }
    if (updatedRoles.includes('FieldServiceCoordinator')) {
      this.canCreate = false;
    }
    this.buisnessPartnerService.getBusinessPartnersLocations().
      subscribe(res => {
        this.countries = res;
      });
    this.locationService.getStates()
      .subscribe(s => {
        this.states = s;
      });
    this.tagsService.getTags()
      .subscribe(tagResult => {
        this.tags = tagResult;
      });
    this.buisnessPartnerService.getSolutionPilars()
      .subscribe(solutionPilarResult => {
        this.solutionPilars = solutionPilarResult;
        this.filteredPartners = solutionPilarResult;
      });

    this.filterForm = this.fb.group({
      'soultionPilar': [''],
      'country': [''],
      'state': [''],
      'city': [''],
      'name': [''],
      'tag': [''],
      'status': [''],
      'agreementType': ['']
    });
    this.updateBps();
    this.route.queryParams
      .subscribe(param => {
        this.urlInfo = {
          name: this.urlInfo && this.urlInfo['name'] != 'null' ? this.urlInfo['name'] : param['name'],
          country: this.getCountry((this.urlInfo && this.urlInfo['country'] != 'null' ? this.urlInfo['country'] : param['country']), false),
          state: this.urlInfo && this.urlInfo['state'] != 'null' ? this.urlInfo['state'] : param['state'],
          city: this.urlInfo && this.urlInfo['city'] != 'null' ? this.urlInfo['city'] : param['city'],
          tag: this.urlInfo && this.urlInfo['tag'] ? this.urlInfo['tag'] : param['tag'],
          status: this.getAllStatuses((this.urlInfo && this.urlInfo['status'] != 'null' ? this.urlInfo['status']?.toString().split(",") : (param['status'] ? param['status']?.toString().split(",") : null)), false),
          agreementType: this.getAllTypes((this.urlInfo && this.urlInfo['agreementType'] != 'null' ? this.urlInfo['agreementType']?.split(",") : (param['agreementType'] ? param['agreementType']?.split(",") : null)), false),
          //agreementType: this.urlInfo && this.urlInfo['agreementType'] ? this.urlInfo['agreementType'] : param['agreementType'],
        };
        this.routeCountryValue = this.urlInfo['country'] ? this.urlInfo['country'] : param['country'];
        if (!this.isPopup)
          this.setUrlQueryParams(this.urlInfo['name'], this.urlInfo['country'], this.urlInfo['state'], this.urlInfo['tag'], this.urlInfo['status'], this.urlInfo['agreementType'], this.urlInfo['city']);
      });
    this.monitorValues();
    this.onCountryValChange();
  }

  onCountryValChange() {
    this.filteredOptions = this.filterForm.controls['country'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): BusinessPartnerLocationModel[] {
    const filterValue = (value || this.routeCountryValue) ? (value || this.routeCountryValue).toLowerCase() : "";

    return this.countries.filter(option => option.country.toLowerCase().includes(filterValue));
  }

  clearRouteChangeValue() {
    this.routeCountryValue = '';
  }

  getStatus(statusValue: any, isValue?: boolean) {
    switch (statusValue) {
      case 0:
      case 'In Process': return isValue ? 'In Process' : 0;
      case 1:
      case 'Approved - In Good Standing': return isValue ? 'Approved - In Good Standing' : 1;
      case 2:
      case 'Do Not Use/Accept': return isValue ? 'Do Not Use/Accept' : 2;
      case 3:
      case 'Approved - Awaiting Renewed COI': return isValue ? 'Approved - Awaiting Renewed COI' : 3;
      default: return isValue ? 'Approved - In Good Standing' : this.isPopup ? [1,3] : 1;
    }
  }

  getCountry(countryValue: any, isValue?: boolean) {
    if (parseInt(countryValue) >= 0 && this.countries.length > 0) {
      switch (countryValue) {
        case countryValue:
          return isValue ? this.countries[countryValue].country.toLowerCase() : this.countries[countryValue].id;
      }
    }
    return false;
  }

  getAgreementType(agreementType: any, isValue?: boolean) {
    switch (agreementType) {
      case 0:
      case 'Subcontractor Agreement': return isValue ? 'Subcontractor Agreement' : 0;
      case 1:
      case 'Reverse Subcontractor Agreement': return isValue ? 'Reverse Subcontractor Agreement' : 1;
      case 2:
      case 'Non-Disclosure Agreement': return isValue ? 'Non-Disclosure Agreement' : 2;
      case 3:
      case 'Mutual Non-Disclosure Agreement': return isValue ? 'Mutual Non-Disclosure Agreement' : 3;
      case 4:
      case 'Reseller Agreement': return isValue ? 'Reseller Agreement' : 4;
      case 5:
      case 'Partner Agreement': return isValue ? 'Partner Agreement' : 5;
      case 6:
      case 'Sub Provided Agreement': return isValue ? 'Sub Provided Agreement' : 6;
      case 7:
      case 'Other Agreement': return isValue ? 'Other Agreement' : 7;
      default: return isValue ? 'Not Found' : 0;
    }
  }

  getAllTypes(agreementTypeValue: any, isValue?: boolean) {
    let agreementValues: any = [];
    if (agreementTypeValue && agreementTypeValue.length > 0) {
      agreementTypeValue.forEach((agg: any) => {
        let value = this.getAgreementType(agg, isValue);
        agreementValues.push(value);
      })
      return agreementValues;
    } else
      return null;
  }

  getAllStatuses(statusValue: any, isValue?: boolean) {
    let statusValues: any = [];
    if (statusValue && statusValue.length > 0) {
      statusValue.forEach((st: any) => {
        let value = this.getStatus(st, isValue);
        statusValues.push(value);
      })
    } else if(this.isPopup){
      return this.getStatus("default", isValue);
    } else{
      let value = this.getStatus("default", isValue);
      statusValues.push(value);
    }
    return statusValues;
  }

  monitorValues() {

    this.filterForm.valueChanges.subscribe(
      formValue => {
        this.businessPartners = [];
        const soultionPilarValue: string = formValue['soultionPilar'];
        const nameValue: string = formValue['name'];
        const locationValue: string = formValue['country'];
        const stateValue: string = formValue['state'];
        const cityValue: string = formValue['city'];
        const tagValue: string = formValue['tag'];
        const statusValue: any[] = formValue['status'];
        const agreementTypeValue: any[] = formValue['agreementType'];
        const countryValue = locationValue ? this.countries.find(x => x.country?.toLowerCase() == locationValue.toLowerCase())?.id : null;
        Object.assign(this.businessPartners, this.allPartners);
        if (soultionPilarValue) {
          const partners = this.businessPartners.filter(bp => {
            if (bp.solutionPilarsBusinessPartners) {
              let doesExist = false;
              if (bp.solutionPilarsBusinessPartners.length > 0) {
                bp.solutionPilarsBusinessPartners.forEach(sp => {
                  if (!doesExist) {
                    doesExist = sp.solutionPilarId == soultionPilarValue;
                  }
                });
              }
              return doesExist;
            } else {
              return false;
            }
          });
        }
        if (stateValue) {
          this.businessPartners = this.businessPartners.filter(bp => {
            if (bp.serviceableStates) {
              let doesExist = false;
              if (bp.serviceableStates.length > 0) {
                bp.serviceableStates.forEach(sp => {
                  if (!doesExist) {
                    doesExist = sp.stateId == stateValue;
                  }
                });
              }
              return doesExist;
            } else {
              return false;
            }
          });
        }
        if (cityValue) {
          this.businessPartners = this.businessPartners.filter(bp => {
            if (bp.serviceableCities) {
              let doesExist = false;
              if (bp.serviceableCities.length > 0) {
                bp.serviceableCities.forEach(sp => {
                  if (!doesExist) {
                    doesExist = sp.cityId == cityValue;
                  }
                });
              }
              return doesExist;
            } else {
              return false;
            }
          });
        }
        if (countryValue) {
          if (locationValue && countryValue >= 0) {
            this.businessPartners = this.businessPartners.filter(bp => {
              if (bp.locations) {
                let doesExist = false;
                if (bp.locations.length > 0) {
                  bp.locations.forEach(loc => {
                    if (!doesExist && this.countries[countryValue].country?.toLowerCase() != "usa") {// && loc.isInternational && loc.country
                      doesExist = loc.country?.toLowerCase() == this.countries[countryValue].country.toLowerCase();
                    } else if (!doesExist && this.countries[countryValue].country?.toLowerCase() == "usa") {
                      doesExist = (loc.country == null || loc.country == "" || loc.country?.toLowerCase() == this.countries[countryValue].country.toLowerCase());
                    }
                  });
                }
                return doesExist;
              } else {
                return false;
              }
            });
          }
        }
        if (tagValue) {
          this.businessPartners = this.businessPartners.filter(bp => {
            if (bp.businessPartnerTags) {
              let doesExist = false;
              if (bp.businessPartnerTags.length > 0) {
                bp.businessPartnerTags.forEach(tag => {
                  if (!doesExist) {
                    doesExist = tag.tagId == tagValue;
                  }
                });
              }
              return doesExist;
            } else {
              return false;
            }
          });
        }
        if (nameValue.length > 0) {
          this.businessPartners = this.businessPartners.filter(bp => {
            return bp.businessPartnerLegalName.toLocaleLowerCase().indexOf(nameValue.toLocaleLowerCase()) > -1;
          });
        }
        if (statusValue && statusValue.length > 0) {
          this.businessPartners = !this.isPopup ? this.businessPartners
          .filter(x => +statusValue.includes(x.businessPartnerStatus ))
           : this.businessPartners.filter((bp: any) => {
            return +statusValue.includes(bp.businessPartnerStatus)
          });
        }
        if (agreementTypeValue && agreementTypeValue.length > 0) {
          let businessPartnersArray: Array<BusinessPartner> = [];
          agreementTypeValue.forEach(agreementValue => {
            if (Number.parseInt(agreementValue.toString()) >= 0) {
              let businessPartners: Array<BusinessPartner> = [];
              businessPartners = this.businessPartners.filter(bp => {
                if (bp.agreements.length > 0) {
                  let doesExist = false;
                  bp.agreements.forEach(agreement => {
                    if (!doesExist) {
                      const agreementType = Number.parseInt(agreementValue.toString());
                      doesExist = agreement.agreementType == agreementType;
                    }
                  });
                  return doesExist;
                } else {
                  return false;
                }
              });
              businessPartners.forEach(bp => {
                if (businessPartnersArray.indexOf(bp) == -1)
                  businessPartnersArray.push(bp);
              })
            }
          });
          this.businessPartners = businessPartnersArray;
        }

        this.filteredPartners = this.businessPartners;
        if (!this.isPopup)
          this.setUrlQueryParams(nameValue, countryValue, stateValue, tagValue, statusValue, agreementTypeValue, cityValue);
        this.setTableData(this.businessPartners);
      }
    );
  }

  onStateChange(){
    this.cities = [];
    let states: string = this.filterForm.controls['state'].value;
    if(states){
      this.locationService.getCitiesByStateId(states)
      .subscribe(res => {
        const cities = res;
        this.cities = cities;
      });
    }
  }

  setUrlQueryParams(nameValue: any, countryValue: any, stateValue: any, tagValue: any, statusValue: any, agreementTypeValue: any, cityValue: any) {
    this.urlInfo = {
      name: nameValue ? nameValue.toString() : null,
      country: !isNaN(countryValue) ? this.getCountry(countryValue, true) : null,
      state: stateValue ? stateValue.toString() : null,
      city: cityValue ? cityValue.toString() : null,
      tag: tagValue ? tagValue.toString() : null,
      status: statusValue?.length > 0 ? this.getAllStatuses(statusValue, true) : null,
      agreementType: agreementTypeValue?.length > 0 ? this.getAllTypes(agreementTypeValue, true) : null,
      //agreementType: agreementTypeValue ? agreementTypeValue.toString() : null,
    };
    let params = Object.keys(this.urlInfo)
      .map(key => {
        if (this.urlInfo[key]) {
          return `${key}=${key == 'country' ? this.titleCase.transform(this.urlInfo[key]) : this.urlInfo[key]}`
        }
        else if (key == 'country' && this.routeCountryValue) {
          return `${key}=${this.titleCase.transform(this.routeCountryValue)}`
        }
        else return false
      }).filter(Boolean)
      .join('&');
    params ? window.history.replaceState({}, '', `${location.pathname}?${params}`) : window.history.replaceState({}, '', `${location.pathname}`);
  }

  updateForm() {
    this.filterForm.patchValue({
      name: this.urlInfo['name'] ? this.urlInfo['name'] : '',
      country: this.urlInfo['country'] ? this.getCountry(this.urlInfo['country'], false) : this.routeCountryValue ? (this.getCountryName(this.routeCountryValue) ? this.routeCountryValue : this.routeCountryValue.toLocaleUpperCase()) : null, //this.countries.find(c => c.country.toLowerCase() == this.routeCountryValue.toLowerCase()).id
      state: this.urlInfo['state'] ? this.urlInfo['state'] : '',
      city: this.urlInfo['city'] ? this.urlInfo['city'] : '',
      tag: this.urlInfo['tag'] ? this.urlInfo['tag'] : '',
      status: this.urlInfo['status'] ? this.getAllStatuses(this.urlInfo['status'], false) : null,
      agreementType: this.urlInfo['agreementType'] ? this.getAllTypes(this.urlInfo['agreementType'], false) : null,
      //agreementType: this.urlInfo['agreementType'] ? this.urlInfo['agreementType'] : '',
    });
  }

  updateBps() {
    this.buisnessPartnerService.getBusinessPartners()
      .subscribe(res => {
        this.businessPartnerDataSource = new BusinessPartnerDataSource();
        let data: Array<BusinessPartner>;
        data = res;
        let countries = []; let index: number = 0;
        data.forEach(bp => {
          // this.buisnessPartnerService.getBusinessPartnerScoreCardAverageTotal(bp.id)
          //   .subscribe(ratingRes => {
          //     if (ratingRes) {
          //       bp['overallRating'] = ratingRes;
          //     } else {
          //       bp['overallRating'] = 'Not rated'
          //     }
          //   });
          if (bp.primaryLocation) {
            this.locationService.getState(bp.primaryLocation.stateId)
              .subscribe(res2 => {
                let state;
                state = res2;
                bp.primaryLocation.state = state.name;
              });
          }
          //if (bp.locations.length > 0) {
          //  bp.locations.forEach(loc => {
          //    let locExists = countries.filter(x => x.country?.toLowerCase() == loc.country?.toLowerCase()).length > 0;
          //    if (loc.isInternational && loc.country && !locExists) {
          //      countries.push({ country: loc.country.toLowerCase(), id: index++});
          //    } else if (!loc.isInternational && !(countries.filter(x => x.country?.toLowerCase() == "usa").length > 0)) {
          //      countries.push({ country: "usa", id: index++ });
          //    }
          //  });
          //}
        });
        this.businessPartnerDataSource.setData(data);
        this.allPartners = res;
        this.monitorValues();
        this.onCountryValChange();
        this.updateForm();
        this.onStateChange();
        this.isLoading = false;
      });
  }
  setTableData(data: Array<BusinessPartner>) {
    this.businessPartnerDataSource = new BusinessPartnerDataSource();
    this.businessPartnerDataSource.setData(data);
  }
  getCountryName(country: any) {
    return country && country.toLowerCase() != "usa";
  }
  filterByStatus(status: any) {
    this.setTableData(this.filteredPartners.filter(partner => {
      return partner.businessPartnerStatus === status;
    }));
  }
  filterBySolutionPartners(pilar: any) {

  }
  filterByScoreCard(scoreCard: any) {

    this.setTableData(this.filteredPartners.filter(partner => {
      return partner.scoreCardId === scoreCard;
    }));
  }
  closePopUp(data: any) {
    this.selectedPartner.emit(data);
  }
  resetFilters() {
    // Object.assign(this.filteredPartners, this.allPartners);
    this.filterForm.controls['soultionPilar'].setValue('');
    this.filterForm.controls['name'].setValue('');
    this.filterForm.controls['country'].setValue('');
    this.filterForm.controls['state'].setValue('');
    this.filterForm.controls['city'].setValue('');
    this.filterForm.controls['tag'].setValue('');
    this.filterForm.controls['status'].setValue([1]);
    this.filterForm.controls['agreementType'].setValue('');
    this.routeCountryValue = '';
    this.setTableData(this.allPartners);
    this.monitorValues();
    this.onCountryValChange();
    this.onStateChange();
    this.updateForm();

  }
  openGeoFencingPopup() {
    let prepoupulatedGeofenceData: CityStateGeoFenceTransitModel = new CityStateGeoFenceTransitModel();
    if (this.clientData && this.clientData.city) {
      this.locationService.getCityByName(this.clientData.city)
        .subscribe(cityResult => {
          prepoupulatedGeofenceData.stateId = this.states.find(s => s.abbreviation == this.clientData.state)['stateId'];
          prepoupulatedGeofenceData.city = cityResult[0];
          this.dialogRef = this.dialog.open(GeoFencingPopupComponent, {
            height: '70%',
            width: '80%',
            autoFocus: false,
            id: 'geoFencingModal',
            data: {
              isPopup: this.isPopup,
              clientData: prepoupulatedGeofenceData,
              states: this.states,
              //dataSource: this.businessPartnerDataSource,
              filteredPartners: this.businessPartnerDataSource.data
            }
          });
          this.dialogRef.afterClosed()
            .subscribe(result => {
              if (result) {
                this.closePopUp(result);

              }
            });
        });

    }
    else {
      this.dialogRef = this.dialog.open(GeoFencingPopupComponent, {
        height: '70%',
        width: '80%',
        autoFocus: false,
        id: 'geoFencingModal',
        data: {
          isPopup: this.isPopup,
          clientData: prepoupulatedGeofenceData,
          states: this.states,
          //dataSource: this.businessPartnerDataSource,
          filteredPartners: this.filteredPartners
        }
      });
      this.dialogRef.afterClosed()
        .subscribe(result => {
          if (result) {
            this.closePopUp(result);

          }
        });
    }

  }
}
export class CityStateGeoFenceTransitModel {
  stateId: string;
  city: any;
}
