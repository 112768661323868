import { VehicleModel } from './fleet-management-models';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
export class VehicleModelDataSource extends DataSource<any> {
    data: Array<VehicleModel>;
    connect(): Observable<VehicleModel[]> {

        return of(this.data);
    }
    disconnect(collectionViewer: CollectionViewer): void {

    }
    setData(data: Array<VehicleModel>) {
        this.data = data;
    }

}
