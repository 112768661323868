
<mat-card>
  <h4>Previous Versions</h4>
  <mat-card-content *ngIf="employees">
    <mat-table #table [dataSource]="dataSource" multiTemplateDataRows>
      <ng-container matColumnDef="componentName">
        <mat-header-cell *matHeaderCellDef (click)="sortByProjectName()" class="clickable">
          Name <mat-icon class="twelve">
            sort
          </mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{componentName}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="dateModified">
        <mat-header-cell *matHeaderCellDef (click)="sortByDate()" class="clickable">
          Modified Date <mat-icon class="twelve">
            sort
          </mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createdDate | date:'shortDate'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef> Created by</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: createdBy}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="lastEditedBy">
        <mat-header-cell *matHeaderCellDef> Last Edited by</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: element.modifiedById}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a mat-raised-button color="default" type="button" id="viewDetails"
             (click)="viewDetails(element)">{{element != expandedElement ? 'Show' : 'Hide'}}Details</a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <mat-accordion [ngStyle]="{height: element == expandedElement ? '' : '0px'}" class="element-detail">
            <mat-expansion-panel [expanded]="expandedElement? true: false" class="row expansion-panel">
              <!--<span *ngIf="changes.componentName">Component Name is changed from {{oldValue.ComponentName}} to {{updatedValue.ComponentName}}</span>-->
              <div class="row" *ngIf="checkChanges()">
                <div class="col-md-5">
                  <h4><u>Old Values</u></h4>
                  <mat-list role="list" *ngIf="changeItems?.length">
                    <ng-container *ngFor="let item of changeItems">
                      <mat-list-item role="listitem" *ngIf="isDisplay(item)">{{item.key}} - {{item.oldValue}}</mat-list-item>
                    </ng-container>
                  </mat-list>
                  <mat-list role="list" *ngIf="changedPhaseItems?.length">
                    <h4>Changed Phase Items</h4>
                    <ng-container *ngFor="let items of changedPhaseItems">
                      <div *ngFor="let item of items.oldValue; let i=index">
                        <span style='font-size:14px;'><b>&#8827;</b></span>
                        <div *ngFor="let obj of item| keyvalue">
                          <mat-list-item role="listitem" *ngIf="isDisplay(obj)">{{obj.key}} - {{obj.value}}</mat-list-item>
                        </div>
                      </div>
                    </ng-container>
                  </mat-list>
                  <mat-list role="list" *ngIf="deletedPhaseItems?.length">
                    <h4>Deleted Phase Items</h4>
                    <ng-container *ngFor="let item of deletedPhaseItems">
                      <div style='font-size:14px;'><b>&#8827;</b></div>
                      <div *ngFor="let obj of item| keyvalue">
                        <mat-list-item role="listitem" *ngIf="isDisplay(obj)">{{obj.key}} - {{obj.value}}</mat-list-item>
                      </div>
                    </ng-container>
                  </mat-list>
                </div>
                <div class="col-md-5 vertical">
                  <h4><u>New Values</u></h4>
                  <mat-list role="list" *ngIf="changeItems?.length">
                    <ng-container *ngFor="let item of changeItems">
                      <mat-list-item role="listitem" *ngIf="isDisplay(item)">{{item.key}} - {{item.updatedValue}}</mat-list-item>
                    </ng-container>
                  </mat-list>
                  <mat-list role="list" *ngIf="changedPhaseItems?.length">
                    <h4>Changed Phase Items</h4>
                    <ng-container *ngFor="let items of changedPhaseItems">
                      <div *ngFor="let item of items.updatedValue; let i=index">
                        <span style='font-size:14px;'><b>&#8827;</b></span>
                        <div *ngFor="let obj of item| keyvalue">
                          <mat-list-item role="listitem" *ngIf="isDisplay(obj)">{{obj.key}} - {{obj.value}}</mat-list-item>
                        </div>
                      </div>
                    </ng-container>
                  </mat-list>
                  <mat-list role="list" *ngIf="addedPhaseItems?.length">
                    <h4>Added Phase Items</h4>
                    <ng-container *ngFor="let item of addedPhaseItems">
                      <div style='font-size:14px;'><b>&#8827;</b></div>
                      <div *ngFor="let obj of item| keyvalue">
                        <mat-list-item role="listitem" *ngIf="isDisplay(obj)">{{obj.key}} - {{obj.value}}</mat-list-item>
                      </div>
                    </ng-container>
                  </mat-list>
                </div>
              </div>
              <div class="row" *ngIf="!checkChanges()">No changes found</div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;let element" [ngClass]="{'element-row': element == expandedElement}"></mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail'];let element" [ngStyle]="{display: element == expandedElement? 'flex' : 'none'}"></mat-row>
    </mat-table>
    <div *ngIf="!(dataSource && dataSource.data.length > 0)" class="no-records">
      No records found
    </div>
    <mat-paginator (page)="pageChangedEventUsages($event)" *ngIf="dataSource" [length]="serviceEstimateComponents.length" [ngStyle]="{display: serviceEstimateComponents.length > 0 ? 'block' : 'none'}"
                   [pageSize]="5" [pageIndex]="0" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
  </mat-card-content>
</mat-card>
