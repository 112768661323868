import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-loading-popup',
  templateUrl: './loading-popup.component.html',
  styleUrls: ['./loading-popup.component.scss']
})
export class LoadingPopupComponent implements OnInit {

  loading: boolean;

  constructor(private loaderService: LoadingService) {
    this.loading = false;
    // this.loaderService.isLoading.pipe(distinctUntilChanged()).subscribe((v) => {
    //   this.loading = v;
    // });

  }
  ngOnInit() {
  }

}
