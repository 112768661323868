import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppRole } from './models/appRole';

@Injectable({
  providedIn: 'root'
})
export class RoleAdministrationService {

  constructor(private http: HttpClient) { }

  getAppRoles() {
    return this.http.get<AppRole>(`/api/RoleAdmin`);
  }

  getAssignedAppRoleUsers(roleId: string) {
    return this.http.get<AppRole>(`/api/RoleAdmin/appRoleUsers/${roleId}`);
  }

  getAppRole(roleId: string) {
    return this.http.get<AppRole>(`/api/RoleAdmin/${roleId}`);
  }

  createAppRole(appRole: any) {
    const body = JSON.stringify(appRole);
    return this.http.post(`/api/RoleAdmin/CreateRole`, body);
  }

  updateAppRole(appRole: any) {
    const body = JSON.stringify(appRole);
    return this.http.put(`/api/RoleAdmin`, body);
  }

  deleteAppRole(appRoleId: string) {
    return this.http.delete(`/api/RoleAdmin/${appRoleId}`);
  }
}
