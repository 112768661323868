<fieldset>
  <legend>
    Storage Summary
  </legend>
  <mat-table mat-table matSort class="full-width-table" aria-label="Storage Summary Table"
    [dataSource]="tableDataSource">
    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell  *matCellDef="let row;" >{{row.label}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>Storage Type</mat-header-cell>
      <mat-cell  [ngClass]="{formUpdated: storageTypeUpdated && odd}" *matCellDef="let row; let odd = odd;" >{{row.type}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="capacity">
      <mat-header-cell *matHeaderCellDef>Storage Capacity(GB)</mat-header-cell>
      <mat-cell [ngClass]="{formUpdated: storageCapacityUpdated && odd}" *matCellDef="let row; let odd = odd;" >{{row.capacity}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
      <mat-header-cell *matHeaderCellDef>Storage Cost</mat-header-cell>
      <mat-cell [ngClass]="{formUpdated: storageCapacityUpdated && odd}" *matCellDef="let row; let odd = odd;" >{{row.cost ? '$'+row.cost : 'N/A'}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row columns: displayedColumns;" ></mat-row>
  </mat-table>


  <mat-paginator style="visibility:hidden; height:0px; margin:0px 0px 0px 0px">
  </mat-paginator>
</fieldset>
