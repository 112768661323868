<mat-card>
  <mat-card-content>
    <h2>Template Components</h2>
    <a mat-raised-button color="primary" [routerLink]="['create']" matTooltip="Create Template" id="templateCreate"
                    [matTooltipPosition]="'right'"><mat-icon>add</mat-icon>
    Template
    </a>
    <app-templates-list> </app-templates-list>
  </mat-card-content>
</mat-card>
