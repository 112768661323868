<div *ngIf="cities">
  <div class="container-fluid">
    <mat-card>
      <mat-card-content>
        <a mat-raised-button [routerLink]="['create']" color="primary">Create</a>
        <mat-table #table [dataSource]="cities">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span> {{element.cityName}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.stateAbbreviation}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-raised-button color="warn" type="button" (click)="deleteCity(element.cityId)">Delete</button>
              <a mat-raised-button type="button" [routerLink]="['edit',element.cityId]">Edit</a>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>