import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cloud-migration-discovery-workload-list',
  templateUrl: './cloud-migration-discovery-workload-list.component.html',
  styleUrls: ['./cloud-migration-discovery-workload-list.component.scss']
})
export class CloudMigrationDiscoveryWorkloadListComponent implements OnInit {
  @Input() workloads:any;
  @Input() LPARLabeling: boolean;
  @Output() OnViewAction: EventEmitter<any> = new EventEmitter();
  @Output() OnDeleteAction: EventEmitter<any> = new EventEmitter();
  workloadsDataSource: any;
  displayedColumns: string[];

  constructor() { }

  ngOnInit(): void {
    this.setData();
  }

  ngOnChanges(){
    this.setData();
  }

  setData() {
    this.workloadsDataSource = this.workloads;
    let workloadDetailColumns: string[];
    if (this.LPARLabeling)
    {
        workloadDetailColumns = ["name","processors", "ram", "osType",  "actions"];
    }
    else
    {
        workloadDetailColumns = ["vmId", "vmCpu", "vmCores", "vmRam", "actions" ];
    }
    this.displayedColumns = workloadDetailColumns;
  }

  viewRow(row: any){
    this.OnViewAction.emit(row);
  }
  deleteRow(row: any){
    this.OnDeleteAction.emit(row);
  }
}

