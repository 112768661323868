import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { GraphApiService } from '../../shared/services/graph-api.service';
import { RoleService } from '../../shared/services/role.service';

@Component({
  selector: 'app-my-profile-landing-page',
  templateUrl: './my-profile-landing-page.component.html',
  styleUrls: ['./my-profile-landing-page.component.scss']
})
export class MyProfileLandingPageComponent implements OnInit {
  isDarkViewEnabled: boolean;
  userId: any;
  currentUser: any;
  isLoading: boolean;
  @HostBinding('class.dark-theme') isDarkThemeEnabled: boolean = false;
  isDashboardViewEnabled: boolean = false;
  user: AccountInfo | null;
  isDashbboardViewVisible: boolean = false;

  constructor(private authService: MsalService, private graphApiService: GraphApiService, private roleService: RoleService) {
  }

  ngOnInit() {
    this.isDarkThemeEnabled = false;
    this.isDashboardViewEnabled = false;
    this.isDashbboardViewVisible = false;
    this.isLoading = true;
    this.setupPermissions();
    this.getUser();
    this.getStorageValues();
  }

  getActiveAccount() {
    return this.authService.instance.getActiveAccount();
  }

  setupPermissions() {
    this.user = this.authService.instance.getActiveAccount();
    this.userId = this.user?.localAccountId;
    if (this.hasRole('PortalSEPostsalesEngineerSA') || this.hasRole('Projects.Approver') || this.hasRole('PortalAdmin') ||
      this.hasRole('PortalSEDirector') || this.hasRole('PortalSEProjectManager') || this.hasRole('PortalSEPresalesArchitect') ||
      this.hasRole('PortalSEPostsalesEngineer') || this.hasRole('PortalSalesSupport') || this.hasRole('PortalSEProjectManagerOfficer') ||
      this.hasRole('ProfessionalServiceCoordinator')) {
      this.isDashbboardViewVisible = true;
    }
  }

  getStorageValues() {
    let darkTheme; let isEnabledDashboardView;
    var darkThemeLocalStorageValue = localStorage.getItem('useDarkTheme');
    var dashboardViewStorageValue = localStorage.getItem('isDashboardViewEnabled');
    if (darkThemeLocalStorageValue) {
      darkTheme = JSON.parse(darkThemeLocalStorageValue);
    }
    if (dashboardViewStorageValue) {
      isEnabledDashboardView = JSON.parse(dashboardViewStorageValue);
    }
    if (darkTheme) {
      this.isDarkThemeEnabled = darkTheme['useDarkTheme'];
    }
    if (isEnabledDashboardView)
      this.isDashboardViewEnabled = isEnabledDashboardView['isDashboardViewEnabled'];
  }

  getFontColor() {
    return !this.isDarkThemeEnabled ? 'theme' : 'dark-theme';
  }

  getUser() {
    if (this.userId) {
      this.graphApiService.getUser(this.userId).subscribe((data) => {
        this.currentUser = data;
        this.isLoading = false;
      })
    }
  }

  getToolTipData() {
    return this.currentUser.mail;
  }

  flipTheme() {
    var obj = { 'useDarkTheme': this.isDarkThemeEnabled };
    localStorage.setItem('useDarkTheme', JSON.stringify(obj));
    location.reload();

  }

  toggleDashboardView() {
    var obj = { 'isDashboardViewEnabled': this.isDashboardViewEnabled };
    localStorage.setItem('isDashboardViewEnabled', JSON.stringify(obj));
    location.reload();
  }

  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, false);
  }
}
