import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CloudMigrationToolAdminComponent } from './cloud-migration-tool-admin.component';
import { CloudProviderPricingScheduleComponent } from './cloud-provider-pricing-schedule/cloud-provider-pricing-schedule.component';
import { CloudProviderPricingComponent } from './cloud-provider-pricing/cloud-provider-pricing.component';
import { CloudProviderDetailComponent } from './cloud-provider/cloud-provider-detail/cloud-provider-detail.component';
import { CloudProviderComponent } from './cloud-provider/cloud-provider.component';

// const routes: Routes = [];

const routes: Routes = [
  {    
    path: 'cmtadmin/cloudproviders/detail/:id', component: CloudProviderDetailComponent 
  },
  {
    path: 'cmtadmin', component: CloudMigrationToolAdminComponent, children: [
      {path: 'cloudproviders', component: CloudProviderComponent, children: [
          { path: '', component: CloudProviderComponent },
          { path: 'detail/:id', component: CloudProviderDetailComponent }
      ]
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CloudMigrationToolAdminRoutingModule { }
