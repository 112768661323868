<mat-card style="min-height: 80%" *ngIf="environmentForm">
  <mat-card-content>
    <div [formGroup]="environmentForm">
      <div class="row">
        <div class="col-md-3">
          <mat-form-field class="field-full-width">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" id="envname" />
            <mat-error
              *ngIf="environmentForm.controls['name'].hasError('required')"
            >
              Name Type is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field *ngIf="environmentTypeOptions">
            <mat-label>Environment Type</mat-label>
            <mat-select
              formControlName="environmentType"
              disableOptionCentering
              id="envTypeDropdown"
            >
              <mat-option
                *ngFor="let envType of environmentTypeOptions"
                [value]="envType.value"
                id="envTypes"
              >
                {{ envType.value }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                environmentForm.controls['environmentType'].hasError('required')
              "
            >
              Environment Type is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <span *ngIf="showAdd">
            <button
              show
              mat-icon-button
              type="button"
              id="btn-add-environment"
              [disabled]="!environmentForm.valid"
              (click)="addDiscoveryEnvironment()"
            >
              <mat-icon>add</mat-icon>
            </button>
          </span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
