import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
export class ThresholdDataSource extends DataSource<any> {
    data: Array<any>;
    connect(): Observable<any[]> {

        return of(this.data);
    }
    disconnect(collectionViewer: CollectionViewer): void {

    }
    setData(data: Array<any>) {
        this.data = data;
    }

}
