import { Chart, registerables } from 'chart.js';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DashboardRequestModel } from '../models/dashboard-request.model';
import { DashboardService } from '../dashboard.service';
import { debounceTime } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-revenue-bar-chart',
  templateUrl: './revenue-bar-chart.component.html',
  styleUrls: ['./revenue-bar-chart.component.scss']
})
export class RevenueBarChartComponent implements OnInit {

  revenueData: any;
  maxRevenue: any;
  serviceRoles: any;
  isLoading: boolean = false
  @ViewChild('barChart', { static: true }) barChartReference: ElementRef;
  barChart: Chart;
  revenueRequestFromGroup: UntypedFormGroup;
  isDarkViewEnabled: boolean;

  constructor(private dashboardService: DashboardService, private fb: UntypedFormBuilder, private overlayContainer: OverlayContainer) {
    Chart.register(...registerables);
  }

  ngOnInit() {
    this.isDarkViewEnabled = this.overlayContainer.getContainerElement().classList.contains("dark-theme");
    this.revenueRequestFromGroup = this.fb.group({
      'noOfDays': ['90'],
      'startDate': [''],
      'endDate': [''],
      'projectStatus': ['0'],
      'serviceRoleIds': [null]
    });
    this.getSecRevenueData(this.revenueRequestFromGroup.value);
    this.formValueChanges();
  }

  filterRevenueData(event: any, fieldName: string) {
    if (fieldName == "noOfDays") {
      this.revenueRequestFromGroup.patchValue({ startDate: null, endDate: null, serviceRoleIds: null });
      this.getSecRevenueData(this.revenueRequestFromGroup.value);
    }
    else {
      if (fieldName != "serviceRoleIds") {
        this.revenueRequestFromGroup.patchValue({ serviceRoleIds: null });
        //this.revenueData = [];
      }
      let startDate = this.revenueRequestFromGroup.controls['startDate'];
      let endDate = this.revenueRequestFromGroup.controls['endDate'];
      if (startDate.value && endDate.value && startDate.valid && endDate.valid) {
        this.getDateRange(startDate.value, endDate.value, fieldName == 'serviceRoleIds');
      } else {
        this.getSecRevenueData(this.revenueRequestFromGroup.value, fieldName == 'serviceRoleIds');
      }
    }
  }

  formValueChanges() {
    this.revenueRequestFromGroup.controls['startDate'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.checkForValidDateRange();
      });
    this.revenueRequestFromGroup.controls['endDate'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.checkForValidDateRange();
      });
  }

  checkForValidDateRange() {
    let startDate = this.revenueRequestFromGroup.controls['startDate'];
    let endDate = this.revenueRequestFromGroup.controls['endDate'];
    if (startDate.value && endDate.value && startDate.valid && endDate.valid) {
      this.dateRangeChange(startDate.value, endDate.value);
    }
  }

  getDateRange(startDate: Date, endDate: Date, isServiceRoleIdChanged?: boolean) {

    let dashboardRequestModel: DashboardRequestModel = Object.assign({}, this.revenueRequestFromGroup.value);
    dashboardRequestModel.startDate = new Date(startDate).toLocaleDateString('en-US');
    dashboardRequestModel.endDate = new Date(endDate).toLocaleDateString('en-US');
    this.getSecRevenueData(dashboardRequestModel, isServiceRoleIdChanged);

  }

  dateRangeChange(startDate: Date, endDate: Date) {
    this.revenueRequestFromGroup.patchValue({ noOfDays: null, serviceRoleIds: null });
    this.getDateRange(startDate, endDate);
  }

  getSecRevenueData(dashboardRequestModel: DashboardRequestModel, isServiceRoleIdChanged?: boolean) {
    this.isLoading = true;
    this.dashboardService.getServiceRoleRevenueInfo(dashboardRequestModel).subscribe((res: any) => {
      !isServiceRoleIdChanged? this.serviceRoles = res.revenueResponses: null;
      this.revenueData = res.revenueResponses;
      this.maxRevenue = res.maxRevenue;
      this.getPrjectStatusChartData();
      this.isLoading = false;
    });
  }

  getPrjectStatusChartData() {
    if (!this.barChart) {
      this.barChart = new Chart(this.barChartReference.nativeElement, {
        type: 'bar',
        data: {
          labels: this.getRevenueData(),
          datasets: [{
            label: '# of Unadjusted Totals',
            data: this.getRevenueData(true),
            backgroundColor: [
              'rgb(33, 150, 243)'
            ],
            borderColor: [
              'rgb(33 150 243)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          color: this.getFontColor(),
          plugins: {
            tooltip: {
              mode: 'index',
              intersect: false,
              yAlign: 'bottom'
            } },
          scales: {
            y: {
              max: this.maxRevenue,
              type: 'linear',
              ticks: {
                font: { size: 10 },
                color: this.getFontColor()
              },
              grid: {
                color: this.getFontColor(true)
              }
            },
            x: {
              display: 'auto',
              ticks: {
                callback: function (value, index, ticks_array) {
                  let labels: any = this.chart.data.labels;
                  let data = labels[index];
                  if (data.length > 25) {
                    return data.toString().substr(0, 25) + '...'; //truncate
                  } else {
                    return data
                  }

                },
                font: { size: 10 },
                color: this.getFontColor(),
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90
              },
              grid: {
                color: this.getFontColor(true)
              }
            }
          }
        }
      });
    }
    else {
      this.barChart.data.labels = this.getRevenueData();
      this.barChart.data.datasets[0].data = this.getRevenueData(true);
      this.barChart.update();
    }
  }

  getFontColor(isGrid: boolean = false) {
    if (isGrid)
      return this.isDarkViewEnabled ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.1)';//set to the chart default color
    else
      return this.isDarkViewEnabled ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
  }

  getRevenueData(isValue?: boolean) {
    return isValue ? this.revenueData?.map((x: any) => x.revenue) : this.revenueData?.map((x: any) => x.serviceRoleName);
  }

  getProjectStatus() {
    let status = this.revenueRequestFromGroup.controls['projectStatus'].value;
    return status != null && status != '' ? status: null;
  }

  resetFilters() {
    this.revenueRequestFromGroup.reset({ noOfDays: '90', projectStatus: '0' });
    this.getSecRevenueData(this.revenueRequestFromGroup.value);
  }

}
