import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


import { VehicleCreateInsurance } from '../../../models/fleet-management-models';
import { FleetMangementService } from '../../../fleet-mangement.service';

@Component({
  selector: 'app-vehicle-insurance-create',
  templateUrl: './vehicle-insurance-create.component.html',
  styleUrls: ['./vehicle-insurance-create.component.scss']
})
export class VehicleInsuranceCreateComponent implements OnInit {
  insurance: VehicleCreateInsurance;
  insuranceId: string;
  insuranceForm: UntypedFormGroup;
  file: File;

  constructor(private service: FleetMangementService, private location: Location, private route: ActivatedRoute, private fb: UntypedFormBuilder, private router: Router) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        const id = params['id'];
        if (id) {
          this.insuranceId = id;
          this.service.getVehicleInsuranceRecordById(this.insuranceId)
            .subscribe(httpResponse => {
              this.insurance = httpResponse;
              this.createForm(this.insurance);
            });
        } else {
          this.insurance = new VehicleCreateInsurance();
          this.createForm(this.insurance);
        }
      });

  }
  createInsurance() {
    this.service.createVehicleInsuranceRecord(this.insurance)
      .subscribe(res => {
        this.location.back();
      });
  }
  addFileToModel(event: any) {
    this.insurance.insurancePhoto = event.target.files[0];
  }
  uploadFile() {
    if (this.insuranceForm.valid) {
      if (this.insurance.insurancePhoto) {
        this.service.uploadVehicleInsuranceRecordPhoto(this.insurance)
          .subscribe(res => {
            this.location.back();

          });
      } else {
        this.service.createVehicleInsuranceRecord(this.insurance)
          .subscribe(insuranceResult => {
            this.location.back();

          });
      }
    }
  }
  createForm(insuranceCard?: VehicleCreateInsurance) {
    if (insuranceCard) {
      this.insuranceForm = this.fb.group({
        vehicleInsuranceCompanyName: [insuranceCard.vehicleInsuranceCompanyName, Validators.required],
        vehicleInsurancePolicyNumber: [insuranceCard.vehicleInsurancePolicyNumber, Validators.required],
        vehicleInsuranceStartDate: [insuranceCard.vehicleInsuranceStartDate, Validators.required],
        vehicleInsuranceEndDate: [insuranceCard.vehicleInsuranceEndDate, Validators.required]
      });
    } else {
      this.insuranceForm = this.fb.group({
        vehicleInsuranceCompanyName: ['', Validators.required],
        vehicleInsurancePolicyNumber: ['', Validators.required],
        vehicleInsuranceStartDate: ['', Validators.required],
        vehicleInsuranceEndDate: ['', Validators.required]
      });
    }

  }
  getMinDate() {
    let startDate = this.insuranceForm.value['vehicleInsuranceStartDate'];
    return startDate ? startDate : null;
  }
  getMaxDate() {
    let endDate = this.insuranceForm.value['vehicleInsuranceEndDate'];
    return endDate ? endDate : null;
  }
}
