import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';

@Component({
  selector: 'cloud-migration-environment-list',
  templateUrl: './cloud-migration-environment-list.component.html',
  styleUrls: ['./cloud-migration-environment-list.component.scss']
})
export class CloudMigrationEnvironmentListComponent implements OnInit {
  @Input() projectId: string;  
  @Input() environmentList: any;  
  @Output() editEnvironmentEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEnvironmentEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editEnvironmentConfigEvent: EventEmitter<any> = new EventEmitter<any>();
  tableDataSource: any;
  displayedColumns: string[];

  constructor(private cloudMigrationService: CloudMigrationToolService) { }

  ngOnInit(): void {
    this.setData();
  }
  
  ngOnChanges(){
    this.setData();
  }

  setData(){
   this.tableDataSource = this.environmentList?.rows;
   this.displayedColumns = ['name','type','actions'];
  }

  editRow(row: any){
    this.editEnvironmentEvent.emit(row);
  }

  deleteRow(row: any){
    this.deleteEnvironmentEvent.emit(row);
  }
  
  addConfig(row: any){
    this.editEnvironmentConfigEvent.emit(row);
  }
}
