<div *ngIf="documentForm" >
  <h2 mat-dialog-title>Upload a Project Document</h2>
<form [formGroup]="documentForm" (ngSubmit)="saveFile()">
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name">
      <mat-hint [ngStyle]="{color: isDuplicateName ? 'red' : ''}">Document name must be unique, sample naming convention: ClientName_DocType_Date</mat-hint>
      <mat-error *ngIf="documentForm.controls['name'].hasError('required')">
        A Document name is required
      </mat-error>
    </mat-form-field>
    <br />
    <mat-form-field>
      <mat-label>Document Type</mat-label>
      <mat-select formControlName="projectDocumentType" disableOptionCentering>
        <mat-option [value]="0">SOW</mat-option>
        <mat-option [value]="1">BP Quote</mat-option>
        <mat-option [value]="2">BOM</mat-option>
      </mat-select>
      <mat-error *ngIf="documentForm.controls['projectDocumentType'].hasError('required')">
        A Document type is required
      </mat-error>
    </mat-form-field>
    <br />
    <div>
      <div class="upload">
        <input type="file" #fileRef id="fileRef" (change)="updateDocumentFile($event)" placeholder="Select a File"
               value="fileName" (blur)="setErrorStatus()" />

        <label for="fileRef">Browse for file to upload<mat-icon class="icon">attachment</mat-icon></label>
      </div>
      <div class="single-file">
        <div class="info">
          <h4 class="name">
            {{ fileName }}
          </h4>
        </div>
      </div>
      <div class="error" *ngIf="docFileError">A file is required</div>
    </div>
    <input hidden type="file" #imgFileInput (change)="onFileChange($event)" [multiple]="false" />
    <br />
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="submit" mat-raised-button [disabled]="!documentForm.valid">Upload</button>
  </mat-dialog-actions>
</form>
