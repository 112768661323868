import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminHelpVideosComponent } from './admin-help-videos.component';
import { AdminHelpVideosListComponent } from './admin-help-videos-list/admin-help-videos-list.component';
import { AdminHelpVideosCreateComponent } from './admin-help-videos-create/admin-help-videos-create.component';


const routes: Routes = [{
  path: 'adminHelpVideos', component: AdminHelpVideosComponent, data: {
    breadcrumb: 'Admin Help Videos', isRoutable: true
  }, children: [
    {
      path: '', component: AdminHelpVideosListComponent, data: {
        breadcrumb: null, isRoutable: false
      }
    }, {
      path: 'create', component: AdminHelpVideosCreateComponent, data: {
        breadcrumb: 'Create Admin Help Video', isRoutable: true
      }
    }, {
      path: 'details/:videoId', component: AdminHelpVideosCreateComponent, data: {
        breadcrumb: 'Edit Admin Help Video', isRoutable: true
      }
    }]

}];;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminHelpVideosRoutingModule { }
