import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

import { FleetMangementService } from '../../../fleet-mangement.service';

import { VehicleModel } from '../../../models/fleet-management-models';

@Component({
  selector: 'app-vehicle-models-create',
  templateUrl: './vehicle-models-create.component.html',
  styleUrls: ['./vehicle-models-create.component.scss']
})
export class VehicleModelsCreateComponent implements OnInit {
  model: VehicleModel;
  modelForm: UntypedFormGroup;
  manufacturerNameControl: UntypedFormControl;
  vehicleModelNameControl: UntypedFormControl;
  vehcileModelYearControl: UntypedFormControl;
  constructor(private service: FleetMangementService, private router: Router, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    if (this.model) {

    } else {
      this.model = new VehicleModel();
      this.manufacturerNameControl = new UntypedFormControl('', Validators.required);
      this.vehicleModelNameControl = new UntypedFormControl('', Validators.required);
      this.vehcileModelYearControl = new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(4), Validators.minLength(4)]);
      this.modelForm = this.fb.group({
        manufacturerName: this.manufacturerNameControl,
        vehicleModelName: this.vehicleModelNameControl,
        vehcileModelYear: this.vehcileModelYearControl
      });
    }
  }
  onCreate() {
    if (this.modelForm.valid) {
      this.service.createVehicleModels(this.model)
        .subscribe(res => {
          this.router.navigate(['fleetmanagement', 'models']);
        });
    }
  }

}
