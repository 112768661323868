import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../clients.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-client-upload',
  templateUrl: './client-upload.component.html',
  styleUrls: ['./client-upload.component.scss']
})
export class ClientUploadComponent implements OnInit {
  uploadError: boolean;
  fileName: string;
  model: ClientsUploadModel;
  constructor(private clientsService: ClientsService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.model = new ClientsUploadModel();
  }
  updateReciptFile(event: any) {
    this.model.file = event.target.files[0];
    this.fileName = this.model.file.name;
  }
  saveFile() {
    const file = this.model.file.files[0];

    this.clientsService.uploadClients(file)
      .subscribe(res => {
        this.model.file = null;
        this.showSnackBar('File uploaded successfully.', 'close');

      },
        (error) => {
          this.showSnackBar('File was not uploaded successfully.', 'close');
          this.uploadError = true;
        });
  }
  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
export class ClientsUploadModel {
  file: any;
}
