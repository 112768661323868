<div>
  <mat-form-field>
    <input matInput [matDatepicker]="picker" [max]="getMaxDate()"  [(ngModel)]="startDate" placeholder="Start Date">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input matInput [matDatepicker]="picker2" [min]="getMinDate()"   [(ngModel)]="endDate" placeholder="End Date">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <button mat-raised-button type="button" (click)="getReport()">Run Report</button>

  <a #donwloadCsvLink></a>
  <div *ngIf="noRecordsError">
    <h3>There were no records in that query change parameters and try again.</h3>
  </div>
</div>
