<p class="red"><i>All incident reports will remain anonymous</i></p>
<form #form="ngForm" [formGroup]="formGroup" (ngSubmit)="submitForm()" >
  <mat-form-field class="larger">
    <input matInput [matDatepicker]="dateOfIncidentPicker" placeholder="Date of incident" formControlName="dateOfIncident" />
    <mat-datepicker-toggle matSuffix [for]="dateOfIncidentPicker"></mat-datepicker-toggle>
    <mat-datepicker #dateOfIncidentPicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="larger">
    <input matInput type="time" step="60" placeholder="Time of incident"  formControlName="timeOfIncident"  />
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" placeholder="Location of incident"  formControlName="locationOfIncident"  />
  </mat-form-field>
  <br />
  <mat-form-field>
    <textarea matInput type="text" placeholder="Detailed description of incident"  formControlName="descriptionOfIncident" ></textarea>
  </mat-form-field>
  <br />
  <button mat-raised-button color="primary" type="submit">Submit</button>
</form>
