<mat-card>
  <mat-card-content [class.hide-content]="!tags">
    <!-- <a mat-raised-button [routerLink]="['create']">Create</a> -->

    <a id="createTag" mat-raised-button [routerLink]="['create']" color="primary" matTooltip="Create Tag"
       [matTooltipPosition]="'right'">
      <mat-icon class="add-icon">add</mat-icon>
      Tag
    </a>
    <br />
    <div class="col-md-12">
      <fieldset>
        <legend>
          Search Selection
        </legend>
        <form [formGroup]="filterForm">
          <mat-form-field>
            <input matInput type="text" placeholder="Filter by Name" id="tagName" formControlName="tagName" />
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="createdBy" id="createdBy" disableOptionCentering #employee placeholder="Filter By Created By">
              <mat-option [value]="''">
                None
              </mat-option>
              <mat-option *ngFor="let emp of employees" [value]="emp.employeeId">
                {{emp.firstName + " " + emp.lastName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-icon-button type="button" (click)="resetFilters()" title="Clear Filters" id="resetBtn">
            <mat-icon>clear</mat-icon>
          </button>
        </form>
      </fieldset>
    </div>
    <br />
    <div class="col-md-12">
      <mat-table #table [dataSource]="tagsDataSource" matSort class="apply-border">
        <ng-container matColumnDef="name">
          <mat-header-cell class="sortable" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Created By</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{this.employees | employeeFilter: element.createdByEmployeeId}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.createdDate | date}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button id="kebabMenu" [attr.data-id]="element.id" mat-icon-button [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item color="default" type="button" [routerLink]="['edit',element.id]">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      </div>
      <mat-paginator #paginator [length]="tagsDataSource?.data.length" [pageIndex]="0" [pageSize]="50"
                     [pageSizeOptions]="[5, 25, 50, 100]">
      </mat-paginator>
  </mat-card-content>
</mat-card>
