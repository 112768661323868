<div class="mat-elevation-z8">
  <mat-card>
    <mat-card-title>Clients</mat-card-title>
    <mat-card-content>
      <div [class.loading]="!loading" style="position: relative; margin:auto;width:calc(50% + 225px)">
        <mat-spinner mode="indeterminate" width="450" height="450" style="margin: 0 auto;"></mat-spinner>
      </div>
      <form [formGroup]="filterForm" [class.loading]="loading">
        <mat-form-field>
          <mat-label> Client Type</mat-label>
          <mat-select formControlName="clientType" disableOptionCentering>
            <mat-option [value]="0"> Suspect </mat-option>
            <mat-option [value]="1"> Prospect </mat-option>
            <mat-option [value]="2"> Customer </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Epicor Id Filter</mat-label>
          <input id= "epicorIdFilter" matInput formControlName="customerId" type="text">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Common Name Filter</mat-label>
          <input id= "filterField" matInput formControlName="clientsCommonName" type="text">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Legal Name Filter</mat-label>
          <input matInput formControlName="clientLegalName" type="text">
        </mat-form-field>
      </form>
    </mat-card-content>
    <!-- <button [disabled]="!paginator.hasPreviousPage()" mat-icon-button (click)="paginator.previousPage()">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button [disabled]="!paginator.hasNextPage()" mat-icon-button (click)="paginator.nextPage()">
      <mat-icon>navigate_next</mat-icon>
    </button> -->
  </mat-card>


  <mat-table mat-table matSort class="full-width-table" aria-label="Client Table" [class.loading]="loading">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Epicor Id</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.externalCustomerId}}</mat-cell>
      <mat-footer-cell *cdkFooterCellDef> Epicor Id </mat-footer-cell>
    </ng-container>

    <!--Common Name Column -->
    <ng-container matColumnDef="commonName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Common Name</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.commonName}}</mat-cell>
      <mat-footer-cell *cdkFooterCellDef> Epicor Id </mat-footer-cell>
    </ng-container>
    <!-- Legal Name Column -->
    <ng-container matColumnDef="legalName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Legal Name</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.legalName}}</mat-cell>
      <mat-footer-cell *cdkFooterCellDef> Epicor Id </mat-footer-cell>
    </ng-container>
    <!-- Legal Name Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <a mat-icon-button color="primary" matTooltip="Edit Client" aria-label="Navigation button to edit the client"
          [matTooltipPosition]="'above'" [routerLink]="[row.id,'edit']">
          <mat-icon>create</mat-icon>
        </a></mat-cell>
      <mat-footer-cell *cdkFooterCellDef> Epicor Id </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectClient(row)"></mat-row>
  </mat-table>
  <div [class.loading]="loading">
    <span *ngIf="!(dataSource && dataSource.data.length > 0)" class="no-records">
      No records found
    </span>
  </div>
  <mat-paginator [class.loading]="loading" #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="50"
    [pageSizeOptions]="[5, 25, 50, 100]">
  </mat-paginator>
</div>
