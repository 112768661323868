import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, NgForm, UntypedFormControl, AbstractControl } from '@angular/forms';
import { ServiceEstimateDetail, ProjectPhase } from '../../models/service-estimate';
import { ServiceRole } from '../../../admin/models/service-role';
import { ServiceRoleService } from '../../../admin/service-role/service-role.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BusinessPartnerSearchPopupComponent } from '../../../business-partners/business-partner-search-popup/business-partner-search-popup.component';
import { SolutionPilar } from '../../../business-partners/models/solution-pilar';
import { BusinessPartnerService } from '../../../business-partners/business-partner.service';
import { ActivatedRoute } from '@angular/router';
import { ProfessionalServicesService } from '../../professional-services.service';
import { Location } from '@angular/common';
import { MaterialItemService } from '../../material-item.service';
import { ServiceEstimatorService } from '../../_services/service-estimator.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ApplicationConfigurationService } from 'src/app/admin/margin-adjustments/application-configuration.service';
import { stepValidator } from '../../service-estimates/parent-estimate-create/parent-estimate-create.component';
import { MarginOptionType } from 'src/app/admin/margin-adjustments/models/marginOption';

@Component({
  selector: 'app-templates-create',
  templateUrl: './templates-create.component.html',
  styleUrls: ['./templates-create.component.scss']
})
export class TemplatesCreateComponent implements OnInit {

  serviceEstimateForm: UntypedFormGroup;
  serviceEstimateItems: Array<any>;
  serviceRoles: Array<ServiceRole>;
  serviceEstimateDetailsPlanning: UntypedFormArray;
  serviceEstimateDetailsExecute: UntypedFormArray;
  serviceEstimateDetailsClosing: UntypedFormArray;
  businessPartnerItems: UntypedFormArray;
  materialItems: UntypedFormArray;
  pricingNotes: UntypedFormArray;
  travelExpenses: UntypedFormArray;
  skuItems: UntypedFormArray;

  planningPhase = ProjectPhase.Plan;
  solutionPilars: Array<SolutionPilar>;
  skuSolPilars: Array<SolutionPilar>;
  templateId: string;
  travelExpenseGroup: UntypedFormGroup;
  isValid = true;
  @ViewChild('form')
  form: NgForm;
  isUniqueName: boolean;
  marginOption: any;
  isUpliftDefaulted: boolean;
  travelExpenseTypes: Array<string>;
  materialItemsExpenseTypes: Array<string>;
  selectableBusinessPartners: Array<any>;
  selectedServiceRole: any;

  constructor(private fb: UntypedFormBuilder,
    private serviceRolesService: ServiceRoleService,
    private businessPartnerService: BusinessPartnerService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private psService: ProfessionalServicesService,
    private serviceEstimatiorService: ServiceEstimatorService,
    private materialService: MaterialItemService,
    private location: Location,
    private applicationConfigurationService: ApplicationConfigurationService) { }

  ngOnInit() {
    this.isUniqueName = true;
    this.travelExpenseTypes = ['Meridian', 'Business Partner'];
    this.materialItemsExpenseTypes = ['Meridian', 'Business Partner'];
    this.route.params
      .subscribe(param => {
        this.templateId = param['templateId'];
        this.serviceEstimateDetailsPlanning = this.fb.array([]);
        this.serviceEstimateDetailsClosing = this.fb.array([]);
        this.serviceEstimateDetailsExecute = this.fb.array([]);
        this.businessPartnerItems = this.fb.array([]);
        this.materialItems = this.fb.array([]);
        this.pricingNotes = this.fb.array([]);
        this.travelExpenses = this.fb.array([]);
        this.skuItems = this.fb.array([]);
        this.getMarginOptions();

        this.serviceRolesService.getServiceRoles()
          .subscribe(res => {
            this.serviceRoles = res;

            this.businessPartnerService.getSolutionPilars()
              .subscribe(pilarResult => {
                this.solutionPilars = pilarResult;
                this.skuSolPilars = pilarResult.filter(x => x.solutionPilarName.toLowerCase() == 'avaya business line' || x.solutionPilarName.toLowerCase() == 'resold services');
             },
                (error) => {
                  this.solutionPilars = [];
                  this.skuSolPilars = [];
                });
            if (this.templateId) {
              this.psService.getTemplate(this.templateId)
                .subscribe(secResult => {
                  const data = secResult;
                  if (data.phaseItems) {
                    data.phaseItems.forEach((item: any) => {
                      if (item.phase == 0) {
                        this.addPlanningItem(item);

                      } else if (item.phase == 1) {
                        this.addExecuteItem(item);

                      } else if (item.phase == 2) {
                        this.addClosingItem(item);
                      }
                    });
                  }
                  if (data.materialItems) {
                    data.materialItems.forEach((mi: any) => {
                      this.addMeterialItem(mi);
                    });
                  }
                  if (data.businessPartnerItems) {
                    data.businessPartnerItems.forEach((bi: any) => {
                      this.addBusinessItem(bi);
                    });
                  }
                  if (data.pricingNotes) {
                    data.pricingNotes.forEach((pricingNote: any) => {
                      this.addPricingNote(pricingNote);
                    });
                  }
                  data.travelExpenses.forEach((te: any) => {
                    this.addTravelExpense(te);
                  });
                  data.skuItems.forEach((si: any) => {
                    this.addSkuItem(si);
                  });
                  const selectedServiceRole = this.serviceRoles.find(sr => sr.serviceRoleId == data.serviceRoleId);
                  this.selectedServiceRole = selectedServiceRole;
                  this.serviceEstimateForm = this.fb.group({
                    id: data.id,
                    serviceRoleId: [data.serviceRoleId, Validators.required],
                    componentOverrideRate: [data.componentOverrideRate],
                    phaseItems: [],
                    createdByEmployeeId: [data.createdByEmployeeId],
                    componentName: [data.componentName, Validators.required],
                    totalAmount: data.totalAmount,
                    businessPartnerTotal: data.businessPartnerTotal,
                    notes: [data.notes],
                    priceAdjustment: data.priceAdjustment,
                    serviceEstimateDetailsPlanning: this.serviceEstimateDetailsPlanning,
                    serviceEstimateDetailsClosing: this.serviceEstimateDetailsClosing,
                    serviceEstimateDetailsExecute: this.serviceEstimateDetailsExecute,
                    businessPartnerItems: this.businessPartnerItems,
                    materialItems: this.materialItems,
                    travelExpenses: this.travelExpenses,
                    pricingNotes: this.pricingNotes,
                    skuItems: this.skuItems,
                    grossMarginPercentage: [data.grossMarginPercentage],
                    upliftPercentage: [data.upliftPercentage]
                  });
                  this.selectableBusinessPartners = this.serviceEstimateForm.controls['businessPartnerItems'].value;
                  this.onBusinessPartnersChange();
                  this.onGrossMarginPercentageChange();
                  this.onTotalUpliftPercentageChange();
                  this.setGrossMarginValidations();
                  this.setUpliftValidations();
                  this.onOverrideRateChange();
                  this.serviceEstimateForm.valueChanges
                    .subscribe(formVa => {
                      this.isValid = this.serviceEstimateForm.valid;
                    });
                  this.serviceEstimateForm.controls['priceAdjustment'].valueChanges.subscribe(priceAdjustmentValue => {
                    const control = this.serviceEstimateForm.controls['notes'];
                    if (priceAdjustmentValue) {
                      control.setValidators(Validators.required);
                      control.updateValueAndValidity();
                    } else {
                      control.clearValidators();
                      control.updateValueAndValidity();
                    }
                  });
                  this.serviceRoleUpdateChange();
                },
                  (error) => {
                    this.serviceEstimateForm = this.fb.group({
                      componentName: ['', Validators.required],
                      serviceRoleId: [null, Validators.required],
                      componentOverrideRate: [null],
                      totalAmount: null,
                      createdByEmployeeId: [],
                      notes: [''],
                      priceAdjustment: null,
                      phaseItems: [],
                      serviceEstimateDetailsPlanning: this.serviceEstimateDetailsPlanning,
                      serviceEstimateDetailsClosing: this.serviceEstimateDetailsClosing,
                      serviceEstimateDetailsExecute: this.serviceEstimateDetailsExecute,
                      businessPartnerItems: this.businessPartnerItems,
                      materialItems: this.materialItems,
                      travelExpenses: this.travelExpenses,
                      businessPartnerTotal: [],
                      pricingNotes: this.pricingNotes,
                      skuItems: this.skuItems,
                      grossMarginPercentage: [null],
                      upliftPercentage: [null]
                    });
                    this.selectableBusinessPartners = this.serviceEstimateForm.controls['businessPartnerItems'].value;
                    this.onBusinessPartnersChange();
                    this.onGrossMarginPercentageChange();
                    this.onTotalUpliftPercentageChange();
                    this.onOverrideRateChange();
                    this.serviceEstimateForm.valueChanges
                      .subscribe(formVa => {
                        this.isValid = this.serviceEstimateForm.valid;
                      });
                    this.serviceEstimateForm.controls['priceAdjustment'].valueChanges.subscribe(priceAdjustmentValue => {
                      const control = this.serviceEstimateForm.controls['notes'];
                      if (priceAdjustmentValue) {
                        control.setValidators(Validators.required);
                        control.updateValueAndValidity();
                      } else {
                        control.clearValidators();
                        control.updateValueAndValidity();
                      }
                    });
                    this.serviceRoleUpdateChange();
                  });
            } else {
              this.serviceEstimateForm = this.fb.group({
                componentName: ['', Validators.required],
                serviceRoleId: [null, Validators.required],
                totalAmount: null,
                notes: [''],
                componentOverrideRate: [null],
                createdByEmployeeId: [],
                priceAdjustment: null,
                serviceEstimateDetailsPlanning: this.serviceEstimateDetailsPlanning,
                serviceEstimateDetailsClosing: this.serviceEstimateDetailsClosing,
                serviceEstimateDetailsExecute: this.serviceEstimateDetailsExecute,
                businessPartnerItems: this.businessPartnerItems,
                materialItems: this.materialItems,
                travelExpenses: this.travelExpenses,
                businessPartnerTotal: [],
                pricingNotes: this.pricingNotes,
                skuItems: this.skuItems,
                grossMarginPercentage: [null],
                upliftPercentage: [null]
              });
              this.selectableBusinessPartners = this.serviceEstimateForm.controls['businessPartnerItems'].value;
              this.onBusinessPartnersChange();
              this.serviceRolesChange();
              this.serviceRoleUpdateChange();
              this.onGrossMarginPercentageChange();
              this.onTotalUpliftPercentageChange();
              this.onOverrideRateChange();
              this.serviceEstimateForm.controls['priceAdjustment'].valueChanges.subscribe(priceAdjustmentValue => {
                const control = this.serviceEstimateForm.controls['notes'];
                if (priceAdjustmentValue) {
                  control.setValidators(Validators.required);
                  control.updateValueAndValidity();
                } else {
                  control.clearValidators();
                  control.updateValueAndValidity();
                }
              });
            }
          });
      });

  }

  onTravelExpenseTypeChanged(index: number) {
    const expenseControl = this.travelExpenses.at(index) as UntypedFormArray;
    const travelExpenseTypeControl = expenseControl.get('travelExpenseType');
    const travelExpenseBusinessPartnerIdControl = expenseControl.get(
      'travelExpenseBusinessPartnerId'
    );
    travelExpenseTypeControl?.valueChanges.subscribe((value) => {
      if (value === 'Meridian') {
        travelExpenseBusinessPartnerIdControl?.setValidators(null);
        travelExpenseBusinessPartnerIdControl?.setValue(null); // Clear the value if it's not needed
      } else {
        travelExpenseBusinessPartnerIdControl?.setValidators([
          Validators.required,
        ]);
      }
      travelExpenseBusinessPartnerIdControl?.updateValueAndValidity();
    });
    const currentValue = travelExpenseTypeControl?.value;
    if (currentValue === 'Meridian') {
      travelExpenseBusinessPartnerIdControl?.setValidators(null);
      travelExpenseBusinessPartnerIdControl?.setValue(null);
    } else {
      travelExpenseBusinessPartnerIdControl?.setValidators([
        Validators.required,
      ]);
    }
    travelExpenseBusinessPartnerIdControl?.updateValueAndValidity();
  }

  onBusinessPartnersChange() {
    this.serviceEstimateForm.controls['businessPartnerItems'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((businessPartnerItems) => {
        this.selectableBusinessPartners = businessPartnerItems;
        this.serviceEstimateForm.updateValueAndValidity();
        this.clearInvalidBusinessPartners();
      });
  }

  onOverrideRateChange() {
    this.serviceEstimateForm.controls['componentOverrideRate'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((componentOverrideRate) => {
        const rateToUse = componentOverrideRate || this.selectedServiceRole?.serviceRoleHourlyRate;
  
        // Update the overrideRate for planning phase items
        const planningItemsArray = this.serviceEstimateDetailsPlanning as UntypedFormArray;
        planningItemsArray.controls.forEach((control: any) => {
          control.get('overrideRate')?.setValue(rateToUse);
        });
  
        // Update the overrideRate for closing phase items
        const closingItemsArray = this.serviceEstimateDetailsClosing as UntypedFormArray;
        closingItemsArray.controls.forEach((control: any) => {
          control.get('overrideRate')?.setValue(rateToUse);
        });
  
        // Update the overrideRate for execute phase items
        const executeItemsArray = this.serviceEstimateDetailsExecute as UntypedFormArray;
        executeItemsArray.controls.forEach((control: any) => {
          control.get('overrideRate')?.setValue(rateToUse);
        });
  
        // Update form validity
        this.serviceEstimateForm.updateValueAndValidity();
      });
  }

  clearInvalidBusinessPartners() {
    const validBusinessPartnerIds = this.selectableBusinessPartners.map(
      (bp) => bp.businessPartnerId
    );
    this.travelExpenses.controls.forEach((expenseControl) => {
      const travelExpenseBusinessPartnerId = expenseControl.get(
        'travelExpenseBusinessPartnerId'
      )?.value;
      if (!validBusinessPartnerIds.includes(travelExpenseBusinessPartnerId)) {
        expenseControl.get('travelExpenseBusinessPartnerId')?.setValue(null);
      }
    });
    this.materialItems.controls.forEach((materialExpenseControl) => {
      const materialItemBusinessPartnerId = materialExpenseControl.get(
        'materialItemBusinessPartnerId'
      )?.value;
      if (!validBusinessPartnerIds.includes(materialItemBusinessPartnerId)) {
        materialExpenseControl.get('materialItemBusinessPartnerId')?.setValue(null);
      }
    });
  }

  serviceRoleUpdateChange() {
    this.serviceEstimateForm.controls['serviceRoleId'].valueChanges
      .subscribe(serviceRoleId => {
        if (serviceRoleId) {
          const selectedServiceRole = this.serviceRoles.find(sr => sr.serviceRoleId == serviceRoleId);
          this.selectedServiceRole = selectedServiceRole;
          this.updateServiceRoleFormArray(this.serviceEstimateDetailsClosing, selectedServiceRole);
          this.updateServiceRoleFormArray(this.serviceEstimateDetailsPlanning, selectedServiceRole);
          this.updateServiceRoleFormArray(this.serviceEstimateDetailsExecute, selectedServiceRole);
        }
      });
  }

  onGrossMarginPercentageChange() {
    this.serviceEstimateForm.controls['grossMarginPercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(grossMarginValue => {
        this.calcBPControls('grossMarginPercentage');
      });
  }

  onTotalUpliftPercentageChange() {
    this.serviceEstimateForm.controls['upliftPercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(upliftValue => {
        this.calcBPControls('totalUpliftPercentage');
      });
  }

  isNotValidBpGrossMarginPerct(index: number) {
    let bpValue = this.businessPartnerItems.controls[index].value;
    return bpValue.bpGrossMarginPercentage?.toFixed(2) < (this.setBPGrossMargin()??0)? true : false;
  }

  isNotValidTotalGrossMarginPerct() {
    let totalGrossMarginValue = this.serviceEstimateForm.controls['grossMarginPercentage'].value;
    return totalGrossMarginValue?.toFixed(2) < (this.setBPGrossMargin()??0)? true : false;
  }

  isNotValidUpliftPerct(index: number) {
    let bpValue = this.businessPartnerItems.controls[index].value;
    return bpValue.upliftPercentage?.toFixed(2) < (this.setBPUpliftMargin()??0)? true : false;
  }

  isNotValidTotalUpliftPerct() {
    let totalUpliftValue = this.serviceEstimateForm.controls['upliftPercentage'].value;
    return totalUpliftValue?.toFixed(2) < (this.setBPUpliftMargin()??0)? true : false;
  }

  setGrossMarginValidations(isManualyAdded?: boolean) {
    const grossMarginPercentageControl = this.serviceEstimateForm?.controls['grossMarginPercentage'];
    if (grossMarginPercentageControl) {
      if (this.businessPartnerItems.length > 0) {
        grossMarginPercentageControl.setValidators([Validators.required, Validators.max(99.99), Validators.min(0)]);//Validators.pattern('\\-?\\d*\\.?\\d{1,4}')
        !grossMarginPercentageControl.value && grossMarginPercentageControl.value != 0 ? grossMarginPercentageControl.setValue(this.templateId ? (isManualyAdded ? (grossMarginPercentageControl.value ? grossMarginPercentageControl.value : this.setBPGrossMargin()) : null) : this.setBPGrossMargin(), { emitEvent: false }) : '';
      }
      else {
        grossMarginPercentageControl.clearValidators();
        grossMarginPercentageControl.setValue(null, { emitEvent: false });
      }
      grossMarginPercentageControl.updateValueAndValidity({ emitEvent: false });
    }
  }

  setUpliftValidations(isManualyAdded?: boolean) {
    const totalUpliftPercentageControl = this.serviceEstimateForm?.controls['upliftPercentage'];
    if (totalUpliftPercentageControl) {
      if (this.businessPartnerItems.length > 0) {
        totalUpliftPercentageControl.setValidators([Validators.required, Validators.min(0)]);
        !totalUpliftPercentageControl.value && totalUpliftPercentageControl.value != 0 ? totalUpliftPercentageControl.setValue(this.templateId ? (isManualyAdded ? (totalUpliftPercentageControl.value ? totalUpliftPercentageControl.value : this.setBPUpliftMargin()) : null) : this.setBPUpliftMargin(), { emitEvent: false }) : '';
      }
      else {
        totalUpliftPercentageControl.clearValidators();
        totalUpliftPercentageControl.setValue(null, { emitEvent: false });
      }
      totalUpliftPercentageControl.updateValueAndValidity({ emitEvent: false });
    }
  }

  updateServiceRoleFormArray(array: UntypedFormArray, selectedServiceRole?: ServiceRole) {
    const formIndex = array.length;
    if (formIndex > 0) {
      for (let index = 0; index < formIndex; index++) {
        array.at(index).get('serviceRoleId')?.setValue(selectedServiceRole?.serviceRoleId);
        array.at(index).get('billedRate')?.setValue(selectedServiceRole?.serviceRoleHourlyRate);
        array.at(index).get('serviceRatePerQuanity')?.setValue(selectedServiceRole?.serviceRoleHourlyRate);
        array.at(index).get('overrideRate')?.setValue(selectedServiceRole?.serviceRoleHourlyRate);
      }
    }
  }
  serviceRolesChange() {
    this.serviceEstimateForm.controls['serviceRoleId'].valueChanges
      .subscribe(value => {
        if (this.serviceEstimateDetailsPlanning.length == 0) {
          const item = { 'activity': 'Status Meetings' };
          this.addPlanningItem(item);
          this.updatePhaseIndex(ProjectPhase.Plan);
        }
      });
  }
  addBusinessItem(serviceDetailItem?: any) {
    let isManualyAdded: boolean = !serviceDetailItem;
    if (!serviceDetailItem) {
      serviceDetailItem = {};
      serviceDetailItem.phase = 3;
    }
    const groupItem = this.buildBPGroup(serviceDetailItem);
    if (!this.businessPartnerItems) {
      this.businessPartnerItems = this.fb.array([groupItem]);
      this.setGrossMarginValidations(isManualyAdded);
      this.setUpliftValidations(isManualyAdded);
    } else {
      this.businessPartnerItems.push(groupItem);
      this.setGrossMarginValidations(isManualyAdded);
      this.setUpliftValidations(isManualyAdded);
    }
  }
  addExecuteItem(serviceDetailItem?: any, isDragRecord?: boolean) {
    if (!serviceDetailItem) {
      serviceDetailItem = new ServiceEstimateDetail();
    }
    serviceDetailItem.phase = ProjectPhase.Execute;
    if (!this.templateId) {
      serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
    } else {
      if (this.serviceEstimateForm) {
        if (this.serviceEstimateForm.controls['serviceRoleId'].value) {
          serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
        } else {
          serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
        }
      } else {
        serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
      }
    }
    const groupItem = this.buildGroup(serviceDetailItem, isDragRecord);
    if (!this.serviceEstimateDetailsExecute) {
      this.serviceEstimateDetailsExecute = this.fb.array([]);
    } else {
    }
    this.serviceEstimateDetailsExecute.push(groupItem);
  }
  addClosingItem(serviceDetailItem?: any, isDragRecord?: boolean) {
    if (!serviceDetailItem) {
      serviceDetailItem = new ServiceEstimateDetail();
    }
    serviceDetailItem.phase = ProjectPhase.Close;
    if (!this.templateId) {
      serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
    } else {
      if (this.serviceEstimateForm) {
        if (this.serviceEstimateForm.controls['serviceRoleId'].value) {
          serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
        } else {
          serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
        }
      } else {
        serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
      }
    }
    const groupItem = this.buildGroup(serviceDetailItem, isDragRecord);
    serviceDetailItem.phase = ProjectPhase.Close;
    if (!this.serviceEstimateDetailsClosing) {
      this.serviceEstimateDetailsClosing = this.fb.array([]);
    }

    this.serviceEstimateDetailsClosing.push(groupItem);
  }
  addPlanningItem(serviceDetailItem?: any, isDragRecord?: boolean) {
    if (!serviceDetailItem) {
      serviceDetailItem = new ServiceEstimateDetail();
    }
    serviceDetailItem.phase = ProjectPhase.Plan;
    if (!this.templateId) {
      serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
    } else {
      if (this.serviceEstimateForm) {
        if (this.serviceEstimateForm.controls['serviceRoleId'].value) {
          serviceDetailItem.serviceRoleId = this.serviceEstimateForm.controls['serviceRoleId'].value;
        } else {
          serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
        }
      } else {
        serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
      }
    }
    const groupItem = this.buildGroup(serviceDetailItem, isDragRecord);
    if (!this.serviceEstimateDetailsPlanning) {
      this.serviceEstimateDetailsPlanning = this.fb.array([groupItem]);
    } else {
      this.serviceEstimateDetailsPlanning.push(groupItem);
    }
  }
  addPricingNote(mi?: any) {
    let item: UntypedFormGroup;
    if (mi) {
      item = this.fb.group({
        'id': [mi.id],
        'note': [mi.note]
      });
    } else {
      item = this.fb.group({
        'note': []
      });
    }
    this.pricingNotes.push(item);

  }
  removePricingNote(index: number) {
    const id = this.pricingNotes.at(index).value['id'];
    if (id) {
      this.psService.deletePricingNoteById(id)
        .subscribe(res => {

          this.pricingNotes.removeAt(index);
        });
    } else {
      this.pricingNotes.removeAt(index);
    }
  }
  addMeterialItem(mi?: any) {
    let item: UntypedFormGroup;
    if (mi) {
      item = this.fb.group({
        'id': [mi.id],
        'name': [mi.name],
        'cost': [mi.cost],
        'sell': [mi.sell],
        materialItemType: [mi.materialItemType, Validators.required],
        materialItemBusinessPartnerId: [
          mi.materialItemBusinessPartnerId,
          mi.materialItemType == 'Meridian' ? null : Validators.required,
        ],
      });
    } else {
      item = this.fb.group({
        'name': [],
        'cost': [],
        'sell': [],
        materialItemType: [null, Validators.required],
        materialItemBusinessPartnerId: [null],
      });
    }
    item.controls['materialItemType'].valueChanges.subscribe((s) => {
      if (s == 'Business Partner') {
        item.controls['materialItemBusinessPartnerId'].setValidators(
          Validators.required
        );
        item.controls['materialItemBusinessPartnerId'].updateValueAndValidity();
      } else {
        item.controls['materialItemBusinessPartnerId'].clearValidators();
        item.controls['materialItemBusinessPartnerId'].updateValueAndValidity();
      }
    });
    this.materialItems.push(item);

  }
  removeMaterialItem(index: number) {
    const id = this.materialItems.at(index).value['id'];
    if (id) {
      this.psService.deleteMaterialItemById(id)
        .subscribe(res => {
          this.materialItems.removeAt(index);
        });
    } else {

      this.materialItems.removeAt(index);
    }
  }
  addTravelExpense(travelExpense?: any) {
    if (!travelExpense) {
      travelExpense = {};
    }
    const travelExpenseItem = this.buildTEGroup(travelExpense);
    if (!this.travelExpenses) {
      this.travelExpenses = this.fb.array([travelExpenseItem]);
    } else {
      this.travelExpenses.push(travelExpenseItem);
    }
  }
  removeTEControl(control: any, index: any) {
    const id = this.travelExpenses.controls[index].value['id'];
    if (id) {
      this.serviceEstimatiorService.deleteTravelExpenseItem(id)
        .subscribe(res => {
          this.travelExpenses.removeAt(index);

        });
    } else {
      this.travelExpenses.removeAt(index);
    }
  }
  addSkuItem(sku?: any) {
    if (!sku) {
      sku = {};
    }
    const skuItem = this.buildSKUGroup(sku);
    if (!this.skuItems) {
      this.skuItems = this.fb.array([skuItem]);
    } else {
      this.skuItems.push(skuItem);
    }
  }
  buildBPGroup(serviceEstimateDetail?: any) {
    let group: UntypedFormGroup;
    if (serviceEstimateDetail.id) {
      group = this.fb.group({
        id: serviceEstimateDetail.id ? serviceEstimateDetail.id : null,
        'businessPartnerId': serviceEstimateDetail.businessPartnerId ? serviceEstimateDetail.businessPartnerId : '',
        resourceType: 1,
        'activity': [serviceEstimateDetail.activity ? serviceEstimateDetail.activity : '', Validators.required],
        bpName: [serviceEstimateDetail.businessPartner ? serviceEstimateDetail.businessPartner.businessPartnerLegalName : '', Validators.required],
        estimatiedPrice: [serviceEstimateDetail.estimatiedPrice ? serviceEstimateDetail.estimatiedPrice : 0, Validators.required],
        rate: serviceEstimateDetail.actualRate ? serviceEstimateDetail.actualRate : 0,
        serviceRatePerQuanity: serviceEstimateDetail.actualRate ? serviceEstimateDetail.actualRate : 0,
        overrideRate: serviceEstimateDetail.actualRate ? serviceEstimateDetail.actualRate : 0,
        expenseEstimate: serviceEstimateDetail.estimatiedPrice ? serviceEstimateDetail.estimatiedPrice : 0,
        multiplier: 1,
        'isFixedCost': serviceEstimateDetail.isFixedCost ? serviceEstimateDetail.isFixedCost : 0,
        'cost': [serviceEstimateDetail.cost ? serviceEstimateDetail.cost : 0, Validators.required],
        'sell': [serviceEstimateDetail.sell ? serviceEstimateDetail.sell : 0],
        'upliftPercentage': [serviceEstimateDetail.upliftPercentage ? serviceEstimateDetail.upliftPercentage : serviceEstimateDetail.cost && serviceEstimateDetail.sell ? (((serviceEstimateDetail.sell - serviceEstimateDetail.cost) / serviceEstimateDetail.cost) * 100) : null ],
        estimatedHours: [serviceEstimateDetail.isFixedCost ? { value: null, disabled: true } : (serviceEstimateDetail.estimatedHours ? serviceEstimateDetail.estimatedHours : 0), Validators.required],
        solutionPilarId: [serviceEstimateDetail.solutionPilarId ? serviceEstimateDetail.solutionPilarId : 0, Validators.required],
        bpGrossMarginPercentage: [serviceEstimateDetail.bpGrossMarginPercentage ? serviceEstimateDetail.bpGrossMarginPercentage : (serviceEstimateDetail.cost && serviceEstimateDetail.sell ? (1 - (serviceEstimateDetail.cost / serviceEstimateDetail.sell)) * 100 : null), Validators.compose([Validators.required, Validators.max(99.99), Validators.min(0)])]
        });
    } else {
      group = this.fb.group({
        'businessPartnerId': '',
        'solutionPilarId': [null, Validators.required],
        'activity': [serviceEstimateDetail.activity ? serviceEstimateDetail.activity : '', Validators.required],
        'bpName': ['', Validators.required],
        'estimatiedPrice': [0, Validators.required],
        'rate': 0,
        'cost': [null, Validators.required],
        'sell': [null],
        'upliftPercentage': [this.setBPUpliftMargin()],
        'expenseEstimate': 0,
        'multiplier': 1,
        'isFixedCost': 0,
        'estimatedHours': [null, Validators.required],
        'bpGrossMarginPercentage': [this.setBPGrossMargin(), Validators.compose([Validators.required, Validators.max(99.99), Validators.min(0)])]
      });
    }

    this.businessPartnerCalulcationOnGroup(group);
    return group;

  }
  buildGroup(serviceEstimateDetail?: any, isDragRecord?: boolean) {
    const _sr = this.serviceRoles.filter(sr => sr.serviceRoleId == serviceEstimateDetail.serviceRoleId)[0];
    if (serviceEstimateDetail.id || isDragRecord) {
      let group: UntypedFormGroup;
      const phase1 = serviceEstimateDetail.phase ? serviceEstimateDetail.phase : null;
      group = this.fb.group({
        id: serviceEstimateDetail.id ? serviceEstimateDetail.id : null,
        'activity': [serviceEstimateDetail.activity ? serviceEstimateDetail.activity : 'Default', Validators.required],
        quantity: [serviceEstimateDetail.quantity ? serviceEstimateDetail.quantity : 0, Validators.compose([Validators.required, stepValidator()])],
        estimatiedPrice: serviceEstimateDetail.estimatiedPrice ? serviceEstimateDetail.estimatiedPrice : 0,
        billedRate: serviceEstimateDetail.billedRate ? serviceEstimateDetail.billedRate : 0,
        serviceRoleId: serviceEstimateDetail.serviceRoleId ? serviceEstimateDetail.serviceRoleId : null,
        serviceRatePerQuanity: serviceEstimateDetail.serviceRatePerQuanity ? serviceEstimateDetail.serviceRatePerQuanity : 0,
        overrideRate: serviceEstimateDetail.overrideRate ? serviceEstimateDetail.overrideRate : 0,
        expenseEstimate: serviceEstimateDetail.estimatiedPrice ? serviceEstimateDetail.estimatiedPrice : 0,
        multiplier: serviceEstimateDetail.multiplier ? serviceEstimateDetail.multiplier : 1,
        phase: serviceEstimateDetail.phase,
        activityNotes: serviceEstimateDetail.activityNotes,
        isInstallation: serviceEstimateDetail.isInstallation,
        isSpecialApplicationAndPremierService: serviceEstimateDetail.isSpecialApplicationAndPremierService,
        isPrevailingWage: serviceEstimateDetail.isPrevailingWage ? serviceEstimateDetail.isPrevailingWage : false,
        'phaseItemOrder': serviceEstimateDetail.phaseItemOrder
      });
      this.setupCalculationOnGroup(group);
      if(this.serviceEstimateForm?.controls['componentOverrideRate'].value != null) {
        group.controls['overrideRate'].setValue(this.serviceEstimateForm.controls['componentOverrideRate'].value);
      }
      return group;
    } else {
      const group = this.fb.group({
        'activity': [serviceEstimateDetail && serviceEstimateDetail.activity ? serviceEstimateDetail.activity : 'Default', Validators.required],
        'id': serviceEstimateDetail.id ? serviceEstimateDetail.id : null,
        'quantity': [0, Validators.compose([Validators.required, stepValidator()])],
        'estimatiedPrice': 0,
        'billedRate': _sr ? _sr.serviceRoleHourlyRate : 0,
        'serviceRoleId': serviceEstimateDetail.serviceRoleId ? serviceEstimateDetail.serviceRoleId : null,
        'serviceRatePerQuanity': _sr ? _sr.serviceRoleHourlyRate : 0,
        'overrideRate': _sr ? _sr.serviceRoleHourlyRate : 0,
        'expenseEstimate': 0,
        'multiplier': 1,
        phase: serviceEstimateDetail.phase,
        activityNotes: [],
        isInstallation: false,
        isSpecialApplicationAndPremierService: false,
        isPrevailingWage: serviceEstimateDetail.isPrevailingWage ? serviceEstimateDetail.isPrevailingWage : false,
        'phaseItemOrder': serviceEstimateDetail.phaseItemOrder
      });
      if(this.serviceEstimateForm?.controls['componentOverrideRate'].value != null) {
        group.controls['overrideRate'].setValue(this.serviceEstimateForm.controls['componentOverrideRate'].value);
      }
      this.setupCalculationOnGroup(group);
      return group;
    }

  }
  buildBusinessGroup(businessPartnerItem?: any) {
    let group: UntypedFormGroup;
    if (businessPartnerItem) {
      group = this.fb.group({

      });
    } else {

    }
  }
  buildTEGroup(travelExpense?: any) {
    let group: UntypedFormGroup;
    if (travelExpense.id) {
      group = this.fb.group({
        id: [travelExpense.id ? travelExpense.id : null],
        airfare: [travelExpense.airfare ? travelExpense.airfare : 0],
        auto: [travelExpense.auto ? travelExpense.auto : 0],
        hotel: [travelExpense.hotel ? travelExpense.hotel : 0],
        gas: [travelExpense.gas ? travelExpense.gas : 0],
        meals: [travelExpense.meals ? travelExpense.meals : 0],
        other: [travelExpense.other ? travelExpense.other : 0],
        isLocalClient: [travelExpense.isLocalClient ? travelExpense.isLocalClient : false],
        travelExpenseType: [
          travelExpense.travelExpenseType
            ? travelExpense.travelExpenseType
            : null,
          Validators.required,
        ],
        travelExpenseBusinessPartnerId: [
          travelExpense.travelExpenseBusinessPartnerId
            ? travelExpense.travelExpenseBusinessPartnerId
            : null,
          this.getInitialBusinessPartnerValidator(
            travelExpense.travelExpenseType
          ),
        ],
        travelHours: [travelExpense.travelHours ? travelExpense.travelHours : 0],
        perHourCharge: [travelExpense.perHourCharge ? travelExpense.perHourCharge : 100],
        airfareNotes: [travelExpense.airfareNotes],
        autoNotes: [travelExpense.autoNotes],
        hotelNotes: [travelExpense.hotelNotes],
        gasNotes: [travelExpense.gasNotes],
        mealNotes: [travelExpense.mealNotes],
        otherNotes: [travelExpense.otherNotes]
      });
    } else {
      group = this.fb.group({
        airfare: [0],
        auto: [0],
        hotel: [0],
        gas: [0],
        meals: [0],
        other: [0],
        isLocalClient: [false],
        travelExpenseType: [null, Validators.required],
        travelExpenseBusinessPartnerId: [null, Validators.required],
        travelHours: [0],
        perHourCharge: [100],
        airfareNotes: [null],
        autoNotes: [null],
        hotelNotes: [null],
        gasNotes: [null],
        mealNotes: [null],
        otherNotes: [null]
      });
    }
    return group;
  }

  getInitialBusinessPartnerValidator(
    travelExpenseType: string | null
  ): Validators | null {
    return travelExpenseType === 'Meridian' ? null : Validators.required;
  }

  buildSKUGroup(sku?: any) {
    let group: UntypedFormGroup;
    if (sku.id) {
      group = this.fb.group({
        id: [sku.id ? sku.id : null],
        activity: [sku.activity ? sku.activity : '', Validators.required],
        solutionPilarId: [sku.solutionPilarId ? sku.solutionPilarId : 0, Validators.required],
        cost: [sku.cost ? sku.cost : 0, Validators.required],
      });
    } else {
      group = this.fb.group({
        activity: ['', Validators.required],
        solutionPilarId: [null, Validators.required],
        cost: [0, Validators.required],
      });
    }
    this.skuCalcOnGroup(group);
    return group;
  }
  businessPartnerCalulcationOnGroup(group: UntypedFormGroup) {
    group.controls['isFixedCost'].valueChanges
      .subscribe(res => {
        if (res) {
          group.controls['estimatedHours'].clearValidators();
          group.controls['estimatedHours'].clearAsyncValidators();
          group.controls['estimatedHours'].disable();
          group.controls['estimatedHours'].updateValueAndValidity();
        } else {
          group.controls['estimatedHours'].setValidators(Validators.required);
          group.controls['estimatedHours'].enable();
          group.controls['estimatedHours'].updateValueAndValidity();


        }
        this.costEval(group);
      });
    group.controls['cost'].valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(res => {
        this.costEval(group);
        this.calcBPControls('cost', group);
      });
    group.controls['solutionPilarId'].valueChanges
      .subscribe(res => {
        this.costEval(group);
      });
    group.controls['estimatedHours'].valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(res => {
        this.costEval(group);
      });
    group.controls['sell'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(res => {
        this.calcBPControls('sell', group);
      });
      group.controls['upliftPercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(res => {
        this.calcBPControls('upliftPercentage', group);
      });
    group.controls['bpGrossMarginPercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(res => {
        this.calcBPControls('bpGrossMarginPercentage', group);
      });
  }
  skuCalcOnGroup(group: UntypedFormGroup) {
    group.controls['solutionPilarId'].valueChanges
      .subscribe(res => {
        this.skuCostEval(group);
      });
    group.controls['cost'].valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(res => {
        this.skuCostEval(group);
      });
  }
  calcBPControls(fieldName: string, formValue?: UntypedFormGroup) {
    if (fieldName == 'cost' || fieldName == 'bpGrossMarginPercentage') {
      this.calcSellUplift(formValue, fieldName != 'cost');
      this.setTotalGrossMarginPercentage();
      this.setTotalUpliftPercentage();
    } else if (fieldName == 'sell') {
      this.calcBpGmpUplift(formValue);
      this.setTotalGrossMarginPercentage();
      this.setTotalUpliftPercentage();
    } else if (fieldName == 'grossMarginPercentage') {
      this.calcGrossMarginPerct();
      this.calcAllSellUplift();
      this.setTotalUpliftPercentage();
    }else if (fieldName == 'upliftPercentage') {
      this.calcBpGmpSell(formValue);
      this.setTotalGrossMarginPercentage();
      this.setTotalUpliftPercentage();
    }else if (fieldName == 'totalUpliftPercentage') {
      this.calcUpliftPerct();
      this.calcAllSellGMP();
      this.setTotalGrossMarginPercentage();      
    }
  }
  calcAllSellUplift() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get('businessPartnerItems') as UntypedFormArray;
    businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
      this.calcSellUplift(bpItem, true);
    });
  }
  calcAllSellGMP() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get('businessPartnerItems') as UntypedFormArray;
    businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
      this.calcBpGmpSell(bpItem);
    });
  }
  calcSellUplift(group?: UntypedFormGroup, calcUplift?: boolean) {
    let bpGrossMarginPercentage: any = group?.controls['bpGrossMarginPercentage'].value;
    let uplift = null;
    if (bpGrossMarginPercentage < 100 && bpGrossMarginPercentage >= 0) {
      const cost = group?.controls.cost.value;
      const sellValue = (cost * 100) / (100 - bpGrossMarginPercentage);
      uplift = cost && sellValue ? (((sellValue - cost) / cost) * 100) : null;
      group?.controls['sell'].setValue(sellValue, { emitEvent: false });
    } else {
      group?.controls['sell'].setValue(0, { emitEvent: false });
    }
    if(!this.isUpliftDefaulted && group?.controls['bpGrossMarginPercentage'].value == null && !calcUplift){
      this.calcBpGmpSell(group);
    }
    else if(!this.isUpliftDefaulted || calcUplift || group?.controls['upliftPercentage'].value == null){
      group?.controls['upliftPercentage'].setValue(uplift, { emitEvent: false });
    }else{
      this.calcBpGmpSell(group);
    }
  }
  calcBpGmpUplift(group?: UntypedFormGroup) {
    let sellValue: any = group?.controls['sell'].value;
    let costValue: any = group?.controls['cost'].value;
    const bpGrossMarginPerct = costValue && sellValue ? (1 - (costValue / sellValue)) * 100 : null;
    const uplift = costValue && sellValue ?(((sellValue - costValue) / costValue) * 100) : null;
    group?.controls['bpGrossMarginPercentage'].setValue(bpGrossMarginPerct, { emitEvent: false });
    group?.controls['upliftPercentage'].setValue(uplift, { emitEvent: false });
  }
  calcBpGmpSell(group?: UntypedFormGroup) {
    let uplift: any = group?.controls['upliftPercentage'].value;
    let costValue: any = group?.controls['cost'].value;
    const sellValue = costValue * ((uplift + 100) / 100);
    const bpGrossMarginPerct = costValue && sellValue ? (1 - (costValue / sellValue)) * 100 : null;
    group?.controls['bpGrossMarginPercentage'].setValue(bpGrossMarginPerct, { emitEvent: false });
    group?.controls['sell'].setValue(sellValue, { emitEvent: false });
  }
  calcGrossMarginPerct() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get('businessPartnerItems') as UntypedFormArray;
    let grossMarginPercentageControl: UntypedFormControl = this.serviceEstimateForm.controls['grossMarginPercentage'] as UntypedFormControl;
    if (grossMarginPercentageControl.value != null || grossMarginPercentageControl.dirty) {
      businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
        bpItem.controls['bpGrossMarginPercentage'].setValue(grossMarginPercentageControl.value, { emitEvent: false });
      });
    }
  }
  calcUpliftPerct() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get('businessPartnerItems') as UntypedFormArray;
    let upliftPercentageControl: UntypedFormControl = this.serviceEstimateForm.controls['upliftPercentage'] as UntypedFormControl;
    if (upliftPercentageControl.value != null || upliftPercentageControl.dirty) {
      businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
        bpItem.controls['upliftPercentage'].setValue(upliftPercentageControl.value, { emitEvent: false });
      });
    }
  }
  getMarginOptions(){
    this.applicationConfigurationService.getMarginOptions()
      .subscribe((res: any) => {
        this.marginOption = res;
        this.isUpliftDefaulted = this.marginOption.marginOptionType == MarginOptionType.Uplift;
      });
  }
  setBPGrossMargin(){
    return this.marginOption.marginOptionType == MarginOptionType.GrossMargin ? Number.parseFloat(this.marginOption.grossMarginPercentage) : null;
  }
  setBPUpliftMargin(){
    return this.isUpliftDefaulted? Number.parseFloat(this.marginOption.upliftPercentage) : null;
  }
  setTotalGrossMarginPercentage() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get('businessPartnerItems') as UntypedFormArray;
    let costVal = 0;
    let sellVal = 0;
    businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
      costVal += bpItem.controls['cost'].value ? bpItem.controls['cost'].value : 0;
      sellVal += bpItem.controls['sell'].value ? bpItem.controls['sell'].value : 0;
    });
    const totalGMP = (1 - (costVal / sellVal)) * 100;
    this.serviceEstimateForm.controls['grossMarginPercentage'].setValue(totalGMP, { emitEvent: false });
  }
  setTotalUpliftPercentage() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get('businessPartnerItems') as UntypedFormArray;
    let costVal = 0;
    let sellVal = 0;
    businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
      costVal += bpItem.controls['cost'].value ? bpItem.controls['cost'].value : 0;
      sellVal += bpItem.controls['sell'].value ? bpItem.controls['sell'].value : 0;
    });
    const totalUplift = costVal && sellVal ?((sellVal - costVal) / costVal) * 100 : null;
    this.serviceEstimateForm.controls['upliftPercentage'].setValue(totalUplift, { emitEvent: false });
  }
  costEval(group: UntypedFormGroup) {
    const solPillarVal = group.controls['solutionPilarId'].value;
    if (solPillarVal) {
      let maxCharge = 0;
      group.controls['cost'].clearValidators();
      const solutionPillarRates = this.solutionPilars.find(x => x.solutionPilarId == solPillarVal)?.solutionPilarRates;
      solutionPillarRates?.forEach(rate => {
        if (maxCharge <= rate.maxCharge) {
          maxCharge = rate.maxCharge;
        }
      });
      const hrs = group.controls['estimatedHours'].value;
      const maxVal = group.controls['isFixedCost'].value ? maxCharge : hrs ? maxCharge / hrs : maxCharge;
      group.controls['cost'].setValidators(Validators.max(maxVal));
      group.controls['cost'].updateValueAndValidity();
    }
  }
  skuCostEval(group: UntypedFormGroup) {
    const solPillarVal = group.controls['solutionPilarId'].value;
    if (solPillarVal) {
      let maxCharge = 0;
      group.controls['cost'].clearValidators();
      const solutionPillarRates = this.solutionPilars.find(x => x.solutionPilarId == solPillarVal)?.solutionPilarRates;
      solutionPillarRates?.forEach(rate => {
        if (maxCharge <= rate.maxCharge) {
          maxCharge = rate.maxCharge;
        }
      });
      group.controls['cost'].setValidators(Validators.max(maxCharge));
      group.controls['cost'].updateValueAndValidity({ emitEvent: false });

    }
  }
  setupCalculationOnGroup(group: UntypedFormGroup) {
    group.controls['serviceRoleId'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        const selectedRole = this.serviceRoles.filter(sr => sr.serviceRoleId === res)[0];
        group.controls['billedRate'].setValue(selectedRole.serviceRoleHourlyRate);
        group.controls['serviceRatePerQuanity'].setValue(selectedRole['serviceRoleHourlyRate']);

        this.performEstimatiedPriceCalulation(group);
      });
    group.controls['quantity'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.performEstimatiedPriceCalulation(group);
      });
    group.controls['multiplier'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.performEstimatiedPriceCalulation(group);
      });
    group.controls['overrideRate'].valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.performEstimatiedPriceCalulation(group);
      });
  }
  performEstimatiedPriceCalulation(formGroup: UntypedFormGroup) {
    if (formGroup.controls['overrideRate']) {
      if (formGroup.controls['overrideRate'].value) {
        if (formGroup.controls['overrideRate'].value !== 0) {
          if (formGroup.controls['overrideRate'].value !== '0') {
            if (formGroup.controls['overrideRate'].value !== '') {
              const rate = formGroup.controls['overrideRate'].value;
              formGroup.controls['billedRate'].setValue(rate);
            }
          }
        }
      }
    } else {
    }
    let quantity = formGroup.controls['quantity'].value;
    const multiplier = formGroup.controls['multiplier'].value;

    quantity = quantity * multiplier;
    let billRate;
    billRate = formGroup.controls['billedRate'].value;
    const cost = quantity * billRate;

    formGroup.controls['estimatiedPrice'].setValue(cost);

  }
  calculateTotals() {
    if (this.serviceEstimateForm.valid) {
      let total: number;
      total = 0;
      const closingItems: Array<any> = this.serviceEstimateDetailsClosing.value;
      const executeItems: Array<any> = this.serviceEstimateDetailsExecute.value;
      const planningItems: Array<any> = this.serviceEstimateDetailsPlanning.value;
      const businessPartnerItems: Array<any> = this.businessPartnerItems.value;
      closingItems.forEach(item => {
        total += Number.parseInt(item['estimatiedPrice']);
      });
      executeItems.forEach(item => {
        total += Number.parseInt(item['estimatiedPrice']);
      });
      planningItems.forEach(item => {
        total += Number.parseInt(item['estimatiedPrice']);
      });
      const priceAdjustment = Number.parseInt(this.serviceEstimateForm.controls['priceAdjustment'].value);
      if (priceAdjustment) {
        total += priceAdjustment;
      }
      total += this.getTravelTotal();
      this.serviceEstimateForm.controls['totalAmount'].setValue(total);
      let formValue: any;
      formValue = this.serviceEstimateForm.value;
      formValue['phaseItems'] = [];
      closingItems.forEach(item => {
        formValue['phaseItems'].push(item);
      });
      executeItems.forEach(item => {
        formValue['phaseItems'].push(item);
      });
      planningItems.forEach(item => {
        formValue['phaseItems'].push(item);
      });
      let businessPartnerTotal = 0;
      businessPartnerItems.forEach(i => {
        businessPartnerTotal += i.sell;

      });
      this.serviceEstimateForm.controls['businessPartnerTotal'].setValue(businessPartnerTotal);
      formValue.phaseItems.filter((x: any) => !x.id).forEach((item: any) => {
        delete item.id;
      })
      if (!this.templateId) {
        this.psService.createTemplate(formValue)
          .subscribe(res => {
            this.location.back();
          }, error => {
            if (error.status == 409) {
              this.isUniqueName = false;
            }
          });
      } else {
        this.psService.updateTemplate(formValue)
          .subscribe(res => {
            this.location.back();
          }, error => {
            if (error.status == 409) {
              this.isUniqueName = false;
            }
          });
      }
    } else {
      this.isValid = this.serviceEstimateForm.valid;
    }
  }
  calculateMaterialTotal() {
    const matItems: Array<any> = this.materialItems.value;
    let total = 0;
    matItems.forEach(item => {
      total += item['sell'];
    });
    const totalValue = this.serviceEstimateForm.controls['totalAmount'].value;

    this.serviceEstimateForm.controls['totalAmount'].setValue(totalValue + total);
  }
  getTravelTotal() {
    const travelExpenses: Array<any> = this.travelExpenses.value;
    let total = 0;
    travelExpenses.forEach(item => {
      total += item['airfare'];
      total += item['auto'];
      total += item['hotel'];
      total += item['gas'];
      total += item['meals'];
      total += item['other'];
      total += (item['travelHours'] * item['perHourCharge']);
    });
    return total;
  }
  setupCalculationOnBPGroup(group: UntypedFormGroup) {

  }
  removeControl(control: any, index: any) {
    const id = this.businessPartnerItems.controls[index].value['id'];
    if (id) {
      this.psService.deleteTemplateBusinessPartnerItemById(id)
        .subscribe(res => {
          this.businessPartnerItems.removeAt(index);
          this.setGrossMarginValidations(true);
          this.setUpliftValidations(true);
          this.setTotalGrossMarginPercentage();
          this.setTotalUpliftPercentage();

        });
    } else {
      this.businessPartnerItems.removeAt(index);
      this.setGrossMarginValidations(true);
      this.setUpliftValidations(true);
      this.setTotalGrossMarginPercentage();
      this.setTotalUpliftPercentage();
    }
  }
  openBusinessSearch(control: any, index: any) {
    const popup = this.dialog.open(BusinessPartnerSearchPopupComponent, {
      height: '60%',
      width: '60%',
      data: {
        hideGeoFenceOpt: true
      }
    });
    popup.afterClosed().subscribe(res => {
      if (res) {
        let item: any;
        item = this.businessPartnerItems.at(index).value;
        this.businessPartnerItems.at(index).patchValue({ 'bpName': res.data.businessPartnerLegalName, 'businessPartnerId': res.data.id });
      }
    });
  }


  public canDeactivate(): boolean {
    const state = this.form.submitted || !this.form.dirty;
    const url = this.location.path();

    if (state) {

    } else {
      history.pushState(null, 'Meridian Unified Portal', url);
    }
    return state;
  }

  itemsReordered(data: any) {
    this.serviceEstimateDetailsPlanning.setValue(data);
  }
  drop(event: any) {
    let item;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.reorderPhaseIndex(event.container.data);
      switch (event.container.id) {
        case "planItems":
          this.serviceEstimateDetailsPlanning.setValue(event.container.data);
          break;
        case "executeItems":
          this.serviceEstimateDetailsExecute.setValue(event.container.data);
          break;
        case "closeItems":
          this.serviceEstimateDetailsClosing.setValue(event.container.data);
          break;
      }
    } else {
      item = event.previousContainer.data[event.previousIndex];
      this.reorderItems(event, item);

    }
  }
  reorderPhaseIndex(items: any) {
    items.forEach((x: any, index: any) => {
      x.phaseItemOrder = index
    })
  }
  updatePhaseIndex(phase: ProjectPhase) {
    let items: UntypedFormArray;
    if (phase == ProjectPhase.Plan) {
      items = this.serviceEstimateDetailsPlanning;
      this.reorderPhaseIndex(items.value);
      this.serviceEstimateDetailsPlanning.setValue(items.value);
    } else if (phase == ProjectPhase.Execute) {
      items = this.serviceEstimateDetailsExecute;
      this.reorderPhaseIndex(items.value);
      this.serviceEstimateDetailsExecute.setValue(items.value);
    } else {
      items = this.serviceEstimateDetailsClosing;
      this.reorderPhaseIndex(items.value);
      this.serviceEstimateDetailsClosing.setValue(items.value);
    }
  }
  reorderItems(event: any, item: any) {
    let items; let prevItems;
    switch (event.previousContainer.id) {
      case "planItems":
        prevItems = this.serviceEstimateDetailsPlanning;
        prevItems.removeAt(event.previousIndex);
        this.reorderPhaseIndex(prevItems.value);
        this.serviceEstimateDetailsPlanning.setValue(prevItems.value);
        break;
      case "executeItems":
        prevItems = this.serviceEstimateDetailsExecute;
        prevItems.removeAt(event.previousIndex);
        this.reorderPhaseIndex(prevItems.value);
        this.serviceEstimateDetailsExecute.setValue(prevItems.value);
        break;
      case "closeItems":
        prevItems = this.serviceEstimateDetailsClosing;
        prevItems.removeAt(event.previousIndex);
        this.reorderPhaseIndex(prevItems.value);
        this.serviceEstimateDetailsClosing.setValue(prevItems.value);
        break;
    }
    switch (event.container.id) {
      case "planItems":
        this.addPlanningItem(item, true);
        items = this.swap(this.serviceEstimateDetailsPlanning.value, event);
        this.reorderPhaseIndex(items);
        this.serviceEstimateDetailsPlanning.setValue(items);
        break;
      case "executeItems":
        this.addExecuteItem(item, true);
        items = this.swap(this.serviceEstimateDetailsExecute.value, event);
        this.reorderPhaseIndex(items);
        this.serviceEstimateDetailsExecute.setValue(items);
       break;
      case "closeItems":
        this.addClosingItem(item, true);
        items = this.swap(this.serviceEstimateDetailsClosing.value, event);
        this.reorderPhaseIndex(items);
        this.serviceEstimateDetailsClosing.setValue(items);
        break;
    }
  }
  swap(items: any, event: any) {
    let temp = items[event.currentIndex];
    items[event.currentIndex] = items[items.length - 1];
    items[items.length - 1] = temp;
    return items;
  }
  IsNotValidValidator(grossMarginControl: AbstractControl) {
    if (grossMarginControl.value != null && grossMarginControl.value.toFixed(2) < 15) {
      return { 'isNotValid': { value: grossMarginControl.value } };
    };
    return null;
  }
}
