import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-templates-landing-page',
  templateUrl: './templates-landing-page.component.html',
  styleUrls: ['./templates-landing-page.component.scss']
})
export class TemplatesLandingPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
