import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import { Discovery } from '../../models/discovery-datasource';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-discovery-upload',
  templateUrl: './discovery-upload.component.html',
  styleUrls: ['./discovery-upload.component.scss']
})
export class CloudMigrationDiscoveryUploadComponent implements OnInit {
  @Input() title: string;
  @Input() fileExtension: string;
  @Input() configurationId: string;
  @Input() projectId: string;

  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();
  fileInput: any;

  constructor(private http: HttpClient, private cloudMigrationService: CloudMigrationToolService,
    private activeRoute: ActivatedRoute,
  ) { }
  
  ngOnInit() {
      console.log("this.projectId; ", this.projectId);
  }

  public uploadFile = (files: File[]) => {
    console.log("this.projectId: ",this.projectId);
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    let discovery = new Discovery();
    discovery.projectId = this.projectId;

    if(this.fileExtension=='.json')
    {
      this.cloudMigrationService.uploadDiscovery(fileToUpload, discovery).then((res) => {
        this.broadcastLoadCompleted(res);
        this.fileInput = null;
      });
    }
    else if(this.fileExtension='.csv')
    {
      this.cloudMigrationService.uploadIBMiDiscovery(fileToUpload, this.projectId, this.configurationId).then((res) => {
        this.broadcastLoadCompleted(res);
        this.fileInput = null;
      });
      
    }
  }
  broadcastLoadCompleted(res: any) {
    this.onUploadFinished.emit(res);
  }
}
