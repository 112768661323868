import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'read-only-field',
  templateUrl: './read-only-field.component.html',
  styleUrls: ['./read-only-field.component.scss']
})

export class ReadOnlyFieldComponent implements OnInit {
  @Input() value: string;
  @Input() title: string;
  @Input() suffix: string;

  ngOnInit() {
    if (this.value === undefined || (this.value && this.value === "")) {
      this.value = "-";
    }
  }
}
