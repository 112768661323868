import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MsalGuard } from '@azure/msal-angular';
import { SimpplrIntegrationComponent } from './simpplr-integration/simpplr-integration.component';
import { SalesForceTestingListComponent } from './salesforce-testing/salesforce-testing-list/salesforce-testing-list.component';
import { SalesforceTestingCreateComponent } from './salesforce-testing/salesforce-testing-create/salesforce-testing-create.component';
import { SalesforceComponent } from './salesforce-testing/salesforce.component';


const routes: Routes = [
  {
    path: '', children: [
      {
        path: '',
        component: LandingPageComponent, canActivate: [MsalGuard]
      },
      {
        path: 'documentCreator',
        component: HomeComponent
      },
      {
        path: 'logout',
        component: LogoutComponent
      },
      {
        path: 'simpplr-integration',
        component: SimpplrIntegrationComponent, canActivate: [MsalGuard]
      },
      // {
      //   path: 'salesforce-testing',
      //   component: SalesForceTestingComponent, canActivate: [MsalGuard]
      // },
      {
        path: 'salesforce-testing', component: SalesforceComponent, canActivate: [MsalGuard], children: [
          { path: '', component: SalesForceTestingListComponent },
          { path: 'create', component: SalesforceTestingCreateComponent },
        ]
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export const routedComponents = [AppComponent];
