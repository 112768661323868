<div *ngIf="lineOfBusinesses">
  <div class="container-fluid">
      <mat-card>
          <mat-card-content>
              <mat-table #table [dataSource]="lineOfBusinesses">
                  <ng-container matColumnDef="vehicleDriverName">
                      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                          <span> {{element.firstName}} {{element.lastName}}</span>
                      </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="vehicleDriverActions">
                      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                          <button mat-raised-button color="warn" type="button" (click)="deleteLineOfBusiness(element.serviceRoleId)">Delete</button>
                      </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
          </mat-card-content>
      </mat-card>
  </div>
</div>