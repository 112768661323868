<mat-card *ngIf="insurance">
  <form [formGroup]="insuranceForm" (ngSubmit)="uploadFile()">
    <mat-card-content>
      <div class="col-md-4">
        <mat-form-field>
          <input placeholder="Company Name" matInput type="text" [(ngModel)]="insurance.vehicleInsuranceCompanyName" name="vehicleInsuranceCompanyName" formControlName="vehicleInsuranceCompanyName"
          />
          <mat-error *ngIf="insuranceForm.controls['vehicleInsuranceCompanyName'].hasError('required')">A company name is required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field>
          <input placeholder="Policy Number" matInput type="text" [(ngModel)]="insurance.vehicleInsurancePolicyNumber" name="vehicleInsurancePolicyNumber" formControlName="vehicleInsurancePolicyNumber"
          />
          <mat-error *ngIf="insuranceForm.controls['vehicleInsurancePolicyNumber'].hasError('required')">A policy number is required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field>
          <input [matDatepicker]="vehicleInsuranceStartDate" [max]="getMaxDate()" matInput placeholder="Start Date" type="datetime" [(ngModel)]="insurance.vehicleInsuranceStartDate"
            name="vehicleInsuranceStartDate" formControlName="vehicleInsuranceStartDate" />
            <mat-error *ngIf="insuranceForm.controls['vehicleInsuranceStartDate'].hasError('required')">A start date is required</mat-error>
          <mat-datepicker-toggle matSuffix [for]="vehicleInsuranceStartDate"></mat-datepicker-toggle>
          <mat-datepicker #vehicleInsuranceStartDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field>
          <input [matDatepicker]="vehicleInsuranceEndDate" [min]="getMinDate()" matInput placeholder="End Date" type="datetime" [(ngModel)]="insurance.vehicleInsuranceEndDate"
            name="vehicleInsuranceEndDate" formControlName="vehicleInsuranceEndDate" /> 
            <mat-error *ngIf="insuranceForm.controls['vehicleInsuranceEndDate'].hasError('required')">A end date is required</mat-error>
          <mat-datepicker-toggle matSuffix [for]="vehicleInsuranceEndDate"></mat-datepicker-toggle>
          <mat-datepicker #vehicleInsuranceEndDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <input placeholder="Policy File" type="file" (change)="addFileToModel($event)" />
      </div>

    </mat-card-content>
    <mat-card-actions>

      <div class="col-md-4">
        <button mat-raised-button color="accent" type="submit">Create</button>
      </div>

    </mat-card-actions>
  </form>
</mat-card>
