import { Component, OnInit } from '@angular/core';
import { VehicleModel } from '../../../models/fleet-management-models';
import { FleetMangementService } from '../../../fleet-mangement.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicle-model-detail',
  templateUrl: './vehicle-model-detail.component.html',
  styleUrls: ['./vehicle-model-detail.component.scss']
})
export class VehicleModelDetailComponent implements OnInit {

  model: VehicleModel;
  isEditing: boolean;
  constructor(private service: FleetMangementService, private router: Router) { }

  ngOnInit() {
    this.isEditing = false;
    if (this.model) {

    } else {
      this.model = new VehicleModel();
    }
  }
  toggleEditing() {
    this.isEditing = !this.isEditing;
  }
  onCreate() {
    this.service.createVehicleModels(this.model)
      .subscribe(res => {
        this.router.navigate(['fleetmanagement', 'models']);
      });
  }

}
