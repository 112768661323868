import { DataSource } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, merge } from 'rxjs';
import { CloudMigrationToolAdminService } from '../cloud-migration-tool-admin.service';

@Component({
  selector: 'app-cloud-provider-compute-product-list',
  templateUrl: './cloud-provider-compute-product-list.component.html',
  styleUrls: ['./cloud-provider-compute-product-list.component.scss']
})
export class CloudProviderComputeProductListComponent implements OnInit {
  @Input() regionOptions: any;
  @ViewChild(MatTable) table: MatTable<any>;

  cloudProductFilterForm: UntypedFormGroup;
  cloudProductTableDataSource: any;
  displayedColumns = ['computeProductId', 'productName', 'operatingSystem','cpu', 'ram'];
  productSearchCriteria: any;
  cloudProviderId: any;

  constructor(private service: CloudMigrationToolAdminService,
    private activeRoute: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(rp => {
      this.cloudProviderId = rp['id'] ?? '';
    });
    this.productSearchCriteria = {  cloudProviderId: this.cloudProviderId,
                                    region: '',//(this.regionOptions)?this.regionOptions[0]?.value:'',
                                    cpu: '-1',
                                    ram: '-1',
                                    operatingSystem: '',
    };
    this.cloudProductFilterForm = this.createFormGroup(this.productSearchCriteria);
    
    this.getCloudProducts(this.productSearchCriteria);
  }

  createFormGroup(data: any): UntypedFormGroup {
    return new UntypedFormGroup({
      region: new UntypedFormControl(data?.region),
      cpu: new UntypedFormControl(''),
      ram: new UntypedFormControl(''),
    })
  }
  getCloudProducts(data: ProductSearchCriteria) {
    this.service.getMatchingProducts(data).subscribe(res => {
      const cloudProductTableDataSource:any = res;
      this.table.dataSource = cloudProductTableDataSource.rows;
    }, (error) => {
    });
  }

}
export class ProductDataSource extends DataSource<any>
{
  data: Cp[];
  nonFilteredData;

  constructor(private products: Array<any>) {
    super();
    this.data = products;
    this.nonFilteredData = products;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<Cp[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      of(this.data)
    ];

    return merge(...dataMutations);
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

}
export class ProductSearchCriteria {
  cloudProviderId: any;
  region: string;
  cpu: number;
  ram: number;
  operatingSystem: string;
}

export class Cp {
  instanceType: string;
}