<div class="mat-elevation-z8" *ngIf="clientForm && serviceRoles">
  <form [formGroup]="clientForm" (ngSubmit)="updateClient()">
    <mat-toolbar color="accent">
      <button mat-mini-fab type="submit" [disabled]="!clientForm.valid" color="default" matTooltip="Save Client" aria-label="Button to save client data" [matTooltipPosition]="'after'">
        <mat-icon>save</mat-icon>
      </button>
    </mat-toolbar>
    <mat-card>
      <mat-card-title>Unified Portal Values</mat-card-title>
      <mat-card-content>
        <mat-slide-toggle formControlName="customSowTemplate">Custom SOW Template</mat-slide-toggle>
        <br />
        <mat-form-field>
          <mat-label>Notes</mat-label>
          <textarea matInput placeholder="" formControlName="notes"></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>
        Custom Rates
      </mat-card-title>
      <mat-card-content>
        <button style="margin-left: 15px !important;" mat-icon-button (click)="addClientRate()" color="primary"
                type="button">
          <mat-icon>add</mat-icon>
        </button>
        <div class="col-md-4" [formArrayName]="'clientRates'">
          <div *ngFor="let item of clientRates.controls;let i = index;" [formGroupName]="i">
            <p class="header5">
              Item {{i + 1}}
              <mat-icon class="md-18 cursor" (click)="removeClientRate(i)">
                clear
              </mat-icon>
            </p>
            <mat-form-field>
              <mat-label>Service Role</mat-label>
              <mat-select formControlName="serviceRoleId" disableOptionCentering>
                <mat-option *ngFor="let serviceRole of serviceRoles" [value]="serviceRole.serviceRoleId">
                  {{serviceRole.serviceRoleName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <span matPrefix>$ </span>
              <mat-label>Rate</mat-label>
              <input matInput currencyMask formControlName="clientHourlyRate" placeholder="rate" />
              <mat-error *ngIf="clientForm.controls['clientRates'].controls[i].controls['clientHourlyRate'].hasError('required')">A rate is required.</mat-error>
            </mat-form-field>
            <hr>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>
        Epicor Values
      </mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field>
              <mat-label>ExternalCustomerId</mat-label>
              <input matInput type="text" formControlName="externalCustomerId" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>CommonName</mat-label>
              <input matInput type="text" placeholder="" formControlName="commonName" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>LegalName</mat-label>
              <input matInput type="text" placeholder="" formControlName="legalName" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>DateCreatedExternally</mat-label>
              <input matInput type="text" placeholder="" formControlName="dateCreatedExternally" />
            </mat-form-field>
            <mat-form-field>
              <mat-select placeholder="CustomerType" formControlName="customerType" disableOptionCentering>
                <mat-option [value]="0"> Suspect </mat-option>
                <mat-option [value]="1"> Prospect </mat-option>
                <mat-option [value]="2"> Customer </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">

            <mat-form-field>
              <mat-label>AddressLine1</mat-label>
              <input matInput type="text" placeholder="" formControlName="addressLine1" />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field>
              <mat-label>AddressLine2</mat-label>
              <input matInput type="text" placeholder="" formControlName="addressLine2" />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field>
              <mat-label>AddressLine3</mat-label>
              <input matInput type="text" placeholder="" formControlName="addressLine3" />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field>
              <mat-label>City</mat-label>
              <input matInput type="text" placeholder="" formControlName="city" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>State</mat-label>
              <input matInput type="text" placeholder="" formControlName="state" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Zip</mat-label>
              <input matInput type="text" placeholder="" formControlName="zip" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Country</mat-label>
              <input matInput type="text" placeholder="" formControlName="country" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field>
              <mat-label>IndustryClassType</mat-label>
              <input matInput type="text" placeholder="" formControlName="industryClassType" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>IndustryClassCode</mat-label>
              <input matInput type="text" placeholder="" formControlName="industryClassCode" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>IndustryClassDescription</mat-label>
              <input matInput type="text" placeholder="" formControlName="industryClassDescription" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field>
              <mat-label>Phone</mat-label>
              <input matInput type="tel" placeholder="" formControlName="phone" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput type="email" placeholder="" formControlName="email" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Website</mat-label>
              <input matInput type="text" placeholder="" formControlName="website" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field>
              <mat-label>TerritoryDescription</mat-label>
              <input matInput type="text" placeholder="" formControlName="territoryDescription" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Region</mat-label>
              <input matInput type="text" placeholder="" formControlName="region" />
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
