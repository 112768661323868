import { Component, OnInit } from '@angular/core';
import { FleetMangementService } from '../../fleet-mangement.service';

@Component({
  selector: 'app-expense-per-mile',
  templateUrl: './expense-per-mile.component.html',
  styleUrls: ['./expense-per-mile.component.scss']
})
export class ExpensePerMileComponent implements OnInit {
  startDate: Date;
  endDate: Date;
  reportResult: any;
  constructor(private fleetService: FleetMangementService) { }

  ngOnInit() {
  }
  getReport() {
    this.fleetService.getVehicleReportExpensePerMile(this.startDate, this.endDate)
      .subscribe(res => {
          this.reportResult = res;
        
      });
  }

}
