import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployeeComponent } from './employee.component';
import { EmployeeCreateComponent } from './employee-create/employee-create.component';
import { EmployeeDetailComponent } from './employee-detail/employee-detail.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';

export const routes: Routes = [
  {
    path: 'employee', component: EmployeeComponent, data: {
      breadcrumb: 'Employees', isRoutable: true
    }, children: [
      {
        path: '', component: EmployeeListComponent,
        data: {
          breadcrumb: null, isRoutable: true
        }
      },
      {
        path: 'create', component: EmployeeCreateComponent, data: {
          breadcrumb: 'Create', isRoutable: true
        }
      },
      {
        path: 'details/:userId', component: EmployeeDetailComponent, data: { data: 'Test Data', breadcrumb: 'Details', isRoutable: true }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeRoutingModule { }
