<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{formTitle}}</h3>
<!--         
        diskSpace: new FormControl(Number(data?.diskSpace)), -->
<mat-card>
  <mat-dialog-content [formGroup]="pricingRequestForm">
    <div>
      <fieldset>
        <legend>Pricing</legend>
        <h4>LPAR Data</h4>
        <div class="row">
          <div class="col-2">
            <mat-form-field>
              <!-- Processors -->
              <input matInput type="number" formControlName="processors" placeholder="Processors" />
              <mat-error *ngIf="pricingRequestForm.controls['processors'].hasError('required')">
                Processors is required
              </mat-error>
              <span matSuffix></span>
            </mat-form-field>
          </div>
          <div class="col-2">
            <!-- Main Storage  -->
            <mat-form-field>
              <input matInput type="number" formControlName="mainStorage" placeholder="Main Storage (GB)" />
              <mat-error *ngIf="pricingRequestForm.controls['mainStorage'].hasError('required')">
                Main Storage is required
              </mat-error>
              <span matSuffix>(GB)</span>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <!-- diskSpace -->
              <input matInput type="number" min="0" step="1" formControlName="diskSpace" placeholder="Disk Space" />
              <mat-error *ngIf="pricingRequestForm.controls['osLevel'].hasError('required')">
                diskSpace is required
              </mat-error>
              <span matSuffix>(MB)</span>
            </mat-form-field>
          </div>
          <div *ngIf="operatingSystemOptions">
            <div class="col-2">
              <mat-form-field>
                <!-- osType -->
                <mat-label>Operating System</mat-label>
                <mat-select formControlName="osType">
                  <mat-option *ngFor="let hostedSystem of operatingSystemOptions" [value]="hostedSystem.value">
                    {{hostedSystem.text}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="
                    pricingRequestForm.controls['osType'].hasError(
                        'required'
                      )
                    ">
                  Opertating System is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-2">
            <mat-form-field>
                <!-- osRelease -->
              <mat-label>OS Release</mat-label>
                <mat-select formControlName="osRelease">
                  <mat-option *ngFor="let osRelease of osReleaseOptions" [value]="osRelease.value">
                    {{osRelease.text}}
                  </mat-option>
                </mat-select>
              <mat-error *ngIf="pricingRequestForm.controls['osRelease'].hasError('required')">
                osRelease required
              </mat-error>
              <span matSuffix></span>
            </mat-form-field>
          </div>
         </div>
        <div class="row"> 

        </div>

        <div class="row">
          <div class="col-12" align="end">
            <button type="submit" mat-raised-button mat-primary (click)="price()"
              [disabled]="!pricingRequestForm?.valid">Run...</button>
          </div>
        </div>
      </fieldset>

    </div>
    <br />
    <div>
      <fieldset>
        <legend>Price</legend>
        <h4>Pricing Result</h4>
        <span style="font-weight:bold; color: red;" *ngIf="![pricing]">{{message}}</span>
        <div *ngIf="pricing">
          <div class="row">
            <div class="col-2">
              <read-only-field [value]='pricing.serviceName' title='Service'></read-only-field>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <read-only-field [value]='pricing.productCode' title='Product'></read-only-field>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <read-only-field [value]='pricing.monthlyFees' title='Monthly Fees'></read-only-field>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <read-only-field [value]='pricing.setupFees' title='Setup Fees'></read-only-field>
            </div>
          </div>

        </div>
      </fieldset>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <!-- <button type="submit" mat-raised-button mat-primary [disabled]="!pricingRequestForm?.valid" (click)="save()">Save</button>
    --><button class="mat-raised-button" (click)="close()">Close</button>
  </mat-dialog-actions>
</mat-card>