import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-estimates-list',
  templateUrl: './service-estimates-list.component.html',
  styleUrls: ['./service-estimates-list.component.scss']
})
export class ServiceEstimatesListComponent implements OnInit {
  @Input() serviceEstimateData: any;
  rowData = [{ id: 0, title: "Service Estimate" }, { id: 1, title: "Change Order" }];
  displayedColumns = ['title', 'seCount', 'inProgress', 'pendingApproval', 'needsSubmission', 'approved', 'pmApproval', 'contractSigned', 'thresholdApproval', 'expired'];

  constructor() { }

  ngOnInit(): void {
  }

  getSEStatusCount(status?: number | null, isChangeOrder?: number | null) {
    if (status != null && isChangeOrder != null)
      return this.serviceEstimateData.filter((x: any) => x.status == status && x.isChangeOrder == isChangeOrder).length;
    else if (isChangeOrder != null)
      return this.serviceEstimateData.filter((x: any) => x.isChangeOrder == isChangeOrder).length;
    else
      return this.serviceEstimateData?.filter((x: any) => x.status == status).length;
  }
}
