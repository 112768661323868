<fieldset>
  <legend>Discovery Environments</legend>
  <div>
    <!-- <div class="align-button" style="float: right">
      <a mat-raised-button color="primary" style="float: right"
        ><mat-icon>add</mat-icon>Environment</a
      >
    </div> -->
    <div *ngIf="environmentList">
      <mat-table
        mat-table
        matSort
        class="full-width-table"
        aria-label="Discovery Environments Table"
        [dataSource]="tableDataSource"
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>								
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.type }}</mat-cell>								
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <ng-container>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editRow(row.id)">
                    <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="addConfig(row.id)">
                    <mat-icon>add</mat-icon>
                  <span>Add/View Configuration</span>
                </button>
                <button mat-menu-item (click)="deleteRow(row.id)">
                  <mat-icon>delete_outline</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </ng-container>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</fieldset>
