<app-breadcrumb></app-breadcrumb>
<mat-card>
  <mat-card-content>
    <div class="col-md-12 hidden-print">
      <nav mat-tab-nav-bar>
        <a mat-tab-link [routerLink]="['project']" routerLinkActive="active-link" routerLinkActiveOptions="{ exact: true }">Projects</a>
        <a mat-tab-link [routerLink]="['templates']" routerLinkActive="active-link"
           routerLinkActiveOptions="{ exact: true }" [attr.disabled]="isSales || isSalesSupport">Templates</a>
        <a mat-tab-link *ngIf="isDirector || isAdmin" [routerLink]="['componentsPendingApproval']" routerLinkActive="active-link"
           routerLinkActiveOptions="{ exact: true }">
          Components Pending Approval
        </a>
        <a mat-tab-link *ngIf="isDirector || isAdmin" [routerLink]="['componentsRateApproval']" routerLinkActive="active-link"
           routerLinkActiveOptions="{ exact: true }">
          Components Rate Approval
        </a>
        <a mat-tab-link *ngIf="isDirector || isAdmin" [routerLink]="['componentsApproved']" routerLinkActive="active-link"
           routerLinkActiveOptions="{ exact: true }">Approved Components</a>
        <a mat-tab-link *ngIf="isProjectManager || isAdmin" [routerLink]="['serviceestimatependingpmoApproval']" routerLinkActive="active-link"
           routerLinkActiveOptions="{ exact: true }">
          Service Estimates Pending PM approval
        </a>
        <a mat-tab-link *ngIf="isAdmin" [routerLink]="['serviceestimatependingThresholdApproval']" routerLinkActive="active-link"
           routerLinkActiveOptions="{ exact: true }">
          Service Estimates Pending Threshold approval
        </a>
      </nav>
    </div>
    <br />
    <router-outlet></router-outlet>
  </mat-card-content>
</mat-card>
