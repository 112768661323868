import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { FleetMangementService } from '../fleet-mangement.service';
import { ReportsService } from '../../reports/reports.service';

@Component({
  selector: 'app-vehicle-reports',
  templateUrl: './vehicle-reports.component.html',
  styleUrls: ['./vehicle-reports.component.scss']
})
export class VehicleReportsComponent implements OnInit {
  selectedReport: number;
  reports: Array<ReportsViewModel>;
  constructor(private router: Router, private reportsService: ReportsService) { }

  reportOption: UntypedFormControl;
  currentRoute: any;
  routeUrlPath: string;
  ngOnInit() {
    // const model: ReportsViewModel = new ReportsViewModel();
    const registrationExpirationReport = new ReportsViewModel();
    const mileageReport = new ReportsViewModel();
    // const expenseReport = new ReportsViewModel();
    const agingReport = new ReportsViewModel();
    const auditReport = new ReportsViewModel();

    auditReport.id = 5;
    auditReport.name = 'Vehicle Audit Report';

    agingReport.id = 4;
    agingReport.name = 'Vehicle Aging Report';

    // expenseReport.id = 3;
    // expenseReport.name = 'Expense Report';

    mileageReport.id = 2;
    mileageReport.name = 'Mileage Report';

    registrationExpirationReport.id = 1;
    registrationExpirationReport.name = 'Registration Expiration Report';

    // model.id = 0;
    // model.name = 'Expense Per Mile';

    this.reportOption = new UntypedFormControl();
    let routeUrl = this.router.url.split("/");
    this.routeUrlPath = routeUrl[routeUrl.length - 1];
    this.currentRoute = this.reportsService.getReportState(this.routeUrlPath);
    this.setData();
    this.reportOption.valueChanges.subscribe(val => {
      this.reportsService.setReportState(this.routeUrlPath, val);
    });

    this.reports = [
      // model,
      registrationExpirationReport,
      mileageReport,
      // expenseReport,
      agingReport,
      auditReport
    ];
  }
  getReportOption(): number {
    return this.reportOption.value;
  }
  setData() {
    this.reportOption.setValue(this.currentRoute);
  }
}
export class ReportsViewModel {
  id: number;
  name: string;
}
