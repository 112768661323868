import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AdminService {

  constructor(private http: HttpClient) { }
  getEmployeeByAzureAdId(azureAdId?: string) {
    return this.http.get<any>(`/api/employees/${azureAdId}/azureId`);
  }
  getCurrentUser() {
    return this.http.get(`/api/admin/currentuser`);
  }
  getRole(id: string) {
    return this.http.get(`/api/admin/roles/${id}`);
  }
  getRoles() {
    return this.http.get<any[]>(`/api/admin/role`);
  }
  updateRole(role: any) {
    const body = JSON.stringify(role);
    return this.http.put(`/api/admin/roles`, body);
  }
  createRole(role: any) {
    const body = JSON.stringify(role);
    return this.http.post(`/api/admin/roles`, body);
  }
  deleteRole(id: string) {
    return this.http.delete(`/api/admin/roles/${id}`);
  }


  getEmployee(id: string) {
    return this.http.get<any>(`/api/admin/employees/${id}`);
  }
  getEmployees() {
    return this.http.get<any[]>(`/api/admin/employees`);
  }
  getAllEmployees() {
    return this.http.get<any[]>(`/api/admin/allEmployees`);
  }
  getInactiveEmployees() {
    return this.http.get<any[]>(`/api/admin/inactiveEmployees`);
  }
  updateEmployee(role: any) {
    const body = JSON.stringify(role);
    return this.http.put(`/api/admin/employees`, body);
  }
  createEmployee(role: any) {
    const body = JSON.stringify(role);
    return this.http.post(`/api/admin/employees`, body);
  }
  deleteEmployee(id: string) {
    return this.http.delete(`/api/admin/employees/${id}`);
  }
  activateEmployee(id: string) {
    return this.http.put(`/api/admin/activateEmployee/${id}`, null);
  }
  getCard(id: string) {
    return this.http.get<any>(`/api/admin/cards/${id}`);
  }
  getCards() {
    return this.http.get<any[]>(`/api/admin/cards`);
  }
  createCard(card: any) {
    const body = JSON.stringify(card);
    return this.http.post(`/api/admin/cards`, body);
  }
  updateCard(card: any) {
    const body = JSON.stringify(card);
    return this.http.put(`/api/admin/cards`, body);
  }
  deleteCard(id: string) {
    return this.http.delete(`/api/admin/cards/${id}`);
  }


  getOilCard(id: string) {
    return this.http.get<any>(`/api/admin/cards/oil/${id}`);
  }
  getOilCards() {
    return this.http.get<any[]>(`/api/admin/cards/oil`);
  }
  createOilCard(card: any) {
    const body = JSON.stringify(card);
    return this.http.post(`/api/admin/cards/oil`, body);
  }
  updateOilCard(card: any) {
    const body = JSON.stringify(card);
    return this.http.put(`/api/admin/cards/oil`, body);
  }
  deleteOilCard(id: string) {
    return this.http.delete(`/api/admin/cards/oil/${id}`);
  }

}
