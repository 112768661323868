import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GraphApiService } from '../../shared/services/graph-api.service';
import { Location } from '@angular/common';
import { AdminService } from '../../admin/admin.service';
import { Employee } from '../employee-list/employee-list-datasource';
import { forkJoin, Observable } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-employee-create',
  templateUrl: './employee-create.component.html',
  styleUrls: ['./employee-create.component.scss']
})
export class EmployeeCreateComponent implements OnInit {
  empFormGroup: UntypedFormGroup;
  filteredOptionEmps: Observable<any[]>;
  azAdEmployees: any[];
  employees: any[];
  approles: any[];
  statusMsg: string;
  isSelected: boolean;
  selectedEmp: any;

  constructor(private fb: UntypedFormBuilder, private graphApiService: GraphApiService, private location: Location, private adminService: AdminService) { }

  ngOnInit(): void {
    this.empFormGroup = this.fb.group({
      'user': ['', Validators.required],
      'appRole': ['', Validators.required]
    });
    let fetchEmployees = this.adminService.getAllEmployees();
    let fetchUsers = this.graphApiService.getUsers();
    forkJoin([fetchEmployees, fetchUsers]).subscribe(results => {
      this.employees = results[0];
      let azAdEmployees = results[1].filter(user => user.givenName != null && user.surname != null && user.accountEnabled != false);
      this.azAdEmployees = azAdEmployees.sort((a, b) => {
        return this.compare(a.givenName, b.givenName);
      });
    });
    this.graphApiService.getApplicationRoles().subscribe(res => {
      this.approles = res;
    });
    this.filteredOptionEmps = this.empFormGroup.controls['user'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  _filter(value: any): any[] {
    this.isSelected = false;
    this.selectedEmp = null;
    const filterValue = value ? value.toLowerCase() : "";
    return this.azAdEmployees?.filter(x => (x.givenName + " " + x.surname).toLowerCase().includes(filterValue)).sort((a, b) => { return a.givenName < b.givenName ? -1 : 1 });
  }

  submitForm() {
    if (this.empFormGroup.valid) {
      const appRole = this.empFormGroup.value.appRole;
      let employee = this.employees.find(x => x.azureAdId == this.selectedEmp.id);
      if (!employee) {
        let newEmployee: Employee = { firstName: this.selectedEmp.givenName, lastName: this.selectedEmp.surname, email: this.selectedEmp.mail, azureAdId: this.selectedEmp.id, userName: this.selectedEmp.givenName + "." + this.selectedEmp.surname };
        this.adminService.createEmployee(newEmployee).subscribe(res => {
          this.assignUserToApplication(this.selectedEmp, appRole)
        });
      } else {
        this.assignUserToApplication(this.selectedEmp, appRole);
      }
    }
  }
  goBack() {
    this.location.back();
  }
  getEmployees() {
    this.adminService.getAllEmployees().subscribe(res => {
      this.employees = res;
    })
  }
  isDisabled(azAdEmployee: any) {
    let emp = this.employees.filter(emp => emp.azureAdId == azAdEmployee.id && emp.softDelete == true);
    if (emp?.length > 0) {
      return true;
    }
    else
      return false;
  }
  assignUserToApplication(user: any, appRole: any) {
    this.graphApiService.assignUserToApplication(user.id, appRole.id)
      .subscribe(result => {
        this.location.back();

      },
        (error) => {
          this.getEmployees();
          if (error?.status == 400) {
            this.statusMsg = "User '" + user.givenName + " " + user.surname + "' is already assigned to the role '" + appRole.displayName + "'";
          } else
            this.statusMsg = "Unknown error while saving the data";
        });
  }
  compare(a: string | number, b: string | number) {
    return (a < b ? -1 : 1);
  }
  selectedEmployee(event: any) {
    this.isSelected = event.option.selected;
    this.selectedEmp = this.azAdEmployees.find(x => x.givenName + " " + x.surname == this.empFormGroup.value.user);
  }
}
