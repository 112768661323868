import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { CloudMigrationToolAdminService } from '../cloud-migration-tool-admin.service';

@Component({
  selector: 'app-cloud-provider-pricing-schedule',
  templateUrl: './cloud-provider-pricing-schedule.component.html',
  styleUrls: ['./cloud-provider-pricing-schedule.component.scss','../../dialog.scss'],
})
export class CloudProviderPricingScheduleComponent implements OnInit {
  formTitle: string;
  pricingScheduleForm: UntypedFormGroup;
  cloudProviderId: any;
  pricingSchedule: any;
  message: any;
  hostedSystems: any;
  terms: any;
  oSVersions: any;

  constructor(
    private service: CloudMigrationToolAdminService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CloudProviderPricingScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.pricingScheduleForm = this.createFormGroup(this.data);
    this.formTitle = 'Pricing Schedule';
    this.cloudProviderId = this.data?.cloudProviderId;
    this.hostedSystems = this.data?.hostedSystemOptions;
    this.terms = this.data?.termOptions;
  }
  
  createFormGroup(data: any): UntypedFormGroup {
    if (data) {
      const row = data.row;
      console.log("data.row: ", row);

      return this.fb.group({
        id: new UntypedFormControl(data?.id),
        hostedSystem: new UntypedFormControl(data?.hostedSystem),
        serviceName: new UntypedFormControl(data?.serviceName),
        partitionCosts: new UntypedFormControl(Number(data?.partitionCosts) ? parseFloat(data?.partitionCosts).toFixed(2) : ''),
        hostingLaborCosts: new UntypedFormControl(Number(data?.hostingLaborCosts) ? parseFloat(data?.hostingLaborCosts).toFixed(2) : ''),
        dRlaborCosts: new UntypedFormControl(Number(data?.dRlaborCosts) ? parseFloat(data?.dRlaborCosts).toFixed(2) : ''),
        haLaborCosts: new UntypedFormControl(Number(data?.haLaborCosts) > 0 ? parseFloat(data?.haLaborCosts).toFixed(2) : ''),
        setUpCosts: new UntypedFormControl(Number(data?.setUpCosts) > 0 ? parseFloat(data?.setUpCosts).toFixed(2) : ''),
        productCode: new UntypedFormControl(row?.productCode),
        v6R1GpRate: new UntypedFormControl(row?.v6R1GpRate),
        v5R4GpRate: new UntypedFormControl(row?.v5R4GpRate),
        diskUnitPrice : new UntypedFormControl((row?.diskUnitPrice)),
        processingUnitPrice : new UntypedFormControl((row?.processingUnitPrice)),
        memoryUnitPrice : new UntypedFormControl((row?.memoryUnitPrice)>0?(row?.memoryUnitPrice).toFixed(2):''),
        defaultTargetPercent:  new UntypedFormControl(row?.defaultTargetPercent),
        year1GpPercentPercent : new UntypedFormControl(row?.defaultTargetPercent),
        year2GpPercentPercent : new UntypedFormControl(row?.defaultTargetPercent),
        year3GpPercentPercent : new UntypedFormControl(row?.defaultTargetPercent),
        year4GpPercentPercent : new UntypedFormControl(row?.defaultTargetPercent),
        year5GpPercentPercent : new UntypedFormControl(row?.defaultTargetPercent),

      });
    }
    return this.fb.group({
      id: [''],
      hostedSystem: [''],
      serviceName: [''],
      productCode:  [''],
      v6R1GpRate:   [''],
      v5R4GpRate:   [''],      
      partitionCosts: [''],
      hostingLaborCosts: [''],
      dRlaborCosts: [''],
      haLaborCosts: [''],
      setUpCosts: [''],
      diskUnitPrice: [''],
      processingUnitPrice: [''],
      memoryUnitPrice: [''],
      defaultTargetPercent: [''],
      year1GpPercentPercent : [''],
      year2GpPercentPercent : [''],
      year3GpPercentPercent : [''],
      year4GpPercentPercent : [''],
      year5GpPercentPercent : [''],
    });
  }

  save() {
    let formVal = this.pricingScheduleForm.value;
    
    this.pricingSchedule = {
      id: formVal?.id==''?'00000000-0000-0000-0000-000000000000': formVal?.id,
      hostedSystem: formVal?.hostedSystem,
      serviceName: formVal?.serviceName,
      productCode: formVal?.productCode,
      v6R1GpRate: Number(formVal?.v6R1GpRate),
      v5R4GpRate: Number(formVal?.v5R4GpRate),
      partitionCosts: Number(formVal?.partitionCosts),
      hostingLaborCosts: Number(formVal?.hostingLaborCosts),
      dRlaborCosts: Number(formVal?.dRlaborCosts),
      haLaborCosts: Number(formVal?.haLaborCosts),
      setUpCosts: Number(formVal?.setUpCosts),
      diskUnitPrice: Number(formVal?.diskUnitPrice),
      processingUnitPrice: Number(formVal?.processingUnitPrice),
      memoryUnitPrice: Number(formVal?.memoryUnitPrice),
      defaultTargetPercent: Number(formVal?.defaultTargetPercent),
      year1GpPercentPercent: Number(formVal?.year1GpPercentPercent),
      year2GpPercentPercent: Number(formVal?.year2GpPercentPercent),
      year3GpPercentPercent: Number(formVal?.year3GpPercentPercent),
      year4GpPercentPercent: Number(formVal?.year4GpPercentPercent),
      year5GpPercentPercent: Number(formVal?.year5GpPercentPercent),

  }

    let request = {
      cloudProviderId: this.cloudProviderId,
      payload: this.pricingSchedule,
    };
    this.service.saveCloudProviderPricingSchedule(request).then((res: any) => {
      this.pricingSchedule = res.data.result;
      this.message = res.data.message;

      console.log('got it: ', this.pricingSchedule);
    });
  }

  close() {
    this.dialogRef.close(this.pricingScheduleForm.value);
  }
}
