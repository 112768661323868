import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountInfo } from '@azure/msal-common';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { RoleService } from '../shared/services/role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReleaseNotesService } from '../shared/services/release-notes.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  email: any;

  loginDisplay = true;
  currentUser: AccountInfo | null;

  isSuperAdmin: boolean;
  isFleetManagementUser: boolean;
  hasVisited: boolean;
  isServiceEstimateUser: boolean;
  isDirector: boolean;
  hasBusinessPartnerAccess: boolean;
  hasClientAccess: boolean;
  isOfferLetterAccess: boolean;
  currentRelease: any;
  isDashboardViewEnabled: boolean;

  constructor(
    private http: HttpClient,
    // private authService: AuthService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private roleService: RoleService,
    private router: Router,
    private route: ActivatedRoute,
    private releaseNotesService: ReleaseNotesService
    ) { }


  ngOnInit() {

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: any) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.currentUser = this.authService.instance.getActiveAccount();
        this.secondRoleCheck();
      });
    this.checkDashboardViewEnabled();
    this.roleCheckFunction();
    this.getCurrentIteration();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        // if (this.loginDisplay) {
        //   this.secondRoleCheck();
        // }

      });



  }
  checkDashboardViewEnabled() {
    let isEnabledDashboardView;
    var dashboardViewStorageValue = localStorage.getItem('isDashboardViewEnabled');
    if (dashboardViewStorageValue) {
      isEnabledDashboardView = JSON.parse(dashboardViewStorageValue);
    }
    if (isEnabledDashboardView)
      this.isDashboardViewEnabled = isEnabledDashboardView['isDashboardViewEnabled'];
  }
  sendEmail() {
    const body = JSON.stringify(this.email);
    this.http.post('/api/email', body)
      .subscribe(res => {
        console.log(res);
      });
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.authService.instance.getActiveAccount(), true)
  }
  roleCheckFunction() {
    let idTokenClaims = this.roleService.idToken();

    let roles: string[] | string;
    if (idTokenClaims) {
      roles = idTokenClaims['roles'];

      let updatedRoles: string[];
      if (typeof roles === 'string') {
        updatedRoles = [
          roles
        ];
      } else {
        updatedRoles = roles;
      }
      updatedRoles.forEach(r => {
        if (r.includes('Fleet')) {
          this.isFleetManagementUser = true;

        } if (r.includes('PortalSEPostsalesEngineerSA')) {
          this.isServiceEstimateUser = true;
        }
        if (r.includes('BusinessOperationsSpecialist')) {
          this.hasBusinessPartnerAccess = true;
        }
        if (r.includes('ProfessionalServiceCoordinator')) {
          this.hasBusinessPartnerAccess = true;
          this.isServiceEstimateUser = true;
          this.hasClientAccess = true;
        }
        if (r.includes('SE')) {
          this.isServiceEstimateUser = true;
          this.hasBusinessPartnerAccess = true;
        } if (r.includes('PortalAdmin')) {
          this.isFleetManagementUser = true;
          this.isServiceEstimateUser = true;
          this.isSuperAdmin = true;
          this.hasBusinessPartnerAccess = true;
          this.isOfferLetterAccess = true;
        } if (r.includes('PortalSEDirector')) {
          this.hasBusinessPartnerAccess = true;
          this.isDirector = true;
        } if (r.includes('PortalSEServiceOperations')) {
          this.hasBusinessPartnerAccess = true;
        } if (r.includes('PortalSEProjectManager')) {
          this.hasBusinessPartnerAccess = true;
        } if (r.includes('PortalSEPresalesArchitect')) {
          this.hasBusinessPartnerAccess = true;
        } if (r.includes('PortalSEPostsalesEngineer')) {
          this.hasBusinessPartnerAccess = true;
        } if (r.includes('PortalSEServiceOperations')) {
          this.isServiceEstimateUser = true;
          this.hasBusinessPartnerAccess = true;
        } if (r.includes('PortalOfferLetterUser')) {
          this.isOfferLetterAccess = true;
        } if (r.includes('FieldServiceCoordinator')) {
          this.hasBusinessPartnerAccess = true;
        }
      });
    }
  }
  secondRoleCheck() {
    this.isDirector = this.hasRole('PortalSEDirector');
    this.isServiceEstimateUser = false;
    let localStorageVisited;
    localStorageVisited = localStorage.getItem('hasVisited');
    if (localStorageVisited) {
      this.hasVisited = true;
    } else {
      this.hasVisited = false;
      localStorage.setItem("hasVisited", "true");
    }
    this.email = {};
    this.isSuperAdmin = false;
    this.isSuperAdmin = this.hasRole('PortalAdmin');
    this.isFleetManagementUser = this.hasRole('FleetManagementUser');
    if (!this.isFleetManagementUser) {
      this.isFleetManagementUser = this.hasRole('FleetMangementAdmin');
      if (!this.isFleetManagementUser) {
        this.isFleetManagementUser = this.hasRole('PortalAdmin');
      }
    }
    if (!this.isSuperAdmin) {
      this.isSuperAdmin = this.hasRole('PortalAdmin');
    }
    if (this.hasRole('Projects.Approver')) {
      this.isServiceEstimateUser = true;
    }
    if (this.hasRole("PortalAdmin")) {
      this.isServiceEstimateUser = true;
    }
    let idTokenClaims = this.authService.instance.getActiveAccount()?.idTokenClaims;

    let roles: any[] | any = [];
    type IdTokenClaims  = {
      roles: string[]
  };
  
    if (idTokenClaims) {
      roles = (idTokenClaims as IdTokenClaims).roles;
    }
    if (roles.includes('SE')) {
      this.isServiceEstimateUser = true;
    } if (roles.includes('PortalSEDirector')) {
      this.isServiceEstimateUser = true;
      this.isDirector = true;
    } if (roles.includes('PortalSEPostsalesEngineerSA')) {
      this.isServiceEstimateUser = true;
    } if (roles.includes('PortalSEPostsalesEngineer')) {
      this.isServiceEstimateUser = true;
    } if (roles.includes('PortalSEPresalesArchitect')) {
      this.isServiceEstimateUser = true;
    }
    if (roles.includes('PortalSEServiceOperations')) {
      this.isServiceEstimateUser = true;
      this.hasBusinessPartnerAccess = true;
    } if (roles.includes('ProfessionalServiceCoordinator')) {
      this.isServiceEstimateUser = true;
      this.hasBusinessPartnerAccess = true;
      this.hasClientAccess = true;
    } if (roles.includes('FieldServiceCoordinator')) {
      this.hasBusinessPartnerAccess = true;
    }
  }
  getCurrentIteration() {
    this.releaseNotesService.getCurrentReleaseInfo().subscribe((res: any) => {
      this.currentRelease = res;
    })
  }
}
