<button
  id="btn-close-icon"
  mat-icon-button
  class="close-button"
  (click)="close()"
>
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div>
  <h3
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    {{ formTitle }}
  </h3>
</div>
<p></p>
<div >
  <cloud-migration-environment-add *ngIf="environment" id="add-edit-env"
    [environment]="environment"
    [message]="responseMessage"
	  (addEnvironmentEvent)="onAddEnvironment($event)"
	  (editEnvironmentEvent)="onEditEnvironment($event)"
    >
  ></cloud-migration-environment-add>
  <div>
    <span style="font-weight: bold; color:red">{{responseMessage}}</span>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-dialog-actions align="end">
      <button id="btn-close-dialog" class="mat-raised-button" (click)="close()">
        Close
      </button>
      <button
        id="btn-save-discovery-environment"
        class="mat-raised-button mat-primary"
        (click)="save()"
      >
        Save
      </button>
    </mat-dialog-actions>
  </div>
  <div style="width: 1%;"></div>
</div>
