<mat-card>
  <mat-card-content>
    <nav mat-tab-nav-bar ngClass="tabs" class="bckgnd-color" *ngIf="projectId">
      <a mat-tab-link [routerLink]="['details']" routerLinkActive="active-link">Details</a>
      <a mat-tab-link [routerLink]="['serviceestimates']" routerLinkActive="active-link">Service Estimates</a>
      <a mat-tab-link [routerLink]="['migrationEnvironments']" routerLinkActive="active-link">Discovery Environments</a>
      <a mat-tab-link [routerLink]="['discoveries']" routerLinkActive="active-link">Discoveries</a>
      <a mat-tab-link [routerLink]="['analyses']" routerLinkActive="active-link">Analysis</a>
      <a mat-tab-link [routerLink]="['assessments']" routerLinkActive="active-link">Assessments</a>

    </nav>
    <nav mat-tab-nav-bar ngClass="tabs" class="bckgnd-color" *ngIf="!projectId">
      <a mat-tab-link routerLink="./" routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }">Create</a>

    </nav>
    <router-outlet></router-outlet>
  </mat-card-content>
</mat-card>
