import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cloud-migration-analysis',
  template: '<router-outlet></router-outlet>',
})
export class CloudMigrationAnalysisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
