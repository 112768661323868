<div class="container-fluid">
    <mat-card>
        <mat-card-content>
            <a id="newInsurancePolicyCreate" mat-raised-button [routerLink]="['create']" color="primary"
                matTooltip="Create New Insurance Policy" [matTooltipPosition]="'right'">
                <mat-icon class="add-icon">add</mat-icon>
                Insurance Policy
            </a>
            <br>
            <mat-table #table [dataSource]="insurances">
                <ng-container matColumnDef="vehicleInsuranceState">
                    <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.vehicleInsuranceCompanyName}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="vehicleInsurancePolicyNumber">
                    <mat-header-cell *matHeaderCellDef> Policy Number </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.vehicleInsurancePolicyNumber}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="vehicleInsuranceEndDate">
                    <mat-header-cell *matHeaderCellDef> Insurance End Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.vehicleInsuranceEndDate | date:'shortDate'}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="vehicleInsuranceDaysRemaining">
                    <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-icon-button id="kebabMenu" [matMenuTriggerFor]="menu"
                            aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['details',element.vehicleInsuranceId]">
                                <mat-icon> edit </mat-icon>
                                <Span> Details/Edit </Span>
                            </button>
                            <button mat-menu-item (click)="deleteInsurance(element.vehicleInsuranceId)">
                                <mat-icon> delete_outline </mat-icon>
                                <Span> Delete </Span>
                            </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>
</div>