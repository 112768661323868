import { DataSource } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
export class VehicleUsageModelDataSource extends DataSource<any> {
    data: Array<any>;
    paginator: MatPaginator;
    sort: MatSort;

    constructor() {
        super();
    }
    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    connect(): Observable<any[]> {
        // Combine everything that affects the rendered data into one update
        // stream for the data-table to consume.
        if(this.paginator && this.sort) {
            const dataMutations = [
                observableOf(this.data),
                this.paginator.page,
                this.sort.sortChange
            ];
    
            return merge(...dataMutations).pipe(map(() => {
                return this.getPagedData(this.getSortedData([...this.data]));
            }));
        }
        return observableOf(this.data);
    }

    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    disconnect() { }
    setData(data: any) {
        this.data = data;
    }

    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getPagedData(data: any[]) {
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        return data.splice(startIndex, this.paginator.pageSize);
    }

    /**
     * Sort the data (client-side). If you're using server-side sorting,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getSortedData(data: any[]) {
        if (!this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const isAsc = this.sort.direction === 'asc';
            switch (this.sort.active) {
                case 'vehicleUsageTripMileage': return compare(a.vehicleUsageMileageEnd, b.vehicleUsageMileageEnd, isAsc);
                case 'vehicleUsageDate': return compare(a.vehicleUsageDate, b.vehicleUsageDate, isAsc);
                default: return 0;
            }
        });
    }

}
/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: number | Date, b: number | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

