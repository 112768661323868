import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentsDataSource } from '../models/documents-data-source';
import { DocumentCreatorService } from '../document-creator.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';

@Component({
  selector: 'app-document-creator-list',
  templateUrl: './document-creator-list.component.html',
  styleUrls: ['./document-creator-list.component.scss']
})
export class DocumentCreatorListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  displayedColumns = [
    'title',
    'description',
    'module'
  ];
  documents: DocumentsDataSource;
  constructor(private router: Router, private service: DocumentCreatorService) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.service.getDocuments()
      .subscribe(res => {
        this.documents = new DocumentsDataSource(res);
        this.documents.paginator = this.paginator;
        this.documents.sort = this.sort;
        this.table.dataSource = this.documents;
        // this.documents.setData(res);
      });
  }
  gotoDocument(row: any) {
    this.router.navigate(['documentcreator', 'details', row.id]);
  }

}
