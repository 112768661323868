<div class="row" style="margin-bottom:15px;">
  <div class="col-md-3">
    <input type="file" #file placeholder="Choose file" [(ngModel)]="fileInput" accept={{fileExtension}} (change)="uploadFile(file.files)" style="display:none;">
    <a mat-raised-button color="primary" (click)="file.click()"><mat-icon>upload</mat-icon>{{title}}</a>
  </div>
  <div class="col-md-4">
    <span class="upload" *ngIf="progress > 0">
      {{progress}}%
    </span>
    <span class="upload" *ngIf="message">
      {{message}}
    </span>
  </div>
</div>
