export class VehicleGasCard {
    vehicleGasCardId: string;
    vehicleGasCardNumber: string;
    vehicleGasCardExpirationDate: Date;
    assignedToEmployeeId: string;
}

export class VehicleOilCard {
  vehicleOilCardId: string;
  vehicleOilCardNumber: string;
  vehicleOilCardExpirationDate: Date;
  assignedToEmployeeId: string;
}

export enum CardType {
    GAS,
    OIL
}
