import { Component, OnInit } from '@angular/core';

import { AdminService } from '../../admin.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.scss']
})
export class RoleCreateComponent implements OnInit {
  role: any;

  constructor(private adminService: AdminService, private location: Location) { }

  ngOnInit() {
  }
  createRole() {
    this.adminService.createRole(this.role)
      .subscribe(httpResult => {
          this.location.back();
        
      })
  }

}
