import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { VehicleInsurance, VehicleCreateInsurance } from '../../../models/fleet-management-models';
import { FleetMangementService } from '../../../fleet-mangement.service';

@Component({
  selector: 'app-vehicle-insurance-detail',
  templateUrl: './vehicle-insurance-detail.component.html',
  styleUrls: ['./vehicle-insurance-detail.component.scss']
})
export class VehicleInsuranceDetailComponent implements OnInit {
  @Input() insurance: VehicleCreateInsurance;
  tempInsurance: VehicleCreateInsurance;
  isEditing: boolean;
  updateMessage: string;
  insuranceForm: UntypedFormGroup;
  constructor(private route: ActivatedRoute, private fleetService: FleetMangementService, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.isEditing = false;
    if (this.insurance) {

    } else {
      this.route.params
        .subscribe(params => {
          const id = params['id'];
          this.fleetService.getVehicleInsuranceRecordById(id)
            .subscribe(insuranceResult => {
              this.insurance = insuranceResult;

              this.tempInsurance = new VehicleCreateInsurance();
              Object.assign(this.tempInsurance, this.insurance);
              this.insuranceForm = this.fb.group({
                'vehicleInsuranceDaysRemaining': [this.insurance.vehicleInsuranceDaysRemaining, Validators.required],
                'vehicleInsuranceCompanyName': [this.insurance.vehicleInsuranceCompanyName, Validators.required],
                'vehicleInsurancePolicyNumber': [this.insurance.vehicleInsurancePolicyNumber, Validators.required],
                'vehicleInsuranceState': [this.insurance.vehicleInsuranceState, Validators.required],
                'vehicleInsuranceStartDate': [this.insurance.vehicleInsuranceStartDate, Validators.required],
                'vehicleInsuranceEndDate': [this.insurance.vehicleInsuranceEndDate, Validators.required]
              });

            });
        });

    }
  }
  toggleEdit(toggleType?: string) {
    if (toggleType === 'edit') {

    } else {
      Object.assign(this.insurance, this.tempInsurance);
    }
    this.isEditing = !this.isEditing;
  }
  updateInsurance() {
    if (this.insuranceForm.valid) {
      this.fleetService.updateVehicleInsuranceRecord(this.insurance)
        .subscribe(insuranceUpdate => {
          this.updateMessage = 'The record was updated successfully';
        },
          (error) => {
            this.updateMessage = 'There was an error updating the record please try again and if the error continues contact support';
          });
    }
  }

  addFileToModel(event: any) {
    this.insurance.insurancePhoto = event.target.files[0];
  }

  getMinDate() {
    let startDate = this.insuranceForm.value['vehicleInsuranceStartDate'];
    return startDate;
  }

  getMaxDate() {
    let endDate = this.insuranceForm.value['vehicleInsuranceEndDate'];
    return endDate;
  }
}
