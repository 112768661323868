<mat-card>

  <mat-card-content>
    <h2>Projects</h2>
    <button mat-raised-button [routerLink]="['create']" [disabled]="!canCreateProject" id="projectCreate"
            color="primary" matTooltip="Create Project"
                    [matTooltipPosition]="'right'">
      <mat-icon>add</mat-icon>
      Project
    </button>
    <app-professional-services-project-list [isCmtProj]="isCmtProj"></app-professional-services-project-list>
  </mat-card-content>
</mat-card>
