<mat-card>
  <h1>Components</h1>
  <mat-card-content *ngIf="serviceEstimate">
    <h2>SE Components</h2>
    <button [disabled]="serviceEstimate.isLocked" mat-raised-button color="primary"
      [routerLink]="['createComponent']">Create
      Service Estimate(SE)
      Component</button>
    <button id='createFromTemplateBtn' [disabled]="serviceEstimate.isLocked" mat-raised-button color="primary" (click)="createServiceEstimateFromTemplate()">Create From Template</button>
    <br>
    <app-service-estimate-component-list></app-service-estimate-component-list>
  </mat-card-content>
</mat-card>
