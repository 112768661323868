import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FleetMangementService } from '../../fleet-mangement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { VehicleUsageFull, VehicleUsageEditViewModel } from '../../models/vehicle-useage-model';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-vehicle-mileage-update',
  templateUrl: './vehicle-mileage-update.component.html',
  styleUrls: ['./vehicle-mileage-update.component.scss']
})
export class VehicleMileageUpdateComponent implements OnInit {
  mileageForm: UntypedFormGroup;
  mileage: VehicleUsageFull;
  initalMileage: VehicleUsageFull;
  editViewModel: VehicleUsageEditViewModel;
  isUpdateError: boolean;
  isSubmitAvailable: boolean;

  constructor(private fb: UntypedFormBuilder, private fleetService: FleetMangementService, private router: Router, private location: Location, private route: ActivatedRoute) { }

  ngOnInit() {
    this.isSubmitAvailable = false;
    this.isUpdateError = false;
    this.route.params
      .subscribe(routeParams => {
        const id = routeParams['id'];
        if (id) {
          this.fleetService.getVehicleMileageById(id)
            .subscribe(mileageResult => {
              const returnedMileage = mileageResult;
              if (returnedMileage) {
                this.mileage = returnedMileage;
                this.initalMileage = new VehicleUsageFull();
                this.editViewModel = new VehicleUsageEditViewModel();
                Object.assign(this.initalMileage, this.mileage);
                Object.assign(this.editViewModel, this.mileage);
                this.editViewModel.vehicleIncorrectEndMileage = this.editViewModel.vehicleUsageMileageEnd;
                this.mileageForm = this.fb.group({
                  'incorrectMileage': [{ value: this.editViewModel.vehicleIncorrectEndMileage, disabled: true }],
                  'mileageToUpdate': [this.mileage.vehicleUsageMileageEnd, Validators.min(this.mileage.vehicleUsageMileageStart)],
                  'vehicleStartingMileage': [{ value: this.mileage.vehicleUsageMileageStart, disabled: true }]
                });
                this.mileageForm.controls['mileageToUpdate'].valueChanges
                  .pipe(debounceTime(500))
                  .subscribe(updatedValue => {
                    if (this.mileage.vehicleUsageMileageEnd === this.editViewModel.vehicleUsageMileageEnd) {
                      this.isSubmitAvailable = false;
                    } else {
                      if (!this.mileageForm.valid) {
                        this.isSubmitAvailable = false;
                      } else {
                        this.isSubmitAvailable = true;
                      }
                    }
                    this.mileage.vehicleUsageTripMileage = this.mileage.vehicleUsageMileageEnd - this.mileage.vehicleUsageMileageStart;
                  });

              }
            });
        }
      });
  }
  onUndo(undoEvent: any) {
    Object.assign(this.mileage, this.initalMileage);
  }
  updateMileage(updateMileageEvent: any) {
    if (this.initalMileage.vehicleUsageMileageEnd !== this.mileage.vehicleUsageMileageEnd) {
      this.fleetService.updateVehicleMileage(this.mileage)
        .subscribe(mileageResponse => {
          this.location.back();
        },
          (error) => {
            this.isUpdateError = true;
          });
    }
  }

}
