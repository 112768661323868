import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AdminService } from '../../admin.service';
import { CardType, VehicleGasCard, VehicleOilCard } from '../../models/gas-card';
import { Employee } from '../../models/employee';

@Component({
  selector: 'app-create-card',
  templateUrl: './create-card.component.html',
  styleUrls: ['./create-card.component.scss']
})
export class CreateCardComponent implements OnInit {
  gasCard: VehicleGasCard;
  oilCard: VehicleOilCard;
  cardTypes: CardType;
  employees: Employee[];
  showError: boolean;
  isGasCard: boolean;
  isOilCard: boolean;

  constructor(private location: Location, private adminService: AdminService) { }

  ngOnInit() {
    this.showError = false;
    this.isGasCard = false;
    this.isOilCard = false;
    this.adminService.getEmployees()
      .subscribe(httpResult => {
        this.employees = httpResult;
      },
        (error) => {
          this.showError = error;
        });
  }
  createCard() {

    const cardType = Number.parseInt(this.cardTypes.toString());
    if (cardType === CardType.GAS) {
      this.adminService.createCard(this.gasCard)
        .subscribe(httpResponse => {
          this.location.back();

        });
    } else {
      this.adminService.createOilCard(this.oilCard)
        .subscribe(httpResult => {
          this.location.back();

        });
    }
  }
  updateView(event: any) {
    const cardType = Number.parseInt(this.cardTypes.toString());
    if (cardType === CardType.GAS) {
      this.gasCard = new VehicleGasCard();
      this.isGasCard = true;
      this.isOilCard = false;
    } else {
      this.oilCard = new VehicleOilCard();
      this.isOilCard = true;
      this.isGasCard = false;
    }
  }

}
