<mat-card>
  <mat-card-content>
    <mat-tab-group *ngIf="displayData">
      <mat-tab label="Details">
        <app-project-create-form [project]="project" #createForm (isBPScoreCardOpenPopup)="openBusinessPartnerScorecard()" [businessPartnerItems]="businessPartnerItems" [projectCanBeClosed]="projectCanBeClosed"></app-project-create-form>
      </mat-tab>

      <mat-tab *ngIf="projectId" label="Documents">
        <h3>SOW</h3>
        <mat-list>
          <mat-list-item *ngFor="let document of project.projectDocuments | projectDocumentFilter:0">
            <div mat-line>{{document.name}} </div>
            <mat-icon class="click-cursor" (click)="loadFile(document.documentUri)">search</mat-icon>
            <mat-icon class="click-cursor" (click)="deleteDocument(document.id)">clear</mat-icon>
          </mat-list-item>
        </mat-list>
        <h3>Bp Quote</h3>
        <mat-list>
          <mat-list-item *ngFor="let document of project.projectDocuments | projectDocumentFilter:1">
            <div mat-line>{{document.name}} </div>
            <mat-icon class="click-cursor" (click)="loadFile(document.documentUri)">search</mat-icon>
            <mat-icon class="click-cursor" (click)="deleteDocument(document.id)">clear</mat-icon>
          </mat-list-item>
        </mat-list>
        <h3>BOM</h3>
        <mat-list>
          <mat-list-item *ngFor="let document of project.projectDocuments | projectDocumentFilter:2">
            <div mat-line>{{document.name}} </div>
            <mat-icon class="click-cursor" (click)="loadFile(document.documentUri)">search</mat-icon>
            <mat-icon class="click-cursor" (click)="deleteDocument(document.id)">clear</mat-icon>
          </mat-list-item>
        </mat-list>
      </mat-tab>

      <mat-tab *ngIf="projectId && businessPartnerItems?.length > 0" label="Scorecards">
        <div class="row">

          <div class="col-md-12" *ngIf="scorecardDatasource">
            <h2>
              Scorecards
              <span *ngIf="!isDirector">
                <br /><mat-hint id="hint">
                  To delete a scorecard that was created in error, please contact a LOB Director
                </mat-hint>
              </span>
            </h2>
            <mat-table [dataSource]="scorecardDatasource">
              <ng-container matColumnDef="businessPartner">
                <mat-header-cell *matHeaderCellDef> Business Partner </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.businessPartner.businessPartnerLegalName}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="solutionPillar">
                <mat-header-cell *matHeaderCellDef> Solution Pillar </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.solutionPilar.solutionPilarName}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.createdByEmployee.firstName}}
                  {{row.createdByEmployee.lastName}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="createdDate">
                <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.createdDate | date}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <button id="kebabMenu" [attr.data-id]="row.id" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item color="accent" type="button" (click)="openBusinessPartnerScorecardEdit(row)">
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                    <button mat-menu-item color="accent" type="button" (click)="openBusinessPartnerScorecardWithData(row)">
                      <mat-icon>search</mat-icon>
                      <span>Search</span>
                    </button>
                    <button mat-menu-item color="warn" type="button" [disabled]="!isDirector" (click)="deleteScoreCard(row.id)" id="deleteScoreCard">
                      <mat-icon>delete_outline</mat-icon>
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
