<div *ngIf="serviceEstimatesList"><form [formGroup]="pricingFormGroup">
  <fieldset>
    <legend>
      Price Information
    </legend>
    <div class="row">
      <div class="col-6">
    <mat-card>
      <mat-card-title class="mat-card-title-large">Migration Costs</mat-card-title>
      <mat-card-content>
          <div class="row">
            <mat-form-field style="min-width: 300px;">
              <mat-label>Service Estimate</mat-label>
              <mat-select formControlName="selectedServiceEstimate">
                <mat-option *ngFor="let serviceEstimate of serviceEstimatesList" [value]="serviceEstimate.id">
                  {{serviceEstimate.name + " (Price: " + (serviceEstimate.totalCost | currency) + " )"}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Managed Services Percentage(%)</mat-label>
              <input matInput type="number" min="0" max="1" step=".05"
                     formControlName="managedServicePercent">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field>
              <mat-label>Data Transfer Times</mat-label>
              <mat-select formControlName="selectedNetworkProfile">
                <mat-option *ngFor="let networkProfile of networkProfiles" [value]="networkProfile.value">
                  {{networkProfile.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <mat-form-field>
              <mat-label>VPN Provider</mat-label>
              <mat-select formControlName="selectedVpnProvider">
                <mat-option *ngFor="let vpnProvider of vpnProviders" [value]="vpnProvider.value">
                  {{vpnProvider.text}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->

          </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-6">
    <mat-card>
      <mat-card-title  class="mat-card-title-large">Platform Costs</mat-card-title>
      <mat-card-content>
        <mat-form-field style="min-width:300px">
          <mat-label>Region</mat-label>
          <mat-select formControlName="selectedRegion">
            <mat-option *ngFor="let region of regions" [value]="region.value">{{region.text}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Target Network Bandwidth</mat-label>
          <input matInput type="number" min="0" max="32768" formControlName="bandwidthAmount" list="bandwidthGigValues" autocomplete="off">
          <datalist id="bandwidthGigValues">
            <option value="2">
            <option value="4">
            <option value="8">
            <option value="16">
            <option value="32">
            <option value="64">
            <option value="128">
            <option value="256">
            <option value="512">
            <option value="1024">
            <option value="2048">
            <option value="4096">
            <option value="8192">
            <option value="16384">
            <option value="32768">
          </datalist>
          <span matSuffix>GB/mo</span>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Storage Amount(GB)</mat-label>
          <input matInput type="number" min="0" max="32768" list="defaultGigValues" formControlName="storageAmount" autocomplete="off">
          <datalist id="defaultGigValues">
            <option value="2">
            <option value="4">
            <option value="8">
            <option value="16">
            <option value="32">
            <option value="64">
            <option value="128">
            <option value="256">
            <option value="512">
            <option value="1024">
            <option value="2048">
            <option value="4096">
            <option value="8192">
            <option value="16384">
            <option value="32768">
          </datalist>
          <span matSuffix>GB</span>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Storage Type</mat-label>
          <mat-select formControlName="selectedStorageType">
            <mat-option *ngFor="let storageType of storageTypes" [value]="storageType.value">
              {{storageType.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>License Cost($/Mo.)</mat-label>
          <input id="licenseCost" matInput type="number" formControlName="licenseCost">
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </div>
  </div>
  </fieldset>
</form>
</div>
