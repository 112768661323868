import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'cloud-migration-contract-preview',
  templateUrl: './cloud-migration-contract-preview.component.html',
  styleUrls: [
    '../../../dialog.scss',
    './cloud-migration-contract-preview.component.scss',
  ],
})
export class CloudMigrationContractPreviewComponent implements OnInit {
  @ViewChild('donwloadExcelLink') donwloadExcelLink: ElementRef;
    formTitle: string = 'Contract Preview';

  contract: any;
  recurringFees: any;
  oneTimeFees: any;
  invoicingFrequencyOptions: any;
  paymentRequirementOptions: any;
  newOrRenewalOptions: any;
  contractPreviewForm: UntypedFormGroup;
  assessmentId: any;
  customerName: any;
  quotedBy: any;
  accountManager: any;
  contractTermOptions: any;
  message: any;
  contractGenerated: any;

  constructor(
    private service: CloudMigrationToolService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CloudMigrationContractPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    console.log('requestData: ', this.data);
    this.assessmentId = this.data.assessmentId;
    this.customerName = this.data.customerName;
    this.quotedBy = this.data.quotedBy;
    this.accountManager = this.data.accountManager;
    this.invoicingFrequencyOptions = this.data?.invoicingFrequencyOptions;
    this.contractTermOptions = this.data?.contractTermOptions;
    this.paymentRequirementOptions = this.data?.paymentRequiredOptions;
    this.newOrRenewalOptions = this.data?.newOrRenewalOptions;
    this.contractPreviewForm = this.createFormGroup(this.data);
  }

  createFormGroup(data: any): UntypedFormGroup {
    if (data) {
      console.log('QuoteExpirationDate: ', data?.quoteExpirationDate);
      return this.fb.group({
        customerName: new UntypedFormControl(
          data?.customerName,
          Validators.required
        ),
        quoteExpirationDate: new UntypedFormControl(
          new Date(data?.quoteExpirationDate),
          Validators.required
        ),
        quotedBy: new UntypedFormControl(data?.quotedBy, Validators.required),
        accountManager: new UntypedFormControl(
          data?.accountManager,
          Validators.required
        ),
        contractTermYears: new UntypedFormControl(data?.contractTermYears),
        fullTermPaymentRequired: new UntypedFormControl(
          data?.fullTermPaymentRequired
        ),
        invoicingFrequency: new UntypedFormControl(data?.invoicingFrequency),
        newOrRenewal: new UntypedFormControl(data?.newOrRenewal),
        sellPriceAdustmentPercentage: new UntypedFormControl(
          data?.sellPriceAdustmentPercentage
        ),
      });
    }
    return this.fb.group({
      customerName: [''],
      quoteExpirationDate: [''],
      contractTermYears: [''],
      fullTermPaymentRequired: [''],
      invoicingFrequency: [''],
      newOrRenewal: [''],
      sellPriceAdustmentPercentage: [''],
    });
  }

  close() {
    this.dialogRef.close();
  }
  save() {}

  run() {
    var formVal = this.contractPreviewForm.value;

    const spadjpercent =
      formVal.sellPriceAdustmentPercentage > 0
        ? formVal.sellPriceAdustmentPercentage
        : 0;
    console.log('spadjpercent: ', spadjpercent);
    var data = {
      assessmentId: this.assessmentId,
      quoteExpirationDate: formVal.quoteExpirationDate,
      contractTermYears: formVal.contractTermYears,
      fullTermPaymentRequired: formVal.fullTermPaymentRequired,
      invoicingFrequency: formVal.invoicingFrequency,
      newOrRenewal: formVal.newOrRenewal,
      sellPriceAdustmentPercentage: spadjpercent,
    };

    this.service.generateContractPreview(data).then((res: any) => {
      this.contract = res.data;
      this.recurringFees = this.contract.recurringFees;
      this.oneTimeFees = this.contract.oneTimeFees;
    });
  }

  exportPreview() {
    let data = this.getRequestData();

    this.service.generateContractPreviewExcelFile(data).subscribe((res: any) => {
      const url = window.URL.createObjectURL(res);
          const link = this.donwloadExcelLink.nativeElement;
          link.href = url;
          link.download = 'ContractPreview.xlsx';
          link.click();
          window.URL.revokeObjectURL(url);

    });
  }

  getRequestData(): any {
    var formVal = this.contractPreviewForm.value;

    const spadjpercent =
      formVal.sellPriceAdustmentPercentage > 0
        ? formVal.sellPriceAdustmentPercentage
        : 0;
    console.log('spadjpercent: ', spadjpercent);
    var data = {
      assessmentId: this.assessmentId,
      quoteExpirationDate: formVal.quoteExpirationDate,
      contractTermYears: formVal.contractTermYears,
      fullTermPaymentRequired: formVal.fullTermPaymentRequired,
      invoicingFrequency: formVal.invoicingFrequency,
      newOrRenewal: formVal.newOrRenewal,
      sellPriceAdustmentPercentage: spadjpercent,
    };
    return data;
  }
}
