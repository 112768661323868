import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SupportService } from '../support.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-incident-report-form',
  templateUrl: './incident-request-form.component.html',
  styleUrls: ['./incident-request-form.component.scss']
})
export class IncidentRequestFormComponent implements OnInit {
  formGroup: UntypedFormGroup;


  constructor(private formBuilder: UntypedFormBuilder, private supportService: SupportService, private location: Location) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      'dateOfIncident': [null, Validators.required],
      'timeOfIncident': [null, Validators.required],
      'locationOfIncident': ['', Validators.required],
      'descriptionOfIncident': ['', Validators.required]
    });
  }
  submitForm() {
    console.log(this.formGroup.value);
    if (this.formGroup.valid) {
      this.supportService.saveIncidentReports(this.formGroup.value)
        .subscribe(res => {
            this.location.back();
          
        });
    }
  }

}
