import { Component, OnInit, ViewChild, Input, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, NgForm, AbstractControl, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-cloud-migration-discovery-configuration-popup',
  templateUrl: './cloud-migration-discovery-configuration-popup.component.html',
  styleUrls: ['../../../dialog.scss', './cloud-migration-discovery-configuration-popup.component.scss']
})
export class CloudMigrationDiscoveryConfigurationPopupComponent implements OnInit {

  dicoveryConfigForm: UntypedFormGroup;
  discoveryConfigId: string;
  discoveryConfigData: any;
  virtualizationTypes: any = [];
  @ViewChild('form') form: NgForm;
  projectId: string;
  formTitle: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<CloudMigrationDiscoveryConfigurationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private location: Location,
    private cloudMigrationService: CloudMigrationToolService) { }

  ngOnInit(): void {
    this.discoveryConfigId = this.data['discoveryConfigId'];
    this.projectId = this.data['projectId'];
    let formAction = this.discoveryConfigId == '00000000-0000-0000-0000-000000000000' ? 'Create' : 'Update';
    this.formTitle = `${formAction} Discovery Configuration`;

    this.cloudMigrationService.getconfiguration(this.projectId, this.discoveryConfigId)
      .subscribe(res => {
        this.dicoveryConfigForm = this.fb.group({
          title: [res.title, Validators.required],
          virtualEnvironment: [res.virtualEnvironment, Validators.required],
          hostName: [res.hostName, Validators.required],
          projectId: [res.projectId],
          userName: [res.userName, Validators.required],
          password: [res.password, Validators.required],
          confirmPassword: [res.password, Validators.required],
          id: [res.id],
        }, { validator: passwordMatchValidator });

        this.virtualizationTypes = res.virtualizationTypeOptions;

      });
  }



  public canDeactivate(): boolean {
    let state: boolean;
    state = true;
    if (this.dicoveryConfigForm) {
      state = this.form.submitted || !this.form.dirty;
    }

    const url = this.location.path();

    if (state) {

    } else {
      history.pushState(null, 'Meridian Unified Portal', url);
    }
    return state;
  }

  close() {
    this.dialogRef.close(this.dicoveryConfigForm.value);
  }

  save() {
    this.message = '';
    if (this.dicoveryConfigForm.valid) {
      if (this.discoveryConfigId !== '00000000-0000-0000-0000-000000000000') {
        this.cloudMigrationService.updateconfiguration(this.dicoveryConfigForm.value)
          .subscribe((res: any) => {
            this.discoveryConfigData = res;
            this.message = 'Configuration Saved!'
          });
      } else {
        this.cloudMigrationService.addconfiguration(this.dicoveryConfigForm.value)
          .subscribe((res: any) => {
            this.discoveryConfigData = res;
            this.message = 'Configuration Updated!'
          });
      }
    }
  }
}

export function passwordMatchValidator(control: AbstractControl) {
  const password: string = control.get('password')?.value;
  const confirmPassword: string = control.get('confirmPassword')?.value;
  if (password !== confirmPassword) {
    control.get('confirmPassword')?.setErrors({ NoPassswordMatch: true });
  } else if (!confirmPassword) {
    control.get('confirmPassword')?.setErrors({ required: true });
  } else if (password === confirmPassword) {
    control.get('confirmPassword')?.setErrors(null);
  }
}
