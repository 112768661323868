import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { CloudMigrationToolService } from '../../cloud-migration-tool.service';
import { forkJoin } from 'rxjs';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Assessment, AssessmentDataSource } from '../../models/assessment-datasource';
import { CloudMigrationGenerateAssessmentComponent } from '../generate-assessment/generate-assessment.component';

@Component({
  selector: 'app-cloud-migration-assessments-list',
  templateUrl: './cloud-migration-assessments-list.component.html',
  styleUrls: ['./cloud-migration-assessments-list.component.scss']
})
export class CloudMigrationAssessmentsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  project: any;
  assessmentDataSource: AssessmentDataSource;
  loading: boolean;
  assessments: Array<Assessment>;
  projectId: string = "";
  displayedColumns = ['title', 'date', 'source', 'target', 'cost', 'actions'];
  viewList: Array<any>;
  newAssessment: Assessment;

  constructor(private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private cloudMigrationService: CloudMigrationToolService) { }

  ngOnInit(): void {
    this.loading = true;
    this.refreshView();
  }


  refreshView() {
    this.route.parent?.parent?.params.subscribe(param => {
      let projectId = param['id'];
      console.log('CloudMigrationAssessmentsListComponent.projectId', projectId);
      this.projectId = projectId;
      let data = this.cloudMigrationService.getAssessments(projectId);
      let project = this.cloudMigrationService.getProject(projectId);
      forkJoin([data, project])
        .subscribe(results => {
          this.loading = false;
          this.project = results[1];
          this.viewList = results[0]?.rows;
          this.assessmentDataSource = new AssessmentDataSource(this.viewList);
          this.assessmentDataSource.sort = this.sort;
          this.assessmentDataSource.paginator = this.paginator;
        });
    }, (error) => {
      this.loading = false;
    });

  }

  generateAssessement() {
    console.log(this.projectId);

    this.cloudMigrationService.getNewAssessment(this.projectId).subscribe(data => {
      this.newAssessment = data;

      this.openGenerateAssessemenDialog(this.newAssessment);
    });
  }

  getAssessments() {
    this.cloudMigrationService.getAssessments(this.project.id).subscribe(res => {
      this.assessments = res;
      this.assessmentDataSource = new AssessmentDataSource(this.assessments);
      this.assessmentDataSource.sort = this.sort;
      this.assessmentDataSource.paginator = this.paginator;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });

  }

  deleteRecord(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.loading = true;
          this.cloudMigrationService.deleteAssessment(id)
            .subscribe(res => {
              this.getAssessments();

            });
        }
      });
  }
  
  openGenerateAssessemenDialog(rowData: any) {
    const dialogRef = this.dialog.open(CloudMigrationGenerateAssessmentComponent, {
      height: '65%',
      width: '55%',
      data: {
        assessment: rowData,
        projectId: this.projectId,
      },
      disableClose: true,
      autoFocus: true,

    });

    dialogRef.afterClosed()
      .subscribe((result: any) => {
        if (result) {
          console.log('result', result);

          const projectId = result.cloudMigrationServiceProjectId;
          const id = result.id;
          this.router.navigateByUrl(`cmt/projects/${projectId}/view/assessments/${id}`);
        }
      });
  }
 

}
