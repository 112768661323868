<mat-table id="secTable" *ngIf="serviceRoles" mat-table class="full-width-table" aria-label="SEC Table" [dataSource]="serviceRoles">
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.serviceRoleName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="approvedByMe">
    <mat-header-cell *matHeaderCellDef>Approved By Me</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getServiceRoleCount(row.serviceRoleId, true)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="totalPendingApproval">
    <mat-header-cell *matHeaderCellDef>Total Pending Approval</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getServiceRoleCount(row.serviceRoleId, false)}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<div *ngIf="!(serviceRoles && serviceRoles.length > 0)" class="no-records">
  No records found
</div>
