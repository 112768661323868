import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ServiceEstimateService } from '../../../shared/components/service-estimate-popup/service-estimate.service';
import { ServiceEstimateMilestoneDataSource } from '../../models/service-estimate-milestone.datasource';
import { ProfessionalServicesService } from '../../professional-services.service';
@Component({
  selector: 'app-milestone-summary-popup',
  templateUrl: './milestone-summary-popup.component.html',
  styleUrls: ['./milestone-summary-popup.component.scss']
})
export class MilestoneSummaryPopupComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  oppurtunityNumber: string;
  dataSource: ServiceEstimateMilestoneDataSource;
  displayedColumns: Array<string>;
  loading: boolean;
  milestoneData: Array<any>;
  isScopeRiskEnabled: boolean;

  constructor(public dialogRef: MatDialogRef<MilestoneSummaryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private professionalServicesService: ProfessionalServicesService) { }

  ngOnInit(): void {
    this.displayedColumns = [
      'seName',
      'milestone',
      'totalHrs',
      'estimatedTotalPrice'
    ];
    this.fetchSEMilesones();
  }

  getTotalHours() {
    return this.milestoneData?.map(t => t.serviceHours).reduce((acc, value) => acc + value, 0);
  }

  getTotalEstimatedPrice() {
    return this.milestoneData?.map(t => t.updatedEstimatedTotalPrice).reduce((acc, value) => acc + value, 0);
  }


  fetchSEMilesones() {
    this.loading = true;
    this.isScopeRiskEnabled = this.data?.project?.isScopeRiskEnabled;
    this.professionalServicesService.fetchSEMilestones(this.data?.project?.id, this.data?.milestoneVal).subscribe(res => {
      const data = res;
      data.forEach((se: any) => {
        let scopeRiskPrice = this.isScopeRiskEnabled ? this.calculateScopeRiskPerct(se.estimatedPrice, this.data.project.scopeRiskPercentage) : 0;
        se.updatedEstimatedTotalPrice = se.estimatedPrice + (se.serviceEstimatePriceAdjustments ? se.serviceEstimatePriceAdjustments : 0) + (scopeRiskPrice);
      });
      this.milestoneData = data;
      this.dataSource = new ServiceEstimateMilestoneDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, 
      (error) => {
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  closePopup(data: string) {
    this.dialogRef.close({ data });
  }

  calculateScopeRiskPerct(estimatedPrice: any, scopeRiskPerct: any) {
    return estimatedPrice && scopeRiskPerct ? scopeRiskPerct * (estimatedPrice / 100) : 0;
  }

}
