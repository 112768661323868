<h2 mat-dialog-title>Date the Contract Was Actually Signed</h2>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Enter Date</mat-label>
    <input required matInput [matDatepicker]="picker" [(ngModel)]="data['contractSignDate']" [max]="getMaxDate()" #contractSignDate="ngModel">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="data" color="primary" [disabled]="!contractSignDate.valid">Save</button>
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
